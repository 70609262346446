import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from "crypto-js";
import Header from "../Header";
import Footer from "../Footer";
import $ from "jquery";
import { API_URL } from '../config';
import {useParams} from 'react-router-dom'
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function AddPreshipment() {
  const param=useParams();
  const id=param.id;
  const [accountNames, setAccountName] = useState([]);
  const [bank, setBank] = useState([]);
  // bank
  useEffect(() => {
    fetchBank();
  }, [decryptedAccessToken]);

  const fetchBank = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/rwoodbank`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwood_bank; 
      setBank(data);
      console.log("Supplier" + JSON.stringify(data));
    } catch (error) {
      console.error(error);
    }
  };
  
  const [user, setUser] = useState([]);
  // bank
  useEffect(() => {
    fetchUser();
  }, [decryptedAccessToken]);

  const fetchUser = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/users`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      setUser(data);
      console.log("Supplier" + JSON.stringify(data));
    } catch (error) {
      console.error(error);
    }
  };
  
console.log(user)
  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(
        `${API_URL}all/accounts`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.accounts;
      setAccountName(data);
      console.log("Supplier" + JSON.stringify(data));
    } catch (error) {
      console.error(error);
    }
  };
  const body = $(window).height();
  const header = $("#header").height();
  const nav = $(".navbar").height();
  const footer = $(".footer").height();
  const total_height = header + nav + footer;
  const window_height = body - total_height;
  console.log(total_height);
  console.log(body);
  $(".contactAccount").css({ "min-height": window_height + "px" });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    const responseData = {
     locked:true,
rwood_bank:data.rwood_bank,
currency:data.currency, 
exchange_rate:data.exchange_rate,
country:data.country,
bank_address:data.bank_address,
master_lc_expiry_date_1:data.master_lc_expiry_date_1,
master_lc_place_of_expiry:data.master_lc_place_of_expiry,
master_lc_issuing_bank:data.master_lc_issuing_bank,
issuing_bank_swift_code:data.issuing_bank_swift_code,
master_lc_applicant:data.master_lc_applicant,
master_lc_amount:data.master_lc_amount,
master_lc_tolerance:data.master_lc_tolerance,
master_lc_value_with_tolerance:data.master_lc_value_with_tolerance,
master_lc_consignee:data.master_lc_consignee,
master_lc_desination:data.master_lc_desination,
master_lc_port_of_loading:data.master_lc_port_of_loading,
adding_confirmation_date:data.adding_confirmation_date,
master_lc_status:data.master_lc_status,
master_lc_reviewed_by:data.master_lc_reviewed_by,
master_lc_reviewed_date:data.master_lc_reviewed_date,
master_lc_reviewed_date_1:data.master_lc_reviewed_date_1,
master_lc_shipment_date_1:data.master_lc_shipment_date_1,
commodity:data.commodity,
master_lc_unit_price:data.master_lc_unit_price,
master_lc_quantity:data.master_lc_quantity,
master_lc_quantity_tolerance:data.master_lc_quantity_tolerance,
price_basis:data.price_basis,
notify_party_1:data.notify_party_1,
notify_party:data.notify_party,
lc_type:data.lc_type,
master_lc_issued_date:data.master_lc_issued_date,
master_lc_no:data.master_lc_no,
master_lc_presentation_days:data.master_lc_presentation_days,
master_lc_presentation_date:data.master_lc_presentation_date,
child_lc_no:data.child_lc_no,
child_lc_issued_date:data.child_lc_issued_date,
child_lc_expiry_date:data.child_lc_expiry_date,
child_lc_place_of_expiry:data.child_lc_place_of_expiry,
child_lc_applicant:data.child_lc_applicant,
child_lc_amount:data.child_lc_amount,
child_lc_tolerance:data.child_lc_tolerance,
child_lc_notify_party:data.child_lc_notify_party,
child_lc_consignee:data.child_lc_consignee,
child_lc_destination:data.child_lc_destination,
child_lc_status:data.child_lc_status,
child_lc_port_of_loading:data.child_lc_port_of_loading,
child_lc_shipment_date:data.child_lc_shipment_date,
child_lc_quantity:data.child_lc_quantity,
child_lc_unit_price:data.child_lc_unit_price,
child_lc_prepared_by:data.child_lc_prepared_by,
child_lc_prepared_date:data.child_lc_prepared_date,
child_lc_rwood_bank:data.child_lc_rwood_bank,
child_lc_shipper_bank:data.child_lc_shipper_bank,
child_lc_reviewed_by:data.child_lc_reviewed_by,
child_lc_reviewed_date:data.child_lc_reviewed_date,
child_lc_application_date:data.child_lc_application_date,
child_lc_presentation_days:data.child_lc_presentation_days,
lc_discounting_application_date:data.lc_discounting_application_date,
days_to_add_into_child_lc_period:data.days_to_add_into_child_lc_period,
days_to_add_into_adding_confirmation_day:data.days_to_add_into_adding_confirmation_day,
lc_transfer_cost:data.lc_transfer_cost,
interest:data.interest,
adding_confirmation_charges:data.adding_confirmation_charges,
adding_confrimation_days:data.adding_confrimation_days,
vat_on_bank_charges:data.vat_on_bank_charges,
bill_handling_charges:data.bill_handling_charges,
libor_rate_n_bank_cost_rate:data.libor_rate_n_bank_cost_rate,
other_bank_cost:data.other_bank_cost

    };
    console.log(JSON.stringify(responseData));
    const encryptedAccessToken = localStorage.getItem("access_token");
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);

      fetch(`${API_URL}add/preshipment/`+id, {
        method: "POST",
        body: JSON.stringify(responseData),
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())

        .then((response) => {
          console.log(response);
           if(response.message=="Preshipments added successfully"){
              toast.success("Added Succesfully", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              reset();
            }, 300);
           }
           else{
            toast.success("Record Not Found", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
           }
        });
    }
  };

  return (
    <div className="addAccount">
      <Header />
      <div className="page-header" id="add-account">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Add Preshipment</h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Dashboard / Preshipment /Add Preshipment{" "}
            </li>
          </ol>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
          </div>
        </div>
      </div>{" "}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <h4 className="heading">Preshipment Information</h4>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="Rwood Bank"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="rwood_bank"
                id="account_name"
                placeholder="Rwood Bank"
                defaultValue=""
                {...register("rwood_bank", { required: false })}
              >
                <option value="">Select</option>
                {bank.map((x) => {
                  return (
                    <option value={x.rwood_bank_name}>{x.rwood_bank_name}</option>
                  );
                })}
              </Form.Select>
              {errors.rwood_bank && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Currency"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Currency"
                name="currency"
                {...register("currency", { required: false })}
              />
              {errors.currency && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Exchange Rate"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Exchange Rate"
                name="exchange_rate"
                {...register("exchange_rate", {
                  valueAsNumber: true,
                  required: false,
                })}
              />
              {errors.exchange_rate && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Country"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Country"
                name="country"
                {...register("country", { required: false })}
              />
              {errors.country && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Bank Address"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Bank Address"
                name="bank_address"
                {...register("bank_address", { required: false })}
              />
              {errors.bank_address && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Expiry Date 1"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Expiry Date 1"
                name="master_lc_expiry_date_1"
                {...register("master_lc_expiry_date_1", { required: false })}
              />
              {errors.master_lc_expiry_date_1 && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Place of expiry"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Place of expiry"
                name="master_lc_place_of_expiry"
                {...register("master_lc_place_of_expiry", { required: false })}
              />
              {errors.master_lc_place_of_expiry && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC  Issuing Bank"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC  Issuing Bank"
                name="master_lc_issuing_bank"
                {...register("master_lc_issuing_bank", { required: false })}
              />
              {errors.master_lc_issuing_bank && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Issuing Bank Swift Code"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Issuing Bank Swift Code"
                name="issuing_bank_swift_code"
                {...register("issuing_bank_swift_code", { required: false })}
              />
              {errors.issuing_bank_swift_code && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="Master LC Applicant"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="master_lc_applicant"
                id="account_name"
                placeholder="Master LC Applicant"
                defaultValue=""
                {...register("master_lc_applicant", { required: false })}
              >
                <option value="">Select</option>
                {accountNames.map((x) => {
                  return (
                    <option value={x.account_name}>{x.account_name}</option>
                  );
                })}
              </Form.Select>
              {errors.master_lc_applicant && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>
        </Row> 
         <Row className="mb-3">
          <h4 className="heading">Preshipment Information 1</h4>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Amount"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Amount"
                name="master_lc_amount"
                {...register("master_lc_amount", { valueAsNumber:true,required: false })}
              />
              {errors.master_lc_amount && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Tolerance"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Tolerance"
                name="master_lc_tolerance"
                {...register("master_lc_tolerance", {valueAsNumber:true, required: false })}
              />
              {errors.master_lc_tolerance && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Value With Tolerance"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Value With Tolerance"
                name="master_lc_value_with_tolerance"
                {...register("master_lc_value_with_tolerance", {valueAsNumber:true, required: false })}
              />
              {errors.master_lc_value_with_tolerance && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Consignee"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Consignee"
                name="master_lc_consignee"
                {...register("master_lc_consignee", { required: false })}
              />
              {errors.master_lc_consignee && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
           
        </Row>
<Row className="mb-3">
          <h4 className="heading">Preshipment Information 3</h4>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Desination"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Desination"
                name="master_lc_desination"
                {...register("master_lc_desination", {required: false })}
              />
              {errors.master_lc_desination && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Port Of Loading"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Port Of Loading"
                name="master_lc_port_of_loading"
                {...register("master_lc_port_of_loading", {required: false })}
              />
              {errors.master_lc_port_of_loading && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Adding Confirmation Date"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Adding Confirmation Date"
                name="adding_confirmation_date"
                {...register("adding_confirmation_date", {required: false })}
              />
              {errors.adding_confirmation_date && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Status"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Status"
                name="master_lc_status"
                {...register("master_lc_status", {required: false })}
              />
              {errors.master_lc_status && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Reviewed By"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Reviewed By"
                name="master_lc_reviewed_by"
                {...register("master_lc_reviewed_by", {required: false })}
              />
              {errors.master_lc_reviewed_by && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Reviewed Date"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Reviewed Date"
                name="master_lc_reviewed_date"
                {...register("master_lc_reviewed_date", {required: false })}
              />
              {errors.master_lc_reviewed_date && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Reviewed Date 1"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Reviewed Date 1"
                name="master_lc_reviewed_date_1"
                {...register("master_lc_reviewed_date_1", {required: false })}
              />
              {errors.master_lc_reviewed_date_1 && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Commodity"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Commodity"
                name="commodity"
                {...register("commodity", {required: false })}
              />
              {errors.commodity && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Unit Price"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Unit Price"
                name="master_lc_unit_price"
                {...register("master_lc_unit_price", {valueAsNumber:true, required: false })}
              />
              {errors.master_lc_unit_price && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Quantity"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Quantity"
                name="master_lc_quantity"
                {...register("master_lc_quantity", {valueAsNumber:true, required: false })}
              />
              {errors.master_lc_quantity && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Quantity Tolerance"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Quantity Tolerance"
                name="master_lc_quantity_tolerance"
                {...register("master_lc_quantity_tolerance", {valueAsNumber:true, required: false })}
              />
              {errors.master_lc_quantity_tolerance && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Price Basis"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Price Basis"
                name="price_basis"
                {...register("price_basis", {required: false })}
              />
              {errors.price_basis && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Notify Party 1"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Notify Party 1"
                name="notify_party_1"
                {...register("notify_party_1", {required: false })}
              />
              {errors.notify_party_1 && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="Notify Party"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="notify_party"
                id="account_name"
                placeholder="Notify Party"
                defaultValue=""
                {...register("notify_party", { required: false })}
              >
                <option value="">Select</option>
                {bank.map((x) => {
                  return (
                    <option value={x.rwood_bank_name}>{x.rwood_bank_name}</option>
                  );
                })}
              </Form.Select>
              {errors.notify_party && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>
          
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="LC Type"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="LC Type"
                name="lc_type"
                {...register("lc_type", {required: false })}
              />
              {errors.lc_type && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Issued Date"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Issued Date"
                name="master_lc_issued_date"
                {...register("master_lc_issued_date", {required: false })}
              />
              {errors.master_lc_issued_date && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC No"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC No"
                name="master_lc_no"
                {...register("master_lc_no", {required: true })}
              />
              {errors.master_lc_no && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
 
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Presentation Days"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Presentation Days"
                name="master_lc_presentation_days"
                {...register("master_lc_presentation_days", {valueAsNumber:true, required: false })}
              />
              {errors.master_lc_presentation_days && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Master LC Presentation Date"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Master LC Presentation Date"
                name="master_lc_presentation_date"
                {...register("master_lc_presentation_date", {required: false })}
              />
              {errors.master_lc_presentation_date && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC No"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC No"
                name="child_lc_no"
                {...register("child_lc_no", {required: false })}
              />
              {errors.child_lc_no && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Issued Date"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Issued Date"
                name="child_lc_issued_date"
                {...register("child_lc_issued_date", {required: false })}
              />
              {errors.child_lc_issued_date && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Expiry Date"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Expiry Date"
                name="child_lc_expiry_date"
                {...register("child_lc_expiry_date", {required: false })}
              />
              {errors.child_lc_expiry_date && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Place Of Expiry"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Place Of Expiry"
                name="child_lc_place_of_expiry"
                {...register("child_lc_place_of_expiry", {required: false })}
              />
              {errors.child_lc_place_of_expiry && <span>Required</span>}
            </FloatingLabel>
          </Form.Group> 
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="Child LC Applicant"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="child_lc_applicant"
                id="account_name"
                placeholder="Child LC Applicant"
                defaultValue=""
                {...register("child_lc_applicant", { required: false })}
              >
                <option value="">Select</option>
                {bank.map((x) => {
                  return (
                    <option value={x.rwood_bank_name}>{x.rwood_bank_name}</option>
                  );
                })}
              </Form.Select>
              {errors.child_lc_applicant && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Amount"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Amount"
                name="child_lc_amount"
                {...register("child_lc_amount", {valueAsNumber:true, required: false })}
              />
              {errors.child_lc_amount && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Tolerance"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Tolerance"
                name="child_lc_tolerance"
                {...register("child_lc_tolerance", {valueAsNumber:true, required: false })}
              />
              {errors.child_lc_tolerance && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Notify Party"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Notify Party"
                name="child_lc_notify_party"
                {...register("child_lc_notify_party", {required: false })}
              />
              {errors.child_lc_notify_party && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Consignee"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Consignee"
                name="child_lc_consignee"
                {...register("child_lc_consignee", {required: false })}
              />
              {errors.child_lc_consignee && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Destination"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Destination"
                name="child_lc_destination"
                {...register("child_lc_destination", {required: false })}
              />
              {errors.child_lc_destination && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Status"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Status"
                name="child_lc_status"
                {...register("child_lc_status", {required: false })}
              />
              {errors.child_lc_status && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Port Of Loading"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Port Of Loading"
                name="child_lc_port_of_loading"
                {...register("child_lc_port_of_loading", {required: false })}
              />
              {errors.child_lc_port_of_loading && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Shipment Date"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Shipment Date"
                name="child_lc_shipment_date"
                {...register("child_lc_shipment_date", {required: false })}
              />
              {errors.child_lc_shipment_date && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Quantity"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Quantity"
                name="child_lc_quantity"
                {...register("child_lc_quantity", {valueAsNumber:true, required: false })}
              />
              {errors.child_lc_quantity && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Unit Price"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Unit Price"
                name="child_lc_unit_price"
                {...register("child_lc_unit_price", {valueAsNumber:true, required: false })}
              />
              {errors.child_lc_unit_price && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
<Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="Child LC Prepared By"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="child_lc_prepared_by"
                id="account_name"
                placeholder="Child LC Prepared By"
                defaultValue=""
                {...register("child_lc_prepared_by", { required: false })}
              >
                <option value="">Select</option>
                {user.map((x) => {
                  return (
                    <option value={x.username}>{x.username}</option>
                  );
                })}
              </Form.Select>
              {errors.child_lc_prepared_by && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>
          
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Prepared Date"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Prepared Date"
                name="child_lc_prepared_date"
                {...register("child_lc_prepared_date", {required: false })}
              />
              {errors.child_lc_prepared_date && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Rwood Bank"
              className="mb-3"
            >
              <Form.Control    
                type="text"
                placeholder="Child LC Rwood Bank"
                name="child_lc_rwood_bank"
                {...register("child_lc_rwood_bank", {required: false })}
              />
              {errors.child_lc_rwood_bank && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Shipper Bank"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Shipper Bank"
                name="child_lc_shipper_bank"
                {...register("child_lc_shipper_bank", {required: false })}
              />
              {errors.child_lc_shipper_bank && <span>Required</span>}
            </FloatingLabel>
          </Form.Group> 
         
<Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="Child LC Reviewed By"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="child_lc_reviewed_by"
                id="account_name"
                placeholder="Child LC Reviewed By"
                defaultValue=""
                {...register("child_lc_reviewed_by", { required: false })}
              >
                <option value="">Select</option>
                {user.map((x) => {
                  return (
                    <option value={x.username}>{x.username}</option>
                  );
                })}
              </Form.Select>
              {errors.child_lc_prepared_by && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>
          
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Reviewed Date"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Reviewed Date"
                name="child_lc_reviewed_date"
                {...register("child_lc_reviewed_date", {required: false })}
              />
              {errors.child_lc_reviewed_date && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Application Date"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Application Date"
                name="child_lc_application_date"
                {...register("child_lc_application_date", {required: false })}
              />
              {errors.child_lc_application_date && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Child LC Presentation Days"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Child LC Presentation Days"
                name="child_lc_presentation_days"
                {...register("child_lc_presentation_days", {valueAsNumber:true, required: false })}
              />
              {errors.child_lc_presentation_days && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="LC Discounting Application Date"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="LC Discounting Application Date"
                name="lc_discounting_application_date"
                {...register("lc_discounting_application_date", {required: false })}
              />
              {errors.lc_discounting_application_date && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Days To Add Into Child LC Period"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Days To Add Into Child LC Period"
                name="days_to_add_into_child_lc_period"
                {...register("days_to_add_into_child_lc_period", {valueAsNumber:true, required: false })}
              />
              {errors.days_to_add_into_child_lc_period && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Days To Add Into Adding Confirmation Day"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Days To Add Into Adding Confirmation Day"
                name="days_to_add_into_adding_confirmation_day"
                {...register("days_to_add_into_adding_confirmation_day", {valueAsNumber:true, required: false })}
              />
              {errors.days_to_add_into_adding_confirmation_day && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="LC Transfer Cost"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="LC Transfer Cost"
                name="lc_transfer_cost"
                {...register("lc_transfer_cost", {valueAsNumber:true, required: false })}
              />
              {errors.lc_transfer_cost && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Interest"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Interest"
                name="interest"
                {...register("interest", {valueAsNumber:true, required: false })}
              />
              {errors.interest && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Adding Confirmation Charges"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Adding Confirmation Charges"
                name="adding_confirmation_charges"
                {...register("adding_confirmation_charges", {valueAsNumber:true, required: false })}
              />
              {errors.adding_confirmation_charges && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Adding Confrimation Days"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Adding Confrimation Days"
                name="adding_confrimation_days"
                {...register("adding_confrimation_days", {valueAsNumber:true, required: false })}
              />
              {errors.adding_confrimation_days && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="VAT On Bank Charges"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="VAT On Bank Charges"
                name="vat_on_bank_charges"
                {...register("vat_on_bank_charges", {valueAsNumber:true, required: false })}
              />
              {errors.vat_on_bank_charges && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Bill Handling Charges"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Bill Handling Charges"
                name="bill_handling_charges"
                {...register("bill_handling_charges", {valueAsNumber:true, required: false })}
              />
              {errors.bill_handling_charges && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Libor Rate N Bank Cost Rate"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Libor Rate N Bank Cost Rate"
                name="libor_rate_n_bank_cost_rate"
                {...register("libor_rate_n_bank_cost_rate", {valueAsNumber:true, required: false })}
              />
              {errors.libor_rate_n_bank_cost_rate && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         
         <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Other Bank Cost"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Other Bank Cost"
                name="other_bank_cost"
                {...register("other_bank_cost", {valueAsNumber:true, required: false })}
              />
              {errors.other_bank_cost && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
         
        </Row>
        <input type="submit" className="addaccount-save" value="Save" />
        <div className="successDiv">
          <p></p><p></p>
        </div>
      </Form>
      <Footer />
    </div>
  );
}

export default AddPreshipment;
