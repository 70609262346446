import logo from './logo.svg';
import './App.css';
import React, { useEffect,useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from './components/Header' 
import HeaderFinanace from './components/headerfinanace'
import Menu from './components/menu/menu'
import OppShipmentList from './components/oppShipmentList'
import Login from './components/Login'
import Price from './components/price'
import AddProduct from './components/addproduct'
// import PriceList from './components/pricelist'
import Dashboard from './components/dashboard'
import DashboardFinance from './components/dashboardfinance'
import Contactus from './components/account/contacts' 
 
import Accountlist from './components/accountlist' 
import AllTcf from './components/alltcf'   
import AccountDetails from './components/accountDetails'   
import Addaccount from './components/account/addaccount'
import Oppourtunity from './components/account/oppourtunity' 
import ResetPassword from './components/ResetPassword' 
import ResetConfirm from './components/ResetConfirm' 
import QuotesList from './components/quoteslist' 
// import AddTcf from './components/addTcf'
import AddOppurtunity from './components/account/addoppurtunity' 
import AllQuotes from './components/account/allQuotes' 
import EditQuote from './components/account/editquote'
import ProductMaster from './components/productMaster/productmaster' 
import Shipment from './components/account/shipment' 
import Quotes from './components/account/quotes'
import AddTcfs from './components/tcf/addTcfs'
import EditTcf from './components/tcf/edittcf'
import AddPricebook from './components/pricebook/addPricebook'
import Report from './components/reports/Report'
// newly added 
import AddRecord from './components/accounts/addRecord'
import NewAccounts from './components/accounts/newAccounts'
import AccountCreateView from './components/accounts/accountcreateview'
import Quotecreateview from './components/quotes/quotecreateview'
import EditView from './components/accounts/editview'
import AccountsView from './components/accounts/accountsview'
import AccountsList from './components/accounts/accounts'
import CreateviewList from './components/opportunity/createviewlist'
import AccountViewPage from './components/accounts/accountViewPage'
import AddContacts from './components/contact/addContacts';
import ContactLists from './components/contact/contactLists';
import Contacts from './components/contact/contacts';
import ContactCreateView from './components/contact/contactcreateview';
import BankCreateView from './components/bank/createview'
import EditContact from './components/contact/editcontact';
import PriceBooklist from './components/pricebook/priceBooklist'
import ViewPricebook from './components/pricebook/viewPricebook'
import PriceType from './components/price/viewPrice'
import AddRwoodusers from './components/rwoodusers/addRwoodusers'
import UserList from './components/rwoodusers/userList'
import ViewUser from './components/rwoodusers/viewUser'
import AddRwoodCompany from './components/rwoodCompany/addRwoodcompany'
import ViewCompany  from './components/rwoodCompany/viewCompany'
import RwoodCompanyLists from './components/rwoodCompany/rwoodCompanyLists'
import AddPrice from './components/price/addPrice'
import QuotesView from './components/opportunity/quotesview'
import UpdatePriceBook from './components/pricebook/updatePricebook'
import PriceLists from './components/price/priceLists'
import AddCotract from './components/contract/addCotract'
import ContractList from './components/contract/contarctList'
import EditContract from './components/contract/editcontract'
import AddOpportunitys from './components/opportunity/addOpportunitys'
import AddRecords from './components/opportunity/addRecords'
import AddQuotes from './components/opportunity/addQuotes'
import OpportunityDetails from './components/opportunity/opportunityDetails'
import OppourtunityList from './components/opportunity/oppourtunityList'
import OpportunityShipmentDetail from './components/opportunity/opportunityShipmentDetail'
import TcfList from './components/tcf/tcfList'
import ViewTcf from './components/tcf/viewTcf'
import AddBank from './components/bank/addBank'
import EditBank from './components/bank/editbank'
import ViewBank from './components/bank/viewBank' 
import BankLists from './components/bank/BankLists'
import AddoppShippment  from './components/opportunity/addoppShippment'
import ViewOpportunity  from './components/opportunity/viewOpportunity'
import ViewQuote  from './components/opportunity/viewQuote'
import ReceiptsDetails from './components/accounts/receiptsDetails'
// import OpphomeCreateView  from './components/opportunity/opphomecreateview'
import OppShipmentLists from './components/opportunity/oppShipmentList'
import ViewContract from './components/contract/viewContract'
import ContractShippment from './components/shippment/contractShippment'
import ContractShippmentList from './components/shippment/contractShippmentList'
import NewProduct from './components/productMaster/addProductMaster'
import ContactViewPage from './components/contact/contactViewPage'
import ProductDetailPage from './components/productMaster/productDetailPage'
import ProductDetail from './components/product/productDetail'
import ProductCreateView from './components/productMaster/productcreateview'
import AddOrder from './components/order/addOrder'
import EditOrder from './components/order/editorder'
import OrderList from './components/order/orderLists'
import ViewOrder from './components/order/viewOrder'
import AddLaytime from './components/laytime/addLaytime'
import AddPreshipment from './components/accounts/addPreshipment'
import UpdateAccount from './components/accounts/updateAccount'
import AddBuyerProduct from './components/buyerproduct/addbuyerproduct'
import EditCompany from './components/rwoodCompany/editcompany'
import EditUser from './components/rwoodusers/edituser'
import PreshipmentList from './components/preshipment/preshipmentlist'
import ProductList from './components/product/productlist'
import { AES, enc } from "crypto-js";
import AddcontractClasue from './components/contractclasue/addcontractclasue'
import LaytimeLists from './components/laytime/laytimelists'
import ContractClauseList from './components/contractclasue/contractclauselist'
import Testdata from './components/preshipment/testdate' 
import BankEditView from './components/bank/editview'
import PriceEditView from './components/price/editview'
import Addpostshipment from './components/order/addpostshipment'
import AddQuoteProduct from './components/quoteproduct/addquoteproduct'
import EditQuoteProduct from './components/quoteproduct/editquoteproduct'
import QuoteProductView from './components/quoteproduct/quoteproductview'
import Addpreshipment from './components/preshipment/addpreshipment'
import QuoteList from './components/quotes/quotelist'
import Usercreateview from './components/rwoodusers/usercreateview'
import AddProducts from './components/product/addproduct'
import CounterParty from './components/accounts/counterParty';
import OppShipmentNew from './components/opportunity/oppShipmentNew'
import Payreceivedetails from './components/postshipment/payreceivedetails'
import PayReceive from './components/postshipment/payreceive'
import ContractShippmentDetails from './components/shippment/contractShippmentDetails'
import ContractShippmentDetailsList from './components/shippment/contractShippmentDetailsList'
import BuyerSupplier from './components/opportunity/buyerSupplier'
import Excel from './components/postshipment/excel'
import OrderReceivable from './components/order/orderreceivable'
import Orderreceipt from './components/order/orderreceipt'
import AddProductShipment from './components/shippment/addproductshipment'
import ProductDetails from './components/shippment/productdetails'
import Createviewoppshipment from './components/opportunity/createviewoppshipment'
import RecentItem from './components/recentitem/rececentItem'
import Createoppshipmentlist from './components/opportunity/createoppshipmentlist'
// all inner adding page
import FilterComponent from './components/filterComponent'
import AddContactInner from './components/alldetailspage/addcontactInner'
import AddQuoteproduct from './components/alldetailspage/addQuoteproduct'
import AddOpportunityInner from './components/alldetailspage/addopportunityinner'
import AddTcfInner from './components/alldetailspage/addtcfinner'
// import FormTest from './components/formTest'
import AddContractInner from './components/alldetailspage/addcontractinner'
import AddPostshipment from './components/postshipment/addpostshipment'
import PostShipmentList from './components/postshipment/postshipmentlist'
import ViewPost from './components/postshipment/viewpost'
import PreshipmentDetails from './components/preshipment/preshipmentDetails'
import CreateContract from './components/opportunity/createcontract'
import Rwpayreceipt from './components/order/rwpayreceipt'
// import ContractView from './components/contract/contractview'
import CreateviewContract from './components/contract/createviewcontract'
import CreateContractlist from './components/contract/createcontractlist'
import BuyerView from './components/buyerproduct/buyerView'
import OppCreateView from'./components/opportunity/oppcreateview' 
import Createviewcontractshipment from './components/shippment/createviewcontractshipment'
import OppCreateNewView from './components/opportunity/oppcreatneweview'
import AddContactOpportunity from './components/alldetailspage/addcontactopportunity'
import Addproductmasterinner from './components/alldetailspage/addproductmasterinner'
import AddbuyerInner from './components/alldetailspage/addbuyerinner'
import AddoppShippmentInner from './components/alldetailspage/addopportunityshipment'
import AllQuoteView from './components/opportunity/allquoteview'
import Addcontractshipment from './components/alldetailspage/addcontractshipment'
import Addopportunitycontracts from './components/alldetailspage/addopportunitycontract'
import Addcontracttcf from './components/alldetailspage/addcontracttcf'
import Addorderpostshipment from './components/alldetailspage/addorderpostshipment'
import Search from './components/search/search'
import Addorderpreshipment from './components/alldetailspage/addorderpreshipment'
import AddOrderTcf from './components/alldetailspage/addtordercf'
import Payreceiptdetails from './components/postshipment/payreceiptdetails'
import RwpayDetails from './components/order/rwpaydetails'
import Postrwpaydetails from './components/postshipment/postrwpaydetails'
import PayReceiptList from './components/postshipment/payreceiptlist'
import Payablereceiptdetails from './components/postshipment/payablereceiptdetails'
import Accountreceivable from './components/accounts/accountreceivable'
import AddDocument from './components/contract/adddocument'
import Receiptdetails from "./components/order/receiptdetails"
import DocumentView from './components/contract/documentView'
import ShipmentOrder from './components/shippment/shipmentorder'
import CreateviewOpportunity from './components/opportunity/createviewopportunity'
import CreateviewOrder from './components/order/createvieworder'
import CreateOrderList from './components/order/createorderlist'
import CreateviewTcf from './components/tcf/createviewtcf'
import CreatetcfList from './components/tcf/createtcflist'
import CreateContractshipmentlist from './components/shippment/createcontractlist'
import HbaUpdate from './components/pricebook/hbaUpdate'
import QuoteProductList from './components/quoteproduct/quoteproductlist'
import ReceivableDetails from './components/accounts/receivableDetails'
import ReceiptDetails from './components/accounts/receiptDetails'
import PostreceivableDetails from './components/postshipment/postreceivableDetails'

// create view
import ViewaccountMaster from './components/accountMaster/viewaccountmaster'
import Createviewpostshipment from './components/postshipment/createviewpostshipment'
import Createpostshipmentlist from './components/postshipment/createpostshipmentlist'
import Createviewpreshipment from './components/preshipment/createviewpreshipment'
import ViewFinanace from './components/finance/viewFinanace'
import Createpreshipmentlist from './components/preshipment/createpreshipmentlist'
import Createviewproductmaster  from './components/productMaster/createviewproduct'
import Createproductmasterlist from './components/productMaster/createproductmasterlist'
import Createviewbuyer from './components/buyerproduct/createviewbuyer'
import Createviewprice from './components/pricebook/createviewprice'
import Createpricebooklist from './components/pricebook/createpricebooklist'
import AddFinance from './components/finance/addFinance'
import FinanceList from './components/finance/financelist'
import AddAccountmaster from './components/accountMaster/addAccountmaster'
import AccountmasterList from './components/accountMaster/accountmasterlist'
import HBA from './components/pricebook/hba'
import CreatePriceType from './components/price/createpricetype'
import Laytime from './components/order/laytime'
import Suplierlaytime from './components/order/supplierlaytime'
import ContactEditview from './components/contact/contacteditview'
import ContractEditview from './components/contract/contracteditview'
import Producteditview from './components/productMaster/producteditview'
import OrderEditview from './components/order/ordereditview'
import RwpayreceiptAccount from './components/accounts/rwpayreceiptAccount'
import PreshipmentEditView from './components/preshipment/editview'
import Apdemo from './components/apdemo'
import Tcfreceivable from './components/tcf/tcfreceivable'
import Tcfreceivabledetails from './components/tcf/tcfreceivabledetails'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,

} from "react-router-dom"; 
import $ from 'jquery'
import { useNavigate } from 'react-router-dom';
import Receivabledetails from './components/order/receivabledetails'
import {AuthProvider, useAuth} from "./components/AuthProvider";
import BuyerList from './components/buyerproduct/buyerlist';
import Opportunityeditview from './components/opportunity/opportunityeditview'
 import Oppshipmenteditview from './components/opportunity/oppshipmenteditview'
import Tcfeditview from './components/tcf/tcfeditview'
import PostreceiveDetail from './components/postshipment/postreceiveDetail'
import PricebookEditView from './components/pricebook/editview'
import ViewContracts from './components/contract/viewContracts'
import ContractEditView from './components/shippment/editview'
import PostreceiptDetail from './components/postshipment/postreceiptDetail'
import Quoteshipmentdetail from './components/quoteshipment/quoteshipmentdetail'
import SelectQuoteproduct from './components/quoteproduct/selectQuoteproduct'
import ContractShippmentDetailsLongterm from './components/shippment/contractShippmentDetailsLongterm'
import ContractShippmentDetailsLongterms from './components/shippment/contractShippmentDetailsLongterms'
import ViewContractProduct from './components/contractproduct/viewContractProduct';
import Tcfreceipt from './components/tcf/tcfreceipt'
function App() {
  const { token, login, } = useAuth();


  // // // const [authenticated, setAuthenticated] = useState(false);
  // const navigate = useNavigate();
  // const location = useLocation();



  // useEffect(() => {
  //   const authenticated = Boolean(localStorage.getItem('access_token'));
  //   console.log(authenticated, token)

  //   if (!authenticated && !token) {
  //     console.log('Not authenticated. Redirecting to login...');
  //     if (location.pathname !== '/login') {
  //       navigate('/login');
  //     }
  //   }
  // }, [navigate, location.pathname]);
 


  // const isAuthenticated = localStorage.getItem("access_token");


  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    const isAuthenticated = localStorage.getItem('access_token');

    if (!isAuthenticated && location.pathname !== '/login') {
      console.log('Not authenticated. Redirecting to login...');
      localStorage.clear()
      navigate('/login');
    }
  }, [navigate, location.pathname]);




  const isAuthenticated = localStorage.getItem('access_token');





  // useEffect(() => {
  //   const checkAuthentication = () => {
  //     const isAuthenticated = localStorage.getItem('access_token');


  //     console.log(isAuthenticated);

  //     if (!isAuthenticated && location.pathname !== '/login') {
  //       console.log('Redirecting to login');
  //       localStorage.removeItem('access_token');
  //       navigate('/login');
  //     }
  //   };

  //   checkAuthentication();
  // }, [token, navigate, location.pathname]);

  // useEffect(() => {
  //   if (!token && location.pathname !== '/login') {
  //     console.log('Redirecting to login');
  //     navigate('/login');
  //   }
  // }, [token, navigate, location.pathname])



 

  return ( 
    <AuthProvider> 
    <Routes>
        <Route
          
          path="/"
          element={isAuthenticated ? <Dashboard /> : <Login />}
          isAuthenticated={isAuthenticated}
        />
        <Route
          path="/login"
          isAuthenticated={isAuthenticated}
          element={
            isAuthenticated ? (
              <Navigate
                to={{
                  pathname: location.state?.from || "/",
                  state: { from: location.pathname },
                }}
              />
            ) : (
              <Login/>
            )
          }
        />
        
        
        <Route
        
        path="/rwood-trading"
        element={isAuthenticated ? <Header /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
       
       <Route
        
        path="/finance"
        element={isAuthenticated ? < HeaderFinanace /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      <Route
        
        path="/rwood-finance"
        element={isAuthenticated ? < Menu /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      <Route
        
        path="/dashboard-finance"
        element={isAuthenticated ? < DashboardFinance /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
   <Route
        
        path="/pricebook/hba"
        element={isAuthenticated ? < HBA /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
  
     
        <Route
        
        path="/account/addaccount"
        element={isAuthenticated ? <Addaccount /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      <Route
        
        path="/all-quotes"
        element={isAuthenticated ? <AllQuotes /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      <Route
        
        path="/quotes-list"
        element={isAuthenticated ? <QuotesList /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      <Route
        
        path="/account-list"
        element={isAuthenticated ? <Accountlist /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/account/add-new-account"
        element={isAuthenticated ? <Addaccount /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/account/contacts/:id"
        element={isAuthenticated ? <Contactus /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/order/edit-order/:id"
        element={isAuthenticated ? <EditOrder /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
         <Route
        
        path="/account/add-shipment/:id"
        element={isAuthenticated ? <Shipment /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       
      
      <Route
        
        path="/account/price"
        element={isAuthenticated ? <Price /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/opp-shipment-list"
        element={isAuthenticated ? <OppShipmentList /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      
      <Route
        
        path="/add-product-master"
        element={isAuthenticated ? <AddProduct /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       <Route
        
        path="/tcf-list"
        element={isAuthenticated ? <AllTcf /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       <Route
        
        path="/tcf/add-tcf"
        element={isAuthenticated ? <AddTcfs /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  <Route
        
        path="/tcf/edit-tcf/:id"
        element={isAuthenticated ? <EditTcf /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       
      <Route path="/reset-password" element={isAuthenticated ? '' : <ResetPassword />} />
      <Route path="/reset-password/:token" element={isAuthenticated ? '' : <ResetConfirm />} />

      <Route
        
        path="/product-master-list"
        element={isAuthenticated ? <ProductMaster /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      {/* <Route
        
        path="/add-opportunity"
        element={isAuthenticated ? <AddOppurtunity /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  */}
      {/* <Route
        
        path="/opportunity"
        element={isAuthenticated ? <Oppourtunity /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  */}

<Route
        
        path="/reports"
        element={isAuthenticated ? <Report /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

      <Route
        
        path="/add-shipment"
        element={isAuthenticated ? <Shipment /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/quotes/:id"
        element={isAuthenticated ? <Quotes/> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       <Route
        
        path="/dashboard"
        element={isAuthenticated ? <Dashboard/> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       <Route
        
        path="/quoteproduct/edit-quote-product/:id"
        element={isAuthenticated ? <EditQuoteProduct/> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
        <Route
        
        path="/hba/hba-edit/"
        element={isAuthenticated ? <HbaUpdate/> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

      
      <Route
        
        path="/pricebook/add-pricebook"
        element={isAuthenticated ? <AddPricebook/> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      {/* newly added */}
      <Route
        
        path="/accounts/add-record"
        element={isAuthenticated ? <AddRecord/> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       
      <Route
        index
        path="/accounts/account-list"
        element={isAuthenticated ? <AccountsList/> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        index
        path="/accounts/:id"
        element={isAuthenticated ? <AccountViewPage/> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

      
<Route
        index
        path="/product/product-list"
        element={isAuthenticated ? <ProductList/> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

<Route
        index
        path="/product/product-detail/:id"
        element={isAuthenticated ? <ProductDetail/> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 


      
      <Route
        
        path="/contact/add-contact"
        element={isAuthenticated ? <AddContacts/> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/contact/contact-list"
        element={isAuthenticated ? <Contacts/> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      
      <Route
      
      path="/contact/contact"
      element={isAuthenticated ? <ContactLists/> : <Login />}
      isAuthenticated={isAuthenticated}
    /> 
       <Route
      
      path="/pricebook/pricebook-list"
      element={isAuthenticated ? <PriceBooklist/> : <Login />}
      isAuthenticated={isAuthenticated}
    /> 
     <Route
      
      path="/user/add-user"
      element={isAuthenticated ? <AddRwoodusers/> : <Login />}
      isAuthenticated={isAuthenticated}
    /> 
    <Route
      
      path="/user/userlist"
      element={isAuthenticated ? <UserList/> : <Login />}
      isAuthenticated={isAuthenticated}
    /> 
     <Route
      
      path="/company/add-company"
      element={isAuthenticated ? <AddRwoodCompany/> : <Login />}
      isAuthenticated={isAuthenticated}
    /> 
       <Route
      
      path="/company/company-list"
      element={isAuthenticated ? <RwoodCompanyLists/> : <Login />}
      isAuthenticated={isAuthenticated}
    /> 
     <Route
      
      path="price/add-price-type"
      element={isAuthenticated ? <AddPrice/> : <Login />}
      isAuthenticated={isAuthenticated}
    /> 
    <Route
      
      path="/pricebook/update-price-book/:id"
      element={isAuthenticated ? <UpdatePriceBook/> : <Login />}
      isAuthenticated={isAuthenticated}
    /> 
      <Route
        
        path="/price/price-list"
        element={isAuthenticated ? <PriceLists /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/contract/add-contract"
        element={isAuthenticated ? <AddCotract /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       <Route
        
        path="/contract/contract-list"
        element={isAuthenticated ? <ContractList /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="opportunity/add-opportunity"
        element={isAuthenticated ? <AddOpportunitys /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

<Route
        
        path="quote-product/select/:id"
        element={isAuthenticated ? <SelectQuoteproduct /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 


      <Route
        
        path="opportunity/add-record"
        element={isAuthenticated ? <AddRecords /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="opportunity/add-quote/:id"
        element={isAuthenticated ? <AddQuotes /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="opportunity/add-opp-shipment/:id"
        element={isAuthenticated ? <AddoppShippment /> : <Login />}
        isAuthenticated={isAuthenticated}
      />

   
<Route
        
        path="/opp-shipment/new"
        element={isAuthenticated ? <OppShipmentNew /> : <Login />}
        isAuthenticated={isAuthenticated}
      />   
      <Route
        
        path="/opportunity/buyer-supplier"
        element={isAuthenticated ? <BuyerSupplier /> : <Login />}
        isAuthenticated={isAuthenticated}
      />   
       
       {/* <Route
        
        path="/preshipment/preshipment-details/:id"
        element={isAuthenticated ? <AddoppShippment /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      PreshipmentDetails */}
      
       <Route
        
        path="/accounts/account-view"
        element={isAuthenticated ? <AccountsView /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/accounts/create-new-view"
        element={isAuthenticated ? <AccountCreateView /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/opportunity/create-new-view"
        element={isAuthenticated ? <CreateviewOpportunity /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      
      <Route
        
        path="/bank/add-bank"
        element={isAuthenticated ? <AddBank /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
       <Route
        
        path="bank/bank-list"
        element={isAuthenticated ? <BankLists /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      
      <Route
        
        path="/opportunity/opportunity-list"
        element={isAuthenticated ? <OppourtunityList /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
     
      <Route
        
        path="/opportunity/opportunity-details/:id"
        element={isAuthenticated ? <OpportunityDetails /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
 <Route
        
        path="/contract/shipment-by-contract/:id"
        element={isAuthenticated ? <Addcontractshipment /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

<Route
        
        path="/contract-shipment/product-details/:id"
        element={isAuthenticated ? <ProductDetails /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 


      <Route
        
        path="/shippment/add-contract-shippment"
        element={isAuthenticated ? <ContractShippment /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/shippment/contract-shippment-list"
        element={isAuthenticated ? <ContractShippmentList /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/shipment/contract-shipment-details/:id"
        element={isAuthenticated ? <ContractShippmentDetails /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/shippment/contract-shippment-details-lists/:id"
        element={isAuthenticated ? <ContractShippmentDetailsList /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      
      <Route
        
        path="/contract/contract-by-opportunitys/:id"
        element={isAuthenticated ? <Addopportunitycontracts /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      
      
      <Route
        
        path="/opportunity/opp-shipment-list"
        element={isAuthenticated ? <OppShipmentLists /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      
      <Route
        
        path="/product-master/add"
        element={isAuthenticated ? <NewProduct /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/tcf/tcf-list"
        element={isAuthenticated ? <TcfList /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      
      <Route
        
        path="/tcf/tcf-list"
        element={isAuthenticated ? <TcfList /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
        <Route
        
        path="/postshipment/postshipment-list"
        element={isAuthenticated ? <PostShipmentList /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      
      <Route
        
        path="/order/order-by-postshipment/:id"
        element={isAuthenticated ? <Addorderpostshipment /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/postshipment/postshipment-details/:id"
        element={isAuthenticated ? <ViewPost /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
 <Route
        
        path="/contract-shipment/edit-view"
        element={isAuthenticated ? <ContractEditView /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

<Route
        
        path="/bank/create-view"
        element={isAuthenticated ? <BankCreateView /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 



 <Route 
        
        path="/pre-shipment/edit-view"
        element={isAuthenticated ? <PreshipmentEditView /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 



       <Route
        
        path="/postshipment/payable-receipt-list"
        element={isAuthenticated ? <PayReceiptList /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      
      <Route
        
        path="/order/rw-payable/:id"
        element={isAuthenticated ? <RwpayDetails /> : <Login />}
        isAuthenticated={isAuthenticated}
      />

<Route
        
        path="/post/rw-payable/:id"
        element={isAuthenticated ? <Postrwpaydetails /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      <Route
        
        path="/post/pay-receipt/:id"
        element={isAuthenticated ? <Payreceiptdetails /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      
      
      <Route 
        path="/contact/contact-details/:id"
        element={isAuthenticated ? <ContactViewPage /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
 
 <Route 
        path="/price-type/create-price-type"
        element={isAuthenticated ? <CreatePriceType /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route 
        path="/bank/edit-view"
        element={isAuthenticated ? <BankEditView /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       <Route 
        path="/price-type/edit-view"
        element={isAuthenticated ? <PriceEditView /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
 
         <Route
        
        path="/opportunity/oppshipment-detail/:id"
        element={isAuthenticated ? <OpportunityShipmentDetail /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  

<Route
        
        path="/opportunity/create-view-list"
        element={isAuthenticated ? <CreateviewList /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
      <Route
        
        path="/opportunity/edit-view"
        element={isAuthenticated ? <Opportunityeditview /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  

<Route
        
        path="/buyer-product/create-view"
        element={isAuthenticated ? <Createviewbuyer /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

      <Route
        
        path="/pricebook/create-view"
        element={isAuthenticated ? <Createviewprice /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  

<Route
        
        path="/pricebook/create-view-list"
        element={isAuthenticated ? <Createpricebooklist /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
      
      
<Route
        
        path="/contract/create-view"
        element={isAuthenticated ? <CreateviewContract /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  


        <Route
        
        path="/opportunity/opportunity-detail/:id"
        element={isAuthenticated ? <ViewOpportunity /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
       <Route
        
        path="/opportunity/quote-detail/:id"
        element={isAuthenticated ? <ViewQuote /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
      <Route
        
        path="/order/add-order"
        element={isAuthenticated ? <AddOrder /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/order/order-list"
        element={isAuthenticated ? <OrderList /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
        <Route
        
        path="/productmaster/product-detail/:id"
        element={isAuthenticated ? <ProductDetailPage /> : <Login />}
        isAuthenticated={isAuthenticated}
      />    <Route
        
        path="/opportunity/quotes-details/:id"
        element={isAuthenticated ? <QuotesView /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
        <Route
        
        path="/order/order-receivable-details/:id"
        element={isAuthenticated ? <OrderReceivable /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
        <Route
        
        path="/tcf/receivable-details/:id"
        element={isAuthenticated ? <Tcfreceivable /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       <Route
        
        path="/tcf/receivable-lineitem/:id"
        element={isAuthenticated ? <Tcfreceivabledetails /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 




      <Route
        
        path="/order/order-receipt-details/:id"
        element={isAuthenticated ? <Orderreceipt /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/tcf/tcf-receipt-details/:id"
        element={isAuthenticated ? <Tcfreceipt /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 



 <Route
        
        path="/order/order-receipt/:id"
        element={isAuthenticated ? <Rwpayreceipt /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

      <Route
        
        path="/contract/contract-detail/:id"
        element={isAuthenticated ? <ViewContract /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
      <Route
        
        path="/pricebook/pricebook-details/:id"
        element={isAuthenticated ? <ViewPricebook /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
  <Route
        
        path="/account/counter-party/:id"
        element={isAuthenticated ? <CounterParty /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  


      <Route
        
        path="/bank/bank-details/:id"
        element={isAuthenticated ? <ViewBank /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/order/view-order-details/:id"
        element={isAuthenticated ? <ViewOrder /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/laytime/add-laytime"
        element={isAuthenticated ? <AddLaytime /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

<Route
        
        path="/order/suplier-laytime"
        element={isAuthenticated ? <Suplierlaytime /> : <Login />}
        isAuthenticated={isAuthenticated}
      />

<Route
        
        path="/contract/contract-details/:id"
        element={isAuthenticated ? <ViewContracts /> : <Login />}
        isAuthenticated={isAuthenticated}
      />





      <Route
        
        path="/pricebook/editview"
        element={isAuthenticated ? <PricebookEditView /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
        <Route
        
        path="/quotes/quote-edit-view"
        element={isAuthenticated ? <PricebookEditView /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 


      <Route
        
        path="/tcf/tcf-details/:id"
        element={isAuthenticated ? <ViewTcf /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
<Route
        
        path="/opportunity-shipment/create-view"
        element={isAuthenticated ? <Createviewoppshipment /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

<Route
        
        path="/quote-shipment/detail/:id"
        element={isAuthenticated ? <Quoteshipmentdetail /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 


      <Route
        
        path="/opportunity-shipment/create-view-list"
        element={isAuthenticated ? <Createoppshipmentlist /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/contract-shipment/create-new-view"
        element={isAuthenticated ? <Createviewcontractshipment /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       <Route
        
        path="/contract-shipment/create-view-list"
        element={isAuthenticated ? <CreateContractshipmentlist /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
        <Route
        
        path="/post-shipment/create-view"
        element={isAuthenticated ? <Createviewpostshipment /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

<Route
        
        path="/post-shipment/create-view-list"
        element={isAuthenticated ? <Createpostshipmentlist /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      <Route
        
        path="/pre-shipment/create-view"
        element={isAuthenticated ? <Createviewpreshipment /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/pre-shipment/create-view-list"
        element={isAuthenticated ? <Createpreshipmentlist /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

<Route
        
        path="/quote-product/list"
        element={isAuthenticated ? <QuoteProductList /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

<Route
        
        path="/product-master/create-view"
        element={isAuthenticated ? <Createviewproductmaster /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/product-master/edit-view"
        element={isAuthenticated ? <Producteditview /> : <Login />}
        isAuthenticated={isAuthenticated}
      />


      <Route
        
        path="/user/edit-user/:id"
        element={isAuthenticated ? <EditUser /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      <Route
        
        path="/contract/edit-contract/:id"
        element={isAuthenticated ? <EditContract /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
      <Route
        
        path="/preshipment/list"
        element={isAuthenticated ? <PreshipmentList /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
  <Route
        
        path="/preshipment/add-preshipment/:id"
        element={isAuthenticated ? <Addpreshipment /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
       <Route
        
        path="/user/create-view"
        element={isAuthenticated ? <Usercreateview /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      
 <Route
        
        path="/postshipment/add-postshipment/:id"
        element={isAuthenticated ? <AddPostshipment /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      <Route
        
        path="/payable/receive/:id"
        element={isAuthenticated ? <PayReceive /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      <Route
        
        path="/payable/receive-details/:id"
        element={isAuthenticated ? <Payreceivedetails /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
<Route
        
        path="/contract/add/:id"
        element={isAuthenticated ? <AddProductShipment /> : <Login />}
        isAuthenticated={isAuthenticated}
      />


       <Route
        
        path="/contract-clasue/lists"
        element={isAuthenticated ? <ContractClauseList /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  <Route
        
        path="/test/test"
        element={isAuthenticated ? <Testdata /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  <Route
        
        path="/post-shipment/add/:id"
        element={isAuthenticated ? <Addpostshipment /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
      <Route
        
        path="/accounts/add-preshipment/:id"
        element={isAuthenticated ? <AddPreshipment /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       
       <Route
        
        path="/contract-product/details/:id"
        element={isAuthenticated ? <ViewContractProduct /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/recentitem/recentitems"
        element={isAuthenticated ? <RecentItem /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      
      
      <Route
        
        path="/laytime/lists"
        element={isAuthenticated ? <LaytimeLists /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
       <Route
        
        path="/contract-clasue/add"
        element={isAuthenticated ? <AddcontractClasue /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
       <Route
        
        path="/opportunity/create-new-view"
        element={isAuthenticated ? <OppCreateView /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
      <Route
        
        path="/opportunity/opportunity-view"
        element={isAuthenticated ? <OppCreateNewView /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
      
       <Route

        
        path="/productmaster/create-new-view"
        element={isAuthenticated ? <ProductCreateView /> : <Login />}
        isAuthenticated={isAuthenticated}
      />   
       <Route
        
        path="/quoteproduct/add-quote-product/:id"
        element={isAuthenticated ? <AddQuoteProduct /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       <Route
        
        path="/accounts/edit-view/"
        element={isAuthenticated ? <EditView /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

<Route
        
        path="/order/order-by-tcf/:id"
        element={isAuthenticated ? <AddOrderTcf /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      
      <Route
        
        path="/quote-product/quote-product-details/:id"
        element={isAuthenticated ? <QuoteProductView /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       <Route
        
        path="/buyer/add-buyer-product"
        element={isAuthenticated ? <AddBuyerProduct /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
      <Route
        
        path="/productmaster/create-view-list"
        element={isAuthenticated ? <Createproductmasterlist /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

      <Route
        
        path="/contact/edit-view"
        element={isAuthenticated ? <ContactEditview /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
{/* edit view */}




<Route
        
        path="/contract/edit-view"
        element={isAuthenticated ? <ContractEditview /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  

 <Route
        
        path="/product/add-product"
        element={isAuthenticated ? <AddProducts /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

<Route
        
        path="/quote/create-view"
        element={isAuthenticated ? <Quotecreateview /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

  <Route
        
        path="/buyer/product-buyer/:id"
        element={isAuthenticated ? <AddbuyerInner /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
        <Route
        
        path="/price/price-type-details/:id"
        element={isAuthenticated ? <PriceType /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
 
       <Route
        
        path="/buyer/buyer-list"
        element={isAuthenticated ? <BuyerList /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
      
      <Route
        
        path="/contact/edit-contact/:id"
        element={isAuthenticated ? <EditContact /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       <Route
        
        path="/contact/create-new-view"
        element={isAuthenticated ? <ContactCreateView /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
       <Route
        
        path="/contract/create-contract-list"
        element={isAuthenticated ? <CreateContractlist /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
       <Route
        
        path="/order/create-view"
        element={isAuthenticated ? <CreateviewOrder /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
       <Route
        
        path="/order/create-order-list"
        element={isAuthenticated ? <CreateOrderList /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
 <Route
        
        path="/order/order-edit"
        element={isAuthenticated ? <OrderEditview /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       <Route
        
        path="/search/search-result"
        element={isAuthenticated ? <Search location={location}/> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

<Route
        
        path="/tcf/tcf-view-edit"
        element={isAuthenticated ? <Tcfeditview /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 




<Route
        
        path="/tcf/create-new-view"
        element={isAuthenticated ? <CreateviewTcf /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

      <Route
        
        path="/tcf/create-view-list"
        element={isAuthenticated ? <CreatetcfList /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  

       
      <Route
        
        path="/company/company-details/:id"
        element={isAuthenticated ? <ViewCompany /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      <Route
        
        path="/company/edit-company/:id"
        element={isAuthenticated ? <EditCompany /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      <Route
        
        path="/bank/edit/:id"
        element={isAuthenticated ? <EditBank /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
       <Route
        
        path="/postshipment/excel/:id"
        element={isAuthenticated ? <Excel /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      <Route
        
        path="/user/user-details/:id"
        element={isAuthenticated ? <ViewUser /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
       <Route
        
        path="/accounts/edit-account/:id"
        element={isAuthenticated ? <UpdateAccount /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
          <Route
        
        path="/accounts/edit-account/:id"
        element={isAuthenticated ? <UpdateAccount /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  
       
       {/* <Route
        
        path="/contract/contract-view"
        element={isAuthenticated ? <ContractView /> : <Login />}
        isAuthenticated={isAuthenticated}
      />   */}
      <Route
       
       path="/opportunity/oppotunity-create-view"
       element={isAuthenticated ? <OppCreateView /> : <Login />}
       isAuthenticated={isAuthenticated}
     />  
       
       <Route
       
       path="/account/receivable/:id"
       element={isAuthenticated ? <ReceivableDetails /> : <Login />}
       isAuthenticated={isAuthenticated}
     />  
      <Route
       
       path="/account/receipt/:id"
       element={isAuthenticated ? <ReceiptDetails /> : <Login />}
       isAuthenticated={isAuthenticated}
     /> 
      <Route
       
       path="/postshipment/receivable-details/:id"
       element={isAuthenticated ? <PostreceivableDetails /> : <Login />}
       isAuthenticated={isAuthenticated}
     />  
     
      <Route
       
       path="/account/receipt-lineitem/:id"
       element={isAuthenticated ? <RwpayreceiptAccount /> : <Login />}
       isAuthenticated={isAuthenticated}
     />  
      <Route
       
       path="/account/receiptlineitems/:id"
       element={isAuthenticated ? <ReceiptsDetails /> : <Login />}
       isAuthenticated={isAuthenticated}
     />  
      <Route
       
       path="/order/receipt-details/:id"
       element={isAuthenticated ? <Receiptdetails /> : <Login />}
       isAuthenticated={isAuthenticated}
     />  
     <Route
       
       path="/order/receivable-details/:id"
       element={isAuthenticated ? <Receivabledetails /> : <Login />}
       isAuthenticated={isAuthenticated}
     />  
     
     
     
       <Route
       
       path="/opportunity/quote-view/:id"
       element={isAuthenticated ? <AllQuoteView /> : <Login />}
       isAuthenticated={isAuthenticated}
     />  
       <Route
       
       path="/quotes/quote-product"
       element={isAuthenticated ? <AddQuoteproduct /> : <Login />}
       isAuthenticated={isAuthenticated}
     />  

<Route
       
       path="/contract-shipment/detail/:id"
       element={isAuthenticated ? <ContractShippmentDetailsLongterm /> : <Login />}
       isAuthenticated={isAuthenticated}
     />  

<Route
       
       path="/contractshipment/details/:id"
       element={isAuthenticated ? <ContractShippmentDetailsLongterms /> : <Login />}
       isAuthenticated={isAuthenticated}
     /> 

 
      <Route
       
       path="/contract-shipment/new-order/:id"
       element={isAuthenticated ? <ShipmentOrder /> : <Login />}
       isAuthenticated={isAuthenticated}
     />  


      <Route
       
       path="/quote/quote-list"
       element={isAuthenticated ? <QuoteList /> : <Login />}
       isAuthenticated={isAuthenticated}
     />  
     <Route
       
       path="/buyer/buyer-view/:id"
       element={isAuthenticated ? <BuyerView /> : <Login />}
       isAuthenticated={isAuthenticated}
     />  
      <Route
       
       path="/postshipment/payable-receipt-details/:id"
       element={isAuthenticated ? <Payablereceiptdetails /> : <Login />}
       isAuthenticated={isAuthenticated}
     /> 
      <Route
       
       path="/account/receivable-lineitem/:id"
       element={isAuthenticated ? <Accountreceivable /> : <Login />}
       isAuthenticated={isAuthenticated}
     /> 
     <Route
       
       path="/postshipment/receivable-lineitem/:id"
       element={isAuthenticated ? <PostreceiveDetail /> : <Login />}
       isAuthenticated={isAuthenticated}
     /> 
     <Route
       
       path="/postshipment/receipt-lineitem/:id"
       element={isAuthenticated ? <PostreceiptDetail /> : <Login />}
       isAuthenticated={isAuthenticated}
     /> 
     
     <Route
       
       path="/contract/add-document/:id"
       element={isAuthenticated ? <AddDocument /> : <Login />}
       isAuthenticated={isAuthenticated}
     />  
     <Route
       
       path="/contract/document-view/:id"
       element={isAuthenticated ? <DocumentView /> : <Login />}
       isAuthenticated={isAuthenticated}
     />  
     
     <Route
       
       path="/contract/create"
       element={isAuthenticated ? <CreateContract /> : <Login />}
       isAuthenticated={isAuthenticated}
     />  
       
       {/* all inner page */}

       <Route
        
        path="/contact/contact-by-account/:id"
        element={isAuthenticated ? <AddContactInner /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      <Route
        
        path="/contract/contract-by-tcf/:id"
        element={isAuthenticated ? <Addcontracttcf /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
      <Route
        
        path="/order/add-order-preshipment/:id"
        element={isAuthenticated ? <Addorderpreshipment /> : <Login />}
        isAuthenticated={isAuthenticated}
      />
       
       
      <Route
        
        path="/opportunity/opportunity-by-account/:id"
        element={isAuthenticated ? <AddOpportunityInner /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

<Route
        
        path="/tcf/tcf-by-account/:id"
        element={isAuthenticated ? <AddTcfInner /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
<Route
        
        path="/opp/opportunity-shipment/:id"
        element={isAuthenticated ? <AddoppShippmentInner /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
{/* <Route
        
        path="/test/test1"
        element={isAuthenticated ? <FormTest /> : <Login />}
        isAuthenticated={isAuthenticated}
      />  */}
      <Route
        
        path="/finance/add-finance"
        element={isAuthenticated ? <AddFinance /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
 <Route
        
        path="/finance/finance-details/:id"
        element={isAuthenticated ? <ViewFinanace /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

        <Route
        
        path="/account-master/add"
        element={isAuthenticated ? <AddAccountmaster /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
        <Route
        
        path="/account-master/list"
        element={isAuthenticated ? <AccountmasterList /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/account-master/details/:id"
        element={isAuthenticated ? <ViewaccountMaster /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      
 <Route
        
        path="/order/laytime-details"
        element={isAuthenticated ? <Laytime /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 




        <Route
        
        path="/finance/finance-list"
        element={isAuthenticated ? <FinanceList /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      
      
<Route
        
        path="/account/contract-by-account/:id"
        element={isAuthenticated ? <AddContractInner /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      <Route
        
        path="/contact/contact-by-opportunity/:id"
        element={isAuthenticated ? <AddContactOpportunity /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      
      <Route
        
        path="/opportunity/add-shipment/:id"
        element={isAuthenticated ? <AddoppShippment /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
         <Route
        
        path="/shipment/preshipment-details/:id"
        element={isAuthenticated ? <PreshipmentDetails /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
       <Route
        
        path="/accounts/new-accounts"
        element={isAuthenticated ? <NewAccounts /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 


      <Route
        
        path="/product/product-master-by-tcf/:id"
        element={isAuthenticated ? <Addproductmasterinner /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 
      
      <Route
        
        path="/opportunity-shipment/edit-view"
        element={isAuthenticated ? <Oppshipmenteditview /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

<Route
        
        path="/test/test1"
        element={isAuthenticated ? <Apdemo /> : <Login />}
        isAuthenticated={isAuthenticated}
      /> 

      


      {/* <Route path="/" element={<Login/>}/>
     
      <Route path="/account/addaccount" element={<Addaccount/>}/>
      <Route path="/account/contacts/:id" element={<Contactus/>}/>
      <Route path="/account/contact-list" element={<ContactList/>}/>
      <Route path="/price" element={<Price/>}/> */}
    </Routes> 
    </AuthProvider>
  );
}

export default App;
