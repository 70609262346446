import React, { Component, useState, useEffect, useContext, forwardRef } from "react";
import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import $ from "jquery";
import DatePicker from "react-datepicker";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Popup from "reactjs-popup";
import { API_URL } from '../config';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
const DocumentView = () => {
 
  const params = useParams();
  const id = params.id;
  const [dataDocument,setdataDocument]=useState({})
  const [datas, setDatas] = useState({});
  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/contract_documentsbyID/` + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      setdataDocument(data) 
      setDatas(data)
      console.log(data);
      
    } catch (error) {
      console.error(error);
    }
  };
  //  edit 

  const [data, setData] = useState({}); 
 
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


 const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);
     
  };
  const handleSaveEdit = (e) => {
    e.preventDefault();  
    
    const responseData={
      "list_of_docuemnts":datas.list_of_docuemnts,
      "original_in_no":datas.original_in_no,
      "original_in_words":datas.original_in_words, 
      "copies_in_no":datas.copies_in_no,
      "suffix":datas.suffix, 
     
    };
    console.log(JSON.stringify(responseData))
    
    fetch(`${API_URL}update/contract/documents/${id}`, {  
      method: 'PUT',
      body: JSON.stringify(responseData),
      headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
         
    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log(updatedData) 
        console.log(JSON.stringify(responseData))
        // if(updatedData.msg==='contact updated successfully'){
         
          fetchData();
      // }
       
        setData(updatedData);
        setIsEditMode(false);
      })
      .catch((error) => console.error(error));
  };
  

  return (
    <div id='view-page'> 
    <Header/>
        
                         
             <div className="contactAccount">
    
     <div className="page-header btn-margin" id='add-account'>
     <div>
       <h2 className="main-content-title tx-24 mg-b-5">Document Details</h2>
       <ol className="breadcrumb"> 
         <li className="breadcrumb-item active" aria-current="page">Dashboard / Document Details</li>
       </ol>
     </div>
     
       </div> 
       <div className="tasks">
       <>
       {isEditMode ? (
          
         <>
         
          <p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
     
     
     </p>
     <Row className="mb-3">
        <h4 className='heading'>Information</h4>
        
         

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="list of documents"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="list of documents"
              name="list_of_docuemnts"
              defaultValue={datas.contract_list_of_docs || ""}
              onChange={handleChangeInput}
              />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="list of documents"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="list_of_docuemnts"
                    placeholder="list of documents" 
                    defaultValue={datas.list_of_docuemnts || ""}
                    onChange={handleChangeInput}
                  >
                    <option value="">--None--</option>
                    <option value="Signed Commercial Invoice showing details of calculation">Signed Commercial Invoice showing details of calculation</option>
                    <option value="Clean on board Bills of Lading, marked  Freight payable as per Charter Party ">Clean on board Bills of Lading, marked " Freight payable as per Charter Party "</option>
                    <option value="Certificate of Sampling and Analysis issued by Independent Inspecting Authority">Certificate of Sampling and Analysis issued by Independent Inspecting Authority</option>
                    <option value="Certificate of Weight issued by Independent Inspecting Authority">Certificate of Weight issued by Independent Inspecting Authority</option>
                    <option value="Certificate of Draft Survey issued by Independent Inspecting Authority">Certificate of Draft Survey issued by Independent Inspecting Authority</option>
                    <option value="Certificate of Origin issued by Independent Inspecting Authority">Certificate of Origin issued by Independent Inspecting Authority</option>
                    
                    <option value="Certificate Of Hold Cleanliness issued by Independent Inspecting Authority">Certificate Of Hold Cleanliness issued by Independent Inspecting Authority</option>
                    <option value="Signed Provisional Commercial Invoice in triplicate showing actual results and adjustments if applicable">Signed Provisional Commercial Invoice in triplicate showing actual results and adjustments if applicable</option>
                    <option value="Certificate of Quality issued by Independent Inspecting Authority">Certificate of Quality issued by Independent Inspecting Authority</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="original in no"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="original in no"
              name="original_in_no"
              defaultValue={datas.original_in_no || ""}
              onChange={handleChangeInput}
              />
          </FloatingLabel>
        </Form.Group>
         
        
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="original in words"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="original in words"
              name="original_in_words"
              defaultValue={datas.original_in_word || ""}
              onChange={handleChangeInput}
              />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="copies in no"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="copies in no"
              name="copies_in_no"
              defaultValue={datas.copies_in_no || ""}
              onChange={handleChangeInput}

             />
          </FloatingLabel>
        </Form.Group>
      
        
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Suffix"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Suffix"
              name="suffix"
              defaultValue={datas.suffix || ""}
              onChange={handleChangeInput}
             />
          </FloatingLabel>
        </Form.Group>
        </Row>

        <p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
     
     
     </p>

     </>
       ):(
        <>
   
       <p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleEditClick}>Edit</button>
    <Link to={`/contract/contract-details/${datas.contract_id}`}> <button className="btn btn-primary">Cancel</button></Link>
     
     
     </p>
   <div className='row'>
     <div className="col-md-12" id='adatas'>
     <h4 className="heading">Document Information</h4>
     <hr></hr>
     </div>
   </div>
    
  <table class="table table-bordered account-table tables">
  <tbody>
    <tr>
      <td id="td-right"><span>contract list of docs</span> </td>
      <td>{dataDocument.contract_list_of_docs}</td>
      <td id="td-right"><span>document required name</span></td>
      <td>{dataDocument.document_required_name}
      </td>
      
    </tr>
    <tr>
    <td id="td-right">
        <span>copies no</span>
      </td>
      <td>
        {dataDocument.copies_in_no}
      </td>
      <td id="td-right">
        <span>
        list of documents
          
        </span>
        </td><td>
        {dataDocument.list_of_docuemnts}
      </td>
    </tr>
    <tr>
    <td id="td-right">
        <span>original in no</span>
        </td>
        <td>
         {dataDocument.original_in_no}
      </td>
      <td id="td-right">
        <span>original in word</span>
        </td>
        <td>
         {dataDocument.original_in_word}
      </td>
    </tr>
    <tr>
      
    <td id="td-right">
        <span>suffix</span>
        </td>
        <td>
        
        {dataDocument.suffix}
      </td>
      <td id="td-right">
        
        </td>
        <td>
       
      </td>
      
    </tr>
  </tbody>
</table>
     
</>
       )}
       </>
       </div>
       </div>
       <Footer/>
       </div>
  );
};

export default DocumentView;
