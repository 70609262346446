import React, { Component, useState, useEffect, useContext } from "react";
import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import $ from "jquery"; import Form from 'react-bootstrap/Form';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { parse, format, isValid } from 'date-fns';
import Popup from "reactjs-popup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { API_URL } from '../config';
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
const ViewPost = ({ dataValues }) => {
  const navigate = useNavigate();
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
  const [dataValue, setDataValue] = useState([]);
  const [opp, setOpp] = useState([]);
  const [data, setData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState([]);
  
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // Button state
  useEffect(() => {
    setIsButtonEnabled(true);
  }, []);
  const params = useParams();
  const id = params.id;
  console.log(id)
  const [radio, setRadio] = useState(0);
  const [orderId, setOrderId] = useState({})
  function handleClick(e) {
    const responseData = {
      record_type: radio
    }
    const rtype = responseData.record_type;
    

    navigate("/post/rw-payable/" + id,
      {


        state: { ids: id, recordtype: rtype, order_id: orderId },


      });


  }


  const [lockeds, setLockeds] = useState(null);
  const [actual, setActual] = useState(null);
  const [blconfirm, setBlconfirm] = useState(null);
  const [weight, setSetweight] = useState(null);
  const [hold, setHold] = useState(null);
  const [servey, setServey] = useState(null);
  const [register, setRegister] = useState(null);
  const [safety, setSafety] = useState(null);
  const [insurence, setInsurence] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [received, setReceived] = useState(null);
  const [classificate, setClassification] = useState(null);

  const chek_bok = dataValue.locked;
  const actual_payment = dataValue.actual_payment_received
  const blConfirms = dataValue.bl_confirm
  const weights = dataValue.certificate_of_weight
  const holds = dataValue.certificate_of_hold_cleanliness
  const serveys = dataValue.draft_survey_report
  const registers = dataValue.certificate_of_registry
  const safetys = dataValue.safety_management_certificate
  const insurences = dataValue.insurance_of_certificate
  const invoices = dataValue.checking_of_import_invoice
  const receiveds = dataValue.actual_payment_received
  const classifications = dataValue.classification_certificate
  useEffect(() => {
    setInvoice(invoices)
    setLockeds(chek_bok);
    setActual(actual_payment)
    setBlconfirm(blConfirms)
    setSetweight(weights)
    setHold(holds)
    setServey(serveys)
    setRegister(registers)
    setSafety(safetys)
    setClassification(classifications)
    setInsurence(insurences)
    setReceived(receiveds)
  }, [chek_bok, receiveds, invoices, actual_payment, insurences, safetys, registers, blConfirms, weights, serveys, holds]);

  useEffect(() => {
    console.log("Value of lockeds:", lockeds); // Log lockeds
  }, [lockeds, actual, blconfirm, weight, hold, servey, register, safety, insurence, invoice, received]);


  const [isSubmitted, setIsSubmitted] = useState(false);  



  const handleCheckboxActive0 = (e) => {
    setLockeds(e.target.checked);
  };


  const handleCheckboxActive1 = (e) => {
    setActual(e.target.checked);
  };
  const handleCheckboxActive2 = (e) => {
    setBlconfirm(e.target.checked);
  };

  const handleCheckboxActive3 = (e) => {
    setSetweight(e.target.checked);
  };

  const handleCheckboxActive4 = (e) => {
    setHold(e.target.checked);
  };
  const handleCheckboxActive5 = (e) => {
    setServey(e.target.checked);
  };
  const handleCheckboxActive6 = (e) => {
    setRegister(e.target.checked);
  };
  const handleCheckboxClassification = (e) => {
    setClassification(e.target.checked);
  };

  
  const handleCheckboxActive7 = (e) => {
    setSafety(e.target.checked);
  };
  const handleCheckboxActive8 = (e) => {
    setInsurence(e.target.checked);
  };
  const handleCheckboxActive9 = (e) => {
    setInvoice(e.target.checked);
  };
  const handleCheckboxActive10 = (e) => {
    setReceived(e.target.checked);
  };




  // receipt
  function handleClickReceipt(e) {
    const responseData = {
      record_type: radio
    }
    const rtype = responseData.record_type;


    navigate("/post/pay-receipt/" + id,
      {


        state: { ids: id, recordtype: rtype, order_id: orderId },


      });


  }



  const [history, setHistory] = useState([])
  function handleChange(e) {
    const { nodeName, value } = e.target;
    if (nodeName === 'INPUT') {
      setRadio(value);
    }
  }

  // receipt
  function handleChangeRecipt(e) {
    const { nodeName, value } = e.target;
    if (nodeName === 'INPUT') {
      setRadio(value);
    }
  }

  const [payrecieve, setpay_recieve] = useState([])
  const [payreceipt, setpayreceipt] = useState([])
  const [blsentDate, setBlsentDate] = useState('')
  const [blsentDate1, setBlsentDate1] = useState('')
  const [blsentDate2, setBlsentDate2] = useState('')
  const [blsentDate3, setBlsentDate3] = useState('')
  const [blsentDate4, setBlsentDate4] = useState('')
  const [blsentDate5, setBlsentDate5] = useState('')
  const [blsentDate6, setBlsentDate6] = useState('')
  const [blsentDate7, setBlsentDate7] = useState('')
  const [blsentDate8, setBlsentDate8] = useState('')
  const [blsentDate9, setBlsentDate9] = useState('')
  const [blsentDate10, setBlsentDate10] = useState('')
  const [blsentDate11, setBlsentDate11] = useState('')
  const [blsentDate12, setBlsentDate12] = useState('')
  const [blsentDate13, setBlsentDate13] = useState('')
  const [blsentDate14, setBlsentDate14] = useState('')
  const [blsentDate15, setBlsentDate15] = useState('')
  const [blsentDate16, setBlsentDate16] = useState('')
  const [blsentDate17, setBlsentDate17] = useState('')
  const [blsentDate18, setBlsentDate18] = useState('')
  const [blsentDate19, setBlsentDate19] = useState('')
  const [blsentDate20, setBlsentDate20] = useState('')
  const [blsentDate21, setBlsentDate21] = useState('')
  const [blsentDate22, setBlsentDate22] = useState('')
  const [blsentDate23, setBlsentDate23] = useState('')
  const [blsentDate24, setBlsentDate24] = useState('')
  const [blsentDateNon, setBlsentDateNon] = useState(null)
  const [blsentDate25, setBlsentDate25] = useState('')
  const [blsentDate26, setBlsentDate26] = useState('')
  const [blsentDate27, setBlsentDate27] = useState('')
  const [blsentDateDiscount, setBlsentDateDiscount] = useState('')
  const [blsentDate28, setBlsentDate28] = useState('')
  const [blsentDate29, setBlsentDate29] = useState('')
  const [blsentDate30, setBlsentDate30] = useState('')
  const [selectAgent, setSelectedAgent] = useState(null);
  const [norResult, setNorResult] = useState('');
  const [selectAgent1, setSelectedAgent1] = useState(null);
  const [norResult1, setNorResult1] = useState('');
  const [selectAgent2, setSelectedAgent2] = useState(null);
  const [norResult2, setNorResult2] = useState('');
  const [selectAgent3, setSelectedAgent3] = useState(null);
  const [norResult3, setNorResult3] = useState('');
  const [selectAgent4, setSelectedAgent4] = useState(null);
  const [norResult4, setNorResult4] = useState('');
  const [selectAgent5, setSelectedAgent5] = useState(null);
  const [norResult5, setNorResult5] = useState('');
  const [selectAgent6, setSelectedAgent6] = useState(null);
  const [selectAgentActual, setSelectedAgentActual] = useState(null);
   
  const [norResult6, setNorResult6] = useState('');
  const [norResultActual, setNorResultActual] = useState('');
  
  const [selectAgent7, setSelectedAgent7] = useState(null);
  const [norResult7, setNorResult7] = useState('');
  const [selectAgent8, setSelectedAgent8] = useState(null);
  const [norResult8, setNorResult8] = useState('');
  const [selectAgent9, setSelectedAgent9] = useState(null);
  const [norResult9, setNorResult9] = useState('');
  const [selectAgent10, setSelectedAgent10] = useState(null);
  const [norResult10, setNorResult10] = useState('');




  const [splitData, setSplitData] = useState('')

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/postship_byID/" + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      console.log(response.data, "postshipment")
      const data = response.data.postshipment_details;
      const dataValues = response.data.postshipment_details[0];

      const data1 = response.data.order_id;
      const historys = response.data.history;
      const pay_recieve = response.data.receive_lineitem;
      const test = response.data
      setpayreceipt(response.data.rw_receipt_lineitem)
      setpay_recieve(pay_recieve)

      setHistory(historys)
      setOrderId(data1);
      setDatas(data);
      setDataValue(dataValues)

      if (dataValues.bl_draft_sent_date_and_time) {
        const blsentdate = dataValues.bl_draft_sent_date_and_time
          ? parse(
            `${dataValues.bl_draft_sent_date_and_time}`,
            'dd/MM/yyyy hh:mm aa',
            new Date()
          )
          : null;
        setSelectedAgent10(blsentdate);
        console.log('Parsed Date:', blsentdate);
      } else {
        setSelectedAgent10(null);
      }

      if(dataValues.bl_date !== null) {
        const blsentdate1 = parse(dataValues.bl_date, 'dd/MM/yyyy', new Date());
        setBlsentDate1(blsentdate1);

      }
      else {
        setBlsentDate1(null)
      }
      if(dataValues.obl_email_recieved_date_and_time !== null) {

        const blsentdate2 = parse(dataValues.obl_email_recieved_date_and_time, 'dd/MM/yyyy hh:mm aa', new Date());
        setBlsentDate3(blsentdate2);

      }
      else {
        setBlsentDate3(new Date())
      }

      if(dataValues.coo_form_govt_recieve_by_email !== null) {

        const blsentdate4 = parse(dataValues.coo_form_govt_recieve_by_email, 'dd/MM/yyyy hh:mm aa', new Date());
        setBlsentDate4(blsentdate4);


      }
      else {
        setBlsentDate4(new Date())
      }



      if(dataValues.shiper_surveyor_coa_date !== null) {

        const blsentdate5 = parse(dataValues.shiper_surveyor_coa_date, 'dd/MM/yyyy hh:mm aa', new Date());
        setBlsentDate5(blsentdate5);


      }
      else {
        setBlsentDate5(new Date())
      }


      if(dataValues.rwood_or_cust_surveyor_coa_date !== null) {
        const blsentdate6 = parse(dataValues.rwood_or_cust_surveyor_coa_date, 'dd/MM/yyyy hh:mm aa', new Date());
        setBlsentDate6(blsentdate6);

      }
      else {
        setBlsentDate6(new Date())
      }



      if(dataValues.addi_surveyor_certification_date !== null) {
        const blsentdate7 = parse(dataValues.addi_surveyor_certification_date, 'dd/MM/yyyy hh:mm aa', new Date());
        setBlsentDate7(blsentdate7);

      }
      else {
        setBlsentDate7(new Date())
      }




      if(dataValues.coo_issuance_date !== null) {
        const blsentdate8 = parse(dataValues.coo_issuance_date, 'dd/MM/yyyy', new Date());
        setBlsentDate8(blsentdate8);

      }
      else {
        setBlsentDate8(null)
      }


      if(dataValues.coo_courier_date !== null) {
        const blsentdate9 = parse(dataValues.coo_courier_date, 'dd/MM/yyyy', new Date());
        setBlsentDate9(blsentdate9);

      }
      else {
        setBlsentDate9(null)
      }


      if(dataValues.supplier_invoice_check_date !== null) {
        const blsentdate10 = parse(dataValues.supplier_invoice_check_date, 'dd/MM/yyyy', new Date());
        setBlsentDate10(blsentdate10);

      }
      else {
        setBlsentDate10(null)
      }

      if(dataValues.submitted_by_shipper_to_shipper_bank_date !== null) {
        const blsentdate11 = parse(dataValues.submitted_by_shipper_to_shipper_bank_date, 'dd/MM/yyyy hh:mm aa', new Date());
        setBlsentDate11(blsentdate11);
      }
      else {
        setBlsentDate11(new Date())
      }


      if(dataValues.scan_bl_rcvd_date !== null) {
        const blsentdate12 = parse(dataValues.scan_bl_rcvd_date, 'dd/MM/yyyy', new Date());
        setBlsentDate12(blsentdate12);
      }
      else {
        setBlsentDate12(null)
      }



      if(dataValues.supplier_bank_to_rwood_bank_sent_date !== null) {
        const blsentdate13 = parse(dataValues.supplier_bank_to_rwood_bank_sent_date, 'dd/MM/yyyy hh:mm aa', new Date());
        setBlsentDate13(blsentdate13);
      }
      else {
        setBlsentDate13(new Date())
      }


      if(dataValues.scan_iia_certs_rcvd_date !== null) {

        const blsentdate14 = parse(dataValues.scan_iia_certs_rcvd_date, 'dd/MM/yyyy', new Date());
        setBlsentDate14(blsentdate14);
      }
      else {
        setBlsentDate14(null)
      }
      if(dataValues.scan_all_original_docs_rcvd_date !== null) {

        const blsentdate15 = parse(dataValues.scan_all_original_docs_rcvd_date, 'dd/MM/yyyy', new Date());
        setBlsentDate15(blsentdate15);
      }
      else {
        setBlsentDate15(null)
      }


      if(dataValues.import_document_rwood_bank_recieved_date !== null) {


        const blsentdate16 = parse(dataValues.import_document_rwood_bank_recieved_date, 'dd/MM/yyyy hh:mm aa', new Date());
        setBlsentDate16(blsentdate16);
      }
      else {
        setBlsentDate16(new Date())
      }



      if(dataValues.org_document_to_buyer_80_percent !== null) {



        const blsentdate17 = parse(dataValues.org_document_to_buyer_80_percent, 'dd/MM/yyyy', new Date());
        setBlsentDate17(blsentdate17);

      }
      else {
        setBlsentDate17(null)
      }


      if(dataValues.arrived_at_discharge_port !== null) {



        const blsentdate18 = parse(dataValues.arrived_at_discharge_port, 'dd/MM/yyyy', new Date());
        setBlsentDate18(blsentdate18);

      }
      else {
        setBlsentDate18(null)
      }


      if(dataValues.org_document_to_buyer_20_percent !== null) {



        const blsentdate19 = parse(dataValues.org_document_to_buyer_20_percent, 'dd/MM/yyyy', new Date());
        setBlsentDate19(blsentdate19);

      }
      else {
        setBlsentDate19(null)
      }





      if(dataValues.commercial_invoice_prepare_date !== null) {



        const blsentdate20 = parse(dataValues.commercial_invoice_prepare_date, 'dd/MM/yyyy', new Date());
        setBlsentDate20(blsentdate20);

      }
      else {
        setBlsentDate20(null)
      }

      if(dataValues.commercial_invoice_confirmation_date_and_time !== null) {



        const blsentdate21 = parse(dataValues.commercial_invoice_confirmation_date_and_time, 'dd/MM/yyyy', new Date());
        setBlsentDate21(blsentdate21);

      }
      else {
        setBlsentDate21(null)
      }



      // if(dataValues.non_negotiable_document_sent_date !== null) {



      //   const blsentdate23 = parse(dataValues.non_negotiable_document_sent_date, 'dd/MM/yyyy', new Date());
      //   setBlsentDate23(blsentdate23);

      // }
      // else {
      //   setBlsentDate23(new Date())
      // }


      // if(dataValues.document_submission_date !== null) {



      //   const blsentdate24 =  parse(dataValues.document_submission_date, 'dd/MM/yyyy hh:mm aa', new Date());
      //   setBlsentDate24(blsentdate24);

      // }
      // else {
      //   setBlsentDate24(new Date())
      // }



      // if(dataValues.rwood_bank_to_buyer_bank_send_date !== null) {



      
      //   const blsentdate25 =  parse(dataValues.rwood_bank_to_buyer_bank_send_date, 'dd/MM/yyyy hh:mm aa', new Date()) ;
      //   setBlsentDate25(blsentdate25);
  

      // }
      // else {
      //   setBlsentDate25(new Date())
      // }
      

      // if(dataValues.rwood_bank_to_buyer_bank_airway_date !== null) {



      
      //   const blsentdate26 = parse(dataValues.rwood_bank_to_buyer_bank_airway_date, 'dd/MM/yyyy hh:mm aa', new Date());
      //   setBlsentDate26(blsentdate26);
  

      // }
      // else {
      //   setBlsentDate26(new Date())
      // }
      

      if(dataValues.commercial_invoice_date !== null) {



      
      
        const blsentdate27 = parse(dataValues.commercial_invoice_date, 'dd/MM/yyyy', new Date());
        setBlsentDate22(blsentdate27);
  

      }
      else {
        setBlsentDate22(null)
      }
      
      // if(dataValues.buyer_bank_document_receive_date !== null) {



      
      
   
      //   const blsentdate28 =parse(dataValues.document_collection_date, 'dd/MM/yyyy hh:mm aa', new Date());
      //   setBlsentDate28(blsentdate28);
  

      // }
      // else {
      //   setBlsentDate28(new Date())
      // }


      // if(dataValues.expected_value_date !== null) {   
      
   
    

      //   const blsentdate29 = parse(dataValues.expected_value_date, 'dd/MM/yyyy', new Date());
      //   setBlsentDate29(blsentdate29);
  

      // }
      // else {
      //   setBlsentDate29(new Date())
      // }



      // if(dataValues.actual_payment_received_date !== null) {   
      
   
    

      //   const blsentdate30 =  parse(dataValues.actual_payment_received_date, 'dd/MM/yyyy', new Date()) ;
      // setBlsentDate30(blsentdate30);
  

      // }
      // else {
      //   setBlsentDate30(new Date())
      // }




    

      // if(dataValues.bl_draft_or_splits_review_confirm_date !== null) {   
      
   
    

      //   const splitDatas =  parse(dataValues.bl_draft_or_splits_review_confirm_date, 'dd/MM/yyyy', new Date());
      // setSplitData(splitDatas)
  

      // }
      // else {
      //   setSplitData(new Date())
      // }


     
      setTimeout(() => {
        $('#history').DataTable();
        $('#payrecieve').DataTable();

        $('#payreceipt').DataTable();
      }, 1000)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dataValues, decryptedAccessToken]);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    const newState = !isChecked;
    setIsChecked(newState);
    console.log("Checkbox is checked:", newState);
  };


  const handlebldate = (date) => {
    setBlsentDate(date);

  };
  const handlebldate1 = (date) => {
    setBlsentDate1(date);
  };
  const handlebldate2 = (date) => {
    setBlsentDate2(date);
  };

  const handlebldate3 = (date) => {
    setBlsentDate3(date);
  };

  const handlebldate4 = (date) => {
    setBlsentDate4(date);
  };
  const handlebldate5 = (date) => {
    setBlsentDate5(date);
  };
  const handlebldate6 = (date) => {
    setBlsentDate6(date);
  };
  const handlebldate7 = (date) => {
    setBlsentDate7(date);
  };
  const handlebldate8 = (date) => {
    setBlsentDate8(date);
  };
  const handlebldate9 = (date) => {
    setBlsentDate9(date);
  };
  const handlebldate10 = (date) => {
    setBlsentDate10(date);
  };
  const handlebldate11 = (date) => {
    setBlsentDate11(date);
  };
  const handlebldate12 = (date) => {
    setBlsentDate12(date);
  };
  const handlebldate13 = (date) => {
    setBlsentDate13(date);
  };
  const handlebldate14 = (date) => {
    setBlsentDate14(date);
  };
  const handlebldate15 = (date) => {
    setBlsentDate15(date);
  };
  const handlebldate16 = (date) => {
    setBlsentDate16(date);
  };
  const handlebldate17 = (date) => {
    setBlsentDate17(date);
  };
  const handlebldate18 = (date) => {
    setBlsentDate18(date);
  };
  const handlebldate19 = (date) => {
    setBlsentDate19(date);
  };

  const handlebldate20 = (date) => {
    setBlsentDate20(date);
  };
  const handlebldate21 = (date) => {
    setBlsentDate21(date);
  };

  const handlebldate22 = (date) => {
    setBlsentDate22(date);
  };


  const handlebldate23 = (date) => {
    setBlsentDate23(date);
  };

  const handlebldate24 = (date) => {
    setBlsentDate24(date);
  };
  const handlebldateNon = (date) => {
    setBlsentDateNon(date);
  };

  const handlebldate25 = (date) => {
    setBlsentDate25(date);
  };

  const handlebldate26 = (date) => {
    setBlsentDate26(date);
  };

  const handlebldate27 = (date) => {
    setBlsentDate27(date);
  };
  const handlebldateDiscount = (date) => {
    setBlsentDateDiscount(date);
  };

  

  const handlebldate28 = (date) => {
    setBlsentDate28(date);
  };

  const handlebldate29 = (date) => {
    setBlsentDate29(date);
  };


  const handlebldate30 = (date) => {
    setBlsentDate30(date);
  };


  const handleSplit = (date) => {
    setSplitData(date);
  };

  if (!datas) {
    return null;
  }

  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    console.log("Previous state:", datas);
    const { name, value } = e.target;
    setDataValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log("New state:", datas);
  };


  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);

  };
 
  
  // datepicker time 

  const handleDateTimeChange = (date) => {
    setSelectedAgent(date); // Set the selected date and time
    // handleSelectAgent(date); // Automatically send date and time when changed
  };

  const formatDateForBackend = (date) => {
    if (!date) return ''; // Return empty string if date is null

    const formattedDate = new Date(date);

    const day = String(formattedDate.getDate()).padStart(2, '0');
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
    const year = formattedDate.getFullYear();
    const hours = String(formattedDate.getHours()).padStart(2, '0');
    const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
    const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

    return formattedDateTime;
  };


  const handleSelectAgent = (dateTime) => {
    const formattedDateTime = formatDateForBackend(dateTime);
    setNorResult(formattedDateTime)
    // console.log('Formatted date and time:', formattedDateTime);    
  };


  const handleDateTimeChange1 = (date) => {
    setSelectedAgent1(date); // Set the selected date and time
    // handleSelectAgent1(date); // Automatically send date and time when changed
  };

  const formatDateForBackend1 = (date) => {
    if (!date) return ''; // Return empty string if date is null

    const formattedDate = new Date(date);

    const day = String(formattedDate.getDate()).padStart(2, '0');
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
    const year = formattedDate.getFullYear();
    const hours = String(formattedDate.getHours()).padStart(2, '0');
    const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
    const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

    return formattedDateTime;
  };


  const handleSelectAgent1 = (dateTime) => {
    const formattedDateTime = formatDateForBackend1(dateTime);
    setNorResult1(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);    
  };


  const handleDateTimeChange2 = (date) => {
    setSelectedAgent2(date); // Set the selected date and time
    // handleSelectAgent2(date); // Automatically send date and time when changed
  };

  const formatDateForBackend2 = (date) => {
    if (!date) return ''; // Return empty string if date is null

    const formattedDate = new Date(date);

    const day = String(formattedDate.getDate()).padStart(2, '0');
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
    const year = formattedDate.getFullYear();
    const hours = String(formattedDate.getHours()).padStart(2, '0');
    const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
    const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

    return formattedDateTime;
  };

  const handleSelectAgent2 = (dateTime) => {
    const formattedDateTime = formatDateForBackend2(dateTime);
    setNorResult2(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);    
  };



  const handleDateTimeChange3 = (date) => {
    setSelectedAgent3(date); // Set the selected date and time
    // handleSelectAgent3(date); // Automatically send date and time when changed
  };

  const formatDateForBackend3 = (date) => {
    if (!date) return ''; // Return empty string if date is null 

    const formattedDate = new Date(date);

    const day = String(formattedDate.getDate()).padStart(2, '0');
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
    const year = formattedDate.getFullYear();
    const hours = String(formattedDate.getHours()).padStart(2, '0');
    const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
    console.log(minutes)
    const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

    return formattedDateTime;
  };


  const handleSelectAgent3 = (dateTime) => {
    const formattedDateTime = formatDateForBackend3(dateTime);
    setNorResult3(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);    
  };





  // Agent Date 

  const handleDateTimeChange4 = (date) => {
    setSelectedAgent4(date); // Set the selected date and time
    // handleSelectAgent4(date); // Automatically send date and time when changed
  };

  const formatDateForBackend4 = (date) => {
    if (!date) return ''; // Return empty string if date is null

    const formattedDate = new Date(date);

    const day = String(formattedDate.getDate()).padStart(2, '0');
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
    const year = formattedDate.getFullYear();
    const hours = String(formattedDate.getHours()).padStart(2, '0');
    const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
    const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

    return formattedDateTime;
  };


  const handleSelectAgent4 = (dateTime) => {
    const formattedDateTime = formatDateForBackend4(dateTime);
    setNorResult4(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);    
  };


  // Agent Date 

  const handleDateTimeChange5 = (date) => {
    setSelectedAgent5(date); // Set the selected date and time
    // handleSelectAgent5(date); // Automatically send date and time when changed
  };

  const formatDateForBackend5 = (date) => {
    if (!date) return ''; // Return empty string if date is null

    const formattedDate = new Date(date);

    const day = String(formattedDate.getDate()).padStart(2, '0');
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
    const year = formattedDate.getFullYear();
    const hours = String(formattedDate.getHours()).padStart(2, '0');
    const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
    const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

    return formattedDateTime;
  };


  const handleSelectAgent5 = (dateTime) => {
    const formattedDateTime = formatDateForBackend5(dateTime);
    setNorResult5(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);    
  };


  // Agent Date 

  const handleDateTimeChange6 = (date) => {
    setSelectedAgent6(date); // Set the selected date and time
    handleSelectAgent6(date); // Automatically send date and time when changed
  };


  const handleDateTimeChangeActual = (date) => {
    setSelectedAgentActual(date); // Set the selected date and time
    handleSelectAgentActual(date); // Automatically send date and time when changed
  };

  const formatDateForBackend6 = (date) => {
    if (!date) return ''; // Return empty string if date is null

    const formattedDate = new Date(date);

    const day = String(formattedDate.getDate()).padStart(2, '0');
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
    const year = formattedDate.getFullYear();
    const hours = String(formattedDate.getHours()).padStart(2, '0');
    const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
    const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

    return formattedDateTime;
  };


  const handleSelectAgent6 = (dateTime) => {
    const formattedDateTime = formatDateForBackend6(dateTime);
    setNorResult6(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);    
  };
  const handleSelectAgentActual = (dateTime) => {
    const formattedDateTime = formatDateForBackend6(dateTime);
    setNorResultActual(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);    
  };
  


  // Agent Date 

  const handleDateTimeChange7 = (date) => {
    setSelectedAgent7(date); // Set the selected date and time
    // handleSelectAgent7(date); // Automatically send date and time when changed
  };

  const formatDateForBackend7 = (date) => {
    if (!date) return ''; // Return empty string if date is null

    const formattedDate = new Date(date);

    const day = String(formattedDate.getDate()).padStart(2, '0');
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
    const year = formattedDate.getFullYear();
    const hours = String(formattedDate.getHours()).padStart(2, '0');
    const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
    const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

    return formattedDateTime;
  };


  const handleSelectAgent7 = (dateTime) => {
    const formattedDateTime = formatDateForBackend7(dateTime);
    setNorResult7(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);    
  };


  // Agent Date 

  const handleDateTimeChange8 = (date) => {
    setSelectedAgent8(date); // Set the selected date and time
    // handleSelectAgent8(date); // Automatically send date and time when changed
  };

  const formatDateForBackend8 = (date) => {
    if (!date) return ''; // Return empty string if date is null

    const formattedDate = new Date(date);

    const day = String(formattedDate.getDate()).padStart(2, '0');
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
    const year = formattedDate.getFullYear();
    const hours = String(formattedDate.getHours()).padStart(2, '0');
    const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
    const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

    return formattedDateTime;
  };


  const handleSelectAgent8 = (dateTime) => {
    const formattedDateTime = formatDateForBackend8(dateTime);
    setNorResult8(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);    
  };

  // Agent Date 

  const handleDateTimeChange10 = (date) => {
    setSelectedAgent10(date); // Set the selected date and time
    // handleSelectAgent10(date);
  };

  const formatDateForBackend10 = (date) => {
    if (!date) return ''; // Return empty string if date is null

    const formattedDate = new Date(date);

    const day = String(formattedDate.getDate()).padStart(2, '0');
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
    const year = formattedDate.getFullYear();
    const hours = String(formattedDate.getHours()).padStart(2, '0');
    const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
    const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

    return formattedDateTime;
  };


  const handleSelectAgent10 = (dateTime) => {
    const formattedDateTime = formatDateForBackend10(dateTime);
    setNorResult10(formattedDateTime)
    console.log(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);    
  };


 
  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", options).format(number);
  };
  
  const renderShipmentQty = (tcfValues) => {
    if (tcfValues > 0) {
      return <span className='sr'>{formatNumber(tcfValues)}</span>;
    } else if (tcfValues < 0) {
      return <span className='sr'>({formatNumber(Math.abs(tcfValues))})</span>;
    } else {
      return <span className='sr'>{formatNumber(tcfValues)}</span>;
    }
  };


  const handleSaveEdit = async (date) => {
    const responseData = {
      "locked":lockeds,
      "bl_draft_sent_date_and_time": selectAgent10 ? format(selectAgent10, 'dd/MM/yyyy hh:mm aa') : null,
      "order": dataValue.order,
      "actual_payment_received_date":selectAgentActual ? format(selectAgentActual, 'dd/MM/yyyy') : null,
      // "non_negotiable_document_sent_date": blsentDate23 ? format(blsentDate23, 'dd/MM/yyyy') : null,
      "currency": dataValue.currency,
      "obl_email_recieved_date_and_time": selectAgent1 ? format(selectAgent1, 'dd/MM/yyyy hh:mm aa') : null,
      "pre_ship_document": dataValue.pre_ship_document,
      "coo_destination": dataValue.coo_destination,
      "bl_draft_or_splits_review_confirm_date": splitData ? format(splitData, 'dd/MM/yyyy') : null,
      "bl_no": dataValue.bl_no,
      "commercial_invoice_prepare_date": blsentDate20 ? format(blsentDate20, 'dd/MM/yyyy') : null,
      "bl_quantity": parseFloat(dataValue.bl_quantity),
      "bl_date": blsentDate1 ? format(blsentDate1, 'dd/MM/yyyy') : null,
      "bl_issue_time_and_date": selectAgent ? format(selectAgent, 'dd/MM/yyyy hh:mm aa') : null,
      "coo_form_govt_recieve_by_email": selectAgent2 ? format(selectAgent2, 'dd/MM/yyyy hh:mm aa') : null,
      "bl_issue_place": dataValue.bl_issue_place,
      "bl_status": dataValue.bl_status,
      "document_collection_date": blsentDate28 ? format(blsentDate28, 'dd/MM/yyyy hh:mm aa') : null,
      "shiper_surveyor_coa_date": selectAgent3 ? format(selectAgent3, 'dd/MM/yyyy hh:mm aa') : null,
      "certificate_of_weight": dataValue.certificate_of_weight,
      "rwood_or_cust_surveyor_coa_date": selectAgent4 ? format(selectAgent4, 'dd/MM/yyyy hh:mm aa') : null,
      "certificate_of_hold_cleanliness": dataValue.certificate_of_hold_cleanliness,
      "net_weight_as_per_document": parseFloat(dataValue.net_weight_as_per_document),
      "draft_survey_report": dataValue.draft_survey_report,
      "addi_surveyor_certification_date": selectAgent5 ? format(selectAgent5, 'dd/MM/yyyy hh:mm aa') : null,
      "certificate_of_registry": dataValue.certificate_of_registry,
      "coo_status": dataValue.coo_status,
      "cow_status": dataValue.cow_status,
      "dsr_status": dataValue.dsr_status,
      "expected_value_date": blsentDate29 ? format(blsentDate29, 'dd/MM/yyyy') : null,
      "buyer_bank_document_receive_date": blsentDate27 ? format(blsentDate27, 'dd/MM/yyyy') : null,
      "rw_margin": parseFloat(dataValue.rw_margin),
      "rwood_bank_to_buyer_bank_airway_date": blsentDate26 ? format(blsentDate26, 'dd/MM/yyyy hh:mm aa') : null,
      "rwood_bank_to_buyer_bank_airway_no": dataValue.rwood_bank_to_buyer_bank_airway_no,
      "rwood_bank_to_buyer_bank_send_date": blsentDate25 ? format(blsentDate25, 'dd/MM/yyyy hh:mm aa') : null,
      "coa_status": dataValue.coa_status,
      "comm_invoice_no":parseFloat(dataValue.comm_invoice_no),
      "checking_of_import_invoice": invoice,
      "supplier_invoice_price": dataValue.supplier_invoice_price,
      "supplier_invoice_check_date": selectAgent6 ? format(selectAgent6, 'dd/MM/yyyy') : null,
      "remark": dataValue.remark,
      "document_submission_date": blsentDate24 ? format(blsentDate24, 'dd/MM/yyyy hh:mm aa') : null,
      "govt_coo_status": dataValue.govt_coo_status,
      "safety_management_certificate": dataValue.safety_management_certificate,
      "coo_issuance_date": blsentDate8 ? format(blsentDate8, 'dd/MM/yyyy') : null,
      "coo_courier_date": blsentDate9 ? format(blsentDate9, 'dd/MM/yyyy') : null,
      "commercial_invoice_date":blsentDate22 ? format(blsentDate22, 'dd/MM/yyyy') : null,
      "coo_courier_tracking_no": dataValue.coo_courier_tracking_no,
      "coo_courier_company": dataValue.coo_courier_company,
      "insurance_of_certificate": dataValue.insurance_of_certificate,
      "reason_for_delay": dataValue.reason_for_delay,
      "classification_certificate": dataValue.classification_certificate,
      "document_discount_date": blsentDateDiscount ? format(blsentDateDiscount, 'dd/MM/yyyy hh:mm aa') : null,
      "submitted_by_shipper_to_shipper_bank_date": selectAgent7 ? format(selectAgent7, 'dd/MM/yyyy hh:mm aa') : null,
      "non_negotiable_document_sent_date": blsentDateNon ? format(blsentDateNon, 'dd/MM/yyyy') : null,
      "rwood_bank_to_buyer_bank_airway_company": dataValue.rwood_bank_to_buyer_bank_airway_company,
      "arrived_at_discharge_port": blsentDate18 ? format(blsentDate18, 'dd/MM/yyyy') : null,
      "import_document_rwood_bank_recieved_date": blsentDate16 ? format(blsentDate16, 'dd/MM/yyyy hh:mm aa') : null,
      "commercial_invoice_no": dataValue.commercial_invoice_no,
      "commercial_invoice_price": dataValue.commercial_invoice_price,
      "scan_all_original_docs_rcvd_date": blsentDate15 ? format(blsentDate15, 'dd/MM/yyyy') : null,
      "scan_bl_rcvd_date": blsentDate12 ? format(blsentDate12, 'dd/MM/yyyy') : null,
      "scan_iia_certs_rcvd_date": blsentDate14 ? format(blsentDate14, 'dd/MM/yyyy') : null,
      "commercial_invoice_confirmation_date_and_time": blsentDate21 ? format(blsentDate21, 'dd/MM/yyyy') : null,
      "supplier_bank_to_rwood_bank_sent_date": selectAgent8 ? format(selectAgent8, 'dd/MM/yyyy hh:mm aa') : null,
      "import_document_airway_company": dataValue.import_document_airway_company,
      "org_document_to_buyer_20_percent": blsentDate19 ? format(blsentDate19, 'dd/MM/yyyy') : null,
      "org_document_to_buyer_80_percent": blsentDate17 ? format(blsentDate17, 'dd/MM/yyyy') : null,
      "import_document_airway_no": dataValue.import_document_airway_no,
      "payment_remark": dataValue.payment_remark
    };
  
    try {
      setIsSubmitted(true);
      console.log(JSON.stringify(responseData));
  
      // Making the PUT request with Axios
      const response = await axios.put(`${API_URL}update/postshipment/${id}`, responseData, {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          'Content-Type': 'application/json',
        },
      });
  
      console.log(response);
  
      if (response.data.success === true) {
        setIsSubmitted(false);
        setTimeout(() => {
          document.getElementById('messageContainer').innerHTML = `
            <div class="success-message">
                ${response.data.msg}
            </div>
          `;
          setTimeout(() => {
            $('#history').DataTable();
            $('#payrecieve').DataTable();
            $('#payreceipt').DataTable(); 
          }, 1000)
          window.scrollTo({
            top: 0,
            behavior: "smooth" // Optional: animated scrolling
          });
  
          fetchData(); // Call your data fetching function
          setData(response.data); // Update state with the new data
          setIsEditMode(false); // Exit edit mode
        }, 1000);
      } else if (response.data.success === false) {
        document.getElementById('messageContainer').innerHTML = `
            <div class="error-message">
                ${response.data.msg}
            </div>
        `;
        window.scrollTo({
          top: 0,
          behavior: "smooth" // Optional: animated scrolling
        });
      }
    } catch (error) {
      console.error(error);
      
      window.scrollTo({
        top: 0,
        behavior: "smooth" // Optional: animated scrolling
      });
    }
  };



  return (
    <div id="view-page">
      <Header />

      <div className="contactAccount postshipments">
        <div className="page-header btn-margin" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">
              Postshipment Details
            </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Postshipment / Postshipment details
              </li>
            </ol>
          </div>
        </div>
        {isEditMode ? (
          <div className="tasks fulid-section order-section">


            <div id="messageContainer"></div>
            <p className='edit-btn'>
            <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value="Save"
      //disabled={isSubmitted}
    />
              <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


            </p>

            <Row className="mb-3">

              <div className='col-md-12'>
                <h4 className='heading'>Information</h4>
              </div>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="RWood Post Shipment Doc No "
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="RWood Post Shipment Doc No "
                    name="post_code_no"
                    defaultValue={dataValue.post_code_no || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>
              <Form.Group as={Col} md="6">
                <label>Locked</label>
                <input
                  type='checkbox'
                  name='locked'
                  checked={lockeds} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive0} // Handle the change event
                  className='mx-3'
                />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    className="error-validation"
                    value={dataValue.currency || ""}
                    onChange={handleChangeInput}
                  >
                    <option value="">Select </option>
                    <option value="USD">USD </option>
                    <option value="IDR">IDR </option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="order"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="order"
                    name="order"
                    defaultValue={dataValue.order || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Pre Shipment Document"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Pre Shipment Document"
                    name="pre_ship_document"
                    defaultValue={dataValue.pre_ship_document || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>

            </Row>
            <Row className="mb-3">

              <div className='col-md-12'>
                <h4 className='heading'>BL Draft/AI COO</h4>
              </div>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="BL draft sent date and time"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={selectAgent10}
                    onChange={handleDateTimeChange10}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    placeholderText="Select Date and Time"
                  />

                </FloatingLabel>

              </Form.Group>


              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="COO destination"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="COO destination"
                    name="coo_destination"
                    defaultValue={dataValue.coo_destination || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>

              <Form.Group as={Col} md="6">

                <input
                  type='checkbox'
                  name='bl_confirm'
                  checked={blconfirm} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive2} // Handle the change event
                  className='mx-3'
                />
                <label>BL Confirm</label>
              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="BL Draft / Splits Review Confirm Date  "
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={splitData}
                    onChange={handleSplit}

                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select Date"
                  />
                </FloatingLabel>

              </Form.Group>


            </Row>
            <Row className="mb-3">

              <div className='col-md-12'>
                <h4 className='heading'>BL Issued</h4>
              </div>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="BL No"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="BL No"
                    name="bl_no"
                    value={dataValue.bl_no}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="BL Quantity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="BL Quantity"
                    name="bl_quantity"
                    defaultValue={dataValue.bl_quantity || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="BL Date"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate1}
                    onChange={handlebldate1}                    
                    dateFormat='dd/MM/yyyy'
                    placeholderText="Select"
                  />
                </FloatingLabel>

              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="OBL Email Received Date n Time"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={selectAgent1}
                    onChange={handleDateTimeChange1}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    placeholderText="Select Date and Time"
                  />


                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="BL Issue Date and Time"
                  className="mb-3 datepick post-date"
                >
                  <DatePicker
                    selected={selectAgent}
                    onChange={handleDateTimeChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    placeholderText="Select Date and Time"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="COO form Govt receive by email "
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={selectAgent2}
                    onChange={handleDateTimeChange2}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    placeholderText="Select Date and Time"
                  />
                </FloatingLabel>

              </Form.Group>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="BL Issue Place "
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="BL Issue Place"
                    name="bl_issue_place"
                    defaultValue={dataValue.bl_issue_place || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>

              <Form.Group
                as={Col}
                md="6"

              ></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="BL status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="bl_status"
                    placeholder="BL status"
                    defaultValue={dataValue.bl_status || ""}
                    onChange={handleChangeInput}
                  >
                    <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>

                  </Form.Select>

                </FloatingLabel>
              </Form.Group>


            </Row>

            <Row className="mb-3">

              <div className='col-md-12'>
                <h4 className='heading'>Additional Surveyors Certificates</h4>
              </div>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shiper Surveyor COA Date   "
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={selectAgent3}
                    onChange={handleDateTimeChange3}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    placeholderText="Select Date and Time"
                  />
                </FloatingLabel>

              </Form.Group>
              <Form.Group as={Col} md="6">

                <input
                  type='checkbox'
                  name='certificate_of_weight'
                  checked={weight} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive3} // Handle the change event
                  className='mx-3'
                />
                <label>Certificate Of Weight
                </label>
              </Form.Group>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood/Cust Surveyor COA Date "
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={selectAgent4}
                    onChange={handleDateTimeChange4}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    placeholderText="Select Date and Time"
                  />
                </FloatingLabel>

              </Form.Group>

              <Form.Group as={Col} md="6">

                <input
                  type='checkbox'
                  name='certificate_of_hold_cleanliness'
                  checked={hold} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive4} // Handle the change event
                  className='mx-3'
                />
                <label>Certificate of hold cleanliness
                </label>
              </Form.Group>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Net Weight As per Document"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Net Weight As per Document"
                    name="net_weight_as_per_document"
                    defaultValue={dataValue.net_weight_as_per_document || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>
              <Form.Group as={Col} md="6">

                <input
                  type='checkbox'
                  name='draft_survey_report'
                  checked={servey} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive5} // Handle the change event
                  className='mx-3'
                />
                <label>Draft Survey Report

                </label>
              </Form.Group>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Addi Surveyor Certificate Date "
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={selectAgent5}
                    onChange={handleDateTimeChange5}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    placeholderText="Select Date and Time"
                  />
                </FloatingLabel>

              </Form.Group>
              <Form.Group as={Col} md="6">

                <input
                  type='checkbox'
                  name='certificate_of_registry'
                  checked={register} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive6} // Handle the change event
                  className='mx-3'
                />
                <label>Certificate of Registry

                </label>
              </Form.Group>
              <Form.Group as={Col} md="6" id='lockeds'></Form.Group>

              <Form.Group as={Col} md="6">

                <input
                  type='checkbox'
                  name='classification_certificate'
                  checked={classificate} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxClassification} // Handle the change event
                  className='mx-3'
                />
                <label>Classification Certificate

                </label>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="COA status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="coa_status"
                    placeholder="COA status"
                    value={dataValue.coa_status || ""}
                    onChange={handleChangeInput}
                  >
                    <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">

                <input
                  type='checkbox'
                  name='safety_management_certificate'
                  checked={safety} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive7} // Handle the change event
                  className='mx-3'
                />
                <label>Safety Management Certificate

                </label>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="COO status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="coo_status"
                    placeholder="COO status"
                    value={dataValue.coo_status || ""}
                    onChange={handleChangeInput}
                  >
                    <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>



              <Form.Group as={Col} md="6">

                <input
                  type='checkbox'
                  name='insurance_of_certificate'
                  checked={insurence} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive8} // Handle the change event
                  className='mx-3'
                />
                <label>insurance of certificate

                </label>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="COW status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="cow_status"
                    placeholder="COW status"
                    value={dataValue.cow_status || ""}
                    onChange={handleChangeInput}
                  >
                    <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="DSR status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="dsr_status"
                    placeholder="DSR status"
                    value={dataValue.dsr_status || ""}
                    onChange={handleChangeInput}
                  >
                    <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Reason for Delay"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Reason for Delay"
                    name="reason_for_delay"
                    defaultValue={dataValue.reason_for_delay || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Govt COO Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="govt_coo_status"
                    placeholder="Govt COO Status"
                    value={dataValue.govt_coo_status || ""}
                    onChange={handleChangeInput}
                  >
                    <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>

            </Row>


            <Row className="mb-3">

              <div className='col-md-12'>
                <h4 className='heading'>COO Issued and Courier information</h4>
              </div>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="COO Issuance Date"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate8}
                    onChange={handlebldate8}                    
                    dateFormat='dd/MM/yyyy'
                    placeholderText="Select"
                  />
                </FloatingLabel>

              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="COO Courier Date"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate9}
                    onChange={handlebldate9}
                    
                    dateFormat='dd/MM/yyyy'
                    placeholderText="Select"
                  />
                </FloatingLabel>

              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="COO Courier Tracking No"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="COO Courier Tracking No"
                    name="coo_courier_tracking_no"
                    defaultValue={dataValue.coo_courier_tracking_no || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="COO Courier Company"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="COO Courier Company"
                    name="coo_courier_company"
                    defaultValue={dataValue.coo_courier_company || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>
            </Row>
            <Row className="mb-3">

              <div className='col-md-12'>
                <h4 className='heading'>Import Invoice</h4>
              </div>


              <Form.Group as={Col} md="6">

                <input
                  type='checkbox'
                  name='checking_of_import_invoice'
                  checked={invoice} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive9} // Handle the change event
                  className='mx-3'
                />
                <label>Checking of Import Invoice

                </label>
              </Form.Group>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Supplier Invoice Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Supplier Invoice Price "
                    name="supplier_invoice_price"
                    defaultValue={dataValue.supplier_invoice_price || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Supplier Invoice Check Date"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={selectAgent6}
                    onChange={handleDateTimeChange6} 
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select Date"
                  />
                </FloatingLabel>

              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6" className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="remark"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="remark"
                    style={{ height: "100px" }}
                    name="remark"
                    defaultValue={dataValue.remark || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>
              </Form.Group>

            </Row>
            <Row className="mb-3">

              <div className='col-md-12'>
                <h4 className='heading'>Document Submitted by Shipper/Supplier
                </h4>

              </div>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Submited by Shiper to Shiper Bank Date "
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={selectAgent7}
                    onChange={handleDateTimeChange7}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    placeholderText="Select Date and Time"
                  />
                </FloatingLabel>

              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Scan BL Rcvd Date"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate12}
                    onChange={handlebldate12}
                    
                    dateFormat='dd/MM/yyyy'
                    placeholderText="Select"
                  />
                </FloatingLabel>

              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Supplier Bank to RWood Bank Sent Date  "
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={selectAgent8}
                    onChange={handleDateTimeChange8}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    placeholderText="Select Date and Time"
                  />
                </FloatingLabel>

              </Form.Group>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Scan IIA Certs Rcvd Date"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate14}
                    onChange={handlebldate14}
                    
                    dateFormat='dd/MM/yyyy'
                    placeholderText="Select"
                  />
                </FloatingLabel>

              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              ></Form.Group>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Scan all Orginal Docs Rcvd Date"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate15}
                    onChange={handlebldate15}
                    
                    dateFormat='dd/MM/yyyy'
                    placeholderText="Select"
                  />
                </FloatingLabel>

              </Form.Group>


              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Import Document RWood Bank recieved Date"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate16}
                    onChange={handlebldate16}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    placeholderText="Select Date and Time"
                  />
                </FloatingLabel>

              </Form.Group>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Import Document Airway No"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Import Document Airway No"
                    name="import_document_airway_no"
                    defaultValue={dataValue.import_document_airway_no || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              > </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Import Document Airway Company"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Import Document Airway Company"
                    name="import_document_airway_company"
                    defaultValue={dataValue.import_document_airway_company || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>

            </Row>

            <Row className="mb-3">

              <div className='col-md-12'>
                <h4 className='heading'>Documents Submitted to Buyer

                </h4>
              </div>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Org Document to Buyer 80%"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate17}
                    onChange={handlebldate17}
                    
                    dateFormat='dd/MM/yyyy'
                    placeholderText="Select"
                  />
                </FloatingLabel>

              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Arrived at Discharge Port"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate18}
                    onChange={handlebldate18}
                    
                    dateFormat='dd/MM/yyyy'
                    placeholderText="Select"
                  />
                </FloatingLabel>

              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Org Document to Buyer 20%"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate19}
                    onChange={handlebldate19}
                    
                    dateFormat='dd/MM/yyyy'
                    placeholderText="Select"
                  />
                </FloatingLabel>

              </Form.Group>

            </Row>

            <Row className="mb-3">

              <div className='col-md-12'>
                <h4 className='heading'>Commercial Invoice


                </h4>
              </div>


              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Commercial Invoice Prepare Date"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate20}
                    onChange={handlebldate20}
                    
                    dateFormat='dd/MM/yyyy'
                    placeholderText="Select"
                  />
                </FloatingLabel>

              </Form.Group>


              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Commercial Invoice Confirmation Date n Time"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate21}
                    onChange={handlebldate21}
                    
                    dateFormat='dd/MM/yyyy'
                    placeholderText="Select"
                  />
                </FloatingLabel>

              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Commercial Invoice No"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Commercial Invoice No"
                    name="commercial_invoice_no"
                    defaultValue={dataValue.commercial_invoice_no || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Commercial Invoice Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Commercial Invoice Price"
                    name="commercial_invoice_price"
                    defaultValue={dataValue.commercial_invoice_price || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>

              <Form.Group
              as={Col}
              md="6"
             
            >
    <FloatingLabel
            controlId="floatingInput"
            label="Comm Invoice Date"
            className="mb-3 post-date"
          >
            <DatePicker
        selected={blsentDate22}
        onChange={handlebldate22}
        
        dateFormat='dd/MM/yyyy'
         placeholderText="Select" 
      />
          </FloatingLabel>
        
            </Form.Group>
            <Form.Group
                as={Col}
                md="6"

              ></Form.Group>
            <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Comm Invoice No"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Comm Invoice No"
                    name="comm_invoice_no"
                    defaultValue={dataValue.comm_invoice_no || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>

            </Row>
            <Row className="mb-3">

<div className='col-md-12'>
  <h4 className='heading'>Non Negotiable Document</h4>

</div>
<Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="NN Document sent Date"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDateNon}
                    onChange={handlebldateNon} 
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select"
                  />
                </FloatingLabel>

              </Form.Group>

</Row>
            <Row className="mb-3">

              <div className='col-md-12'>
                <h4 className='heading'>Rwood Export Document Status</h4>
              </div>


              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document Submission Date"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate24}
                    onChange={handlebldate24}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    placeholderText="Select Date and Time"
                  />
                </FloatingLabel>

              </Form.Group>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood Bank to Buyer Bank Airway No "
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Rwood Bank to Buyer Bank Airway No"
                    name="rwood_bank_to_buyer_bank_airway_no"
                    defaultValue={dataValue.rwood_bank_to_buyer_bank_airway_no || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>


              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood Bank to Buyer Bank Send Date "
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate25}
                    onChange={handlebldate25}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    placeholderText="Select Date and Time"
                  />
                </FloatingLabel>

              </Form.Group>


              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood Bank to Buyer Bank Airway Date "
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate26}
                    onChange={handlebldate26}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    placeholderText="Select Date and Time"
                  />
                </FloatingLabel>

              </Form.Group>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Buyer Bank Document Receive Date"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate27}
                    onChange={handlebldate27}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    placeholderText="Select Date and Time"
                  />
                </FloatingLabel>

              </Form.Group>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood Bank to Buyer Bank Airway Company"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Rwood Bank to Buyer Bank Airway Company"
                    name="rwood_bank_to_buyer_bank_airway_company"
                    defaultValue={dataValue.rwood_bank_to_buyer_bank_airway_company || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>

              </Form.Group>

            </Row>
            <Row className="mb-3">

              <div className='col-md-12'>
                <h4 className='heading'>Payment</h4>
              </div>

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document Collection Date"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate28} 
                    onChange={handlebldate28}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    placeholderText="Select Date and Time"
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">

                <input
                  type='checkbox'
                  name='actual_payment_received'
                  checked={actual} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive1} // Handle the change event
                  className='mx-3'
                />
                <label>Actual Payment Received

                </label>
              </Form.Group>
              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="RW Margin"
                >
                  <Form.Control 
                  type="text"
                    placeholder="Rw Margin"
                    style={{ height: "100px" }}
                    name="rw_margin"
                    defaultValue={dataValue.rw_margin || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>
              </Form.Group> */}

              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Expected Value Date"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={blsentDate29}
                    onChange={handlebldate29}
                    
                    dateFormat='dd/MM/yyyy'
                    placeholderText="Select"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              ></Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Actual Payment Received Date"
                  className="mb-3 post-date"
                >
                  <DatePicker
                    selected={selectAgentActual}
                    onChange={handleDateTimeChangeActual} 
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select Date"
                  />
                </FloatingLabel>

              </Form.Group>
              

              <Form.Group as={Col} md="6" className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="payment remark"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="payment remark"
                    style={{ height: "100px" }}
                    name="payment_remark"
                    defaultValue={dataValue.payment_remark || ""}
                    onChange={handleChangeInput} />
                </FloatingLabel>
              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              ></Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document Discount Date"
                  className="mb-3 post-date" 
                >
                  <DatePicker
                    selected={blsentDateDiscount}
                    onChange={handlebldateDiscount} 
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy hh:mm aa"
                    placeholderText="Select Date and Time"
                  />
                </FloatingLabel>

              </Form.Group>

            </Row>

            <p className='edit-btn'>
            <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value="Save"
      //disabled={isSubmitted}
    />
              <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


            </p>
          </div>
        ) : (
          <>
            {datas.length > 0 ? (
              <>
                {datas.map((x, i) => (
                  <>
                    <div className="tasks fulid-section order-section">
                      {/* <p id='top-header'>
                                    <Link to={'/payable/receive-list'}><span className="button add-accountrecord">Rw Payable/Receive List</span></Link>
                                    <Link to={'/postshipment/payable-receipt-list'}><span className="button add-accountrecord">Rw Payable/Receipt List</span></Link>
                                    </p> */}
                      <div className="edit-delete">
                        <span id="edit" onClick={handleEditClick}>
                          Edit
                        </span>
                        <span>
                          <Link to={'/post-shipment/create-view-list/' + orderId}> Cancel</Link>
                        </span>

                      </div>



                      <div className="">
                        <div className="row">
                          <div className="col-md-12" id="adatas">
                            <h4 className="heading">Information</h4>
                            <hr></hr>
                          </div>
                        </div>
                        <div id="contracts">
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>RWood Post Shipment Doc No  </span>
                                </td>
                                <td>{x.post_code_no}</td>
                                <td id="td-right">
                                  <span>Locked</span>
                                </td>
                                <td><input
                  
    type='checkbox'    disabled={isButtonEnabled}
                  name='locked'
                  checked={lockeds} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive0} // Handle the change event
                  className='mx-3'
                /></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Currency</span>
                                </td>
                                <td>{x.currency}</td>
                                <td id="td-right">
                                  <span>Order</span>
                                </td>
                                <td><Link to={`/order/view-order-details/${orderId}`}>{x.order}</Link></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Exchange Rate </span>
                                </td>
                                <td>{x.exchange_rate}</td>
                                <td id="td-right">  
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Pre Shipment Document
                                  </span>
                                </td>
                                <td><Link to={`/shipment/preshipment-details/${x.preshipment_id}`}>{x.pre_ship_document}</Link></td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Vessel Arrival Date and Time  </span>
                                </td>
                                <td>{x.vessel_arrival_date_and_time}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>


                        <div className="row">
                          <div className="col-md-12" id="adatas">
                            <h4 className="heading">BL Draft/AI COO</h4>
                            <hr></hr>
                          </div>
                        </div>
                        <div id="contracts">
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>BL draft sent date and time</span>
                                </td>
                                <td>{x.bl_draft_sent_date_and_time}</td>
                                <td id="td-right">
                                  <span>COO destination </span>
                                </td>
                                <td>{x.coo_destination}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>BL Confirm</span>
                                </td>
                                <td> <input
                  
    type='checkbox'    disabled={isButtonEnabled}
                  name='bl_confirm'
                  checked={blconfirm} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive2} // Handle the change event
                  className='mx-3'
                /></td>
                                <td id="td-right">
                                  <span>bl draft or splits Review Confirm Date </span>
                                </td>
                                <td>{x.bl_draft_or_splits_review_confirm_date}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="row">
                          <div className="col-md-12" id="adatas">
                            <h4 className="heading">BL Issued</h4>
                            <hr></hr>
                          </div>
                        </div>
                        <div id="contracts">
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>BL No</span>
                                </td>
                                <td>{x.bl_no}</td>
                                <td id="td-right">
                                  <span>5WD from BL Date</span>
                                </td>
                                <td>{x.five_wd_from_bl_date}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>BL Date</span>
                                </td>
                                <td>{x.bl_date}</td>
                                <td id="td-right">
                                  <span>BL Quantity </span>
                                </td>
                                <td>{renderShipmentQty(x.bl_quantity)}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>bl issue time and date</span>
                                </td>
                                <td>{x.bl_issue_time_and_date}</td>

                                <td id="td-right">
                                  <span>OBL email recieved date and time </span>
                                </td>
                                <td>{x.obl_email_recieved_date_and_time}</td>
                              </tr>
                              <tr>

                                <td id="td-right">
                                  <span>BL issue place </span>
                                </td>
                                <td>{x.bl_issue_place}</td>

                                <td id="td-right">
                                  <span>COO form govt recieve by email</span>
                                </td>
                                <td>{x.coo_form_govt_recieve_by_email}</td>
                              </tr>
                              <tr>

                                <td id="td-right">
                                  <span>BL Status</span>
                                </td>
                                <td>{x.bl_status}</td>

                                <td id="td-right">
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>


                        <div className="row">
                          <div className="col-md-12" id="adatas">
                            <h4 className="heading">Additional Surveyors Certificates</h4>
                            <hr></hr>
                          </div>
                        </div>
                        <div id="contracts">
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>shiper surveyor coa date</span>
                                </td>
                                <td>{x.shiper_surveyor_coa_date}</td>
                                <td id="td-right">
                                  <span>Certificate Of Weight </span>
                                </td>
                                <td><input
                  
    type='checkbox'    disabled={isButtonEnabled}
                  name='certificate_of_weight'
                  checked={weight} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive3} // Handle the change event
                  className='mx-3'
                /></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>rwood or cust surveyor coa date</span>
                                </td>
                                <td>{x.rwood_or_cust_surveyor_coa_date}</td>
                                <td id="td-right">
                                  <span>Certificate Of Hold Cleanliness </span>
                                </td>
                                <td><input
                  
    type='checkbox'    disabled={isButtonEnabled}
                  name='certificate_of_hold_cleanliness'
                  checked={hold} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive4} // Handle the change event
                  className='mx-3'
                /></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>NET weight as per document</span>
                                </td>
                                <td>{renderShipmentQty(x.net_weight_as_per_document)}</td>
                                <td id="td-right">
                                  <span>Draft Survey Report </span>
                                </td>
                                <td><input
                  
    type='checkbox'    disabled={isButtonEnabled}
                  name='draft_survey_report'
                  checked={servey} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive5} // Handle the change event
                  className='mx-3'
                /></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>addi surveyor certification date</span>
                                </td>
                                <td>{x.addi_surveyor_certification_date}</td>
                                <td id="td-right">
                                  <span>Certificate Of Registry </span>
                                </td>
                                <td> <input
                  
    type='checkbox'    disabled={isButtonEnabled}
                  name='certificate_of_registry'
                  checked={register} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive6} // Handle the change event
                  className='mx-3'
                /></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>COA status</span>
                                </td>
                                <td>{x.coa_status}</td>
                                <td id="td-right">
                                  <span>Classification Certificate </span>
                                </td>
                                <td><input
                  
    type='checkbox'    disabled={isButtonEnabled}
                  name='classification_certificate'
                  checked={register} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive5} // Handle the change event
                  className='mx-3'
                /></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>COO Status</span>
                                </td>
                                <td>{x.coo_status}</td>
                                <td id="td-right">
                                  <span>Safety Management Certificate </span>
                                </td>
                                <td><input
                  
    type='checkbox'    disabled={isButtonEnabled}
                  name='safety_management_certificate'
                  checked={safety} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive7} // Handle the change event
                  className='mx-3'
                /></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>COW Status</span>
                                </td>
                                <td>{x.cow_status}</td>
                                <td id="td-right">
                                  <span>Insurance of Certificate</span>
                                </td>
                                <td><input
                  
    type='checkbox'    disabled={isButtonEnabled}
                  name='insurance_of_certificate'
                  checked={insurence} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive8} // Handle the change event
                  className='mx-3'
                /></td>
                              </tr>

                              <tr>


                                <td id="td-right">
                                  <span>DSR Status </span>
                                </td>
                                <td>{x.dsr_status}</td>

                                <td id="td-right">
                                  <span>reason for delay </span>
                                </td>
                                <td>{x.reason_for_delay}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>govt coo status</span>
                                </td>
                                <td>{x.govt_coo_status}</td>
                                <td id="td-right">
                                </td>
                                <td></td>
                              </tr>

                            </tbody>
                          </table>
                        </div>


                        <div className="row">
                          <div className="col-md-12" id="adatas">
                            <h4 className="heading">COO Issued and Courier information</h4>
                            <hr></hr>
                          </div>
                        </div>
                        <div id="contracts">
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>COO issuance date</span>
                                </td>
                                <td>{x.coo_issuance_date}</td>
                                <td id="td-right">
                                  <span>COO courier date</span>
                                </td>
                                <td>{x.coo_courier_date}</td>
                              </tr>

                              <tr>
                                <td id="td-right">
                                </td>
                                <td></td>
                                <td id="td-right">
                                  <span>COO courier tracking no</span>
                                </td>
                                <td>{x.coo_courier_tracking_no}</td>

                              </tr>

                              <tr>
                                <td id="td-right">
                                </td>
                                <td></td>
                                <td id="td-right">
                                  <span>COO courier company </span>
                                </td>
                                <td>{x.coo_courier_company}</td>
                              </tr>

                            </tbody>
                          </table>
                        </div>



                        <div className="row">
                          <div className="col-md-12" id="adatas">
                            <h4 className="heading">Import Invoice</h4>
                            <hr></hr>
                          </div>
                        </div>
                        <div id="contracts">
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>Checking Of Import Invoice</span>
                                </td>
                                <td> <input
                  
    type='checkbox'    disabled={isButtonEnabled}
                  name='checking_of_import_invoice'
                  checked={invoice} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive9} // Handle the change event
                  className='mx-3'
                /></td>
                                <td id="td-right">
                                  <span>supplier invoice price</span>
                                </td>
                                <td>{renderShipmentQty(x.supplier_invoice_price)}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>supplier invoice check date</span>
                                </td>
                                <td>{x.supplier_invoice_check_date}</td>
                                <td id="td-right">
                                  <span>Supplier Invoice Amount </span>
                                </td>
                                <td>{renderShipmentQty(x.supplier_invoice_amount)}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Remark</span>
                                </td>
                                <td>{x.remark}</td>
                                <td id="td-right">
                                </td>
                                <td></td>
                              </tr>


                            </tbody>
                          </table>
                        </div>


                        <div className="row">
                          <div className="col-md-12" id="adatas">
                            <h4 className="heading">Document Submitted by Shipper/Supplier</h4>
                            <hr></hr>
                          </div>
                        </div>
                        <div id="contracts">
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>submitted shipper to shipper bank date</span>
                                </td>
                                <td>{x.submitted_by_shipper_to_shipper_bank_date}</td>
                                <td id="td-right">
                                  <span>scan BL rcvd date</span>
                                </td>
                                <td>{x.scan_bl_rcvd_date}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>supplier bank to rwood bank sent date</span>
                                </td>
                                <td>{x.supplier_bank_to_rwood_bank_sent_date}</td>
                                <td id="td-right">
                                  <span>Scan_IIA certs rcvd date</span>
                                </td>
                                <td>{x.scan_iia_certs_rcvd_date}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                </td>
                                <td></td>
                                <td id="td-right">
                                  <span>scan all original docs rcvd date</span>
                                </td>
                                <td>{x.scan_all_original_docs_rcvd_date}</td>
                              </tr>

                              <tr>
                                <td id="td-right">
                                  <span>import document rwood bank recieved date</span>
                                </td>
                                <td>{x.import_document_rwood_bank_recieved_date}</td>
                                <td id="td-right">
                                </td>
                                <td></td>
                              </tr>

                              <tr>

                                <td id="td-right">
                                  <span>import document airway no</span>
                                </td>
                                <td>{x.import_document_airway_no}</td>
                                <td id="td-right">
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>import document airway company</span>
                                </td>
                                <td>{x.import_document_airway_company}</td>
                                <td id="td-right">
                                </td>
                                <td></td>

                              </tr>

                            </tbody>
                          </table>
                        </div>


                        <div className="row">
                          <div className="col-md-12" id="adatas">
                            <h4 className="heading">Documents Submitted to Buyer</h4>
                            <hr></hr>
                          </div>
                        </div>
                        <div id="contracts">
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>Org Document to Buyer 80%</span>
                                </td>
                                <td>{x.org_document_to_buyer_80_percent}</td>
                                <td id="td-right">
                                  <span>Arrived at Discharge Port </span>
                                </td>
                                <td>{x.arrived_at_discharge_port}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Org Document to Buyer 20%</span>
                                </td>
                                <td>{x.org_document_to_buyer_20_percent}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>



                            </tbody>
                          </table>
                        </div>






                        <div className="row">
                          <div className="col-md-12" id="adatas">
                            <h4 className="heading">Commercial Invoice</h4>
                            <hr></hr>
                          </div>
                        </div>
                        <div id="contracts">
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>Commercial Invoice Prepare Date </span>
                                </td>
                                <td>{x.commercial_invoice_prepare_date}</td>
                                <td id="td-right">
                                  <span>Comme Invoice Confirmation Date n Time  </span>
                                </td>
                                <td>{x.commercial_invoice_confirmation_date_and_time}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Commercial Invoice No </span>
                                </td>
                                <td>{renderShipmentQty(x.commercial_invoice_no)}</td>
                                <td id="td-right">
                                  <span>Commercial Invoice Price  </span>
                                </td>
                                <td>{renderShipmentQty(x.commercial_invoice_price)}</td>
                              </tr>
                              <tr>

                                <td id="td-right">
                                  <span>Comm Invoice Date </span>
                                </td>
                                <td>{x.commercial_invoice_date}</td>
                                <td id="td-right">
                                  <span>Commercial Invoice Amount </span>
                                </td>
                                <td>{renderShipmentQty(x.commercial_invoice_amount)}</td>
                              </tr>

                              <tr>
                                <td id="td-right">
                                  <span>Comm Invoice No</span>
                                </td>
                                <td>{x.comm_invoice_no}</td>
                                <td id="td-right">
                                </td>
                                <td></td>
                              </tr>



                            </tbody>
                          </table>
                        </div>



                        <div className="row">
                          <div className="col-md-12" id="adatas">
                            <h4 className="heading">Non Negotiable Document</h4>
                            <hr></hr>
                          </div>
                        </div>
                        <div id="contracts">
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>NN Document sent Date </span>
                                </td>
                                <td>{x.non_negotiable_document_sent_date}</td>
                                <td id="td-right">
                                </td>
                                <td></td>
                              </tr>

                            </tbody>
                          </table>
                        </div>



                        <div className="row">
                          <div className="col-md-12" id="adatas">
                            <h4 className="heading">Rwood Export Document Status</h4>
                            <hr></hr>
                          </div>
                        </div>
                        <div id="contracts">
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>Document Submission Date</span>
                                </td>
                                <td>{x.document_submission_date}</td>
                                <td id="td-right">
                                  <span>Rwood Bank to Buyer Bank Airway No  </span>
                                </td>
                                <td>{x.rwood_bank_to_buyer_bank_airway_no}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Rwood Bank to Buyer Bank Send Date</span>
                                </td>
                                <td>{x.rwood_bank_to_buyer_bank_send_date}</td>
                                <td id="td-right">
                                  <span> Rwood Bank to Buyer Bank Airway Date </span>
                                </td>
                                <td>{x.rwood_bank_to_buyer_bank_airway_date}</td>
                              </tr>
                              <tr>
                               
                                <td id="td-right">
                                  <span>Buyer Bank Document Receive Date</span>
                                </td>
                                <td>{x.buyer_bank_document_receive_date}</td>
                                <td id="td-right">
                                  <span>Rwood Bank to Buyer Bank Airway Company </span>
                                </td>
                                <td>{x.rwood_bank_to_buyer_bank_airway_company}</td>
                              </tr>
                              
                            </tbody>
                          </table>

                        </div>




                        <div className="row">
                          <div className="col-md-12" id="adatas">
                            <h4 className="heading">Payment Informations</h4>
                            <hr></hr>
                          </div>
                        </div>
                        <div id="contracts">
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>document collection date</span>
                                </td>
                                <td>{x.document_collection_date}</td>
                                <td id="td-right">
                                  <span>Actual Payment Received</span>
                                </td>
                                <td> <input
                  
    type='checkbox'    disabled={isButtonEnabled}
                  name='actual_payment_received'
                  checked={actual} // Use the state variable to determine if the checkbox is checked
                  onChange={handleCheckboxActive1} // Handle the change event
                  className='mx-3'
                /></td>
                              </tr>
                              <tr>

                                <td id="td-right">
                                  {/* <span>RW Margin</span> */}
                                </td>
                                <td>
                                  {/* {renderShipmentQty(x.rw_margin)} */}
                                  </td>
                                <td id="td-right">
                                </td>
                                <td></td>
                              </tr>
                              <tr>

                                <td id="td-right">
                                  <span>Expected Value Date</span>
                                </td>
                                <td>{x.expected_value_date}</td>
                                <td id="td-right">
                                  <span>Doc Discounting Month </span>
                                </td>
                                <td>{x.doc_discounting_month}</td>
                              </tr> <tr>

                                <td id="td-right">
                                  <span>No of Working Days</span>
                                </td>
                                <td>{x.no_of_working_days}</td>
                                <td id="td-right">
                                  <span>Payment Remark</span>
                                </td>
                                <td>{x.payment_remark}</td>
                              </tr>
                              <tr>

                                <td id="td-right">
                                  <span>Actual Payment Received Date</span>
                                </td>
                                <td>{x.actual_payment_received_date}</td>
                                <td id="td-right">
                                  <span>Document Discount Date</span>
                                </td>
                                <td>{x.document_discount_date}</td>
                              </tr>





                            </tbody>
                          </table>

                          <div className="row">
                          <div className="col-md-12" id="adatas">
                            <h4 className="heading">System Information</h4>
                            <hr></hr>
                          </div>
                        </div>

                        <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>Created By	
                                  </span>
                                </td>
                                <td>{x.created_by}</td>
                                <td id="td-right">
                                  <span>Last Modified By	
                                  </span>
                                </td>
                                <td>{x.last_modified_by}</td>
                                 </tr>





                            </tbody>
                          </table>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading"><span>Rw Payable/ Receivable LineItem</span><span>

                                <Popup className='post-buyer'
                                  trigger={
                                    <a className="button add-accountrecord">
                                      {" "}
                                      {/* <FaPlus /> */}
                                      New{" "}
                                    </a>
                                  }
                                  modal
                                >
                                  <div className="form-group" onChange={handleChange}>
                                    <Form.Check // prettier-ignore
                                      type='radio'
                                      id=''
                                      name='type'
                                      label='Buyer - Credit Note'
                                      value='Buyer - Credit Note'
                                    />
                                    <Form.Check // prettier-ignore
                                      type='radio'
                                      id=''
                                      name='type'
                                      label='Supplier - Credit Note'
                                      value='Supplier - Credit Note'
                                    />
                                    <Form.Check // prettier-ignore
                                      type='radio'
                                      id=''
                                      name='type'
                                      label='Buyer - Debit Note'
                                      value='Buyer - Debit Note'
                                    />
                                    <Form.Check // prettier-ignore
                                      type='radio'
                                      id=''
                                      name='type'
                                      label='Supplier - Debit Note'
                                      value='Supplier - Debit Note'
                                    />
                                    <Form.Check // prettier-ignore
                                      type='radio'
                                      id=''
                                      name='type'
                                      label='Load Port Invoice'
                                      value='Load Port Invoice'
                                    />
                                    <Form.Check // prettier-ignore
                                      type='radio'
                                      id=''
                                      name='type'
                                      label='Other Payable'
                                      value='Other Payable'
                                    />
                                    <Form.Check // prettier-ignore
                                      type='radio'
                                      id=''
                                      name='type'
                                      label='Other Receivable'
                                      value='Other Receivable'
                                    />
                                    <Form.Check // prettier-ignore
                                      type='radio'
                                      id=''
                                      name='type'
                                      label='Purchase Invoice'
                                      value='Purchase Invoice'
                                    />
                                    <Form.Check // prettier-ignore
                                      type='radio'
                                      id=''
                                      name='type'
                                      label='Sales Invoice'
                                      value='Sales Invoice'
                                    />
                                    <Form.Check // prettier-ignore
                                      type='radio'
                                      id=''
                                      name='type'
                                      label='Surveyor Invoice'
                                      value='Surveyor Invoice'
                                    />

                                  </div>
                                  <input
                                    type="submit"
                                    className="btn btn-primary "
                                    value="Submit"
                                    onClick={handleClick}
                                  />

                                </Popup>
                              </span></h4>
                              <hr></hr>
                            </div>
                            <table id="payrecieve" className="">
                              <thead>
                                <tr>
                                  <th>Record Type	</th>
                                  <th>RW Billing	</th>
                                  <th>Account</th>
                                  <th>Amount</th>
                                  <th>Line Item	</th>
                                  <th>Order</th>
                                  <th>Contract</th>
                                  <th>Buyer</th>
                                  <th>Supplier	</th>
                                  <th>BL Date</th>
                                </tr>
                              </thead>

                              {payrecieve.length > 0 ? (
                                <tbody>
                                  {payrecieve.map((y, i) => (
                                    <tr>
                                      
                                      <td>{y.record_type}</td>
                                      <td><Link
   
  onClick={(e) => {
    e.preventDefault(); // Prevent default action
    // Perform custom action here
    navigate("/postshipment/receivable-lineitem/" + y.rw_pay_recieve_id, {
      state: { postId:id },
    });
    
  }}
>
  {y.rw_billing}
</Link></td>
                                      <td><Link to={'/accounts/' + y.account_id}>{y.account}</Link></td>
                                      <td>{y.amount}</td>
                                      <td>{y.line_item}</td>
                                      <td><Link to={'/order/view-order-details/' + y.order_id}>{y.order}</Link></td>
                                      <td><Link to={'/contract/contract-details/' + y.contract_id}>{y.contract}</Link></td>
                                      <td>{y.buyer}</td>
                                      <td><Link to={'/accounts/' + y.account_id}>{y.supplier}</Link></td>
                                      <td>{y.bl_date}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>



                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading"><span>Rw Payments Recipts Lineitem</span>
                                <span>
                                  <Popup className='post-buyer'
                                    trigger={
                                      <a className="button add-accountrecord">
                                        {" "}
                                        {/* <FaPlus /> */}
                                        New{" "}
                                      </a>
                                    }
                                    modal
                                  >
                                    <div className="form-group" onChange={handleChangeRecipt}>
                                      <Form.Check // prettier-ignore
                                        type='radio'
                                        id=''
                                        name='type'
                                        label='Buyer - Credit Note'
                                        value='Buyer - Credit Note'
                                      />
                                      <Form.Check // prettier-ignore
                                        type='radio'
                                        id=''
                                        name='type'
                                        label='Supplier - Credit Note'
                                        value='Supplier - Credit Note'
                                      />
                                      <Form.Check // prettier-ignore
                                        type='radio'
                                        id=''
                                        name='type'
                                        label='Buyer - Debit Note'
                                        value='Buyer - Debit Note'
                                      />
                                      <Form.Check // prettier-ignore
                                        type='radio'
                                        id=''
                                        name='type'
                                        label='Supplier - Debit Note'
                                        value='Supplier - Debit Note'
                                      />
                                      <Form.Check // prettier-ignore
                                        type='radio'
                                        id=''
                                        name='type'
                                        label='Load Port Invoice'
                                        value='Load Port Invoice'
                                      />
                                      <Form.Check // prettier-ignore
                                        type='radio'
                                        id=''
                                        name='type'
                                        label='Other Payable'
                                        value='Other Payable'
                                      />
                                      <Form.Check // prettier-ignore
                                        type='radio'
                                        id=''
                                        name='type'
                                        label='Other Receivable'
                                        value='Other Receivable'
                                      />
                                      <Form.Check // prettier-ignore
                                        type='radio'
                                        id=''
                                        name='type'
                                        label='Purchase Invoice'
                                        value='Purchase Invoice'
                                      />
                                      <Form.Check // prettier-ignore
                                        type='radio'
                                        id=''
                                        name='type'
                                        label='Sales Invoice'
                                        value='Sales Invoice'
                                      />
                                      <Form.Check // prettier-ignore
                                        type='radio'
                                        id=''
                                        name='type'
                                        label='Surveyor Invoice'
                                        value='Surveyor Invoice'
                                      />

                                    </div>
                                    <input
                                      type="submit"
                                      className="btn btn-primary "
                                      value="Submit"
                                      onClick={handleClickReceipt}
                                    />

                                  </Popup>

                                </span></h4>
                              <hr></hr>
                            </div>
                            <table id="payreceipt" className="">
                              <thead>
                                <tr>
                                  <th>RW Payment/Receipt LineItem Name	</th>
                                  <th>Account</th>
                                  <th>Line Item	</th>
                                  <th>Amount</th>
                                  <th>Quantity</th>
                                </tr>
                              </thead>

                              {payreceipt.length > 0 ? (
                                <tbody>
                                  {payreceipt.map((y, i) => (
                                    <tr>
                                       <td><Link
   
   onClick={(e) => {
     e.preventDefault(); // Prevent default action
     // Perform custom action here
     navigate("/postshipment/payable-receipt-details/" + y.rw_pay_receipt_detail_id, {
       state: { postId:id },
     });
     
   }}
 >
   {y.rw_payment_receipt_name}
 </Link></td>
                                      <td><Link to={'/accounts/' + y.account_id}>{y.account}</Link></td>
                                      <td>{y.line_item}</td>
                                      <td>{y.amount}</td>
                                      <td>{y.quantity}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>


                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Open Activities</span>
                                <span>
                                  <i className=""></i>
                                  New Task
                                </span>
                                <span>
                                  <i className=""></i>
                                  New Event
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Activity History</span>
                                <span>
                                  <i className=""></i>
                                  Log a Call
                                </span>
                                <span>
                                  <i className=""></i>
                                  Mail merge
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="" className="">
                              <tr>
                                <td>
                                  <p class="no-records">No records to display</p>
                                </td>
                              </tr>
                            </table>
                          </Row>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Notes & Attachments</span>
                                <span>
                                  <i className=""></i>
                                  New Note
                                </span>
                                <span>
                                  <i className=""></i>
                                  Attach File
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Files</span>
                                <span>
                                  <i className=""></i>
                                  Upload File
                                </span>

                              </h4>
                              <hr></hr>
                            </div>
                            <table id="" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading"><span>Post Shipment Document History</span></h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <thead>
                                <tr>
                                  <th>Field Name</th>
                                  <th>New Value</th>
                                  <th>Old Value</th>
                                  <th>Changed By</th>
                                  <th>Change Time</th>
                                </tr>
                              </thead>

                              {history.length > 0 ? (
                                <tbody>
                                  {history.map((y, i) => (
                                    <tr>
                                      <td>{y.field_name}</td>
                                      <td>{y.new_value}</td>
                                      <td>{y.old_value}</td>
                                      <td>{y.changed_by}</td>
                                      <td>{y.change_time}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>

                        </div>
                        <div className="row">
                          <div className="col-md-12" id="adatas">
                            <h4 className="heading">System Information</h4>
                            <hr></hr>
                          </div>
                        </div>
                        
                      </div>
                      <p></p><p></p>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <div className="tasks fulid-section order-section">
                <h1 className="no-data-detail"><img src='../../images/loading.gif' className="loading" /></h1>
              </div>
            )}
          </>)}
      </div>
      {/* pending
      Documents Submitted to Buyer
      Non Negotiable Document
      Rwood Export Document Status */}
      <Footer />
    </div>
  );
};

export default ViewPost;
