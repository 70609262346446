import React, { Component, useState, useEffect, useContext } from "react";
import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { API_URL } from '../config';
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import $ from "jquery";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
const QuoteProductView = () => {
  const params = useParams();
  const id = params.id;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState({});
  const [quoteProduct,setQuoteproduct]=useState([]);
  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/quotesbyID/" + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.quote_details;
      const quotesProduct=response.data.quote_products;
      
      setDatas(data);
      setQuoteproduct(quotesProduct)
      console.log(quotesProduct);
      setTimeout(() => { 
      $('#quote-product').DataTable();
    }, 50); 
    } catch (error) {
      console.error(error);
    }
  };
  if (!datas) {
    return null;
  }
  return (
    <div id="view-page">
      <Header />

      <div className="contactAccount">
        <div className="page-header btn-margin" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">
              Quote Product Details
            </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Quote Product / Quote Product details
              </li>
            </ol>
          </div>
        </div>
        <div className="tasks">
          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">Information</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
              <Row>
                <Col>
                  <p><span>Locked</span><br />{datas.locked}</p>
                </Col>
                <Col>
                  <p><span>Supplier</span><br />{datas.supplier}</p>
                </Col>
                <Col>
                  <p><span>Quotes Status</span><br />{datas.quotes_status}</p>
                </Col>
                </Row>
                <Row>
                  
                <Col>
                  <p><span>Quotes Name</span><br />{datas.quotes_name}</p>
                </Col>
                <Col>
                  <p><span>No of Product</span><br />{datas.no_of_product}</p>
                </Col>
                <Col>
                  <p><span>No of Quote Shipment</span><br />{datas.no_of_quote_shipment}</p>
                </Col>
                </Row>
                <Row>
                <Col>
                  <p><span>Quote Shipment</span><br />{datas.quote_shipment}</p>
                </Col>
                <Col>
                  <p><span>Product Code</span><br />{datas.product_code}</p>
                </Col>
                <Col>
                  <p><span>Product Name</span><br />{datas.product_name}</p>
                </Col>
                </Row>
                <Row>
                <Col>
                  <p><span>Product Account</span><br />{datas.product_account}</p>
                </Col>
                <Col>
                  <p><span>Product Supplier</span><br />{datas.product_supplier}</p>
                </Col>
                
                <Col>
                  <p><span>Product Description</span><br />{datas.product_description}</p>
                </Col>
               </Row>
               <Row>
                <Col>
                  <p><span>Product Family</span><br />{datas.product_family}</p>
                </Col><Col>
                  <p><span>Product Shipping Term</span><br />{datas.product_shipping_term}</p>
                </Col><Col>
                  <p><span>Product Owner</span><br />{datas.product_owner}</p>
                </Col>
                </Row>
                <Row>
                <Col>
                  <p><span>Product Active</span><br />{datas.product_active}</p>
                </Col>
                <Col>
                  <p><span>Short Term Available</span><br />{datas.short_term_available}</p>
                </Col>
                <Col>
                  
                </Col>
              </Row>
              
            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">Product Information</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
             <Row>
                <Col><p><span>Product Price Basis</span><br />{datas.product_price_basis} </p></Col>
                <Col><p><span>Product Price Type</span><br />{datas.product_price_type} </p></Col>
                <Col><p><span>Product Port of Loading</span><br />{datas.product_port_of_loading} </p></Col>
                
                
              </Row>              
            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">%As Received Basis</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
             
              <Row>
                <Col><p><span>Total Moisture Typical</span><br />{datas.total_moisture_typical} </p></Col>
                <Col><p><span>Total Moisture Minimum</span><br />{datas.total_moisture_minimum} </p></Col>
                <Col><p><span>Total Moisture Maximum</span><br />{datas.total_moisture_maximum} </p></Col>
                
              </Row>
              <Row>
                <Col><p><span>Ash Typical ARB</span><br />{datas.ash_typical_arb} </p></Col>
                <Col><p><span>Ash Minimum ARB</span><br />{datas.ash_minimum_arb} </p></Col>
                <Col><p><span>Ash Maximum ARB</span><br />{datas.ash_maximum_arb} </p></Col>
              </Row>
              <Row>
                <Col><p><span>Sulphur Typical ARB</span><br />{datas.sulphur_typical_arb} </p></Col>
                <Col><p><span>Sulphur Minimum ARB</span><br />{datas.sulphur_minimum_arb} </p></Col>
                <Col><p><span>Sulphur Maximum ARB</span><br />{datas.sulphur_maximum_arb} </p></Col>
              </Row>

              <Row>
                <Col><p><span>Volatile Matter Typical ARB</span><br />{datas.volatile_matter_typical_arb} </p></Col>
                <Col><p><span>Volatile Matter Minimum ARB</span><br />{datas.volatile_matter_minimum_arb} </p></Col>
                <Col><p><span>Volatile Matter Maximum ARB</span><br />{datas.volatile_matter_maximum_arb} </p></Col>
                
              </Row>
            </div>
          </div>
          
          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">Proximate Analysis %Air Dried Basis</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
             
              <Row>
                <Col><p><span>Moisture Typical</span><br />{datas.moisture_typical} </p></Col>
                <Col><p><span>Moisture Minimum</span><br />{datas.moisture_minimum} </p></Col>
                <Col><p><span>Ash Typical</span><br />{datas.ash_typical} </p></Col>
                
              </Row>
              <Row>
                <Col><p><span>Ash Minimum</span><br />{datas.ash_minimum} </p></Col>
                <Col><p><span>Ash Maximum</span><br />{datas.ash_maximum} </p></Col>
                <Col><p><span>Volatile Matter Typical</span><br />{datas.volatile_matter_typical} </p></Col>
              </Row>
              <Row>
                <Col><p><span>Volatile Matter Minimum</span><br />{datas.volatile_matter_minimum} </p></Col>
                <Col><p><span>Volatile Matter Maximum</span><br />{datas.volatile_matter_maximum} </p></Col>
                <Col><p><span>Fixed Carbon By Difference</span><br />{datas.fixed_carbon_by_difference} </p></Col>
              </Row>
              <Row>
                <Col><p><span>Fixed Carbon Typical</span><br />{datas.fixed_carbon_typical} </p></Col>
                <Col><p><span>Fixed Carbon Minimum</span><br />{datas.fixed_carbon_minimum} </p></Col>
                <Col><p><span>Fixed Carbon Maximum</span><br />{datas.fixed_carbon_maximum} </p></Col>
              </Row><Row>
                <Col><p><span>Gross Air Dried Typical</span><br />{datas.gross_air_dried_typical} </p></Col>
                <Col><p><span>Gross Air Dried Min</span><br />{datas.gross_air_dried_min} </p></Col>
                <Col><p><span>Gross Air Dried Max</span><br />{datas.gross_air_dried_max} </p></Col>
              
              </Row>
              <Row>
                <Col><p><span>Gross As Recieved Min</span><br />{datas.gross_as_recieved_min} </p></Col>
                <Col><p><span>Gross As Recieved Max</span><br />{datas.gross_as_recieved_max} </p></Col>
                <Col><p><span>Net As Recieved Min</span><br />{datas.net_as_recieved_min} </p></Col>
              </Row>
              <Row>
                <Col><p><span>Gross As Recieved Min</span><br />{datas.gross_as_recieved_min} </p></Col>
                <Col><p><span>Gross As Recieved Max</span><br />{datas.gross_as_recieved_max} </p></Col>
                <Col></Col>
              </Row>

            </div>
          </div>
          
           <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">CALORIFIC VALUE, Keal/kg</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
             
              <Row>
                <Col><p><span>HGI Typical</span><br />{datas.hgi_typical} </p></Col>
                <Col><p><span>HGI Min</span><br />{datas.hgi_min} </p></Col>
                <Col><p><span>HGI Max</span><br />{datas.hgi_max} </p></Col>
                
              </Row> 

              <Row>
                <Col><p><span>Carbon Typical</span><br />{datas.carbon_typical} </p></Col>
                <Col><p><span>Carbon Min</span><br />{datas.carbon_min} </p></Col>
                <Col><p><span>Carbon Max</span><br />{datas.carbon_max} </p></Col>
                
              </Row>

              <Row>
                <Col><p><span>Hydrogen Typical</span><br />{datas.hydrogen_typical} </p></Col>
                <Col><p><span>Hydrogen Min</span><br />{datas.hydrogen_min} </p></Col>
                <Col><p><span>Hydrogen Max</span><br />{datas.hydrogen_max} </p></Col>
              </Row>

              <Row>
                <Col><p><span>Nitrogen Typical</span><br />{datas.nitrogen_typical} </p></Col>
                <Col><p><span>Nitrogen Min</span><br />{datas.nitrogen_min} </p></Col>
                <Col><p><span>Nitrogen Max</span><br />{datas.nitrogen_max} </p></Col>
              </Row>

              <Row>
                <Col><p><span>Sulphur Typical</span><br />{datas.sulphur_typical} </p></Col>
                <Col><p><span>Sulphur Min</span><br />{datas.sulphur_min} </p></Col>
                <Col><p><span>Sulphur Max</span><br />{datas.sulphur_max} </p></Col>
                
              </Row>
              <Row>
                <Col><p><span>Sulphur Max Ultimate Analysis</span><br />{datas.sulphur_max_ultimate_analysis} </p></Col>
                <Col><p><span>Oxygen Typical</span><br />{datas.oxygen_typical} </p></Col>
                <Col><p><span>Oxygen Min</span><br />{datas.oxygen_min} </p></Col>
                
              </Row>
              <Row>
                <Col><p><span>Oxygen Max</span><br />{datas.oxygen_max} </p></Col>
                <Col></Col>
                <Col></Col>
                
              </Row>
            </div>
          </div>
 
           <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">SULPHUR %air dried basis</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
             
              <Row>
                <Col><p><span>Sulphur Typical ADB</span><br />{datas.sulphur_typical_adb} </p></Col>
                <Col><p><span>Sulphur Min ADB</span><br />{datas.sulphur_min_adb} </p></Col>
                <Col><p><span>Ash</span><br />{datas.ash_typical} </p></Col>
                
              </Row><Row>
                <Col><p><span>Product Sulphur Max</span><br />{datas.product_sulphur_max} </p></Col>
                <Col></Col>
                <Col></Col>
                
              </Row>
            </div>
          </div>
          
          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">ASH FUSION TEMPERATURE, DEG (C) Reducing</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
             
              <Row>
                <Col><p><span>Initial Typical </span><br />{datas.initial_typical} </p></Col>
                <Col><p><span>Initial Minimum</span><br />{datas.initial_minimum} </p></Col>
                <Col><p><span>Initial Maximum</span><br />{datas.initial_maximum} </p></Col>
                
              </Row>

              <Row>
                <Col><p><span>Spherical Typical</span><br />{datas.spherical_typical} </p></Col>
                <Col><p><span>Spherical Minimum</span><br />{datas.spherical_minimum} </p></Col>
                <Col><p><span>Spherical Maximum</span><br />{datas.spherical_maximum} </p></Col>
                
              </Row>
              
              <Row>
                <Col><p><span>Hemispherical Typical </span><br />{datas.hemispherical_typical} </p></Col>
                <Col><p><span>Hemispherical Minimum</span><br />{datas.hemispherical_minimum} </p></Col>
                <Col><p><span>Hemispherical Maximum</span><br />{datas.hemispherical_maximum} </p></Col>
                
              </Row>
              
              <Row>
                <Col><p><span>Flow Typical</span><br />{datas.flow_typical} </p></Col>
                <Col><p><span>Flow Minimum</span><br />{datas.flow_minimum} </p></Col>
                <Col><p><span>Flow Maximum</span><br />{datas.flow_maximum} </p></Col>
                
              </Row>
            </div>
          </div>

          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">ASH FUSION TEMPERATURE, Deg C Oxidizing</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
             
              <Row>
                <Col><p><span>Oxidising Initial Typical</span><br />{datas.oxidising_initial_typical} </p></Col>
                <Col><p><span>Oxidising Initial Minimum</span><br />{datas.oxidising_initial_minimum} </p></Col>
                <Col><p><span>Oxidising Initial Maximum</span><br />{datas.oxidising_initial_maximum} </p></Col>
              </Row>
              <Row>
                <Col><p><span>Oxidising Spherical Typical</span><br />{datas.oxidising_spherical_typical} </p></Col>
                <Col><p><span>Oxidising Spherical Minimum</span><br />{datas.oxidising_spherical_minimum} </p></Col>
                <Col><p><span>Oxidising Spherical Maximum</span><br />{datas.oxidising_spherical_maximum} </p></Col>
              </Row>
              <Row>
                <Col><p><span>Oxidising Hemispherical Typical</span><br />{datas.oxidising_hemispherical_typical} </p></Col>
                <Col><p><span>Oxidising Hemispherical Minimum</span><br />{datas.oxidising_hemispherical_minimum} </p></Col>
                <Col><p><span>Oxidising Hemispherical Maximum</span><br />{datas.oxidising_initial_maximum} </p></Col>
              </Row>
              <Row>
                <Col><p><span>Oxidising Flow Typical</span><br />{datas.oxidising_flow_typical} </p></Col>
                <Col><p><span>Oxidising Flow Minimum</span><br />{datas.oxidising_flow_minimum} </p></Col>
                <Col><p><span>Oxidising Flow Maximum</span><br />{datas.oxidising_flow_maximum} </p></Col>
              </Row>
            </div>
          </div>
          
           <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">ASH ANALYSIS %Dry Basis in Ash</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
             
              <Row>
                <Col><p><span>SiO2 Typical</span><br />{datas.SiO2_Typical} </p></Col>
                <Col><p><span>SiO2 Minimum</span><br />{datas.SiO2_Minimum} </p></Col>
                <Col><p><span>SiO2 Maximum</span><br />{datas.SiO2_Maximum} </p></Col>
              </Row>
              <Row>
                <Col><p><span>Al2O3 Typical</span><br />{datas.Al2O3_Typical} </p></Col>
                <Col><p><span>Al2O3 Minimum</span><br />{datas.Al2O3_Minimum} </p></Col>
                <Col><p><span>Al2O3 Maximum</span><br />{datas.Al2O3_Maximum} </p></Col>
              </Row>
              <Row>
                <Col><p><span>Fe2O3 Typical</span><br />{datas.Fe2O3_Typical} </p></Col>
                <Col><p><span>Fe2O3 Minimum</span><br />{datas.Fe2O3_Minimum} </p></Col>
                <Col><p><span>Fe2O3 Maximum</span><br />{datas.Fe2O3_Maximum} </p></Col>
              </Row>
              <Row>
                <Col><p><span>CaO Typical</span><br />{datas.CaO_Typical} </p></Col>
                <Col><p><span>CaO Minimum</span><br />{datas.CaO_Minimum} </p></Col>
                <Col><p><span>CaO Maximum</span><br />{datas.CaO_Maximum} </p></Col>
              </Row>
              <Row>
                <Col><p><span>MgO Typical</span><br />{datas.MgO_Typical} </p></Col>
                <Col><p><span>MgO Minimum</span><br />{datas.MgO_Minimum} </p></Col>
                <Col><p><span>MgO Maximum</span><br />{datas.MgO_Maximum} </p></Col>
              </Row>
              <Row>
                <Col><p><span>MnO Typical</span><br />{datas.MnO_Typical} </p></Col>
                <Col><p><span>MnO Minimum</span><br />{datas.MnO_Minimum} </p></Col>
                <Col><p><span>MnO Maximum</span><br />{datas.MnO_Maximum} </p></Col>
              </Row>
              <Row>
                <Col><p><span>TiO2 Typical</span><br />{datas.TiO2_Typical} </p></Col>
                <Col><p><span>TiO2 Minimum</span><br />{datas.TiO2_Minimum} </p></Col>
                <Col><p><span>TiO2 Maximum</span><br />{datas.TiO2_Maximum} </p></Col>
              </Row>
              <Row>
                <Col><p><span>Na2O Typical</span><br />{datas.Na2O_Typical} </p></Col>
                <Col><p><span>Na2O Minimum</span><br />{datas.Na2O_Minimum} </p></Col>
                <Col><p><span>Na2O Maximum</span><br />{datas.Na2O_Maximum} </p></Col>
              </Row>
              <Row>
                <Col><p><span>K2O Typical</span><br />{datas.K2O_Typical} </p></Col>
                <Col><p><span>K2O Minimum</span><br />{datas.K2O_Minimum} </p></Col>
                <Col><p><span>K2O Maximum</span><br />{datas.K2O_Maximum} </p></Col>
              </Row>
              <Row>
                <Col><p><span>P2O5 Typical</span><br />{datas.P2O5_Typical} </p></Col>
                <Col><p><span>P2O5 Minimum</span><br />{datas.P2O5_Minimum} </p></Col>
                <Col><p><span>P2O5 Maximum</span><br />{datas.P2O5_Maximum} </p></Col>
              </Row>
              <Row>
                <Col><p><span>SO3 Typical</span><br />{datas.SO3_Typical} </p></Col>
                <Col><p><span>SO3 Minimum</span><br />{datas.SO3_Minimum} </p></Col>
                <Col><p><span>SO3 Maximum</span><br />{datas.SO3_Maximum} </p></Col>
              </Row>
              
            </div>
          </div>
          <Row>
       <div className="col-md-12" id='head'>
     <h4 className="heading">Product Master Information
     {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>
    
    <form onSubmit={handleSubmittcf} className="add-accounts">
     <div className='form-group'>
     <label>TCF Record Type</label><br/>
     <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
       <option >--Select TCF Record Type--</option>
       <option value='Buyer - Spot'>Buyer - Spot</option>
       <option value='Buyer - Long Term'>Buyer - Long Term</option>
       </select>
       </div>
       <input type='submit' className="btn btn-primary " value='Submit'/>

       </form>
</Popup></span> */}
</h4>
     <div  id='inner-heading'>
     
</div>
     <hr></hr>
     </div>
       <table id='quote-product' className="">
    <thead>
    <tr> 
    <th>Product Code</th>
      <th>Product Account</th>
      <th>Product Description</th>
      <th>Product Family</th>
      <th>Product Name</th>
      <th>Product Price Type</th>
    </tr>
    </thead>
    
    {quoteProduct.length>= 0  ?
      
      <tbody> 
      {
          quoteProduct.map((x,i)=>( 
            <tr>
           
           <td><Link to={"/productmaster/product-detail/"+x.id }>{x.product_code}</Link></td>
      <td>{x.product_account}</td>
      <td>{x.product_description}</td>  
      <td>{x.product_family}</td>
      <td><Link to={"/productmaster/product-detail/"+x.id }>{x.product_name}</Link></td>
      <td>{x.product_price_type}</td> 
          </tr>
       ))}
    </tbody>
    :<tbody>
    <tr> 
      <td></td> 
      <td></td> 
      <td>No data available</td>
      <td></td>
      <td></td>  
      <td></td>  
      
      </tr>
      </tbody>
}
    </table>
    
       </Row>   
        </div>
        
      </div>
    
    
      <Footer />
    </div>
  );
};

export default QuoteProductView;
