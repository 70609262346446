import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,

  OverlayTrigger,

  Tooltip,
  Table,
} from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from '../Header'
import Footer from '../Footer'
import { API_URL } from '../config';
import $ from 'jquery'
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaEnvelope, FaPhoneSquareAlt, FaMobileAlt, FaFax } from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
const ViewPrice = () => {

  const params = useParams()
  const id = params.id
  const userRole = localStorage.getItem("role");
  // console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
  const [data1, setData1] = useState([]);
  const [dataID, setDataID] = useState([]);
  const [pricename, setpricename] = useState([]);
  const [data, setData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const [agent, setAgent] = useState([]);
  const [actives, setActive] = useState('')
  const [lockedCheck, setLockedCheck] = useState('')
  const navigate = useNavigate();

  const PBN = pricename;

  useEffect(() => {

    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/pricebook_by_ID/' + id, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      }
      );
      console.log(response)
      const data = response.data.pricebook_details[0];
      const data1 = response.data.records;

      // const data1 = response.data; 
      const dataprice = data.price_book_name
      const dataId = data.id
      console.log(datas.active)
      setDatas(data);

      // if (datas.active) {
      //   setActive("Yes")
      // }
      // else {
      //   setActive("No")
      // }


      // if (datas.locked !== null && datas.locked !== false && datas.locked !== undefined) {
      //   setLockedCheck("Yes")
      // }
      // else {
      //   setLockedCheck("No")
      // }

      setData1(dataprice);
      setDataID(dataId);
      setpricename(dataprice)
      // console.log(dataprice)   
      // setAgent(data1)  
      fetch(API_URL+'get/all_hba/' + dataprice, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          'Content-Type': 'application/json'
        },
      })
        .then((response) => response.json())

        .then((response) => {
          setAgent(response.records)
          console.log(response.records)


        })


      setTimeout(() => {
        $('#hba').DataTable();
      }, 50);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);

  };

  useEffect(() => {
    if (datas.locked) {
      setLockeds(false);
    }
  }, [datas.locked]);




  const [activate, setActivate] = useState(null); // Initialize the state of the checkbox
  const [lockeds, setLockeds] = useState(null); // Initialize the state of the checkbox
  const chek_bok = datas.locked;
  const chek_active = datas.active;
  useEffect(() => {
    setLockeds(chek_bok); // Update lockeds with the value of tt
    setActivate(chek_active)
  }, [chek_bok, chek_active]); // Run this effect whenever tt changes

  useEffect(() => {
    console.log("Value of lockeds:", lockeds); // Log lockeds
  }, [lockeds, activate]); // Run

  const handleCheckboxChange = (e) => {
    setLockeds(e.target.checked);
  };


  const handleCheckboxActive = (e) => {
    setActivate(e.target.checked);
  };



  const handleSaveEdit = async (date) => {


    const responseData = {
      "price_book_name": datas.price_book_name,
      "month": datas.month,
      "year": datas.year,
      "active": activate,
      "locked": lockeds,
      "description": datas.description,
      "created_by": datas.created_by,
      "last_modified_by": datas.last_modified_by,
      "projected_HBA": datas.projected_HBA,
      "projected_GCNEWC": datas.projected_GCNEWC,
      "actual_GCNEWC_for_the_month": datas.actual_GCNEWC_for_the_month,
      "applicable_GCNEWC_for_the_month": datas.applicable_GCNEWC_for_the_month,
      "avg_GCNEWC_prev_QTR": datas.avg_GCNEWC_prev_QTR,
      "avg_GCNEWC_current_QTR": datas.avg_GCNEWC_current_QTR,
      "actual_HBA": datas.actual_HBA,
      "actual_HBA_1": datas.actual_HBA_1,
      "actual_HBA_2": datas.actual_HBA_2,
      "actual_HBA_2": datas.actual_HBA_3,
      "ici1_6500_GAR": datas.ici1_6500_GAR,
      "ici_2_5800_GAR": datas.ici_2_5800_GAR,
      "ici_3_5000_GAR": datas.ici_3_5000_GAR,
      "ici_4_4200_GAR": datas.ici_4_4200_GAR,
      "ici_5_3400_GAR": datas.ici_5_3400_GAR,
      "platts_K5900": datas.platts_K5900,
      "Nex": datas.Nex,
      "des_ARA": datas.des_ARA,
      "RB": datas.RB


    };
    console.log(JSON.stringify(responseData))

    fetch(`${API_URL}update/pricebook/${id}`, {
      method: 'PUT',
      body: JSON.stringify(responseData),
      headers: {
        Authorization: `Bearer ${decryptedAccessToken}`,
        'Content-Type': 'application/json'
      },

    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log(updatedData)
        fetchData();
        if (updatedData.msg === 'contact updated successfully') {

          // fetchData();
        }

        setData(updatedData);
        setIsEditMode(false);
      })
      .catch((error) => console.error(error));
  }


  // const url=API_URL+'get/all_hba/'+PBN;
  // console.log(url);
  // const fetchDataSupplier1 = async () => {
  //   console.log(url)
  //   try {
  //     const response = await axios.get(url, {
  //       headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
  //     );
  //     const responseData = response;
  //     setAgent(responseData)
  //      console.log(responseData)
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }; 
  // useEffect(() => {

  //   fetchDataSupplier1();
  // }, [decryptedAccessToken]);



  // useEffect(() => {

  //   fetchData1();
  // }, [decryptedAccessToken]);

  // const fetchData1 = async () => {
  //   try {
  //     const response = await axios.get(API_URL+'get/all_hba/'+pricename, { 
  //       headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
  //     );
  //     const data = response.data.records;     
  //     setAgent(data);   
  //     console.log(data)     
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };




  const hba = () => {
    navigate("/hba/hba-edit/", {
      state: { pricebookName: data1, id: dataID },
    });
  }





  return (
    <div id='view-page'>
      <Header />


      <div className="contactAccount fulid-section order-section">

        <div className="page-header btn-margin" id='add-account'>
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">Price Book Details</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">Dashboard / Price Book Details</li>
            </ol>
          </div>

        </div>
        <div className='tasks'>
          <div className=''>
            <div className='row'>


            </div></div>
          {isEditMode ? (

            <>
              <Row className="mb-3">
                <p className='edit-btn'>
                  <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
                  <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


                </p>
                <h4 className='heading'>Pricebook Detail Information</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Price Book Name"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Price Book Name"
                      name="price_book_name"
                      defaultValue={datas.price_book_name || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <label>Locked</label>
                  <input
                    type='checkbox'
                    name='locked'
                    checked={lockeds} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange} // Handle the change event
                    className='mx-3'
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <label>Active</label>
                  <input
                    type='checkbox'
                    name='active'
                    checked={activate} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxActive} // Handle the change event
                    className='mx-3'
                  />
                </Form.Group>        <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Month"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="month"
                      placeholder="Month"
                      defaultValue={datas.month || ""}
                      onChange={handleChangeInput}
                    >
                      <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"  className="textarea-half">
                  <FloatingLabel
                    controlId="floatingTextarea2"
                    label="Description"
                  >
                    <Form.Control
                      as="textarea"
                      placeholder="Description"
                      style={{ height: "100px" }}
                      name="description"
                      defaultValue={datas.description || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="year"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="year"
                      placeholder="Year"
                      defaultValue={datas.year || ""}
                      onChange={handleChangeInput}>
                      <option value="">--None--</option><option value="2021">2021</option><option value="2022">2022</option><option value="2023">2023</option><option value="2024">2024</option><option value="2025">2025</option><option value="2026">2026</option><option value="2015">2015</option><option value="2018">2018</option><option value="2019">2019</option><option value="2020">2020</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>

                <h4 className='heading'> Projected Index</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Projected HBA"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Projected HBA"
                      name="projected_HBA"
                      defaultValue={datas.projected_HBA || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Projected GCNEWC"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Projected GCNEWC"
                      name="projected_GCNEWC"
                      defaultValue={datas.projected_GCNEWC || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <h4 className='heading'>GCNEWC</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Actual GCNEWC for the month"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Actual GCNEWC for the month"
                      name="actual_GCNEWC_for_the_month"
                      defaultValue={datas.actual_GCNEWC_for_the_month || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Avg GCNEWC prev QTR"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Avg GCNEWC prev QTR"
                      name="avg_GCNEWC_prev_QTR"
                      defaultValue={datas.avg_GCNEWC_prev_QTR || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Applicable GCNEWC for the month"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Applicable GCNEWC for the month"
                      name="applicable_GCNEWC_for_the_month"
                      defaultValue={datas.applicable_GCNEWC_for_the_month || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Avg GCNEWC current QTR"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Avg GCNEWC current QTR"
                      name="avg_GCNEWC_current_QTR"
                      defaultValue={datas.avg_GCNEWC_current_QTR || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>


                <h4 className="heading">HBA</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Actual HBA"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Actual HBA"
                      name="actual_HBA"
                      defaultValue={datas.actual_HBA || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Actual HBA 1"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Actual HBA 1"
                      name="actual_HBA_1"
                      defaultValue={datas.actual_HBA_1 || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Actual HBA 2"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Actual HBA 2"
                      name="actual_HBA_2"
                      defaultValue={datas.actual_HBA_2 || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Actual HBA 3"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Actual HBA 3"
                      name="actual_HBA_3"
                      defaultValue={datas.actual_HBA_3 || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>

                <h4 className="heading">ICI Index</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ICI1 6500 GAR"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="ICI1 6500 GAR"
                      name="ici1_6500_GAR"
                      defaultValue={datas.ici1_6500_GAR || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ICI4 4200 GAR"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="ICI4 4200 GAR"
                      name="ici_4_4200_GAR"
                      defaultValue={datas.ici_4_4200_GAR || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ICI2 5800 GAR"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="ICI2 5800 GAR"
                      name="ici_2_5800_GAR"
                      defaultValue={datas.ici_2_5800_GAR || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ICI 5 3400 GAR"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="ICI 5 3400 GAR"
                      name="ci_5_3400_GAR"
                      defaultValue={datas.ci_5_3400_GAR || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ICI 3 5000 GAR"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="ICI 3 5000 GAR"
                      name="ici_3_5000_GAR"
                      defaultValue={datas.ici_3_5000_GAR || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                <h4 className="heading">Other Indexes</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="platts K5900"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="platts K5900"
                      name="platts_K5900"
                      defaultValue={datas.platts_K5900 || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Des ARA "
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Des ARA "
                      name="des_ARA"
                      defaultValue={datas.des_ARA || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nex"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Nex"
                      name="Nex"
                      defaultValue={datas.Nex || ""}
                      onChange={handleChangeInput} />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="RB"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="RB"
                      name="RB"
                      defaultValue={datas.RB || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <p className='edit-btn'>
                  <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
                  <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


                </p>

              </Row>
            </>) : (
            <>
              <div class="edit-delete"> <span id="edit" onClick={handleEditClick}>
                Edit
              </span>
                <span>
                  <Link to={'/pricebook/create-view-list'}> Cancel</Link>
                </span><span id="edit" onClick={hba}>Pricebook HBA</span><span>Delete</span><span>Clone</span></div>
              <div className="col-md-12" id='adatas'>
                <h4 className="heading">Price Book Information</h4>
                <hr></hr>
              </div>

              <>
                <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>Pricebook Code</span> </td>
                      <td>{datas.pricebook_code}</td>
                      <td id="td-right"><span></span></td>
                      <td>
                      </td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Price Book Name</span></td>
                      <td>{datas.price_book_name}
                      </td>
                      <td id="td-right"><span>locked</span> </td>
                      <td>{datas.locked === true ? "Yes" : "No"}</td>


                    </tr>

                    <tr>
                      <td id="td-right"><span>active</span> </td>
                      <td> {datas.active === true ? "Yes" : "No"}</td>
                      <td id="td-right"><span></span></td>
                      <td>
                      </td>

                    </tr>

                    <tr>
                      <td id="td-right"><span>Month</span> </td>
                      <td>{datas.month}</td>
                      <td id="td-right"><span>Description</span></td>
                      <td>
                        {datas.description}
                      </td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Year</span> </td>
                      <td>{datas.year}</td>
                      <td id="td-right"><span>Last modified by</span> </td>
                      <td>{datas.last_modified_by}</td>

                    </tr>
                  </tbody>
                </table>
                <div className="col-md-12" id='adatas'>
                  <h4 className="heading">Projected Index</h4>
                  <hr></hr>
                </div>
                <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>Projected HBA</span> </td>
                      <td>{datas.projected_HBA}</td>
                      <td id="td-right"><span>Projected GCNEWC</span></td>
                      <td>{datas.projected_GCNEWC}
                      </td>

                    </tr>
                  </tbody>
                </table>
                <div className="col-md-12" id='adatas'>
                  <h4 className="heading">GCNEWC</h4>
                  <hr></hr>
                </div>
                <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>Actual GCNEWC For The Month
                      </span> </td>
                      <td>{datas.actual_GCNEWC_for_the_month}</td>
                      <td id="td-right"><span>Avg GCNEWC Prev QTR</span></td>
                      <td>{datas.avg_GCNEWC_prev_QTR}
                      </td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Applicable GCNEWC For The Month
                      </span> </td>
                      <td>{datas.applicable_GCNEWC_for_the_month}</td>
                      <td id="td-right"><span>Avg GCNEWC Current QTR</span></td>
                      <td>{datas.avg_GCNEWC_prev_QTR}
                      </td>

                    </tr>


                  </tbody>
                </table>
                <div className="col-md-12" id='adatas'>
                  <h4 className="heading">HBA</h4>

                  <hr></hr>
                </div>

                <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>Actual HBA</span> </td>
                      <td>{datas.actual_HBA}</td>
                      <td id="td-right"><span></span></td>
                      <td>
                      </td>


                    </tr>

                    <tr>
                      <td id="td-right"><span>Actual HBA 1</span> </td>
                      <td>{datas.actual_HBA_1}</td>
                      <td id="td-right"><span></span> </td>
                      <td></td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Actual HBA 2</span> </td>
                      <td>{datas.actual_HBA_1}</td>
                      <td id="td-right"><span></span> </td>
                      <td></td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>Actual HBA 3</span> </td>
                      <td>{datas.actual_HBA_3}</td>
                      <td id="td-right"><span></span> </td>
                      <td></td>

                    </tr>
                  </tbody>
                </table>



                <div className="col-md-12" id='adatas'>
                  <h4 className="heading">ICI Index</h4>
                  <hr></hr>
                </div>
                <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                      <td id="td-right"><span>ICI 1 6500 GAR</span> </td>
                      <td>{datas.ici1_6500_GAR}</td>
                      <td id="td-right"><span>ICI 4 4200 GAR	</span></td>
                      <td>{datas.ici_4_4200_GAR}
                      </td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>ICI 2 5800 GAR</span> </td>
                      <td>{datas.ici_2_5800_GAR}</td>
                      <td id="td-right"><span>ICI 5 3400 GAR
                      </span></td>
                      <td>{datas.ici_5_3400_GAR}
                      </td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>ICI 3 5000 GAR	</span> </td>
                      <td>{datas.ici_2_5800_GAR}</td>
                      <td id="td-right"><span>
                      </span></td>
                      <td>
                      </td>

                    </tr>

                  </tbody>
                </table>
                <div className="col-md-12" id='adatas'>
                  <h4 className="heading">Other Indexes</h4>
                  <hr></hr>
                </div>
                <table class="table table-bordered account-table tables">
                  <tbody>

                    <tr>
                      <td id="td-right"><span>Platts K5900</span> </td>
                      <td>{datas.platts_K5900}</td>
                      <td id="td-right"><span>DES ARA
                      </span></td>
                      <td>{datas.des_ARA}
                      </td>

                    </tr>
                    <tr>
                      <td id="td-right"><span>NEX</span> </td>
                      <td>{datas.Nex}</td>
                      <td id="td-right"><span>RB</span></td>
                      <td>{datas.RB}
                      </td>

                    </tr>
                  </tbody>
                </table>
              </>



              <div>
                <div className="col-md-12" id='adatas'>
                  <h4 className="heading">HBA Calculation</h4>
                  <hr></hr>
                </div>
                <Row id='table-styles'>
                  <div className="col-md-12" id='head'>
                    <h4 className="heading"><span>HBA Calculation </span><span className="links" onClick={hba}> <Link >Edit</Link></span></h4>
                    <hr></hr>
                  </div>
                  <table id='hba' className="">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Platts K5900</th>
                        <th>HBA Day</th>
                        <th>GCNEW 6322</th>
                        <th>NEX</th>
                        {/* <th>HBA Day</th> */}
                        <th>Platts</th>

                      </tr>
                    </thead>

                    {agent.length > 0 ?

                      <tbody>
                        {
                          agent.map((y, i) => (
                            <tr>
                              <td><span id='codes'><Link >{y.hba_date}</Link></span></td>
                              <td>{y.platts_K5900}</td>
                              <td>{y.hba_day}</td>
                              <td>{y.GCNEWC_6322}</td>
                              <td>{y.NEX}</td>
                              {/* <td>{y.hba_day}</td>   */}
                              <td>{y.platts}</td>
                            </tr>
                          ))}
                      </tbody>
                      : <tbody>
                        <tr>

                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    }
                  </table>

                </Row>

              </div>
            </>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewPrice;