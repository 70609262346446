import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap"; 
import Header from '../Header' 
import Footer from '../Footer' 
import { API_URL } from '../config';
import { AES, enc } from 'crypto-js';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios"; 
import { useParams,useLocation, Link,useNavigate  } from "react-router-dom"; 
function  ContractShippmentDetailsList() { 
   const location=useLocation();
  const { number_of_shipment , quantityValue}=location.state;
  const navigate=useNavigate();
console.log(number_of_shipment,'*********',quantityValue)


   const params=useParams();
  
 
   const ids=params.id
  const number = number_of_shipment;

  const [dropDetails, setDropdetails] = useState([]);






 
    
  const [getPrice, setgetPrice] = useState([]);
  const [formDataList, setFormDataList] = useState([]);

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken,ids]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/price_items",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.priceitem;
      console.log(data);
      setgetPrice(data);
    } catch (error) {
      console.error(error);
    }
  }; 

  
  useEffect(() => {
    fetchDataPrice();
  }, [decryptedAccessToken]); 
  const fetchDataPrice = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/contract_shipbyID/${ids}`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      const new_data = data.contract_details[0]
      console.log(data)
      setDropdetails(data.contract_details[0]);
      const no_of_shipments_length = data.contract_details[0].no_of_shipments
      setFormDataList(Array.from({ length: parseInt(no_of_shipments_length, 10) }, () => ({
        laycan_month: new_data.laycan_month_from||'',
        laycan_year:new_data.laycan_year_from|| '',
        price_type: new_data.price_type||'',
        quantity: new_data.quantity|| '',
        sales_price_margin:new_data.sales_price_margin|| '',
        shipment_quantity_tolerance: new_data.shipment_quantity_tolerance||'',
        price:  new_data.price||''
      })));
    } catch (error) {
      console.error(error);
    }
  };

  console.log(dropDetails)

  // //////
  // // const [inputValues, setInputValues] = useState({
  // //   name: '',
  // //   email: '',
  // //   age: ''
  // // });

  // // // Function to handle changes in input fields
  // // const handleInputChange = (e) => {
  // //   const { name, value } = e.target;
  // //   setInputValues({
  // //     ...inputValues,
  // //     [name]: value
  // //   });
  // // };

  // // <input
  // //         type="email"
  // //         name="email"
  // //         value={inputValues.email  ||   }
  // //         onChange={handleInputChange}
  // //       />



  // //////







  
  // // const handleInputChange = (e) => {
  // //   // const updatedFormData = [...formData]; 
  // //   // if (!updatedFormData[index]) {
  // //   //   updatedFormData[index] = {};
  // //   // }
  // //   // updatedFormData[index][field] = value;
  // //   // setFormData(updatedFormData);
  // //   const { name, value } = e.target;
  // //   setFormData({
  // //       ...formData,
  // //       [name]: value
  // //     });
  // // };



  // const [formData, setFormData] = useState({
  //   laycan_month: '',
  //   laycan_year: '',
  //   price_type: '',
  //   quantity: '',
  //   sales_price_margin: '',
  //   shipment_quantity_tolerance: '',
  //   price: ''
  // });
  
  // useEffect(() => {
  //   if (dropDetails) {
  //     setFormData({
  //       laycan_month: dropDetails.laycan_month_from || '',
  //       laycan_year: dropDetails.laycan_year_from || '',
  //       price_type: dropDetails.price_type || '',
  //       quantity: dropDetails.quantity || '',
  //       sales_price_margin: dropDetails.sales_price_margin || '',
  //       shipment_quantity_tolerance: dropDetails.shipment_quantity_tolerance || '',
  //       price: dropDetails.price || ''
  //     });
  //   }
  // }, [dropDetails]);

  console.log(dropDetails)

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setFormDataList(prevFormDataList => {
      const newList = [...prevFormDataList];
      newList[index] = { ...newList[index], [name]: value };
      return newList;
    });
  };


  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   setFormDataList(prevFormDataList => {
  //     const newList = [...prevFormDataList];
  //     newList[index] = { ...newList[index], [name]: value };
  //     return newList;
  //   });
  // };
  const handleSubmit = async event => {
    event.preventDefault();
    console.log(JSON.stringify(formDataList))
    const encryptedAccessToken = localStorage.getItem('access_token');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
      
  fetch(API_URL+'add/contractship_details/'+ids, {
    method: "POST",
    body: JSON.stringify(formDataList),
    headers: { Authorization: `Bearer ${decryptedAccessToken}`,
    'Content-Type': 'application/json' },
  })
    .then((response) => response.json())
    .then((response) => { 
      navigate(`/contract-shipment/detail/${ids}`)
      // if (response.msg ==='Added successfully') { 
       console.log(response)
        toast.success('Data Submitted Successfully', {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
         
      // } 
      // else {
      //   // console.error('Failed to submit data');
      // }
     
    })
      
  }
};
  
  return (

    <div className="contactAccount" id='contract-ship-drop'>
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Contract Shipment </h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Contract Shipment</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
         
    </div> 
  </div>   
  </div>
  <form onSubmit={handleSubmit} id='contactLists'>
  <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract-shipment/detail/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
  <table>
            <thead>
              <tr>
                <th>Laycan Month</th>
                <th>Laycan Year</th>
                <th>Price</th>
                <th>Price Type</th>
                <th>Quantity</th>
                <th>Sales Price Margin</th>
                <th>shipment quantity tolerance</th>
              </tr>
            </thead>
            <tbody>
 {formDataList.map((formData, index) => (          
          <tr key={index}>
            <td>
          {/* <input value={index}></input> */}
            
            <select 
            className='form-control'
            placeholder="Laycan Month"
            name='laycan_month'
            value={formData.laycan_month || (dropDetails && dropDetails.laycan_month_from)}
            onChange={e => handleInputChange(e, index)}>
             <option value='' >---None---</option><option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
            </select>
            </td>
            <td>
              <select className="form-control"
              placeholder="Laycan Year"
              name='laycan_year'
              value={formData.laycan_year || dropDetails.laycan_year_from}
              onChange={e => handleInputChange(e, index)}>
              <option value='' >---None---</option>
    <option value="2021">2021</option>
    <option value="2022">2022</option>
    <option value="2023">2023</option>
    <option value="2024">2024</option>
    <option value="2025">2025</option>
    <option value="2026">2026</option>
    <option value="2015">2015</option>
    <option value="2019">2019</option>
    <option value="2020">2020</option>
              </select>
            
            </td>
            <td>
            <input
              type="text"
              className="form-control"
              placeholder="Price"
              name='price'
              value={formData.price || dropDetails.price}
              onChange={e => handleInputChange(e, index)}
              />
  </td>
  <td>
             
            <select className="form-control"
              placeholder="Price Type"
              name='price_type'
              value={formData.price_type || dropDetails.price_type}
              onChange={e => handleInputChange(e, index)}>
            <option value="">---None---</option>
                    {getPrice.map((x) => {
                      return (
                        <option value={x.price_type_name}>
                          {x.price_type_name}
                        </option>
                      );
                    })}
                  
            </select>
  </td>     <td>
            <input
              type="text"
              className="form-control"
              placeholder="Quantity"
              name='quantity'
              value={formData.quantity || dropDetails.quantity}
              onChange={e => handleInputChange(e, index)}
            />
  </td>
  <td>
    
            <input
              type="text"
              placeholder="Sales Price Margin"
              className="form-control"
              name='sales_price_margin'
              value={formData.sales_price_margin || dropDetails.sales_price_margin}
              onChange={e => handleInputChange(e, index)}
            />
  </td>
          
  <td>
            <input
              type="text"
              name='shipment_quantity_tolerance'
              placeholder="shipment quantity tolerance"
              className="form-control" 
              value={formData.shipment_quantity_tolerance || dropDetails.shipment_quantity_tolerance}
              onChange={e => handleInputChange(e, index)}
            />
  </td>
          
          </tr>
         
        ))}
</tbody></table>       <br/>

<p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract-shipment/detail/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      </form>
      <p></p><p></p>
    <Footer/>
    
    </div>
  );
}

export default ContractShippmentDetailsList;
