import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap"; 
import Header from '../Header' 
import Footer from '../Footer' 
import { AES, enc } from 'crypto-js';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import $ from 'jquery' 
import { API_URL } from '../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { parse, format } from 'date-fns';
import DatePicker from "react-datepicker";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios"; 
import { useParams,Link , useNavigate } from "react-router-dom"; 
function  ContractShippmentDetailsLongterm() { 
  
  const navigate=useNavigate()
  const params=useParams();
  const ids=params.id
  console.log(ids)
  const [datas,setDatas]=useState([])
  const [datas1,setDatas1]=useState([])
  const [history,setHistory]=useState([])
  const [orders,setOrders]=useState([])
  const [tcf,setTcf]=useState([])
  const [data, setData] = useState({}); 
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [product,setProduct]=useState([])
  const [companies, setCompanies] = useState([]);
  const [check, setCheck1] = useState(false);
  const [contractShipment, setContractShipment] = useState([]);
  
  useEffect(() => {
    fetchCompany();
  }, [decryptedAccessToken]);

  const fetchCompany = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/contract",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.contract;
      setCompanies(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const birthDate = startDate ? `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}` : null;
 

  const BankDate = endDate ? `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() + 1).toString().padStart(2, '0')}/${endDate.getFullYear()}` : null;
  
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  
  const [getPrice, setPrice] = useState([]);
  useEffect(() => {
    fetchDataPrice();
  }, [decryptedAccessToken]); 
  const fetchDataPrice = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/price_items",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.priceitem;
      setPrice(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };



const [fromDate, setFromDate]=useState(null)


const [contractID, setContractID]=useState([])
console.log(contractID);
const [tcfList, setTcfList]=useState([])
  useEffect(() => {
   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/contract_shipbyID/'+ids, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      console.log(response)
      if(response.status===200){
      const data = response.data;
      const contract_shipID=response.data.contract_details[0].contract_id;
      setContractID(contract_shipID)
      setDatas(data.contract_details);
      setDatas1(data.contract_details[0]);
      setContractShipment(data.child_contract_shipments)
      console.log(response)

      setOrders(data.orders)
      setProduct(data.products)
      setTcfList(data.tcf) 
      setHistory(data.history)
      $(document).ready(function(){
        // $('#tables').DataTable().destroy();
        // setTimeout(()=>{
          $('#tables').DataTable();
          $('#tcf').DataTable();
          $('#orders').DataTable();
          $('#historys').DataTable();
          $('#product').DataTable();
          $('#contract-ship').DataTable();
          
        // },500)
       })
      let fromDate=data.contract_details[0].laycan_from_date;
      const from_date = fromDate?parse(fromDate, 'dd/MM/yyyy', new Date()):null;
      const fromdateResult = from_date?format(from_date, 'dd/MM/yyyy'):null; 
      setFromDate(fromdateResult)
      
      }else{
        toast.error("No Data Available", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",})
      }
    } catch (error) {
      console.error(error);
    }
  }; 


  // useEffect(() => {
   
  //   fetchData3();
  // }, [decryptedAccessToken]);

  // const fetchData3 = async () => {
  //   try {
  //     const response = await axios.get(API_URL+'get/contractbyID/'+ids, {
  //       headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
  //     );
  //     console.log(response)
      
      
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }; 

   const noOfshipment=()=>{

    navigate("/shippment/contract-shippment-details-lists/"+ids,
     {                        
      state: { number_of_shipment: datas[0].no_of_shipments, quantityValue:datas[0].quantity },


    });   


   }
   
   $('#tables').DataTable();
   $('#tcf').DataTable();
   $('#orders').DataTable();
   $('#history').DataTable();
   $('#product').DataTable();
   $('#contract-ship').DataTable();

  //  edit
  const handleEditClick = () => {
    $('#tables').DataTable();
          $('#tcf').DataTable();
          $('#orders').DataTable();
          $('#history').DataTable();
          $('#product').DataTable();
          $('#contract-ship').DataTable();
    setIsEditMode(true);
    setEditedData(data);
  };
  
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas1((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);
  };
  
  const handleSaveEdit = (e) => {
    $('#tables').DataTable();
          $('#tcf').DataTable();
          $('#orders').DataTable();
          $('#history').DataTable();
          $('#contract-ship').DataTable();
          $('#product').DataTable();
    e.preventDefault();  
    const responseData={
      "currency":datas1.currency,
"exchange_rate":datas1.exchange_rate,
"contract_shipment_status":datas1.contract_shipment_status,
"record_type":datas1.record_type,
"contract":datas1.contract,
"parent_shipment":datas1.parent_shipment,
"order_quantity":datas1.order_quantity,
"shipping_term":datas1.shipping_term,
"price":datas1.price,
"price_type":datas1.price_type,
"price_basis":datas1.price_basis,
"sales_price_margin":datas1.sales_price_margin,
"price_pmt_in_words":datas1.price_pmt_in_words,
"quantity_type":datas1.quantity_type,
"quantity":datas1.quantity,
"quantity_from":datas1.quantity_from,
"quantity_to":datas1.quantity_to,
"no_of_shipments":datas1.no_of_shipments,
"child_shipment_count":datas1.child_shipment_count,
"shipment_quantity_tolerance":datas1.shipment_quantity_tolerance,
"tcf_count":datas1.tcf_count,
"product_count":datas1.product_count,
"no_of_orders":datas1.no_of_orders,
"quantity_info ":datas1.quantity_info,
"laycan_year_from":datas1.laycan_year_from,
"laycan_year_to":datas1.laycan_year_to,
"laycan_month_from":datas1.laycan_month_from,
"laycan_month_to":datas1.laycan_month_to,
"laycan_from_date":birthDate,
"laycan_to_date":BankDate,
"shipment_month":datas1.shipment_month,
"laycan":datas1.laycan,
"shipment_quantity":datas1.shipment_quantity

         };
         console.log(responseData)
         axios.put(`${API_URL}update/contract_shipments/${ids}`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            'Content-Type': 'application/json',
          },
        })
          .then((response) => {
           
    
            if(response.status===200){
              toast.success("Order Updated Successfully",
              {
               position: "bottom-center",
               autoClose: 1000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "light",
               });
      fetchData();
       
    
    
      setData(response);
      setIsEditMode(false);
    }
    
    else{
    toast.error("Check with fields", {
      position: "bottom-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",})
    }
    })
      .catch((error) => console.error(error));
  };
  
  const createProduct=()=>{
     
    navigate("/contract/add/"+ids,{  

     state: { contractshipment: datas[0].contract_ship_code, buyercontract:datas[0].contract, contractshipmentID: contractID },


   }); 
  }
 

  const createTcf = () => {
    fetch(`${API_URL}add/contract_ship/tcf/${ids}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${decryptedAccessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((response) => {
        console.log('Response:', response); // Log the entire response for debugging
        // navigate(`/shipment/contract-shipment-details/${ids}`)
        // Check the HTTP status code
        if (response.status === 200) {
          console.log('Success:', response.status);
          toast.success('TCF Created Successfully', {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        } else {
          console.log('Unexpected status code:', response.status);
          // Handle unexpected status code
        }
      })
      .catch((error) => {
        console.error(error);
        // Handle the error, you can show a toast or any other error handling logic here
      });
  };
  


if (datas1.record_type ===  "Buyer - Long Term") {
    
   
     return (
   
       <div className="priceAccount" id='view-contract-ship'>
       <Header/>
       <div>
       <div className="page-header" id=''>
       <div>
         <h2 className="main-content-title tx-24 mg-b-5">Contract Shippment </h2>
         <ol className="breadcrumb">
           <li className="breadcrumb-item active" aria-current="page">Dashboard / shipment / Contract Shippment</li>
         </ol>
       </div>
       <div className="d-flex">
         <div className="justify-content-center">
         
           {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
            <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
           </button> */}
           
         </div>
       </div> 
     </div> 
         
       </div>
       {isEditMode ? (
     <>
     <div className="tasks">
       <p className="edit-btn">
         <button
           className="btn btn-primary"
           onClick={handleSaveEdit}
         >
           Save
         </button>
         <button
           className="btn btn-primary"
           onClick={handleCancelEdit}
         >
           Cancel
         </button>
       </p>
       <div className="row">
         <div className="col-md-12" id="head">
           <h4 className="heading">Contract Shipment Details</h4>

           <hr></hr>
         </div>
       </div>

       <Row className="mb-3">
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingSelect"
             className="dropDown"
             label="Currency"
           >
             <Form.Select
               aria-label="Floating label select example"
               name="currency"
               placeholder="Currency"
               defaultValue={datas1.currency || ""}
               onChange={handleChangeInput}
             >
               <option value="">Select </option>
               <option value="IDR">IDR </option>
               <option value="USD">USD </option>
             </Form.Select>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Record type"
             className="mb-3"
           >
             <Form.Control
               type="text"
               placeholder="Record type"
               name="record_type"
               className="record-type"
               defaultValue={datas1.record_type}
               onChange={handleChangeInput}
             />
           </FloatingLabel>
         </Form.Group>

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Exchange rate"
             className="mb-3"
           >
             <Form.Control
               type="text"
               placeholder="Exchange rate"
               name="exchange_rate"
               defaultValue={datas1.exchange_rate || ""}
               onChange={handleChangeInput}
             />
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
     <FloatingLabel
       controlId="floatingInput"
       label="Contract"
       className="mb-3"
       
       
     >
       <Form.Control
         type="text"
         placeholder="Contract"
         name="contract"
         defaultValue={datas1.contract || ""}
         onChange={handleChangeInput}/>
     </FloatingLabel>
   </Form.Group>
         {/* <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingSelect"
             className="dropDown"
             label="Contract"
           >
             <Form.Select
               aria-label="Floating label select example"
               name="contract"
               placeholder="Contract"
               defaultValue={datas1.contract || ""}
               onChange={handleChangeInput}
             >
               <option value="">Select</option>
               {companies.map((x) => {
                 return (
                   <option value={x.rwood_contract_number}>
                     {x.rwood_contract_number}
                   </option>
                 );
               })}
             </Form.Select>
           </FloatingLabel>
         </Form.Group> */}
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingSelect"
             className="dropDown"
             label="Contract shipment status"
           >
             <Form.Select
               aria-label="Floating label select example"
               name="contract_shipment_status"
               placeholder="Contract shipment status"
               defaultValue={
                 datas1.contract_shipment_status || "Draft"
               }
               onChange={handleChangeInput}
             >
               <option value="">--None--</option>
               <option value="Activated">Activated</option>
               <option value="Completed">Completed</option>
               <option value="Cancelled">Cancelled</option>
               <option value="Draft">Draft</option>
               <option value="Shipments - Arutmin">
                 Shipments - Arutmin
               </option>
               <option value="Cancelled with Penalty">
                 Cancelled with Penalty
               </option>
             </Form.Select>
           </FloatingLabel>
         </Form.Group>

         <Form.Group as={Col} md="6" id="lockeds">
           <input
             type="checkbox"
             name="locked"
             onChange={(e) => setCheck1(e.target.checked)}
           />
           <label>Locked</label>
         </Form.Group>
       </Row>
       <Row>
         <h4 className="heading">Price</h4>

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingSelect"
             className="dropDown"
             label="Shipping Term"
           >
             <Form.Select
               aria-label="Floating label select example"
               name="shipping_term"
               placeholder="Shipping Term"
               defaultValue={datas1.shipping_term || ""}
               onChange={handleChangeInput}
             >
               <option value="">--None--</option><option value="FOB-B">FOB-B</option><option value="FOB-MV">FOB-MV</option><option value="CFR-MV">CFR-MV</option><option value="CIF-MV">CIF-MV</option><option value="FAS">FAS</option>
             </Form.Select>
           </FloatingLabel>
         </Form.Group>

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Price"
             className="mb-3"
           >
             <Form.Control
               type="text"
               placeholder="Price"
               name="price"
               defaultValue={datas1.price || ""}
               onChange={handleChangeInput}
             />
           </FloatingLabel>
         </Form.Group>

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingSelect"
             className="dropDown"
             label="Price type"
           >
             <Form.Select
               aria-label="Floating label select example"
               name="price_type"
               placeholder="Price type"
               defaultValue={datas1.price_type || ""}
               onChange={handleChangeInput}
             >
               <option value="">Select</option>
               {getPrice.map((x) => {
                 return (
                   <option value={x.price_type_name}>
                     {x.price_type_name}
                   </option>
                 );
               })}
             </Form.Select>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingSelect"
             className="dropDown"
             label="Price Basis"
           >
             <Form.Select
               aria-label="Floating label select example"
               name="price_basis"
               placeholder="Price Basis"
               defaultValue={datas1.price_basis || ""}
               onChange={handleChangeInput}
             >
               <option value="">--None--</option>
               <option value="GCV (ARB)">GCV (ARB)</option>
               <option value="GCV (ADB)">GCV (ADB)</option>
               <option value="NCV (ARB)">NCV (ARB)</option>
             </Form.Select>
           </FloatingLabel>
         </Form.Group>
         {datas1.price_type === "Fix Price" ? (
           <Form.Group as={Col} md="6">
             <FloatingLabel
               controlId="floatingInput"
               label="Sales price margin"
               className="mb-3"
             >
               <Form.Control
                 type="text"
                 placeholder="Sales price margin"
                 name="sales_price_margin"
                 defaultValue={datas1.sales_price_margin || ""}
                 onChange={handleChangeInput}
                 disabled
               />
             </FloatingLabel>
           </Form.Group>
         ) : (
           <Form.Group as={Col} md="6">
             <FloatingLabel
               controlId="floatingInput"
               label="Sales price margin"
               className="mb-3"
             >
               <Form.Control
                 type="text"
                 placeholder="Sales price margin"
                 name="sales_price_margin"
                 defaultValue={datas1.sales_price_margin || ""}
                 onChange={handleChangeInput}
               />
             </FloatingLabel>
           </Form.Group>
         )}

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Price pmt in words"
             className="mb-3"
           >
             <Form.Control
               type="text"
               placeholder="Price pmt in words"
               name="price_pmt_in_words"
               defaultValue={datas1.price_pmt_in_words || ""}
               onChange={handleChangeInput}
             />
           </FloatingLabel>
         </Form.Group>
       </Row>
       <Row>
         <h4 className="heading">Delivery</h4>

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingSelect"
             className="dropDown"
             label="Laycan Year From"
           >
             <Form.Select
               aria-label="Floating label select example"
               name="laycan_year_from"
               placeholder="Laycan Year From"
               className="error-validation"
               defaultValue={datas1.price_pmt_in_words || ""}
               onChange={handleChangeInput}
             >
               <option value="">---None---</option>
               <option value="2024">2024</option>
               <option value="2025">2025</option>
               <option value="2026">2026</option>
               <option value="2027">2027</option>
               <option value="2028">2028</option>
               <option value="2029">2029</option>
               <option value="2030">2030</option>
               <option value="2018">2018</option>
               <option value="2019">2019</option>
               <option value="2020">2020</option>
               <option value="2021">2021</option>
               <option value="2022">2022</option>
               <option value="2023">2023</option>
             </Form.Select>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6"> </Form.Group>
         {/* <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Laycan From Date"
             className="mb-3 datepick"
           >
             <DatePicker
               selected={startDate}
               onChange={handleStartDateChange}
               selectsStart
               dateFormat="dd/MM/yyyy"
               minDate={startDate}
               startDate={startDate}
               placeholderText="Select"
             />
           </FloatingLabel>
         </Form.Group> */}

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingSelect"
             className="dropDown"
             label="Laycan Year To"
           >
             <Form.Select
               aria-label="Floating label select example"
               name="laycan_year_to"
               placeholder="Laycan Year To"
               className="error-validation"
               defaultValue={datas1.laycan_year_to || ""}
               onChange={handleChangeInput}
             >
               <option value="">---None---</option>
               <option value="2024">2024</option>
               <option value="2025">2025</option>
               <option value="2026">2026</option>
               <option value="2027">2027</option>
               <option value="2028">2028</option>
               <option value="2029">2029</option>
               <option value="2030">2030</option>
               <option value="2018">2018</option>
               <option value="2019">2019</option>
               <option value="2020">2020</option>
               <option value="2021">2021</option>
               <option value="2022">2022</option>
               <option value="2023">2023</option>
             </Form.Select>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6"> </Form.Group>
         {/* <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Laycan To Date"
             className="mb-3 datepick"
           >
             <DatePicker
               selected={endDate}
               onChange={handleEndDateChange}
               dateFormat="dd/MM/yyyy"
               startDate={endDate}
               placeholderText="Select"
             />
           </FloatingLabel>
         </Form.Group> */}

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingSelect"
             className="dropDown"
             label="Laycan Month From"
           >
             <Form.Select
               aria-label="Floating label select example"
               name="laycan_month_from"
               placeholder="Laycan Month From"
               className="error-validation"
               defaultValue={datas1.laycan_month_from || ""}
               onChange={handleChangeInput}
             >
               <option value="January">January</option>
               <option value="February">February</option>
               <option value="March">March</option>
               <option value="April">April</option>
               <option value="May">May</option>
               <option value="June">June</option>
               <option value="July">July</option>
               <option value="August">August</option>
               <option value="September">September</option>
               <option value="October">October</option>
               <option value="November">November</option>
               <option value="December">December</option>
             </Form.Select>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6"></Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingSelect"
             className="dropDown"
             label="Laycan Month To"
           >
             <Form.Select
               aria-label="Floating label select example"
               name="laycan_month_to"
               placeholder="Laycan Month To"
               className="error-validation"
               defaultValue={datas1.laycan_month_to || ""}
               onChange={handleChangeInput}
             >
               <option value="January">January</option>
               <option value="February">February</option>
               <option value="March">March</option>
               <option value="April">April</option>
               <option value="May">May</option>
               <option value="June">June</option>
               <option value="July">July</option>
               <option value="August">August</option>
               <option value="September">September</option>
               <option value="October">October</option>
               <option value="November">November</option>
               <option value="December">December</option>
             </Form.Select>
           </FloatingLabel>
         </Form.Group>
       </Row>
       <Row>
         <h4 className="heading">Quanitity</h4>

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingSelect"
             className="dropDown"
             label="Quantity Type"
           >
             <Form.Select
               aria-label="Floating label select example"
               name="quantity_type"
               placeholder="Quantity Type"
               defaultValue={datas1.quantity_type || ""}
               onChange={handleChangeInput}
             >
               <option value="">--None--</option>
               <option value="Base Quantity">Base Quantity</option>
               <option value="Buyers Option">Buyers Option</option>
             </Form.Select>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="No of Shipments"
             className="mb-3"
           >
             <Form.Control
               type="number"
               placeholder="No of Shipments"
               name="no_of_shipments"
               defaultValue={datas1.no_of_shipments || ""}
               onChange={handleChangeInput}
             />
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Quantity"
             className="mb-3"
           >
             <Form.Control
               type="text"
               placeholder="Quantity"
               name="quantity"
               defaultValue={datas1.quantity || ""}
               onChange={handleChangeInput}
             />
           </FloatingLabel>
         </Form.Group>

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Child Shipment Count"
             className="mb-3"
           >
             <Form.Control
               type="number"
               placeholder="Child Shipment Count"
               name="child_shipment_count"
               defaultValue={datas1.child_shipment_count || ""}
               onChange={handleChangeInput}
             />
           </FloatingLabel>
         </Form.Group>

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Quantity From"
             className="mb-3"
           >
             <Form.Control
               type="number"
               placeholder="Quantity From"
               name="quantity_from"
               defaultValue={datas1.quantity_from || ""}
               onChange={handleChangeInput}
             />
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">  </Form.Group>
         {/* <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Shipment Quantity Tolerance"
             className="mb-3"
           >
             <Form.Control
               type="number"
               placeholder="Shipment Quantity Tolerance"
               className="error-validation"
               name="shipment_quantity_tolerance"
               defaultValue={
                 datas1.shipment_quantity_tolerance || ""
               }
               onChange={handleChangeInput}
             />
           </FloatingLabel>
         </Form.Group> */}

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Quantity To"
             className="mb-3"
           >
             <Form.Control
               type="number"
               placeholder="Quantity To"
               name="quantity_to"
               defaultValue={datas1.quantity_to || ""}
               onChange={handleChangeInput}
             />
           </FloatingLabel>
         </Form.Group>
       </Row>
       <Row>
         <h4 className="heading">System Information</h4>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="TCF Count"
             className="mb-3"
           >
             <Form.Control
               type="number"
               placeholder="TCF Count"
               name="tcf_count"
               defaultValue={datas1.tcf_count || ""}
               onChange={handleChangeInput}
             />
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="No of orders"
             className="mb-3"
           >
             <Form.Control
               type="number"
               placeholder="No of orders"
               name="no_of_orders"
               defaultValue={datas1.no_of_orders || ""}
               onChange={handleChangeInput}
             />
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Count"
             className="mb-3"
           >
             <Form.Control
               type="number"
               placeholder="Product Count"
               name="product_count"
               defaultValue={datas1.product_count || ""}
               onChange={handleChangeInput}
             />
           </FloatingLabel>
         </Form.Group>

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Order Quantity"
             className="mb-3"
           >
             <Form.Control
               type="number"
               placeholder="Order Quantity"
               name="order_quantity"
               defaultValue={datas1.order_quantity || ""}
               onChange={handleChangeInput}
             />
           </FloatingLabel>
         </Form.Group>
       </Row>
       <p></p><p></p>
       <p className="edit-btn">
         <button
           className="btn btn-primary"
           onClick={handleSaveEdit}
         >
           Save
         </button>
         <button
           className="btn btn-primary"
           onClick={handleCancelEdit}
         >
           Cancel
         </button>
       </p>
     </div>
   </>
  ):(
  <div className="tasks" id='view-page'>
     <p className='edit-btn'>
   
   <button id='edits' onClick={handleEditClick}>
                             Edit
                           </button>
                           {/* <Link to={`/contract-shipment/create-view-list`}> <button id='edits'>
                              Cancel
                            </button></Link>
                           <button id='edits' onClick={createProduct}>
                             Create Product
                           </button>
                           <Link><button id='edits' onClick={createTcf}>
                             Create TCF
                           </button></Link>
                           <Link to={'/contract-shipment/new-order/'+ids}><button id='edits'>
                             Create Order
                           </button></Link> */}
                           <button className='btn btn-primary' id='edits' onClick={noOfshipment}>Contract Shipment Details</button> 
   </p> 
                    {datas.length > 0 ?(
                    datas.map((x)=>(
                      
                    <> 
                    {/* <p id="top-header"><span class="button add-accountrecord" aria-describedby="popup-54"> RW Payment/Receipt LineItem </span></p> */}
  
  
                     <div className="">
                       <div className="row">
                         <div className="col-md-12" id="head">
                           <h4 className="heading">Contract Shipment Details</h4>
   
                           <hr></hr>
                         </div>
                       </div>
                       <table class="table table-bordered account-table tables">
                         <tbody>
                           <tr>
                             <td id="td-right"><span>contract Shipment No</span> </td>
                             <td>{x.contract_ship_code}</td>
                             <td id="td-right"><span>Record Type</span></td>
                             <td>{x.record_type}
                             </td>
                             
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>Currency</span>
                             </td>
                             <td>
                               {x.currency}
                             </td>
                             <td id="td-right">
                               <span>
                                 Contract{" "}
                                 {/* <OverlayTrigger
                                   placement="right"
                                   delay={{ show: 250, hide: 400 }}
                                   overlay={renderTooltip}
                                 >
                                   <Button variant="success" className="tooltips">
                                     <IoIosHelpCircle id="help" />
                                   </Button>
                                 </OverlayTrigger>{" "} */}
                               </span>
                               </td><td>
                               <Link to={ `/contract/contract-details/${x.contract_id}`} >  {x.contract} </Link>
                             </td>
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>Exchange Rate</span>
                               </td>
                               <td>
                                {x.exchange_rate}
                             </td>
                             <td id="td-right">
                               <span>Locked</span>
                               </td>
                               <td>
                                {x.locked}
                             </td>
                           </tr>
                           <tr>
                       <td id="td-right">
                       <span>Contract Shipment Status</span>
                           </td><td>
                            {x.contract_shipment_status}
                          </td>
                          <td id="td-right">
                       <span></span>
                           </td><td>
                             
                          </td>
                        </tr>
                        
                         </tbody>
                       </table>
                     </div>
                     <div className="">
                       <div className="row">
                         <div className="col-md-12" id="head">
                           <h4 className="heading">Price</h4>
                           <hr></hr>
                         </div>
                       </div>
                       <table class="table table-bordered account-table tables">
                         <tbody>
                         <tr>
                             <td id="td-right"><span></span> </td>
                             <td></td>
                             <td id="td-right"><span>Pricing</span></td>
                             <td>{x.pricing}
                             </td>
                             
                           </tr>
                           <tr>
                             <td id="td-right"><span>Shipping Term</span> </td>
                             <td>{x.shipping_term}</td>
                             <td id="td-right"><span>Price</span></td>
                             <td>{x.price}
                             </td>
                             
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>price type</span>
                             </td>
                             <td>
                               {x.price_type}
                             </td>
                             <td id="td-right">
                               <span>
                               price basis{" "}
                                 {/* <OverlayTrigger
                                   placement="right"
                                   delay={{ show: 250, hide: 400 }}
                                   overlay={renderTooltip}
                                 >
                                   <Button variant="success" className="tooltips">
                                     <IoIosHelpCircle id="help" />
                                   </Button>
                                 </OverlayTrigger>{" "} */}
                               </span>
                               </td><td>
                               {x.price_basis}
                             </td>
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>sales price margin</span>
                               </td>
                               <td>
                                {x.sales_price_margin}
                             </td>
                             <td id="td-right">
                               <span>price PMT words</span>
                               </td>
                               <td>
                                {x.price_pmt_in_words}
                             </td>
                           </tr>
                        
                         </tbody>
                       </table>
                     </div>

<div className="">
                       <div className="row">
                         <div className="col-md-12" id="head">
                           <h4 className="heading">Delivery</h4>
                           <hr></hr>
                         </div>
                       </div>
                       <table class="table table-bordered account-table tables">
                         <tbody>
                           <tr>
                             <td id="td-right"><span>Laycan Year From</span> </td>
                             <td>{x.laycan_year_from}</td>
                             <td id="td-right">
                               <span>Laycan From Date</span>
                             </td>
                             <td>
                               {x.laycan_from_date}
                             </td>
                             
                           </tr> 
                           <tr>
                           <td id="td-right">
                               <span>laycan year to</span>
                               </td>
                               <td>
                                {x.laycan_year_to}
                             </td>
                             <td id="td-right">
                               <span>laycan to date</span>
                               </td>
                               <td>
                                {x.laycan_to_date}
                             </td>
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>laycan month from</span>
                               </td>
                               <td>
                                {x.laycan_month_from}
                             </td>
                             <td id="td-right">
                               <span></span>
                               </td>
                               <td>
                               
                             </td>
                             
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>laycan month to</span>
                               </td>
                               <td>
                                {x.laycan_month_to}
                             </td>
                             <td id="td-right">
                               <span></span>
                               </td>
                               <td>
                               
                             </td>
                             
                           </tr>
                           
                         </tbody>
                       </table>
                     </div>     
                     
                     <div className="">
                       <div className="row">
                         <div className="col-md-12" id="head">
                           <h4 className="heading">Quantity</h4>
                           <hr></hr>
                         </div>
                       </div>
                       <table class="table table-bordered account-table tables">
                         <tbody>
                           <tr>
                           <td id="td-right"><span>no of shipments</span></td>
                             <td>{x.no_of_shipments}
                             </td>
                             <td id="td-right">
                              {/* <span>quantity info</span>  */}
                              </td>
                             <td>
                              {/* {x.quantity_info} */}
                              </td>
                            
                             
                           </tr>
                           <tr>
                           <td id="td-right"><span>quantity Type</span> </td>
                             <td>{x.quantity_type}</td>
                          
                           <td id="td-right">
                               <span>child shipment count</span>
                             </td>
                             <td>
                               {x.child_shipment_count}
                             </td>
                             
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>
                               Quantity
                                 {/* <OverlayTrigger
                                   placement="right"
                                   delay={{ show: 250, hide: 400 }}
                                   overlay={renderTooltip}
                                 >
                                   <Button variant="success" className="tooltips">
                                     <IoIosHelpCircle id="help" />
                                   </Button>
                                 </OverlayTrigger>{" "} */}
                               </span>
                               </td><td>
                               {x.quantity}
                             </td>
                           <td id="td-right">
                               <span>shipment quantity tolerance</span>
                               </td>
                               <td>
                                {x.shipment_quantity_tolerance}
                             </td>
                            
                           </tr>
                           <tr>
                            
                           <td id="td-right">
                               <span>quantity from</span>
                               </td>
                               <td>
                                {x.quantity_from}
                             </td>
                            
                           <td id="td-right">
                               <span></span>
                               </td>
                               <td>
                                 
                             </td>
                             
                           </tr>
                           <tr>
                            
                           
                            <td id="td-right">
                                <span>quantity to</span>
                                </td>
                                <td>
                                 {x.quantity_to}
                              </td>
                            <td id="td-right">
                                <span></span>
                                </td>
                                <td>
                                  
                              </td>
                              
                            </tr>
                         </tbody>
                       </table>
                     </div> 

                     
                     <div className="">
                       <div className="row">
                         <div className="col-md-12" id="head">
                           <h4 className="heading">System Information</h4>
                           <hr></hr>
                         </div>
                       </div>
                       <table class="table table-bordered account-table tables">
                         <tbody>
                           <tr>
                             <td id="td-right"><span>TCF count</span> </td>
                             <td>{x.tcf_count}</td>
                             <td id="td-right"><span>no of orders</span></td>
                             <td>{x.no_of_orders}
                             </td>
                             
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>product count</span>
                             </td>
                             <td>
                               {x.product_count}
                             </td>
                             <td id="td-right">
                               <span>
                               order quantity
                                 {/* <OverlayTrigger
                                   placement="right"
                                   delay={{ show: 250, hide: 400 }}
                                   overlay={renderTooltip}
                                 >
                                   <Button variant="success" className="tooltips">
                                     <IoIosHelpCircle id="help" />
                                   </Button>
                                 </OverlayTrigger>{" "} */}
                               </span>
                               </td><td>
                               {x.order_quantity}
                             </td>
                           </tr>
                           
                         </tbody>
                       </table>
                     
                            
                       <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>History Information</span></h4>
                    <hr></hr>
                  </div>
                  <table id="historys" className="">
                    <thead>
                      <tr>
                        <th>Field Name</th>
                        <th>New Value</th>
                        <th>Old Value</th>
                        <th>Changed By</th>
                        <th>Change Time</th>
                      </tr>
                    </thead>

                    {history.length > 0 ? (
                      <tbody>
                        {history.map((y, i) => (
                          <tr>
                            <td>{y.field_name}</td>
                            <td>{y.new_value}</td>
                            <td>{y.old_value}</td>
                            <td>{y.changed_by}</td>
                            <td>{y.change_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </Row>  
                <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Contract Shipment Information</span></h4>
                    <hr></hr>
                  </div>
                  <table id="contract-ship" className="">
                    <thead>
                      <tr>
                        <th>contract ship code</th>
                        <th>Contract</th>
                        <th>Quantity</th>
                        <th>Laycan</th>
                        <th>Product Count</th>
                        <th>TCF Count	</th>
                        <th>no of orders</th>
                        <th>Pricing</th>
                        <th>Order Qty
</th>
                      </tr>
                    </thead>

                    {contractShipment.length > 0 ? (
                      <tbody>
                        {contractShipment.map((y, i) => (
                          <tr>
                                                       <td><Link to={`/shipment/contract-shipment-details/${y.id}`}>{y.contract_ship_code}</Link></td>

                            <td>{y.contract}</td>
                            <td>{y.quantity}</td>
                            <td>{y.laycan}</td>
                            <td>{y.product_count}</td>
                            <td>{y.tcf_count}</td>
                            <td>{y.no_of_orders}</td>
                            <td>{y.pricing}</td>
                            <td>{y.order_quantity}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </Row>
                        

                            
                        <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Contract Products

</span>
                                  <span>
                                    <i className=""></i>
                                    New Contract Products

                                  </span>
                                  <span>
                                    <i className=""></i>
                                    Attach File
                                  </span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>
                            <Row id="table-styles">
                             <div className="col-md-12" id="head">
                               <h4 className="heading">
                                 <span>Product Information</span>
                              
                               </h4>
                               <div id="inner-heading"></div>
                               <hr></hr>
                             </div>
                             <table id="product" className="">
                               <thead>
                                 <tr>
                                   <th>Product Code</th>
                                   <th>Product Name</th>
                                   <th>Product Family</th>
                                   <th>Product Description</th>
                                   <th>Quantity MT</th>
                                   <th>Supplier</th> 
                                 </tr>
                               </thead>
                   
                               {product.length > 0 ? (
                                 <tbody>
                                   {product.map((x, i) => (
                                     <tr>
                                       <td>
                                         <Link to={"/product/product-detail/" + x.id}>{x.product_code}</Link>
                                       </td>
                                       <td>{x.product_name}</td>
                                       <td>{x.product_family}</td>
                                       <td>{x.product_description}</td>
                                       <td>{x.quantity_MT}</td>
                                       <td>{x.supplier}</td> 
                                     </tr>
                                   ))}
                                 </tbody>
                               ) : (
                                 <tbody>
                                   <tr>
                                     <td></td>
                                     <td></td>
                                     <td></td>
                                     <td>No data available</td>
                                     <td></td>
                                     <td></td> 
                                   </tr>
                                 </tbody>
                               )}
                             </table>
                           </Row>
                           
                          
                            <Row id="table-styles">
                             <div className="col-md-12" id="head">
                               <h4 className="heading">
                                 <span>Orders Information</span>
                              
                               </h4>
                               <div id="inner-heading"></div>
                               <hr></hr>
                             </div>
                             <table id="orders" className="">
                               <thead>
                                 <tr>
                                   <th>Order code no</th>
                                   <th>Contract Number</th>
                                   <th>Purchase Invoice</th>
                                   <th>Shipment Qty</th> 
                                 </tr>
                               </thead>
                   
                               {orders.length > 0 ? (
                                 <tbody>
                                   {orders.map((x, i) => (
                                     <tr>
                                       <td>
                                         <Link to={`/order/view-order-details/${x.id}`}>{x.order_code_no}</Link>
                                       </td>
                                       <td>{x.contract_number}</td>
                                       <td>{x.purchase_invoice}</td>
                                       <td>{x.shipment_qty}</td> 
                                     </tr>
                                   ))}
                                 </tbody>
                               ) : (
                                 <tbody>
                                   <tr>
                                     
                                     <td></td>
                                     <td>No data available</td>
                                     <td></td>
                                     <td></td> 
                                   </tr>
                                 </tbody>
                               )}
                             </table>
                           </Row>


                           <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Trade Confirmation Form</span>
                             </h4>
                            <div id="inner-heading"></div>
                            <hr></hr>
                          </div>
                          <table id="tcf" className="">
                            <thead>
                              <tr>
                                <th>TCF No</th>
                                <th>Company</th>
                                <th>Opportunity</th>
                                <th>Quote</th>
                                <th>Record Type</th>
                                <th>Currency</th>
                                <th>Purchase Confirmation Date</th>
                              </tr>
                            </thead>

                            {tcfList.length > 0 ? (
                              <tbody>
                                {tcfList.map((x, i) => (
                                  <tr>
                                    <td>
                                      <Link to={"/tcf/tcf-details/" + x.id}>
                                        {x.tcf_no}
                                      </Link>
                                    </td>
                                    <td>{x.company}</td>
                                    <td>{x.opportunity}</td>
                                    <td>{x.quote}</td>
                                    <td>{x.record_type}</td>
                                    <td>{x.currency}</td>
                                    <td>{x.purchase_confirmation_date}</td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>No data available</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </Row>

                           <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Notes & Attachments</span>
                                  <span>
                                    <i className=""></i>
                                    New Note
                                  </span>
                                  <span>
                                    <i className=""></i>
                                    Attach File
                                  </span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>


                            <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Open Activities</span>
                                  <span>
                                    <i className=""></i>
                                    New Task
                                  </span>
                                  <span>
                                    <i className=""></i>
                                    New Event
                                  </span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>


                            
                          
                     </div>     
                     
                                    </>
                                    
                    ))
                    ):(
                      <></>
                    )
                    }
                   </div>
  )}
    <Footer/>
       </div>
     );
   } 
   else if (datas1.record_type ===  "Supplier - Long Term") {
    
   
    return (
  
      <div className="priceAccount" id='view-contract-ship'>
      <Header/>
      <div>
      <div className="page-header" id=''>
      <div>
        <h2 className="main-content-title tx-24 mg-b-5">Contract Shippment </h2>
        <ol className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">Dashboard / shipment / Contract Shippment</li>
        </ol>
      </div>
      <div className="d-flex">
        <div className="justify-content-center">
        
          {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
           <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
          </button> */}
          
        </div>
      </div> 
    </div> 
        
      </div>
      {isEditMode ? (
        <>
        <div className="tasks">
          <p className="edit-btn">
            <button
              className="btn btn-primary"
              onClick={handleSaveEdit}
            >
              Save
            </button>
            <button
              className="btn btn-primary"
              onClick={handleCancelEdit}
            >
              Cancel
            </button>
          </p>
          <div className="row">
            <div className="col-md-12" id="head">
              <h4 className="heading">Contract Shipment Details</h4>

              <hr></hr>
            </div>
          </div>

          <Row className="mb-3">
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Currency"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="currency"
                  defaultValue={datas1.currency || ""}
                  onChange={handleChangeInput}
                >
                  <option value="">Select </option>
                  <option value="IDR">IDR </option>
                  <option value="USD">USD </option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Record type"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Record type"
                  name="record_type"
                  className="record-type"
                  defaultValue={datas1.record_type || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Exchange rate"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Exchange rate"
                  name="exchange_rate"
                  defaultValue={datas1.exchange_rate || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            {/* <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Laycan From Date"
                className="mb-3 datepick"
              >
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  selectsStart
                  dateFormat="dd/MM/yyyy"
                  minDate={startDate}
                  startDate={startDate}
                  placeholderText="Select"
                />
              </FloatingLabel>
            </Form.Group> */}

            <Form.Group as={Col} md="6"></Form.Group>

            {/* <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Laycan To Date"
                className="mb-3 datepick"
              >
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  dateFormat="dd/MM/yyyy"
                  startDate={endDate}
                  placeholderText="Select"
                />
              </FloatingLabel>
            </Form.Group> */}
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Price type"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="price_type"
                  placeholder="Price type"
                  defaultValue={datas1.price_type || ""}
                  onChange={handleChangeInput}
                >
                  <option value="">Select</option>
                  {getPrice.map((x) => {
                    return (
                      <option value={x.price_type_name}>
                        {x.price_type_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Quantity"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Quantity"
                  name="quantity"
                  defaultValue={datas1.quantity || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Quantity From"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Quantity From"
                  name="quantity_from"
                  defaultValue={datas1.quantity_from || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Quantity To"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Quantity To"
                  name="quantity_to"
                  defaultValue={datas1.quantity_to || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Quantity Type"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="quantity_type"
                  placeholder="Quantity Type"
                  defaultValue={datas1.quantity_type || ""}
                  onChange={handleChangeInput}
                >
                  <option value="">--None--</option>
                  <option value="Base Quantity">Base Quantity</option>
                  <option value="Buyers Option">Buyers Option</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Price"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Price"
                  name="price"
                  defaultValue={datas1.price || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Price pmt in words"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Price pmt in words"
                  name="price_pmt_in_words"
                  defaultValue={datas1.price_pmt_in_words || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
            {/* <Form.Group as={Col} md="6"></Form.Group> */}
            {/* <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Shipment Quantity Tolerance"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Shipment Quantity Tolerance"
                  name="shipment_quantity_tolerance"
                  defaultValue={
                    datas1.shipment_quantity_tolerance || ""
                  }
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group> */}
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Laycan Year From"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="laycan_year_from"
                  placeholder="Laycan Year From"
                  defaultValue={datas1.laycan_year_from || ""}
                  onChange={handleChangeInput}
                >
                  <option value="">---None---</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                  <option value="2018">2018</option>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Laycan Year To"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="laycan_year_to"
                  placeholder="Laycan Year To"
                  defaultValue={datas1.laycan_year_to || ""}
                  onChange={handleChangeInput}
                >
                  <option value="">---None---</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                  <option value="2018">2018</option>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Laycan Month From"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="laycan_month_from"
                  placeholder="Laycan Month From"
                  defaultValue={datas1.laycan_month_from || ""}
                  onChange={handleChangeInput}
                >
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Laycan Month To"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="laycan_month_to"
                  placeholder="Laycan Month To"
                  defaultValue={datas1.laycan_month_to || ""}
                  onChange={handleChangeInput}
                >
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="No of Shipments"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="No of Shipments"
                  name="no_of_shipments"
                  defaultValue={datas1.laycan_month_to || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Contract"
          className="mb-3"
          
          
        >
          <Form.Control
            type="text"
            placeholder="Contract"
            name="contract"
            defaultValue={datas1.contract || ""}
            onChange={handleChangeInput}/>
        </FloatingLabel>
      </Form.Group>

            {/* <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Contract"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="contract"
                  placeholder="Contract"
                  className="error-validation"
                  defaultValue={datas1.contract || ""}
                  onChange={handleChangeInput}
                >
                  <option value="">Select</option>
                  {companies.map((x) => {
                    return (
                      <option value={x.rwood_contract_number}>
                        {x.rwood_contract_number}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Form.Group> */}
            <Form.Group as={Col} md="6"></Form.Group>
            {datas1.price_type === "Fix Price" ? (
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Sales price margin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Sales price margin"
                    name="sales_price_margin"
                    defaultValue={datas1.sales_price_margin || ""}
                    onChange={handleChangeInput}
                    disabled
                  />
                </FloatingLabel>
              </Form.Group>
            ) : (
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Sales price margin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Sales price margin"
                    name="sales_price_margin"
                    defaultValue={datas1.sales_price_margin || ""}
                    onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
            )}

            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Contract shipment status"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="contract_shipment_status"
                  placeholder="Contract shipment status"
                  defaultValue={
                    datas1.contract_shipment_status || "Draft"
                  }
                  onChange={handleChangeInput}
                >
                  <option value="">--None--</option>
                  <option value="Activated">Activated</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Draft">Draft</option>
                  <option value="Shipments - Arutmin">
                    Shipments - Arutmin
                  </option>
                  <option value="Cancelled with Penalty">
                    Cancelled with Penalty
                  </option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Order quantity"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Order quantity"
                  name="order_quantity"
                  defaultValue={datas1.order_quantity || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Price Basis"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="price_basis"
                  placeholder="Price Basis"
                  defaultValue={datas1.price_basis || ""}
                  onChange={handleChangeInput}
                >
                  <option value="">--None--</option>
                  <option value="GCV (ARB)">GCV (ARB)</option>
                  <option value="GCV (ADB)">GCV (ADB)</option>
                  <option value="NCV (ARB)">NCV (ARB)</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Shipping Term"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="shipping_term"
                  placeholder="Shipping Term"
                  defaultValue={datas1.shipping_term || ""}
                  onChange={handleChangeInput}
                >
                 <option value="">--None--</option><option value="FOB-B">FOB-B</option><option value="FOB-MV">FOB-MV</option><option value="CFR-MV">CFR-MV</option><option value="CIF-MV">CIF-MV</option><option value="FAS">FAS</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Laycan Year"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="laycan_year"
                  placeholder="Laycan Year"
                  defaultValue={datas1.laycan_year || ""}
                  onChange={handleChangeInput}
                >
                  <option value="">---None---</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                  <option value="2018">2018</option>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TCF Count"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TCF Count"
                  name="tcf_count"
                  defaultValue={datas1.tcf_count || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
          </Row>

          <p className="edit-btn">
            <button
              className="btn btn-primary"
              onClick={handleSaveEdit}
            >
              Save
            </button>
            <button
              className="btn btn-primary"
              onClick={handleCancelEdit}
            >
              Cancel
            </button>
          </p>
        </div>
      </>
 ):(
 <div className="tasks" id='view-page'>
    
                   {datas.length > 0 ?(
                   datas.map((x)=>(
                     
                   <> 
                   {/* <p id="top-header"><span class="button add-accountrecord" aria-describedby="popup-54"> RW Payment/Receipt LineItem </span></p> */}
 <p className='edit-btn'>
   
   <button id='edits' onClick={handleEditClick}>
                             Edit
                           </button>
                           <Link to={`/contract-shipment/create-view-list`}> <button id='edits'>
                              Cancel
                            </button></Link>
                           {/* <button id='edits' onClick={createProduct}>
                             Create Product
                           </button>
                           <Link><button id='edits' onClick={createTcf}>
                             Create TCF
                           </button></Link>
                           <Link to={'/contract-shipment/new-order/'+ids}><button id='edits'>
                             Create Order
                           </button></Link> */}
                            <button className='btn btn-primary' id='edits' onClick={noOfshipment}>Contract Shipment Details</button> 
                           
   </p> 
 
                    <div className="">
                      <div className="row">
                        <div className="col-md-12" id="head">
                          <h4 className="heading">Contract Shipment Details</h4>
  
                          <hr></hr>
                        </div>
                      </div>
                      <table class="table table-bordered account-table tables">
                        <tbody>
                        <tr>
                             <td id="td-right"><span>Currency</span> </td>
                             <td>{x.currency}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>record type</span> </td>
                             <td>{x.record_type}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>exchange rate</span> </td>
                             <td>{x.exchange_rate}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>Laycan From Date</span> </td>
                             <td>{x.laycan_from_date}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                        <td id="td-right"><span>Laycan To Date</span> </td>
                             <td>{x.laycan_to_date}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>price type</span> </td>
                             <td>{x.price_type}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                        <td id="td-right"><span>quantity</span> </td>
                             <td>{x.quantity}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>quantity from</span> </td>
                             <td>{x.quantity_from}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>quantity to</span> </td>
                             <td>{x.quantity_to}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>quantity type</span> </td>
                             <td>{x.quantity_type}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>price</span> </td>
                             <td>{x.price}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>price pmt in words</span> </td>
                             <td>{x.price_pmt_in_words}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>shipment quantity tolerance</span> </td>
                             <td>{x.shipment_quantity_tolerance}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>laycan year from</span> </td>
                             <td>{x.laycan_year_from}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>laycan year to</span> </td>
                             <td>{x.laycan_year_to}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>laycan month from</span> </td>
                             <td>{x.laycan_month_from}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>laycan month to</span> </td>
                             <td>{x.laycan_month_to}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>no of shipments</span> </td>
                             <td>{x.no_of_shipments}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>contract</span> </td>
                             <td><Link to={ `/contract/contract-details/${x.contract_id}`} >  {x.contract} </Link></td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>sales price margin</span> </td>
                             <td>{x.sales_price_margin}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>contract shipment status</span> </td>
                             <td>{x.contract_shipment_status || 'Draft'}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>order quantity</span> </td>
                             <td>{x.order_quantity}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>price basis</span> </td>
                             <td>{x.price_basis}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                        <tr>
                             <td id="td-right"><span>shipping term</span> </td>
                             <td>{x.shipping_term}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                           <tr>
                             <td id="td-right"><span>laycan year</span> </td>
                             <td>{x.laycan_year}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr>
                           <tr>
                             <td id="td-right"><span>TCFcount</span> </td>
                             <td>{x.tcf_count}</td>
                             <td id="td-right"><span></span></td>
                             <td>
                             </td>
                             
                           </tr> 
                          
                        </tbody>
                      </table>
                    
                           
                          
                      <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Open Activities</span>
                                  <span>
                                    <i className=""></i>
                                    New Task
                                  </span>
                                  <span>
                                    <i className=""></i>
                                    New Event
                                  </span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>

                            <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>History Information</span></h4>
                    <hr></hr>
                  </div>
                  <table id="historys" className="">
                    <thead>
                      <tr>
                        <th>Field Name</th>
                        <th>New Value</th>
                        <th>Old Value</th>
                        <th>Changed By</th>
                        <th>Change Time</th>
                      </tr>
                    </thead>

                    {history.length > 0 ? (
                      <tbody>
                        {history.map((y, i) => (
                          <tr>
                            <td>{y.field_name}</td>
                            <td>{y.new_value}</td>
                            <td>{y.old_value}</td>
                            <td>{y.changed_by}</td>
                            <td>{y.change_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </Row>
                <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Contract Shipment Information</span></h4>
                    <hr></hr>
                  </div>
                  <table id="contract-ship" className="">
                    <thead>
                      <tr>
                        <th>contract ship code</th>
                        <th>Contract</th>
                        <th>Quantity</th>
                        <th>Laycan</th>
                        <th>Product Count</th>
                        <th>TCF Count	</th>
                        <th>no of orders</th>
                        <th>Pricing</th>
                        <th>Order Qty
</th>
                      </tr>
                    </thead>

                    {contractShipment.length > 0 ? (
                      <tbody>
                        {contractShipment.map((y, i) => (
                          <tr>
                                                       <td><Link to={`/shipment/contract-shipment-details/${y.id}`}>{y.contract_ship_code}</Link></td>

                            <td>{y.contract}</td>
                            <td>{y.quantity}</td>
                            <td>{y.laycan}</td>
                            <td>{y.product_count}</td>
                            <td>{y.tcf_count}</td>
                            <td>{y.no_of_orders}</td>
                            <td>{y.pricing}</td>
                            <td>{y.order_quantity}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </Row>

                        <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Notes & Attachments</span>
                                  <span>
                                    <i className=""></i>
                                    New Note
                                  </span>
                                  <span>
                                    <i className=""></i>
                                    Attach File
                                  </span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>
                           

                            <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Contract Products</span>
                                  <span>
                                    <i className=""></i>
                                    New Contract Products
                                  </span>
                                  <span>
                                    <i className=""></i>
                                    Attach File
                                  </span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>

                            <Row id="table-styles">
                             <div className="col-md-12" id="head">
                               <h4 className="heading">
                                 <span>Product Information</span>
                              
                               </h4>
                               <div id="inner-heading"></div>
                               <hr></hr>
                             </div>
                             <table id="product" className="">
                               <thead>
                                 <tr>
                                   <th>Product Code</th>
                                   <th>Product Name</th>
                                   <th>Product Family</th>
                                   <th>Product Description</th>
                                   <th>Quantity MT</th>
                                   <th>Supplier</th> 
                                 </tr>
                               </thead>
                   
                               {product.length > 0 ? (
                                 <tbody>
                                   {product.map((x, i) => (
                                     <tr>
                                       <td>
                                         <Link to={"/product/product-detail/" + x.id}>{x.product_code}</Link>
                                       </td>
                                       <td>{x.product_name}</td>
                                       <td>{x.product_family}</td>
                                       <td>{x.product_description}</td>
                                       <td>{x.quantity_MT}</td>
                                       <td>{x.supplier}</td> 
                                     </tr>
                                   ))}
                                 </tbody>
                               ) : (
                                 <tbody>
                                   <tr>
                                     <td></td>
                                     <td></td>
                                     <td></td>
                                     <td>No data available</td>
                                     <td></td>
                                     <td></td> 
                                   </tr>
                                 </tbody>
                               )}
                             </table>
                           </Row>
                           
                           
                      <Row id="table-styles">
                             <div className="col-md-12" id="head">
                               <h4 className="heading">
                                 <span>Orders Information</span>
                              
                               </h4>
                               <div id="inner-heading"></div>
                               <hr></hr>
                             </div>
                             <table id="orders" className="">
                               <thead>
                                 <tr>
                                   <th>Order code no</th>
                                   <th>Contract Number</th>
                                   <th>Purchase Invoice</th>
                                   <th>Shipment Qty</th> 
                                 </tr>
                               </thead>
                   
                               {orders.length > 0 ? (
                                 <tbody>
                                   {orders.map((x, i) => (
                                     <tr>
                                       <td>
                                       <Link to={`/order/view-order-details/${x.id}`}>{x.order_code_no}</Link>
                                       </td>
                                       <td>{x.contract_number}</td>
                                       <td>{x.purchase_invoice}</td>
                                       <td>{x.shipment_qty}</td> 
                                     </tr>
                                   ))}
                                 </tbody>
                               ) : (
                                 <tbody>
                                   <tr>
                                     
                                     <td></td>
                                     <td>No data available</td>
                                     <td></td>
                                     <td></td> 
                                   </tr>
                                 </tbody>
                               )}
                             </table>
                           </Row>
                           <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Trade Confirmation Form</span>
                             </h4>
                            <div id="inner-heading"></div>
                            <hr></hr>
                          </div>
                          <table id="tcf" className="">
                            <thead>
                              <tr>
                                <th>TCF No</th>
                                <th>Company</th>
                                <th>Opportunity</th>
                                <th>Quote</th>
                                <th>Record Type</th>
                                <th>Currency</th>
                                <th>Purchase Confirmation Date</th>
                              </tr>
                            </thead>

                            {tcfList.length > 0 ? (
                              <tbody>
                                {tcfList.map((x, i) => (
                                  <tr>
                                    <td>
                                      <Link to={"/tcf/tcf-details/" + x.id}>
                                        {x.tcf_no}
                                      </Link>
                                    </td>
                                    <td>{x.company}</td>
                                    <td>{x.opportunity}</td>
                                    <td>{x.quote}</td>
                                    <td>{x.record_type}</td>
                                    <td>{x.currency}</td>
                                    <td>{x.purchase_confirmation_date}</td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>No data available</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </Row>
                           
                           <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Contract Shipments
</span>
                                  <span>
                                    <i className=""></i>
                                    New Contract Shipment
                                  </span>
                                  <span>
                                    <i className=""></i>
                                    Attach File
                                  </span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>

                    </div>     
                    
                                   </>
                                   
                   ))
                   ):(
                     <></>
                   )
                   }
                  </div>
 )}
   <Footer/>
      </div>
    );
  } 
   
  
   
}

export default ContractShippmentDetailsLongterm;
