import React, { useEffect, useState, useRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from "crypto-js";
import Header from "../Header";
import Footer from "../Footer";
import $ from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios";
import { API_URL } from '../config';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Addpostshipment() {
  const [pre, setPre] = useState([]);
  const [orders, setOrders] = useState([]);
  const [date, setDate] = useState("");
  const [date1, setDate1] = useState("");

  const [date3, setDate3] = useState("");
  const [date4, setDate4] = useState("");
  const [date5, setDate5] = useState("");
  const [date6, setDate6] = useState("");
  const [date7, setDate7] = useState("");
  const [date8, setDate8] = useState("");
  const [date9, setDate9] = useState("");
  const [date10, setDate10] = useState("");
  const [date11, setDate11] = useState("");
  const [date12, setDate12] = useState("");
  const [date13, setDate13] = useState("");
  const [date14, setDate14] = useState("");
  const [date15, setDate15] = useState("");
  const [date16, setDate16] = useState("");
  const [date17, setDate17] = useState("");
  const [date18, setDate18] = useState("");
  const [date19, setDate19] = useState("");
  const [date20, setDate20] = useState("");
  const [date21, setDate21] = useState("");
  const [date22, setDate22] = useState("");
  const [date23, setDate23] = useState("");
  const [date24, setDate24] = useState("");

  const handleDateChange = (time) => {
    setDate(time);
  };
  const handleDateChange1 = (time) => {
    setDate1(time);
  };

  const handleDateChange2 = (time) => {
    setDate3(time);
  };

  const handleDateChange3 = (time) => {
    setDate4(time);
  };

  const handleDateChange4 = (time) => {
    setDate5(time);
  };

  const handleDateChange5 = (time) => {
    setDate6(time);
  };

  const handleDateChange6 = (time) => {
    setDate7(time);
  };

  const handleDateChange7 = (time) => {
    setDate8(time);
  };

  const handleDateChange8 = (time) => {
    setDate9(time);
  };

  const handleDateChange9 = (time) => {
    setDate10(time);
  };

  const handleDateChange10 = (time) => {
    setDate11(time);
  };

  const handleDateChange11 = (time) => {
    setDate12(time);
  };

  const handleDateChange12 = (time) => {
    setDate13(time);
  };

  const handleDateChange13 = (time) => {
    setDate14(time);
  };

  const handleDateChange14 = (time) => {
    setDate15(time);
  };

  const handleDateChange15 = (time) => {
    setDate16(time);
  };

  const handleDateChange16 = (time) => {
    setDate17(time);
  };

  const handleDateChange17 = (time) => {
    setDate18(time);
  };

  const handleDateChange18 = (time) => {
    setDate19(time);
  };

  const handleDateChange19 = (time) => {
    setDate20(time);
  };

  const handleDateChange20 = (time) => {
    setDate21(time);
  };

  const handleDateChange21 = (time) => {
    setDate22(time);
  };
  const handleDateChange22 = (time) => {
    setDate23(time);
  };
  const handleDateChange23 = (time) => {
    setDate24(time);
  };

  // bank
  useEffect(() => {
    fetchBank();
  }, [decryptedAccessToken]);

  const fetchBank = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/allorders",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.orders;
      setOrders(data);
    } catch (error) {
      console.error(error);
    }
  };
  console.log(orders);
  // bank
  useEffect(() => {
    fetchUser();
  }, [decryptedAccessToken]);

  const fetchUser = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/preshipment",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.preshipments; 
      setPre(data);
    } catch (error) {
      console.error(error);
    }
  };
  console.log(pre);
  const body = $(window).height();
  const header = $("#header").height();
  const nav = $(".navbar").height();
  const footer = $(".footer").height();
  const total_height = header + nav + footer;
  const window_height = body - total_height;
  console.log(total_height);
  console.log(body);
  const [message, setMessage] = useState("");
  const [blconfirm, setBlconfirm] = useState("");
  const [weight, setWeight] = useState("");
  const [weight1, setWeight1] = useState("");
  const [weight2, setWeight2] = useState("");
  const [weight3, setWeight3] = useState("");
  const [weight4, setWeight4] = useState("");
  const [weight5, setWeight5] = useState("");
  const [weight6, setWeight6] = useState("");
  const [weight7, setWeight7] = useState("");
  const [weight8, setWeight8] = useState("");
  const checkbox = useRef();
  const handleChange = () => {
    if (checkbox.current.checked) {
      setMessage(true);
    } else {
      setMessage(false);
    }
  };

  const handleHold = () => {
    if (checkbox.current.checked) {
      setWeight(true);
    } else {
      setWeight(false);
    }
  };
  const handleSurvey = () => {
    if (checkbox.current.checked) {
      setWeight1(true);
    } else {
      setWeight1(false);
    }
  };
  const handleRegistry = () => {
    if (checkbox.current.checked) {
      setWeight2(true);
    } else {
      setWeight2(false);
    }
  };

  const handleClassification = () => {
    if (checkbox.current.checked) {
      setWeight3(true);
    } else {
      setWeight3(false);
    }
  };
  const handleSafty = () => {
    if (checkbox.current.checked) {
      setWeight4(true);
    } else {
      setWeight4(false);
    }
  };

  const handleInsurance = () => {
    if (checkbox.current.checked) {
      setWeight5(true);
    } else {
      setWeight5(false);
    }
  };
  const handleImport = () => {
    if (checkbox.current.checked) {
      setWeight6(true);
    } else {
      setWeight6(false);
    }
  };
  const handleWeight = () => {
    if (checkbox.current.checked) {
      setWeight7(true);
    } else {
      setWeight7(false);
    }
  };
  const handleBL = () => {
    if (checkbox.current.checked) {
      setWeight8(true);
    } else {
      setWeight8(false);
    }
  };

  $(".contactAccount").css({ "min-height": window_height + "px" });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    const res = {
      test: date,
      test1: date1,
    };
    console.log(JSON.stringify(res));
    const encryptedAccessToken = localStorage.getItem("access_token");
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);

      fetch(API_URL+"add/postshipme2nt/2", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())

        .then((response) => {
          console.log(response);
          toast.success("Company added successfully", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            reset();
          }, 300);
        });
    }
  };

  return (
    <div className="addAccount">
      <Header />
      <div className="page-header" id="add-account">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Add Postshipment</h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Dashboard / Company /Add company{" "}
            </li>
          </ol>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
          </div>
        </div>
      </div>{" "}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <h4 className="heading">Information</h4>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="Currency"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="currency"
                id="account_name"
                placeholder="Currency"
                defaultValue=""
                {...register("currency", { required: false })}
              >
                <option value="">Select</option>
                <option value="INR">INR</option>
                <option value="AED">AED</option>
                <option value="USD">USD</option>
              </Form.Select>
              {errors.currency && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <input
              type="checkbox"
              name="js"
              ref={checkbox}
              onChange={handleChange}
            />{" "}
            <label>Locked</label>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Exchange Rate"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Exchange Rate"
                name="exchange_rate"
                {...register("exchange_rate", { required: true })}
              />
              {errors.exchange_rate && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="Pre ship document"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="pre_ship_document"
                id="account_name"
                placeholder="Pre ship document"
                defaultValue=""
                {...register("pre_ship_document ", { required: false })}
              >
                <option value="">Select</option>

                {pre.map((x) => {
                  return <option value={x.pre_code_no}>{x.pre_code_no}</option>;
                })}
              </Form.Select>
              {errors.pre_ship_document && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="Orders"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="order"
                id="account_name"
                placeholder="Orders"
                defaultValue=""
                {...register("order ", { required: false })}
              >
                <option value="">Select</option>

                {orders.map((x) => {
                  return (
                    <option value={x.order_code_no}>{x.order_code_no}</option>
                  );
                })}
              </Form.Select>
              {errors.order && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <h4 className="heading">BL Draft/AI COO </h4>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date}
              onChange={handleDateChange}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="BL Draft Sent Date And Time"
            />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="COO Destination"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="COO Destination"
                name="coo_destination"
                {...register("coo_destination", { required: true })}
              />
              {errors.coo_destination && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <input
              type="checkbox"
              name="js"
              ref={checkbox}
              onChange={handleBL}
            />{" "}
            <label>BL Confirm</label>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date1}
              onChange={handleDateChange1}
              dateFormat="yyyy-MM-dd"
              placeholderText="BL Draft Or Splits Review Confirm Date"
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <h4 className="heading">BL Issued</h4>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="BL No"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="BL No"
                name="bl_no"
                {...register("bl_no", { required: true })}
              />
              {errors.bl_no && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="BL Quantity"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="BL Quantity"
                name="bl_quantity"
                {...register("bl_quantity", { required: true })}
              />
              {errors.bl_quantity && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date3}
              onChange={handleDateChange2}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="OBL Email Recieved Date And Time "
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date4}
              onChange={handleDateChange3}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="BL Issue Time And Date"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date5}
              onChange={handleDateChange4}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="COO Form Govt Recieve By Email"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="BL Issue Place "
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="BL Issue Place "
                name="bl_issue_place"
                {...register("bl_issue_place", { required: true })}
              />
              {errors.bl_issue_place && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="BL Status"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="bl_status"
                id="account_name"
                placeholder="BL Status"
                defaultValue=""
                {...register("bl_status", { required: false })}
              >
                <option value="">Select</option>
                <option value="INR">Draft Review</option>
                <option value="AED">Pending</option>
                <option value="USD">Approved</option>
              </Form.Select>
              {errors.bl_status && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <h4 className="heading">Addtional Surveyors Certificates</h4>

          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date6}
              onChange={handleDateChange5}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Shiper Surveyor COA Date"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <label>Certificate Of Weight</label>
            <input
              type="checkbox"
              name="js"
              ref={checkbox}
              onChange={handleWeight}
            />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date7}
              onChange={handleDateChange6}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Rwood Or Cust Surveyor COA Date"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <label>Certificate Of Hold Cleanliness</label>
            <input
              type="checkbox"
              name="js"
              ref={checkbox}
              onChange={handleHold}
            />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date8}
              onChange={handleDateChange7}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Net Weight As Per Document"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <label>Draft Survey Report </label>
            <input
              type="checkbox"
              name="js"
              ref={checkbox}
              onChange={handleSurvey}
            />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date9}
              onChange={handleDateChange8}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Addi Surveyor Certification Date"
            />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <label>Certificate Of Registry</label>
            <input
              type="checkbox"
              name="js"
              ref={checkbox}
              onChange={handleRegistry}
            />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="COA Status"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="coa_status"
                id="account_name"
                placeholder="COA Status"
                defaultValue=""
                {...register("coa_status", { required: false })}
              >
                <option value="">Select</option>
                <option value="INR">Draft Review</option>
                <option value="AED">Pending</option>
                <option value="USD">Approved</option>
              </Form.Select>
              {errors.coa_status && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <label>Classification Certificate </label>
            <input
              type="checkbox"
              name="js"
              ref={checkbox}
              onChange={handleClassification}
            />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="COO Status"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="coo_status"
                id="account_name"
                placeholder="COO Status"
                defaultValue=""
                {...register("coo_status", { required: false })}
              >
                <option value="">Select</option>
                <option value="INR">Draft Review</option>
                <option value="AED">Pending</option>
                <option value="USD">Approved</option>
              </Form.Select>
              {errors.coo_status && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <label>Safety Management Certificate</label>
            <input
              type="checkbox"
              name="js"
              ref={checkbox}
              onChange={handleSafty}
            />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="COW Status"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="cow_status"
                id="account_name"
                placeholder="COW Status"
                defaultValue=""
                {...register("cow_status", { required: false })}
              >
                <option value="">Select</option>
                <option value="INR">Draft Review</option>
                <option value="AED">Pending</option>
                <option value="USD">Approved</option>
              </Form.Select>
              {errors.cow_status && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <label>Insurance Of Certificate</label>
            <input
              type="checkbox"
              name="js"
              ref={checkbox}
              onChange={handleInsurance}
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="DSR Status"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="dsr_status"
                id="account_name"
                placeholder="DSR Status"
                defaultValue=""
                {...register("dsr_status", { required: false })}
              >
                <option value="">Select</option>
                <option value="INR">Draft Review</option>
                <option value="AED">Pending</option>
                <option value="USD">Approved</option>
              </Form.Select>
              {errors.dsr_status && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Reason For Delay"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Reason For Delay"
                name="reason_for_delay"
                {...register("reason_for_delay", { required: true })}
              />
              {errors.reason_for_delay && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="Govt COO Status"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="govt_coo_status"
                id="account_name"
                placeholder="Govt COO Status"
                defaultValue=""
                {...register("govt_coo_status", { required: false })}
              >
                <option value="">Select</option>
                <option value="INR">Draft Review</option>
                <option value="AED">Pending</option>
                <option value="USD">Approved</option>
              </Form.Select>
              {errors.govt_coo_status && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <h4 className="heading">COO Issued and Courier Information</h4>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date10}
              onChange={handleDateChange9}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="COO Insurance Date"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date11}
              onChange={handleDateChange10}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="COO Courier Date"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="COO Courier Tracking No"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="COO Courier Tracking No"
                name="coo_courier_tracking_no"
                {...register("coo_courier_tracking_no", { required: true })}
              />
              {errors.coo_courier_tracking_no && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="COO Courier Company"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="COO Courier Company"
                name="coo_courier_company"
                {...register("coo_courier_company", { required: true })}
              />
              {errors.coo_courier_company && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <h4 className="heading">Import Invoice</h4>
          <Form.Group as={Col} md="6">
            <label>Checking of Import Invoice</label>
            <input
              type="checkbox"
              name="js"
              ref={checkbox}
              onChange={handleImport}
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Supplier Invoice Price"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Supplier Invoice Price"
                name="supplier_invoice_price"
                {...register("supplier_invoice_price", { required: true })}
              />
              {errors.supplier_invoice_price && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date12}
              onChange={handleDateChange11}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Supplier Invoice Check Date"
            />
          </Form.Group>
          <Form.Group as={Col} md="12">
            <FloatingLabel controlId="floatingTextarea2" label="Remark">
              <Form.Control
                as="textarea"
                placeholder="Remark"
                style={{ height: "100px" }}
                name="remark"
                {...register("remark", { required: false })}
              />
              {errors.remark && <p id="text-area">Required</p>}
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <h4 className="heading">Document Submitted by Shipper/Supplier</h4>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date13}
              onChange={handleDateChange12}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Submited by Shiper to Shiper Bank Date"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date14}
              onChange={handleDateChange13}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Scan BL Rcvd Date"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date15}
              onChange={handleDateChange14}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Supplier Bank to RWood Bank Sent Date"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date16}
              onChange={handleDateChange15}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Scan llA Certs Rcvd Date"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date17}
              onChange={handleDateChange16}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Import Document RWood Bank Received Date"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date18}
              onChange={handleDateChange17}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Scan all Original Docs Rcvd Date"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="RWood Bank to Buyer Bank Airway No"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="RWood Bank to Buyer Bank Airway No"
                name="rwood_bank_to_buyer_bank_airway_no"
                {...register("rwood_bank_to_buyer_bank_airway_no", {
                  required: true,
                })}
              />
              {errors.rwood_bank_to_buyer_bank_airway_no && (
                <span>Required</span>
              )}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="RWood Bank to Buyer Bank Airway Company"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="RWood Bank to Buyer Bank Airway Company"
                name="rwood_bank_to_buyer_bank_airway_no"
                {...register("rwood_bank_to_buyer_bank_airway_no", {
                  required: true,
                })}
              />
              {errors.rwood_bank_to_buyer_bank_airway_no && (
                <span>Required</span>
              )}
            </FloatingLabel>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <h4 className="heading">Documents Submited to Buyer</h4>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date19}
              onChange={handleDateChange18}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Org Document to Buyer 80%"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date20}
              onChange={handleDateChange19}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Arrived At Discharge Port"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date21}
              onChange={handleDateChange20}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Org Document to Buyer 20%"
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <h4 className="heading">Commercial Invoice</h4>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date22}
              onChange={handleDateChange21}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Commercial Invoice Prepare Date"
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date23}
              onChange={handleDateChange22}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Comme Invoice Confirmation Date n Time"
            />
          </Form.Group>

          <Form.Group as={Col} md="12">
            <FloatingLabel
              controlId="floatingTextarea2"
              label="Commercial Invoice No"
            >
              <Form.Control
                as="text"
                placeholder="Commercial Invoice No"
                name="commercial_invoice_no"
                {...register("commercial_invoice_no", { required: false })}
              />
              {errors.commercial_invoice_no && <p id="text-area">Required</p>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="12">
            <FloatingLabel
              controlId="floatingTextarea2"
              label="Commercial Invoice Price"
            >
              <Form.Control
                as="text"
                placeholder="Commercial Invoice Price"
                name="commercial_invoice_price"
                {...register("commercial_invoice_price", { required: false })}
              />
              {errors.commercial_invoice_price && (
                <p id="text-area">Required</p>
              )}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <DatePicker
              selected={date24}
              onChange={handleDateChange23}
              showTimeSelect
              dateFormat="yyyy-MM-dd hh:mm aa"
              timeFormat="hh:mm aa"
              timeIntervals={15}
              timeCaption="Time"
              placeholderText="Commercial Invoice Date"
            />
          </Form.Group>
        </Row>
        <input type="submit" className="addaccount-save" value="Save" />
        <div className="successDiv">
          <p></p><p></p>
        </div>
      </Form>
      <Footer />
    </div>
  );
}

export default Addpostshipment;
