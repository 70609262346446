import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,

  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap"; 
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from '../Header' 
import Footer from '../Footer' 
import { API_URL } from '../config';
import $ from 'jquery'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { FaEnvelope,FaPhoneSquareAlt,FaMobileAlt,FaFax } from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams, useLocation  } from "react-router-dom"; 
const Excel = () => {
  
  const params = useParams()
  const id=params.id 
  const userRole = localStorage.getItem("role");
  // console.log(userRole);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
  const [excel,setExcel]= useState([]);
  const [file_name, setfile_name]= useState(" ")
  const location=useLocation();
  // const { id } = location.state;
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL+'get/excel_byID/12', { 
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
        );
        const data = response.data;  
        console.log(data)
        setDatas(data);    
        setfile_name(data.file_name) 
        
        $('#orders').DataTable();
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, [decryptedAccessToken, id]);


  // let file_name=datas.file_name;
  // console.log(file_name)

  const new_file_name = `${String(file_name)}.xlsx;`
  // console.log(new_file_name)
 

  // let new_file_name =  file_name.replace(".xlsx", " ");
  // console.log(new_file_name)

  // const filenameWithoutExtension = file_name.includes('.') ? file_name.split('.').slice(0, -1).join('.') : file_name;

  const handleDownload = async () => {
    try {
      const response = await axios.get(`${API_URL}download/file/${file_name}`, {
        responseType: 'blob',
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
    });
    
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the temporary link

    } catch (error) {
        console.error('Error downloading file', error);
    }
};
   
  return (
 
    <div id='view-page'> 
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Postshipment </h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Postshipment / Download Excel</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div> 
    <div className="tasks">
    <div className="col-md-12" id="adatas.account_details">
        {/* <button className="add-accountrecord" onclick={downLoad}> generate debit note</button>  */}
       
                  <h4 className="heading">Information</h4>
                  <hr></hr>
                </div>
                <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                    <td id="td-right">
                        <span>Related To</span>
                        </td><td>
                        {datas.related_to}
                      </td>
                      <td id="td-right">
                        <span>Size</span>
                        </td><td>
                        {datas.size}
                      </td>
                     
                    </tr>
                    <tr>
                    <td id="td-right">
                        <span>Private</span>
                        </td><td>
                        {datas.private}
                      </td>
                    <td id="td-right">
                        <span>File Name</span>
                        </td><td>
                        <span onClick={() => handleDownload()} id='downloads'>{datas.file_name}</span>
                      </td>
                      </tr>
                      <tr> 
                      <td id="td-right">
                        <span>Attachment Owner</span>
                        </td><td>
                        {datas.attachment_owner}
                      </td>
                    </tr>{" "}
                    <tr>
                    <td id="td-right">
                        <span>Description</span>
                        </td><td>
                        {datas.description}
                      </td>
                      <td id="td-right">
                        <span>Modified By</span>
                        </td><td>
                        {datas.modified_by}
                      </td>
                    </tr>{" "}
                    
                  </tbody>
                </table>
                         </div>
            
      <Footer/>
      </div>
  );
 
};

export default Excel;