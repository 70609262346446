import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from '../Header'
import Footer from '../Footer';import { API_URL } from '../config';
import { AES, enc } from 'crypto-js';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { parse, format , isValid } from 'date-fns';
import $ from 'jquery'
import Editor from 'react-simple-wysiwyg';
import DatePicker from "react-datepicker";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate, Link } from "react-router-dom";
function Addorderpostshipment(props) {
  const navigate = useNavigate();
  const [check, setCheck1] = useState(false)
  const [check2, setCheck2] = useState(false)
  const [check3, setCheck3] = useState(false)
  const [check4, setCheck4] = useState(false)
  const [check5, setCheck5] = useState(false)
  const [check6, setCheck6] = useState(false)
  const [check7, setCheck7] = useState(false)
  const [check8, setCheck8] = useState(false)
  const [check9, setCheck9] = useState(false)
  const [check10, setCheck10] = useState(false)
  const params = useParams()
  const [value, onChange] = useState(new Date());
  const [accountNames, setAccountName] = useState([]);
  const body = $(window).height()
  const header = $("#header").height()
  const nav = $(".navbar").height()
  const footer = $(".footer").height()
  const total_height = header + nav + footer;
  const window_height = body - total_height;
  console.log(window_height)
  $(".contactAccount").css({ "min-height": (window_height) + "px" })

  const [rwoodbank, setRwoodbank] = useState([]);
  const [date, setDate] = useState(null);
  const [dates, setDates] = useState('');



  const [editor1,setEditor1]=useState()
  function onChanges(e) {
    console.log('onChange event:', e);
    console.log('Current HTML:', e.target.value);
    setEditor1(e.target.value);
  }
  const handleDateChange = (time) => {
    setDate(time); 
    setDates(format(time, "dd/MM/yyyy hh:mm aa"))

  }; 
  const [date1, setDate1] = useState(null);
  const [dates1, setDates1] = useState('');


  const handleDateChange1 = (time) => {
    setDate1(time);
    setDates1(format(time, "dd/MM/yyyy hh:mm aa"))
  };
  const [date2, setDate2] = useState(null);
  const [dates2, setDates2] = useState('');


  const handleDateChange2 = (time) => {
    setDate2(time);
    setDates2(format(time, "dd/MM/yyyy hh:mm aa"))
  };

  const [date3, setDate3] = useState(null);
  const [dates3, setDates3] = useState('');

  const handleDateChange3 = (time) => {
    setDate3(time);
    setDates3(format(time, "dd/MM/yyyy hh:mm aa"))
  };
  const [date44, setDate44] = useState(null);
  const [dates44, setDates44] = useState('');


  const handleDateChange44 = (time) => {
    setDate44(time);
    setDates44(format(time, "dd/MM/yyyy hh:mm aa"))
  };
  const [date4, setDate4] = useState(null);
  const [dates4, setDates4] = useState('');


  const handleDateChange4 = (time) => {
    setDate4(time);
    setDates4(format(time, "dd/MM/yyyy hh:mm aa"))
  };
  const [date5, setDate5] = useState(null);
  const [dates5, setDates5] = useState(null);


  const handleDateChange5 = (time) => {
    setDate5(time);
    setDates5(format(time, "dd/MM/yyyy hh:mm aa"))
  }; 
  const [date6, setDate6] = useState(null);

  const [dates6, setDates6] = useState('');
  const handleDateChange6 = (time) => {
    setDate6(time);
    setDates6(format(time, "dd/MM/yyyy hh:mm aa"))
  }; 
  
  const [date7, setDate7] = useState(null);
  const [dates7, setDates7] = useState('');


  const handleDateChange7 = (time) => {
    setDate7(time);
    setDates7(format(time, "dd/MM/yyyy hh:mm aa"))
  };
  
  const [date8, setDate8] = useState(null);
  const [dates8, setDates8] = useState('');


  const handleDateChange8 = (time) => {
    setDate8(time);
    setDates8(format(time, "dd/MM/yyyy"))
  };
  
  const [date9, setDate9] = useState(null);
  const [dates9, setDates9] = useState('');


  const handleDateChange9 = (time) => {
    setDate9(time);
    setDates9(format(time, "dd/MM/yyyy hh:mm aa"))
  }; 
  
  const [date10, setDate10] = useState(null);
  const [dates10, setDates10] = useState('');


  const handleDateChange10 = (time) => {
    setDate10(time);
    setDates10(format(time, "dd/MM/yyyy hh:mm aa"))
  }; 
  
  const [date11, setDate11] = useState(null);
  const [dates11, setDates11] = useState('');

  const [isFormsubmit, setFormsubmited] = useState(false);
  const handleDateChange11 = (time) => {
    setDate11(time);
    setDates11(format(time, "dd/MM/yyyy hh:mm aa"))
  };
  const [dateInvoice, setDateInvoice] = useState(null);
  const [datesInvoice, setDatesInvoice] = useState('');


  const handleDateChangeInvoice= (time) => {
    setDateInvoice(time);
    setDatesInvoice(format(time, "dd/MM/yyyy hh:mm aa"))
  };
  const [date12, setDate12] = useState(null);
  const [dates12, setDates12] = useState('');

  const handleDateChange12 = (time) => {
    setDate12(time);
    setDates12(format(time, "dd/MM/yyyy hh:mm aa"))
  };
  const [date13, setDate13] = useState(null);
  const [dates13, setDates13] = useState('');


  const handleDateChange13 = (time) => {
    setDate13(time);
    setDates13(format(time, "dd/MM/yyyy hh:mm aa"))
  };
  const [date14, setDate14] = useState(null);
  const [dates14, setDates14] = useState('');


  const handleDateChange14 = (time) => {
    setDate14(time);
    setDates14(format(time, "dd/MM/yyyy hh:mm aa"))
  };
  const [date15, setDate15] = useState(null);
  const [dates15, setDates15] = useState('');


  const handleDateChange15 = (time) => {
    setDate15(time);
    setDates15(format(time, "dd/MM/yyyy hh:mm aa"))
  };
  const [date16, setDate16] = useState(null);
  const [dates16, setDates16] = useState('');


  const handleDateChange16 = (time) => {
    setDate16(time);
    setDates16(format(time, "dd/MM/yyyy hh:mm aa"))
  };
  const [date17, setDate17] = useState(null);
  const [dates17, setDates17] = useState('')


  const handleDateChange17 = (time) => {
    setDate17(time);
    setDates17(format(time, "dd/MM/yyyy hh:mm aa"))
  };
  const [date18, setDate18] = useState(null);
  const [dates18, setDates18] = useState(null);

  const handleDateChange18 = (time) => {
    setDate18(time);
    setDates18(format(time, "dd/MM/yyyy hh:mm aa"))
  };



  //checkbox
  const [actives, setActives] = useState(false); // Initialize the state of the checkbox

  const handleCheckboxActive = (e) => {
    setActives(e.target.checked);
  };
  const [actives1, setActives1] = useState(false); // Initialize the state of the checkbox

  const handleCheckboxActive1 = (e) => {
    setActives1(e.target.checked);
  };
  const [actives10, setActives10] = useState(false); // Initialize the state of the checkbox

  const handleCheckboxActive10 = (e) => {
    setActives10(e.target.checked);
  };
  const [actives2, setActives2] = useState(false); // Initialize the state of the checkbox

  const handleCheckboxActive2 = (e) => {
    setActives2(e.target.checked);
  };
  const [actives3, setActives3] = useState(false); // Initialize the state of the checkbox

  const handleCheckboxActive3 = (e) => {
    setActives3(e.target.checked);
  };

  const [actives4, setActives4] = useState(false); // Initialize the state of the checkbox

  const handleCheckboxActive4 = (e) => {
    setActives4(e.target.checked);
  };
  const [actives5, setActives5] = useState(false); // Initialize the state of the checkbox

  const handleCheckboxActive5 = (e) => {
    setActives5(e.target.checked);
  };

  const [actives6, setActives6] = useState(false); // Initialize the state of the checkbox

  const handleCheckboxActive6 = (e) => {
    setActives6(e.target.checked);
  };
  const [actives7, setActives7] = useState(false); // Initialize the state of the checkbox

  const handleCheckboxActive7 = (e) => {
    setActives7(e.target.checked);
  };
  
  const [actives8, setActives8] = useState(false); // Initialize the state of the checkbox

  const handleCheckboxActive8 = (e) => {
    setActives8(e.target.checked);
  };
  const [actives9, setActives9] = useState(false); // Initialize the state of the checkbox

  const handleCheckboxActive9 = (e) => {
    setActives9(e.target.checked);
  };
  useEffect(() => {

    fetchData1();
  }, [decryptedAccessToken]);

  const fetchData1 = async () => {
    try {
      const response = await axios.get(`${API_URL}get/postship/preshipbyID/${id}`, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      }
      );
      const data = response.data.preship_details;
      console.log(data)
      setRwoodbank(data);

    } catch (error) {
      console.error(error);
    }
  };


  const [four, setFour] = useState(new Date());
  const fours =
    four.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
      .split('/')
      .reverse()
      .join('-');
  const Date4 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));
  const [five, setFive] = useState(new Date());
  const fives =
    five.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
      .split('/')
      .reverse()
      .join('-');
  const Date5 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));
  const [eight, setEight] = useState(new Date());
  const eights =
    eight.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
      .split('/')
      .reverse()
      .join('-');
  const Date8 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));

  const [seven, setSeven] = useState(new Date());

  const sevens =
    seven.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
      .split('/')
      .reverse()
      .join('-');
  const Date7 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));

  const [six, setSix] = useState(new Date());
  const sixs =
    six.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
      .split('/')
      .reverse()
      .join('-');
  const Date6 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));

  const [eleven, setEleven] = useState(new Date());

  const elevens =
    eleven.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
      .split('/')
      .reverse()
      .join('-');
  const Date11 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));


  const [ten, setTen] = useState(new Date());
  const tens =
    ten.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
      .split('/')
      .reverse()
      .join('-');
  const Date10 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));


  const [nine, setNine] = useState(new Date());;

  const nines =
    nine.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
      .split('/')
      .reverse()
      .join('-');
  const Date9 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));

  
  const [negotiableDate, setStartnegotiable] = useState(null);
  const birthDate_1 = negotiableDate ? `${negotiableDate.getDate().toString().padStart(2, '0')}/${(negotiableDate.getMonth() + 1).toString().padStart(2, '0')}/${negotiableDate.getFullYear()}` : null;
  const handlestartDateChanges = (date) => {
    setStartnegotiable(date);
  };
  const [startDate, setStartDate] = useState(null);
  const birthDate = startDate ? `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}` : null;
  const handlestartDateChange = (date) => {
    setStartDate(date);
  };

  const [startDate1, setstartDate1] = useState(null);
  const birthDate1 = startDate1 ? `${startDate1.getDate().toString().padStart(2, '0')}/${(startDate1.getMonth() + 1).toString().padStart(2, '0')}/${startDate1.getFullYear()}` : null;
  const handlestartDateChange1 = (date) => {
    setstartDate1(date);
  };

  const [startSupplier, setStartSupplier] = useState(null);
  const supplier_date = startSupplier ? `${startSupplier.getDate().toString().padStart(2, '0')}/${(startSupplier.getMonth() + 1).toString().padStart(2, '0')}/${startSupplier.getFullYear()}` : null;
  const handlestartSupplier = (date) => {
    setStartSupplier(date);
  };


  const [startDate11, setstartDate11] = useState(null);
  const birthDate11 = startDate11 ? `${startDate11.getDate().toString().padStart(2, '0')}/${(startDate11.getMonth() + 1).toString().padStart(2, '0')}/${startDate11.getFullYear()}` : null;
  const handlestartDateChange11 = (date) => {
    setstartDate11(date);
  };
  const [startDate2, setstartDate2] = useState(null);
  const birthDate2 = startDate2 ? `${startDate2.getDate().toString().padStart(2, '0')}/${(startDate2.getMonth() + 1).toString().padStart(2, '0')}/${startDate2.getFullYear()}` : null;
  const handlestartDateChange2 = (date) => {
    setstartDate2(date);
  };
  const [startDate22, setstartDate22] = useState(null);
  const birthDate22 = startDate22 ? `${startDate22.getDate().toString().padStart(2, '0')}/${(startDate22.getMonth() + 1).toString().padStart(2, '0')}/${startDate22.getFullYear()}` : null;
  const handlestartDateChange22 = (date) => {
    setstartDate22(date);
  };
  const [startDate3, setstartDate3] = useState(null);
  const birthDate3 = startDate3 ? `${startDate3.getDate().toString().padStart(2, '0')}/${(startDate3.getMonth() + 1).toString().padStart(2, '0')}/${startDate3.getFullYear()}` : null;
  const handlestartDateChange3 = (date) => {
    setstartDate3(date);
  };

  const [startDate4, setstartDate4] = useState(null);
  const birthDate4 = startDate4 ? `${startDate4.getDate().toString().padStart(2, '0')}/${(startDate4.getMonth() + 1).toString().padStart(2, '0')}/${startDate4.getFullYear()}` : null;
  const handlestartDateChange4 = (date) => {
    setstartDate4(date);
  };
  const [startDate5, setstartDate5] = useState(null);
  const birthDate5 = startDate5 ? `${startDate5.getDate().toString().padStart(2, '0')}/${(startDate5.getMonth() + 1).toString().padStart(2, '0')}/${startDate5.getFullYear()}` : null;
  const handlestartDateChange5 = (date) => {
    setstartDate5(date);
  };

  const [startDate6, setstartDate6] = useState(null);
  const birthDate6 = startDate6 ? `${startDate6.getDate().toString().padStart(2, '0')}/${(startDate6.getMonth() + 1).toString().padStart(2, '0')}/${startDate6.getFullYear()}` : null;
  const handlestartDateChange6 = (date) => {
    setstartDate6(date);
  };
  const [startDate66, setstartDate66] = useState(null);
  const birthDate66 = startDate66 ? `${startDate66.getDate().toString().padStart(2, '0')}/${(startDate66.getMonth() + 1).toString().padStart(2, '0')}/${startDate66.getFullYear()}` : null;
  const handlestartDateChange66 = (date) => {
    setstartDate66(date);
  };

  const [startDateInvoiceDate, setstartDateInvoiceDate] = useState(null);
  const birthDateInvoiceDate = startDateInvoiceDate ? `${startDateInvoiceDate.getDate().toString().padStart(2, '0')}/${(startDateInvoiceDate.getMonth() + 1).toString().padStart(2, '0')}/${startDateInvoiceDate.getFullYear()}` : null;
  const handlestartDateChangeInvoiceDate = (date) => {
    setstartDateInvoiceDate(date);
  };
  const [startDateInvoiceActual, setstartDateInvoiceActual] = useState(null);
  const birthDateInvoiceDateActual = startDateInvoiceActual ? `${startDateInvoiceActual.getDate().toString().padStart(2, '0')}/${(startDateInvoiceActual.getMonth() + 1).toString().padStart(2, '0')}/${startDateInvoiceActual.getFullYear()}` : null;
  const handlestartDateChangeActual = (date) => {
    setstartDateInvoiceActual(date);
  };

  
  
  const [startDate7, setstartDate7] = useState(null);
  const birthDate7 = startDate7 ? `${startDate7.getDate().toString().padStart(2, '0')}/${(startDate7.getMonth() + 1).toString().padStart(2, '0')}/${startDate7.getFullYear()}` : null;
  const handlestartDateChange7 = (date) => {
    setstartDate7(date);
  };
  const [startDate8, setstartDate8] = useState(null);
  const birthDate8 = startDate8 ? `${startDate8.getDate().toString().padStart(2, '0')}/${(startDate8.getMonth() + 1).toString().padStart(2, '0')}/${startDate8.getFullYear()}` : null;
  const handlestartDateChange8 = (date) => {
    setstartDate8(date);
  };
  const [startDate9, setstartDate9] = useState(null);
  const birthDate9 = startDate9 ? `${startDate9.getDate().toString().padStart(2, '0')}/${(startDate9.getMonth() + 1).toString().padStart(2, '0')}/${startDate9.getFullYear()}` : null;
  const handlestartDateChange9 = (date) => {
    setstartDate9(date);
  };
  const [startDate10, setstartDate10] = useState(null);
  const birthDate10 = startDate10 ? `${startDate10.getDate().toString().padStart(2, '0')}/${(startDate10.getMonth() + 1).toString().padStart(2, '0')}/${startDate10.getFullYear()}` : null;
  const handlestartDateChange10 = (date) => {
    setstartDate10(date);
  };


  const id = params.id
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const onSubmit = data => {
    setFormsubmited(true) 
    const responseData = {
      currency: data.currency,
      import_document_airway_no:data.import_document_airway_no,
      pre_ship_document: data.pre_ship_document,
      locked: actives9,
      exchange_rate: data.exchange_rate,
      bl_issue_time_and_date: dates3?dates3:null,
      coo_destination: data.coo_destination,
      bl_confirm: actives6,
      bl_draft_or_splits_review_confirm_date: birthDate11?birthDate11:null,
      bl_no: data.bl_no,
      supplier_invoice_price:parseFloat(data.supplier_invoice_price),
      bl_quantity: data.bl_quantity,
      bl_date: birthDate1?birthDate1:null,
      bl_draft_sent_date_and_time:dates?dates:null,
      obl_email_recieved_date_and_time: dates1?dates1:null,
      coo_form_govt_recieve_by_email: dates4?dates4:null,
      bl_issue_place: data.bl_issue_place,
      bl_status: data.bl_status,
      shiper_surveyor_coa_date: dates5?dates5:null,
      certificate_of_weight: actives,
      document_discount_date:dates18?dates18:null,
      document_collection_date:dates17?dates17:null,
      rwood_bank_to_buyer_bank_airway_date:dates15?dates15:null,
      rwood_or_cust_surveyor_coa_date: dates6?dates6:null,
      certificate_of_hold_cleanliness: actives1,
      checking_of_import_invoice:actives7,
      buyer_bank_document_receive_date:dates16?dates16:null,
      supplier_invoice_check_date:supplier_date?supplier_date:null,
      rwood_bank_to_buyer_bank_airway_no:data.rwood_bank_to_buyer_bank_airway_no,
      supplier_bank_to_rwood_bank_sent_date:dates10?dates10:null,
      net_weight_as_per_document:parseFloat(data.net_weight_as_per_document),
      draft_survey_report: actives2,
      submitted_by_shipper_to_shipper_bank_date:dates9?dates9:null,
      addi_surveyor_certification_date: dates7?dates7:null,
      certificate_of_registry: actives3,
      non_negotiable_document_sent_date:birthDate_1?birthDate_1:null,
      coa_status: data.coa_status,
      import_document_rwood_bank_recieved_date:dates11?dates11:null,
      classification_certificate: actives4,
      coo_status: data.coo_status,
      commercial_invoice_confirmation_date_and_time:datesInvoice?datesInvoice:null,
      rwood_bank_to_buyer_bank_airway_company:data.rwood_bank_to_buyer_bank_airway_company,
      rwood_bank_to_buyer_bank_send_date:dates14?dates14:null,
      safety_management_certificate: actives5,
      cow_status: data.cow_status,
      dsr_status: data.dsr_status,
      govt_coo_status: data.govt_coo_status,
      reason_for_delay: data.reason_for_delay,
      coo_issuance_date: birthDate2?birthDate2:null,
      document_submission_date:dates13?dates13:null,
      coo_courier_date: birthDate3?birthDate3:null,
      scan_bl_rcvd_date: birthDate4?birthDate4:null,
      import_document_airway_company:data.import_document_airway_company,
      scan_iia_certs_rcvd_date: birthDate5?birthDate5:null,
      scan_all_original_docs_rcvd_date: birthDate6?birthDate6:null,
      commercial_invoice_prepare_date:birthDate66?birthDate66:null,
      commercial_invoice_date:birthDateInvoiceDate?birthDateInvoiceDate:null,
      commercial_invoice_no:parseFloat(data.commercial_invoice_no),
      actual_payment_received_date:birthDateInvoiceDateActual?birthDateInvoiceDateActual:null,
      commercial_invoice_price:parseFloat(data.commercial_invoice_price),
      org_document_to_buyer_80_percent: birthDate7?birthDate7:null,
      arrived_at_discharge_port: birthDate8?birthDate8:null,
      org_document_to_buyer_20_percent: birthDate9?birthDate9:null, 
      expected_value_date: birthDate10?birthDate10:null,
      coo_courier_tracking_no: data.coo_courier_tracking_no,
      coo_courier_company: data.coo_courier_company,
      insurance_of_certificate:actives10,
      actual_payment_received:actives8,
      payment_remark:data.payment_remark,
      remark:editor1


    }; 
    console.log(JSON.stringify(responseData))
    const encryptedAccessToken = localStorage.getItem('access_token');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
    
      axios.post(`${API_URL}add/postshipment/${id}`, responseData, {
  headers: {
    Authorization: `Bearer ${decryptedAccessToken}`,
    'Content-Type': 'application/json'
  }
})
      .then(response => {
        console.log(response);
        if (response.data.success === true) {
          
          document.getElementById('messageContainer').innerHTML = `
          <div class="success-message">
              ${response.data.msg}
          </div>
      `;
      window.scrollTo({
        top: 0,
        behavior: "smooth" // Optional: animated scrolling
      });
          setTimeout(() => {
            navigate("/postshipment/postshipment-details/" + response.data.postshipment_id);
            reset();
          }, 500);
        } else if (response.data.success === false) {
           
          document.getElementById('messageContainer').innerHTML = `
          <div class="error-message">
              ${response.data.msg}
          </div>
      `;
      window.scrollTo({
        top: 0,
        behavior: "smooth" // Optional: animated scrolling
      });
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
        // Optionally, you can add error handling logic or toast notifications here
      });
    }
  };
  const [one, setOne] = useState(new Date());
  const ones =
    one.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    one.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    one.toLocaleDateString("en-US", { day: "2-digit" });
  const Date1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name="renewal_date"
      ref={ref}
    ></input>
  ));



  const [three, setThree] = useState(new Date());
  const threes =
    three.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    three.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    three.toLocaleDateString("en-US", { day: "2-digit" });
  const Date3 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));

  const [thirteen, setThirteen] = useState(new Date());
  const thirteens =
    thirteen.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    thirteen.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    thirteen.toLocaleDateString("en-US", { day: "2-digit" });
  const Date13 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));

  const [twele, setTwele] = useState(new Date());
  const tweles =
    twele.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    twele.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    twele.toLocaleDateString("en-US", { day: "2-digit" });
  const Date12 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));

  return (

    <div className="contactAccount post-shipment fulid-section order-section" id='contacts'>
      <Header />
      <div className="page-header" id='add-account'>
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Add Postshipment </h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">Dashboard / Postshipment / Add Postshipment</li>
          </ol>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">

            {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> <div className="tasks fulid-section order-section">*/}

          </div>
        </div>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
      <div id="messageContainer"></div>
        <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value="Save"
      //disabled={isFormsubmit}
    /><Link to={'/order/view-order-details/' + id}><input type="submit" className="account-save" value="Cancel" /></Link></p>
        <Row className="mb-3">

          <div className='col-md-12'>
            <h4 className='heading'>Information</h4>
          </div>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="Currency"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="currency" // value or onChnage ?
                placeholder="Currency"
                {...register("currency", { required: false })}
              >
                <option value="">Select </option>
                <option value="USD">USD </option>
                <option value="IDR">IDR </option>
              </Form.Select>
              {errors.currency && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          
          <input
          type='checkbox'
          name='locked'
          checked={actives9} // Use the state variable to determine if the checkbox is checked
          onChange={handleCheckboxActive9} // Handle the change event
          className='mx-3'
        />
        <label>Locked</label>
        </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Exchange rate"
              className="mb-3"


            >
              <Form.Control
                type="text"
                placeholder="Exchange rate"
                name="exchange_rate"
                {...register("exchange_rate", { valueAsNumber: true, required: false })}
              />
              {errors.exchange_rate && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Pre Shipment Document">
              <Form.Select aria-label="Floating label select example"
                name="pre_ship_document"
                placeholder="Pre Shipment Document"
                className={`error-validation form-control ${errors.pre_ship_document ? 'is-invalid' : ''}`}
                {...register("pre_ship_document", { required: 'Pre Shipment Document is required' })}
              >
                <option value='' >Select</option>


                {rwoodbank.length > 0 ? (
                  <>
                    {
                      rwoodbank.map((x) => {
                        return (
                          <option value={x.pre_code_no}>{x.pre_code_no}</option>
                        )
                      })
                    }
                  </>
                ) : (

                  <option className='no-data' value='empty'>No data  available</option>
                )}
              </Form.Select>
              {/* {errors.pre_ship_document && <span>Required</span>} */}
            </FloatingLabel>
          </Form.Group>
          {/* <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="pre ship document"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="pre ship document"
              name="pre_ship_document"
              {...register("pre_ship_document", {valueAsNumber:false, required: false })}
            />
            {errors.pre_ship_document && <span>Required</span>}
          </FloatingLabel>
        </Form.Group> */}

        </Row>
        <Row>
          <div className='col-md-12'>
            <h4 className='heading'>BL Draft/AI COO</h4>
          </div>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="BL Draft Sent Date n Time"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={date}
                onChange={handleDateChange}
                showTimeSelect
                dayClassName={() => "example-datepicker-day-class"}
                popperClassName="example-datepicker-class"
                todayButton="TODAY"
                dateFormat="dd/MM/yyyy hh:mm aa"
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="Time" placeholderText="Select"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="COO Destination"
              className="mb-3"
            >

              <Form.Control type="text"
                placeholder="COO Destination"
                name="coo_destination"
                {...register("coo_destination", { required: false })}
              />
              {errors.coo_destination && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          
          <input
          type='checkbox'
          name='bl_confirm'
          checked={actives6} // Use the state variable to determine if the checkbox is checked
          onChange={handleCheckboxActive6} // Handle the change event
          className='mx-3'
        />
        <label>BL Confirm</label>
        </Form.Group>
        <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="BL Draft / Splits Review Confirm Date	"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={startDate11}
                onChange={handlestartDateChange11}

                dateFormat='dd/MM/yyyy'
                placeholderText="Select"
              />

            </FloatingLabel>
          </Form.Group>

         

        </Row>

        <Row>
          <div className='col-md-12'>
            <h4 className='heading'>BL Issued</h4>
          </div>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="BL No"
              className="mb-3"
            >

              <Form.Control type="text"
                placeholder="BL No"
                name="bl_no"
                // className={`error-validation form-control ${errors.bl_no ? 'is-invalid' : ''}`}
                {...register("bl_no")}
              />
              {/* {errors.bl_no && <span>Required</span>} */}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="BL Quantity"
              className="mb-3"
            >

              <Form.Control type="text"
                placeholder="BL Quantity"
                name="bl_quantity"
                className={`error-validation form-control ${errors.bl_quantity ? 'is-invalid' : ''}`}
                {...register("bl_quantity", { required: 'Bill Quantity is required', valueAsNumber: true })}
              />
              {errors.bl_quantity && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="BL Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={startDate1}
                onChange={handlestartDateChange1}

                dateFormat='dd/MM/yyyy'
                placeholderText="Select"
              />

            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="OBL Email Received Date n Time"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={date1}
                onChange={handleDateChange1}
                showTimeSelect
                dateFormat="dd/MM/yyyy hh:mm aa"
                timeFormat="hh:mm aa"
                placeholderText="Select Date and Time"
              />

            </FloatingLabel>
          </Form.Group>


          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="BL Issue Date and Time"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={date3}
                onChange={handleDateChange3}
                showTimeSelect
                dayClassName={() => "example-datepicker-day-class"}
                popperClassName="example-datepicker-class"

                dateFormat="dd/MM/yyyy hh:mm aa"
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="Time" placeholderText="Select"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="COO form Govt receive by email"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={date4}
                onChange={handleDateChange4}
                showTimeSelect
                dayClassName={() => "example-datepicker-day-class"}
                popperClassName="example-datepicker-class"

                dateFormat="dd/MM/yyyy hh:mm aa"
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="Time" placeholderText="Select"

              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="BL Issue Place"
              className="mb-3"
            >

              <Form.Control type="text"
                placeholder="BL Issue Place"
                name="bl_issue_place"
                {...register("bl_issue_place", { required: false })}
              />
              {errors.bl_issue_place && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6"></Form.Group>
          <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="BL Status">
              <Form.Select aria-label="Floating label select example"
                name="bl_status"
                id='account_name'
                placeholder="BL Status"
                {...register("bl_status", { valueAsNumber: false, required: false })}
              // {...register("bl_status", { required: 'BL Status is required', valueAsNumber:true })}
              >
                <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
              </Form.Select>
              {/* {errors.bl_status && <span>Required </span>} */}
            </FloatingLabel>
          </Form.Group>

        </Row>
        <Row>
          <div className='col-md-12'>
            <h4 className='heading'>Additional Surveyors Certificates</h4>
          </div>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Shiper Surveyor COA Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={date5}
                onChange={handleDateChange5}
                dayClassName={() => "example-datepicker-day-class"}
                popperClassName="example-datepicker-class"

                dateFormat='dd/MM/yyyy hh:mm:aa'
                timeIntervals={15}
                timeCaption="Time" placeholderText="Select"

                showTimeSelect
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
          
          <input
          type='checkbox'
          name='certificate_of_weight'
          checked={actives} // Use the state variable to determine if the checkbox is checked
          onChange={handleCheckboxActive} // Handle the change event
          className='mx-3'
        />
        <label>certificate of weight</label>
        </Form.Group>
         

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Rwood/Cust Surveyor COA Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={date6}
                onChange={handleDateChange6}
                showTimeSelect
                dayClassName={() => "example-datepicker-day-class"}
                popperClassName="example-datepicker-class"

                dateFormat="dd/MM/yyyy hh:mm aa"
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="Time" placeholderText="Select"
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          
          <input
          type='checkbox'
          name='certificate_of_hold_cleanliness'
          checked={actives1} // Use the state variable to determine if the checkbox is checked
          onChange={handleCheckboxActive1} // Handle the change event
          className='mx-3'
        />
        <label>Certificate of hold cleanliness</label>
        </Form.Group> 
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Net Weight As per Document"
              className="mb-3"
            >

              <Form.Control type="text"
                placeholder="Net Weight As per Document"
                name="net_weight_as_per_document"
                {...register("net_weight_as_per_document", { valueAsNumber: true, required: false })}
              />
              {errors.net_weight_as_per_document && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
          
          <input
          type='checkbox'
          name='draft_survey_report'
          checked={actives2} // Use the state variable to determine if the checkbox is checked
          onChange={handleCheckboxActive2} // Handle the change event
          className='mx-3'
        />
        <label>Draft Survey Report</label>
        </Form.Group>
           
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Addi Surveyor Certificate Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={date7}
                onChange={handleDateChange7}
                showTimeSelect  
                dayClassName={() => "example-datepicker-day-class"}
                popperClassName="example-datepicker-class"

                dateFormat="dd/MM/yyyy hh:mm aa"
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="Time" placeholderText="Select"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
          
          <input
          type='checkbox'
          name='certificate_of_registry'
          checked={actives3} // Use the state variable to determine if the checkbox is checked
          onChange={handleCheckboxActive3} // Handle the change event
          className='mx-3'
        />
        <label>Certificate of Registry	</label>
        </Form.Group>
           
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="COA Status"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="coa_status"
                placeholder="COA Status"
                {...register("coa_status", { required: false })}
              >
                <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
              </Form.Select>
              {errors.coa_status && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
          
          <input
          type='checkbox'
          name='classification_certificate'
          checked={actives4} // Use the state variable to determine if the checkbox is checked
          onChange={handleCheckboxActive4} // Handle the change event
          className='mx-3'
        />
        <label>Classification Certificate</label>
        </Form.Group>
           

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="COO Status"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="coo_status"
                placeholder="COO Status"
                {...register("coo_status", { required: false })}
              >
                <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
              </Form.Select>
              {errors.coo_status && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
          
          <input
          type='checkbox'
          name='safety_management_certificate'
          checked={actives5} // Use the state variable to determine if the checkbox is checked
          onChange={handleCheckboxActive5} // Handle the change event
          className='mx-3'
        />
        <label>Safety Management Certificate</label>
        </Form.Group>
            
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="COW Status"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="cow_status"
                placeholder="COW Status"
                {...register("cow_status", { required: false })}
              >
                <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
              </Form.Select>
              {errors.cow_status && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
          
          <input
          type='checkbox'
          name='insurance_of_certificate'
          checked={actives10} // Use the state variable to determine if the checkbox is checked
          onChange={handleCheckboxActive10} // Handle the change event
          className='mx-3'
        />
        <label>insurance of certificate</label>
        </Form.Group>
            
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="DSR Status"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="dsr_status"
                placeholder="DSR Status"
                {...register("dsr_status", { required: false })}
              >
                <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
              </Form.Select>
              {errors.dsr_status && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Reason for Delay"
              className="mb-3"
            >

              <Form.Control type="text"
                placeholder="Reason for Delay"
                name="reason_for_delay"
                {...register("reason_for_delay", { required: false })}
              />
              {errors.reason_for_delay && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="Govt COO Status"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="govt_coo_status"
                placeholder="Govt COO Status"
                {...register("govt_coo_status", { required: false })}
              >
                <option value="">--None--</option><option value="Draft Review">Draft Review</option><option value="Draft Confirmed">Draft Confirmed</option><option value="Not Issued">Not Issued</option><option value="Issued">Issued</option><option value="Not Required">Not Required</option>
              </Form.Select>
              {errors.govt_coo_status && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          
        </Row>
        <Row>

          <div className='col-md-12'>
            <h4 className='heading'>COO Issued and Courier information</h4>
          </div>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="COO Issuance Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={startDate2}
                onChange={handlestartDateChange2}

                dateFormat='dd/MM/yyyy'
                placeholderText="Select"
              />

            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="COO Courier Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={startDate3}
                onChange={handlestartDateChange3}

                dateFormat='dd/MM/yyyy'
                placeholderText="Select"
              />

            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6"></Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="COO Courier Tracking No"
              className="mb-3"
            >

              <Form.Control type="text"
                placeholder="COO Courier Tracking No"
                name="coo_courier_tracking_no"
                {...register("coo_courier_tracking_no", { required: false })}
              />
              {errors.coo_courier_tracking_no && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6"></Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="COO Courier Company"
              className="mb-3"
            >

              <Form.Control type="text"
                placeholder="COO Courier Company"
                name="coo_courier_company"
                {...register("coo_courier_company", { required: false })}
              />
              {errors.coo_courier_company && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
        </Row>
        {/* pending */}
        <Row>

          <div className='col-md-12'>
            <h4 className='heading'>Import Invoice</h4>
          </div>
          <Form.Group as={Col} md="6">
          
          <input
          type='checkbox' 
          name='checking_of_import_invoice'
          checked={actives7} // Use the state variable to determine if the checkbox is checked
          onChange={handleCheckboxActive7} // Handle the change event
          className='mx-3'
        />
        <label>checking of import invoice</label>
        </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Supplier Invoice Price"
              className="mb-3"
            >

              <Form.Control type="text"
                placeholder="Supplier Invoice Price"
                name="supplier_invoice_price"
                {...register("supplier_invoice_price", { valueAsNumber: true, required: false })}
              />
              {errors.supplier_invoice_price && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Supplier Invoice Check Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={startSupplier}
                onChange={handlestartSupplier} 

                dateFormat="dd/MM/yyyy" placeholderText="Select"
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6"></Form.Group>
          <Form.Group as={Col} md="6">
              <FloatingLabel controlId="floatingTextarea2" label="Remark">
              <Editor value={editor1} onChange={onChanges} />
            </FloatingLabel>  
            
          </Form.Group>
        </Row>
        <Row>
          <div className='col-md-12'>
            <h4 className='heading'>Document Submitted by Shipper/Supplier</h4>
          </div>


          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Submited by Shiper to Shiper Bank Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={date9}
                onChange={handleDateChange9}
                showTimeSelect
                dayClassName={() => "example-datepicker-day-class"}
                popperClassName="example-datepicker-class"

                dateFormat="dd/MM/yyyy hh:mm aa"
                timeFormat="hh:mm aa" 
                timeIntervals={15}
                timeCaption="Time" placeholderText="Select"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Scan BL Rcvd Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={startDate4}
                onChange={handlestartDateChange4}

                dateFormat='dd/MM/yyyy'
                placeholderText="Select"
              />

            </FloatingLabel>
          </Form.Group>


          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Supplier Bank to RWood Bank Sent Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={date10}
                onChange={handleDateChange10}
                showTimeSelect
                dayClassName={() => "example-datepicker-day-class"}
                popperClassName="example-datepicker-class"

                dateFormat="dd/MM/yyyy hh:mm aa"
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="Time" placeholderText="Select"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Scan IIA Certs Rcvd Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={startDate5}
                onChange={handlestartDateChange5}

                dateFormat='dd/MM/yyyy'
                placeholderText="Select"
              />

            </FloatingLabel>
          </Form.Group>


          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Import Document RWood Bank recieved Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={date11}
                onChange={handleDateChange11}
                showTimeSelect
                dayClassName={() => "example-datepicker-day-class"}
                popperClassName="example-datepicker-class"

                dateFormat="dd/MM/yyyy hh:mm aa"
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="Time" placeholderText="Select"
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Scan all Orginal Docs Rcvd Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={startDate6}
                onChange={handlestartDateChange6}

                dateFormat='dd/MM/yyyy'
                placeholderText="Select"
              />

            </FloatingLabel>
          </Form.Group>


          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Import Document Airway No"
              className="mb-3"
            >

              <Form.Control type="text"
                placeholder="Import Document Airway No"
                name="import_document_airway_no"
                {...register("import_document_airway_no", { required: false })}
              />
              {errors.import_document_airway_no && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6"></Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Import Document Airway Company"
              className="mb-3"
            >

              <Form.Control 
              type="text"
                placeholder="Import Document Airway Company"
                name="import_document_airway_company"
                {...register("import_document_airway_company", { required: false })}
              />
              {errors.import_document_airway_company && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>

<div className='col-md-12'>
  <h4 className='heading'>Documents Submitted to Buyer
</h4>
</div> 

        <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Org Document To Buyer 80%"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={startDate7}
                onChange={handlestartDateChange7}

                dateFormat='dd/MM/yyyy'
                placeholderText="Select"
              />

            </FloatingLabel>
          </Form.Group>
        <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Arrived At Discharge Port"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={startDate8}
                onChange={handlestartDateChange8}

                dateFormat='dd/MM/yyyy'
                placeholderText="Select"
              />

            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Org Document To Buyer 20%"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={startDate9}
                onChange={handlestartDateChange9}

                dateFormat='dd/MM/yyyy'
                placeholderText="Select"
              />

            </FloatingLabel>
          </Form.Group>
          </Row>
        <Row>

          <div className='col-md-12'>
            <h4 className='heading'>Commercial Invoice</h4>
          </div> 
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Commercial Invoice Prepare Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={startDate66}
                onChange={handlestartDateChange66}

                dateFormat='dd/MM/yyyy'
                placeholderText="Select"
              />

            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Comme Invoice Confirmation Date n Time	"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={dateInvoice}
                onChange={handleDateChangeInvoice}
                showTimeSelect
                dayClassName={() => "example-datepicker-day-class"}
                popperClassName="example-datepicker-class"

                dateFormat="dd/MM/yyyy hh:mm aa"
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="Time" placeholderText="Select"
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Commercial Invoice No"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Commercial Invoice No"
                    name="commercial_invoice_no"
                    {...register("commercial_invoice_no", { required: false })}
                    />
                    {errors.commercial_invoice_no && <span>Required</span>}
                </FloatingLabel>

              </Form.Group>
              <Form.Group
                as={Col}
                md="6"

              >
                <FloatingLabel
                  controlId="floatingInput"
                  label="Commercial Invoice Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Commercial Invoice Price"
                    name="commercial_invoice_price"
                    {...register("commercial_invoice_price", { required: false })}
                    />
                    {errors.commercial_invoice_price && <span>Required</span>}
                </FloatingLabel>

              </Form.Group>
              <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Comm Invoice Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={startDateInvoiceDate}
                onChange={handlestartDateChangeInvoiceDate}

                dateFormat='dd/MM/yyyy'
                placeholderText="Select"
              />

            </FloatingLabel>
          </Form.Group>
        </Row>
        <Row>
        <div className='col-md-12'>
            <h4 className='heading'>Non Negotiable Document
</h4>
          </div>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="NN Document Sent Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={negotiableDate}
                onChange={handlestartDateChanges}
                dateFormat='dd/MM/yyyy'
                placeholderText="Select"
              />
            </FloatingLabel>
          </Form.Group>

        </Row>
        <Row>

          <div className='col-md-12'>
            <h4 className='heading'>Rwood Export Document Status
            </h4>
          </div>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Document Submission Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={date13}
                onChange={handleDateChange13}
                showTimeSelect
                dayClassName={() => "example-datepicker-day-class"}
                popperClassName="example-datepicker-class"

                dateFormat="dd/MM/yyyy hh:mm aa"
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="Time" placeholderText="Select"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Rwood Bank to Buyer Bank Airway No"
              className="mb-3"
            >

              <Form.Control type="text"
                placeholder="Rwood Bank to Buyer Bank Airway No"
                name="rwood_bank_to_buyer_bank_airway_no"
                {...register("rwood_bank_to_buyer_bank_airway_no", { required: false })}
              />
              {errors.rwood_bank_to_buyer_bank_airway_no && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Rwood Bank to Buyer Bank Send Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={date14}
                onChange={handleDateChange14}
                showTimeSelect
                dayClassName={() => "example-datepicker-day-class"}
                popperClassName="example-datepicker-class"

                dateFormat="dd/MM/yyyy hh:mm aa"
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="Time" placeholderText="Select"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Rwood Bank to Buyer Bank Airway Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={date15}
                onChange={handleDateChange15}
                showTimeSelect
                dayClassName={() => "example-datepicker-day-class"}
                popperClassName="example-datepicker-class"

                dateFormat="dd/MM/yyyy hh:mm aa"
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="Time" placeholderText="Select"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Buyer Bank Document Receive Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={date16}
                onChange={handleDateChange16}
                showTimeSelect
                dayClassName={() => "example-datepicker-day-class"}
                popperClassName="example-datepicker-class"

                dateFormat="dd/MM/yyyy hh:mm aa"
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="Time" placeholderText="Select"
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Rwood Bank to Buyer Bank Airway Company"
              className="mb-3"
            >

              <Form.Control type="text"
                placeholder="Rwood Bank to Buyer Bank Airway Company"
                name="rwood_bank_to_buyer_bank_airway_company"
                {...register("rwood_bank_to_buyer_bank_airway_company", { required: false })}
              />
              {errors.rwood_bank_to_buyer_bank_airway_company && <span>Required</span>}
            </FloatingLabel>
          </Form.Group>

        </Row>
        <Row>
          <div className="col-md-12">
            <h4 className="heading">Payment</h4></div>

          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Document Collection Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={date17}
                onChange={handleDateChange17}
                showTimeSelect
                dayClassName={() => "example-datepicker-day-class"}
                popperClassName="example-datepicker-class"

                dateFormat="dd/MM/yyyy hh:mm aa"
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="Time" placeholderText="Select"
              />
            </FloatingLabel>

          </Form.Group>

          <Form.Group as={Col} md="6">
          
          <input
          type='checkbox' 
          name='actual_payment_received'
          checked={actives8} // Use the state variable to determine if the checkbox is checked
          onChange={handleCheckboxActive8} // Handle the change event
          className='mx-3'
        />
        <label>actual payment received</label>
        </Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Expected Value Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={startDate10}
                onChange={handlestartDateChange10}

                dateFormat='dd/MM/yyyy'
                placeholderText="Select"
              />

            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6"></Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Actual Payment Received Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={startDateInvoiceActual}
                onChange={handlestartDateChangeActual}

                dateFormat='dd/MM/yyyy'
                placeholderText="Select"
              />

            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6"  className="textarea-half">
            <FloatingLabel controlId="floatingTextarea2" label="Payment Remark">
              <Form.Control
                as="textarea"
                placeholder="Payment Remark"
                style={{ height: "100px!important" }}
                name="payment_remark"
                {...register("payment_remark", { required: false })}
              />
              {errors.payment_remark && <p id="text-area">Required</p>}
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6"></Form.Group>
          <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingInput"
              label="Document Discount Date"
              className="mb-3 date-picker"
            >
              <DatePicker
                selected={date18}
                onChange={handleDateChange18}
                showTimeSelect
                dayClassName={() => "example-datepicker-day-class"}
                popperClassName="example-datepicker-class"

                dateFormat="dd/MM/yyyy hh:mm aa"
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="Time" placeholderText="Select"
              />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <p className='edit-cancel'><input
      type="submit"
      className="account-save"
      value="Save"
      //disabled={isFormsubmit}
    /><Link to={'/post-shipment/create-view-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>

        <div className='contact-success'><p></p><p></p></div>
      </Form>
      <Footer />
    </div>
  );
}

export default Addorderpostshipment;
