import React, { useState, useMemo, useEffect } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from '../Header'
import Footer from '../Footer'  
import axios from "axios";
import { AES, enc } from "crypto-js";
import $ from 'jquery';
import { useParams  } from "react-router-dom"; 
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function Shipment() {
  const [startDate, setStartDate] = useState(new Date());
  const [datas,setDatas]=useState([]);  
  const [selected, setSelected]=useState([]);
  const [opportunityName, setopportunityName] = useState([]);

  useEffect(() => {
   
    fetchDataPrice();
  }, [decryptedAccessToken]);

  const fetchDataPrice = async () => {
    try {
      const response = await axios.get('https://rwooduatpy.znapay.in/get/all/price_items', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.priceitem;
      setSelected(data)
       
    } catch (error) {
      console.error(error);
    }
  };

  
  useEffect(() => {
   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://rwooduatpy.znapay.in/all/opportunity', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.opportunity;
      console.log(data)
      setopportunityName(data);
      
    } catch (error) {
      console.error(error);
    }
  };
  const params = useParams()
  const id=params.id 
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const onSubmit = data =>{ 
console.log(data) 
     const responseData={  
currency:data.currency,
exchange_rate:data.exchange_rate,
status:data.status,
opportunity_shipment:data.opportunity_shipment,
record_type:data.record_type,
price_type:data.price_type,
price_basis:data.price_basis,
price:data.price,
sales_price_margin:data.sales_price_margin,
shipping_term:data.shipping_term,
laycan_from_date:'2023-09-11',
laycan_to_date:'2023-09-11',
laycan_year:data.laycan_year,
laycan_month:data.laycan_month,
quantity:data.quantity,
shipment_quality_tolerance:data.shipment_quality_tolerance,
vessel_type:data.vessel_type,
remark:data.remark
   }; 
   const encryptedAccessToken = localStorage.getItem('access_token');
     const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    
     if (encryptedAccessToken) {
       const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
       
     fetch('https://rwooduatpy.znapay.in/add/shipment/'+id, {
               method: "POST",
               body: JSON.stringify(responseData),
               headers: { Authorization: `Bearer ${decryptedAccessToken}`,
               'Content-Type': 'application/json' },
             })
               .then((response) => response.json())
               
               .then((response) => { 
                console.log('Test'+JSON.stringify(response.message)) 
                if (response.message!='') { 
                  fetch('https://rwooduatpy.znapay.in/add/quoteshipment/'+id, {
                    method: 'POST',
                    headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                    'Content-Type': 'application/json' }
                  })
                  .then((response) => response.json())
               
                  .then((responses) => {
                    console.log(responses)
                   })
                  }
               $('.priceDiv').html("<p class='result'>"+response.message+"</p>"); 
              //  $('.priceDiv').hide(2000)
                
               reset();
              
               })
               
             } 
              
     };

  return (

    <div className="shipment">
    <Header/>
    <div className="page-header" id=''>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Shipment</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Add Shipment</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div>   
  <div className='tasks'>
   <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
      
      <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Currency">
    <Form.Select aria-label="Floating label select example"
    name="currency" 
    placeholder="Currency" 
    id='status'
    defaultValue=""
    {...register("currency", { required:true })}
    >
    <option value=''>Select</option>
    <option value='AED'>AED</option>
    <option value='INR'>INR</option>
    <option value='GBP'>GBP</option>
    </Form.Select>
    {errors.currency && <span>Required Currency</span>}
  </FloatingLabel>
              </Form.Group> 
              <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Opportunity">
    <Form.Select aria-label="Floating label select example"
    name="opportunity_shipment" 
    placeholder="Opportunity" 
    defaultValue=""
    {...register("opportunity_shipment", { required:true })}
    >
      <option value='' >Select</option>
       {
          opportunityName.map((x)=>{
              return(
                  <option key={x.opportunity_name}>{x.opportunity_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.opportunity_shipment && <span>Required Opportunity</span>}
  </FloatingLabel>
              </Form.Group> 
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Exchange Rate"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Exchange Rate" 
            name="exchange_rate"
            {...register("exchange_rate", { required: true })}
            />
             {errors.exchange_rate && <span>Required Exchange Rate</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Record Type"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Record Type" 
            name="record_type"
            {...register("record_type", { required: true })}
            />
             {errors.record_type && <span>Required Record Type</span>}
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Status">
    <Form.Select aria-label="Floating label select example"
    name="status"
    id='status' 
    placeholder="Status" 
    defaultValue=""
    {...register("status", { required:true })}
    >
    <option value=''>Select</option>
    <option value='pending'>Pending</option>
    <option value='approved'>Approved</option> 
    </Form.Select>
    {errors.status && <span>Required Status</span>}
  </FloatingLabel>
              </Form.Group> 
      
              <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Price Type">
    <Form.Select aria-label="Floating label select example"
    name="price_type" 
    placeholder="Select" 
    defaultValue=""
    {...register("price_type", { required:true })}
    >
      <option value='' >Select</option>
       {
          selected.map((x)=>{
              return(
                  <option key={x.pice_description}>{x.price_description}</option>
              )
          })
       }
    </Form.Select>
    {errors.price_type && <span>Required price type</span>}
  </FloatingLabel>
              </Form.Group> 
        
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Price"
            className="mb-3"
            
          >
            
            <Form.Control type="text" 
            placeholder="Price" 
            name="price"
            {...register("price", { valueAsNumber:true,required: true })}
            />
             {errors.price && <span>Required Price</span>}
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Price Basis">
    <Form.Select aria-label="Floating label select example"
    name="price_basis" 
    placeholder="Price Basis" 
    defaultValue=""
    {...register("price_basis", { required:true })}
    >
    <option value=''>Select</option>
    <option value='AED'>AED</option>
    <option value='INR'>INR</option>
    <option value='GBP'>GBP</option>
    </Form.Select>
    {errors.price_basis && <span>Required Price Basis</span>}
  </FloatingLabel>
              </Form.Group> 
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sales Price Margin"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Sales Price Margin" 
            name="sales_price_margin"
            {...register("sales_price_margin", {valueAsNumber:true, required: true })}
            />
             {errors.sales_price_margin && <span>Required Sales Price</span>}
          </FloatingLabel>
        </Form.Group>
        
        
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Shipping Term">
    <Form.Select aria-label="Floating label select example"
    name="shipping_term" 
    placeholder="Shipping Term" 
    defaultValue=""
    {...register("shipping_term", { required:true })}
    >
    <option value=''>Select</option>
    <option value='test1'>Test 1</option>
    <option value='test2'>Test 1</option> 
    </Form.Select>
    {errors.shipping_term && <span>Required Shipping Term</span>}
  </FloatingLabel>
              </Form.Group> 
              <Form.Group as={Col} md="6">
          
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan From Date"
            className="mb-3"
          >
            <Form.Control type="date" 
            placeholder="Laycan From Date" 
            name="laycan_from_date"
            {...register("laycan_from_date", { required: true })}
            />
             {errors.laycan_from_date && <
              span>Required laycan from date</span>}
          </FloatingLabel>
        </Form.Group>
         
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="laycan year">
    <Form.Select aria-label="Floating label select example"
    name="laycan_year" 
    placeholder="laycan year" 
    defaultValue=""
    {...register("laycan_year", { required:true })}
    >
    <option value=''>Select</option>
    <option value='2019'>2019</option>
    <option value='2020'>2020</option> 
    </Form.Select>
    {errors.laycan_year && <span>Required laycan year</span>}
  </FloatingLabel>
              </Form.Group>   
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan To Date"
            className="mb-3"
          >
            
            <Form.Control type="date" 
            placeholder="Laycan To Date" 
            name="laycan_to_date"
            {...register("laycan_to_date", { required: true })}
            />
             {errors.laycan_to_date && <span>Required laycan to date</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="laycan month">
    <Form.Select aria-label="Floating label select example"
    name="laycan_month" 
    placeholder="laycan Month" 
    defaultValue=""
    {...register("laycan_month", { required:true })}
    >
    <option value=''>Select</option>
    <option value='January'>January</option> 
    </Form.Select>
    {errors.laycan_month && <span>Required laycan Month</span>}
  </FloatingLabel>
              </Form.Group>   
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Quantity"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Quantity" 
            name="quantity"
            {...register("quantity", { required: true })}
            />
             {errors.quantity && <span>Required quantity</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Vessal Type">
    <Form.Select aria-label="Floating label select example"
    name="vessel_type" 
    placeholder="Vessal Type" 
    defaultValue="">
    <option>Select</option>
    <option>Geared (GnG)</option> 
    </Form.Select>
    
  </FloatingLabel>
              </Form.Group> 
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Shipment Quantity Tolerance"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Shipment Quantity Tolerance" 
            name="shipment_quality_tolerance"
            {...register("shipment_quality_tolerance", { required: true })}
            />
             {errors.shipment_quality_tolerance && <span>Required Shipment Quantity</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Remark"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="remark" 
            name="remark"
            {...register("remark", { required: true })}
            />
             {errors.remark && <span>Required remark</span>}
          </FloatingLabel>
        </Form.Group>
        <input type="submit" className='btn btn shipment-submit' value='Save'/>
        <p className='priceDiv'></p>
      </Row> 
    </Form>
    </div>
    <Footer/>
    </div>

  );
}

export default Shipment;
