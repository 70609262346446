import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import $ from "jquery";
import axios from "axios";
import { API_URL } from '../config';
import Footer from '../Footer'
import Header from '../Header' 
import { AiFillDiff } from "react-icons/ai";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
const BuyerList = () => {
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username ,token} = useContext(TokenContext);
    

  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height() 
  const total_height=header+nav+footer;
  const window_height=body-total_height;  
  $(".contactAccount").css({"min-height":(window_height)+"px"})
  const [datas, setDatas] = useState([]);
  
  useEffect(() => {
   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}get/all/buyer_product`, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const data = response.data;
      console.log(data)
      setDatas(data);
      $(document).ready(function(){
        // $('#tables').DataTable().destroy();
        // setTimeout(()=>{
          $('#tables').DataTable();
        // },500)
       })
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <div> 
    <Header/>
  <div className="contactAccount">
  
  <div className="page-header" id='add-account'>
  <div>
    <h2 className="main-content-title tx-24 mg-b-5">Buyer List </h2>
    <ol className="breadcrumb"> 
      <li className="breadcrumb-item active" aria-current="page">Dashboard / Buyer List</li>
    </ol>
  </div>
  <div className="d-flex">
    <div className="justify-content-center">
    
      <button type="button" className="btn btn-primary my-2 btn-icon-text">
       <Link to='/buyer/add-buyer-product'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Buyer</Link>
      </button>
      
    </div>
  </div> 
</div> 
<div class='tasks'>
<table id='tables'>
  <thead>
  <tr>
  <th>Order</th> 
  <th>Account Name</th> 
    <th>Buyer Product Type</th>  
    <th>Product</th>
    <th>Product Master</th> 
    <th>Actions</th>
  </tr>
  </thead>
  <tbody> 
    <React.Fragment>
    {datas.length > 0 ? (
datas.map((x) => (
  <tr>
  <td><Link to={"/buyer/buyer-view/"+x.id}>{x.order}</Link></td>
  <td>{x.buyer_product_type}</td> 
    <td>{x.buyer_product_type}</td>  
    <td>{x.supplier}</td>      
    <td>{x.product_master}</td> 
    <td><Link to=''><FaPencilAlt/> </Link><FaTrashAlt/><Link to=''><AiFillDiff/></Link></td>
  </tr>
))
) : (
<tr>
  <td colSpan="6">No data available</td>
</tr>
)}

    </React.Fragment>


  </tbody>
  </table>  
  </div> </div> 
  <Footer/>
  </div>
  );
};

export default BuyerList;