import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap"; 
import Header from '../Header' 
import Footer from '../Footer' 
import { API_URL } from '../config';
import { AES, enc } from 'crypto-js';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import $ from 'jquery' 
import DatePicker from "react-datepicker";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useLocation  } from "react-router-dom"; 
function  OppShipmentNew(props) { 
  const location = useLocation()
  const {id,record_type}=location.state;
  console.log(id+record_type)
        
  return (

    <div className="contactAccount" id='contacts'>
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add New Contact </h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Contact / Add New Contact</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div>   
  <p>Opp Shipment New</p>
    <Footer/>
    </div>
  );
}

export default OppShipmentNew;
