import React, { useState, useEffect } from "react";
import TreeNode from './TreeNode';
import { v4 as uuidv4 } from 'uuid';
import "./report.css";
import PreviewTable from "./PreviewTable";
import axios from "axios";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import modifiedTableKeysData from "./utils";

import { useDispatch, useSelector } from 'react-redux';
import { setJsonData, setFilteredData } from '../../store/FilterDataReducer';
 
import { colors } from "@mui/material";

const ReportGenerator = ({ tableKeys, api_payload }) => {

    const [selectedNodeId, setSelectedNodeId] = useState(null);
    const [tableHeaders, setTableHeaders] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [showCustomFilter, setShowCustomFilter] = useState(false);
    const [customFilterData, setCustomFilterData] = useState({});
    const [filterCondition, setFilterCondition] = useState([])
    const [sampleData , setSampleData] = useState([]);
    const [scope, setScope] = useState("")
    const [updatedFields, setupdatedFields] = useState({})

    const responseData = useSelector(state => state.filterData.responseData);

    const dispatch = useDispatch();
     
    const jsonData = useSelector(state => state.filterData);
 

    const dateFormatter = (dateStr) => {

        const [year, month, day] = dateStr.split('-').map(Number);
        const date = new Date(year, month - 1, day);  
        // Extract day, month, and year
        const dayFormatted = String(date.getDate()).padStart(2, '0');
        const monthFormatted = String(date.getMonth() + 1).padStart(2, '0');  
        const yearFormatted = date.getFullYear();

        // Format into "DD/MM/YYYY"
        return `${dayFormatted}/${monthFormatted}/${yearFormatted}`;

    }   

    const generateFilename = () => {

        // const uniqueId = uuidv4();  
        // return `reports_${uniqueId}.xlsx`;  


        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `report_${year}${month}${day}_${hours}${minutes}${seconds}.xlsx`;

    };

    useEffect(() => {
        console.log(responseData,'responseData123')
    },[responseData])
     
      

    const date_fields = ["created_at", "updated_at", "birthdate", "last_viewed_at"]
     
    //generate Report 

    const fecthFilteredResponseData = async () => {

        const URL = "https://rwooduatpy.znapay.in/get/keys"

        console.log(updatedFields,'updatedFieldsres')

        const response = await axios.post(
            URL, 
            updatedFields,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        )
        const filteredResponse = response.data
    
        if(filteredResponse){

             
            setSampleData(filteredResponse?.default_values)
            dispatch(setFilteredData(filteredResponse?.default_values))
        }

    }

     
     
    const transformData = modifiedTableKeysData(tableKeys);

     

    const filterOperators = [

        { id: "equals", label: "equals" },
        { id: "not_equals", label: "not equal" },
        {id:"greater_than", label: "greater than"},
        {id: "less_than", label: "less than"},
        { id: "greater_equal", label: "greater or equal" },
        { id: "less_equal", label: "lesser or equal" }

    ];


    
    //select json structure based on models
     
    const handleScope = (e) => {
         
        setScope(e.target.value)
    }


     
    const generateReport = async () => {

        const URL = `https://rwooduatpy.znapay.in/reports`


        if (responseData.length > 0){

            const data = {

                "filtered_data": responseData
            }
    
            console.log(data,'data123')
    
            const response = await axios.post(
    
                URL, 
                data,
                
                {
                    headers: {
                        'Content-Type': 'application/json',
                        
                    },
                    responseType: 'blob'
                }
            )
    
    
            const results = response.data
             
            if(results)

            {
    
                const file_name  = generateFilename()
    
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
    
                link.setAttribute('download', file_name);
                document.body.appendChild(link);
                link.click();
                link.remove();
    
            }

        }else{
            alert("No Report's Available !!")
        }

         

    }

    //table keys - default values


    const transformFieldType = (fieldType) => {
        return fieldType
            .replace(/_/g, ' ')            // Replace underscores with spaces
            .split(' ')                   // Split by spaces to capitalize each word
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');                   // Join words back with spaces
    };


    useEffect(() => {

        const defaultFields = tableKeys?.default_fields

        Object.keys(defaultFields).forEach(fieldType => {

            if (defaultFields[fieldType]) {

                const fieldValues = defaultFields[fieldType];

                const headers = fieldValues.reduce((acc, field) => {

                    acc[field] = transformFieldType(field);
                    return acc;

                }, {});

                 
               setTableHeaders(prevHeaders => ({

                        ...prevHeaders,
                        ...headers

                }));


                setSampleData(tableKeys?.default_values)
                dispatch(setFilteredData(tableKeys?.default_values))
                 
            }
        });

    },[tableKeys])


    // if modification happens in json structure renders components //

    useEffect(() => {

        const updated_condition = Object.keys(updatedFields);

        if(updated_condition.length > 0){

            fecthFilteredResponseData()

        }

    }, [updatedFields])

    

    useEffect(() => {


        jsonData && setupdatedFields(jsonData.filteredData)

        
    },[jsonData])

    
  useEffect(() => {

    if (filterCondition.length > 0) {

      // Create a copy of updatedFields to avoid mutating state directly
      let newFilteredFields = { ...updatedFields };

      // Define the key for filter conditions
      let field_type = "filterConditions";

      newFilteredFields["table_name"] = api_payload ?.table_name

      // Ensure the field type exists in the newFilteredFields object
      if (!newFilteredFields[field_type]) {

        newFilteredFields[field_type] = [];

      }

      // Use a set to track unique conditions temporarily
      let uniqueConditions = new Set(newFilteredFields[field_type].map(condition => JSON.stringify(condition)));

       
      console.log(uniqueConditions,'uniqueConditions')
      // Add new filter conditions to the set
      filterCondition.forEach(newCondition => {

        uniqueConditions.add(JSON.stringify(newCondition));

      });

      // Convert set back to array of objects
      let uniqueFilterConditions = Array.from(uniqueConditions).map(condition => JSON.parse(condition));

      // Update newFilteredFields with unique filter conditions
      newFilteredFields[field_type] = uniqueFilterConditions;

      // Dispatch the updated filtered fields to the Redux store
      dispatch(setJsonData(newFilteredFields));
    }
  }, [filterCondition, dispatch]);
    


   
    //handle drop of table key items
    const handleDrop = (transformData, draggableId) => {

        let field_type

        let newUpdatedFields = { ...updatedFields };

        transformData.forEach(group => {

            group.children.forEach(child => {

                if (child.id === draggableId) {

                    console.log(group, child.id, api_payload)

                    newUpdatedFields["table_name"] = api_payload ?.table_name

                    if (group.id === "accounts"){

                        field_type = `${group.id}_fields`

                    }

                    if(group.id === "contacts"){

                        field_type = `${group.id}_fields`
                    }

                    if (!newUpdatedFields[field_type]) {

                        newUpdatedFields[field_type] = [];
                    }
              
                       
                    if (!newUpdatedFields[field_type].includes(child.label)) {
                        
                    newUpdatedFields[field_type] = [

                        ...newUpdatedFields[field_type],
                        child.id

                    ];

                    }
                    
                    dispatch(setJsonData(newUpdatedFields));

                    setTableHeaders(prevHeaders => ({

                        ...prevHeaders,

                        [child.id]: child.label,

                    }));

                }  

            });

        });   

    };

    // trigger's on drag end
    const onDragEnd = (result) => {

        const { destination, draggableId } = result;

        if (!destination) {

            console.log("No destination, item was not dropped in a valid droppable area.");
            return;

        }

        if (destination.droppableId.includes("preview_table_drop_area")) {

            handleDrop(transformData, draggableId); 
             
        }

    };

    // handle search functionality
    const handleSearch = (event) => {

        setSearchQuery(event.target.value);

    };

    // toggle custom filter modal
    const toggleCustomFilter = () => {

        setShowCustomFilter(!showCustomFilter);

    };


    //render based on sampleData

    useEffect(() => {

    },[sampleData])

    // handle selection change in custom filter
    const handleCustomFilterChange = (event) => {

        let { id, value, type} = event.target;

         
        if (type == "date")
        {
            value = dateFormatter(value)
        }

        setCustomFilterData(prevState => ({

            ...prevState,
            [id]: value,

        }));

    };



    // close modal of filter
    const handleCustomFilterOK = () => {

        setFilterCondition(prevSampleData => [
            ...prevSampleData,
            {
                input: customFilterData.input,
                operator: customFilterData.operator,
                values: [customFilterData.values]
            }
        ]);

        toggleCustomFilter();

    };

    // Close modal without applying filter
    const handleCustomFilterCancel = () => {

        toggleCustomFilter();

    };

    const filteredData = transformData.filter((item) =>

        item.label.toLowerCase().includes(searchQuery.toLowerCase())

    );

    // Generate a unique droppableId for preview_table_drop_area
    const uniqueDropAreaId = `preview_table_drop_area_${Date.now()}`;

    return (
        <DragDropContext onDragEnd={onDragEnd}>

            <div className="container">

                <div className="sidebar">

                    <h3>Fields</h3>

                    <input type="text" placeholder="Quick Find" onChange={handleSearch} />

                    <Droppable droppableId="fields_list" type="FIELD">

                        {(provided) => (

                            <ul ref={provided.innerRef} {...provided.droppableProps}>

                                {filteredData.map((item, index) => (

                                    <TreeNode
                                        key={item.id}
                                        node={item}
                                        selectedNodeId={selectedNodeId}
                                        setSelectedNodeId={setSelectedNodeId}
                                        index={index}
                                    />

                                ))}

                                {provided.placeholder}

                            </ul>

                        )}

                    </Droppable>

                </div>

                <div className="main-content">

                    <div className="filters">

                        <div className="add_filter_fields">

                            <button onClick={toggleCustomFilter}>Add Fields</button>
                            <button onClick={generateReport}>Run Report</button>

                        </div>

                        <div className="show_fields">

                            <label htmlFor="show">Show</label>
                            <select id="show" onChange={handleScope}>
                                <option value="all">All</option>
                            </select>

                        </div>

                        <div className="main_filter_comp">

                            <label htmlFor="dateField">Date Field</label>
                            <select id="dateField">
                                <option>Created Date</option>
                            </select>

                            <label htmlFor="dateRange">Range</label>
                            <select id="dateRange">
                                <option>Custom</option>
                            </select>

                            <label htmlFor="dateFrom">From</label>
                            <input type="date" id="dateFrom" />
                            <label htmlFor="dateTo">To</label>
                            <input type="date" id="dateTo" />

                        </div>

                    </div>

                    <div className="show_custom_filter">
                        {showCustomFilter && (
                            <div className="custom-filter-modal">
                                <h3>Add Custom Filter</h3>

                                <div className="filter-options">

                                    <select id="input" onChange={handleCustomFilterChange} value={customFilterData.label}>
                                        <option value="">Select a field to add</option>
                                        {transformData.map(group => (
                                            group.children.map(child => (
                                                <option key={child.id} value={child.id}>{child.label}</option>
                                            ))
                                        ))}
                                    </select>

                                    <select id="operator" onChange={handleCustomFilterChange} value={customFilterData.selectedOption2}>
                                        <option value="">Select a Operator</option>
                                        {filterOperators.map(operator => (
                                            <option key={operator.id} value={operator.label}>{operator.label}</option>
                                        ))}
                                    </select>

                                    {console.log(customFilterData,'customFilterData')}

                                    {date_fields.includes(customFilterData.input) ? (
                                        <input id="values" onChange={handleCustomFilterChange} type="date" placeholder="value" />
                                    ) : (
                                        <input id="values" onChange={handleCustomFilterChange} type="text" placeholder="value" />
                                         
                                    )}
                                     

                                    <div className="modal-buttons">
                                        <button onClick={handleCustomFilterOK}>OK</button>
                                        <button onClick={handleCustomFilterCancel}>Cancel</button>
                                    </div>
                                </div>

                            </div>
                        )}

                    </div>
                    <div className="filter_conditions">
                        {filterCondition.map((item, index) => (
                            <p className="filter_condition_p" key={index}>{item?.input} - {item?.operator} - {item?.values[0]}</p>
                        ))}
                    </div>
                    <div className="preview">
                        <PreviewTable data={sampleData} headers={tableHeaders} />
                    </div>
                    <Droppable droppableId={uniqueDropAreaId} type="FIELD">
                        {(provided) => (
                            <div
                                className="preview_table_drop_area"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                <div>
                                    Drop fields here
                                </div>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </div>
        </DragDropContext>
    );
};

export default ReportGenerator;
