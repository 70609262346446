import React, { useState, useMemo, useEffect, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from '../config';
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AES, enc } from "crypto-js";
import $ from "jquery";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
function AddoppShippment() {
  const [opportunity,setOpportunity]=useState([])
  const location = useLocation();
  const params = useParams();
  const id = params.id;
  const [startDate1, setStartDate1] = useState(new Date());
  const datepick1 =
    startDate1.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate2, setStartDate2] = useState(new Date());
  const datepick2 =
    startDate2.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate3, setStartDate3] = useState(new Date());
  const datepick3 =
    startDate3.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput3 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate4, setStartDate4] = useState(new Date());
  const datepick4 =
    startDate4.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput4 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const navigate = useNavigate();

  // const body=$(window).height()
  // const header=$("#header").height()
  // const nav=$(".navbar").height()
  // const footer=$(".footer").height()
  // const total_height=header+nav+footer;
  // const window_height=body-total_height;
  // $(".priceAccount div:nth-child(3)").css({"min-height":(window_height)+"px"})
  const [datas, setDatas] = useState([]);
  const [getPrice, setgetPrice] = useState([]);
  const [noShipment, setNoshipment] = useState([]);
  const [products, setproducts] = useState([]);
  const [record, setRecord] = useState([]);
    useEffect(() => {
    fetchData1();
  }, [decryptedAccessToken]);

  const fetchData1 = async () => {
    try {
      const response = await axios.get(
        `${API_URL}all/opportunity/datas/byID/` + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data[0].opportunity_details.opportunity_record_type;
      setOpportunity(response.data[0].opportunity_details.opportunity_name)
      setNoshipment(response.data[0].opportunity_details)
      setRecord(data);
    } catch (error) {
      console.error(error);
    }
  };
console.log(noShipment)
  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/price_items`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.priceitem;
      console.log(data);
      setgetPrice(data);
    } catch (error) {
      console.error(error);
    }
  }; 
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  if (record === "Buyer") {
    const onSubmit = (data) => {
      console.log(JSON.stringify(data));
         const responseData={
          currency:data.currency,
exchange_rate:data.exchange_rate,
status:data.status,
opportunity_shipment:data.opportunity_shipment,
record_type:data.record_type,
price_type:data.price_type,
price_basis:data.price_basis,
price:data.price,
sales_price_margin:data.sales_price_margin,
shipping_term:data.shipping_term,
laycan_year_from:CustomInput1,
laycan_year_to:CustomInput2,
laycan_month_from:CustomInput3,
laycan_month_to:data.CustomInput4,
quantity:data.quantity,
shipment_quality_tolerance:data.shipment_quality_tolerance,
vessel_type:data.vessel_type,
remark:data.remark,
 
laycan_month:data.laycan_month,
laycan_year:data.laycan_year,
no_of_shipments:data.no_of_shipments
       };

      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}add/shipment/` + id, {
          method: "POST",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response)
            toast.success("Shipment added successfully", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            if (response.message == "Data added") {
              if (record === 'Buyer - Spot' || record === 'Supplier - Spot') {
              fetch(`${API_URL}add/quoteshipment/` + id, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${decryptedAccessToken}`,
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())

                .then((responses) => {
                  console.log(responses);
                  toast.success("Quote shipment Created successfully", {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  setTimeout(() => {
                    // $('.successDiv').hide();
                    reset();
                  }, 300);
                });

              }
              else
              {
                if(record === 'Buyer - Long Term' || record === 'Supplier - Long Term')
      {
        navigate("/opp-shipment/new", {
                        
          state: { id: id,record_type:record },

        });
      }
    }

            }

            //   console.log('Test'+JSON.stringify(response))
            //  $('.priceDiv').html("<p class='result'>"+response.MSG+"</p>");
            //  $('.priceDiv').hide(2000)
          });
      }
      
    };

    return (
      <div className="priceAccount m-b" id="prices">
        <Header />
        <div>
          <div className="page-header" id="">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Add Opp Shipment</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Add Opp Shipment
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
              </div>
            </div>
          </div>   <Form onSubmit={handleSubmit(onSubmit)} id="price">
            <Row className="mb-3">
              <h4 className="heading">Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    defaultValue=""
                    {...register("currency", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="USD">USD</option>
                    <option value="IDR">IDR</option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    value={record}
                    {...register("record_type", { required: false })}
                  />
                  {errors.record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange Rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Exchange Rate"
                    name="exchange_rate"
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                    defaultValue=""
                    {...register("status", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Opportunity"
                    name="opportunity_shipment"
                    defaultValue={opportunity}
                    {...register("opportunity_shipment", { required: false })}
                  />
                  {errors.opportunity_shipment && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping term"
                    defaultValue=""
                    {...register("shipping_term", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="FOB-B">FOB-B</option>
                    <option value="FOB-MV">FOB-MV</option>
                    <option value="CFR-MV">CFR-MV</option>
                    <option value="CIF-MV">CIF-MV</option>
                    <option value="FAS">FAS</option>
                  </Form.Select>
                  {errors.shipping_term && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price"
                    name="price"
                    {...register("price", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price basis"
                    defaultValue=""
                    {...register("price_basis", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="GCV(ARB)">GCV(ARB)</option>
                    <option value="GCV(ADB)">GCV(ADB)</option>
                    <option value="NCV(ARB)">NCV(ARB)</option>
                  </Form.Select>
                  {errors.price_basis && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Sales price margin"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Sales price margin"
                    name="sales_price_margin"
                    {...register("sales_price_margin", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.sales_price_margin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_type"
                    placeholder="Price type"
                    defaultValue=""
                    {...register("price_type", { required: true })}
                  >
                    <option value="">Select</option>
                    {getPrice.map((x) => {
                      return (
                        <option value={x.price_type_name}>
                          {x.price_type_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.price_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
             <Row className="mb-3">
              <h4 className="heading">Delivery</h4>
               <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan from date"
            className="mb-3 datepick"
            
            
          >
            <DatePicker
      selected={startDate1}
      onChange={(date) => setStartDate1(date)}
      customInput={<CustomInput1 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Laycan from date'
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year"
                    placeholder="Laycan Year"
                    defaultValue=""
                    {...register("laycan_year", { required: true })}
                  >
                    <option value="">--None--</option><option value="2021">2021</option><option value="2022">2022</option><option value="2023">2023</option><option value="2024">2024</option><option value="2025">2025</option><option value="2026">2026</option><option value="2015">2015</option><option value="2018">2018</option><option value="2019">2019</option><option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan month to date"
            className="mb-3 datepick"
            
            
          >
            <DatePicker
      selected={startDate4}
      onChange={(date) => setStartDate4(date)}
      customInput={<CustomInput4 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Laycan month to date'
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month"
                    placeholder="Laycan Month"
                    defaultValue=""
                    {...register("laycan_month", { required: false })}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               
              </Row>

            <Row className="mb-3">
              <h4 className="heading">Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Quantity Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quantity"
                    placeholder="Quantity Type"
                    defaultValue=""
                    {...register("quantity", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Buyers Option">Base Quality</option>
                    <option value="Buyers Option">Buyers Option</option>
                  </Form.Select>
                  {errors.quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Vessel Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="vessel_type"
                    placeholder="Vessel Type"
                    defaultValue=""
                    {...register("vessel_type", { required: false })}
                  >
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>
                  {errors.vessel_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of shipments"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of shipments"
                    name="no_of_shipments"
                    {...register("no_of_shipments", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_shipments && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment quality tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Shipment quality tolerance"
                    name="shipment_quality_tolerance"
                    {...register("shipment_quality_tolerance", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.shipment_quality_tolerance && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <input type="submit" className="price-submit" />
            <div className="priceDiv">
              <p></p><p></p>
            </div>
          </Form>
       
        </div>
        <Footer />
      </div>
    );
  }
  if (record === "Buyer - Long Term") {
    const onSubmit = (data) => {
      console.log(JSON.stringify(data));
          const responseData={
          currency:data.currency,
exchange_rate:data.exchange_rate,
status:data.status,
opportunity_shipment:data.opportunity_shipment,
record_type:data.record_type,
price_type:data.price_type,
price_basis:data.price_basis,
price:data.price,
sales_price_margin:data.sales_price_margin,
shipping_term:data.shipping_term,
laycan_year_from:data.laycan_year_from,
laycan_from_date:'2023-03-22',
laycan_to_date:'2023-03-22',
laycan_year_to:'2023-03-22',
laycan_month_from:data.laycan_month_from,
laycan_month_to:'2023-03-22',
quantity:data.quantity,
shipment_quality_tolerance:data.shipment_quality_tolerance,
vessel_type:data.vessel_type,
remark:'Good',
quantity_info:'test',
quantity_type:'test',
quantity_from:data.quantity_from,
quantity_to:data.quantity_to,
laycan:"test",
pricing:'test',
// laycan_month:data.laycan_month,
// laycan_year:data.laycan_year,
no_of_shipments:data.no_of_shipments
       };

      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}add/shipment/` + id, {
          method: "POST",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json", 
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response)
            

            if (response.message == "Data added") {
              
              fetch(`${API_URL}add/quoteshipment/` + id, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${decryptedAccessToken}`,
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())

                .then((responses) => {
                  console.log(responses);
                  toast.success("Quote shipment Created successfully", {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  setTimeout(() => {
                    // $('.successDiv').hide();
                    reset();
                  }, 300);
                });
    
}

            //   console.log('Test'+JSON.stringify(response))
            //  $('.priceDiv').html("<p class='result'>"+response.MSG+"</p>");
            //  $('.priceDiv').hide(2000)
          });
      }
    };

    return (
      <div className="priceAccount m-b" id="prices">
        <Header />
        <div>
          <div className="page-header" id="">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Add Shipment</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Add Shipment
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
              </div>
            </div>
          </div>

          <Form onSubmit={handleSubmit(onSubmit)} id="price">
            <Row className="mb-3">
              <h4 className="heading">Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    defaultValue=""
                    {...register("currency", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="USD">USD</option>
                    <option value="IDR">IDR</option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    className="record-type"
                    value={record}
                    {...register("record_type", { required: false })}
                  />
                  {errors.record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange Rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Exchange Rate"
                    name="exchange_rate"
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                    defaultValue=""
                    {...register("status", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Opportunity"
                    className="record-type"
                    name="opportunity_shipment"
                    defaultValue={opportunity}
                    {...register("opportunity_shipment", { required: false })}
                  />
                  {errors.opportunity_shipment && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping term"
                    defaultValue=""
                    {...register("shipping_term", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="FOB-B">FOB-B</option>
                    <option value="FOB-MV">FOB-MV</option>
                    <option value="CFR-MV">CFR-MV</option>
                    <option value="CIF-MV">CIF-MV</option>
                    <option value="FAS">FAS</option>
                  </Form.Select>
                  {errors.shipping_term && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price"
                    name="price"
                    {...register("price", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price basis"
                    defaultValue=""
                    {...register("price_basis", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="GCV(ARB)">GCV(ARB)</option>
                    <option value="GCV(ADB)">GCV(ADB)</option>
                    <option value="NCV(ARB)">NCV(ARB)</option>
                  </Form.Select>
                  {errors.price_basis && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Sales price margin"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Sales price margin"
                    name="sales_price_margin"
                    {...register("sales_price_margin", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.sales_price_margin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_type"
                    placeholder="Price type"
                    defaultValue=""
                    {...register("price_type", { required: true })}
                  >
                    <option value="">Select</option>
                    {getPrice.map((x) => {
                      return (
                        <option value={x.price_type_name}>
                          {x.price_type_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.price_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
             <Row className="mb-3">
              <h4 className="heading">Delivery</h4>
              
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month From"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month_from"
                    placeholder="Laycan Month From"
                    defaultValue=""
                    {...register("laycan_month_from", { required: false })}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month_from && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Year From"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year_from"
                    placeholder="Laycan Year From"
                    defaultValue=""
                    {...register("laycan_year_from", { required: true })}
                  >
                    <option value="">--None--</option><option value="2021">2021</option><option value="2022">2022</option><option value="2023">2023</option><option value="2024">2024</option><option value="2025">2025</option><option value="2026">2026</option><option value="2015">2015</option><option value="2018">2018</option><option value="2019">2019</option><option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year_from && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>

               <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan from date"
            className="mb-3 datepick"
            
            
          >
            <DatePicker
      selected={startDate1}
      onChange={(date) => setStartDate1(date)}
      customInput={<CustomInput1 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Laycan from date'
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan month to date"
            className="mb-3 datepick"
            
            
          >
            <DatePicker
      selected={startDate4}
      onChange={(date) => setStartDate4(date)}
      customInput={<CustomInput4 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Laycan month to date'
    />
          </FloatingLabel>
        </Form.Group>
               
              </Row>

            <Row className="mb-3">
              <h4 className="heading">Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Quantity Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quantity"
                    placeholder="Quantity Type"
                    defaultValue=""
                    {...register("quantity", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Buyers Option">Base Quality</option>
                    <option value="Buyers Option">Buyers Option</option>
                  </Form.Select>
                  {errors.quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Vessel Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="vessel_type"
                    placeholder="Vessel Type"
                    defaultValue=""
                    {...register("vessel_type", { required: false })}
                  >
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>
                  {errors.vessel_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of shipments"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of shipments"
                    name="no_of_shipments"
                    {...register("no_of_shipments", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_shipments && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment quality tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Shipment quality tolerance"
                    name="shipment_quality_tolerance"
                    {...register("shipment_quality_tolerance", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.shipment_quality_tolerance && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <input type="submit" className="price-submit" />
            <div className="priceDiv">
              <p></p><p></p>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
    );
  }
  if (record === "Buyer - Spot") {
    const onSubmit = (data) => {
      
         const responseData={
          currency:data.currency,
exchange_rate:data.exchange_rate,
status:data.status,
opportunity_shipment:data.opportunity_shipment,
record_type:data.record_type,
price_type:data.price_type,
price_basis:data.price_basis,
price:data.price,
sales_price_margin:data.sales_price_margin,
shipping_term:data.shipping_term,
laycan_year_from:'2023-03-22',
laycan_from_date:'2023-03-22',
laycan_to_date:'2023-03-22',
laycan_year_to:'2023-03-22',
laycan_month_from:'2023-03-22',
laycan_month_to:'2023-03-22',
quantity:22,
shipment_quality_tolerance:data.shipment_quality_tolerance,
vessel_type:data.vessel_type,
remark:'Good',
quantity_info:'test',
quantity_type:'test',
quantity_from:33,
quantity_to:33,
laycan:"test",
pricing:'test',
// laycan_month:data.laycan_month,
// laycan_year:data.laycan_year,
no_of_shipments:data.no_of_shipments
       };
       console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}add/shipment/` + id, {
          method: "POST",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response)
          

            if (response.message == "Data added") {
              if (record === 'Buyer - Spot' || record === 'Supplier - Spot') {
              fetch(`${API_URL}add/quoteshipment/` + id, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${decryptedAccessToken}`,
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())

                .then((responses) => {
                  console.log(responses);
                  toast.success("Quote shipment Created successfully", {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  setTimeout(() => {
                    // $('.successDiv').hide();
                    reset();
                  }, 300);
                });
            }
            else
            {
              if(record === 'Buyer - Long Term' || record === 'Supplier - Long Term')
    {
      navigate("/opp-shipment/new", {
                      
        state: { id: id,record_type:record },

      });
    }
  }
}



            //   console.log('Test'+JSON.stringify(response))
            //  $('.priceDiv').html("<p class='result'>"+response.MSG+"</p>");
            //  $('.priceDiv').hide(2000)
          });
      }
    };

    return (
      <div className="priceAccount m-b" id="prices">
        <Header />
        <div>
          <div className="page-header" id="">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Add Opportunity Shipment</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Add Opportunity Shipment
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
              </div>
            </div>
          </div>
           <Form onSubmit={handleSubmit(onSubmit)} id="price">
            <Row className="mb-3">
              <h4 className="heading">Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    defaultValue=""
                    {...register("currency", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="USD">USD</option>
                    <option value="IDR">IDR</option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    className="record-type"
                    value={record}
                    {...register("record_type", { required: false })}
                  />
                  {errors.record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange Rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Exchange Rate"
                    name="exchange_rate"
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                    defaultValue=""
                    {...register("status", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Opportunity"
                    name="opportunity_shipment"
                    defaultValue={opportunity}
                    {...register("opportunity_shipment", { required: false })}
                  />
                  {errors.opportunity_shipment && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping term"
                    defaultValue=""
                    {...register("shipping_term", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="FOB-B">FOB-B</option>
                    <option value="FOB-MV">FOB-MV</option>
                    <option value="CFR-MV">CFR-MV</option>
                    <option value="CIF-MV">CIF-MV</option>
                    <option value="FAS">FAS</option>
                  </Form.Select>
                  {errors.shipping_term && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price"
                    name="price"
                    {...register("price", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price basis"
                    defaultValue=""
                    {...register("price_basis", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="GCV(ARB)">GCV(ARB)</option>
                    <option value="GCV(ADB)">GCV(ADB)</option>
                    <option value="NCV(ARB)">NCV(ARB)</option>
                  </Form.Select>
                  {errors.price_basis && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Sales price margin"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Sales price margin"
                    name="sales_price_margin"
                    {...register("sales_price_margin", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.sales_price_margin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_type"
                    placeholder="Price type"
                    defaultValue=""
                    {...register("price_type", { required: true })}
                  >
                    <option value="">Select</option>
                    {getPrice.map((x) => {
                      return (
                        <option value={x.price_type_name}>
                          {x.price_type_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.price_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
             <Row className="mb-3">
              <h4 className="heading">Delivery</h4>
               <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan from date"
            className="mb-3 datepick"
            
            
          >
            <DatePicker
      selected={startDate1}
      onChange={(date) => setStartDate1(date)}
      customInput={<CustomInput1 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Laycan from date'
    />
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="laycan_year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year"
                    placeholder="Laycan Year"
                    defaultValue=""
                    {...register("laycan_year", { required: true })}
                  >
                    <option value="">--None--</option><option value="2021">2021</option><option value="2022">2022</option><option value="2023">2023</option><option value="2024">2024</option><option value="2025">2025</option><option value="2026">2026</option><option value="2015">2015</option><option value="2018">2018</option><option value="2019">2019</option><option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan month to date"
            className="mb-3 datepick"
            
            
          >
            <DatePicker
      selected={startDate4}
      onChange={(date) => setStartDate4(date)}
      customInput={<CustomInput4 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Laycan month to date'
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month"
                    placeholder="Laycan Month"
                    defaultValue=""
                    {...register("laycan_month", { required: false })}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               
              </Row>

            <Row className="mb-3">
              <h4 className="heading">Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Quantity Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quantity"
                    placeholder="Quantity Type"
                    defaultValue=""
                    {...register("quantity", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Buyers Option">Base Quality</option>
                    <option value="Buyers Option">Buyers Option</option>
                  </Form.Select>
                  {errors.quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Vessel Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="vessel_type"
                    placeholder="Vessel Type"
                    defaultValue=""
                    {...register("vessel_type", { required: false })}
                  >
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>
                  {errors.vessel_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of shipments"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of shipments"
                    name="no_of_shipments"
                    {...register("no_of_shipments", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_shipments && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment quality tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Shipment quality tolerance"
                    name="shipment_quality_tolerance"
                    {...register("shipment_quality_tolerance", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.shipment_quality_tolerance && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <input type="submit" className="price-submit" />
            <div className="priceDiv">
              <p></p><p></p>
            </div>
          </Form>
       
        </div>
        <Footer />
      </div>
    );
  }
  
  if (record === "Supplier") {
    const onSubmit = (data) => {
      console.log(JSON.stringify(data));
         const responseData={
          currency:data.currency,
exchange_rate:data.exchange_rate,
status:data.status,
opportunity_shipment:data.opportunity_shipment,
record_type:data.record_type,
price_type:data.price_type,
price_basis:data.price_basis,
price:data.price,
sales_price_margin:data.sales_price_margin,
shipping_term:data.shipping_term,
laycan_year_from:CustomInput1,
laycan_year_to:CustomInput2,
laycan_month_from:CustomInput3,
laycan_month_to:data.CustomInput4,
quantity:data.quantity,
shipment_quality_tolerance:data.shipment_quality_tolerance,
vessel_type:data.vessel_type,
remark:data.remark,
 
laycan_month:data.laycan_month,
laycan_year:data.laycan_year,
no_of_shipments:data.no_of_shipments
       };

      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}add/shipment/` + id, {
          method: "POST",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response)
            toast.success("Shipment added successfully", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            if (response.message == "Data added") {
              fetch(`${API_URL}add/quoteshipment/` + id, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${decryptedAccessToken}`,
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())

                .then((responses) => {
                  console.log(responses);
                  toast.success("Quote shipment Createed successfully", {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  setTimeout(() => {
                    // $('.successDiv').hide();
                    reset();
                  }, 300);
                });
            }

            //   console.log('Test'+JSON.stringify(response))
            //  $('.priceDiv').html("<p class='result'>"+response.MSG+"</p>");
            //  $('.priceDiv').hide(2000)
          });
      }
    };

    return (
      <div className="priceAccount m-b" id="prices">
        <Header />
        <div>
          <div className="page-header" id="">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Add Shipment</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Add Shipment
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)} id="price">
            <Row className="mb-3">
              <h4 className="heading">Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    defaultValue=""
                    {...register("currency", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="USD">USD</option>
                    <option value="IDR">IDR</option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    value={record}
                    {...register("record_type", { required: false })}
                  />
                  {errors.record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange Rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Exchange Rate"
                    name="exchange_rate"
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                    defaultValue=""
                    {...register("status", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Opportunity"
                    name="opportunity_shipment"
                    defaultValue={opportunity}
                    {...register("opportunity_shipment", { required: false })}
                  />
                  {errors.opportunity_shipment && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping term"
                    defaultValue=""
                    {...register("shipping_term", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="FOB-B">FOB-B</option>
                    <option value="FOB-MV">FOB-MV</option>
                    <option value="CFR-MV">CFR-MV</option>
                    <option value="CIF-MV">CIF-MV</option>
                    <option value="FAS">FAS</option>
                  </Form.Select>
                  {errors.shipping_term && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price"
                    name="price"
                    {...register("price", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price basis"
                    defaultValue=""
                    {...register("price_basis", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="GCV(ARB)">GCV(ARB)</option>
                    <option value="GCV(ADB)">GCV(ADB)</option>
                    <option value="NCV(ARB)">NCV(ARB)</option>
                  </Form.Select>
                  {errors.price_basis && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Sales price margin"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Sales price margin"
                    name="sales_price_margin"
                    {...register("sales_price_margin", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.sales_price_margin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_type"
                    placeholder="Price type"
                    defaultValue=""
                    {...register("price_type", { required: true })}
                  >
                    <option value="">Select</option>
                    {getPrice.map((x) => {
                      return (
                        <option value={x.price_type_name}>
                          {x.price_type_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.price_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
             <Row className="mb-3">
              <h4 className="heading">Delivery</h4>
               <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan from date"
            className="mb-3 datepick"
            
            
          >
            <DatePicker
      selected={startDate1}
      onChange={(date) => setStartDate1(date)}
      customInput={<CustomInput1 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Laycan from date'
    />
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="laycan_year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year"
                    placeholder="Laycan Year"
                    defaultValue=""
                    {...register("laycan_year", { required: true })}
                  >
                    <option value="">--None--</option><option value="2021">2021</option><option value="2022">2022</option><option value="2023">2023</option><option value="2024">2024</option><option value="2025">2025</option><option value="2026">2026</option><option value="2015">2015</option><option value="2018">2018</option><option value="2019">2019</option><option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan month to date"
            className="mb-3 datepick"
            
            
          >
            <DatePicker
      selected={startDate4}
      onChange={(date) => setStartDate4(date)}
      customInput={<CustomInput4 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Laycan month to date'
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month"
                    placeholder="Laycan Month"
                    defaultValue=""
                    {...register("laycan_month", { required: false })}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               
              </Row>

            <Row className="mb-3">
              <h4 className="heading">Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Quantity Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quantity"
                    placeholder="Quantity Type"
                    defaultValue=""
                    {...register("quantity", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Buyers Option">Base Quality</option>
                    <option value="Buyers Option">Buyers Option</option>
                  </Form.Select>
                  {errors.quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Vessel Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="vessel_type"
                    placeholder="Vessel Type"
                    defaultValue=""
                    {...register("vessel_type", { required: false })}
                  >
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>
                  {errors.vessel_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of shipments"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of shipments"
                    name="no_of_shipments"
                    {...register("no_of_shipments", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_shipments && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment quality tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Shipment quality tolerance"
                    name="shipment_quality_tolerance"
                    {...register("shipment_quality_tolerance", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.shipment_quality_tolerance && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <input type="submit" className="price-submit" />
            <div className="priceDiv">
              <p></p><p></p>
            </div>
          </Form>
       
        </div>
        <Footer />
      </div>
    );
  }
  
    if (record === "Supplier - Long Term") {
    const onSubmit = (data) => {
      console.log(JSON.stringify(data));
         const responseData={currency:data.currency,
exchange_rate:data.exchange_rate,
status:data.status,
opportunity_shipment:data.opportunity_shipment,
record_type:data.record_type,
price_type:data.price_type,
price_basis:data.price_basis,
price:data.price,
sales_price_margin:data.sales_price_margin,
shipping_term:data.shipping_term,
laycan_year_from:CustomInput1,
laycan_year_to:CustomInput2,
laycan_month_from:CustomInput3,
laycan_month_to:data.CustomInput4,
quantity:data.quantity,
shipment_quality_tolerance:data.shipment_quality_tolerance,
vessel_type:data.vessel_type,
remark:data.remark,
 
laycan_month:data.laycan_month,
laycan_year:data.laycan_year,
no_of_shipments:data.no_of_shipments
       };

      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}add/shipment/` + id, {
          method: "POST",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response)
            toast.success("Shipment added successfully", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            if (response.message == "Data added") {
              fetch(`${API_URL}add/quoteshipment/` + id, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${decryptedAccessToken}`,
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())

                .then((responses) => {
                  console.log(responses);
                  toast.success("Quote shipment Createed successfully", {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  setTimeout(() => {
                    // $('.successDiv').hide();
                    reset();
                  }, 300);
                });
            }

            //   console.log('Test'+JSON.stringify(response))
            //  $('.priceDiv').html("<p class='result'>"+response.MSG+"</p>");
            //  $('.priceDiv').hide(2000)
          });
      }
    };

    return (
      <div className="priceAccount m-b" id="prices">
        <Header />
        <div>
          <div className="page-header" id="">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Add Shipment</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Add Shipment
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
              </div>
            </div>
          </div>
            <Form onSubmit={handleSubmit(onSubmit)} id="price">
            <Row className="mb-3">
              <h4 className="heading">Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    defaultValue=""
                    {...register("currency", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="USD">USD</option>
                    <option value="IDR">IDR</option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    value={record}
                    {...register("record_type", { required: false })}
                  />
                  {errors.record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange Rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Exchange Rate"
                    name="exchange_rate"
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                    defaultValue=""
                    {...register("status", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Opportunity"
                    name="opportunity_shipment"
                    defaultValue={opportunity}
                    {...register("opportunity_shipment", { required: false })}
                  />
                  {errors.opportunity_shipment && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping term"
                    defaultValue=""
                    {...register("shipping_term", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="FOB-B">FOB-B</option>
                    <option value="FOB-MV">FOB-MV</option>
                    <option value="CFR-MV">CFR-MV</option>
                    <option value="CIF-MV">CIF-MV</option>
                    <option value="FAS">FAS</option>
                  </Form.Select>
                  {errors.shipping_term && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price"
                    name="price"
                    {...register("price", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price basis"
                    defaultValue=""
                    {...register("price_basis", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="GCV(ARB)">GCV(ARB)</option>
                    <option value="GCV(ADB)">GCV(ADB)</option>
                    <option value="NCV(ARB)">NCV(ARB)</option>
                  </Form.Select>
                  {errors.price_basis && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Sales price margin"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Sales price margin"
                    name="sales_price_margin"
                    {...register("sales_price_margin", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.sales_price_margin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_type"
                    placeholder="Price type"
                    defaultValue=""
                    {...register("price_type", { required: true })}
                  >
                    <option value="">Select</option>
                    {getPrice.map((x) => {
                      return (
                        <option value={x.price_type_name}>
                          {x.price_type_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.price_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
             <Row className="mb-3">
              <h4 className="heading">Delivery</h4>
               <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan from date"
            className="mb-3 datepick"
            
            
          >
            <DatePicker
      selected={startDate1}
      onChange={(date) => setStartDate1(date)}
      customInput={<CustomInput1 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Laycan from date'
    />
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="laycan_year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year"
                    placeholder="Laycan Year"
                    defaultValue=""
                    {...register("laycan_year", { required: true })}
                  >
                    <option value="">--None--</option><option value="2021">2021</option><option value="2022">2022</option><option value="2023">2023</option><option value="2024">2024</option><option value="2025">2025</option><option value="2026">2026</option><option value="2015">2015</option><option value="2018">2018</option><option value="2019">2019</option><option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan month to date"
            className="mb-3 datepick"
            
            
          >
            <DatePicker
      selected={startDate4}
      onChange={(date) => setStartDate4(date)}
      customInput={<CustomInput4 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Laycan month to date'
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month"
                    placeholder="Laycan Month"
                    defaultValue=""
                    {...register("laycan_month", { required: false })}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               
              </Row>

            <Row className="mb-3">
              <h4 className="heading">Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Quantity Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quantity"
                    placeholder="Quantity Type"
                    defaultValue=""
                    {...register("quantity", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Buyers Option">Base Quality</option>
                    <option value="Buyers Option">Buyers Option</option>
                  </Form.Select>
                  {errors.quantity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Vessel Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="vessel_type"
                    placeholder="Vessel Type"
                    defaultValue=""
                    {...register("vessel_type", { required: false })}
                  >
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>
                  {errors.vessel_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of shipments"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of shipments"
                    name="no_of_shipments"
                    {...register("no_of_shipments", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_shipments && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment quality tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Shipment quality tolerance"
                    name="shipment_quality_tolerance"
                    {...register("shipment_quality_tolerance", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.shipment_quality_tolerance && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <input type="submit" className="price-submit" />
            <div className="priceDiv">
              <p></p><p></p>
            </div>
          </Form>
       
        </div>
        <Footer />
      </div>
    );
  }
}

export default AddoppShippment;
