import React, { Component, useState, useEffect, useContext, forwardRef } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import DatePicker from "react-datepicker";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import { AES, enc } from 'crypto-js';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Header from "../Header";
import Footer from "../Footer";
import { API_URL } from '../config';
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import $ from "jquery";
import { useParams } from "react-router-dom";
const ViewQuote = () => {
  // const params = useParams();
  // const id = params.id;
  const {id} = useParams()
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [quotes, setQuotes] = useState({});
  const [quotesProduct, setQuotesProduct] = useState([]);
  const [product, setProduct] = useState([]);
  const [buyer, setBuyer] = useState([]);
  const [history, setHistory] = useState([]); 
  const [productCode, setProductCode] = useState("");
  const [productInfo, setProductInfo] = useState([]);
  const [productValues, setProductValues] = useState([]);


  
  const[contractShippment, setContractShippment]=useState([])
  useEffect(() => {   
    fetchContract();
  }, [decryptedAccessToken]);

  const fetchContract = async () => {
    try {
      const response = await axios.get(API_URL+'get/allcontract_shipments', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.priceitem;
      console.log(data)
      setContractShippment(data);
      
    } catch (error) {
      console.error(error);
    }
  };




  const [formData, setFormData] = useState({
    locked:"", 
quotes_status:"",
quotes_name:"", 
no_of_product:"",
no_of_quote_shipment:"", 
quote_shipment:"",
product_code:"",
product_name:"",
product_account:"",
product_supplier:"",
product_description:"",
product_family:"",
product_shipping_term:"",
product_owner:"",
product_active:"",
short_term_available:"",
product_price_basis:"",
product_price_type:"",
product_port_of_loading:"",
total_moisture_typical:"",
total_moisture_minimum:"",
total_moisture_maximum:"",
ash_typical_arb:"",
ash_minimum_arb:"",
ash_maximum_arb:"",
sulphur_typical_arb:"",
sulphur_minimum_arb:"",
sulphur_maximum_arb:"",
volatile_matter_typical_arb:"",
volatile_matter_minimum_arb:"",
volatile_matter_maximum_arb:"",
 
moisture_typical:"",
moisture_minimum:"",
ash_typical:"",
ash_minimum:"",
ash_maximum:"",
 
volatile_matter_typical:"",
volatile_matter_minimum:"",
volatile_matter_maximum:"",
fixed_carbon_by_difference:"",
fixed_carbon_typical:"",
fixed_carbon_minimum:"",
fixed_carbon_maximum:"",
 
gross_air_dried_typical:"",
gross_air_dried_min:"",
gross_air_dried_max:"",
gross_as_recieved_min:"",
gross_as_recieved_max:"",
net_as_recieved_min:"",
net_as_recieved_max:"",
 
hgi_typical:"",
hgi_min:"",
hgi_max:"",
 
carbon_typical:"",
carbon_min:"",
carbon_max:"",
 
hydrogen_typical:"",
hydrogen_min:"",
hydrogen_max:"",
 
nitrogen_typical:"",
nitrogen_min:"",
nitrogen_max:"",
 
sulphur_typical:"",
sulphur_min:"",
sulphur_max_ultimate_analysis:"",
oxygen_typical:"",
oxygen_min:"",
oxygen_max:"",
 
sulphur_typical_adb:"",
sulphur_min_adb:"",
product_sulphur_max:"",
 
initial_typical:"",
initial_minimum:"",
initial_maximum:"",
spherical_typical:"",
spherical_minimum:"",
spherical_maximum:"",
hemispherical_typical:"",
hemispherical_minimum:"",
hemispherical_maximum:"",
flow_typical:"",
flow_minimum:"",
flow_maximum:"",
 
oxidising_initial_typical:"",
oxidising_initial_minimum:"",
oxidising_initial_maximum:"",
oxidising_spherical_typical:"",
oxidising_spherical_minimum:"",
oxidising_spherical_maximum:"",
oxidising_hemispherical_typical:"",
oxidising_hemispherical_minimum:"",
oxidising_hemispherical_maximum:"",
oxidising_flow_typical:"",
oxidising_flow_minimum:"",
oxidising_flow_maximum:"",
 
SiO2_Typical:"",
SiO2_Minimum:"",
SiO2_Maximum:"",
Al2O3_Typical:"",
Al2O3_Minimum:"",
Al2O3_Maximum:"",
Fe2O3_Typical:"",
Fe2O3_Minimum:"",
Fe2O3_Maximum:"",
CaO_Typical:"",
CaO_Minimum:"",
CaO_Maximum:"",
MgO_Typical:"",
MgO_Minimum:"",
MgO_Maximum:"",
MnO_Typical:"",
MnO_Minimum:"",
MnO_Maximum:"",
TiO2_Typical:"",
TiO2_Minimum:"",
TiO2_Maximum:"",
Na2O_Typical:"",
Na2O_Minimum:"",
Na2O_Maximum:"",
K2O_Typical:"",
K2O_Minimum:"",
K2O_Maximum:"",
P2O5_Typical:"",
P2O5_Minimum:"",
P2O5_Maximum:"",
SO3_Typical:"",
SO3_Minimum:"",
SO3_Maximum:"",
te_arsenic_as_typical_in_air_dried_coal:"",
te_arsenic_as_minimum_in_air_dried_coal:"",
te_arsenic_as_maximum_in_air_dried_coal:"",
te_antimony_sb_typical_in_air_dried_coal:"",
te_antimony_sb_minimum_in_air_dried_coal:"",
te_antimony_sb_maximum_in_air_dried_coal:"",
te_beryllium_be_typical_in_air_dried_coal:"",
te_beryllium_be_minimum_in_air_dried_coal:"",
te_beryllium_be_maximum_in_air_dried_coal:"",
te_boron_b_typical_in_air_dried_coal:"",
te_boron_b_minimum_in_air_dried_coal:"",
te_boron_b_maximum_in_air_dried_coal:"",
te_cadmium_cd_typical_in_air_dried_coal:"",
te_cadmium_cd_minimum_in_air_dried_coal:"",
te_cadmium_cd_maximum_in_air_dried_coal:"",
te_chromium_cr_typical_in_air_dried_coal:"",
te_chromium_cr_minimum_in_air_dried_coal:"",
te_chromium_cr_maximum_in_air_dried_coal:"",
te_copper_cu_typical_in_air_dried_coal:"",
te_copper_cu_minimum_in_air_dried_coal:"",
te_copper_cu_maximum_in_air_dried_coal:"",
te_chlorine_cl_typical_in_air_dried_coal:"",
te_chlorine_cl_minimum_in_air_dried_coal:"",
te_chlorine_cl_maximum_in_air_dried_coal:"",
te_fluorine_f_typical_in_air_dried_coal:"",
te_fluorine_f_minimum_in_air_dried_coal:"",
te_fluorine_f_maximum_in_air_dried_coal:"",
te_mercury_hg_typical_in_air_dried_coal:"",
te_mercury_hg_minimum_in_air_dried_coal:"",
te_mercury_hg_maximum_in_air_dried_coal:"",
te_molybdenum_mo_typical_in_air_dried_coal:"",
te_molybdenum_mo_minimum_in_air_dried_coal:"",
te_molybdenum_mo_maximum_in_air_dried_coal:"",
te_lead_pb_typical_in_air_dried_coal:"",
te_lead_pb_minimum_in_air_dried_coal:"",
te_lead_pb_maximum_in_air_dried_coal:"",
te_phosphorus_p_typical_in_air_dried_coal:"",
te_phosphorus_p_minimum_in_air_dried_coal:"",
te_phosphorus_p_maximum_in_air_dried_coal:"",
te_selenium_se_typical_in_air_dried_coal:"",
te_selenium_se_minimum_in_air_dried_coal:"",
te_selenium_se_maximum_in_air_dried_coal:"",
te_vanadium_v_typical_in_air_dried_coal:"",
te_vanadium_v_minimum_in_air_dried_coal:"",
te_vanadium_v_maximum_in_air_dried_coal:"",
te_zinc_zn_typical_in_air_dried_coal:"",
te_zinc_zn_minimum_in_air_dried_coal:"",
te_zinc_zn_maximum_in_air_dried_coal:"",
 
te_arsenic_as_typical:"",
te_arsenic_as_minimum:"",
te_arsenic_as_maximum:"",
te_antimony_sb_typical:"",
te_antimony_sb_minimum:"",
te_antimony_sb_maximum:"",
te_boron_b_typical:"",
te_boron_b_minimum:"",
te_boron_b_maximum:"",
te_cadmium_cd_typical:"",
te_cadmium_cd_minimum:"",
te_cadmium_cd_maximum:"",
te_chromium_cr_typical:"",
te_chromium_cr_minimum:"",
te_chromium_cr_maximum:"",
te_copper_cu_typical:"",
te_copper_cu_minimum:"",
te_copper_cu_maximum:"",
te_chlorine_cl_typical:"",
te_chlorine_cl_minimum:"",
te_chlorine_cl_maximum:"",
te_fluorine_f_typical:"",
te_fluorine_f_minimum:"",
te_fluorine_f_maximum:"",
te_mercury_hg_typical:"",
te_mercury_hg_minimum:"",
te_mercury_hg_maximum:"",
te_molybdenum_mo_typical:"",
te_molybdenum_mo_minimum:"",
te_molybdenum_mo_maximum:"",
te_lead_pb_typical:"",
te_lead_pb_minimum:"",
te_lead_pb_maximum:"",
te_phosphorus_p_typical:"",
te_phosphorus_p_minimum:"",
te_phosphorus_p_maximum:"",
te_selenium_se_typical:"",
te_selenium_se_minimum:"",
te_selenium_se_maximum:"",
te_vanadium_v_typical:"",
laycan_from_date:"",
laycan_to_date:"",
te_vanadium_v_min:"",
te_vanadium_v_max:"",
te_zinc_zn_typical:"",
te_zinc_zn_min:"",
te_zinc_zn_max:"",
product_master:"",
size_0_to_50mm_typical:"",
size_0_to_50mm_min:"",
size_0_to_50mm_max:"",
above_0_to_50mm_typical:"",
above_0_to_50mm_min:"",
above_0_to_50mm_max:"",
under_2mm_typical:"",
under_2mm_min:"",
under_2mm_max:"",
size_above_2mm_typical:"",
size_above_2mm_min:"",
size_above_2mm_max:"",
quantity_MT:"",
product_status:"",
product_year:"",
shipping_term:"",
vessel_type:"",
testing_method:"",
AI2O3_Typical:"",
        Fe2O3_typical:"",
        CaO_Typical:"",
        P2O5_Typical:"",
        SO3_Typical:"",
        MgO_Typical:"",
        TiO2_Typical:"",
        Na2O_Typical:"",
        K2O_Typical:"",
        Mn3O4_Typical:"",
  });
  const getProductValues = async (productCode) => {
    try {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);
        const response = await axios.get(
          `${API_URL}all/product/datas/product_code/${productCode}`,
          {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },
          }
        );
console.log(response.data)
        setProductValues(response.data);

        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  
  const[contracts, setContracts]=useState([])
  useEffect(() => {

    fetchDataContract();
  }, [decryptedAccessToken]);

  const fetchDataContract = async () => {
    try {
      const response = await axios.get(API_URL+'get/quotesbyID/'+id, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;           
      setContracts(data.quote_details[0]);
      
       
    } catch (error) {
      console.error(error);
    }
  };

  const [productApend, setProductcode] = useState('');
  function changeStatus(e) {
    setProductcode(e.target.value);
  }

 const dropdownChange = e => {
    changeStatus(e)
    handleProductCodeChange(e)
    handleChange(e)
  } 
  const [products, setproducts] = useState([]);
 
  useEffect(() => {
    const fetchProductCodes = async () => {
      try {
        const encryptedAccessToken = localStorage.getItem("access_token");
        const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(
            encryptedAccessToken,
            secret_key
          ).toString(enc.Utf8);
          const response = await axios.get(
            API_URL+"get/products_api", 
            {
              headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
          );


         
          setproducts(response.data);
          
        }
      } catch (error) {
        console.log(error);
      }
    }; 
    fetchProductCodes();
     
  }, []);
  const [datasContract, setdatasContract]=useState([]);
  useEffect(() => {
   
    fetchContractList();
  }, [decryptedAccessToken]);

  const fetchContractList = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/contract', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.contract;
      console.log(data)
      setdatasContract(data);
      
    } catch (error) {
      console.error(error);
    }
  };


  const handleProductCodeChange = async (event) => {
    setProductCode(event.target.value);
    try {
      const productValues = await getProductValues(event.target.value);
      console.log(productValues);

      setFormData({
        ...formData,
        product_code: event.target.value,
        locked:productValues.locked, 
        quote_shipment:productValues.quote_shipment,
product_master:productValues.product_code,
product_code:productValues.product_code,
product_name:productValues.product_name,
product_account:productValues.product_account,
supplier:productValues.supplier,
product_description:productValues.product_description,
product_family:productValues.product_family,
product_shipping_term:productValues.product_shipping_term,
product_owner:productValues.product_owner,
product_active:productValues.product_active,
short_term_available:productValues.short_term_available,
product_price_basis:productValues.product_price_basis,
product_price_type:productValues.product_price_type,
product_port_of_loading:productValues.product_port_of_loading,
total_moisture_typical:productValues.total_moisture_typical,
total_moisture_minimum:productValues.total_moisture_minimum,
total_moisture_maximum:productValues.total_moisture_maximum,
ash_typical_arb:productValues.ash_typical_arb,
ash_minimum_arb:productValues.ash_minimum_arb,
ash_maximum_arb:productValues.ash_maximum_arb,
sulphur_typical_arb:productValues.sulphur_typical_arb,
sulphur_minimum_arb:productValues.sulphur_minimum_arb,
sulphur_maximum_arb:productValues.sulphur_maximum_arb,
volatile_matter_typical_arb:productValues.volatile_matter_typical_arb,
volatile_matter_minimum_arb:productValues.volatile_matter_minimum_arb,
volatile_matter_maximum_arb:productValues.volatile_matter_maximum_arb,
 
moisture_typical:productValues.moisture_typical,
moisture_minimum:productValues.moisture_minimum,
ash_typical:productValues.ash_typical,
ash_minimum:productValues.ash_minimum,
ash_maximum:productValues.ash_maximum,
 
volatile_matter_typical:productValues.volatile_matter_typical,
volatile_matter_minimum:productValues.volatile_matter_minimum,
volatile_matter_maximum:productValues.volatile_matter_maximum,
fixed_carbon_by_difference:productValues.fixed_carbon_by_difference,
fixed_carbon_typical:productValues.fixed_carbon_typical,
fixed_carbon_minimum:productValues.fixed_carbon_minimum,
fixed_carbon_maximum:productValues.fixed_carbon_maximum,
quantity_MT:productValues.quantity_MT,
gross_air_dried_typical:productValues.gross_air_dried_typical,
gross_air_dried_min:productValues.gross_air_dried_min,
gross_air_dried_max:productValues.gross_air_dried_max,
gross_as_recieved_min:productValues.gross_as_recieved_min,
gross_as_recieved_max:productValues.gross_as_recieved_max,
net_as_recieved_min:productValues.net_as_recieved_min,
net_as_recieved_max:productValues.net_as_recieved_max,
 
hgi_typical:productValues.hgi_typical,
hgi_min:productValues.hgi_min,
hgi_max:productValues.hgi_max,
 
carbon_typical:productValues.carbon_typical,
carbon_min:productValues.carbon_min,
carbon_max:productValues.carbon_max,
 
hydrogen_typical:productValues.hydrogen_typical,
hydrogen_min:productValues.hydrogen_min,
hydrogen_max:productValues.hydrogen_max,
 
nitrogen_typical:productValues.nitrogen_typical,
nitrogen_min:productValues.nitrogen_min,
nitrogen_max:productValues.nitrogen_max,
 
sulphur_typical:productValues.sulphur_typical,
sulphur_min:productValues.sulphur_min,
sulphur_max_ultimate_analysis:productValues.sulphur_max_ultimate_analysis,
oxygen_typical:productValues.oxygen_typical,
oxygen_min:productValues.oxygen_min,
oxygen_max:productValues.oxygen_max,
 
sulphur_typical_adb:productValues.sulphur_typical_adb,
sulphur_min_adb:productValues.sulphur_min_adb,
product_sulphur_max:productValues.product_sulphur_max,
 
initial_typical:productValues.initial_typical,
initial_minimum:productValues.initial_minimum,
initial_maximum:productValues.initial_maximum,
spherical_typical:productValues.spherical_typical,
spherical_minimum:productValues.spherical_minimum,
spherical_maximum:productValues.spherical_maximum,
hemispherical_typical:productValues.hemispherical_typical,
hemispherical_minimum:productValues.hemispherical_minimum,
hemispherical_maximum:productValues.hemispherical_maximum,
flow_typical:productValues.flow_typical,
flow_minimum:productValues.flow_minimum,
flow_maximum:productValues.flow_maximum,
 
oxidising_initial_typical:productValues.oxidising_initial_typical,
oxidising_initial_minimum:productValues.oxidising_initial_minimum,
oxidising_initial_maximum:productValues.oxidising_initial_maximum,
oxidising_spherical_typical:productValues.oxidising_spherical_typical,
oxidising_spherical_minimum:productValues.oxidising_spherical_minimum,
oxidising_spherical_maximum:productValues.oxidising_spherical_maximum,
oxidising_hemispherical_typical:productValues.oxidising_hemispherical_typical,
oxidising_hemispherical_minimum:productValues.oxidising_hemispherical_minimum,
oxidising_hemispherical_maximum:productValues.oxidising_hemispherical_maximum,
oxidising_flow_typical:productValues.oxidising_flow_typical,
oxidising_flow_minimum:productValues.oxidising_flow_typical,
oxidising_flow_maximum:productValues.oxidising_flow_maximum,
 
SiO2_Typical:productValues.SiO2_Typical,
SiO2_Minimum:productValues.SiO2_Minimum,
SiO2_Maximum:productValues.SiO2_Maximum,
Al2O3_Typical:productValues.Al2O3_Typical,
Al2O3_Minimum:productValues.Al2O3_Minimum,
Al2O3_Maximum:productValues.Al2O3_Maximum,
Fe2O3_Typical:productValues.Fe2O3_Typical,
Fe2O3_Minimum:productValues.Fe2O3_Minimum,
Fe2O3_Maximum:productValues.Fe2O3_Maximum,
CaO_Typical:productValues.CaO_Typical,
CaO_Minimum:productValues.CaO_Minimum,
CaO_Maximum:productValues.CaO_Maximum,
MgO_Typical:productValues.MgO_Typical,
MgO_Minimum:productValues.MgO_Minimum,
MgO_Maximum:productValues.MgO_Maximum,
MnO_Typical:productValues.MnO_Typical,
MnO_Minimum:productValues.MnO_Minimum,
MnO_Maximum:productValues.MnO_Maximum,
TiO2_Typical:productValues.TiO2_Typical,
TiO2_Minimum:productValues.TiO2_Minimum,
TiO2_Maximum:productValues.TiO2_Maximum,
Na2O_Typical:productValues.Na2O_Typical,
Na2O_Minimum:productValues.Na2O_Minimum,
Na2O_Maximum:productValues.Na2O_Maximum,
K2O_Typical:productValues.K2O_Typical,
K2O_Minimum:productValues.K2O_Minimum,
K2O_Maximum:productValues.K2O_Maximum,
P2O5_Typical:productValues.P2O5_Typical,
P2O5_Minimum:productValues.P2O5_Minimum,
P2O5_Maximum:productValues.P2O5_Maximum,
SO3_Typical:productValues.SO3_Typical,
SO3_Minimum:productValues.SO3_Minimum,
SO3_Maximum:productValues.SO3_Maximum,
te_arsenic_as_typical_in_air_dried_coal:productValues.te_arsenic_as_typical_in_air_dried_coal,
te_arsenic_as_minimum_in_air_dried_coal:productValues.te_arsenic_as_minimum_in_air_dried_coal,
te_arsenic_as_maximum_in_air_dried_coal:productValues.te_arsenic_as_maximum_in_air_dried_coal,
te_antimony_sb_typical_in_air_dried_coal:productValues.te_antimony_sb_typical_in_air_dried_coal,
te_antimony_sb_minimum_in_air_dried_coal:productValues.te_antimony_sb_minimum_in_air_dried_coal,
te_antimony_sb_maximum_in_air_dried_coal:productValues.te_antimony_sb_maximum_in_air_dried_coal,
te_beryllium_be_typical_in_air_dried_coal:productValues.te_beryllium_be_typical_in_air_dried_coal,
te_beryllium_be_minimum_in_air_dried_coal:productValues.te_beryllium_be_minimum_in_air_dried_coal,
te_beryllium_be_maximum_in_air_dried_coal:productValues.te_beryllium_be_maximum_in_air_dried_coal,
te_boron_b_typical_in_air_dried_coal:productValues.te_boron_b_typical_in_air_dried_coal,
te_boron_b_minimum_in_air_dried_coal:productValues.te_boron_b_minimum_in_air_dried_coal,
te_boron_b_maximum_in_air_dried_coal:productValues.te_boron_b_maximum_in_air_dried_coal,
te_cadmium_cd_typical_in_air_dried_coal:productValues.te_cadmium_cd_typical_in_air_dried_coal,
te_cadmium_cd_minimum_in_air_dried_coal:productValues.te_cadmium_cd_minimum_in_air_dried_coal,
te_cadmium_cd_maximum_in_air_dried_coal:productValues.te_cadmium_cd_maximum_in_air_dried_coal,
te_chromium_cr_typical_in_air_dried_coal:productValues.te_chromium_cr_typical_in_air_dried_coal,
te_chromium_cr_minimum_in_air_dried_coal:productValues.te_chromium_cr_minimum_in_air_dried_coal,
te_chromium_cr_maximum_in_air_dried_coal:productValues.te_chromium_cr_maximum_in_air_dried_coal,
te_copper_cu_typical_in_air_dried_coal:productValues.te_copper_cu_typical_in_air_dried_coal,
te_copper_cu_minimum_in_air_dried_coal:productValues.te_copper_cu_minimum_in_air_dried_coal,
te_copper_cu_maximum_in_air_dried_coal:productValues.te_copper_cu_maximum_in_air_dried_coal,
te_chlorine_cl_typical_in_air_dried_coal:productValues.te_chlorine_cl_typical_in_air_dried_coal,
te_chlorine_cl_minimum_in_air_dried_coal:productValues.te_chlorine_cl_minimum_in_air_dried_coal,
te_chlorine_cl_maximum_in_air_dried_coal:productValues.te_chlorine_cl_maximum_in_air_dried_coal,
te_fluorine_f_typical_in_air_dried_coal:productValues.te_fluorine_f_typical_in_air_dried_coal,
te_fluorine_f_minimum_in_air_dried_coal:productValues.te_fluorine_f_minimum_in_air_dried_coal,
te_fluorine_f_maximum_in_air_dried_coal:productValues.te_fluorine_f_maximum_in_air_dried_coal,
te_mercury_hg_typical_in_air_dried_coal:productValues.te_mercury_hg_typical_in_air_dried_coal,
te_mercury_hg_minimum_in_air_dried_coal:productValues.te_mercury_hg_minimum_in_air_dried_coal,
te_mercury_hg_maximum_in_air_dried_coal:productValues.te_mercury_hg_maximum_in_air_dried_coal,
te_molybdenum_mo_typical_in_air_dried_coal:productValues.te_molybdenum_mo_typical_in_air_dried_coal,
te_molybdenum_mo_minimum_in_air_dried_coal:productValues.te_molybdenum_mo_minimum_in_air_dried_coal,
te_molybdenum_mo_maximum_in_air_dried_coal:productValues.te_molybdenum_mo_maximum_in_air_dried_coal,
te_lead_pb_typical_in_air_dried_coal:productValues.te_lead_pb_typical_in_air_dried_coal,
te_lead_pb_minimum_in_air_dried_coal:productValues.te_lead_pb_minimum_in_air_dried_coal,
te_lead_pb_maximum_in_air_dried_coal:productValues.te_lead_pb_maximum_in_air_dried_coal,
te_phosphorus_p_typical_in_air_dried_coal:productValues.te_phosphorus_p_typical_in_air_dried_coal,
te_phosphorus_p_minimum_in_air_dried_coal:productValues.te_phosphorus_p_minimum_in_air_dried_coal,
te_phosphorus_p_maximum_in_air_dried_coal:productValues.te_phosphorus_p_maximum_in_air_dried_coal,
te_selenium_se_typical_in_air_dried_coal:productValues.te_selenium_se_typical_in_air_dried_coal,
te_selenium_se_minimum_in_air_dried_coal:productValues.te_selenium_se_minimum_in_air_dried_coal,
te_selenium_se_maximum_in_air_dried_coal:productValues.te_selenium_se_maximum_in_air_dried_coal,
te_vanadium_v_typical_in_air_dried_coal:productValues.te_vanadium_v_typical_in_air_dried_coal,
te_vanadium_v_minimum_in_air_dried_coal:productValues.te_vanadium_v_minimum_in_air_dried_coal,
te_vanadium_v_maximum_in_air_dried_coal:productValues.te_vanadium_v_maximum_in_air_dried_coal,
te_zinc_zn_typical_in_air_dried_coal:productValues.te_zinc_zn_typical_in_air_dried_coal,
te_zinc_zn_minimum_in_air_dried_coal:productValues.te_zinc_zn_minimum_in_air_dried_coal,
te_zinc_zn_maximum_in_air_dried_coal:productValues.te_zinc_zn_maximum_in_air_dried_coal,
 
te_arsenic_as_typical:productValues.te_arsenic_as_typical,
te_arsenic_as_minimum:productValues.te_arsenic_as_minimum,
te_arsenic_as_maximum:productValues.te_arsenic_as_maximum,
te_antimony_sb_typical:productValues.te_antimony_sb_typical,
te_antimony_sb_minimum:productValues.te_antimony_sb_minimum,
te_antimony_sb_maximum:productValues.te_antimony_sb_maximum,
te_boron_b_typical:productValues.te_boron_b_typical,
te_boron_b_minimum:productValues.te_boron_b_minimum,
te_boron_b_maximum:productValues.te_boron_b_maximum,
te_cadmium_cd_typical:productValues.te_cadmium_cd_typical,
te_cadmium_cd_minimum:productValues.te_cadmium_cd_minimum,
te_cadmium_cd_maximum:productValues.te_cadmium_cd_maximum,
te_chromium_cr_typical:productValues.te_chromium_cr_typical,
te_chromium_cr_minimum:productValues.te_chromium_cr_minimum,
te_chromium_cr_maximum:productValues.te_chromium_cr_maximum,
te_copper_cu_typical:productValues.te_copper_cu_typical,
te_copper_cu_minimum:productValues.te_copper_cu_minimum,
te_copper_cu_maximum:productValues.te_copper_cu_maximum,
te_chlorine_cl_typical:productValues.te_chlorine_cl_typical,
te_chlorine_cl_minimum:productValues.te_chlorine_cl_minimum,
te_chlorine_cl_maximum:productValues.te_chlorine_cl_maximum,
te_fluorine_f_typical:productValues.te_fluorine_f_typical,
te_fluorine_f_minimum:productValues.te_fluorine_f_minimum,
te_fluorine_f_maximum:productValues.te_fluorine_f_maximum,
te_mercury_hg_typical:productValues.te_mercury_hg_typical,
te_mercury_hg_minimum:productValues.te_mercury_hg_minimum,
te_mercury_hg_maximum:productValues.te_mercury_hg_maximum,
te_molybdenum_mo_typical:productValues.te_molybdenum_mo_typical,
te_molybdenum_mo_minimum:productValues.te_molybdenum_mo_minimum,
te_molybdenum_mo_maximum:productValues.te_molybdenum_mo_maximum,
te_lead_pb_typical:productValues.te_lead_pb_typical,
te_lead_pb_minimum:productValues.te_lead_pb_minimum,
te_lead_pb_maximum:productValues.te_lead_pb_maximum,
te_phosphorus_p_typical:productValues.te_phosphorus_p_typical,
te_phosphorus_p_minimum:productValues.te_phosphorus_p_minimum,
te_phosphorus_p_maximum:productValues.te_phosphorus_p_maximum,
te_selenium_se_typical:productValues.te_selenium_se_typical,
te_selenium_se_minimum:productValues.te_selenium_se_minimum,
te_selenium_se_maximum:productValues.te_selenium_se_maximum,
te_vanadium_v_typical:productValues.te_vanadium_v_typical,
te_vanadium_v_min:productValues.te_vanadium_v_min,
te_vanadium_v_max:productValues.te_vanadium_v_max,
te_zinc_zn_typical:productValues.te_zinc_zn_typical,
te_zinc_zn_min:productValues.te_zinc_zn_min,
te_zinc_zn_max:productValues.te_zinc_zn_max,
 
size_0_to_50mm_typical:productValues.size_0_to_50mm_typical,
size_0_to_50mm_min:productValues.size_0_to_50mm_min,
size_0_to_50mm_max:productValues.size_0_to_50mm_max,
above_0_to_50mm_typical:productValues.above_0_to_50mm_typical,
above_0_to_50mm_min:productValues.above_0_to_50mm_min,
above_0_to_50mm_max:productValues.above_0_to_50mm_max,
under_2mm_typical:productValues.under_2mm_typical,
under_2mm_min:productValues.under_2mm_typical,
under_2mm_max:productValues.under_2mm_max,
size_above_2mm_typical:productValues.size_above_2mm_typical,
size_above_2mm_min:productValues.size_above_2mm_min,
size_above_2mm_max:productValues.size_above_2mm_max,
product_status:productValues.product_status,
product_year:productValues.product_year,
shipping_term:productValues.shipping_term,
vessel_type:productValues.vessel_type,
laycan_from_date:start_date,
laycan_to_date:start_date2,
buyer_name:productValues.buyer_name,
testing_method:productValues.testing_method,
AI2O3_typical:productValues.AI2O3_typical,
        Fe2O3_typical:productValues.Fe2O3_typical,
        CaO_Typical:productValues.CaO_Typical,
        P2O5_Typical:productValues.P2O5_Typical,
        SO3_Typical:productValues.SO3_Typical,
        MgO_Typical:productValues.MgO_Typical,
        TiO2_Typical:productValues.TiO2_Typical,
        Na2O_Typical:productValues.Na2O_Typical,
        K2O_Typical:productValues.K2O_Typical,
        Mn3O4_Typical:productValues.Mn3O4_Typical, 
        ...productValues,
      });
    } catch (error) {
      console.log(error);
    }
  };





  const [shippment, setShippment]=useState([]);
  useEffect(() => {
   
    quotes_names();
  }, [decryptedAccessToken]);

   
  const quotes_names = async () => {
    try {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);
        const response = await axios.get(
          `${API_URL}get/quoteshipment_by_offer/${id}`,
          {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },
          }
        );
// alert(JSON.stringify(response))
setShippment(response.data.offer_quote_shipment);
console.log("***",response)
      }
    } catch (error) {
      console.log(error);
    }
  };




  useEffect(() => {
   
    fetchData1();
  }, [decryptedAccessToken]);

  const fetchData1 = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/buyer_product', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const data = response.data;
      // console.log(data)
      // setDatas(data);
      $(document).ready(function(){
        // $('#tables').DataTable().destroy();
        // setTimeout(()=>{
          $('#tables').DataTable();
        // },500)
       })
    } catch (error) {
      console.error(error);
    }
  };
  
  const [productAccount, setProductaccount] = useState([]); 
  const [getPrice, setgetPrice] = useState([]);
    // product account
    useEffect(() => {
      fetchDataAccount();
    }, [decryptedAccessToken]);
  
    const fetchDataAccount = async () => {
      try {
        const response = await axios.get(
          API_URL+"all/accounts",
          {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },
          }
        );
        const data = response.data.accounts; 
        // console.log(data)
        setProductaccount(data);
      } catch (error) {
        console.error(error);
      }
    };
  // price
  useEffect(() => {
    fetchDataPrice();
  }, [decryptedAccessToken]);

  const fetchDataPrice = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/price_items",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.priceitem;
      // console.log(data);
      setgetPrice(data);
    } catch (error) {
      console.error(error);
    }
  }; 
  const [data, setData] = useState({});  
  const [data1, setData1] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [surveyors, setSurveyor] = useState([]);  
console.log(JSON.stringify(data1))
  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken, id]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/quotesbyID/"+id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      console.log(data)
      setData1(data.quote_details[0]);
      setQuotes(data.quote_details[0]);
      setHistory(data.history)
      $("#history").dataTable();  
    // }, 50);
      console.log   (data);
    } catch (error) {
      console.error(error);
    }
  };

   
  const [startDate1, setStartDate1] = useState(new Date());
  
  const start_date=startDate1.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
  
 const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
   <input
     defaultValue={value}
     className="custom-input"
     onClick={onClick}
     name="birthdate"
     onChange={onChange}
     ref={ref}
   ></input>
 ));
 

 const [startDate2, setStartDate2] = useState(new Date());
 
  const start_date2=startDate1.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
   
 const CustomInput2 = forwardRef(({ value, onClick, onChange }, ref) => (
   <input
     defaultValue={value}
     className="custom-input"
     onClick={onClick}
     name="birthdate"
     onChange={onChange}
     ref={ref}
   ></input>
 ));
 

const handleEditClick = () => {
  setIsEditMode(true);
  setEditedData(data);
};

const handleChange = (e) => {
  const { name, value } = e.target;
  setData1((prevState) => ({
    ...prevState,
    [name]: value,
  }));
};






const [lockeds, setLockeds] = useState(null); 
const [defferece, setDefferece] = useState(null);
const check_box = data1.locked;
const check_active = data1.fixed_carbon_by_difference;
console.log(check_box)
useEffect(() => {
  setLockeds(check_box);  
  setDefferece(check_active)
}, [check_box, check_active]); // Run this effect whenever tt changes

useEffect(() => {
  console.log("Value of lockeds:", lockeds); // Log lockeds
}, [lockeds, defferece]); // Run

const handleCheckboxChange = (e) => {
  setLockeds(e.target.checked);
};
const handleCheckboxActive = (e) => {
  setDefferece(e.target.checked);
};
const [isButtonEnabled, setIsButtonEnabled] = useState(false);
useEffect(() => {
  setIsButtonEnabled(true);
}, []);


const handleCancelEdit = () => {
  $("#history").dataTable(); 
  setIsEditMode(false);
  setEditedData(data);
};
  const handleSaveEdit = (e) => {
    $("#history").dataTable(); 
    e.preventDefault();
    const responseData = {
      "product_code": e.target.value,
"locked":lockeds, 
"quote_shipment":data1.quote_shipment,
"product_master":data1.product_code,
"product_code":data1.product_code,
"product_name":data1.product_name,
"product_account":data1.product_account,
"supplier":data1.supplier,
"ash_typical_arb":parseFloat(data1.ash_typical_arb),  
"sulphur_rejection_arb":parseFloat(data1.sulphur_rejection_arb),
"total_moisture_rejection":parseFloat(data1.total_moisture_rejection),
"inherent_moisture_rejection":parseFloat(data1.inherent_moisture_rejection),
"product_description":data1.product_description,
"product_family":data1.product_family,
"product_shipping_term":data1.product_shipping_term,
"product_owner":data1.product_owner,
"product_active":data1.product_active,
"short_term_available":data1.short_term_available,
"product_price_basis":data1.product_price_basis,
"product_price_type":data1.product_price_type,
"product_port_of_loading":data1.product_port_of_loading,
"product_status":data1.product_status,
"product_year":data1.product_year,
"shipping_term":data1.shipping_term,
"vessel_type":data1.vessel_type,
"laycan_from_date":start_date,
"laycan_to_date":start_date2,
"SO3_rejection":parseFloat(data1.SO3_rejection),
"SO3_Typical":parseFloat(data1.SO3_Typical),
"AI2O3_rejection":parseFloat(data1.AI2O3_rejection),
"total_moisture_typical":parseFloat(data1.total_moisture_typical),
"total_moisture_rejection":parseFloat(data1.total_moisture_rejection),
"ash_typical_arb":parseFloat(data1.ash_typical_arb),
"ash_rejection_arb":parseFloat(data1.ash_rejection_arb),
"sulphur_typical_arb":parseFloat(data1.sulphur_typical_arb),
"sulphur_rejection_arb":parseFloat(data1.sulphur_rejection_arb),
"volatile_matter_typical_arb":parseFloat(data1.volatile_matter_typical_arb),
"volatile_matter_rejection_arb":parseFloat(data1.volatile_matter_rejection_arb),
"volatile_matter_maximum_arb":parseFloat(data1.volatile_matter_maximum_arb),
"volatile_matter_minimum_arb":parseFloat(data1.volatile_matter_minimum_arb),
"moisture_typical":parseFloat(data1.moisture_typical),
"moisture_rejection":parseFloat(data1.moisture_rejection),
"ash_typical":parseFloat(data1.ash_typical),
"ash_rejection":parseFloat(data1.ash_rejection),
"volatile_matter_typical":parseFloat(data1.volatile_matter_typical),
"volatile_matter_rejection":parseFloat(data1.volatile_matter_rejection),
"fixed_carbon_by_difference":defferece,
"fixed_carbon_typical":parseFloat(data1.fixed_carbon_typical),
"fixed_carbon_rejection":parseFloat(data1.fixed_carbon_rejection),
"gross_as_received_typical":parseFloat(data1.gross_as_received_typical),
"gross_as_received_rejection":parseFloat(data1.gross_as_received_rejection),
"gross_air_dried_typical":parseFloat(data1.gross_air_dried_typical),
"gross_air_dried_rejection":parseFloat(data1.gross_air_dried_rejection),
"net_as_recieved_rejection":parseFloat(data1.net_as_recieved_rejection),
"net_as_recieved_typical":parseFloat(data1.net_as_recieved_typical),
"hgi_typical":parseFloat(data1.hgi_typical),
"hgi_rejection":parseFloat(data1.hgi_rejection),
"carbon_typical":parseFloat(data1.carbon_typical),
"carbon_rejection":parseFloat(data1.carbon_rejection),
"hydrogen_typical":parseFloat(data1.hydrogen_typical),
"hydrogen_rejection":parseFloat(data1.hydrogen_rejection),
"nitrogen_typical":parseFloat(data1.nitrogen_typical),
"nitrogen_rejection":parseFloat(data1.nitrogen_rejection),
"sulphur_typical":parseFloat(data1.sulphur_typical),
"sulphur_rejection":parseFloat(data1.sulphur_rejection),
"oxygen_typical":parseFloat(data1.oxygen_typical),
"oxygen_rejection":parseFloat(data1.oxygen_rejection),
"sulphur_typical_adb":parseFloat(data1.sulphur_typical_adb),
"sulphur_rejection_adb":parseFloat(data1.sulphur_rejection_adb),
"initial_typical":parseFloat(data1.initial_typical),
"initial_rejection":parseFloat(data1.initial_rejection),
"spherical_typical":parseFloat(data1.spherical_typical),
"spherical_rejection":parseFloat(data1.spherical_rejection),
"hemispherical_typical":parseFloat(data1.hemispherical_typical),
"hemispherical_rejection":parseFloat(data1.hemispherical_rejection),
"flow_typical":parseFloat(data1.flow_typical),
"flow_rejection":parseFloat(data1.flow_rejection),
"oxidising_initial_typical":parseFloat(data1.oxidising_initial_typical),
"oxidising_initial_rejection":parseFloat(data1.oxidising_initial_rejection),
"oxidising_spherical_typical":parseFloat(data1.oxidising_spherical_typical),
"oxidising_spherical_rejection":parseFloat(data1.oxidising_spherical_rejection),
"oxidising_hemispherical_typical":parseFloat(data1.oxidising_hemispherical_typical),
"oxidising_hemispherical_rejection":parseFloat(data1.oxidising_hemispherical_rejection),
"oxidising_flow_typical":parseFloat(data1.oxidising_flow_typical),
"oxidising_flow_rejection":parseFloat(data1.oxidising_flow_rejection),
"SiO2_Typical":parseFloat(data1.SiO2_Typical),
"SiO2_rejection":parseFloat(data1.SiO2_rejection),
"Al2O3_Typical":parseFloat(data1.Al2O3_Typical),
"Al2O3_rejection":parseFloat(data1.Al2O3_rejection),
"Fe2O3_Typical":parseFloat(data1.Fe2O3_Typical),
"Fe2O3_rejection":parseFloat(data1.Fe2O3_rejection),
"CaO_Typical":parseFloat(data1.CaO_Typical),
"CaO_rejection":parseFloat(data1.CaO_rejection),
"MgO_Typical":parseFloat(data1.MgO_Typical),
"MgO_rejection":parseFloat(data1.MgO_rejection),
"Mn3O4_Typical":parseFloat(data1.Mn3O4_Typical),
"Mn3O4_rejection":parseFloat(data1.Mn3O4_rejection),
"TiO2_Typical":parseFloat(data1.TiO2_Typical),
"TiO2_rejection":parseFloat(data1.TiO2_rejection),
"Na2O_Typical":parseFloat(data1.Na2O_Typical),
"Na2O_rejection":parseFloat(data1.Na2O_rejection),
"K2O_Typical":parseFloat(data1.K2O_Typical),
"K2O_rejection":parseFloat(data1.K2O_rejection),
"P2O5_Typical":parseFloat(data1.P2O5_Typical),
"P2O5_rejection":parseFloat(data1.P2O5_rejection), 
"te_arsenic_as_typical_in_air_dried_coal":parseFloat(data1.te_arsenic_as_typical_in_air_dried_coal),
"te_arsenic_as_rejection_in_air_dried_coal":parseFloat(data1.te_arsenic_as_rejection_in_air_dried_coal),
"te_antimony_sb_typical_in_air_dried_coal":parseFloat(data1.te_antimony_sb_typical_in_air_dried_coal),
"te_antimony_sb_rejection_in_air_dried_coal":parseFloat(data1.te_antimony_sb_rejection_in_air_dried_coal),
"te_beryllium_be_typical_in_air_dried_coal":parseFloat(data1.te_beryllium_be_typical_in_air_dried_coal),
"product_supplier":data1.product_supplier,
"te_beryllium_be_rejection_in_air_dried_coal":parseFloat(data1.te_beryllium_be_rejection_in_air_dried_coal),
"te_boron_b_typical_in_air_dried_coal":parseFloat(data1.te_boron_b_typical_in_air_dried_coal),
"te_boron_b_rejection_in_air_dried_coal":parseFloat(data1.te_boron_b_rejection_in_air_dried_coal),
"te_cadmium_cd_typical_in_air_dried_coal":parseFloat(data1.te_cadmium_cd_typical_in_air_dried_coal),
"te_cadmium_cd_rejection_in_air_dried_coal":parseFloat(data1.te_cadmium_cd_rejection_in_air_dried_coal),
"te_chromium_cr_typical_in_air_dried_coal":parseFloat(data1.te_chromium_cr_typical_in_air_dried_coal),
"te_chromium_cr_rejection_in_air_dried_coal":parseFloat(data1.te_chromium_cr_rejection_in_air_dried_coal),
"te_copper_cu_typical_in_air_dried_coal":parseFloat(data1.te_copper_cu_typical_in_air_dried_coal),
"te_copper_cu_rejection_in_air_dried_coal":parseFloat(data1.te_copper_cu_rejection_in_air_dried_coal),
"te_chlorine_cl_typical_in_air_dried_coal":parseFloat(data1.te_chlorine_cl_typical_in_air_dried_coal),
"te_chlorine_cl_rejection_in_air_dried_coal":parseFloat(data1.te_chlorine_cl_rejection_in_air_dried_coal),
"te_fluorine_f_typical_in_air_dried_coal":parseFloat(data1.te_fluorine_f_typical_in_air_dried_coal),
"te_fluorine_f_rejection_in_air_dried_coal":parseFloat(data1.te_fluorine_f_rejection_in_air_dried_coal),
"te_mercury_hg_typical_in_air_dried_coal":parseFloat(data1.te_mercury_hg_typical_in_air_dried_coal),
"te_mercury_hg_rejection_in_air_dried_coal":parseFloat(data1.te_mercury_hg_rejection_in_air_dried_coal),
"te_molybdenum_mo_typical_in_air_dried_coal":parseFloat(data1.te_molybdenum_mo_typical_in_air_dried_coal),
"te_molybdenum_mo_rejection_in_air_dried_coal":parseFloat(data1.te_molybdenum_mo_rejection_in_air_dried_coal),
"te_lead_pb_typical_in_air_dried_coal":parseFloat(data1.te_lead_pb_typical_in_air_dried_coal),
"te_lead_pb_rejection_in_air_dried_coal":parseFloat(data1.te_lead_pb_rejection_in_air_dried_coal),
"te_phosphorus_p_typical_in_air_dried_coal":parseFloat(data1.te_phosphorus_p_typical_in_air_dried_coal),
"te_phosphorus_p_rejection_in_air_dried_coal":parseFloat(data1.te_phosphorus_p_rejection_in_air_dried_coal),
"te_selenium_se_typical_in_air_dried_coal":parseFloat(data1.te_selenium_se_typical_in_air_dried_coal),
"te_selenium_se_rejection_in_air_dried_coal":parseFloat(data1.te_selenium_se_rejection_in_air_dried_coal),
"te_vanadium_v_typical_in_air_dried_coal":parseFloat(data1.te_vanadium_v_typical_in_air_dried_coal),
"te_vanadium_v_rejection_in_air_dried_coal":parseFloat(data1.te_vanadium_v_rejection_in_air_dried_coal),
"te_zinc_zn_typical_in_air_dried_coal":parseFloat(data1.te_zinc_zn_typical_in_air_dried_coal),
"te_zinc_zn_rejection_in_air_dried_coal":parseFloat(data1.te_zinc_zn_rejection_in_air_dried_coal),
"AI2O3_Typical":parseFloat(data1.AI2O3_Typical),
//"te_arsenic_as_rejection":parseFloat(data1.dsdsdsdsdsds),
//"te_antimony_sb_typical":parseFloat(data1.dsdsdsdsdsds),
//"te_antimony_sb_rejection":parseFloat(data1.dsdsdsdsdsds),
//"te_boron_b_typical":parseFloat(data1.dsdsdsdsdsds),
//"te_boron_b_rejection":parseFloat(data1.dsdsdsdsdsds),
//"te_cadmium_cd_typical":parseFloat(data1.dsdsdsdsdsds),
//"te_cadmium_cd_rejection":parseFloat(data1.dsdsdsdsdsds),
//"te_chromium_cr_typical":parseFloat(data1.dsdsdsdsdsds),
//"te_chromium_cr_rejection":parseFloat(data1.dsdsdsdsdsds),
//"te_copper_cu_typical":parseFloat(data1.dsdsdsdsdsds),
//"te_copper_cu_rejection":parseFloat(data1.dsdsdsdsdsds),
//"te_chlorine_cl_typical":parseFloat(data1.dsdsdsdsdsds),
//"te_chlorine_cl_rejection":parseFloat(data1.dsdsdsdsdsds),
//"te_fluorine_f_typical":parseFloat(data1.dsdsdsdsdsds),
//"te_fluorine_f_rejection":parseFloat(data1.dsdsdsdsdsds),
//"te_mercury_hg_typical":parseFloat(data1.dsdsdsdsdsds),
//"te_mercury_hg_rejection":parseFloat(data1.dsdsdsdsdsds),
//"te_molybdenum_mo_typical":parseFloat(data1.dsdsdsdsdsds),
//"te_molybdenum_mo_rejection":parseFloat(data1.dsdsdsdsdsds),
//"te_lead_pb_typical":parseFloat(data1.dsdsdsdsdsds),
//"te_lead_pb_rejection":parseFloat(data1.dsdsdsdsdsds),
//"te_phosphorus_p_typical":parseFloat(data1.dsdsdsdsdsds),
//"te_phosphorus_p_rejection":parseFloat(data1.dsdsdsdsdsds),
//"te_selenium_se_typical":parseFloat(data1.dsdsdsdsdsds),
//"te_selenium_se_rejection":parseFloat(data1.dsdsdsdsdsds),
//"te_vanadium_v_typical":parseFloat(data1.dsdsdsdsdsds),
//"te_vanadium_v_rejection":parseFloat(data1.dsdsdsdsdsds),
//"te_zinc_zn_typical":parseFloat(data1.dsdsdsdsdsds),
"te_zinc_zn_rejection":parseFloat(data1.size_0_to_50mm_typical),
"size_0_to_50mm_typical":parseFloat(data1.size_0_to_50mm_typical),
"size_0_to_50mm_rejection":parseFloat(data1.size_0_to_50mm_rejection),
"above_0_to_50mm_typical":parseFloat(data1.above_0_to_50mm_typical),
"above_0_to_50mm_rejection":parseFloat(data1.above_0_to_50mm_rejection),
"under_2mm_typical":parseFloat(data1.under_2mm_typical),
"under_2mm_rejection":parseFloat(data1.under_2mm_rejection),
"size_above_2mm_typical":parseFloat(data1.size_above_2mm_typical),
"size_above_2mm_rejection":parseFloat(data1.size_above_2mm_rejection),
"testing_method":data1.testing_method,    };
    console.log(JSON.stringify(responseData))
    
    fetch(`${API_URL}update_quotes/${id}`, {
      method: "PUT",
      body: JSON.stringify(responseData),
      headers: {
        Authorization: `Bearer ${decryptedAccessToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((updatedData) => {
       console.log(updatedData.success);
       
        setData(updatedData);
        setIsEditMode(false);

        fetchData();

      })
      .catch((error) => console.error(error));
  };
   
// edit page

// /get/file/<id>
  return (
    <div id="view-page">
      <Header />
      <div className="contactAccount">
        <div className="page-header" id="add-account">
          <div>




            <h2 className="main-content-title tx-24 mg-b-5">Quote Product Detalis</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Quote Product Detalis
              </li>
            </ol>
          </div>
        </div>
      </div>
      {isEditMode ? (
       <div className="tasks">
       <p className="edit-btn">
         <button className="btn btn-primary" onClick={handleSaveEdit}>
           Save
         </button>
         <button className="btn btn-primary" onClick={handleCancelEdit}>
           Cancel
         </button>
       </p>
       
             
       <Row className="mb-3">
    <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Quote Product Name"
             className="mb-3"
           >
             <Form.Control
               type="text"
               placeholder="Product Account"
               name="product_name"
               
           value={data1.product_name}
           onChange={handleChange}
               />
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
                  <label>Locked</label>
                  <input
                    type='checkbox'
                    name='locked'
                    checked={lockeds} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange} // Handle the change event
                    className='mx-3'
                  />
                </Form.Group>  
         <Form.Group as={Col} md="6">
         <div className="form-grop">
       <label htmlFor="product-code">Product Code:</label>
       <select
         className="form-control"
         id="product-code"
         name="product-code"
         value={productCode || data1.product_code}
         onChange={handleProductCodeChange}
       >
         <option value="">Select a product code</option>
         {products.map((product) => (
           <option key={product.id} value={product.product_code}>
             {product.product_code}
           </option>
         ))}
       </select>
       </div> 
         </Form.Group>
         <Form.Group as={Col} md="6">
                   
                   <FloatingLabel controlId="floatingSelect" 
                   className='dropDown' label="Supplier">
         <Form.Select aria-label="Floating label select example"
         name="product_supplier" 
         placeholder="Supplier" 
         value={data1.product_supplier}
         // defaultValue={}
         onChange={handleChange}  
         >
     
     <option defaultValue='' >Select </option>
              {
                 productAccount.map((x)=>{
                     return(
                         <option defaultValue={x.account_name}>{x.account_name}</option>
                     )
                 })
              }
           
             
         </Form.Select>
         
       </FloatingLabel>
     
             </Form.Group> 
          
         
       <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Quote Shipment">
    <Form.Select aria-label="Floating label select example"
    
    name="quote_shipment"
    defaultValue={data1.quote_shipment||""}
    onChange={handleChange} 
    >
      {/* <option value='' >Select</option> */}
       {
          shippment.map((x)=>{
              return(
                <option  value={x.quote_shipment_code}>
                {x.quote_shipment_code}
              </option>
              )
          })
       }
    </Form.Select> 
  </FloatingLabel>
              </Form.Group> 
       
        
  </Row>

  <Row className="mb-3">
             <h4 className='heading'>Product Information
</h4>
<Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Shipping Term"
             className="mb-3"
           >
             
             <Form.Control type="text" 
             placeholder="Shipping Term" 
             name="product_shipping_term"
             
           value={data1.product_shipping_term}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
         
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Name"
             className="mb-3"
           >
             
             <Form.Control type="text" 
             placeholder="Product Name" 
             name="product_name"
            
           value={data1.product_name}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Port Of Loading"
             className="mb-3"
           >
             
             <Form.Control type="text" 
             placeholder="Port Of Loading" 
             name="product_port_of_loading"
             
           value={data1.product_port_of_loading}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
         
        
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Description"
             className="mb-3"
           >
             
             <Form.Control type="text" 
             placeholder="Product Description" 
             name="product_description"
             
           value={data1.product_description}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Product Price GAR"
             className="mb-3"
           >
             
             <Form.Control type="text" 
             placeholder="Product Price GAR" 
             name="product_price_basis"
             
           value={data1.product_price_basis}
           onChange={handleChange}/>
           </FloatingLabel>
         </Form.Group>
        </Row>
          <Row className="mb-3">
             <h4 className='heading'>%As Received Basis</h4>
             
                     {/* <h4 className='heading'>Account Information</h4> */}
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Ash Content Typical ARB "
                         className="mb-3"
                         
                         
                       >
                         <Form.Control
                           type="text"
                           placeholder="Ash Content Typical ARB "
                           name="ash_typical_arb"
                           defaultValue={data1.ash_typical_arb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group> 
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Ash Content Rejection ARB "
                         className="mb-3"
                         
                         
                       >
                         <Form.Control
                           type="text"
                           placeholder="Ash Content Rejection ARB "
                           name="ash_rejection_arb"
                           defaultValue={data1.ash_rejection_arb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group> 
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Volatile Matter Typical ARB "
                         className="mb-3"
                         
                         
                       >
                         <Form.Control
                           type="text"
                           placeholder="Volatile Matter Typical ARB "
                           name="volatile_matter_typical_arb"
                           defaultValue={data1.volatile_matter_typical_arb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group> 
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Volatile Matter Rejection ARB"
                         className="mb-3"
                         
                         
                       >
                         <Form.Control
                           type="text"
                           placeholder="Volatile Matter Rejection ARB"
                           name="volatile_matter_rejection_arb"
                           defaultValue={data1.volatile_matter_rejection_arb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group> 
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Total Sulphur Typical ARB "
                         className="mb-3"
                         
                         
                       >
                         <Form.Control
                           type="text"
                           placeholder="Total Sulphur Typical ARB "
                           name="sulphur_typical_arb"
                           defaultValue={data1.sulphur_typical_arb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group> 
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Total Sulphur Rejection ARB "
                         className="mb-3"
                         
                         
                       >
                         <Form.Control
                           type="text"
                           placeholder="Total Sulphur Rejection ARB "
                           name="sulphur_rejection_arb"
                           defaultValue={data1.sulphur_rejection_arb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group> 
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Total Moisture Typical"
                         className="mb-3"
                         
                         
                       >
                         <Form.Control
                           type="text"
                           placeholder="Total Moisture Typical"
                           name="total_moisture_typical"
                           defaultValue={data1.total_moisture_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group> 
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Total Moisture Rejection"
                         className="mb-3"
                         
                         
                       >
                         <Form.Control
                           type="text"
                           placeholder="Total Moisture Rejection"
                           name="total_moisture_rejection"
                           defaultValue={data1.total_moisture_rejection}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group> 
             </Row>
             <Row className="mb-3">
        <h4 className='heading'>Proximate Analysis %Air Dried Basis</h4>
        
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Inherent Moisture Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Inherent Moisture Typical "
                      name="moisture_typical"
                        defaultValue={data1.moisture_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Inherent Moisture Rejection   "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Inherent Moisture Rejection "
                      name="moisture_rejection"
                        defaultValue={data1.moisture_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Content Typical  "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Ash Content Typical   "
                      name="ash_typical"
                        defaultValue={data1.ash_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Content Rejection  "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Ash Content Rejection"
                      name="ash_rejection"
                        defaultValue={data1.ash_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Typical ADB"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Volatile Matter Typical ADB"
                      name="volatile_matter_minimum_arb"
                        defaultValue={data1.volatile_matter_minimum_arb }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Rejection ADB"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Volatile Matter Rejection ADB "
                      name="volatile_matter_maximum_arb"
                        defaultValue={data1.volatile_matter_maximum_arb}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon Typical ADB "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Fixed Carbon Typical ADB"
                      name="fixed_carbon_typical"
                        defaultValue={data1.fixed_carbon_typical}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon Rejection ADB"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Fixed Carbon Rejection ADB"
                      name="fixed_carbon_rejection"
                        defaultValue={data1.fixed_carbon_rejection}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                 
                  <input
                    type='checkbox'
                    name='fixed_carbon_by_difference'
                    checked={defferece} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxActive} // Handle the change event
                    className='mx-3'
                  />
                   <label>Fixed Carbon By Difference</label>
                </Form.Group> 
                 
        </Row>   
        
        <Row className="mb-3">
        <h4 className='heading'>CALORIFIC VALUE, kcal / kg</h4>
        <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross As Received Typical "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Gross As Received Typical  "
                      name="gross_as_received_typical"
                        defaultValue={data1.gross_as_received_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross As Received Rejection   "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Gross As Received Rejection "
                      name="gross_as_received_rejection"
                        defaultValue={data1.gross_as_received_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross Air Dried Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Gross Air Dried Typical "
                      name="gross_air_dried_typical"
                        defaultValue={data1.gross_air_dried_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross Air Dried Rejection "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Gross Air Dried Rejection  "
                      name="gross_air_dried_rejection"
                        defaultValue={data1.gross_air_dried_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Net As Received Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Net As Received Typical "
                      name="net_as_recieved_typical"
                        defaultValue={data1.net_as_recieved_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Net As Received Rejection"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Net As Received Rejection "
                      name="net_as_recieved_rejection"
                        defaultValue={data1.net_as_recieved_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
        </Row>
        <Row className="mb-3">
        <h4 className='heading'>HGI</h4>
        <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="HGI Typical"
                      name="hgi_typical"
                        defaultValue={data1.hgi_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI Rejection"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="HGI Rejection"
                      name="hgi_rejection"
                        defaultValue={data1.hgi_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
        </Row>

        <Row className="mb-3">
        <h4 className='heading'>ULTIMATE ANALYSIS, %dry ash free basis</h4>
        
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Typical "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Carbon Typical   "
                      name="carbon_typical"
                        defaultValue={data1.carbon_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="carbon rejection"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="carbon rejection "
                      name="carbon_rejection"
                        defaultValue={data1.carbon_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Typical  "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Hydrogen Typical    "
                      name="hydrogen_typical"
                        defaultValue={data1.hydrogen_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Rejection"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Hydrogen Rejection"
                      name="hydrogen_rejection"
                        defaultValue={data1.hydrogen_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Typical "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Nitrogen Typical"
                      name="nitrogen_typical"
                        defaultValue={data1.nitrogen_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="nitrogen rejection"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="nitrogen rejection"
                      name="nitrogen_rejection"
                        defaultValue={data1.nitrogen_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Typical "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Oxygen Typical   "
                      name="oxygen_typical"
                        defaultValue={data1.oxygen_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxygen rejection"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxygen rejection "
                      name="oxygen_rejection"
                        defaultValue={data1.oxygen_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
        </Row>

        <Row className="mb-3">
        <h4 className='heading'>SULPHUR, %air dried basis</h4>
 
        <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical ADB"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Sulphur Typical ADB  "
                      name="sulphur_typical_adb"
                        defaultValue={data1.sulphur_typical_adb }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Rejection ADB"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Sulphur Rejection ADB"
                      name="sulphur_rejection_adb"
                        defaultValue={data1.sulphur_rejection_adb }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
        </Row>
        <Row className="mb-3">
        <h4 className='heading'>ASH FUSION TEMPERATURE, DEG(C) Reducing </h4>
         
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Initial Deformation Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Initial Deformation Typical  "
                      name="initial_typical"
                        defaultValue={data1.initial_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>   
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Initial Deformation Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Initial Deformation Rejection"
                      name="initial_rejection"
                        defaultValue={data1.initial_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>   
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Spherical Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Spherical Typical "
                      name="spherical_typical"
                        defaultValue={data1.spherical_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>   
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Spherical Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Spherical Rejection"
                      name="spherical_rejection"
                        defaultValue={data1.spherical_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>   
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hemispherical Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Hemispherical Typical"
                      name="hemispherical_typical"
                        defaultValue={data1.hemispherical_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>   
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hemispherical rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="hemispherical rejection "
                      name="hemispherical_rejection"
                        defaultValue={data1.hemispherical_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>   
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Flow Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Flow Typical  "
                      name="flow_typical"
                        defaultValue={data1.flow_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>   
 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="flow rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="flow rejection"
                      name="flow_rejection"
                        defaultValue={data1.flow_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>    
        </Row>
        <Row className="mb-3">
        <h4 className='heading'>ASH FUSION TEMPERATURE, DEG (C) Oxidizing</h4>
         
        <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Deformation Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Initial Deformation Typical"
                      name="oxidising_initial_typical"
                        defaultValue={data1.oxidising_initial_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Deformation Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Initial Deformation Rejection"
                      name="oxidising_initial_rejection"
                        defaultValue={data1.oxidising_initial_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Hemisperical Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Hemisperical Typical"
                      name="oxidising_hemispherical_typical"
                        defaultValue={data1.oxidising_hemispherical_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Hemisperical Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Hemisperical Rejection "
                      name="oxidising_hemispherical_rejection"
                        defaultValue={data1.oxidising_hemispherical_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Spherical Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Spherical Typical "
                      name="oxidising_spherical_typical"
                        defaultValue={data1.oxidising_spherical_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Spherical Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Spherical Rejection"
                      name="oxidising_spherical_rejection"
                        defaultValue={data1.oxidising_spherical_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Flow Typical  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Flow Typical   "
                      name="oxidising_flow_typical"
                        defaultValue={data1.oxidising_flow_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Flow Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Flow Rejection"
                      name="oxidising_flow_rejection"
                        defaultValue={data1.oxidising_flow_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
        </Row>

        <Row className="mb-3">
        <h4 className='heading'>ASH ANALYSIS, %dry basis in ash</h4>
          
        <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SiO2 Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="SiO2 Typical"
                      name="SiO2_Typical"
                        defaultValue={data1.SiO2_Typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  

               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SiO2 Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="SiO2 Rejection"
                      name="SiO2_rejection"
                        defaultValue={data1.SiO2_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="AI2O3 Typical"
                    className="mb-3" >
                    <Form.Control
                      type="number"
                      placeholder="AI2O3 Typical"
                      name="AI2O3_Typical"
                        defaultValue={data1.AI2O3_Typical}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="AI2O3 Rejection  "
                    className="mb-3" >
                    <Form.Control
                      type="number"
                      placeholder="AI2O3 Rejection"
                      name="AI2O3_rejection"
                        defaultValue={data1.AI2O3_rejection}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fe2O3 Typical  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Fe2O3 Typical"
                      name="Fe2O3_Typical"
                        defaultValue={data1.Fe2O3_Typical}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fe2O3 Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Fe2O3 Rejection"
                      name="Fe2O3_rejection"
                        defaultValue={data1.Fe2O3_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="CaO Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="CaO Typical"
                      name="CaO_Typical"
                        defaultValue={data1.CaO_Typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="CaO Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="CaO Rejection  "
                      name="CaO_rejection"
                        defaultValue={data1.CaO_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
                
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="P2O5 Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="P2O5 Typical"
                      name="P2O5_Typical"
                        defaultValue={data1.P2O5_Typical}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="P2O5 Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="P2O5 Rejection"
                      name="P2O5_rejection"
                        defaultValue={data1.P2O5_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
                <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SO3 Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="SO3 Typical"
                      name="SO3_Typical"
                        defaultValue={data1.SO3_Typical}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SO3 Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="SO3 Rejection"
                      name="SO3_rejection"
                        defaultValue={data1.SO3_rejection }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
                
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MgO Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="MgO Typical"
                      name="MgO_Typical"
                        defaultValue={data1.MgO_Typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MgO Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="MgO Rejection "
                      name="MgO_rejection"
                        defaultValue={data1.MgO_rejection}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
               <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TiO2 Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TiO2 Typical"
                      name="TiO2_rejection"
                        defaultValue={data1.TiO2_rejection}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TiO2 Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TiO2 Rejection"
                      name="TiO2_rejection"
                        defaultValue={data1.TiO2_rejection}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="NA2O Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="NA2O Typical"
                      name="NA2O_Typical"
                        defaultValue={data1.NA2O_Typical}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="NA2O Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="NA2O Rejection  "
                      name="NA2O_rejection"
                        defaultValue={data1.NA2O_rejection}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="K2O Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="K2O Typical "
                      name="K2O_Typical"
                        defaultValue={data1.K2O_Typical}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>   <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="K2O Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="K2O Rejection"
                      name="K2O_rejection"
                        defaultValue={data1.K2O_rejection}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>   <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="KMn3O4 Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="KMn3O4 Typical"
                      name="KMn3O4_Typical"
                        defaultValue={data1.KMn3O4_Typical}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>    <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Mn3O4 Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Mn3O4 Rejection"
                      name="Mn3O4_rejection"
                        defaultValue={data1.Mn3O4_rejection}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
        </Row>
        <Row className="mb-3">
        <h4 className='heading'>TRACE ELEMENT, ppm Air Dried in Coal
        </h4>
        <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Antimony Sb Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Antimony Sb Typical"
                      name="te_antimony_sb_typical_in_air_dried_coal"
                        defaultValue={data1.te_antimony_sb_typical_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Antimony Sb Rejection   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Antimony Sb Rejection "
                      name="te_antimony_sb_rejection_in_air_dried_coal"
                        defaultValue={data1.te_antimony_sb_rejection_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Arsenic As Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Arsenic As Typical "
                      name="te_arsenic_as_typical_in_air_dried_coal"
                        defaultValue={data1.te_arsenic_as_typical_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Arsenic As Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Arsenic As Rejection  "
                      name="te_arsenic_as_rejection_in_air_dried_coal"
                        defaultValue={data1.te_arsenic_as_rejection_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Beryllium Be Typical "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Beryllium Be Typical  "
                      name="te_beryllium_be_typical_in_air_dried_coal"
                        defaultValue={data1.te_beryllium_be_typical_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Beryllium Be Rejection"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Beryllium Be Rejection"
                      name="te_beryllium_be_rejection_in_air_dried_coal"
                        defaultValue={data1.te_beryllium_be_rejection_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Boron B Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Boron B Typical"
                      name="te_boron_b_typical_in_air_dried_coal"
                        defaultValue={data1.te_boron_b_typical_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Boron B Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Boron B Rejection  "
                      name="te_boron_b_rejection_in_air_dried_coal"
                        defaultValue={data1.te_boron_b_rejection_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Cadmium Cd Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Cadmium Cd Typical "
                      name="te_cadmium_cd_typical_in_air_dried_coal"
                        defaultValue={data1.te_cadmium_cd_typical_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Cadmium Cd Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Cadmium Cd Rejection  "
                      name="te_cadmium_cd_rejection_in_air_dried_coal"
                        defaultValue={data1.te_cadmium_cd_rejection_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Chromium Cr Typical  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Chromium Cr Typical   "
                      name="te_chromium_cr_typical_in_air_dried_coal"
                        defaultValue={data1.te_chromium_cr_typical_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Chromium Cr Rejection   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Chromium Cr Rejection "
                      name="te_chromium_cr_rejection_in_air_dried_coal"
                        defaultValue={data1.te_chromium_cr_rejection_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Chlorine Cl Typical  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Chlorine Cl Typical   "
                      name="te_chlorine_cl_typical_in_air_dried_coal"
                        defaultValue={data1.te_chlorine_cl_typical_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Chromium Cr Rejection   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Chromium Cr Rejection "
                      name="te_chlorine_cl_rejection_in_air_dried_coal"
                        defaultValue={data1.te_chlorine_cl_rejection_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Copper Cu Typical "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Copper Cu Typical  "
                      name="te_copper_cu_typical_in_air_dried_coal"
                        defaultValue={data1.te_copper_cu_typical_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Copper Cu Rejection  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Copper Cu Rejection   "
                      name="te_copper_cu_rejection_in_air_dried_coal"
                        defaultValue={data1.te_copper_cu_rejection_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Fluorine F Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Fluorine F Typical "
                      name="te_fluorine_f_typical_in_air_dried_coal"
                        defaultValue={data1.te_fluorine_f_typical_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Fluorine F Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Fluorine F Rejection  "
                      name="te_fluorine_f_rejection_in_air_dried_coal"
                        defaultValue={data1.te_fluorine_f_rejection_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Lead Pb Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Lead Pb Typical "
                      name="te_lead_pb_typical_in_air_dried_coal"
                        defaultValue={data1.te_lead_pb_typical_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Lead Pb Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Lead Pb Rejection  "
                      name="te_lead_pb_rejection_in_air_dried_coal"
                        defaultValue={data1.te_lead_pb_rejection_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Mercury Hg Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Mercury Hg Typical "
                      name="te_mercury_hg_typical_in_air_dried_coal"
                        defaultValue={data1.te_mercury_hg_typical_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Mercury Hg Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Mercury Hg Rejection  "
                      name="te_mercury_hg_rejection_in_air_dried_coal"
                        defaultValue={data1.te_mercury_hg_rejection_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Molybdenum Mo Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Molybdenum Mo Typical "
                      name="te_molybdenum_mo_typical_in_air_dried_coal"
                        defaultValue={data1.te_molybdenum_mo_typical_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Molybdenum Mo Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Molybdenum Mo Rejection  "
                      name="te_molybdenum_mo_rejection_in_air_dried_coal"
                        defaultValue={data1.te_molybdenum_mo_rejection_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Phosporous P Typical "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Phosporous P Typical  "
                      name="te_phosphorus_p_typical_in_air_dried_coal"
                        defaultValue={data1.te_phosphorus_p_typical_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Phosporous P Rejection  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Phosporous P Rejection   "
                      name="te_phosphorus_p_rejection_in_air_dried_coal"
                        defaultValue={data1.te_phosphorus_p_rejection_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Selenium Se Typical  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Selenium Se Typical   "
                      name="te_selenium_se_typical_in_air_dried_coal"
                        defaultValue={data1.te_selenium_se_typical_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Selenium Se Rejection   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Selenium Se Rejection "
                      name="te_selenium_se_rejection_in_air_dried_coal"
                        defaultValue={data1.te_selenium_se_rejection_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Vanadium V Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Vanadium V Typical "
                      name="te_vanadium_v_typical_in_air_dried_coal"
                        defaultValue={data1.te_vanadium_v_typical_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Vanadium V Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Vanadium V Rejection  "
                      name="te_vanadium_v_rejection_in_air_dried_coal"
                        defaultValue={data1.te_vanadium_v_rejection_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Zinc Zn Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Zinc Zn Typical "
                      name="te_zinc_zn_typical_in_air_dried_coal"
                        defaultValue={data1.te_zinc_zn_typical_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
                 <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE Zinc Zn Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="TE Zinc Zn Rejection  "
                      name="te_zinc_zn_rejection_in_air_dried_coal"
                        defaultValue={data1.te_zinc_zn_rejection_in_air_dried_coal}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
        </Row>
        <Row className="mb-3">
        <h4 className='heading'>Sizing, %</h4>
        <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size (0-50 mm) Typical"
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Size (0-50 mm) Typical"
                      name="size_0_to_50mm_typical"
                        defaultValue={data1.size_0_to_50mm_typical}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size (0-50 mm) Rejection   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Size (0-50 mm) Rejection "
                      name="size_0_to_50mm_rejection"
                        defaultValue={data1.size_0_to_50mm_rejection}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Above 50 mm Typical  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Above 50 mm Typical   "
                      name="above_0_to_50mm_typical"
                        defaultValue={data1.above_0_to_50mm_typical}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Above 50 mm Rejection   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Above 50 mm Rejection "
                      name="above_0_to_50mm_rejection"
                        defaultValue={data1.above_0_to_50mm_rejection}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Under 2 mm Typical   "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Under 2 mm Typical "
                      name="under_2mm_typical"
                        defaultValue={data1.under_2mm_typical}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Under 2 mm Rejection "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Under 2 mm Rejection  "
                      name="under_2mm_rejection"
                        defaultValue={data1.under_2mm_rejection}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size Above 2 mm Typical "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Size Above 2 mm Typical  "
                      name="size_above_2mm_typical"
                        defaultValue={data1.size_above_2mm_typical}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  <Form.Group as={Col} md="6"> 
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size Above 2 mm Rejection  "
                    className="mb-3" >
                    <Form.Control
                      type="text"
                      placeholder="Size Above 2 mm Rejection   "
                      name="size_above_2mm_rejection"
                        defaultValue={data1.size_above_2mm_rejection}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>  
        </Row>
        <Row className="mb-3">
        <h4 className='heading'>Testing Method</h4>
        <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Testing Method"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="testing_method"
                 defaultValue={data1.testing_method }
                        onChange={handleChange}
                >
                  <option defaultValue="">Select </option>
                  <option defaultValue="According to ASTM Standard Method Unless Otherwise Stated">
                    According to ASTM Standard Method Unless Otherwise Stated
                  </option>
                  <option defaultValue="According to ISO Methods">
                    According to ISO Methods
                  </option>
                  <option defaultValue="According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM">
                    According to ISO Methods Except HGI, Trace Elements and Ash
                    Analysis to ASTM
                  </option>
                  <option defaultValue="According to ISO Methods Except HGI to ASTM">
                    According to ISO Methods Except HGI to ASTM
                  </option>
                  <option defaultValue="According to ISO Methods, Except HGI and Ash Analysis to ASTM">
                    According to ISO Methods, Except HGI and Ash Analysis to
                    ASTM
                  </option>
                  <option defaultValue="According to ISO Methods, Except HGI as per ASTM Test Method">
                    According to ISO Methods, Except HGI as per ASTM Test Method
                  </option>
                </Form.Select> 
              </FloatingLabel>
            </Form.Group>
        </Row>
      
               <p className="edit-btn">
         <button className="btn btn-primary" onClick={handleSaveEdit}>
           Save
         </button>
         <button className="btn btn-primary" onClick={handleCancelEdit}>
           Cancel
         </button>
       </p>
                   </div>
      ):(
         
      <div className="tasks"> 

<div className="edit-delete">
                                      <span id="edit" onClick={handleEditClick}>
                                        Edit
                                      </span>
                                      <span>
                                        <Link to={`/opportunity/quote-view/${data1.offer_id}`}>
                                          {" "}
                                          Cancel
                                        </Link>
                                      </span>
                                    </div>
   
        <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">Information</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>Quote Product Name</span>
                </td><td>
                {data1.product_name}
              </td>
              <td id="td-right">
                <span>Locked</span>
                </td><td>
                <input
                    type='checkbox'
                    name='locked'
                    checked={lockeds} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange} // Handle the change event
                    className='mx-3'
                    disabled={isButtonEnabled}
                  />
              </td>
             
            </tr> <tr>
            <td id="td-right">
                <span>Product Master</span>
                </td><td>
                <Link to={`/opportunity/quote-view/${data1.offer}`}>{data1.product_code}</Link>
              </td>
              <td id="td-right">
                <span>Quote	</span>
                </td><td>
                <Link to={`/opportunity/quote-view/${data1.offer_id}`}>{data1.quotes_name}</Link>
                
              </td>
             
            </tr>
            
             <tr>
            
              <td id="td-right">
                <span>quote shipment</span>
                </td><td>
               <Link to={`/quote-shipment/detail/${data1.quote_ship_id}`} >{data1.quote_shipment}</Link>
              </td>
              <td id="td-right">
                <span>Product Supplier</span>
                </td><td>
               <Link to={`/accounts/${data1.supplier_id}`} >{data1.supplier}</Link>
              </td>
            </tr> 
             
          </tbody>
        </table> 



        <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">Product Information
</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>shipping term</span>
                </td><td>
                {data1.product_shipping_term}
              </td>
              <td id="td-right">
                <span>product name</span>
                </td><td>
                {data1.product_name}
              </td>
             
            </tr> <tr>
            <td id="td-right">
                <span>product port of loading	</span>
                </td><td>
                {data1.product_port_of_loading}
              </td>
              <td id="td-right">
                <span>product description</span>
                </td><td>
                {data1.product_description}
              </td>
             
            </tr> <tr>
            <td id="td-right">
                <span>product price GAR</span>
                </td><td>
                {data1.product_price_basis}
              </td>
              <td id="td-right">
                <span></span>
                </td><td>
                 
              </td>
             
            </tr> 
          </tbody>
        </table> 

 
            <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">%As Received Basis</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>Ash Content Typical ARB</span>
                </td><td>
                {data1.ash_typical_arb}
              </td>
              <td id="td-right">
                <span>Ash Content Rejection ARB</span>
                </td><td>
                {data1.ash_rejection_arb}
              </td>
             
            </tr> 
            <tr>
            <td id="td-right">
                <span>Volatile Matter Typical ARB</span>
                </td><td>
                {data1.volatile_matter_typical_arb}
              </td>
              <td id="td-right">
                <span>Volatile Matter Rejection ARB</span>
                </td><td>
                {data1.volatile_matter_rejection_arb}
              </td>
             
            </tr> 

            <tr>
            <td id="td-right">
                <span>Total Sulphur Typical ARB</span>
                </td><td>
                {data1.sulphur_typical_arb}
              </td>
              <td id="td-right">
                <span>Total Sulphur Rejection ARB</span>
                </td><td>
                {data1.sulphur_rejection_arb}
              </td>
             
            </tr> 

            <tr>
            <td id="td-right">
                <span>Total Moisture Typical</span>
                </td><td>
                {data1.total_moisture_typical}
              </td>
              <td id="td-right">
                <span>Total Moisture Rejection</span>
                </td><td>
                {data1.total_moisture_rejection}
              </td>
             
            </tr> 

            
            </tbody>
            </table>

            <div className="col-md-12" id='adatas'>
                   <h4 className="heading">Proximate Analysis %Air Dried Basis</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Inherent Moisture Typical	
      
                         </span> </td>
                         <td>{data1.moisture_typical}</td>
                         <td id="td-right"><span>Inherent Moisture Rejection	
                         </span> </td>
                         <td>{data1.moisture_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Ash Content Typical	
      
                         </span> </td>
                         <td>{data1.ash_typical}</td>
                         <td id="td-right"><span>Ash Content Rejection	
                         </span> </td>
                         <td>{data1.ash_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Volatile Matter Typical ADB	
      
                         </span> </td>
                         <td>{data1.volatile_matter_minimum_arb}</td>
                         <td id="td-right"><span>Volatile Matter Rejection ADB	
                         </span> </td>
                         <td>{data1.volatile_matter_maximum_arb}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Fixed Carbon Typical ADB	
      
                         </span> </td>
                         <td>{data1.fixed_carbon_typical}</td>
                         <td id="td-right"><span>Fixed Carbon Rejection ADB	
                         </span> </td>
                         <td>{data1.fixed_carbon_rejection}</td>
   
                       </tr>
                       
                       <tr>
                      <td id="td-right"><span>Fixed Carbon By Difference	
   
                      </span> </td>
                      <td><input
                   type='checkbox'
                   name='fixed_carbon_by_difference'
                   checked={defferece} // Use the state variable to determine if the checkbox is checked
                   onChange={handleCheckboxActive} // Handle the change event
                   className='mx-3'
                   disabled={isButtonEnabled}
                 /></td>
                      <td id="td-right">
                        {/* <span>Locdsdsdsked</span>  */}
                        </td>
                      <td>
                        {/* {data1.locked} */}

                      </td>

                    </tr>
                   
                       </tbody>
                       </table>
   
                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">CALORIFIC VALUE, kcal / kg</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Gross As Received Typical   
                         </span> </td>
                         <td>{data1.gross_as_received_typical}</td>
                         <td id="td-right"><span>Gross As Received Rejection	
                         </span> </td>
                         <td>{data1.gross_as_received_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Gross Air Dried Typical	
      
                         </span> </td>
                         <td>{data1.gross_air_dried_typical}</td>
                         <td id="td-right"><span>Gross Air Dried Rejection	
                         </span> </td>
                         <td>{data1.gross_air_dried_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Net As Received Typical	
      
                         </span> </td>
                         <td>{data1.net_as_recieved_typical}</td>
                         <td id="td-right"><span>Net As Received Rejection	
                         </span> </td>
                         <td>{data1.net_as_recieved_rejection}</td>
   
                       </tr>
                        
                       </tbody>
                       </table>
                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">HGI</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>HGI Typical   
                         </span> </td>
                         <td>{data1.hgi_typical}</td>
                         <td id="td-right"><span>HGI Rejection	
                         </span> </td>
                         <td>{data1.hgi_rejection}</td>
   
                       </tr>
                        
                       </tbody>
                       </table>
   
                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">ULTIMATE ANALYSIS, %dry ash free basis</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Carbon Typical	
      
                         </span> </td>
                         <td>{data1.carbon_typical}</td>
                         <td id="td-right"><span>Carbon Rejection	
                         </span> </td>
                         <td>{data1.carbon_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Hydrogen Typical	
      
                         </span> </td>
                         <td>{data1.hydrogen_typical}</td>
                         <td id="td-right"><span>Hydrogen Rejection	
                         </span> </td>
                         <td>{data1.hydrogen_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Nitrogen Typical	
      
                         </span> </td>
                         <td>{data1.nitrogen_typical}</td>
                         <td id="td-right"><span>Nitrogen Rejection	
                         </span> </td>
                         <td>{data1.nitrogen_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Oxygen Typical	
      
                         </span> </td>
                         <td>{data1.oxygen_typical}</td>
                         <td id="td-right"><span>Oxygen Rejection	
                         </span> </td>
                         <td>{data1.oxygen_rejection}</td>
   
                       </tr>
                        
                       </tbody>
                       </table>
   
                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">SULPHUR, %air dried basis</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Sulphur Typical ADB	
      
                         </span> </td>
                         <td>{data1.sulphur_typical_adb}</td>
                         <td id="td-right"><span>Sulphur Rejection ADB	
                         </span> </td>
                         <td>{data1.sulphur_rejection_adb}</td>
   
                       </tr>
                        
                       </tbody>
                       </table>
   
                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">ASH FUSION TEMPERATURE, DEG(C) Reducing</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Initial Deformation Typical	
      
                         </span> </td>
                         <td>{data1.initial_typical}</td>
                         <td id="td-right"><span>Initial Deformation Rejection	
                         </span> </td>
                         <td>{data1.initial_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Spherical Typical	
      
                         </span> </td>
                         <td>{data1.spherical_typical}</td>
                         <td id="td-right"><span>Spherical Rejection	
                         </span> </td>
                         <td>{data1.spherical_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Hemispherical Typical	
      
                         </span> </td>
                         <td>{data1.hemispherical_typical}</td>
                         <td id="td-right"><span>Hemispherical Rejection	
                         </span> </td>
                         <td>{data1.hemispherical_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Flow Typical	
      
                         </span> </td>
                         <td>{data1.flow_typical}</td>
                         <td id="td-right"><span>Flow Rejection	
                         </span> </td>
                         <td>{data1.flow_rejection}</td>
   
                       </tr>
                        
                       </tbody>
                       </table>
   
   
                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">ASH FUSION TEMPERATURE, DEG (C) Oxidizing</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Oxidizing Initial Deformation Typical   
                         </span> </td>
                         <td>{data1.oxidising_initial_typical}</td>
                         <td id="td-right"><span>Oxidizing Initial Deformation Rejection	
                         </span> </td>
                         <td>{data1.oxidising_initial_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Oxidizing Hemisperical Typical	
      
                         </span> </td>
                         <td>{data1.oxidising_hemispherical_typical}</td>
                         <td id="td-right"><span>Oxidizing Hemisperical Rejection	
                         </span> </td>
                         <td>{data1.oxidising_hemispherical_rejection}</td>
   
                       </tr>
                        
                       <tr>
                         <td id="td-right"><span>Oxidizing Spherical Typical	
      
                         </span> </td>
                         <td>{data1.oxidising_spherical_typical}</td>
                         <td id="td-right"><span>Oxidizing Spherical Rejection	
                         </span> </td>
                         <td>{data1.oxidising_spherical_rejection}</td>
   
                       </tr>
                        
                       <tr>
                         <td id="td-right"><span>Oxidizing Flow Typical	
      
                         </span> </td>
                         <td>{data1.oxidising_flow_typical}</td>
                         <td id="td-right"><span>Oxidizing Flow Rejection	
                         </span> </td>
                         <td>{data1.oxidising_flow_rejection}</td>
   
                       </tr>
                        
                       </tbody>
                       </table>
   
                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">ASH ANALYSIS, %dry basis in ash</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>SiO2 Typical	
      
                         </span> </td>
                         <td>{data1.SiO2_Typical}</td>
                         <td id="td-right"><span>SiO2 Rejection	
                         </span> </td>
                         <td>{data1.SiO2_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>AI2O3 Typical	
      
                         </span> </td>
                         <td>{data1.AI2O3_Typical}</td>
                         <td id="td-right"><span>AI2O3 Rejection	
                         </span> </td>
                         <td>{data1.AI2O3_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Fe2O3 Typical	
      
                         </span> </td>
                         <td>{data1.Fe2O3_Typical}</td>
                         <td id="td-right"><span>Fe2O3 Rejection	
                         </span> </td>
                         <td>{data1.Fe2O3_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>CaO Typical	
      
                         </span> </td>
                         <td>{data1.CaO_Typical}</td>
                         <td id="td-right"><span>CaO Rejection	
                         </span> </td>
                         <td>{data1.CaO_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>P2O5 Typical	
      
                         </span> </td>
                         <td>{data1.P2O5_Typical}</td>
                         <td id="td-right"><span>P2O5 Rejection	
                         </span> </td>
                         <td>{data1.P2O5_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>SO3 Typical	
      
                         </span> </td>
                         <td>{data1.SO3_Typical}</td>
                         <td id="td-right"><span>SO3 Rejection	
                         </span> </td>
                         <td>{data1.SO3_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>MgO Typical	
      
                         </span> </td>
                         <td>{data1.MgO_Typical}</td>
                         <td id="td-right"><span>MgO Rejection	
                         </span> </td>
                         <td>{data1.MgO_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>TiO2 Typical	
      
                         </span> </td>
                         <td>{data1.TiO2_rejection}</td>
                         <td id="td-right"><span>TiO2 Rejection	
                         </span> </td>
                         <td>{data1.TiO2_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>NA2O Typical	
      
                         </span> </td>
                         <td>{data1.NA2O_Typical}</td>
                         <td id="td-right"><span>NA2O Rejection	
                         </span> </td>
                         <td>{data1.NA2O_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>K2O Typical	
      
                         </span> </td>
                         <td>{data1.K2O_Typical}</td>
                         <td id="td-right"><span>K2O Rejection	
                         </span> </td>
                         <td>{data1.K2O_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Mn3O4 Typical	
      
                         </span> </td>
                         <td>{data1.KMn3O4_Typical}</td>
                         <td id="td-right"><span>Mn3O4 Rejection	
                         </span> </td>
                         <td>{data1.Mn3O4_rejection}</td>
   
                       </tr>
                        
                       </tbody>
                       </table>
   
                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">TRACE ELEMENT, ppm Air Dried in Coal</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>TE Antimony Sb Typical	
      
                         </span> </td>
                         <td>{data1.te_antimony_sb_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Antimony Sb Rejection	
                         </span> </td>
                         <td>{data1.te_antimony_sb_rejection_in_air_dried_coal}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Arsenic As Typical	
      
                         </span> </td>
                         <td>{data1.te_arsenic_as_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Arsenic As Rejection	
                         </span> </td>
                         <td>{data1.te_arsenic_as_rejection_in_air_dried_coal}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Beryllium Be Typical	
      
                         </span> </td>
                         <td>{data1.te_beryllium_be_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Beryllium Be Rejection	
                         </span> </td>
                         <td>{data1.te_beryllium_be_rejection_in_air_dried_coal}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Boron B Typical	
      
                         </span> </td>
                         <td>{data1.te_boron_b_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Boron B Rejection	
                         </span> </td>
                         <td>{data1.te_boron_b_rejection_in_air_dried_coal}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Cadmium Cd Typical	
      
                         </span> </td>
                         <td>{data1.te_cadmium_cd_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Cadmium Cd Rejection	
                         </span> </td>
                         <td>{data1.te_cadmium_cd_rejection_in_air_dried_coal}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Chromium Cr Typical	
      
                         </span> </td>
                         <td>{data1.te_chromium_cr_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Chromium Cr Rejection	
                         </span> </td>
                         <td>{data1.te_chromium_cr_rejection_in_air_dried_coal}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Chlorine Cl Typical	
      
                         </span> </td>
                         <td>{data1.te_chlorine_cl_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Chlorine Cl Rejection	
                         </span> </td>
                         <td>{data1.te_chlorine_cl_rejection_in_air_dried_coal}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Copper Cu Typical	
      
                         </span> </td>
                         <td>{data1.te_copper_cu_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Copper Cu Rejection	
                         </span> </td>
                         <td>{data1.te_copper_cu_rejection_in_air_dried_coal}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Fluorine F Typical	
      
                         </span> </td>
                         <td>{data1.te_fluorine_f_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Fluorine F Rejection	
                         </span> </td>
                         <td>{data1.te_fluorine_f_rejection_in_air_dried_coal}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Lead Pb Typical	
      
                         </span> </td>
                         <td>{data1.te_lead_pb_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Lead Pb Rejection	
                         </span> </td>
                         <td>{data1.te_lead_pb_rejection_in_air_dried_coal}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Mercury Hg Typical	
      
                         </span> </td>
                         <td>{data1.te_mercury_hg_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Mercury Hg Rejection	
                         </span> </td>
                         <td>{data1.te_mercury_hg_rejection_in_air_dried_coal}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Molybdenum Mo Typical	
      
                         </span> </td>
                         <td>{data1.te_molybdenum_mo_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Molybdenum Mo Rejection	
                         </span> </td>
                         <td>{data1.te_molybdenum_mo_rejection_in_air_dried_coal}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Phosporous P Typical	
      
                         </span> </td>
                         <td>{data1.te_phosphorus_p_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Phosporous P Rejection	
                         </span> </td>
                         <td>{data1.te_phosphorus_p_rejection_in_air_dried_coal}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Selenium Se Typical	
      
                         </span> </td>
                         <td>{data1.te_selenium_se_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Selenium Se Rejection	
                         </span> </td>
                         <td>{data1.te_selenium_se_rejection_in_air_dried_coal}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Vanadium V Typical	
      
                         </span> </td>
                         <td>{data1.te_vanadium_v_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Vanadium V Rejection	
     
   </span> </td>
                         <td>{data1.te_vanadium_v_rejection_in_air_dried_coal}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>TE Zinc Zn Typical	
      
                         </span> </td>
                         <td>{data1.te_zinc_zn_typical_in_air_dried_coal}</td>
                         <td id="td-right"><span>TE Zinc Zn Rejection	
                         </span> </td>
                         <td>{data1.te_zinc_zn_rejection_in_air_dried_coal}</td>
   
                       </tr>
                        
                       </tbody>
                       </table>
   
   
                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">Sizing, %</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Size (0-50 mm) Typical	
      
                         </span> </td>
                         <td>{data1.size_0_to_50mm_typical}</td>
                         <td id="td-right"><span>Size (0-50 mm) Rejection	
                         </span> </td>
                         <td>{data1.size_0_to_50mm_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Above 50 mm Typical	
      
                         </span> </td>
                         <td>{data1.above_0_to_50mm_typical}</td>
                         <td id="td-right"><span>Above 50 mm Rejection	
                         </span> </td>
                         <td>{data1.above_0_to_50mm_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Under 2 mm Typical	
      
                         </span> </td>
                         <td>{data1.under_2mm_typical}</td>
                         <td id="td-right"><span>Under 2 mm Rejection	
                         </span> </td>
                         <td>{data1.under_2mm_rejection}</td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Size Above 2 mm Typical	
      
                         </span> </td>
                         <td>{data1.size_above_2mm_typical}</td>
                         <td id="td-right"><span>Size Above 2 mm Rejection	
                         </span> </td>
                         <td>{data1.size_above_2mm_rejection}</td>
   
                       </tr>
                        
                       </tbody>
                       </table>
   
                       <div className="col-md-12" id='adatas'>
                   <h4 className="heading">Testing Method</h4>
                   <hr></hr>
                 </div>
                 <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right"><span>Testing Method	
      
                         </span> </td>
                         <td>{data1.testing_method}</td>
                         <td id="td-right"><span></span> </td>
                         <td></td>
   
                       </tr>
                       <tr>
                         <td id="td-right"><span>Created By	
     
      
                         </span> </td>
                         <td>{data1.created_by}</td>
                         <td id="td-right"><span>Last Modified By	
     
      
      </span> </td>
      <td>{data1.last_modified_by}</td>
   
                       </tr>
                        
                       </tbody>
                       </table>
   

            <p className="edit-btn">
          
         <button className="btn btn-primary" onClick={handleEditClick}>
         Edit
         </button>
       </p> 
        <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading">History Information</h4>
                    <hr></hr>
                  </div>
                  <table id="history" className="">
                    <thead>
                      <tr>
                        <th>Field Name</th>
                        <th>New Value</th>
                        <th>Old Value</th>
                        <th>Changed By</th>
                        <th>Change Time</th>
                      </tr>
                    </thead>

                    {history.length > 0 ? (
                      <tbody>
                        {history.map((y, i) => (
                          <tr>
                            <td>{y.field_name}</td>
                            <td>{y.new_value}</td>
                            <td>{y.old_value}</td>
                            <td>{y.changed_by}</td>
                            <td>{y.change_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td ></td>
                          <td></td>
                          <td >No data available</td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </Row>
                <div className="edit-delete">
                                      <span id="edit" onClick={handleEditClick}>
                                        Edit
                                      </span>
                                      <span>
                                        <Link to={`/opportunity/quote-view/${data1.offer_id}`}>
                                          {" "}
                                          Cancel
                                        </Link>
                                      </span>
                                    </div>
            </div>
      )}
      <Footer />
    </div>
  );
};

export default ViewQuote;
