

import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { AES, enc } from 'crypto-js';
import Header from '../Header' 
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Footer from '../Footer' 
import { API_URL } from '../config';
import $ from 'jquery';
import axios from "axios";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function PricebookEditView() {
  const params=useParams();
  const ids=params.id;
  console.log(ids)
const location =useLocation()
const{pageID,accountIds}=location.state;
console.log(accountIds,'**',pageID)
  const [datas, setDatas]=useState({})
  const [inputDatas, setInputDatas]=useState([])
const [accountName, setAccountName]=useState([])
const [operators, setOperator]=useState([])
const [values, setValues]=useState([])
const [filterConditions, setFilterConditions] = useState();
//  const [filterConditions, setFilterConditions] = useState([    
//   { input: "account_name", operator: "equals", values: ['Prakruthi Resources - Saii Resources'] },

// ]);
// const fetchData6 = async () => {
//   try {
//     const response = await axios.get(API_URL+'get/accountcreateview_byID/'+pageID, {
//       headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
//     );
//     const data = response;
//     console.log(data)
    
// } catch (error) {
//     console.error(error);
//   }
// };
// useEffect(() => {

//   fetchData6();
// }, [decryptedAccessToken]);


  const fetchData5 = async () => {
    try {
      const response = await axios.get(API_URL+'get/pricebook_createview_byID/'+pageID, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response;
      var inputData=response.data.pricebook_view[0];
//       const cleanedJsonString = JSON.stringify(inputData)
//   .replace(/\\/g, '')
//   .replace(/"\[/g, '[')
//   .replace(/\]"/g, ']');
// const inputresult = JSON.parse(cleanedJsonString);
console.log(data)
setInputDatas(inputData)
setAccountName(inputData.fields_to_display)
setSelectedFields(inputData.fields_to_display)
setOperator(inputData.filter_fields)
console.log(data)
// const test=inputresult.filter_fields     

const initialFilterConditions = inputData.filter_fields.map((x) => ({
  input: x.input,
  operator: x.operator,
  values:  x.values.map((y) => y)
}));
// console.log(initialFilterConditions)
console.log("******************************",initialFilterConditions);

setFilterConditions(initialFilterConditions)

      setDatas(inputData)
      // console.log(inputresult)
      setFormData({
        // accounts:[accountIds],
        view_name: inputData.view_name || '',
        unique_name: inputData.unique_name || '',
        owner: inputData.owner || '',
        all_price_book: false,
        my_price_book:true,
        id:parseInt(pageID)
      });
} catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {

    fetchData5();
  }, [decryptedAccessToken]);



  const navigate = useNavigate();
  
 

  const [account_keys, setaccount_keys] = useState([]);

  const [availableFields, setAvailableFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState(['account_name']);


 
  
  const [formData, setFormData] = useState({
    view_name: '',
    unique_name: '',
    all_price_book: '',
    my_price_book: false,

  });
 


  console.log(datas)
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleFieldSelection = (e) => {
    const{name, value}=e.target;
    // console.log(name,value,"*****************************")
    // const options = Array.from(e.target.options)
    //   .filter((option) => option.selected)
    //   .map((option) => option.value);
    // setSelectedOptions(options);
    setSelectedFields([...selectedFields,value])
  };

  const handleAddFields = () => {
    const newFields = selectedOptions.filter(
      (option) => !selectedFields.includes(option)
    );

    setSelectedFields((prevFields) => [...prevFields, ...newFields]);
    setAvailableFields((prevFields) =>
      prevFields.filter((field) => !newFields.includes(field))
    );
    setSelectedOptions([]);
  };

  const handleRemoveFields = () => {
    setAvailableFields((prevFields) => {
      const updatedFields = [...prevFields, ...selectedOptions].filter(
        (field, index, self) => index === self.findIndex((f) => f === field)
      );
      

      return updatedFields;
    });
    setSelectedFields((prevFields) =>
      prevFields.filter((field) => !selectedOptions.includes(field))
    );
    setSelectedOptions([]);
  };

  const handleFieldToggle = (field) => {

    if (selectedOptions.includes(field)) {
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((option) => option !== field)
      );
    } else {
      setSelectedOptions((prevOptions) => [...prevOptions, field]);
    }
  };

  const handleInputChanges = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: newValue })); 
    
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(API_URL+"get/pricebook_keys", {
          method: "GET",
        });

        if (response.ok) {
          const responseData = await response.json();
          
          setAvailableFields(responseData.fields);
        } else {
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchKeys = async () => {
      try {
        const response = await fetch(API_URL+"get/pricebook_keys", {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error ("Request failed");
        }

        const data = await response.json();
        setaccount_keys(data.fields);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchKeys();
  }, []);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFilterConditions = [...filterConditions];
    const updatedCondition = {
      ...updatedFilterConditions[index],
      [name]: name === "values" ? value.split(",") : value,
    };
    updatedFilterConditions[index] = updatedCondition;
    setFilterConditions(updatedFilterConditions);
  };


  // useEffect(() => {
  //   addFilter();
  // }, [decryptedAccessToken]);
  
  // const fetchDataSupplier = async () => {
  //   try {
  //     const response = await axios.get(API_URL+'all/accounts', {
  //       headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
  //     );
  //     const data = response.data;
  //     setAccountName(data)
  //      console.log('Supplier'+ JSON.stringify(data))
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }; 
console.log("*******************************",filterConditions)

  const addFilterCondition = () => {
    setFilterConditions([   
      ...filterConditions,
  { input: "", operator: "", values: [] },

]); 
// if (filterConditions.length < 1) {
//       setFilterConditions([
//         ...filterConditions,
//         { input: "", operator: "", values: [] },
//       ]);
//     } else {
//       alert("You can't add more than 10 filters.");
//     }
    
  };

  const removeFilterCondition = (index) => {
    const updatedFilterConditions = [...filterConditions];
    updatedFilterConditions.splice(index, 1);
    setFilterConditions(updatedFilterConditions);
  };
 
  const handleSubmit = async (e) => {
        e.preventDefault();
    const dataToSend = {
      ...formData,

      selectedFields,
      filterConditions: filterConditions,
    };
    const cleanedJsonString = JSON.stringify(dataToSend)
    .replace(/\\/g, '')
    .replace(/"\[/g, '[')
    .replace(/\]"/g, ']');
  const datatosending = JSON.parse(cleanedJsonString);
  
    if (filterConditions.length === 0) {
      alert("You should add filters.");
      return;
    }

    if (!formData.view_name) {
      alert("You should enter a view name.");
      return;
    }
console.log(JSON.stringify(datatosending))
    try {
      const response = await fetch(
        API_URL+"update/pricebook_records/"+pageID,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datatosending),
        }
      );

      if (response.ok) {
        
          toast.success("Create View Updated Successfully", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        
        const responseData = await response.json();
        console.log(responseData);
        window.location.href = '/pricebook/create-view-list';

        // navigate("/accounts/account-list/" );

      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
 
      
  return (

    <div className="addAccount">
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Edit View</h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Accounts / Edit View </li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div>  
    <div id='view-container'>
      <Row className="mb-3">
        <h4 className='heading'>Step 1: Enter View Name</h4>
       
            <Form.Group as={Col} md="12">
            <FloatingLabel
              controlId="floatingInput"
              label="View Name"
              className="mb-3"
              
              
            >
              <Form.Control
                type="text"
            name="view_name"
            placeholder="View Name"
            value={formData.view_name || ""}
            onChange={handleInputChanges} 
              />
              
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Unique Name"
            className="mb-3"
            
            
          >
            <Form.Control
          type="text"
          name="unique_name"
          value={formData.unique_name}
          onChange={handleInputChanges}
        placeholder="Unique Name"
            />
            
          </FloatingLabel>
        </Form.Group>
       
     
       
        </Row>
      
      <Row className="mb-3">
        <h4 className='heading'>Step 2: Specify Filter Criteria</h4>
        <p>Filter By Owner:</p>
        <Form.Group as={Col} md="12">
          
      <input
        className="form-check-input"
        type="checkbox"
        checked={formData.my_price_book}
        name="my_price_book"
        id="flexCheckDefault"
        onChange={handleInputChanges}
      />
      <label className="form-check-label" htmlFor="flexCheckDefault">
        My Pricebook
      </label>

        </Form.Group>
        <Form.Group as={Col} md="12">
          
      <input
        className="form-check-input"
        type="checkbox"
        checked={formData.all_price_book}
        name="all_price_book"
        id="flexCheckChecked"
        onChange={handleInputChanges}
      />
      <label className="form-check-label" htmlFor="flexCheckChecked">
        All Pricebook
      </label>
        </Form.Group>
        <p id='filter-by'>Filter By Additional Fields (Optional):</p>
        <Form.Group as={Col} md="12" className="account-field" id='view-filters'>
        {filterConditions?.map((condition, index) => (
  <div key={index}>
   
      <div >
       <select
            name='input'
            value={condition.input}
            onChange={(e) => handleInputChange(e, index)}
          >
          <option>Select Key</option>
          {account_keys?.map((key, keyIndex) => (
            <option key={keyIndex} value={key}>
              {key.replace(/_/g, " ")}
            </option>
          ))}
        </select>

        <select
          name="operator"
          value={condition.operator}
          onChange={(e) => handleInputChange(e, index)}
        >
          <option value="Operator">Operator</option>
          <option value="equals">equals</option>
          <option value="not_equals">not_equals</option>
          <option value="greater">greater</option>
          {/* ... other options ... */}
        </select>

        <input
            type="text"
            name="values"
            placeholder="Values"
            value={condition.values.join(",")}
            onChange={(e) => handleInputChange(e, index)}
          />
        <FaTrashAlt id='trash' onClick={() => removeFilterCondition(index)} />
      </div>
   
    
  </div>
))}


      <button onClick={addFilterCondition}>Add Filter</button>
      

  </Form.Group>
        </Row>
         <Row className="mb-3 available">
        <h4 className='heading'>Step 3: Select Fields to Display</h4>
        <Form.Group as={Col} md="6">
          <p>Available Fields</p>
          <select 
            multiple
            name="available_fields"
            value={selectedOptions}
            className='form-control selectedField availableFields'
            onChange={(e)=>handleFieldSelection(e)} 
          >
            {availableFields.map((field) => (
              <option key={field.id} value={field}>
              {field.replace(/_/g, " ")}
              </option>
            ))}
          </select>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <p>Selected Fields</p>
           <div
          className="container selectedFields"
          
        >
          
          {selectedFields.map((field) => (
            <>
            
            <div
              key={field}
              className='availableFields'
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                cursor: "pointer",
                
                padding: "0px",
                background: selectedOptions.includes(field)
                  ? "lightblue"
                  : "#e3e3e3",
              }}
              onClick={() => handleFieldToggle(field)}
            >
              
              {field.replace(/_/g, " ")}
              
            </div>
            </>
          ))}
        </div>
      
        </Form.Group>
        <Form.Group as={Col} id='add-remove' md="12">
      <button
        type="button"
        onClick={handleRemoveFields}
        disabled={!selectedFields.length}
      >
        Remove Selected Fields
      </button>

      <button
        type="button"
        onClick={handleAddFields}
        disabled={!selectedOptions.length}
      >
        Add Selected Fields
      </button>
  </Form.Group>
        </Row>
        <input type="button" className="addaccount-save" onClick={handleSubmit} value='Submit'/>
        
      
      <div className='successDiv'><p></p><p></p></div>
    </div>
    <Footer/>
    </div>
  );
}

export default PricebookEditView;

