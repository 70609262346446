import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap"; 
import Header from '../Header' 
import Footer from '../Footer' 
import { AES, enc } from 'crypto-js';
import $ from 'jquery' 
import { useParams  } from "react-router-dom"; 
function  Contactus(props) { 
  const params = useParams()

  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height() 
  const total_height=header+nav+footer;
  const window_height=body-total_height; 
  console.log(window_height)
  $(".contactAccount").css({"min-height":(window_height)+"px"})
const id=params.id
     const {register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = data =>{ 
      const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
          
      fetch('https://rwooduatpy.znapay.in/all/contact/add/'+id, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { Authorization: `Bearer ${decryptedAccessToken}`,
        'Content-Type': 'application/json' },
      })
        .then((response) => response.json())
        .then((response) => { 
          console.log(response)
          $('.contact-success').html('<p class="result">Data added</p>');  
        setTimeout(() => {
          $('.contact-success').hide();
          reset();
        }, 300); 
        })
         
        console.log(JSON.stringify(data))
      }
};
        
  return (

    <div className="contactAccount">
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add New Contact </h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Account / Add New Contact</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div>   
   <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
        <div className='col-md-6'>
        <h4 className='heading'>Contact Information</h4>
        <Form.Group
              as={Col}
              md="12"
              id='salutation' 
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Salutation">
      <Form.Select aria-label="Floating label select example"
      name="saluation" 
      placeholder="Salutation"
      defaultValue=''
      {...register("saluation", { required: true })}
      >
        <option value='' disabled>--Select--</option>
        <option value="MR">MR</option>
        <option value="Dr">Dr</option> 
      </Form.Select>
      {errors.saluation && <span>Required</span>}
    </FloatingLabel>
    <FloatingLabel
            controlId="floatingInput"
            label="First Name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="First Name"
              name="first_name"
              {...register("first_name", { required: true })}
            />
             {errors.first_name && <span>Required Firstname</span>}
          </FloatingLabel>
        
            </Form.Group>

        <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Lastname"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Lastname" 
            name="last_name"
            {...register("last_name", { required: true })}
            />
             {errors.last_name && <span>Required Lastname</span>}
          </FloatingLabel>
        </Form.Group>
         
        <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Birth Date"
            className="mb-3 birthDate"
          >
            
            <Form.Control type="date" 
            placeholder="Birth Date" 
            name="birthdate"
            {...register("birthdate", { required: true })}
            />
             {errors.birthdate && <span>Required Birth Date</span>}
          </FloatingLabel>
        </Form.Group>
       </div>
       <div className='col-md-6'>
       <Form.Group as={Col} md="12" id='contact-title'>
          <FloatingLabel
            controlId="floatingInput"
            label="Title"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Title" 
            name="title"
            {...register("title", { required: true })}
            />
             {errors.title && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Contact Name"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Contact Name" 
            name="contact_name"
            {...register("contact_name", { required: true })}
            />
             {errors.contact_name && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Reports to"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Reports to" 
            name="reports_to"
            {...register("reports_to", { required: true })}
            />
             {errors.reports_to && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Contact Email"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Contact Email" 
            name="contact_email"
            {...register("contact_email", { required: true })}
            />
             {errors.contact_email && <span>Required Email</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Contact Mobile"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Contact Mobile" 
            name="contact_mobile"
            {...register("contact_mobile", { required: true })}
            />
             {errors.contact_mobile && <span>Required Mobile</span>}
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Contact Owner"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Contact Owner" 
            name="contact_owner"
            {...register("contact_owner", { required: true })}
            />
             {errors.contact_owner && <span>Required Mobile</span>}
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Department"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Department" 
            name="department"
            {...register("department", { required: true })}
            />
             {errors.department && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Lead Source"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Lead Source" 
            name="lead_source"
            {...register("lead_source", { required: true })}
            />
             {errors.lead_source && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Mail Street"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Mail Street" 
            name="mail_street"
            {...register("mail_street", { required: true })}
            />
             {errors.mail_street && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Mail postal code"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Mail postal code" 
            name="mail_postal_code"
            {...register("mail_postal_code", { required: true })}
            />
             {errors.mail_postal_code && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Mail city"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Mail city" 
            name="mail_city"
            {...register("mail_city", { required: true })}
            />
             {errors.mail_city && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Mail state"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Mail state" 
            name="mail_state"
            {...register("mail_state", { required: true })}
            />
             {errors.mail_state && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Mail country"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Mail country" 
            name="mail_country"
            {...register("mail_country", { required: true })}
            />
             {errors.mail_country && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        
       </div>
      </Row>
      <input type="submit"  className="contact-save" value='Save'/>
      <div className='contact-success'></div>
    </Form>
    <Footer/>
    </div>
  );
}

export default Contactus;
