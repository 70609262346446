import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import { ToastContainer, toast } from "react-toastify";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import RecentItem from "../recentitem/rececentItem";
import Select from 'react-select';
import { BiPlusCircle } from "react-icons/bi";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { API_URL } from '../config';
import Header from "../Header";
import Footer from "../Footer";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import $ from "jquery";
import { useParams } from "react-router-dom";
const ProductDetailPage = () => {
  // const params = useParams();
  // const id = params.id;
  const {id} = useParams() 
  const [isSubmitted, setIsSubmitted] = useState(false);
  const adminRole=localStorage.getItem('role')
  const { username, token } = useContext(TokenContext);
  const [quotes, setQuotes] = useState({});
  const [quotesProduct, setQuotesProduct] = useState([]);
  const [product, setProduct] = useState([]);
  const [buyer, setBuyer] = useState([]);
  const [tcf, setTcf] = useState([]); 
  
  const [productInfo, setProductInfo] = useState([]);


  
  const [productAccount, setProductaccount] = useState([]); // select dropdown
  const supplierDropdown = productAccount.map(item => ({
    value: item.account_name,
    label: item.account_name
  })); 

  const [supplierSelect, setSupplierSelect] = useState(null);
  const handleSupplier = (supplierSelect) => {
    setSupplierSelect(supplierSelect);
  };
  const clearSupplier = () => {
    setSupplierSelect(null);
  };

  const ClearIndicator = (props) => {
    return (
      <div onMouseDown={clearSupplier}>
        <span className="clears"><BiPlusCircle /></span>
      </div>
    );
  };

  const supplierDropdown1 = productAccount.map(item => ({
    value: item.account_name,
    label: item.account_name
  })); 

  const [supplierSelect1, setSupplierSelect1] = useState(null);
  const handleSupplier1 = (supplierSelect1) => {
    setSupplierSelect1(supplierSelect1);
  };
  const clearSupplier1 = () => {
    setSupplierSelect1(null);
  };

  const ClearIndicator1 = (props) => {
    return (
      <div onMouseDown={clearSupplier1}>
        <span className="clears"><BiPlusCircle /></span>
      </div>
    );
  };




  
  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", options).format(number);
  };
  
  const renderShipmentQty = (tcfValues) => {
    if (tcfValues > 0) {
      return <span className='sr'>{formatNumber(tcfValues)}</span>;
    } else if (tcfValues < 0) {
      return <span className='sr'>({formatNumber(Math.abs(tcfValues))})</span>;
    } else {
      return <span className='sr'>{formatNumber(tcfValues)}</span>;
    }
  };
  


  
  useEffect(() => {
   
    fetchData1();
  }, [decryptedAccessToken]);

  const fetchData1 = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/buyer_product', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const data = response.data;
      // console.log(data)
      // setDatas(data);
      $(document).ready(function(){
        // $('#tables').DataTable().destroy();
        // setTimeout(()=>{
          $('#tables').DataTable();
        // },500)
       })
    } catch (error) {
      console.error(error);
    }
  };
   
  const [getPrice, setGetPrice] = useState([]);
    // product account
    useEffect(() => {
      fetchDataAccount();
    }, [decryptedAccessToken]);
  
    const fetchDataAccount = async () => {
      try {
        const response = await axios.get(
          API_URL+"all/accounts",
          {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },
          }
        );
        const data = response.data.accounts; 
        // console.log(data)
        setProductaccount(data);
      } catch (error) {
        console.error(error);
      }
    };
  // price
  useEffect(() => {
    fetchDataPrice();
  }, [decryptedAccessToken]);

  const fetchDataPrice = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/price_items",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.priceitem;
      // console.log(data);
      setGetPrice(data);
    } catch (error) {
      console.error(error);
    }
  }; 
  const [data, setData] = useState({});  
  const [data1, setData1] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [lengths, setLength] = useState([]);  
  const [products, setProducts] = useState([]);  
  const [history, setHistory] = useState([]); 
  const [links,setLinks] =useState([]); 
  
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
 
  
  const [suppliers, setSupplier]=useState([]);
  
  useEffect(() => {
   
    fetchData5();
  }, [decryptedAccessToken]);

  const fetchData5 = async () => {
    try {
      const response = await axios.get(API_URL+'get/supplier', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data; 
      console.log(data)
      setSupplier(data);
       
    } catch (error) {
      console.error(error);
    }
  };

  const [activate, setActivate] = useState(null); // Initialize the state of the checkbox
  const [lockeds, setLockeds] = useState(null);
  const [corban, setCorban] = useState(null); // Initialize the state of the checkbox
  
  const chek_bok = data1.product_active;
  const chek_active = data1.short_term_available;
  const fix_corborn = productInfo.fixed_carbon_by_difference;
  useEffect(() => {
    if (lengths > 0) {
      setLockeds(chek_bok);
      setActivate(chek_active);
      setCorban(fix_corborn);
    }
  }, [lengths,data1, productInfo,chek_bok,chek_active,fix_corborn]);

  useEffect(() => {
    
  }, [lockeds, activate, corban]);

  useEffect(() => {
    setIsButtonEnabled(true);
  }, []);
  const handleCheckboxCorban = (e) => {
    setCorban(e.target.checked);
  };
  const handleCheckboxChange = (e) => {
    setLockeds(e.target.checked);
  };
  const handleCheckboxActive = (e) => {
    setActivate(e.target.checked);
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/products_byID/"+id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      console.log(response)
      if(response.status===200){
      const data = response.data;
      const products_info=response.data.product_info[0];
      
      
      setLinks(data)
      setHistory(data.history)
      setQuotes(data.product_datas[0]);
      setData1(data.product_datas[0]);
      setLength(response.data.product_datas.length)
      setQuotesProduct(data.quote_products);
      setProductInfo(products_info);
      setProduct(data.buyer_products);
      setTcf(data.tcf);
      setProducts(data.products)

      setTimeout(() => { 
      $("#quote-info").DataTable();
      $("#tcf-info").DataTable();
      $("#product").DataTable();
      $("#buyer").DataTable();
      $("#history").DataTable();
      $("#order-product").DataTable();
      
    }, 50);
  }
  else{
    toast.error("Check with fields", {
      position: "bottom-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",})
  }
      // console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken, id]);
  console.log(lengths)
   
const handleEditClick = () => {
  setIsEditMode(true);
  setEditedData(data);
  $("#quote-info").DataTable();
      $("#tcf-info").DataTable();
      $("#product").DataTable();
      $("#buyer").DataTable();
      $("#history").DataTable();
      $("#order-product").DataTable();
};const handleChange = (e) => {
  const { name, value } = e.target;
  setProductInfo((prevState) => ({
    ...prevState,
    [name]: value,
  }));
  setData1((prevState) => ({
    ...prevState,
    [name]: value,
  }));
};

// const handleChange = (e) => {
//   const { name, value } = e.target;

//   if (name.startsWith('product')) {
//     setProductInfo(prevState => ({
//       ...prevState,
//       [name.substring(7)]: value
//     }));
//   } else if (name.startsWith('data')) {
//     setData1(prevState => ({
//       ...prevState,
//       [name.substring(4)]: value
//     }));
//   }
// };
setTimeout(() => { 
  $("#quote-info").DataTable();
  $("#tcf-info").DataTable();
  $("#product").DataTable();
  $("#buyer").DataTable();
}, 50);
const handleCancelEdit = () => {
  setTimeout(() => { 
    $("#quote-info").DataTable();
    $("#tcf-info").DataTable();
    $("#product").DataTable();
    $("#buyer").DataTable();
  }, 50);

  setIsEditMode(false);
  setEditedData(data);
};

console.log(productInfo);
  const handleSaveEdit = (e) => {
    
    setIsSubmitted(true);
    $("#quote-info").DataTable();
      $("#tcf-info").DataTable();
      $("#product").DataTable();
      $("#buyer").DataTable();
      $("#history").DataTable();
      $("#order-product").DataTable();
    e.preventDefault();
    const responseData = {
      "product_name":data1.product_name,
// "product_account":supplierSelect1.value,
"product_account":data1.product_account,
"product_supplier":data1.product_supplier,
"product_description":data1.product_description,
"product_family":data1.product_family,
"product_shipping_term":data1.product_shipping_term,
"product_owner":data1.product_owner,
"product_active":lockeds,
"short_term_available":activate,
"product_price_basis":data1.product_price_basis,
"product_price_type":data1.product_price_type,
"product_port_of_loading":data1.product_port_of_loading,
"total_moisture_typical":productInfo.total_moisture_typical,
"total_moisture_minimum":productInfo.total_moisture_minimum,
"total_moisture_maximum":productInfo.total_moisture_maximum,
"moisture_maximum":productInfo.moisture_maximum,
"ash_typical_arb":productInfo.ash_typical_arb,
"ash_minimum_arb":productInfo.ash_minimum_arb,
"ash_maximum_arb":productInfo.ash_maximum_arb,
"sulphur_typical_arb":productInfo.sulphur_typical_arb,
"sulphur_minimum_arb":productInfo.sulphur_minimum_arb,
"sulphur_maximum_arb":productInfo.sulphur_maximum_arb,
"volatile_matter_typical_arb":productInfo.volatile_matter_typical_arb,
"volatile_matter_minimum_arb":productInfo.volatile_matter_minimum_arb,
"volatile_matter_maximum_arb":productInfo.volatile_matter_maximum_arb,
"moisture_typical":productInfo.moisture_typical,
"moisture_minimum":productInfo.moisture_minimum,
"ash_typical":productInfo.ash_typical,
"ash_minimum":productInfo.ash_minimum,
"ash_maximum":productInfo.ash_maximum,
"volatile_matter_typical":productInfo.volatile_matter_typical,
"volatile_matter_minimum":productInfo.volatile_matter_minimum,
"volatile_matter_maximum":productInfo.volatile_matter_maximum,
"fixed_carbon_by_difference":corban,
"fixed_carbon_typical":productInfo.fixed_carbon_typical,
"fixed_carbon_minimum":productInfo.fixed_carbon_minimum,
"fixed_carbon_maximum":productInfo.fixed_carbon_maximum,
"gross_air_dried_typical":productInfo.gross_air_dried_typical,
"gross_air_dried_min":productInfo.gross_air_dried_min,
"gross_air_dried_max":productInfo.gross_air_dried_max,
"gross_as_recieved_min":productInfo.gross_as_recieved_min,
"gross_as_recieved_max":productInfo.gross_as_recieved_max,
"net_as_recieved_min":productInfo.net_as_recieved_min,
"net_as_recieved_max":productInfo.net_as_recieved_max,
"net_as_recieved_typical":productInfo.net_as_recieved_typical,
"hgi_typical":productInfo.hgi_typical,
"hgi_min":productInfo.hgi_min,
"hgi_max":productInfo.hgi_max,
"carbon_typical":productInfo.carbon_typical,
"carbon_min":productInfo.carbon_min,
"carbon_max":productInfo.carbon_max,
"hydrogen_typical":productInfo.hydrogen_typical,
"hydrogen_min":productInfo.hydrogen_min,
"hydrogen_max":productInfo.hydrogen_max,
"nitrogen_typical":productInfo.nitrogen_typical,
"nitrogen_min":productInfo.nitrogen_min,
"nitrogen_max":productInfo.nitrogen_max,
"sulphur_typical":productInfo.sulphur_typical,
"sulphur_min":productInfo.sulphur_min,
"sulphur_max_ultimate_analysis":productInfo.sulphur_max_ultimate_analysis,
"oxygen_typical":productInfo.oxygen_typical,
"oxygen_min":productInfo.oxygen_min,
"oxygen_max":productInfo.oxygen_max,
"sulphur_typical_adb":productInfo.sulphur_typical_adb,
"sulphur_min_adb":productInfo.sulphur_min_adb,
"product_sulphur_max":productInfo.product_sulphur_max,
"initial_typical":productInfo.initial_typical,
"initial_minimum":productInfo.initial_minimum,
"initial_maximum":productInfo.initial_maximum,
"spherical_typical":productInfo.spherical_typical,
"spherical_minimum":productInfo.spherical_minimum,
"spherical_maximum":productInfo.spherical_maximum,
"hemispherical_typical":productInfo.hemispherical_typical,
"hemispherical_minimum":productInfo.hemispherical_minimum,
"hemispherical_maximum":productInfo.hemispherical_maximum,
"flow_typical":productInfo.flow_typical,
"flow_minimum":productInfo.flow_minimum,
"flow_maximum":productInfo.flow_maximum,
"oxidising_initial_typical":productInfo.oxidising_initial_typical,
"oxidising_initial_minimum":productInfo.oxidising_initial_minimum,
"oxidising_initial_maximum":productInfo.oxidising_initial_maximum,
"oxidising_spherical_typical":productInfo.oxidising_spherical_typical,
"oxidising_spherical_minimum":productInfo.oxidising_spherical_minimum,
"oxidising_spherical_maximum":productInfo.oxidising_spherical_maximum,
"oxidising_hemispherical_typical":productInfo.oxidising_hemispherical_typical,
"oxidising_hemispherical_minimum":productInfo.oxidising_hemispherical_minimum,
"oxidising_hemispherical_maximum":productInfo.oxidising_hemispherical_maximum,
"oxidising_flow_typical":productInfo.oxidising_flow_typical,
"oxidising_flow_minimum":productInfo.oxidising_flow_minimum,
"oxidising_flow_maximum":productInfo.oxidising_flow_maximum,
"SiO2_Typical":productInfo.SiO2_Typical,
// "te_beryllium_be_minimumte_beryllium_be_typical_in_air_dried_coal":productInfo.te_beryllium_be_minimumte_beryllium_be_typical_in_air_dried_coal,
"SiO2_Minimum":productInfo.SiO2_Minimum,
"SiO2_Maximum":productInfo.SiO2_Maximum,
"Al2O3_Typical":productInfo.Al2O3_Typical,
"Al2O3_Minimum":productInfo.Al2O3_Minimum,
"Al2O3_Maximum":productInfo.Al2O3_Maximum,
"Fe2O3_Typical":productInfo.Fe2O3_Typical,
"Fe2O3_Minimum":productInfo.Fe2O3_Minimum,
"Fe2O3_Maximum":productInfo.Fe2O3_Maximum,
"CaO_Typical":productInfo.CaO_Typical,
"CaO_Minimum":productInfo.CaO_Minimum,
"CaO_Maximum":productInfo.CaO_Maximum,
"MgO_Typical":productInfo.MgO_Typical,
"MgO_Minimum":productInfo.MgO_Minimum,
"MgO_Maximum":productInfo.MgO_Maximum,
"MnO_Typical":productInfo.MnO_Typical,
"MnO_Minimum":productInfo.MnO_Minimum,
"MnO_Maximum":productInfo.MnO_Maximum,
"TiO2_Typical":productInfo.TiO2_Typical,
"TiO2_Minimum":productInfo.TiO2_Minimum,
"TiO2_Maximum":productInfo.TiO2_Maximum,
"Na2O_Typical":productInfo.Na2O_Typical,
"Na2O_Minimum":productInfo.Na2O_Minimum,
"Na2O_Maximum":productInfo.Na2O_Maximum,
"K2O_Typical":productInfo.K2O_Typical,
"K2O_Minimum":productInfo.K2O_Minimum,
"K2O_Maximum":productInfo.K2O_Maximum,
"P2O5_Typical":productInfo.P2O5_Typical,
"P2O5_Minimum":productInfo.P2O5_Minimum,
"P2O5_Maximum":productInfo.P2O5_Maximum,
"SO3_Typical":productInfo.SO3_Typical,
"SO3_Minimum":productInfo.SO3_Minimum,
"SO3_Maximum":productInfo.SO3_Maximum,
"te_arsenic_as_typical_in_air_dried_coal":productInfo.te_arsenic_as_typical_in_air_dried_coal,
"te_arsenic_as_minimum_in_air_dried_coal":productInfo.te_arsenic_as_minimum_in_air_dried_coal,
"te_arsenic_as_maximum_in_air_dried_coal":productInfo.te_arsenic_as_maximum_in_air_dried_coal,
"te_antimony_sb_typical_in_air_dried_coal":productInfo.te_antimony_sb_typical_in_air_dried_coal,
"te_antimony_sb_minimum_in_air_dried_coal":productInfo.te_antimony_sb_minimum_in_air_dried_coal,
"te_antimony_sb_maximum_in_air_dried_coal":productInfo.te_antimony_sb_maximum_in_air_dried_coal,
"te_beryllium_be_typical_in_air_dried_coal":productInfo.te_beryllium_be_typical_in_air_dried_coal,
"te_beryllium_be_minimum_in_air_dried_coal":productInfo.te_beryllium_be_minimum_in_air_dried_coal,
"te_beryllium_be_maximum_in_air_dried_coal":productInfo.te_beryllium_be_maximum_in_air_dried_coal,
"te_boron_b_typical_in_air_dried_coal":productInfo.te_boron_b_typical_in_air_dried_coal,
"gross_as_recieved_typical":parseFloat(productInfo.gross_as_recieved_typical),
"Mn3O4_Typical":productInfo.Mn3O4_Typical,
"Mn3O4_Minimum":productInfo.Mn3O4_Minimum,
"Mn3O4_Maximum":productInfo.Mn3O4_Maximum,
"te_beryllium_be_minimum":productInfo.te_beryllium_be_minimum,
"te_beryllium_be_typical":productInfo.te_beryllium_be_typical,
"te_beryllium_be_maximum":productInfo.te_beryllium_be_maximum,
"te_boron_b_minimum_in_air_dried_coal":productInfo.te_boron_b_minimum_in_air_dried_coal,
"te_boron_b_maximum_in_air_dried_coal":productInfo.te_boron_b_maximum_in_air_dried_coal,
"te_cadmium_cd_typical_in_air_dried_coal":productInfo.te_cadmium_cd_typical_in_air_dried_coal,
"te_cadmium_cd_minimum_in_air_dried_coal":productInfo.te_cadmium_cd_minimum_in_air_dried_coal,
"te_cadmium_cd_maximum_in_air_dried_coal":productInfo.te_cadmium_cd_maximum_in_air_dried_coal,
"te_chromium_cr_typical_in_air_dried_coal":productInfo.te_chromium_cr_typical_in_air_dried_coal,
"te_chromium_cr_minimum_in_air_dried_coal":productInfo.te_chromium_cr_minimum_in_air_dried_coal,
"te_chromium_cr_maximum_in_air_dried_coal":productInfo.te_chromium_cr_maximum_in_air_dried_coal,
"te_copper_cu_typical_in_air_dried_coal":productInfo.te_copper_cu_typical_in_air_dried_coal,
"te_copper_cu_minimum_in_air_dried_coal":productInfo.te_copper_cu_minimum_in_air_dried_coal,
"te_copper_cu_maximum_in_air_dried_coal":productInfo.te_copper_cu_maximum_in_air_dried_coal,
"te_chlorine_cl_typical_in_air_dried_coal":productInfo.te_chlorine_cl_typical_in_air_dried_coal,
"te_chlorine_cl_minimum_in_air_dried_coal":productInfo.te_chlorine_cl_minimum_in_air_dried_coal,
"te_chlorine_cl_maximum_in_air_dried_coal":productInfo.te_chlorine_cl_maximum_in_air_dried_coal,
"te_fluorine_f_typical_in_air_dried_coal":productInfo.te_fluorine_f_typical_in_air_dried_coal,
"te_fluorine_f_minimum_in_air_dried_coal":productInfo.te_fluorine_f_minimum_in_air_dried_coal,
"te_fluorine_f_maximum_in_air_dried_coal":productInfo.te_fluorine_f_maximum_in_air_dried_coal,
"te_mercury_hg_typical_in_air_dried_coal":productInfo.te_mercury_hg_typical_in_air_dried_coal,
"te_mercury_hg_minimum_in_air_dried_coal":productInfo.te_mercury_hg_minimum_in_air_dried_coal,
"te_mercury_hg_maximum_in_air_dried_coal":productInfo.te_mercury_hg_maximum_in_air_dried_coal,
"te_molybdenum_mo_typical_in_air_dried_coal":productInfo.te_molybdenum_mo_typical_in_air_dried_coal,
"te_molybdenum_mo_minimum_in_air_dried_coal":productInfo.te_molybdenum_mo_minimum_in_air_dried_coal,
"te_molybdenum_mo_maximum_in_air_dried_coal":productInfo.te_molybdenum_mo_maximum_in_air_dried_coal,
"te_lead_pb_typical_in_air_dried_coal":productInfo.te_lead_pb_typical_in_air_dried_coal,
"te_lead_pb_minimum_in_air_dried_coal":productInfo.te_lead_pb_minimum_in_air_dried_coal,
"te_lead_pb_maximum_in_air_dried_coal":productInfo.te_lead_pb_maximum_in_air_dried_coal,
"te_phosphorus_p_typical_in_air_dried_coal":productInfo.te_phosphorus_p_typical_in_air_dried_coal,
"te_phosphorus_p_minimum_in_air_dried_coal":productInfo.te_phosphorus_p_minimum_in_air_dried_coal,
"te_phosphorus_p_maximum_in_air_dried_coal":productInfo.te_phosphorus_p_maximum_in_air_dried_coal,
"te_selenium_se_typical_in_air_dried_coal":productInfo.te_selenium_se_typical_in_air_dried_coal,
"te_selenium_se_minimum_in_air_dried_coal":productInfo.te_selenium_se_minimum_in_air_dried_coal,
"te_selenium_se_maximum_in_air_dried_coal":productInfo.te_selenium_se_maximum_in_air_dried_coal,
"te_vanadium_v_typical_in_air_dried_coal":productInfo.te_vanadium_v_typical_in_air_dried_coal,
"te_vanadium_v_minimum_in_air_dried_coal":productInfo.te_vanadium_v_minimum_in_air_dried_coal,
"te_vanadium_v_maximum_in_air_dried_coal":productInfo.te_vanadium_v_maximum_in_air_dried_coal,
"te_zinc_zn_typical_in_air_dried_coal":productInfo.te_zinc_zn_typical_in_air_dried_coal,
"te_zinc_zn_minimum_in_air_dried_coal":productInfo.te_zinc_zn_minimum_in_air_dried_coal,
"te_zinc_zn_maximum_in_air_dried_coal":productInfo.te_zinc_zn_maximum_in_air_dried_coal,
"te_arsenic_as_typical":productInfo.te_arsenic_as_typical,
"te_arsenic_as_minimum":productInfo.te_arsenic_as_minimum,
"te_arsenic_as_maximum":productInfo.te_arsenic_as_maximum,
"te_antimony_sb_typical":productInfo.te_antimony_sb_typical,
"te_antimony_sb_minimum":productInfo.te_antimony_sb_minimum,
"te_antimony_sb_maximum":productInfo.te_antimony_sb_maximum,
"te_boron_b_typical":productInfo.te_boron_b_typical,
"te_boron_b_minimum":productInfo.te_boron_b_minimum,
"te_boron_b_maximum":productInfo.te_boron_b_maximum,
"te_cadmium_cd_typical":productInfo.te_cadmium_cd_typical,
"te_cadmium_cd_minimum":productInfo.te_cadmium_cd_minimum,
"te_cadmium_cd_maximum":productInfo.te_cadmium_cd_maximum,
"te_chromium_cr_typical":productInfo.te_chromium_cr_typical,
"te_chromium_cr_minimum":productInfo.te_chromium_cr_minimum,
"te_chromium_cr_maximum":productInfo.te_chromium_cr_maximum,
"te_copper_cu_typical":productInfo.te_copper_cu_typical,
"te_copper_cu_minimum":productInfo.te_copper_cu_minimum,
"te_copper_cu_maximum":productInfo.te_copper_cu_maximum,
"te_chlorine_cl_typical":productInfo.te_chlorine_cl_typical,
"te_chlorine_cl_minimum":productInfo.te_chlorine_cl_minimum,
"te_chlorine_cl_maximum":productInfo.te_chlorine_cl_maximum,
"te_fluorine_f_typical":productInfo.te_fluorine_f_typical,
"te_fluorine_f_minimum":productInfo.te_fluorine_f_minimum,
"te_fluorine_f_maximum":productInfo.te_fluorine_f_maximum,
"te_mercury_hg_typical":productInfo.te_mercury_hg_typical,
"te_mercury_hg_minimum":productInfo.te_mercury_hg_minimum,
"te_mercury_hg_maximum":productInfo.te_mercury_hg_maximum,
"te_molybdenum_mo_typical":productInfo.te_molybdenum_mo_typical,
"te_molybdenum_mo_minimum":productInfo.te_molybdenum_mo_minimum,
"te_molybdenum_mo_maximum":productInfo.te_molybdenum_mo_maximum,
"te_lead_pb_typical":productInfo.te_lead_pb_typical,
"te_lead_pb_minimum":productInfo.te_lead_pb_minimum,
"te_lead_pb_maximum":productInfo.te_lead_pb_maximum,
"te_phosphorus_p_typical":productInfo.te_phosphorus_p_typical,
"te_phosphorus_p_minimum":productInfo.te_phosphorus_p_minimum,
"te_phosphorus_p_maximum":productInfo.te_phosphorus_p_maximum,
"te_selenium_se_typical":productInfo.te_selenium_se_typical,
"te_selenium_se_minimum":productInfo.te_selenium_se_minimum,
"te_selenium_se_maximum":productInfo.te_selenium_se_maximum,
"te_vanadium_v_typical":productInfo.te_vanadium_v_typical,
"te_vanadium_v_min":productInfo.te_vanadium_v_min,
"te_vanadium_v_max":productInfo.te_vanadium_v_max,
"te_zinc_zn_typical":productInfo.te_zinc_zn_typical,
"te_zinc_zn_min":productInfo.te_zinc_zn_min,
"te_zinc_zn_max":productInfo.te_zinc_zn_max,
"size_0_to_50mm_typical":productInfo.size_0_to_50mm_typical,
"size_0_to_50mm_min":productInfo.size_0_to_50mm_min,
"size_0_to_50mm_max":productInfo.size_0_to_50mm_max,
"above_0_to_50mm_typical":productInfo.above_0_to_50mm_typical,
"above_0_to_50mm_min":productInfo.above_0_to_50mm_min,
"above_0_to_50mm_max":productInfo.above_0_to_50mm_max,
"under_2mm_typical":productInfo.under_2mm_typical,
"under_2mm_min":productInfo.under_2mm_min,
"under_2mm_max":productInfo.under_2mm_max,
"size_above_2mm_typical":productInfo.size_above_2mm_typical,
"size_above_2mm_min":productInfo.size_above_2mm_min,
"size_above_2mm_max":productInfo.size_above_2mm_max,
"testing_method":productInfo.testing_method,
"created_by":productInfo.created_by,
"last_modified_by":productInfo.last_modified_by,

    }; 

    console.log(JSON.stringify(responseData));
   
    
    axios.put(`${API_URL}update/product/${id}`, responseData, {
      headers: {
        Authorization: `Bearer ${decryptedAccessToken}`,
        'Content-Type': 'application/json',
      },
    }) 
      .then((response) => {
       console.log(response);
        if(response.status===200){
          setIsSubmitted(false);
          toast.success("Product Master updated successfully", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",})
        setData(response);
        setIsEditMode(false);

        fetchData();
        }

      })
      .catch((error) => console.error(error));
  };
   
// edit page
 

// /get/file/<id>

  return (
    <div id="view-page">
      <Header />
      <div className="contactAccount full-container">
      <div className="row">
        <div className="col-md-2 recent-item">
          <RecentItem/>
        </div>
      
       
      
      {lengths>0 ?(
        <>
      {isEditMode ? (
          <div className="col-md-10 right-section"> 
           <div className="page-header" id="add-account">
          <div>




            <h2 className="main-content-title tx-24 mg-b-5">Product Master Detalis</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Product Master Detalis
              </li>
            </ol>
          </div>
        </div>
         <div className="tasks"> 
        <p className="edit-btn">
        <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value="Save"
      //disabled={isSubmitted}
    />
         <button
           className="btn btn-primary"
           onClick={handleCancelEdit}
         >
           Cancel
         </button>
       </p>
       
        
       <Row className="mb-3">
            <h4 className="heading">Information</h4>
             

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Product Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Product Name"
                  name="product_name"
                  className="error-validation"
                   defaultValue={data1.product_name }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Owner"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Owner"
                  name="product_owner"
                  className="owner-role"
                  defaultValue={adminRole} 
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            {/* <Form.Group as={Col} md="6">
            <FloatingLabel controlId="floatingSelect" label="Account" className="select-dropdown">
                 <Select
        options={supplierDropdown1}
        defaultValue={supplierSelect1}
        onChange={handleSupplier1} 
        components={{ ClearIndicator1 }}
        isClearable
      />
      </FloatingLabel>
            </Form.Group> */}
              <Form.Group as={Col} md="6">
                    <FloatingLabel
                      controlId="floatingSelect"
                      className="dropDown"
                      label="account"
                    >
                      <Form.Select
                        aria-label="Floating label select example"
                        name="product_account"
                        placeholder="account"
                        defaultValue={data1.product_account || ""}
                            onChange={handleChange}
                      >
                        <option value="">Select</option>
                        {productAccount.map((x) => {
                          return (
                            <option value={x.account_name}>
                              {x.account_name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                 
            <Form.Group as={Col} md="6" className="p-1">
             
              <input
                    type='checkbox'
                    name='product_active'
                    checked={lockeds} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange} // Handle the change event
                    className='mx-3'
                    
                  />
                   <label> 
                 Active
              </label>
            </Form.Group>
             {/* <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Product code"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Product Code"
                  name="product_code"
                  defaultValue={productInfo.product_code }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group> */}

<Form.Group as={Col} md="6">
                    <FloatingLabel
                      controlId="floatingSelect"
                      className="dropDown"
                      label="supplier"
                    >
                      <Form.Select
                        aria-label="Floating label select example"
                        name="product_supplier"
                        placeholder="supplier"
                        defaultValue={data1.product_supplier || ""}
                            onChange={handleChange}
                      >
                        <option value="">Select</option>
                        {suppliers.map((x) => {
                          return (
                            <option value={x.account_name}>
                              {x.account_name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
             <Form.Group as={Col} md="6">
                  
                  <input
                    type='checkbox'
                    name='short_term_available'
                    checked={activate} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxActive} // Handle the change event
                    className='mx-3'
                  />
                  <label>Product available for short time confirm	
                  </label>
                </Form.Group> 

                <Form.Group as={Col} md="6"  className="textarea-half">
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Product Description"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Product Description"
                  style={{ height: "200px!important" }}
                  name="product_description"
                  defaultValue={data1.product_description }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
           
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Price Basis GAR"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Product Price Basis"
                  name="product_price_basis"
                 defaultValue={data1.product_price_basis }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>

           
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Product Family"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="product_family"
                  placeholder="Product Family" 
                 defaultValue={data1.product_family }
                        onChange={handleChange}>
                  <option value="">--None--</option><option value="Arutmin Cargo">Arutmin Cargo</option><option value="KPC Cargo">KPC Cargo</option><option value="KBN Cargo">KBN Cargo</option><option value="Others">Others</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Shipping Term"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="product_shipping_term"
                  placeholder="Shipping Term" 
                  defaultValue={data1.product_shipping_term }
                        onChange={handleChange}>
                   <option value="">--None--</option><option value="FOB-B">FOB-B</option><option value="FOB-MV">FOB-MV</option><option value="CFR-MV">CFR-MV</option><option value="CIF-MV">CIF-MV</option><option value="FAS">FAS</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group> 
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="price type"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="product_price_type"
                  placeholder="Price type"
                  
                 defaultValue={data1.product_price_type }
                        onChange={handleChange}>
                  <option value="">Select</option>
                  

{getPrice.length>0?(
        <>
        {getPrice.map((x) => {
                    return (
                      <option defaultValue={x.price_type_name}>
                        {x.price_type_name}
                      </option>
                    );
                  })}
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                </Form.Select> 
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Port Of Loading"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Port Of Loading"
                  name="product_port_of_loading"
                  defaultValue={data1.product_port_of_loading }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
          </Row>
          
          <Row className="mb-3">
        <h4 className='heading'>%As Received Basis</h4>
        
                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Moisture Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Total Moisture Typical"
                      name="total_moisture_typical"
                        defaultValue={productInfo.total_moisture_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur typical arb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur typical arb"
                      name="sulphur_typical_arb"
                      defaultValue={productInfo.sulphur_typical_arb }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="total moisture minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="total moisture minimum"
                      name="total_moisture_minimum"
                      defaultValue={productInfo.total_moisture_minimum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur minimum arb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur minimum arb"
                      name="sulphur_minimum_arb"
                     defaultValue={productInfo.sulphur_minimum_arb }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="total moisture maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="total moisture maximum"
                      name="total_moisture_maximum"
                      defaultValue={productInfo.total_moisture_maximum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur maximum arb "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur maximum arb "
                      name="sulphur_maximum_arb"
                        defaultValue={productInfo.sulphur_maximum_arb }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <p className='space-hight'></p>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash typical arb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="ash typical arb"
                      name="ash_typical_arb"
                    defaultValue={productInfo.ash_typical_arb }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                  
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter typical arb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter typical arb"
                      name="volatile_matter_typical_arb"
                      defaultValue={productInfo.volatile_matter_typical_arb }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash minimum arb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="ash minimum arb "
                      name="ash_minimum_arb"
                      defaultValue={productInfo.ash_minimum_arb }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter minimum arb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter minimum arb"
                      name="volatile_matter_minimum_arb"
                     defaultValue={productInfo.volatile_matter_minimum_arb }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash maximum arb "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="ash maximum arb "
                      name="ash_maximum_arb"
                      defaultValue={productInfo.ash_maximum_arb }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                  <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter maximum arb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter maximum arb"
                      name="volatile_matter_maximum_arb"
                     defaultValue={productInfo.volatile_matter_maximum_arb }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
        </Row>
         
        <Row className="mb-3">
                <h4 className='heading'>Proximate Analysis %Air Dried Basis</h4>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="moisture typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="moisture typical"
                      name="moisture_typical"
                     defaultValue={productInfo.moisture_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter typical"
                      name="volatile_matter_typical"
                      defaultValue={productInfo.volatile_matter_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="moisture minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="moisture minimum"
                      name="moisture_minimum"
                      defaultValue={productInfo.moisture_minimum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter minimum"
                      name="volatile_matter_minimum"
                     defaultValue={productInfo.volatile_matter_minimum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="moisture maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="moisture maximum"
                      name="moisture_maximum"
                      defaultValue={productInfo.moisture_maximum}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter maximum"
                      name="volatile_matter_maximum"
                    defaultValue={productInfo.volatile_matter_maximum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <p className='space-hight'></p>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="ash typical"
                      name="ash_typical"
                      defaultValue={productInfo.ash_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                 <input
                    type='checkbox'
                    name='fixed_carbon_by_difference'
                    checked={corban} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxCorban} // Handle the change event
                    className='mx-3'
                    
                  />
                  <label>fixed carbon by difference</label>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="ash minimum"
                      name="ash_minimum"
                    defaultValue={productInfo.ash_minimum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="fixed carbon typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="fixed carbon typical"
                      name="fixed_carbon_typical"
                      defaultValue={productInfo.fixed_carbon_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="ash maximum"
                      name="ash_maximum"
                   defaultValue={productInfo.ash_maximum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="fixed carbon minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="fixed carbon minimum"
                      name="fixed_carbon_minimum"
                      defaultValue={productInfo.fixed_carbon_minimum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="6"></Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="fixed carbon maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="fixed carbon maximum"
                      name="fixed_carbon_maximum"
                      defaultValue={productInfo.fixed_carbon_maximum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
              </Row>   
              <Row className="mb-3">
                <h4 className='heading'>CALORIFIC VALUE, Keal/kg</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross Air Dried Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Gross Air Dried Typical"
                      name="gross_air_dried_typical"
                     defaultValue={productInfo.gross_air_dried_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="net as recieved typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="net as recieved typical"
                      name="net_as_recieved_typical"
                     defaultValue={productInfo.net_as_recieved_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="gross air dried minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="gross air dried minimum"
                      name="gross_air_dried_min"
                     defaultValue={productInfo.gross_air_dried_min }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="net as recieved minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="net as recieved minimum"
                      name="net_as_recieved_min"
                     defaultValue={productInfo.net_as_recieved_min }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="gross air dried maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="gross air dried maximum"
                      name="gross_air_dried_max"
                   defaultValue={productInfo.gross_air_dried_max }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="net as recieved maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="net as recieved maximum"
                      name="net_as_recieved_max"
                    defaultValue={productInfo.net_as_recieved_max }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <p className='space-height'></p>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="gross as recieved typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="gross as recieved typical"
                      name="gross_as_recieved_typical"
                      defaultValue={productInfo.gross_as_recieved_typical}
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="gross as recieved min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="gross as recieved min"
                      name="gross_as_recieved_min"
                    defaultValue={productInfo.gross_as_recieved_min }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="gross as recieved max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="gross as recieved max"
                      name="gross_as_recieved_max"
                      defaultValue={productInfo.gross_as_recieved_max }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group></Row> 
              <Row className="mb-3">
                <h4 className='heading'>HGI</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="HGI typical" 
                    name="hgi_typical"
                    defaultValue={productInfo.hgi_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="HGI minimum" 
                    name="hgi_min"
                     defaultValue={productInfo.hgi_min }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="HGI Maximum" 
                    name="hgi_max" 
                    defaultValue={productInfo.hgi_max }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group> 
                </Row>  
                <Row className="mb-3">
                <h4 className='heading'>ULTIMATE ANALYSIS %dry ash free basis</h4>
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Carbon typical"
                      name="carbon_typical"
                      defaultValue={productInfo.carbon_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur typical"
                      name="sulphur_typical"
                     defaultValue={productInfo.sulphur_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="carbon min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="carbon min"
                      name="carbon_min"
                        defaultValue={productInfo.carbon_min }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur min"
                      name="sulphur_min"
                      defaultValue={productInfo.sulphur_min }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="carbon max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="carbon max"
                      name="carbon_max"
                    defaultValue={productInfo.carbon_max }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur Maximum"
                      name="sulphur_max"
                      defaultValue={productInfo.sulphur_max }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <p className='space-height'></p>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hydrogen typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="hydrogen typical"
                      name="hydrogen_typical"
                     defaultValue={productInfo.hydrogen_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxygen typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxygen typical"
                      name="oxygen_typical"
                        defaultValue={productInfo.oxygen_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hydrogen min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="hydrogen min"
                      name="hydrogen_min"
                      
                        defaultValue={productInfo.hydrogen_min }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxygen min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxygen min"
                      name="oxygen_min"
                     defaultValue={productInfo.oxygen_min }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hydrogen max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="hydrogen max"
                      name="hydrogen_max"
                      defaultValue={productInfo.hydrogen_max }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxygen max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxygen max"
                      name="oxygen_max"
                      defaultValue={productInfo.oxygen_max }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>

                <p className="space-height"></p>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="nitrogen typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="nitrogen typical"
                      name="nitrogen_typical"
                       defaultValue={productInfo.nitrogen_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="nitrogen min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="nitrogen min"
                      name="nitrogen_min"
                      defaultValue={productInfo.nitrogen_min }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="nitrogen max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="nitrogen max"
                      name="nitrogen_max"
                       defaultValue={productInfo.nitrogen_max }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
              </Row>  
              <Row className="mb-3">
                <h4 className='heading'>SULPHUR %air dried basis</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical Adb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Sulphur Typical Adb"
                      name="sulphur_typical_adb"
                         defaultValue={productInfo.sulphur_typical_adb }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Minimum Adb"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Sulphur Minimum Adb" 
                    name="sulphur_min_adb"
                   defaultValue={productInfo.sulphur_min_adb }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                
                <Form.Group as={Col} md="6"></Form.Group>
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Sulphur Maximum" 
                    name="product_sulphur_max"
                     defaultValue={productInfo.product_sulphur_max }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                
              </Row>  
              <Row className="mb-3">
                <h4 className='heading'>ASH FUSION TEMPERATURE, DEG (C) Reducing</h4>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="initial typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="initial typical"
                      name="initial_typical"
                     defaultValue={productInfo.initial_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hemispherical typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="hemispherical typical"
                      name="hemispherical_typical"
                     defaultValue={productInfo.hemispherical_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="initial minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="initial minimum"
                      name="initial_minimum"
                       defaultValue={productInfo.initial_minimum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hemispherical minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="hemispherical minimum"
                      name="hemispherical_minimum"
                     defaultValue={productInfo.hemispherical_minimum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="initial maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="initial maximum"
                      name="initial_maximum"
                      defaultValue={productInfo.initial_maximum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hemispherical maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="hemispherical maximum"
                      name="hemispherical_maximum"
                      defaultValue={productInfo.hemispherical_maximum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>

                <p className="space-height"></p>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="spherical typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="spherical typical"
                      name="spherical_typical"
                      defaultValue={productInfo.spherical_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="flow typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="flow typical"
                      name="flow_typical"
                      defaultValue={productInfo.flow_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="spherical minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="spherical minimum"
                      name="spherical_minimum"
                             defaultValue={productInfo.spherical_minimum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="flow minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="flow minimum"
                      name="flow_minimum"
                       defaultValue={productInfo.flow_minimum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="spherical maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="spherical maximum"
                      name="spherical_maximum"
                        defaultValue={productInfo.spherical_maximum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="flow maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="flow maximum"
                      name="flow_maximum"
                       defaultValue={productInfo.flow_maximum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                



                 </Row>
                 <Row className="mb-3">
                <h4 className='heading'>ASH FUSION TEMPERATURE, Deg C Oxidizing</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Deformtion Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidizing Initial Deformtion Typical"
                      name="oxidizing_initial_typical"
                        defaultValue={productInfo.oxidizing_initial_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising hemispherical typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising hemispherical typical"
                      name="oxidising_hemispherical_typical"
                       defaultValue={productInfo.oxidising_hemispherical_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Deformation minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Initial Deformation minimum"
                      name="oxidising_initial_minimum"
                      defaultValue={productInfo.oxidising_initial_minimum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising hemispherical minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising hemispherical minimum"
                      name="oxidising_hemispherical_minimum"
                     defaultValue={productInfo.oxidising_hemispherical_minimum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Deformation maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Oxidizing Initial Deformation maximum"
                      name="oxidising_initial_maximum"
                      defaultValue={productInfo.oxidising_initial_maximum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising hemispherical maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising hemispherical maximum"
                      name="oxidising_hemispherical_maximum"
                       defaultValue={productInfo.oxidising_hemispherical_maximum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                <p className="space-height"></p>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising spherical typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising spherical typical"
                      name="oxidising_spherical_typical"
                     defaultValue={productInfo.oxidising_spherical_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising flow typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising flow typical"
                      name="oxidising_flow_typical"
                    defaultValue={productInfo.oxidising_flow_typical }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising spherical minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising spherical minimum"
                      name="oxidising_spherical_minimum"
                      defaultValue={productInfo.oxidising_spherical_minimum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising flow minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising flow minimum"
                      name="oxidising_flow_minimum"
                     defaultValue={productInfo.oxidising_flow_minimum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising spherical maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising spherical maximum"
                      name="oxidising_spherical_maximum"
                         defaultValue={productInfo.oxidising_spherical_maximum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising flow maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising flow maximum"
                      name="oxidising_flow_maximum"
                       defaultValue={productInfo.oxidising_flow_maximum }
                        onChange={handleChange}/>
                  </FloatingLabel>
                </Form.Group>
                </Row>
              
          <Row className="mb-3">
            <h4 className="heading">ASH ANALYSIS %Dry Basis in Ash</h4>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SiO2 Typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="SiO2 Typical"
                  name="SiO2_Typical"
                  defaultValue={productInfo.SiO2_Typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TiO2 Typical"
                className="mb-3"
              >
                <Form.Control   
                  type="number"
                  placeholder="TiO2 Typical"
                  name="TiO2_Typical"
                   defaultValue={productInfo.TiO2_Typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SiO2 Minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="SiO2 Minimum"
                  name="SiO2_Minimum"
                 defaultValue={productInfo.SiO2_Minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TiO2 Minimum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TiO2 Minimum"
                  name="TiO2_Minimum"
                 
                  defaultValue={productInfo.TiO2_Minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SiO2 Maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="SiO2 Maximum"
                  name="SiO2_Maximum"
                   defaultValue={productInfo.SiO2_Maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TiO2 Maximum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TiO2 Maximum"
                  name="TiO2_Maximum"
                  defaultValue={productInfo.TiO2_Maximum}
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Al2O3 Typical"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="Al2O3 Typical"
                  name="Al2O3_Typical" 
                   defaultValue={productInfo.Al2O3_Typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Na2O Typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="Na2O Typical"
                  name="Na2O_Typical" 
                   defaultValue={productInfo.Na2O_Typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Al2O3 Minimum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="Al2O3 Minimum"
                  name="Al2O3_Minimum" 
                  defaultValue={productInfo.Al2O3_Minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Na2O Minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="Na2O Minimum"
                  name="Na2O_Minimum"  
                  defaultValue={productInfo.Na2O_Minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Al2O3 Maximum"
                className="mb-3"
              >
                <Form.Control   
                  type="number"
                  placeholder="Al2O3 Maximum"
                  name="Al2O3_Minimum"  
                  defaultValue={productInfo.Al2O3_Minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Na2O Maximum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="Na2O Maximum"
                  name="Na2O_Maximum" 
                  defaultValue={productInfo.Na2O_Maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Fe2O3 Typical"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="Fe2O3 Typical"
                  name="Fe2O3_Typical"  
                  defaultValue={productInfo.Na2O_Maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="K2O Typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="K2O Typical"
                  name="K2O_Typical"defaultValue={productInfo.K2O_Typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Fe2O3 Minimum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="Fe2O3 Minimum"
                  name="Fe2O3_Minimum"  defaultValue={productInfo.Fe2O3_Minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="K2O Minimum"
                className="mb-3"
              >
                <Form.Control   
                  type="number"
                  placeholder="K2O Minimum"
                  name="K2O_Minimum" 
                  defaultValue={productInfo.K2O_Minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Fe2O3 Maximum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="Fe2O3 Maximum"
                  name="Fe2O3_Maximum" 

                  defaultValue={productInfo.Fe2O3_Maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="K2O Maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="K2O Maximum"
                  name="K2O_Maximum"

                  defaultValue={productInfo.K2O_Maximum }
                        onChange={handleChange}/>

              </FloatingLabel>
            </Form.Group>

            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="CaO Typical"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="CaO Typical"
                  name="CaO_Typical" 

                  defaultValue={productInfo.CaO_Typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="P2O5 Typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="P2O5 Typical"
                  name="P2O5_Typical" 

                  defaultValue={productInfo.P2O5_Typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="CaO Minimum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="CaO Minimum"
                  name="CaO_Minimum" 
                  defaultValue={productInfo.CaO_Minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="P2O5 Minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="P2O5 Minimum"
                  name="P2O5_Minimum" 
                  defaultValue={productInfo.P2O5_Minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="CaO Maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="CaO Maximum"
                  name="CaO_Maximum" defaultValue={productInfo.CaO_Maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="P2O5 Maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="P2O5 Maximum"
                  name="P2O5_Maximum" 
                  defaultValue={productInfo.P2O5_Maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>

            <p className="space-height"></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="MgO Typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="MgO Typical"
                  name="MgO_Typical" 
                  defaultValue={productInfo.MgO_Typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SO3 Typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="SO3 Typical"
                  name="SO3_Typical" 
                  defaultValue={productInfo.SO3_Typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="MgO Minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="MgO Minimum"
                  name="MgO_Minimum" 
                  defaultValue={productInfo.MgO_Minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SO3 Minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="SO3 Minimum"
                  name="SO3_Minimum" defaultValue={productInfo.SO3_Minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="MgO Maximum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="MgO Maximum"
                  name="MgO_Maximum"  defaultValue={productInfo.MgO_Maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SO3 Maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="SO3 Maximum"
                  name="SO3_Maximum" 
                   defaultValue={productInfo.SO3_Maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Mn3O4 Typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="Mn3O4 Typical"
                  name="Mn3O4_Typical" 

                   defaultValue={productInfo.Mn3O4_Typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Mn3O4 Minimum"
                className="mb-3"
              >
                <Form.Control   
                  type="number"
                  placeholder="Mn3O4 Minimum"
                  name="Mn3O4_Minimum" 

                   defaultValue={productInfo.Mn3O4_Minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Mn3O4 Maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="Mn3O4 Maximum"
                  name="Mn3O4_Maximum" 

                   defaultValue={productInfo.Mn3O4_Maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
          </Row>
          <Row className="mb-3">
            <h4 className="heading">Trace Elements, ppm Air Dried in Coal</h4>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE arsenic as typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE arsenic as typical"
                  name="te_arsenic_as_typical_in_air_dried_coal"
                  
                   defaultValue={productInfo.te_arsenic_as_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE fluorine f typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE fluorine f typical"
                  name="te_fluorine_f_typical_in_air_dried_coal"
                  defaultValue={productInfo.te_fluorine_f_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE rsenic as minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE arsenic as minimum"
                  name="te_arsenic_as_minimum_in_air_dried_coal"
                    defaultValue={productInfo.te_arsenic_as_minimum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE fluorine f minimum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE fluorine f minimum"
                  name="te_fluorine_f_minimum_in_air_dried_coal"
                   defaultValue={productInfo.te_fluorine_f_minimum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE arsenic as maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE arsenic as maximum"
                  name="te_arsenic_as_maximum_in_air_dried_coal"
                     defaultValue={productInfo.te_arsenic_as_maximum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE fluorine f maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE fluorine f maximum"
                  name="te_fluorine_f_maximum_in_air_dried_coal"
                  defaultValue={productInfo.te_fluorine_f_maximum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>

            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE antimony sb typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE antimony sb typical"
                  name="te_antimony_sb_typical_in_air_dried_coal"
                   defaultValue={productInfo.te_antimony_sb_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE mercury hg typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE mercury hg typical"
                  name="te_mercury_hg_typical_in_air_dried_coal"
                   defaultValue={productInfo.te_mercury_hg_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE antimony sb minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE antimony sb minimum"
                  name="te_antimony_sb_minimum_in_air_dried_coal"
                  defaultValue={productInfo.te_antimony_sb_minimum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE mercury hg minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE mercury hg minimum"
                  name="te_mercury_hg_minimum_in_air_dried_coal"
                  defaultValue={productInfo.te_mercury_hg_minimum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE antimony sb maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE antimony sb maximum"
                  name="te_antimony_sb_maximum_in_air_dried_coal"
                   defaultValue={productInfo.te_antimony_sb_maximum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE mercury hg maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE mercury hg maximum"
                  name="te_mercury_hg_maximum_in_air_dried_coal"
                     defaultValue={productInfo.te_mercury_hg_maximum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
            <FloatingLabel
                controlId="floatingInput"
                label="TE Beryllium Be Typical"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE Beryllium Be Typical"
                  name="te_beryllium_be_typical_in_air_dried_coal"
                     defaultValue={productInfo.te_beryllium_be_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE molybdenum mo typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE molybdenum mo typical"
                  name="te_molybdenum_mo_typical_in_air_dried_coal"
                     defaultValue={productInfo.te_molybdenum_mo_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
         

          <Form.Group as={Col} md="6">
            <FloatingLabel
                controlId="floatingInput"
                label="TE Beryllium Be Minimum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE Beryllium Be Minimum"
                  name="te_beryllium_be_minimum_in_air_dried_coal"
                    defaultValue={productInfo.te_beryllium_be_minimum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE molybdenum mo minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE molybdenum mo minimum"
                  name="te_molybdenum_mo_minimum_in_air_dried_coal"
                   defaultValue={productInfo.te_molybdenum_mo_minimum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
          <Form.Group as={Col} md="6">
            <FloatingLabel
                controlId="floatingInput"
                label="TE Beryllium Be Maximum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE Beryllium Be Maximum"
                  name="te_beryllium_be_maximum_in_air_dried_coal"
                  defaultValue={productInfo.te_beryllium_be_maximum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE molybdenum mo maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE molybdenum mo maximum"
                  name="te_molybdenum_mo_maximum_in_air_dried_coal"
                   defaultValue={productInfo.te_molybdenum_mo_maximum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
<p className='space-height'></p>
<Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE boron b typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE boron b typical"
                  name="te_boron_b_typical_in_air_dried_coal"
                   defaultValue={productInfo.te_boron_b_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE lead pb typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE lead pb typical"
                  name="te_lead_pb_typical_in_air_dried_coal"
                 defaultValue={productInfo.te_lead_pb_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE boron b minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE boron b minimum"
                  name="te_boron_b_minimum_in_air_dried_coal"
                  defaultValue={productInfo.te_boron_b_minimum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE lead pb minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE lead pb minimum"
                  name="te_lead_pb_minimum_in_air_dried_coal"
                    defaultValue={productInfo.te_lead_pb_minimum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE boron b maximum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE boron b maximum"
                  name="te_boron_b_maximum_in_air_dried_coal"
                  defaultValue={productInfo.te_boron_b_maximum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE lead pb maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE lead pb maximum"
                  name="te_lead_pb_maximum_in_air_dried_coal"
                   defaultValue={productInfo.te_lead_pb_maximum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE cadmium cd typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE cadmium cd typical"
                  name="te_cadmium_cd_typical_in_air_dried_coal"
                   defaultValue={productInfo.te_cadmium_cd_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE phosphorus p typical"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE phosphorus p typical"
                  name="te_phosphorus_p_typical_in_air_dried_coal"
                    defaultValue={productInfo.te_phosphorus_p_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE cadmium cd minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE cadmium cd minimum"
                  name="te_cadmium_cd_minimum_in_air_dried_coal"
                  defaultValue={productInfo.te_cadmium_cd_minimum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE phosphorus p minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE phosphorus p minimum"
                  name="te_phosphorus_p_minimum_in_air_dried_coal"
                   defaultValue={productInfo.te_phosphorus_p_minimum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE cadamium cd maximum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE cadamium cd maximum"
                  name="te_cadamium_cd_maximum_in_air_dried_coal"
                    defaultValue={productInfo.te_cadamium_cd_maximum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE phosphorus p maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE phosphorus p maximum"
                  name="te_phosphorus_p_maximum_in_air_dried_coal"
                 defaultValue={productInfo.te_phosphorus_p_maximum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chromium cr typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE chromium cr typical"
                  name="te_chromium_cr_typical_in_air_dried_coal"
                   defaultValue={productInfo.te_chromium_cr_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE selenium se typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE selenium se typical"
                  name="te_selenium_se_typical_in_air_dried_coal"
                  defaultValue={productInfo.te_selenium_se_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chromium cr minimum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE chromium cr minimum"
                  name="te_chromium_cr_minimum_in_air_dried_coal"
                    defaultValue={productInfo.te_chromium_cr_minimum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE selenium se minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE selenium se minimum"
                  name="te_selenium_se_minimum_in_air_dried_coal"
                  defaultValue={productInfo.te_selenium_se_minimum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chromium cr maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE chromium cr maximum"
                  name="te_chromium_cr_maximum_in_air_dried_coal"
                   defaultValue={productInfo.te_chromium_cr_maximum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE selenium se maximum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE selenium se maximum"
                  name="te_selenium_se_maximum_in_air_dried_coal"
                  defaultValue={productInfo.te_selenium_se_maximum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
<p className="space-height"></p>
<Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE copper cu typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE copper cu typical"
                  name="te_copper_cu_typical_in_air_dried_coal"
                 defaultValue={productInfo.te_copper_cu_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE vanadium v typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE vanadium v typical"
                  name="te_vanadium_v_typical_in_air_dried_coal"
                   defaultValue={productInfo.te_vanadium_v_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE copper cu minimum"
                className="mb-3"
              >
                <Form.Control   
                  type="number"
                  placeholder="TE copper cu minimum"
                  name="te_copper_cu_minimum_in_air_dried_coal"
                  defaultValue={productInfo.te_copper_cu_minimum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE vanadium v min"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE vanadium v min"
                  name="te_vanadium_v_minimum_in_air_dried_coal"
                  defaultValue={productInfo.te_vanadium_v_minimum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE copper cu maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE copper cu maximum"
                  name="te_copper_cu_maximum_in_air_dried_coal"
                   defaultValue={productInfo.te_copper_cu_maximum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE vanadium v max"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE vanadium v max"
                  name="te_vanadium_v_maximum_in_air_dried_coal"
                   defaultValue={productInfo.te_vanadium_v_maximum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chlorine cl typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE chlorine cl typical"
                  name="te_chlorine_cl_typical_in_air_dried_coal"
                    defaultValue={productInfo.te_chlorine_cl_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE zinc zn typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE zinc zn typical"
                  name="te_zinc_zn_typical_in_air_dried_coal"
                    defaultValue={productInfo.te_zinc_zn_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chlorine cl minimum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE chlorine cl minimum"
                  name="te_chlorine_cl_minimum_in_air_dried_coal"
                   defaultValue={productInfo.te_chlorine_cl_minimum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE zinc zn min"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE zinc zn min"
                  name="te_zinc_zn_minimum_in_air_dried_coal"
                 defaultValue={productInfo.te_zinc_zn_minimum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chlorine cl maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE chlorine cl maximum"
                  name="te_chlorine_cl_maximum_in_air_dried_coal"
                 defaultValue={productInfo.te_chlorine_cl_maximum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE zinc zn max"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE zinc zn max"
                  name="te_zinc_zn_maximum_in_air_dried_coal"
                    defaultValue={productInfo.te_zinc_zn_maximum_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            </Row>
            <Row className="mb-3">
            <h4 className="heading">Trace Elements, ppm Dried Basis</h4>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE arsenic as typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE arsenic as typical"
                  name="te_arsenic_as_typical"
                defaultValue={productInfo.te_arsenic_as_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE fluorine f typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE fluorine f typical"
                  name="te_fluorine_f_typical"
                  defaultValue={productInfo.te_fluorine_f_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE rsenic as minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE arsenic as minimum"
                  name="te_arsenic_as_minimum"
                 defaultValue={productInfo.te_arsenic_as_minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE fluorine f minimum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE fluorine f minimum"
                  name="te_arsenic_as_maximum"
                   defaultValue={productInfo.te_arsenic_as_maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE arsenic as maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE arsenic as maximum"
                  name="te_fluorine_f_maximum"
                   defaultValue={productInfo.te_fluorine_f_maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE fluorine f maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE fluorine f maximum"
                  name="te_antimony_sb_typical"
                  defaultValue={productInfo.te_antimony_sb_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>

            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE antimony sb typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE antimony sb typical"
                  name="te_mercury_hg_typical"
                   defaultValue={productInfo.te_mercury_hg_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE mercury hg typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE mercury hg typical"
                  name="te_antimony_sb_minimum"
                     defaultValue={productInfo.te_antimony_sb_minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE antimony sb minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE antimony sb minimum"
                  name="te_mercury_hg_minimum"
                  defaultValue={productInfo.te_mercury_hg_minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE mercury hg minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE mercury hg minimum"
                  name="te_antimony_sb_maximum"
                 defaultValue={productInfo.te_antimony_sb_maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE antimony sb maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE antimony sb maximum"
                  name="te_antimony_sb_maximum"
                 defaultValue={productInfo.te_antimony_sb_maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE mercury hg maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE mercury hg maximum"
                  name="te_mercury_hg_maximum"
                   defaultValue={productInfo.te_mercury_hg_maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
            <FloatingLabel
                controlId="floatingInput"
                label="TE Beryllium Be Typical"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE Beryllium Be Typical"
                  name="te_beryllium_be_typical"
                 defaultValue={productInfo.te_beryllium_be_typical}
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE molybdenum mo typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE molybdenum mo typical"
                  name="te_molybdenum_mo_typical"
                  defaultValue={productInfo.te_molybdenum_mo_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
         

          <Form.Group as={Col} md="6">
            <FloatingLabel
                controlId="floatingInput"
                label="TE Beryllium Be Minimum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE Beryllium Be Minimum"
                  name="te_beryllium_be_minimumte_beryllium_be_typical_in_air_dried_coal"
                   defaultValue={productInfo.te_beryllium_be_minimumte_beryllium_be_typical_in_air_dried_coal }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE molybdenum mo minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE molybdenum mo minimum"
                  name="te_molybdenum_mo_minimum"
                  defaultValue={productInfo.te_molybdenum_mo_minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
          <Form.Group as={Col} md="6">
            <FloatingLabel
                controlId="floatingInput"
                label="TE Beryllium Be Maximum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE Beryllium Be Maximum"
                  name="te_beryllium_be_maximum"
                   defaultValue={productInfo.te_beryllium_be_maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE molybdenum mo maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE molybdenum mo maximum"
                  name="te_molybdenum_mo_maximum"
                  defaultValue={productInfo.te_molybdenum_mo_maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
<p className='space-height'></p>
<Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE boron b typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE boron b typical"
                  name="te_boron_b_typical"
                   defaultValue={productInfo.te_boron_b_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE lead pb typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE lead pb typical"
                  name="te_lead_pb_typical"
                     defaultValue={productInfo.te_lead_pb_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE boron b minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE boron b minimum"
                  name="te_boron_b_minimum"
                   defaultValue={productInfo.te_boron_b_minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE lead pb minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE lead pb minimum"
                  name="te_lead_pb_minimum"
                  defaultValue={productInfo.te_lead_pb_minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE boron b maximum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE boron b maximum"
                  name="te_boron_b_maximum"
                    defaultValue={productInfo.te_boron_b_maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE lead pb maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE lead pb maximum"
                  name="te_lead_pb_maximum"
                    defaultValue={productInfo.te_lead_pb_maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE cadmium cd typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE cadmium cd typical"
                  name="te_cadmium_cd_typical"
                  defaultValue={productInfo.te_cadmium_cd_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE phosphorus p typical"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE phosphorus p typical"
                  name="te_phosphorus_p_typical"
                  defaultValue={productInfo.te_phosphorus_p_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE cadmium cd minimum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE cadmium cd minimum"
                  name="te_cadmium_cd_minimum"
                   defaultValue={productInfo.te_cadmium_cd_minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE phosphorus p minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE phosphorus p minimum"
                  name="te_phosphorus_p_minimum"
                    defaultValue={productInfo.te_phosphorus_p_minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE cadamium cd maximum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE cadamium cd maximum"
                  name="te_cadmium_cd_maximum"
                  defaultValue={productInfo.te_cadmium_cd_maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE phosphorus p maximum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE phosphorus p maximum"
                  name="te_phosphorus_p_maximum"
                   defaultValue={productInfo.te_phosphorus_p_maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chromium cr typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE chromium cr typical"
                  name="te_chromium_cr_typical"
                  defaultValue={productInfo.te_chromium_cr_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE selenium se typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE selenium se typical"
                  name="te_selenium_se_typical"
                  defaultValue={productInfo.te_selenium_se_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chromium cr minimum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE chromium cr minimum"
                  name="te_chromium_cr_minimum"
                    defaultValue={productInfo.te_chromium_cr_minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE selenium se minimum"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE selenium se minimum"
                  name="te_selenium_se_minimum"
                  defaultValue={productInfo.te_selenium_se_minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chromium cr maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE chromium cr maximum"
                  name="te_chromium_cr_maximum"
                    defaultValue={productInfo.te_chromium_cr_maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE selenium se maximum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE selenium se maximum"
                  name="te_selenium_se_maximum"
                  defaultValue={productInfo.te_selenium_se_maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
<p className="space-height"></p>
<Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE copper cu typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE copper cu typical"
                  name="te_copper_cu_typical"
                   defaultValue={productInfo.te_copper_cu_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE vanadium v typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="TE vanadium v typical"
                  name="te_vanadium_v_typical"
                 defaultValue={productInfo.te_vanadium_v_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE copper cu minimum"
                className="mb-3"
              >
                <Form.Control   
                  type="number"
                  placeholder="TE copper cu minimum"
                  name="te_copper_cu_minimum"
                  defaultValue={productInfo.te_copper_cu_minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE vanadium v min"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE vanadium v min"
                  name="te_vanadium_v_min"
                  defaultValue={productInfo.te_vanadium_v_min }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE copper cu maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE copper cu maximum"
                  name="te_copper_cu_maximum"
                  defaultValue={productInfo.te_copper_cu_maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE vanadium v max"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE vanadium v max"
                  name="te_vanadium_v_max"
                   defaultValue={productInfo.te_vanadium_v_max }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <p className='space-height'></p>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chlorine cl typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE chlorine cl typical"
                  name="te_chlorine_cl_typical"
                   defaultValue={productInfo.te_chlorine_cl_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE zinc zn typical"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE zinc zn typical"
                  name="te_zinc_zn_typical"
                  defaultValue={productInfo.te_zinc_zn_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chlorine cl minimum"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE chlorine cl minimum"
                  name="te_chlorine_cl_minimum"
                   defaultValue={productInfo.te_chlorine_cl_minimum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE zinc zn min"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="TE zinc zn min"
                  name="te_zinc_zn_min"
                  defaultValue={productInfo.te_zinc_zn_min }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chlorine cl maximum"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE chlorine cl maximum"
                  name="te_chlorine_cl_maximum"
                   defaultValue={productInfo.te_chlorine_cl_maximum }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE zinc zn max"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="TE zinc zn max"
                  name="te_zinc_zn_max"
                    defaultValue={productInfo.te_zinc_zn_max }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            </Row>
            <Row className="mb-3">
            <h4 className="heading">SIZING, %</h4>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size 0 to 50mm typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Size 0 to 50mm typical"
                  name="size_0_to_50mm_typical"
                  defaultValue={productInfo.size_0_to_50mm_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Under 2mm typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Under 2mm typical"
                  name="under_2mm_typical"
                   defaultValue={productInfo.under_2mm_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>{" "}
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size 0 to 50mm min"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="Size 0 to 50mm min"
                  name="size_0_to_50mm_min"
                  defaultValue={productInfo.size_0_to_50mm_min }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Under 2mm min"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="Under 2mm min"
                  name="under_2mm_min"
                   defaultValue={productInfo.under_2mm_min }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>{" "}
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size 0 to 50mm max"
                className="mb-3"
              >
                <Form.Control  
                  type="number"
                  placeholder="Size 0 to 50mm max"
                  name="size_0_to_50mm_max"
                   defaultValue={productInfo.size_0_to_50mm_max }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Under 2mm max"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="Under 2mm max"
                  name="under_2mm_max"
                     defaultValue={productInfo.under_2mm_max }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>{" "}
            <p className='space-height'></p>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Above 0 to 50mm typical"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="Above 0 to 50mm typical"
                  name="above_0_to_50mm_typical"
                  defaultValue={productInfo.above_0_to_50mm_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size above 2mm typical"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Size above 2mm typical"
                  name="size_above_2mm_typical"
                  defaultValue={productInfo.size_above_2mm_typical }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>{" "}
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Above 0 to 50mm min"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Above 0 to 50mm min"
                  name="above_0_to_50mm_min"
                  defaultValue={productInfo.above_0_to_50mm_min }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>{" "}
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size above 2mm min"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Size above 2mm min"
                  name="size_above_2mm_min"
                   defaultValue={productInfo.size_above_2mm_min }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Above 0 to 50mm max"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="Above 0 to 50mm max"
                  name="above_0_to_50mm_max"
                    defaultValue={productInfo.above_0_to_50mm_max }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>{" "}
            
           
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size above 2mm max"
                className="mb-3"
              >
                <Form.Control 
                  type="number"
                  placeholder="Size above 2mm max"
                  name="size_above_2mm_max"  
                  defaultValue={productInfo.size_above_2mm_max }
                        onChange={handleChange}/>
              </FloatingLabel>
            </Form.Group>
            </Row>
          <Row className="mb-3">
            <h4 className="heading">Testing Method</h4>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Testing Method"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="testing_method"
                 defaultValue={productInfo.testing_method }
                        onChange={handleChange}
                >
                  <option defaultValue="">Select </option>
                  <option defaultValue="According to ASTM Standard Method Unless Otherwise Stated">
                    According to ASTM Standard Method Unless Otherwise Stated
                  </option>
                  <option defaultValue="According to ISO Methods">
                    According to ISO Methods
                  </option>
                  <option defaultValue="According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM">
                    According to ISO Methods Except HGI, Trace Elements and Ash
                    Analysis to ASTM
                  </option>
                  <option defaultValue="According to ISO Methods Except HGI to ASTM">
                    According to ISO Methods Except HGI to ASTM
                  </option>
                  <option defaultValue="According to ISO Methods, Except HGI and Ash Analysis to ASTM">
                    According to ISO Methods, Except HGI and Ash Analysis to
                    ASTM
                  </option>
                  <option defaultValue="According to ISO Methods, Except HGI as per ASTM Test Method">
                    According to ISO Methods, Except HGI as per ASTM Test Method
                  </option>
                </Form.Select> 
              </FloatingLabel>
            </Form.Group>
          </Row>
         
 
       <p className="edit-btn">
       <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value="Save"
      //disabled={isSubmitted}
    />
         <button
           className="btn btn-primary"
           onClick={handleCancelEdit}
         >
           Cancel
         </button>
       </p>
       </div></div>
      ):(
        <div className="col-md-10 right-section">
           <div className="page-header" id="add-account">
          <div>




            <h2 className="main-content-title tx-24 mg-b-5">Product Master Detalis</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Product Master Detalis
              </li>
            </ol>
          </div>
        </div>
      <div className="tasks">
         <div className="edit-delete">
                  <span id="edit" onClick={handleEditClick}>
                    Edit
                  </span>
                  <span>
                  <Link to={'/productmaster/create-view-list'}> Cancel</Link>
                  </span>
                   
                  </div>
        <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">Product Master Details</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>Product Code</span>
                         </td>
                         <td>{data1.product_code}</td>
                         <td id="td-right">
                           <span>product Owner</span>
                         </td>
                         <td>{data1.product_owner}</td>
                       </tr>
  <tr>
                         <td id="td-right">
                           <span>Product Name</span>
                         </td>
                         <td>{data1.product_name}</td>
                         <td id="td-right">
                           <span>Active</span>
                         </td>
                         <td> <input
                    type='checkbox'
                    name='product_active'
                    checked={lockeds} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange} // Handle the change event
                    className='mx-3'
                    disabled={isButtonEnabled}
                  /></td>
                       </tr>
  <tr>
                         <td id="td-right">
                           <span>product account</span>
                         </td>
                         <td><Link to={`/accounts/${data1.account_id}`}>{data1.product_account}</Link></td>
                         <td id="td-right">
                           <span>Product available for short time confirm  </span>
                         </td>
                         <td> <input
                    type='checkbox'
                    name='short_term_available'
                    checked={activate} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxActive} // Handle the change event
                    className='mx-3'
                    disabled={isButtonEnabled}
                  /></td>
                       </tr>
  <tr>
                         <td id="td-right">
                           <span>Supplier</span>
                         </td>
                         <td><Link to={  "/accounts/" + data1.supplier_id} >{data1.product_supplier}</Link></td>
                         <td id="td-right">
                           <span>Price Basis GAR</span>
                         </td>
                         <td>{data1.product_price_basis}</td>
                       </tr><tr>
                         <td id="td-right">
                           <span>Product Description</span>
                         </td>
                         <td>{data1.product_description}</td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                       </tr>
                       <tr>
                         <td id="td-right">
                           <span>Product Family</span>
                         </td>
                         <td>{data1.product_family}</td>
                         <td id="td-right">
                           <span>Price Type</span>
                         </td>
                         <td>{data1.product_price_type}</td>
                       </tr><tr>
                         <td id="td-right">
                           <span>Shipping Term</span>
                         </td>
                         <td>{data1.product_shipping_term}</td>
                         <td id="td-right">
                           <span>Port of Loading</span>
                         </td>
                         <td>{data1.product_port_of_loading}</td>
                       </tr>
 
                     </tbody>
                   </table> 
        <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">%As Received Basis</h4>
          <hr></hr>
        </div>
          <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>Total Moisture Typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.total_moisture_typical)}</td>
                         <td id="td-right">
                           <span>Sulphur Typical ARB   </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.sulphur_typical_arb)}</td>
                       </tr> 
 
                       <tr>
                         <td id="td-right">
                           <span>Total Moisture Minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.total_moisture_minimum)}</td>
                         <td id="td-right">
                           <span>Sulphur Minimum ARB</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.sulphur_minimum_arb)}</td>
                       </tr> 
 
                       <tr>
                         <td id="td-right">
                           <span>Total Moisture Maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.total_moisture_maximum)}</td>
                         <td id="td-right">
                           <span>Sulphur Maximum ARB   </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.sulphur_maximum_arb)}</td>
                       </tr> 
  
 
                       <tr>
                         <td id="td-right">
                           <span>Ash Typical ARB</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.ash_typical_arb)}</td>
                         <td id="td-right">
                           <span>Volatile Matter Typical ARB</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.volatile_matter_typical_arb)}</td>
                       </tr> 
 
                       <tr>
                         <td id="td-right">
                           <span>Ash Minimum ARB</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.ash_minimum_arb)}</td>
                         <td id="td-right">
                           <span>Volatile Matter Minimum ARB</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.volatile_matter_minimum_arb)}</td>
                       </tr> 
 
                       <tr>
                         <td id="td-right">
                           <span>Ash Maximum ARB</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.ash_maximum_arb)}</td>
                         <td id="td-right">
                           <span>Volatile Matter Maximum ARB </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.volatile_matter_maximum_arb)}</td>
                       </tr> 
 
                       
                     </tbody>
                   </table>

        <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">Proximate Analysis %Air Dried Basis</h4>
          <hr></hr>
        </div>
       <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>Moisture Typical </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.moisture_typical)}</td>
                         <td id="td-right">
                           <span>Volatile Matter Typical </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.volatile_matter_typical)}</td>
                       </tr> 
  
 
                       <tr>
                         <td id="td-right">
                           <span>Moisture Minium</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.moisture_minimum)}</td>
                         <td id="td-right">
                           <span>Volatile Matter Minimum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.volatile_matter_minimum)}</td>
                       </tr> 
  
 
                       <tr>
                         <td id="td-right">
                           <span>Moisture Maximum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.moisture_maximum)}</td>
                         <td id="td-right">
                           <span>Volatile Matter Maximum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.volatile_matter_maximum)}</td>
                       </tr> 
  
    <tr className="space">
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td>
                         </td>
                       </tr> 


                       <tr>
                         <td id="td-right">
                           <span>Ash Typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.ash_typical)}</td>
                         <td id="td-right">
                           <span>Fixed Carbon By Difference </span>
                         </td>
                         <td><input
                    type='checkbox'
                    name='fixed_carbon_by_difference'
                    checked={corban} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxCorban} // Handle the change event
                    className='mx-3'
                    
                    disabled={isButtonEnabled}
                    
                  /></td>
                       </tr> 
  
 
                       <tr>
                         <td id="td-right">
                           <span>Ash Minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.ash_minimum)}</td>
                         <td id="td-right">
                           <span>Fixed Carbon Typical </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.fixed_carbon_typical)}</td>
                       </tr> 
  
 
                       <tr>
                         <td id="td-right">
                           <span>Ash Maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.ash_maximum)}</td>
                         <td id="td-right">
                           <span>Fixed Carbon Minimum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.fixed_carbon_minimum)}</td>
                       </tr> 
  
 
                       <tr>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span>Fixed Carbon Maximum   </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.fixed_carbon_maximum)}</td>
                       </tr> 
   
                       
                     </tbody>
                   </table>
        <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">CALORFIC VALUE, kcal/kg</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>Gross Air Dried Typical </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.gross_air_dried_typical)}</td>
                         <td id="td-right">
                           <span>Net as received Typical </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.net_as_recieved_typical)}</td>
                       </tr> 
   
                       
                       <tr>
                         <td id="td-right">
                           <span>Gross Air Dried Minimum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.gross_air_dried_min)}</td>
                         <td id="td-right">
                           <span>Net as received Minimum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.net_as_recieved_min)}</td>
                       </tr> 
   
                       
                       <tr>
                         <td id="td-right">
                           <span>Gross Air Dried Maximum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.gross_air_dried_max)}</td>
                         <td id="td-right">
                           <span>Net as received Maximum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.net_as_recieved_max)}</td>
                       </tr> 
   
                       
                       


                       <tr>
                         <td id="td-right">
                           <span>Gross as received Typical </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.gross_as_recieved_typical)}</td>
                         <td id="td-right">
                         </td>
                         <td></td>
                       </tr> 
   
                       <tr>
                       <td id="td-right">
                           <span>Gross as received Minimum  </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.gross_as_recieved_min)}</td>
                         <td id="td-right">
                         </td>
                         <td></td>
                        
                       </tr> 
                    
                       
                       <tr>

                       <td id="td-right">
                           <span>Gross as received Maximum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.gross_as_recieved_max)}</td>
                       <td id="td-right">
                           <span> </span>
                         </td>
                         <td></td>
                        
                        
                       </tr> 
                        
                     </tbody>
                   </table>
        <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">HGI</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>HGI Typical </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.hgi_typical)}</td>
                         <td id="td-right">
                           <span> </span>
                         </td>
                         <td></td>
                       </tr> 
    
                        
                       <tr>
                         <td id="td-right">
                           <span>HGI Minimum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.hgi_min)}</td>
                         <td id="td-right">
                           <span> </span>
                         </td>
                         <td></td>
                       </tr> 
    
                        
                       <tr>
                         <td id="td-right">
                           <span>HGI Maximum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.hgi_max)}</td>
                         <td id="td-right">
                           <span> </span>
                         </td>
                         <td></td>
                       </tr> 
    
                        
                     </tbody>
                   </table>

        <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">ULTIMATE ANALYSIS , %dry ash free basis</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>Carbon Typical </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.carbon_typical)}</td>
                         <td id="td-right">
                           <span>Sulphur Typical  </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.sulphur_typical)}</td>
                       </tr> 
    
                       
                       <tr>
                         <td id="td-right">
                           <span>Carbon Minimum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.carbon_min)}</td>
                         <td id="td-right">
                           <span>Sulphur Minimum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.sulphur_min  )}</td>
                       </tr> 
    
                       
                       <tr>
                         <td id="td-right">
                           <span>Carbon Maximum  </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.carbon_max)}</td>
                         <td id="td-right">
                           <span> Sulphur Maximum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.product_sulphur_max)}</td>
                       </tr> 
    
                       <tr className="space">
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td>
                         </td>
                       </tr> 

                       
                       <tr>
                         <td id="td-right">
                           <span>Hydrogen Typical </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.hydrogen_typical)}</td>
                         <td id="td-right">
                           <span>Oxygen Typical </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.oxygen_typical    )}</td>
                       </tr> 
    
                       
                       <tr>
                         <td id="td-right">
                           <span>Hydrogen Minimum  </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.hydrogen_min)}</td>
                         <td id="td-right">
                           <span>Oxygen Minimum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.oxygen_min)}</td>
                       </tr> 
    
                       
                       <tr>
                         <td id="td-right">
                           <span>Hydrogen Maximum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.hydrogen_max)}</td>
                         <td id="td-right">
                           <span> Oxygen Maximum  
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.oxygen_max)}</td>
                       </tr> 
    
                       <tr className="space">
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td>
                         </td>
                       </tr> 


                       <tr>
                         <td id="td-right">
                           <span>Nitrogen Typical </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.nitrogen_typical)}</td>
                         <td id="td-right">
                           <span> </span>
                         </td>
                         <td></td>
                       </tr> 
    
                       
                       <tr>
                         <td id="td-right">
                           <span>Nitrogen Minimum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.nitrogen_min)}</td>
                         <td id="td-right">
                           <span> </span>
                         </td>
                         <td></td>
                       </tr> 
    
                       
                       <tr>
                         <td id="td-right">
                           <span>Nitrogen Maximum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.nitrogen_max)}</td>
                         <td id="td-right">
                           <span> </span>
                         </td>
                         <td></td>
                       </tr> 
    
                        
    
                     </tbody>
                   </table>

        <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">SULPHUR, %air dried basis</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>SULPHUR Typical ADB </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.sulphur_typical_adb)}</td>
                         <td id="td-right">
                           <span>
  </span>
                         </td>
                         <td></td>
                       </tr> 
     
                       <tr>
                         <td id="td-right">
                           <span>SULPHUR Minimum ADB   
  </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.sulphur_min_adb)}</td>
                         <td id="td-right">
                           <span>   
  </span>
                         </td>
                         <td></td>
                       </tr> <tr>
                         <td id="td-right">
                           <span>SULPHUR Maximum ADB   
  </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.product_sulphur_max)}</td>
                         <td id="td-right">
                           <span>   
  </span>
                         </td>
                         <td></td>
                       </tr> 
     
                     </tbody>
                   </table>
                   <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">ASH FUSION TEMPERATURE, Deg C Reducing</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>Initial Typical </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.initial_typical)}</td>
                         <td id="td-right">
                           <span>Hemispherical Typical   
  </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.hemispherical_typical)}</td>
                       </tr> 
      
                       <tr>
                         <td id="td-right">
                           <span>Initial  Minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.initial_minimum)}</td>
                         <td id="td-right">
                           <span>Hemispherical Minimum 
   
  </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.hemispherical_minimum)}</td>
                       </tr> 
      
      
                       <tr>
                         <td id="td-right">
                           <span>Initial  Maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.initial_maximum)}</td>
                         <td id="td-right">
                           <span>Hemispherical Maximum 
   
  </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.hemispherical_minimum)}</td>
                       </tr> 
      


                       <tr className="space">
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td>
                         </td>
                       </tr> 


      
                       <tr>
                         <td id="td-right">
                           <span>Spherical Typical     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.spherical_typical)}</td>
                         <td id="td-right">
                           <span>Flow Typical     
   
  </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.flow_typical)}</td>
                       </tr> 
      
      
                       <tr>
                         <td id="td-right">
                           <span>Spherical Minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.spherical_minimum)}</td>
                         <td id="td-right">
                           <span>Flow Minimum     
   
  </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.flow_minimum)}</td>
                       </tr> 
      
      
                       <tr>
                         <td id="td-right">
                           <span>Spherical Maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.spherical_maximum)}</td>
                         <td id="td-right">
                           <span>Flow Maximum     
   
  </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.flow_maximum)}</td>
                       </tr> 
      
       
                     </tbody>
                   </table>

                   <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">ASH ANALYSIS, %Dry Basis in Ash</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>SiO2 Typical </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.SiO2_Typical)}</td>
                         <td id="td-right">
                           <span>TiO2 Typical     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.TiO2_Typical)}</td>
                       </tr> 
       
                       <tr>
                         <td id="td-right">
                           <span>SiO2 Minimum     
 </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.SiO2_Minimum)}</td>
                         <td id="td-right">
                           <span>TiO2 Minimum     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.TiO2_Minimum)}</td>
                       </tr> 
       
                       <tr>
                         <td id="td-right">
                           <span>SiO2 Maximum     
 </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.SiO2_Maximum)}</td>
                         <td id="td-right">
                           <span>TiO2 Maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.TiO2_Maximum)}</td>
                       </tr> 
       



  <tr className="space">
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td>
                         </td>
                       </tr> 




                       <tr>
                         <td id="td-right">
                           <span>Al2O3 Typical    
 </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.Al2O3_Typical)}</td>
                         <td id="td-right">
                           <span>NA2O Typical     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.Na2O_Typical)}</td>
                       </tr> 
       
                       <tr>
                         <td id="td-right">
                           <span>Al2O3 Minimum    
 </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.Al2O3_Minimum)}</td>
                         <td id="td-right">
                           <span>NA2O Minimum     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.Na2O_Minimum)}</td>
                       </tr> 
       
                       <tr>
                         <td id="td-right">
                           <span>Al2O3 Maximum </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.Al2O3_Minimum)}</td>
                         <td id="td-right">
                           <span>NA2O Maximum     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.Na2O_Maximum)}</td>
                       </tr> 
       


  <tr className="space">
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td>
                         </td>
                       </tr> 







                       <tr>
                         <td id="td-right">
                           <span>Fe2O3 Typical    
 </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.Fe2O3_Typical)}</td>
                         <td id="td-right">
                           <span>K2O Typical 
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.K2O_Typical)}</td>
                       </tr> 
       
                       <tr>
                         <td id="td-right">
                           <span>Fe2O3 Minimum    
 </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.Fe2O3_Minimum)}</td>
                         <td id="td-right">
                           <span>K2O Minimum 
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.K2O_Minimum)}</td>
                       </tr> 
       
                       <tr>
                         <td id="td-right">
                           <span>Fe2O3 Maximum    
 </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.Fe2O3_Maximum)}</td>
                         <td id="td-right">
                           <span>K2O Maximum 
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.K2O_Maximum)}</td>
                       </tr> 
       




  <tr className="space">
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td>
                         </td>
                       </tr> 


                       <tr>
                         <td id="td-right">
                           <span>CaO Typical 
 </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.CaO_Typical)}</td>
                         <td id="td-right">
                           <span>P2O5 Typical     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.P2O5_Typical)}</td>
                       </tr> 
       
                       <tr>
                         <td id="td-right">
                           <span>CaO Minimum 
 </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.CaO_Minimum)}</td>
                         <td id="td-right">
                           <span>P2O5 Minimum     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.P2O5_Minimum)}</td>
                       </tr> 
       
                       <tr>
                         <td id="td-right">
                           <span>CaO Maximum 
 </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.CaO_Maximum)}</td>
                         <td id="td-right">
                           <span>P2O5 Maximum     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.P2O5_Maximum)}</td>
                       </tr> 
       



  <tr className="space">
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td>
                         </td>
                       </tr> 





                       <tr>
                         <td id="td-right">
                           <span>MgO Typical 
 </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.MgO_Typical)}</td>
                         <td id="td-right">
                           <span>SO3 Typical 
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.SO3_Typical)}</td>
                       </tr> 

                       <tr>
                         <td id="td-right">
                           <span>MgO Minimum 
 </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.MgO_Minimum)}</td>
                         <td id="td-right">
                           <span>SO3 Minimum 
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.SO3_Minimum)}</td>
                       </tr> 

                       <tr>
                         <td id="td-right">
                           <span>MgO Maximum 
 </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.MgO_Maximum)}</td>
                         <td id="td-right">
                           <span>SO3 Maximum 
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.SO3_Maximum)}</td>
                       </tr> 




<tr className="space">
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td>
                         </td>
                       </tr> 




                       <tr>
                         <td id="td-right">
                           <span>Mn3O4 Typical    
 </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.Mn3O4_Typical)}</td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                       </tr>   <tr>
                         <td id="td-right">
                           <span>Mn3O4 Minimum    
   
 </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.Mn3O4_Minimum)}</td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                       </tr>   <tr>
                         <td id="td-right">
                           <span>Mn3O4 Maximum    
    
 </span>
                         </td>
                         <td>{renderShipmentQty(productInfo.Mn3O4_Maximum)}</td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                       </tr> 

                        
                     </tbody>
                   </table>

                   
                   <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">Trace Elements, ppm Air Dried in Coal</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>TE arsenic as typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_arsenic_as_typical_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE fluorine f typical     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_fluorine_f_typical_in_air_dried_coal)}</td>
                       </tr>  <tr>
                         <td id="td-right">
                           <span>TE arsenic as minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_arsenic_as_minimum_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE fluorine f minimum     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_fluorine_f_minimum_in_air_dried_coal)}</td>
                       </tr>  <tr>
                         <td id="td-right">
                           <span>TE arsenic as maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_arsenic_as_maximum_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE fluorine f maximum
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_fluorine_f_maximum_in_air_dried_coal)}</td>
                       </tr>
                       
                       <tr className="space">
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         
                       </tr>  
                        <tr>
                          
                         <td id="td-right">
                           <span>TE antimony sb typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_antimony_sb_typical_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE mercury hg typical     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_mercury_hg_typical_in_air_dried_coal)}</td>
                       </tr>  
                        <tr>
                         <td id="td-right">
                           <span>TE antimony sb minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_antimony_sb_minimum_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE mercury hg minimum     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_mercury_hg_minimum)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE antimony sb maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_antimony_sb_maximum_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE mercury hg maximum
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_mercury_hg_maximum_in_air_dried_coal)}</td>
                       </tr> 
                       
                       <tr className="space">
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE Beryllium Be Typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_beryllium_be_typical_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE molybdenum mo typical     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_molybdenum_mo_typical_in_air_dried_coal)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE Beryllium Be Minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_beryllium_be_minimum_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE molybdenum mo minimum     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_molybdenum_mo_minimum_in_air_dried_coal)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE Beryllium Be Maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_beryllium_be_maximum_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE molybdenum mo maximum    
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_molybdenum_mo_maximum_in_air_dried_coal)}</td>
                       </tr> 
                       
                       <tr className="space">
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE boron b typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_boron_b_typical_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE lead pb typical    
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_lead_pb_typical_in_air_dried_coal)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE boron b minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_boron_b_minimum_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE lead pb minimum     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_lead_pb_minimum_in_air_dried_coal)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE boron b maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_boron_b_maximum_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE lead pb maximum    
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_lead_pb_maximum_in_air_dried_coal)}</td>
                       </tr> 
                       
                       <tr className="space">
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE cadmium cd typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_cadmium_cd_typical_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE phosphorus p typical   
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_phosphorus_p_typical_in_air_dried_coal)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE cadmium cd minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_cadmium_cd_minimum_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE phosphorus p minimum    
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_phosphorus_p_minimum_in_air_dried_coal)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE cadamium cd maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_cadamium_cd_maximum_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE phosphorus p maximum    
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_phosphorus_p_maximum_in_air_dried_coal)}</td>
                       </tr> 
                       
                       <tr className="space">
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE chromium cr typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_chromium_cr_typical_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE selenium se typical     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_selenium_se_typical_in_air_dried_coal)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE chromium cr minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_chromium_cr_minimum_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE selenium se minimum    
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_selenium_se_minimum_in_air_dried_coal)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE chromium cr maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_chromium_cr_maximum_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE selenium se maximum    
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_selenium_se_maximum_in_air_dried_coal)}</td>
                       </tr> 
                       
                       <tr className="space">
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE copper cu typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_copper_cu_typical_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE vanadium v typical     
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_vanadium_v_typical_in_air_dried_coal)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE copper cu minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_copper_cu_minimum_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE vanadium v min
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_vanadium_v_minimum_in_air_dried_coal)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE copper cu maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_copper_cu_maximum_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE vanadium v max    
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_vanadium_v_maximum_in_air_dried_coal)}</td>
                       </tr> 
                       
                       <tr className="space">
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE chlorine cl typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_chlorine_cl_typical_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE zinc zn typical    
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_zinc_zn_typical_in_air_dried_coal)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE chlorine cl minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_chlorine_cl_minimum_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE zinc zn min   
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_zinc_zn_minimum_in_air_dried_coal)}</td>
                       </tr> 



                       <tr>
                         <td id="td-right">
                           <span>TE chlorine cl maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_chlorine_cl_maximum_in_air_dried_coal)}</td>
                         <td id="td-right">
                           <span>TE zinc zn max    
</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_zinc_zn_maximum_in_air_dried_coal)}</td>
                       </tr> 
                       

                       </tbody>
                       </table>
                       <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">Trace Elements, ppm Dried Basis</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>TE arsenic as typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_arsenic_as_typical)}</td>
                         <td id="td-right">
                           <span>TE fluorine f typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_fluorine_f_typical)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE arsenic as minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_arsenic_as_minimum)}</td>
                         <td id="td-right">
                           <span>TE fluorine f minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_fluorine_f_minimum)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE arsenic as maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_arsenic_as_maximum)}</td>
                          <td id="td-right">
                           <span>TE fluorine f maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_fluorine_f_maximum)}</td>
                       </tr> 
                       <tr>
                        
                         <td id="td-right">
                           <span>TE antimony sb typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_antimony_sb_typical)}</td>
                        
                         <td id="td-right">
                           <span>TE mercury hg typical</span>
                         </td>
                           <td>{renderShipmentQty(productInfo.te_mercury_hg_typical)}</td>
                       </tr>
                       <tr>
                       
                         <td id="td-right">
                           <span>TE antimony sb minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_antimony_sb_minimum)}</td>
                       
                         <td id="td-right">
                           <span>TE mercury hg minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_mercury_hg_minimum)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE antimony sb maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_antimony_sb_maximum)}</td>
                       
                         <td id="td-right">
                           <span>TE mercury hg maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_mercury_hg_maximum)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE Beryllium Be Typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_beryllium_be_typical)}</td>
                     
                         <td id="td-right">
                           <span>TE molybdenum mo typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_molybdenum_mo_typical)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE Beryllium Be Minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_beryllium_be_minimum)}</td>
                      
                         <td id="td-right">
                           <span>TE molybdenum mo minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_molybdenum_mo_minimum)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE Beryllium Be Maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_beryllium_be_minimumte_beryllium_be_typical_in_air_dried_coal)}</td>
                          <td id="td-right">
                           <span>TE molybdenum mo maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_molybdenum_mo_maximum)}</td>

                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>te  boron b typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_boron_b_typical)}</td>
                      
                         <td id="td-right">
                           <span>TE lead pb typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_lead_pb_typical)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE boron b minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_boron_b_minimum)}</td>
                        
                         <td id="td-right">
                           <span>TE lead pb minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_lead_pb_minimum)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE boron b maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_boron_b_maximum)}</td>
                       
                         <td id="td-right">
                           <span>TE lead pb maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_lead_pb_maximum)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE cadmium cd typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_cadmium_cd_typical)}</td>
                       
                         <td id="td-right">
                           <span>TE phosphorus p typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_phosphorus_p_typical)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE cadmium cd minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_cadmium_cd_minimum)}</td>
                      
                         <td id="td-right">
                           <span>TE phosphorus p minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_phosphorus_p_minimum)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE cadamium cd maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_cadmium_cd_maximum)}</td>
                       
                         <td id="td-right">
                           <span>TE phosphorus p maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_phosphorus_p_maximum)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE chromium cr typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_chromium_cr_typical)}</td>
                      
                         <td id="td-right">
                           <span>TE selenium se typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_selenium_se_typical)}</td>
                         </tr> 
                       <tr>

                         <td id="td-right">
                           <span>TE chromium cr minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_chromium_cr_minimum)}</td>
                      
                         <td id="td-right">
                           <span>TE selenium se minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_selenium_se_minimum)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE chromium cr maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_chromium_cr_maximum)}</td>
                       
                         <td id="td-right">
                           <span>TE selenium se maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_selenium_se_maximum)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE copper cu typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_copper_cu_typical)}</td>
                       
                         <td id="td-right">
                           <span>TE vanadium v typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_vanadium_v_typical)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE copper cu minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_copper_cu_minimum)}</td>
                       
                         <td id="td-right">
                           <span>TE vanadium v min</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_vanadium_v_min)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE copper cu maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_copper_cu_maximum)}</td>
                     
                         <td id="td-right">
                           <span>TE vanadium v max</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_vanadium_v_max)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE chlorine cl typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_chlorine_cl_typical)}</td>
                      
                         <td id="td-right">
                           <span>TE zinc zn typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_zinc_zn_typical)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>E chlorine cl minimum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_chlorine_cl_minimum)}</td>
                       
                         <td id="td-right">
                           <span>TE zinc zn min</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_zinc_zn_min)}</td>
                         </tr> 
                       <tr>
                         <td id="td-right">
                           <span>TE chlorine cl maximum</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_chlorine_cl_maximum)}</td>
                        
                         <td id="td-right">
                           <span>TE zinc zn max</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.te_zinc_zn_max)}</td>
                         </tr> 
                        
                       
                       </tbody>
                       </table>
                       <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">SIZING, %</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>Size 0 to 50mm typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.size_0_to_50mm_typical)}</td>
                         <td id="td-right">
                           <span>Under 2mm typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.under_2mm_typical)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>Size 0 to 50mm min</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.size_0_to_50mm_min)}</td>
                         <td id="td-right">
                           <span>Under 2mm min</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.under_2mm_min)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>Size 0 to 50mm max</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.size_0_to_50mm_max)}</td>
                         <td id="td-right">
                           <span>Under 2mm max</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.under_2mm_max)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>Above 0 to 50mm typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.above_0_to_50mm_typical)}</td>
                         <td id="td-right">
                           <span>Size above 2mm typical</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.size_above_2mm_typical)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>Above 0 to 50mm min</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.above_0_to_50mm_min)}</td>
                         <td id="td-right">
                           <span>Size above 2mm min</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.size_above_2mm_min)}</td>
                       </tr> 
                       <tr>
                         <td id="td-right">
                           <span>Above 0 to 50mm max</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.above_0_to_50mm_max)}</td>
                         <td id="td-right">
                           <span>Size above 2mm max</span>
                         </td>
                         <td>{renderShipmentQty(productInfo.size_above_2mm_max)}</td>
                       </tr> 
                 
                        
                       
                       </tbody>
                       </table>
                      
                        <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">Testing Method</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>Testing method</span>
                         </td>
                         <td>{productInfo.testing_method}</td>
                         <td id="td-right">
                         </td>
                         <td></td>
                       </tr>  
                       </tbody>
                       </table>
                      
                       <div className="col-md-12" id="ax.account_details">
          <h4 className="heading">System Information</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>Created By	
                           </span>
                         </td>
                         <td>{productInfo.created_by	
                         }</td>
                         <td id="td-right">
                          <span>Last Modified By	
                          </span>
                         </td>
                         <td>{productInfo.modified_by	}
                         </td>
                       </tr>  
                       </tbody>
                       </table>
                      
                   <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Notes</span>
                              <span>
                                <i className=""></i>
                                New Note
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="historys" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                        <Row id="table-styles">
          <div className="col-md-12" id="ax.account_details">
            <h4 className="heading"><span>Products Information</span></h4>
            <hr></hr>
          </div>
          <table id="product" className="table table-bordered">
            <thead>
              <tr>
                <th>Product name</th>
                <th>Purchase Contract	</th>
                <th>Buyer Contract	</th>
                <th>Contract Shipment</th> 
                <th>Laycan From Date	</th>
                <th>Laycan To Date	</th>
                <th>Year</th>
                <th>Month</th>
                <th>Price</th>
                <th>Quantity (MT)
                </th>
              </tr>
            </thead>
            {products.length > 0 ? (
              <tbody>
                {products.map((y, i) => (
                  <tr>
                    <td>
                      <span id="codes"><Link to={`/product/product-detail/${y.id}`}>{y.product_code}</Link></span>
                    </td> 
                    <td>{y.purchase_contract}</td>
                    <td>{y.buyer_contract}</td>
                    <td>{y.contract_shipment}</td>
                    <td>{y.laycan_from_date}</td>
                    <td>{y.laycan_to_date}</td>
                    <td>{y.product_year}</td>
                    <td>{y.product_month}</td>
                    <td>{y.price}</td>
                    <td>{y.quantity_MT}</td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td> 
                  <td></td> 
                  <td>No data available</td>
                  <td></td>
                  <td></td>
                   <td></td>
                  <td></td> 
                </tr>
              </tbody>
            )}
          </table>
        </Row>
        <Row id="table-styles">
          <div className="col-md-12" id="ax.account_details">
            <h4 className="heading"><span>Quotes Product</span></h4>
            <hr></hr>
          </div>
          <table id="quote-info" className="table table-bordered">
            <thead>
              <tr>
                <th>Quote Product Name	</th>
                <th>Gross As Received Typical	</th> 
                <th>Gross As Received Rejection	</th>
                <th>Inherent Moisture Typical	</th>
                <th>Net As Received Typical	</th>
                <th>Net As Received Rejection	</th>
                <th>Ash Content Typical	</th>
                <th>Ash Content Typical ARB	</th>
                <th>Sulphur Typical	</th>
                <th>Sulphur Typical ADB
                </th>
              </tr>
            </thead>
            {quotesProduct.length > 0 ? (
              <tbody>
                {quotesProduct.map((y, i) => (
                  <tr>
                    <td><span id="codes"><Link to={`/opportunity/quote-detail/${y.id}`}>{y.product_name}</Link></span></td>
                    <td>{y.gross_as_recieved_typical}</td>
                    <td>{y.gross_as_recieved_rejection}</td>
                    <td>{y.moisture_typical}</td>
                    <td>{y.net_as_recieved_typical}</td>
                    <td>{y.net_as_recieved_rejection}</td>
                    <td>{y.ash_typical}</td>
                    <td>{y.ash_typical_arb}</td>
                    <td>{y.sulphur_typical}</td>
                    <td>{y.sulphur_typical_adb}</td>
                  </tr>
                ))}
              </tbody>
             ) : ( 
               <tbody>
                <tr>
                <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>No Data Available</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
              </tbody> 
             )} 
          </table>
        </Row>

        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Order Products</span>
                              <span>
                                <i className=""></i>
                                 
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="historys" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
        <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Buyer Product</span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="order-product" className="">
                                    <thead>
                                      <tr>
                                        <th>Buyer Product Name	</th>
                                        <th>Gross as received	</th>
                                        <th>Ash</th>
                                        <th>Ash ARB	</th>
                                        <th>Sulphur ADB	</th>
                                        <th>Sulphur ARB	</th>
                                        <th>Total Moisture	</th>
                                        <th>Moisture	</th>
                                        <th>Surveyor Name
                                        </th>
                                      </tr>
                                    </thead>

                                       <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td><td></td>
                                        </tr>
                                      </tbody>
                                     
                                  </table>
                                </Row>

                               

                                  <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Buyer Products</span>
                                      <span>
                                        <i className=""></i>
                                        New Buyer Product
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="historys" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
          <div className="col-md-12" id="ax.account_details">
            <h4 className="heading"><span>TCF Information </span><span><Link to={'/product/product-master-by-tcf/'+id}><i className="fe fe-download-cloud me-2"></i> New</Link></span></h4>
            <hr></hr>
          </div>
          <table id="tcf-info" className="table table-bordered">
            <thead>
              <tr>
                <th>TCF No</th>
                <th>Buyer</th>
                <th>Supplier</th>
                <th>Quantity (MT)	</th>
                <th>Contractual GAR (P)	</th>
                <th>Actual GAR (P)	</th>
                <th>Actual GAR (S)
                </th>
              </tr>
            </thead>
            {tcf.length > 0 ? (
              <tbody>
                {tcf.map((y, i) => (
                  <tr>
                    <td>
                    <Link to={'/tcf/tcf-details/'+y.id}> <span id="codes">{y.tcf_no}</span></Link>
                    </td>
                    <td>{y.buyer}</td> 
                    <td><Link to={`/accounts/${y.account_id}`}>{y.supplier}</Link></td>
                    <td>{y.quantity_MT}</td>
                    <td>{y.contractual_GAR_P}</td>
                    <td>{y.actual_gar_p}</td><td>{y.actual_gar_s}</td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                <td></td><td></td>
                  <td></td>
                  <td>No data available</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            )}
          </table>
        </Row>

        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Notes & Attachments</span>
                              <span>
                                <i className=""></i>
                                New Note
                              </span>
                              <span>
                                <i className=""></i>
                                Attach File
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="historys" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>

                        <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Activity History</span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="historys" className="">
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </Row>

                            <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Open Activities</span>
                                  <span>
                                    <i className=""></i>
                                    New Task
                                  </span>
                                  <span>
                                    <i className=""></i>
                                    New Event
                                  </span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="historys" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>
                            
                   <Row id="table-styles">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        <span>History Information</span>
                                      </h4>
                                      <hr></hr>
                                    </div>
                                    <table id="history" className="">
                                      <thead>
                                        <tr>
                                          <th>Field Name</th>
                                          <th>New Value</th>
                                          <th>Old Value</th>
                                          <th>Changed By</th>
                                          <th>Change Time</th>
                                        </tr>
                                      </thead>

                                      {history.length > 0 ? (
                                        <tbody>
                                          {history.map((y, i) => (
                                            <tr>
                                              <td>{y.field_name}</td>
                                              <td>{y.new_value}</td>
                                              <td>{y.old_value}</td>
                                              <td>{y.changed_by}</td>
                                              <td>{y.change_time}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      ) : (
                                        <tbody>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td>No data available</td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        </tbody>
                                      )}
                                    </table>
                                  </Row>
                   
        
                                  </div></div>
      )}
      </>
      ):(
        <div className="col-md-10">
        <div className="tasks ">
        <h1 className="no-data-detail">No Data Available</h1>
        </div>
        </div>
      )}
      <p></p><p></p>
      <Footer />
      </div>  </div> </div>
  );
};

export default ProductDetailPage;
