import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import { AiFillDiff } from "react-icons/ai";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "./AuthProvider";
import $ from "jquery";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../Token";
const QuotesList = () => {
  
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  console.log(total_height)
  console.log(body)
  $(".addAccount").css({"min-height":(window_height)+"px"})
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState([]);


  
  useEffect(() => {
   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://rwooduatpy.znapay.in/all/quoutes', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      console.log(data)
      setDatas(data);
      $(document).ready(function(){
        $('#tables').DataTable();
         
       })
    } catch (error) {
      console.error(error);
    }
  };
  

  return (  
    <div className="contactAccount">
    
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Quotes List </h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Quotes List</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <Link to='/account/add-new-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
        
      </div>
    </div> 
  </div> 
  <div class='tasks'>
  {/* <table id='tables'>
    <thead>
    <tr>
      <th>Account Name</th>
      <th>Account Alias</th>
      <th>Billing Address</th>
      <th>Country</th>
      <th>Email</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody> 
      <React.Fragment>
      {datas.length > 0 ? (
  datas.map((x) => (
    <tr>
      <td>{x.account_name}</td>
      <td>{x.account_alias}</td>
      <td>{x.billing_address}</td>  
      <td>{x.country}</td>
      <td>{x.email}</td>
      <td><FaPencilAlt/> <FaTrashAlt/><Link to={"/account/contacts"+"/"+x.id }><BsFillEnvelopeFill/></Link><Link to={"/account-details"+"/"+x.id }><AiFillDiff/></Link></td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="6">No data available</td>
  </tr>
)}

      </React.Fragment>


    </tbody>
    </table>   */}
    </div> </div>  
  );
};

export default QuotesList;