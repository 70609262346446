import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import $ from "jquery";
import axios from "axios";
import Footer from '../Footer'
import Header from '../Header' 
import { AiFillDiff } from "react-icons/ai";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
const ContractClauseList = () => {
  const userRole = localStorage.getItem("role"); 
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState([]);

  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height() 
  const total_height=header+nav+footer;
  const window_height=body-total_height;  
  $(".contactAccount").css({"min-height":(window_height)+"px"})

  
  useEffect(() => {
   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://rwooduatpy.znapay.in/get/all/contract_clause', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const data = response.data;
      console.log(data)
      setDatas(data);
      $(document).ready(function(){
        // $('#tables').DataTable().destroy();
        // setTimeout(()=>{
          $('#tables').DataTable();
        // },500)
       })
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <div> 
      <Header/>
    <div className="contactAccount">
    
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Contract Clasue Lists </h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Contract Clasue Lists</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <Link to='/contract-clasue/add'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Contract Clasue</Link>
        </button>
        
      </div>
    </div> 
  </div> 
  <div class='tasks'>
  <table id='tables'>
    <thead>
    <tr>
    <th>Contract Clause Code</th> 
      <th>Contract Clause Name</th>
      <th>Clause No</th>
      <th>Clause Type</th> 
      <th>Definations</th> 
      <th>Actions</th>
    </tr>
    </thead>
    <tbody> 
      <React.Fragment>
      {datas.length > 0 ? (
  datas.map((x) => (
    <tr>
    <td><Link to={"/contact/contact-details"+"/"+x.id }>{x.contract_clause_code}</Link></td> 
      <td>{x.contract_clause_name}</td>
      <td>{x.clause_no}</td>  
      <td>{x.clause_type}</td>
      <td>{x.definations}</td> 
      <td><Link to={"/contact/edit-contact/"+x.id }><FaPencilAlt/></Link> <FaTrashAlt/><Link to={"/contact/contact-details"+"/"+x.id }><AiFillDiff/></Link></td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="6">No data available</td>
  </tr>
)}

      </React.Fragment>


    </tbody>
    </table>  
    </div> </div> 
    <Footer/>
    </div>
  );
};

export default ContractClauseList;