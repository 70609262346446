import React, {
  Component,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import { AiFillDiff } from "react-icons/ai";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { API_URL } from '../config';
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import $ from "jquery";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import Header from "../Header";
import Footer from "../Footer";
import { useLocation } from "react-router-dom";
const AccountsView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { account_record } = location.state;
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_URL}all/accounts`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.accounts;
      console.log(data);
      setDatas(data);
      setLoading(false);
      $(document).ready(function () {
        setTimeout(() => {
          $("#tables").DataTable();
        }, 5);
      });
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
      <Header />
      <div className="contactAccount">
          <div className="page-header" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">Accounts </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Accounts / Create New View
              </li>
            </ol>
          </div>
          <div className="d-flex">
            <div className="justify-content-center">
              {/* <button type="button" className="btn btn-primary my-2 btn-icon-text"> 
              <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
              </button> */}
             
            </div>
          </div>
        </div>
          <div class="tasks account-view">
          <div className="row dropdowns-form">
            <div className="col-md-6">
              <form className="d-flex dropdown-form">

                <label for="pwd" className="mr-sm-2">
                  View
                </label>
                <select
                  name="account_record"
                  className="form-control"
                  >
                  <option>--Select account record type--</option>
                  <option value="Agent">Agent</option>
                  <option value="Bank">Bank</option>
                  <option value="Buyer">Buyer</option>

                  <option value="Supplier">Supplier</option>
                  <option value="Competitor">Competitor</option>
                  <option value="Employee">Employee</option>
                  <option value="Surveyor">Surveyor</option>
                </select>
                <input
                  type="submit"
                  class="btn btn-primary mb-2"
                  value="GO" 
                />
              </form>
            </div>
            <div className="col-md-6 links">
              <p>
                <Link to="#">Edit</Link> | <Link to="/accounts/create-new-view">Create New View</Link>
              </p>
            </div>
          </div>
            {loading ? (
              <p id="loading">Loading Data..</p>
            ) : (
              <table id="tables">
                <thead>
                  <tr>
                    <th>Account Code</th>
                    <th>Account Name</th>
                    <th>Account Alias</th>
                    <th>Account Record Type</th>
                    <th>Origin</th>
                    <th>Phone</th>
                    <th>Port</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {datas.length > 0 ? (
                      datas.map((x) => (
                        <>
                          {x.account_record_type ===account_record && (
                            <tr>
                              <td>
                                <Link to={"/accounts/" + x.id}>
                                  {x.account_code}
                                </Link>
                              </td>
                              <td>
                              <Link to={"/accounts/" + x.id}>{x.account_name}</Link></td>
                              <td>{x.account_alias}</td>
                              <td>
                                <span className="account-record">
                                  {x.account_record_type}
                                </span>
                              </td>
                              <td>{x.origin}</td>
                              <td>{x.phone}</td>
                              <td>{x.port}</td>
                              <td>
                                {" "}
                                <Link to={"/accounts/edit-account/" + x.id}>
                                  <FaPencilAlt />
                                </Link>
                                <Link to={"/accounts/" + x.id}>
                                  <AiFillDiff />
                                </Link>
                              </td>
                            </tr>
                          )}
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">No data available</td>
                      </tr>
                    )}
                  </React.Fragment>
                </tbody>
              </table>
            )}
          </div>
        
      </div>
      <Footer />
    </>
  );
};

export default AccountsView;
