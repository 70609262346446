import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from './Header' 
import Footer from './Footer' 
import ProductFormTwo from './productMaster/productformtwo' 
import ProductFormThree from './productMaster/productformthree' 
import ProductFormFour from './productMaster/productformfour' 
import ProductFormFive from './productMaster/productformfive' 
import ProductFormSix from './productMaster/productformsix' 
import ProductFormSeven from './productMaster/productformseven' 
import ProductFormEight from './productMaster/productformeight' 
import ProductFormNine from './productMaster/productformnine' 
import ProductFormTen from './productMaster/productformten' 
import ProductFormEleven from './productMaster/productformeleven' 
import ProductFormTwele from './productMaster/productformtwele'
import ProductFormThirteen from './productMaster/productformthirteen' 
import ProductFormFourteen from './productMaster/productformfourteen' 
import $ from 'jquery';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { decryptedAccessToken, encryptedAccessToken } from "../Token";
import axios from "axios";
export default function AddProduct() {
  const [selected,setSelected]=useState([]);
  const [selected1,setSelected1]=useState([]);
  const encryptedAccessToken = localStorage.getItem('access_token');
  const [accountName,setaccountName]=useState([]);
  const [priceItem, setPriceitem]=useState([]);
  useEffect(() => {
   
    fetchData3();
  }, [decryptedAccessToken]);
  
  const fetchData3 = async () => {
    try {
      const response = await axios.get('https://rwooduatpy.znapay.in/get/all/price_items', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.priceitem;
      console.log(data)
      setPriceitem(data);
       
    } catch (error) {
      console.error(error);
    }
  };
useEffect(() => {
   
  fetchDataAccount();
}, [decryptedAccessToken]);

const fetchDataAccount = async () => {
  try {
    const response = await axios.get('https://rwooduatpy.znapay.in/all/accounts', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data.accounts;
    setaccountName(data)
     
  } catch (error) {
    console.error(error);
  }
};

  const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    useEffect(() => {
   
        fetchData();
      }, [decryptedAccessToken]);
    
      const fetchData = async () => {
        try {
          const response = await axios.get('https://rwooduatpy.znapay.in/get/all/price_items', {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
          );
          const data = response.data.priceitem;
          setSelected(data)
           
        } catch (error) {
          console.error(error);
        }
      };
      console.log(selected)
      useEffect(() => {
   
        fetchData1();
      }, [decryptedAccessToken]);
    
      const fetchData1 = async () => {
        try {
          const response = await axios.get('https://rwooduatpy.znapay.in/get/products', {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
          );
          const data = response.data;
          setSelected1(data)
          console.log(data)
           
        } catch (error) {
          console.error(error);
        }
      };

  const firstaccordion=localStorage.getItem('accordion');
  const [dataUid,setdataUid]=useState([]);
  const [dataUid1,setdataUid1]=useState([]);
  const [dataUid2,setdataUid2]=useState([]);
  const [dataUid3,setdataUid3]=useState([]);
  const [dataUid4,setdataUid4]=useState([]);
  const [dataUid5,setdataUid5]=useState([]);
  const [dataUid6,setdataUid6]=useState([]);
  const [dataUid7,setdataUid7]=useState([]);
  const [dataUid8,setdataUid8]=useState([]);
  const [dataUid9,setdataUid9]=useState([]);
  const [dataUid10,setdataUid10]=useState([]);
  const [dataUid11,setdataUid11]=useState([]);
  const [dataUid12,setdataUid12]=useState([]);
  const {
    register,
    reset:reset0,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onBlur",
  });

  const {
    reset:reset1,
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    mode: "onBlur",
  });
  const {
    reset:reset2,
    register: register3,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
  } = useForm({
    mode: "onBlur",
  });
  const {
    reset:reset3,
    register: register4,
    formState: { errors: errors4 },
    handleSubmit: handleSubmit4,
  } = useForm({
    mode: "onBlur",
  });
  const {
    reset:reset4,
    register: register5,
    formState: { errors: errors5 },
    handleSubmit: handleSubmit5,
  } = useForm({
    mode: "onBlur",
  });
  
  const {
    reset:reset5,
    register: register6,
    formState: { errors: errors6 },
    handleSubmit: handleSubmit6,
  } = useForm({
    mode: "onBlur",
  });
  const {
    reset:reset6,
    register: register7,
    formState: { errors: errors7 },
    handleSubmit: handleSubmit7,
  } = useForm({
    mode: "onBlur",
  });
  const {
    reset:reset7,
    register: register8,
    formState: { errors: errors8 },
    handleSubmit: handleSubmit8,
  } = useForm({
    mode: "onBlur",
  });
  const {
    reset:reset8,
    register: register9,
    formState: { errors: errors9 },
    handleSubmit: handleSubmit9,
  } = useForm({
    mode: "onBlur",
  });
  const {
    reset:reset9,
    register: register10,
    formState: { errors: errors10},
    handleSubmit: handleSubmit10,
  } = useForm({
    mode: "onBlur",
  });
  const {
    reset:reset10,
    register: register11,
    formState: { errors: errors11},
    handleSubmit: handleSubmit11,
  } = useForm({
    mode: "onBlur",
  });
  
  const {
    reset:reset11,
    register: register12,
    formState: { errors: errors12},
    handleSubmit: handleSubmit12,
  } = useForm({
    mode: "onBlur",
  });
  const {
    reset:reset12,
    register: register13,
    formState: { errors: errors13},
    handleSubmit: handleSubmit13,
  } = useForm({
    mode: "onBlur",
  });
  const {
    reset:reset13,
    register: register14,
    formState: { errors: errors14},
    handleSubmit: handleSubmit14,
  } = useForm({
    mode: "onBlur",
  });
  const onSubmit = data =>{ 
    console.log(data)
    const responseData={ 
        product_code: data.product_code,
        product_name: data.product_name,
        product_account: data.product_account, 
        product_supplier: data.product_supplier,
        product_description: data.product_description,
        product_family: data.product_family,
        product_shipping_term: data.product_shipping_term,
        product_owner:data.product_owner,
        product_active: true,
        short_term_available: false,
        product_price_basis: data.product_price_basis,
        product_price_type: data.product_price_type,
        product_port_of_loading: data.product_port_of_loading
    
    
  }; 
const encryptedAccessToken = localStorage.getItem('access_token');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
      
    fetch('https://rwooduatpy.znapay.in/add/product', {
              method: "POST",
              body: JSON.stringify(responseData),
              headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
            })
              .then((response) => response.json())
              
              .then((response) => {  
                console.log(response.msg) 
                if(response.msg!=''){
                localStorage.setItem('product_id',response.id); 
                setdataUid('b'); 
                localStorage.setItem('accordion', '0');
                reset0();  
                }
             
              })
             
            } 
             
        }
  const receivedBasis = (data) => {
    console.log(data)
    const responseData={
        gross_air_dried_typical: data.gross_air_dried_typical,
        gross_air_dried_min: data.gross_air_dried_min,
        gross_air_dried_max: data.gross_air_dried_max, 
        gross_as_recieved_min: data.gross_as_recieved_min,
        gross_as_recieved_max: data.gross_as_recieved_max,
        net_as_recieved_min: data.net_as_recieved_min 
    
    
  };
 console.log(JSON.stringify(responseData))   
const encryptedAccessToken = localStorage.getItem('access_token');

const id = localStorage.getItem('product_id');

    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
      
    fetch("https://rwooduatpy.znapay.in/add/product_calorific_value/"+id, {
              method: "POST",
              body: JSON.stringify(responseData),
              headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
            })
              .then((response) => response.json())
              
              .then((response) => { 
                console.log(response)
                setdataUid('0');
                setdataUid1('c'); 
                reset1();
             
              })
             
            } 
  };
  const driedBasis=(data)=>{
    
    const responseData={
      moisture_typical: data.moisture_typical,
      moisture_minimum: data.moisture_minimum,
      ash_typical: data.ash_typical, 
      ash_minimum: data.ash_minimum,
      ash_maximum: data.ash_maximum,
      volatile_matter_typical: data.volatile_matter_typical, 
      volatile_matter_minimum:data.volatile_matter_minimum,
      volatile_matter_maximum: data.volatile_matter_maximum,
      fixed_carbon_by_difference: data.fixed_carbon_by_difference,
      fixed_carbon_typical: data.fixed_carbon_typical,
      fixed_carbon_minimum: data.fixed_carbon_minimum,
      fixed_carbon_maximum: data.fixed_carbon_maximum
  
  
};
console.log(JSON.stringify(responseData))   
const encryptedAccessToken = localStorage.getItem('access_token');
const id = localStorage.getItem('product_id');
  const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
  if (encryptedAccessToken) {
    const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
    
  fetch("https://rwooduatpy.znapay.in/add/product_percent_air_dried_basis/"+id, {
            method: "POST",
            body: JSON.stringify(responseData),
            headers: { Authorization: `Bearer ${decryptedAccessToken}`,
            'Content-Type': 'application/json' },
          })
            .then((response) => response.json())
            
            .then((response) => { 
              if(response.Msg !=''){
              // $('.successDiv').html('<p class="result">Data added</p>').delay(300).fadeOut(1000); 
              setdataUid1('0');
              setdataUid2('d');  
              reset2();
            } 
           
            })
           
          } 
  }
  const calorific=(data)=>{
    console.log(data)
        const responseData={
            moisture_typical: data.moisture_typical,
            moisture_minimum: data.moisture_minimum,
            ash_typical: data.ash_typical, 
            ash_minimum: data.ash_minimum,
            ash_maximum: data.ash_maximum,
            volatile_matter_typical: data.volatile_matter_typical, 
            volatile_matter_minimum:data.volatile_matter_minimum,
            volatile_matter_maximum: data.volatile_matter_maximum,
            fixed_carbon_by_difference: data.fixed_carbon_by_difference,
            fixed_carbon_typical: data.fixed_carbon_typical,
            fixed_carbon_minimum: data.fixed_carbon_minimum,
            fixed_carbon_maximum: data.fixed_carbon_maximum
        
        
      };
     console.log(JSON.stringify(responseData))   
    const encryptedAccessToken = localStorage.getItem('access_token');
    const id = localStorage.getItem('product_id');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
          
        fetch("https://rwooduatpy.znapay.in/add/product_percent_air_dried_basis/"+id, {
                  method: "POST",
                  body: JSON.stringify(responseData),
                  headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json' },
                })
                  .then((response) => response.json())
                  
                  .then((response) => { 
                    console.log(response) 
                    // $('.successDiv5').html('<p class="result">Data added</p>'); 
                    setdataUid2('0');
                    setdataUid3('e');  
                    reset3();
                  })
                 
                }
  }  

  const analysis=(data)=>{
    const responseData={ 
      carbon_typical: data.carbon_typical,
      carbon_min:data.carbon_min,
      carbon_max: data.carbon_max, 
      hydrogen_typical: data.hydrogen_typical,
      hydrogen_min: data.hydrogen_min,
      hydrogen_max: data.hydrogen_max, 
      nitrogen_typical:data.nitrogen_typical,
      nitrogen_min: data.nitrogen_min,
      nitrogen_max: data.nitrogen_max,
      sulphur_typical: data.sulphur_typical,
      sulphur_min: data.sulphur_min,
      sulphur_max_ultimate_analysis: data.sulphur_max_ultimate_analysis,
      oxygen_typical: data.oxygen_typical,
      oxygen_min: data.oxygen_min,
      oxygen_max: data.oxygen_max
  
};
console.log(JSON.stringify(responseData))   
const encryptedAccessToken = localStorage.getItem('access_token');
const id = localStorage.getItem('product_id');
  const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
  if (encryptedAccessToken) {
    const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
    
  fetch("https://rwooduatpy.znapay.in/add/product_ultimate_analysis/"+id, {
            method: "POST",
            body: JSON.stringify(responseData),
            headers: { Authorization: `Bearer ${decryptedAccessToken}`,
            'Content-Type': 'application/json' },
          })
            .then((response) => response.json())
            .then((response) => {  
               setdataUid3('0');
              setdataUid4('e'); 
              reset4();
           
            })
           
          } 
  }
  const sulper=(data)=>{
    console.log(data)
    const responseData={ 
      sulphur_typical_adb: data.sulphur_typical_adb,        
      sulphur_min_adb:data.sulphur_min_adb,
      product_sulphur_max: data.product_sulphur_max
  };
 console.log(JSON.stringify(responseData) )  
const encryptedAccessToken = localStorage.getItem('access_token');
const id = localStorage.getItem('product_id');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
      
    fetch("https://rwooduatpy.znapay.in/add/product_sulphur/"+id, {
              method: "POST",
              body: JSON.stringify(responseData) ,
              headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
            })
              .then((response) => response.json())
              
              .then((response) => {  
                // $('.successDiv9').html('<p class="result">Data added</p>'); 
                setdataUid4('0');
                    setdataUid5('f'); 
                    reset5();
              })
             
            } 
  }
  const reduce=(data)=>{
    const responseData={ 
      initial_typical: data.initial_typical,
      initial_minimum:data.initial_minimum,
      initial_maximum: data.initial_maximum,
      spherical_typical: data.spherical_typical,
      spherical_minimum:data.spherical_minimum,
      spherical_maximum: data.spherical_maximum,
      hemispherical_typical: data.hemispherical_typical,
      hemispherical_minimum:data.hemispherical_minimum,
      hemispherical_maximum: data.hemispherical_maximum,
      flow_typical: data.flow_typical,
      flow_minimum:data.flow_minimum,
      flow_maximum: data.flow_maximum
  };
 console.log(JSON.stringify(responseData) )  
const encryptedAccessToken = localStorage.getItem('access_token');
const id = localStorage.getItem('product_id');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
      
    fetch("https://rwooduatpy.znapay.in/add/product_deg_c_reducing/"+id, {
              method: "POST",
              body: JSON.stringify(responseData) ,
              headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
            })
              .then((response) => response.json())
              
              .then((response) => { 
                console.log(response) 
                // $('.successDiv8').html('<p class="result">Data added</p>'); 
                setdataUid5('0');
                reset6();
                    setdataUid6('g'); 
             
              })
             
            } 
             
  }
  const oxi=(data)=>{
    const responseData={ 
      oxidising_initial_typical:data.oxidising_initial_typical,  
oxidising_initial_minimum:data.oxidising_initial_minimum , 
oxidising_initial_maximum :data.oxidising_initial_maximum ,
oxidising_spherical_typical :data.oxidising_spherical_typical ,
oxidising_spherical_minimum :data.oxidising_spherical_minimum, 
oxidising_spherical_maximum :data.oxidising_spherical_maximum ,
oxidising_hemispherical_typical :data.oxidising_hemispherical_typical, 
oxidising_hemispherical_minimum :data.oxidising_hemispherical_minimum, 
oxidising_hemispherical_maximum :data.oxidising_hemispherical_maximum, 
oxidising_flow_typical :data.oxidising_flow_typical ,
oxidising_flow_minimum :data.oxidising_flow_minimum, 
oxidising_flow_maximum:data.oxidising_flow_maximum
  };
 console.log(JSON.stringify(responseData) )  
const encryptedAccessToken = localStorage.getItem('access_token');
const id = localStorage.getItem('product_id');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
      
    fetch("https://rwooduatpy.znapay.in/add/product_deg_c_oxidising/"+id, {
              method: "POST",
              body: JSON.stringify(responseData) ,
              headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
            })
              .then((response) => response.json())
              
              .then((response) => { 
                console.log(response) 
                // $('.successDiv2').html('<p class="result">Data added</p>'); 
                setdataUid6('0');
                setdataUid7('h');
                reset7();
              })
             
            } 
  }
  const ash=(data)=>{
    const responseData={ 
      SiO2_Typical:data.SiO2_Typical,
SiO2_Minimum:data.SiO2_Minimum,
SiO2_Maximum:data.SiO2_Maximum,
Al2O3_Typical:data.Al2O3_Typical,
Al2O3_Minimum:data.Al2O3_Minimum,
Al2O3_Maximum:data.Al2O3_Maximum,
Fe2O3_Typical:data.Fe2O3_Typical,
Fe2O3_Minimum:data.Fe2O3_Minimum,
Fe2O3_Maximum:data.Fe2O3_Maximum,
CaO_Typical:data.CaO_Typical,
CaO_Minimum:data.CaO_Minimum,
CaO_Maximum:data.CaO_Maximum,
MgO_Typical:data.MgO_Typical,
MgO_Minimum:data.MgO_Minimum,
MgO_Maximum:data.MgO_Maximum,
MnO_Typical:data.MnO_Typical,
MnO_Minimum:data.MnO_Minimum,
MnO_Maximum:data.MnO_Maximum,
TiO2_Typical:data.TiO2_Typical,
TiO2_Minimum:data.TiO2_Minimum,
TiO2_Maximum:data.TiO2_Maximum,
Na2O_Typical:data.Na2O_Typical,
Na2O_Minimum:data.Na2O_Minimum,
Na2O_Maximum:data.Na2O_Maximum,
K2O_Typical:data.K2O_Typical,
K2O_Minimum:data.K2O_Minimum,
K2O_Maximum:data.K2O_Maximum,
P2O5_Typical:data.P2O5_Typical,
P2O5_Minimum:data.P2O5_Minimum,
P2O5_Maximum:data.P2O5_Maximum,
SO3_Typical:data.SO3_Typical,
SO3_Minimum:data.SO3_Minimum,
SO3_Maximum:data.SO3_Maximum
      };
     console.log(JSON.stringify(responseData) )  
    const encryptedAccessToken = localStorage.getItem('access_token');
    const id = localStorage.getItem('product_id');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
          
        fetch("https://rwooduatpy.znapay.in/add/product_ash_analysis/"+id, {
                  method: "POST",
                  body: JSON.stringify(responseData) ,
                  headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json' },
                })
                  .then((response) => response.json())
                  
                  .then((response) => { 
                    console.log(response) 
                    // $('.successDiv7').html('<p class="result">Data added</p>'); 
                    setdataUid7('0');
                    setdataUid8('i');
                    reset8();
                  })
                 
                } 
  }
  const driedCoal=(data)=>{
    const responseData={ 
      te_arsenic_as_typical_in_air_dried_coal:data.te_arsenic_as_typical_in_air_dried_coal,
te_arsenic_as_minimum_in_air_dried_coal:data.te_arsenic_as_minimum_in_air_dried_coal,
te_arsenic_as_maximum_in_air_dried_coal:data.te_arsenic_as_maximum_in_air_dried_coal,
te_antimony_sb_typical_in_air_dried_coal:data.te_antimony_sb_typical_in_air_dried_coal,
te_antimony_sb_minimum_in_air_dried_coal:data.te_antimony_sb_minimum_in_air_dried_coal,
te_antimony_sb_maximum_in_air_dried_coal:data.te_antimony_sb_maximum_in_air_dried_coal,
te_beryllium_be_typical_in_air_dried_coal:data.te_beryllium_be_typical_in_air_dried_coal,
te_beryllium_be_minimum_in_air_dried_coal:data.te_beryllium_be_minimum_in_air_dried_coal,
te_beryllium_be_maximum_in_air_dried_coal:data.te_beryllium_be_maximum_in_air_dried_coal,
te_boron_b_typical_in_air_dried_coal:data.te_boron_b_typical_in_air_dried_coal,
te_boron_b_minimum_in_air_dried_coal:data.te_boron_b_minimum_in_air_dried_coal,
te_boron_b_maximum_in_air_dried_coal:data.te_boron_b_maximum_in_air_dried_coal,
te_cadmium_cd_typical_in_air_dried_coal:data.te_cadmium_cd_typical_in_air_dried_coal,
te_cadmium_cd_minimum_in_air_dried_coal:data.te_cadmium_cd_minimum_in_air_dried_coal,
te_cadmium_cd_maximum_in_air_dried_coal:data.te_cadmium_cd_maximum_in_air_dried_coal,
te_chromium_cr_typical_in_air_dried_coal:data.te_chromium_cr_typical_in_air_dried_coal,
te_chromium_cr_minimum_in_air_dried_coal:data.te_chromium_cr_minimum_in_air_dried_coal,
te_chromium_cr_maximum_in_air_dried_coal:data.te_chromium_cr_maximum_in_air_dried_coal,
te_copper_cu_typical_in_air_dried_coal:data.te_copper_cu_typical_in_air_dried_coal,
te_copper_cu_minimum_in_air_dried_coal:data.te_copper_cu_minimum_in_air_dried_coal,
te_copper_cu_maximum_in_air_dried_coal:data.te_copper_cu_maximum_in_air_dried_coal,
te_chlorine_cl_typical_in_air_dried_coal:data.te_chlorine_cl_typical_in_air_dried_coal,
te_chlorine_cl_minimum_in_air_dried_coal:data.te_chlorine_cl_minimum_in_air_dried_coal,
te_chlorine_cl_maximum_in_air_dried_coal:data.te_chlorine_cl_maximum_in_air_dried_coal,
te_fluorine_f_typical_in_air_dried_coal:data.te_fluorine_f_typical_in_air_dried_coal,
te_fluorine_f_minimum_in_air_dried_coal:data.te_fluorine_f_minimum_in_air_dried_coal,
te_fluorine_f_maximum_in_air_dried_coal:data.te_fluorine_f_maximum_in_air_dried_coal,
te_mercury_hg_typical_in_air_dried_coal:data.te_mercury_hg_typical_in_air_dried_coal,
te_mercury_hg_minimum_in_air_dried_coal:data.te_mercury_hg_minimum_in_air_dried_coal,
te_mercury_hg_maximum_in_air_dried_coal:data.te_mercury_hg_maximum_in_air_dried_coal,
te_molybdenum_mo_typical_in_air_dried_coal:data.te_molybdenum_mo_typical_in_air_dried_coal,
te_molybdenum_mo_minimum_in_air_dried_coal:data.te_molybdenum_mo_minimum_in_air_dried_coal,
te_molybdenum_mo_maximum_in_air_dried_coal:data.te_molybdenum_mo_maximum_in_air_dried_coal,
te_lead_pb_typical_in_air_dried_coal:data.te_lead_pb_typical_in_air_dried_coal,
te_lead_pb_minimum_in_air_dried_coal:data.te_lead_pb_minimum_in_air_dried_coal,
te_lead_pb_maximum_in_air_dried_coal:data.te_lead_pb_maximum_in_air_dried_coal,
te_phosphorus_p_typical_in_air_dried_coal:data.te_phosphorus_p_typical_in_air_dried_coal,
te_phosphorus_p_minimum_in_air_dried_coal:data.te_phosphorus_p_minimum_in_air_dried_coal,
te_phosphorus_p_maximum_in_air_dried_coal:data.te_phosphorus_p_maximum_in_air_dried_coal,
te_selenium_se_typical_in_air_dried_coal:data.te_selenium_se_typical_in_air_dried_coal,
te_selenium_se_minimum_in_air_dried_coal:data.te_selenium_se_minimum_in_air_dried_coal,
te_selenium_se_maximum_in_air_dried_coal:data.te_selenium_se_maximum_in_air_dried_coal,
te_vanadium_v_typical_in_air_dried_coal:data.te_vanadium_v_typical_in_air_dried_coal,
te_vanadium_v_minimum_in_air_dried_coal:data.te_vanadium_v_minimum_in_air_dried_coal,
te_vanadium_v_maximum_in_air_dried_coal:data.te_vanadium_v_maximum_in_air_dried_coal,
te_zinc_zn_typical_in_air_dried_coal:data.te_zinc_zn_typical_in_air_dried_coal,
te_zinc_zn_minimum_in_air_dried_coal:data.te_zinc_zn_minimum_in_air_dried_coal,
te_zinc_zn_maximum_in_air_dried_coal:data.te_zinc_zn_maximum_in_air_dried_coal
    };
   console.log(JSON.stringify(responseData) )  
  const encryptedAccessToken = localStorage.getItem('access_token');
  const id = localStorage.getItem('product_id');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
        
      fetch("https://rwooduatpy.znapay.in/add/product_trace_elements_air_dried_in_coal/"+id, {
                method: "POST",
                body: JSON.stringify(responseData) ,
                headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                'Content-Type': 'application/json' },
              })
                .then((response) => response.json())
                
                .then((response) => { 
                  console.log(JSON.stringify(response))
                  // $('.successDiv10').html('<p class="result">Data added</p>'); 
                  setdataUid8('0');
                  setdataUid9('j');
                  reset9();
               
                })
               
              } 
  }
  const productSulper=(data)=>{
    const responseData={ 
      te_arsenic_as_typical:data.te_arsenic_as_typical,
te_arsenic_as_minimum:data.te_arsenic_as_minimum,
te_arsenic_as_maximum:data.te_arsenic_as_maximum,
te_antimony_sb_typical:data.te_antimony_sb_typical,
te_antimony_sb_minimum:data.te_antimony_sb_minimum,
te_antimony_sb_maximum:data.te_antimony_sb_maximum,
te_boron_b_typical:data.te_boron_b_typical,
te_boron_b_minimum:data.te_boron_b_minimum,
te_boron_b_maximum:data.te_boron_b_maximum,
te_cadmium_cd_typical:data.te_cadmium_cd_typical,
te_cadmium_cd_minimum:data.te_cadmium_cd_minimum,
te_cadamium_cd_maximum:data.te_cadamium_cd_maximum,
te_chromium_cr_typical:data.te_chromium_cr_typical,
te_chromium_cr_minimum:data.te_chromium_cr_minimum,
te_chromium_cr_maximum:data.te_chromium_cr_maximum,
te_copper_cu_typical:data.te_copper_cu_typical,
te_copper_cu_minimum:data.te_copper_cu_minimum,
te_copper_cu_maximum:data.te_copper_cu_maximum,
te_chlorine_cl_typical:data.te_chlorine_cl_typical,
te_chlorine_cl_minimum:data.te_chlorine_cl_minimum,
te_chlorine_cl_maximum:data.te_chlorine_cl_maximum,
te_fluorine_f_typical:data.te_fluorine_f_typical,
te_fluorine_f_minimum:data.te_fluorine_f_minimum,
te_fluorine_f_maximum:data.te_fluorine_f_maximum,
te_mercury_hg_typical:data.te_mercury_hg_typical,
te_mercury_hg_minimum:data.te_mercury_hg_minimum,
te_mercury_hg_maximum:data.te_mercury_hg_maximum,
te_molybdenum_mo_typical:data.te_molybdenum_mo_typical,
te_molybdenum_mo_minimum:data.te_molybdenum_mo_minimum,
te_molybdenum_mo_maximum:data.te_molybdenum_mo_maximum,
te_lead_pb_typical:data.te_lead_pb_typical,
te_lead_pb_minimum:data.te_lead_pb_minimum,
te_lead_pb_maximum:data.te_lead_pb_maximum,
te_phosphorus_p_typical:data.te_phosphorus_p_typical,
te_phosphorus_p_minimum:data.te_phosphorus_p_minimum,
te_phosphorus_p_maximum:data.te_phosphorus_p_maximum,
te_selenium_se_typical:data.te_selenium_se_typical,
te_selenium_se_minimum:data.te_selenium_se_minimum,
te_selenium_se_maximum:data.te_selenium_se_maximum,
te_vanadium_v_typical:data.te_vanadium_v_typical,
te_vanadium_v_min:data.te_vanadium_v_min,
te_vanadium_v_max:data.te_vanadium_v_max,
te_zinc_zn_typical:data.te_zinc_zn_typical,
te_zinc_zn_min:data.te_zinc_zn_min,
te_zinc_zn_max:data.te_zinc_zn_max
  };
 console.log(JSON.stringify(responseData) )  
const encryptedAccessToken = localStorage.getItem('access_token');
const id = localStorage.getItem('product_id');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
      
    fetch("https://rwooduatpy.znapay.in/add/product_sulphur/"+id, {
              method: "POST",
              body: JSON.stringify(responseData) ,
              headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
            })
              .then((response) => response.json())
              
              .then((response) => { 
                console.log(response) 
                // $('.successDiv3').html('<p class="result">Data added</p>'); 
                reset10();
                setdataUid9('0');
                setdataUid10('k');
              })
             
            }
  }
  const productSizing=(data)=>{
    const responseData={ 
      size_0_to_50mm_typical:data.size_0_to_50mm_typical,
size_0_to_50mm_min:data.size_0_to_50mm_min,
size_0_to_50mm_max:data.size_0_to_50mm_max,
above_0_to_50mm_typical:data.above_0_to_50mm_typical,
above_0_to_50mm_min:data.above_0_to_50mm_min,
above_0_to_50mm_max:data.above_0_to_50mm_max,
under_2mm_typical:data.under_2mm_typical,
under_2mm_min:data.under_2mm_min,
under_2mm_max:data.under_2mm_max,
size_above_2mm_typical:data.size_above_2mm_typical,
size_above_2mm_min:data.size_above_2mm_min,
size_above_2mm_max:data.size_above_2mm_max
};
console.log(JSON.stringify(responseData))   
const encryptedAccessToken = localStorage.getItem('access_token');
const id = localStorage.getItem('product_id');
  const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
  if (encryptedAccessToken) {
    const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
    
  fetch("https://rwooduatpy.znapay.in/add/productsizing/"+id, {
            method: "POST",
            body: JSON.stringify(responseData),
            headers: { Authorization: `Bearer ${decryptedAccessToken}`,
            'Content-Type': 'application/json' },
          })
            .then((response) => response.json())
            
            .then((response) => {  
              // $('.successDiv').html('<p class="result">Data added</p>'); 
              setdataUid10('0');
              setdataUid11('l');  
              reset11();
            })
           
          } 
  }
  const testing=(data)=>{
    const responseData={   
      testing_method:data.testing_method
            };
           console.log(JSON.stringify(responseData))   
          const encryptedAccessToken = localStorage.getItem('access_token');
          const id = localStorage.getItem('product_id');
              const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
              if (encryptedAccessToken) {
                const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
                
              fetch("https://rwooduatpy.znapay.in/add/product_testing_method/"+id, {
                        method: "POST",
                        body: JSON.stringify(responseData),
                        headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                        'Content-Type': 'application/json' },
                      })
                        .then((response) => response.json())
                        
                        .then((response) => { 
                          console.log(response) 
                          // $('.successDiv11').html('<p class="result">Data added</p>'); 
                          setdataUid11('0');
                          setdataUid12('m'); 
                          reset12();
                        })
                       
                      } 
  }
  const lastForm=(data)=>{
    const responseData={   
      last_modified_by:data.last_modified_by
  }; 
const encryptedAccessToken = localStorage.getItem('access_token');
const id = localStorage.getItem('product_id');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
      
    fetch("https://rwooduatpy.znapay.in/add/product_system_info/"+id, {
              method: "POST",
              body: JSON.stringify(responseData),
              headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
            })
              .then((response) => response.json())
              
              .then((response) => { 
                console.log(response) 
                $('.successDiv6').html('<p class="result">Data added</p>'); 
                reset13();
             
              })
             
            } 
  }

    return(
        <div>
            <Header/>
            <div className="contactAccount">    
    
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Product Master</h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Add New Product Master </li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
{/*       
        <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-new-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div> </div> 
            <div className="tasks">
            <Accordion preExpanded={['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n']}>
            <AccordionItem uuid={firstaccordion}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                       Products
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <Form key={1} onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
        {/* <h4 className='heading'>Account Information</h4> */}
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product code"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Product Code"
              name="product_code"
              {...register("product_code", { required: false })}
            />
            {errors.product_code && <span>Required Product Code</span>}
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Product Name"
              name="product_name"
              {...register("product_name", { required: false })}
            />
             {errors.product_name && <span>Required Product Name</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Account"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Product Account"
              name="product_account"
              {...register("product_account", { required: false })}
            />
             {errors.product_account && <span>Required Product Name</span>}
          </FloatingLabel>
        </Form.Group>
       
        {/*  */}
        

          
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Supplier"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Product Supplier" 
            name="product_supplier"
            {...register("product_supplier", { required: false })}
            />
             {errors.product_supplier && <span>Required Product Supplier</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Account name"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Account name" 
            name="account_name"
            {...register("account_name", { required: false })}
            />
             {errors.account_name && <span>Required Product Supplier</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Description"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Product Description" 
            name="product_description"
            {...register("product_description", { required: false })}
            />
             {errors.product_description && <span>Required Product Description</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Family"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Product Family" 
            name="product_family"
            {...register("product_family", { required: false })}
            />
             {errors.product_family && <span>Required Product Family</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Shipping Term"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Product Shipping Term" 
            name="product_shipping_term"
            {...register("product_shipping_term", { required: false })}
            />
             {errors.product_shipping_term && <span>Required Product Shipping Term</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Owner"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Product Owner" 
            name="product_owner"
            {...register("product_owner", { required: false })}
            />
             {errors.product_owner && <span>Required Product Owner</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Price Basis"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Product Price Basis" 
            name="product_price_basis"
            {...register("product_price_basis", { required: false })}
            />
             {errors.product_price_basis && <span>Required Product Active</span>}
          </FloatingLabel>
        </Form.Group>
       
        <Form.Group as={Col} md="6" className='p-1'>
        <label>
        <input
          type="checkbox" 
          name="product_active"
        />
         &nbsp;&nbsp;Product Active
      </label> 
        </Form.Group>
        <Form.Group as={Col} md="6" className='p-1'>
        <label>
        <input 
        type="checkbox" 
          name="short_term_available"
        />
         &nbsp;&nbsp;Short Term Available
      </label> 
     
        </Form.Group>
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Product price type">
    <Form.Select aria-label="Floating label select example"
    name="product_price_type" 
    placeholder="Product price type" 
    defaultValue=""
    {...register("product_price_type", { required: false })}
    >
      <option value='' >Select</option>
       {
          priceItem.map((x)=>{
              return(
                  <option value={x.price_type_name}>{x.price_type_name}</option>
              )
          })
       }
    </Form.Select> 
    {errors.product_price_type && <span>Required Account Name</span>}
  </FloatingLabel>
              </Form.Group> 
               <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Port Of Loading"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Product Port Of Loading" 
            name="product_port_of_loading"
            {...register("product_port_of_loading", { required: false })}
            />
             {errors.product_port_of_loading && <span>Required Product Active</span>}
          </FloatingLabel>
        </Form.Group>
      </Row> 
      <input type="submit"  className="addaccount-save" value='Next'/>
      <div className='successDiv'></div>
    </Form>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={dataUid}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Product Percent Received Basis
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <Form key={2} onSubmit={handleSubmit2(receivedBasis)}>
                
              <input type="submit"  className="addaccount-save" value='Next'/>
              <div className='successDiv1'></div>
            </Form>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={dataUid1}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Product Percent Air Dried Basis
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <Form onSubmit={handleSubmit3(driedBasis)}>
              
              <input type="submit"  className="diared" value='Next'/>
              <div className='successDiv'></div>
            </Form>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={dataUid2}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Product Calorific
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <Form onSubmit={handleSubmit4(calorific)}>
              <Row className="mb-3">
                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Moisture Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Moisture Typical"
                      name="moisture_typical"
                      {...register4("moisture_typical", { required: false })}
                    />
                    {errors4.moisture_typical && <span>Required Moisture Typical</span>}
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Moisture Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Moisture Minimum" 
                    name="moisture_minimum"
                    {...register4("moisture_minimum", { required: false })}
                    />
                     {errors4.moisture_minimum && <span>Required Moisture Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                
        
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Ash Typical" 
                    name="ash_typical"
                    {...register4("ash_typical", { required: false })}
                    />
                     {errors4.ash_typical && <span>Required Ash Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Ash Minimum" 
                    name="ash_minimum"
                    {...register4("ash_minimum", { required: false })}
                    />
                     {errors4.ash_minimum && <span>Required Ash Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Ash Maximum" 
                    name="ash_maximum"
                    {...register4("ash_maximum", { required: false })}
                    />
                     {errors4.ash_maximum && <span>Required Ash Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Volatile Matter Typical" 
                    name="volatile_matter_typical"
                    {...register4("volatile_matter_typical", { required: false })}
                    />
                     {errors4.volatile_matter_typical && <span>Required Volatile Matter Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical Arb"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Sulphur Typical Arb" 
                    name="sulphur_typical_arb"
                    {...register4("sulphur_typical_arb", { required: false })}
                    />
                     {errors4.sulphur_typical_arb && <span>Required Sulphur Typical Arb</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Volatile Matter Minimum" 
                    name="volatile_matter_minimum"
                    {...register4("volatile_matter_minimum", { required: false })}
                    />
                     {errors4.volatile_matter_minimum && <span>Required Volatile Matter Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Volatile Matter Maximum" 
                    name="volatile_matter_maximum"
                    {...register4("volatile_matter_maximum", { required: false })}
                    />
                     {errors4.volatile_matter_maximum && <span>Required Volatile Matter Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon By Difference"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Fixed Carbon By Difference" 
                    name="fixed_carbon_by_difference"
                    {...register4("fixed_carbon_by_difference", { required: false })}
                    />
                     {errors4.fixed_carbon_by_difference && <span>Fixed Carbon By Difference</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Fixed Carbon Typical" 
                    name="fixed_carbon_typical"
                    {...register4("fixed_carbon_typical", { required: false })}
                    />
                     {errors4.fixed_carbon_typical && <span>Required Fixed Carbon Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Fixed Carbon Minimum" 
                    name="fixed_carbon_minimum"
                    {...register4("fixed_carbon_minimum", { required: false })}
                    />
                     {errors4.fixed_carbon_minimum && <span>Required Fixed Carbon Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
              </Row> 
              <input type="submit"  className="addaccount-save" value='Next'/>
              <div className='successDiv5'></div>
            </Form>
                </AccordionItemPanel>
            </AccordionItem>
            
            <AccordionItem uuid={dataUid3}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Product Ultimate Analysis
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <Form onSubmit={handleSubmit5(analysis)}>
              <Row className="mb-3">
                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Carbon Typical"
                      name="carbon_typical"
                      {...register5("carbon_typical", { valueAsNumber:true, required: false })}
                    />
                    {errors5.carbon_typical && <span>Required Carbon Typical</span>}
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Min"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Carbon Min" 
                    name="carbon_min"
                    {...register5("carbon_min", {valueAsNumber:true,  required: false })}
                    />
                     {errors5.carbon_min && <span>Required Carbon Min</span>}
                  </FloatingLabel>
                </Form.Group>
                
        
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Max"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Carbon Max" 
                    name="carbon_max"
                    {...register5("carbon_max", {valueAsNumber:true,  required: false })}
                    />
                     {errors5.carbon_max && <span>Required Carbon Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Hydrogen Typical" 
                    name="hydrogen_typical"
                    {...register5("hydrogen_typical", { valueAsNumber:true, required: false })}
                    />
                     {errors5.hydrogen_typical && <span>Required Hydrogen Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Min"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Hydrogen Min" 
                    name="hydrogen_min"
                    {...register5("hydrogen_min", {valueAsNumber:true,  required: false })}
                    />
                     {errors5.hydrogen_min && <span>Required Hydrogen Min</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Max"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Hydrogen Max" 
                    name="hydrogen_max"
                    {...register5("hydrogen_max", { valueAsNumber:true, required: false })}
                    />
                     {errors5.hydrogen_max && <span>Required Hydrogen Max</span>}
                  </FloatingLabel>
                </Form.Group>
                
                 
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Nitrogen Typical" 
                    name="nitrogen_typical"
                    {...register5("nitrogen_typical", {valueAsNumber:true,  required: false })}
                    />
                     {errors5.nitrogen_typical && <span>Required Nitrogen Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Min"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Nitrogen Min" 
                    name="nitrogen_min"
                    {...register5("nitrogen_min", { valueAsNumber:true, required: false })}
                    />
                     {errors5.nitrogen_min && <span>Required Nitrogen Min</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Max"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Nitrogen Max" 
                    name="nitrogen_max"
                    {...register5("nitrogen_max", {valueAsNumber:true,  required: false })}
                    />
                     {errors5.nitrogen_max && <span>Nitrogen Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Sulphur Typical" 
                    name="sulphur_typical"
                    {...register5("sulphur_typical", {valueAsNumber:true,  required: false })}
                    />
                     {errors5.sulphur_typical && <span>Required Sulphur Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Min"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Sulphur Min" 
                    name="sulphur_min"
                    {...register5("sulphur_min", { valueAsNumber:true, required: false })}
                    />
                     {errors5.sulphur_min && <span>Required Sulphur Min</span>}
                  </FloatingLabel>
                </Form.Group>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur max ultimate analysis"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Sulphur max ultimate analysis" 
                    name="sulphur_max_ultimate_analysis"
                    {...register5("sulphur_max_ultimate_analysis", { valueAsNumber:true, required: false })}
                    />
                     {errors5.sulphur_max_ultimate_analysis && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>
                
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Oxygen Typical" 
                    name="oxygen_typical"
                    {...register5("oxygen_typical", { valueAsNumber:true, required: false })}
                    />
                     {errors5.oxygen_typical && <span>Required Oxygen Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Min"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Oxygen Min" 
                    name="oxygen_min"
                    {...register5("oxygen_min", {valueAsNumber:true,  required: false })}
                    />
                     {errors5.oxygen_min && <span>Required Oxygen Min</span>}
                  </FloatingLabel>
                </Form.Group>
                
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Max"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Oxygen Max" 
                    name="oxygen_max"
                    {...register5("oxygen_max", {valueAsNumber:true,  required: false })}
                    />
                     {errors5.oxygen_max && <span>Required Oxygen Max</span>}
                  </FloatingLabel>
                </Form.Group>
              </Row> 
              <input type="submit"  className="addaccount-save" value='Next'/>
              <div className='successDiv4'></div>
            </Form>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={dataUid4}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Product Sulphur
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <Form onSubmit={handleSubmit6(sulper)}>
                <Row className="mb-3">
                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical Adb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Sulphur Typical Adb"
                      name="sulphur_typical_adb"
                      {...register6("sulphur_typical_adb", {valueAsNumber:true, required: false })}
                    />
                    {errors6.sulphur_typical_adb && <span>Required Sulphur Typical Adb</span>}
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Min Adb"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Sulphur Min Adb" 
                    name="sulphur_min_adb"
                    {...register6("sulphur_min_adb", { valueAsNumber:true,required: false })}
                    />
                     {errors6.sulphur_min_adb && <span>Required Sulphur Min Adb</span>}
                  </FloatingLabel>
                </Form.Group>
                
        
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Max"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Sulphur Max" 
                    name="product_sulphur_max"
                    {...register6("product_sulphur_max", {valueAsNumber:true, required: false })}
                    />
                     {errors6.product_sulphur_max && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>
                
              </Row> 
              <input type="submit"  className="addaccount-save" value='Next'/>
              <div className='successDiv9'></div>
            </Form>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={dataUid5}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Product deg c reducing
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <Form onSubmit={handleSubmit7(reduce)}>
              <Row className="mb-3">
                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Initial Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Initial Typical"
                      name="initial_typical"
                      {...register7("initial_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors7.initial_typical && <span>Required Initial Typical</span>}
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Initial Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Initial Minimum" 
                    name="initial_minimum"
                    {...register7("initial_minimum", { valueAsNumber:true,required: false })}
                    />
                     {errors7.initial_minimum && <span>Required Initial Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                
         
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Initial Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Initial Maximum" 
                    name="initial_maximum"
                    {...register7("initial_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors7.initial_maximum && <span>Required Initial Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Spherical Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Spherical Typical" 
                    name="spherical_typical"
                    {...register7("spherical_typical", {valueAsNumber:true, required: false })}
                    />
                     {errors7.spherical_typical && <span>Required Spherical Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Spherical Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Spherical Minimum" 
                    name="spherical_minimum"
                    {...register7("spherical_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors7.spherical_minimum && <span>Required Spherical Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Spherical Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Spherical Maximum" 
                    name="spherical_maximum"
                    {...register7("spherical_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors7.spherical_maximum && <span>Required Spherical Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hemispherical Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Hemispherical Typical" 
                    name="hemispherical_typical"
                    {...register7("hemispherical_typical", {valueAsNumber:true, required: false })}
                    />
                     {errors7.hemispherical_typical && <span>Required Hemispherical Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hemispherical Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Hemispherical Minimum" 
                    name="hemispherical_minimum"
                    {...register7("hemispherical_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors7.hemispherical_minimum && <span>Required Hemispherical Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hemispherical Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Hemispherical Maximum" 
                    name="hemispherical_maximum"
                    {...register7("hemispherical_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors7.hemispherical_maximum && <span>Required Hemispherical Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Flow Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Flow Typical" 
                    name="sulphur_max"
                    {...register7("flow_typical", {valueAsNumber:true, required: false })}
                    />
                     {errors7.flow_typical && <span>Required Flow Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Flow Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Flow Minimum" 
                    name="flow_minimum"
                    {...register7("flow_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors7.flow_minimum && <span>Required Flow Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Flow Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Flow Maximum" 
                    name="flow_maximum"
                    {...register7("flow_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors7.flow_maximum && <span>Required Flow Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
              </Row> 
              <input type="submit"  className="addaccount-save" value='Next'/>
              <div className='successDiv8'></div>
            </Form>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={dataUid6}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Product deg c oxidising
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <Form onSubmit={handleSubmit8(oxi)}>
              <Row className="mb-3">
              <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidising Initial Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidising Initial Typical"
                      name="oxidising_initial_typical"
                      {...register8("oxidising_initial_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors8. oxidising_initial_typical && <span>Required Oxidising Initial Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidising Initial Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidising Initial Minimum"
                      name="oxidising_initial_minimum"
                      {...register8("oxidising_initial_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors8. oxidising_initial_minimum && <span>Required Oxidising Initial Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidising Initial Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidising Initial Maximum"
                      name="oxidising_initial_maximum"
                      {...register8("oxidising_initial_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors8.oxidising_initial_maximum && <span>Required Oxidising Initial Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidising Spherical Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidising Spherical Typical"
                      name="oxidising_spherical_typical"
                      {...register8("oxidising_spherical_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors8.oxidising_spherical_typical && <span>Required Oxidising Spherical Typical </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidising Spherical Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidising Spherical Minimum"
                      name="oxidising_spherical_minimum"
                      {...register8("oxidising_spherical_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors8.oxidising_spherical_minimum && <span>Required Oxidising Spherical Minimum </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidising Spherical Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidising Spherical Maximum"
                      name="oxidising_spherical_maximum"
                      {...register8("oxidising_spherical_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors8.oxidising_spherical_maximum && <span>Required Oxidising Spherical Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidising Hemispherical Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidising Hemispherical Typical"
                      name="oxidising_hemispherical_typical"
                      {...register8("oxidising_hemispherical_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors8.oxidising_hemispherical_typical && <span>Required Oxidising Hemispherical Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidising Hemispherical Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidising Hemispherical Minimum"
                      name="oxidising_hemispherical_minimum"
                      {...register8("oxidising_hemispherical_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors8.oxidising_hemispherical_minimum && <span>Required Oxidising Hemispherical Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidising Hemispherical Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidising Hemispherical Maximum"
                      name="oxidising_hemispherical_maximum"
                      {...register8("oxidising_hemispherical_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors8.oxidising_hemispherical_maximum && <span>Required Oxidising Hemispherical Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidising Flow Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidising Flow Typical"
                      name="oxidising_flow_typical"
                      {...register8("oxidising_flow_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors8.oxidising_flow_typical && <span>Required Oxidising Flow Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidising Flow Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidising Flow Minimum"
                      name="oxidising_flow_minimum"
                      {...register8("oxidising_flow_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors8.oxidising_flow_minimum && <span>Required Oxidising Flow Minimum </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidising Flow Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidising Flow Maximum"
                      name="oxidising_flow_maximum"
                      {...register8("oxidising_flow_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors8.oxidising_flow_maximum && <span>Required Oxidising Flow Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
              </Row> 
              <input type="submit"  className="addaccount-save" value='Next'/>
              <div className='successDiv2'></div>
            </Form>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={dataUid7}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Product ash analysis
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <Form onSubmit={handleSubmit9(ash)}>
              <Row className="mb-3">
                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SiO2 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SiO2 Typical"
                      name="SiO2_Typical"
                      {...register9("SiO2_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors9.SiO2_Typical && <span>Required SiO2 Typical </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SiO2 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SiO2 Minimum"
                      name="SiO2_Minimum"
                      {...register9("SiO2_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.SiO2_Minimum && <span>Required SiO2 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SiO2 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SiO2 Maximum"
                      name="SiO2_Maximum"
                      {...register9("SiO2_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.SiO2_Maximum && <span>Required SiO2 Maximum </span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Al2O3 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Al2O3 Typical"
                      name="Al2O3_Typical"
                      {...register9("Al2O3_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors9.Al2O3_Typical && <span>Required Al2O3 Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Al2O3 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Al2O3 Minimum"
                      name="Al2O3_Minimum"
                      {...register9("Al2O3_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.Al2O3_Minimum && <span>Required Al2O3 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Al2O3 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Al2O3 Maximum"
                      name="Al2O3_Maximum"
                      {...register9("Al2O3_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.Al2O3_Maximum && <span>Required Al2O3 Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fe2O3 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Fe2O3 Typical"
                      name="Fe2O3_Typical"
                      {...register9("Fe2O3_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors9.Fe2O3_Typical && <span>Required Fe2O3 Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fe2O3 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Fe2O3 Minimum"
                      name="Fe2O3_Minimum"
                      {...register9("Fe2O3_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.Fe2O3_Minimum && <span>Required Fe2O3 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fe2O3 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Fe2O3 Maximum"
                      name="Fe2O3_Maximum"
                      {...register9("Fe2O3_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.Fe2O3_Maximum && <span>Required Fe2O3 Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="CaO Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="CaO Typical"
                      name="CaO_Typical"
                      {...register9("CaO_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors9.CaO_Typical && <span>Required CaO Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="CaO Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="CaO Minimum"
                      name="CaO_Minimum"
                      {...register9("CaO_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.CaO_Minimum && <span>Required CaO Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="CaO Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="CaO Maximum"
                      name="CaO_Maximum"
                      {...register9("CaO_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.CaO_Maximum && <span>Required CaO Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MgO Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MgO Typical"
                      name="MgO_Typical"
                      {...register9("MgO_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors9.MgO_Typical && <span>Required MgO Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MgO Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MgO Minimum"
                      name="MgO_Minimum"
                      {...register9("MgO_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.MgO_Minimum && <span>Required MgO Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MgO Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MgO Maximum"
                      name="MgO_Maximum"
                      {...register9("MgO_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.MgO_Maximum && <span>Required MgO Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MnO Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MnO Typical"
                      name="MnO_Typical"
                      {...register9("MnO_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors9.MnO_Typical && <span>Required MnO Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MnO Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MnO Minimum"
                      name="MnO_Minimum"
                      {...register9("MnO_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.MnO_Minimum && <span>Required MnO Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MnO Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MnO Maximum"
                      name="MnO_Maximum"
                      {...register9("MnO_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.MnO_Maximum && <span>Required MnO Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TiO2 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TiO2 Typical"
                      name="TiO2_Typical"
                      {...register9("TiO2_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors9.TiO2_Typical && <span>Required TiO2 Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TiO2 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TiO2 Minimum"
                      name="TiO2_Minimum"
                      {...register9("TiO2_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.TiO2_Minimum && <span>Required TiO2 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TiO2 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TiO2 Maximum"
                      name="TiO2_Maximum"
                      {...register9("TiO2_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.TiO2_Maximum && <span>Required TiO2 Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Na2O Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Na2O Typical"
                      name="Na2O_Typical"
                      {...register9("Na2O_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors9.Na2O_Typical && <span>Required Na2O Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Na2O Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Na2O Minimum"
                      name="Na2O_Minimum"
                      {...register9("Na2O_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.Na2O_Minimum && <span>Required Na2O Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Na2O Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Na2O Maximum"
                      name="Na2O_Maximum"
                      {...register9("Na2O_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.Na2O_Maximum && <span>Required Na2O Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="K2O Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="K2O Typical"
                      name="K2O_Typical"
                      {...register9("K2O_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors9.K2O_Typical && <span>Required K2O Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="K2O Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="K2O Minimum"
                      name="K2O_Minimum"
                      {...register9("K2O_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.K2O_Minimum && <span>Required K2O Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="K2O Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="K2O Maximum"
                      name="K2O_Maximum"
                      {...register9("K2O_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.K2O_Maximum && <span>Required K2O Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="P2O5 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="P2O5 Typical"
                      name="P2O5_Typical"
                      {...register9("P2O5_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors9.P2O5_Typical && <span>Required P2O5 Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="P2O5 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="P2O5 Minimum"
                      name="P2O5_Minimum"
                      {...register9("P2O5_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.P2O5_Minimum && <span>Required P2O5 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="P2O5 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="P2O5 Maximum"
                      name="P2O5_Maximum"
                      {...register9("P2O5_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.P2O5_Maximum && <span>Required P2O5 Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SO3 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SO3 Typical"
                      name="SO3_Typical"
                      {...register9("SO3_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors9.SO3_Typical && <span>Required SO3 Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SO3 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SO3 Minimum"
                      name="SO3_Minimum"
                      {...register9("SO3_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.SO3_Minimum && <span>Required SO3 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SO3 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SO3 Maximum"
                      name="SO3_Maximum"
                      {...register9("SO3_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors9.SO3_Maximum && <span>Required SO3 Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
         
               
                 
                
              </Row> 
              <input type="submit"  className="addaccount-save" value='Next'/>
              <div className='successDiv7'></div>
            </Form>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={dataUid8}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Product trace elements air dried coal
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <Form onSubmit={handleSubmit10(driedCoal)}>
              <Row className="mb-3">
                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="te arsenic as typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="te arsenic as typical"
                      name="te_arsenic_as_typical"
                      {...register10("te_arsenic_as_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_arsenic_as_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te arsenic as minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te arsenic as minimum"
                      name="te_arsenic_as_minimum"
                      {...register10("te_arsenic_as_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_arsenic_as_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te arsenic as maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te arsenic as maximum"
                      name="te_arsenic_as_maximum"
                      {...register10("te_arsenic_as_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_arsenic_as_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te antimony sb typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te antimony sb typical"
                      name="te_antimony_sb_typical"
                      {...register10("te_antimony_sb_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_antimony_sb_typical && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te antimony sb minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te antimony sb minimum"
                      name="te_antimony_sb_minimum"
                      {...register10("te_antimony_sb_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_antimony_sb_minimum && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te antimony sb maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te antimony sb maximum"
                      name="te_antimony_sb_maximum"
                      {...register10("te_antimony_sb_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_antimony_sb_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te beryllium be typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te beryllium be typical"
                      name="te_beryllium_be_typical"
                      {...register10("te_beryllium_be_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_beryllium_be_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te beryllium be minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te beryllium be minimum"
                      name="te_beryllium_be_minimum"
                      {...register10("te_beryllium_be_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_beryllium_be_minimum && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te beryllium be maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te beryllium be maximum"
                      name="te_beryllium_be_maximum"
                      {...register10("te_beryllium_be_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_beryllium_be_maximum && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te boron b typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te boron b typical"
                      name="te_boron_b_typical"
                      {...register10("te_boron_b_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_boron_b_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te boron b minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te boron b minimum"
                      name="te_boron_b_minimum"
                      {...register10("te_boron_b_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_boron_b_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te boron b maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te boron b maximum"
                      name="te_boron_b_maximum"
                      {...register10("te_boron_b_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_boron_b_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te cadmium cd typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te cadmium cd typical"
                      name="te_cadmium_cd_typical"
                      {...register10("te_cadmium_cd_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_cadmium_cd_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te cadmium cd minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te cadmium cd minimum"
                      name="te_cadmium_cd_minimum"
                      {...register10("te_cadmium_cd_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_cadmium_cd_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te cadmium cd maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te cadmium cd maximum"
                      name="te_cadmium_cd_maximum"
                      {...register10("te_cadmium_cd_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_cadmium_cd_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chromium cr typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chromium cr typical"
                      name="te_chromium_cr_typical"
                      {...register10("te_chromium_cr_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_chromium_cr_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chromium cr minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chromium cr minimum"
                      name="te_chromium_cr_minimum"
                      {...register10("te_chromium_cr_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_chromium_cr_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chromium cr maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chromium cr maximum"
                      name="te_chromium_cr_maximum"
                      {...register10("te_chromium_cr_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_chromium_cr_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te copper cu typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te copper cu typical"
                      name="te_copper_cu_typical"
                      {...register10("te_copper_cu_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_copper_cu_typical&& <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te copper cu minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te copper cu minimum"
                      name="te_copper_cu_minimum"
                      {...register10("te_copper_cu_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_copper_cu_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te copper cu maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te copper cu maximum"
                      name="te_copper_cu_maximum"
                      {...register10("te_copper_cu_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_copper_cu_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chlorine cl typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chlorine cl typical"
                      name="te_chlorine_cl_typical"
                      {...register10("te_chlorine_cl_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_chlorine_cl_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chlorine cl minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chlorine cl minimum"
                      name="te_chlorine_cl_minimum"
                      {...register10("te_chlorine_cl_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_chlorine_cl_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chlorine cl maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chlorine cl maximum"
                      name="te_chlorine_cl_maximum"
                      {...register10("te_chlorine_cl_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_chlorine_cl_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te fluorine f typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te fluorine f typical"
                      name="te_fluorine_f_typical"
                      {...register10("te_fluorine_f_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_fluorine_f_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te fluorine f minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te fluorine f minimum"
                      name="te_fluorine_f_minimum"
                      {...register10("te_fluorine_f_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_fluorine_f_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te fluorine f maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te fluorine f maximum"
                      name="te_fluorine_f_maximum"
                      {...register10("te_fluorine_f_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_fluorine_f_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te mercury hg typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te mercury hg typical"
                      name="te_mercury_hg_typical"
                      {...register10("te_mercury_hg_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_mercury_hg_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te mercury hg minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te mercury hg minimum"
                      name="te_mercury_hg_minimum"
                      {...register10("te_mercury_hg_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_mercury_hg_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te mercury hg maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te mercury hg maximum"
                      name="te_mercury_hg_maximum"
                      {...register10("te_mercury_hg_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_mercury_hg_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te molybdenum mo typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te molybdenum mo typical"
                      name="te_molybdenum_mo_typical"
                      {...register10("te_molybdenum_mo_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_molybdenum_mo_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te molybdenum mo minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te molybdenum mo minimum"
                      name="te_molybdenum_mo_minimum"
                      {...register10("te_molybdenum_mo_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_molybdenum_mo_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te molybdenum mo maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te molybdenum mo maximum"
                      name="te_molybdenum_mo_maximum"
                      {...register10("te_molybdenum_mo_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_molybdenum_mo_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te lead pb typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te lead pb typical"
                      name="te_lead_pb_typical"
                      {...register10("te_lead_pb_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_lead_pb_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te lead pb minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te lead pb minimum"
                      name="te_lead_pb_minimum"
                      {...register10("te_lead_pb_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_lead_pb_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te lead pb maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te lead pb maximum"
                      name="te_lead_pb_maximum"
                      {...register10("te_lead_pb_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_lead_pb_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te phosphorus p typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te phosphorus p typical"
                      name="te_phosphorus_p_typical"
                      {...register10("te_phosphorus_p_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_phosphorus_p_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te phosphorus p minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te phosphorus p minimum"
                      name="te_phosphorus_p_minimum"
                      {...register10("te_phosphorus_p_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_phosphorus_p_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te phosphorus p maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te phosphorus p maximum"
                      name="te_phosphorus_p_maximum"
                      {...register10("te_phosphorus_p_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_phosphorus_p_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te selenium se typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te selenium se typical"
                      name="te_selenium_se_typical"
                      {...register10("te_selenium_se_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_selenium_se_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te selenium se minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te selenium se minimum"
                      name="te_selenium_se_minimum"
                      {...register10("te_selenium_se_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_selenium_se_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te selenium se maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te selenium se maximum"
                      name="te_selenium_se_maximum"
                      {...register10("te_selenium_se_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_selenium_se_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te vanadium v typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te vanadium v typical"
                      name="te_vanadium_v_typical"
                      {...register10("te_vanadium_v_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_vanadium_v_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te vanadium v minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te vanadium v minimum"
                      name="te_vanadium_v_minimum"
                      {...register10("te_vanadium_v_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_vanadium_v_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te vanadium v maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te vanadium v maximum"
                      name="te_vanadium_v_maximum"
                      {...register10("te_vanadium_v_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_vanadium_v_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te zinc zn typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te zinc zn typical"
                      name="te_zinc_zn_typical"
                      {...register10("te_zinc_zn_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_zinc_zn_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te zinc zn minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te zinc zn minimum"
                      name="te_zinc_zn_minimum"
                      {...register10("te_zinc_zn_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_zinc_zn_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te zinc zn maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te zinc zn maximum"
                      name="te_zinc_zn_maximum"
                      {...register10("te_zinc_zn_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors10.te_zinc_zn_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                
                
              </Row> 
              <input type="submit"  className="addaccount-save" value='Next'/>
              <div className='successDiv10'></div>
            </Form>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={dataUid9}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Product sulphur
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <Form onSubmit={handleSubmit11(productSulper)}>
              <Row className="mb-3">
                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te arsenic as typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te arsenic as typical"
                      name="te_arsenic_as_typical_in_air_dried_coal"
                      {...register11("te_arsenic_as_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_arsenic_as_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te rsenic as minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te rsenic as minimum"
                      name="te_arsenic_as_minimum_in_air_dried_coal"
                      {...register11("te_arsenic_as_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_arsenic_as_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te arsenic as maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te arsenic as maximum"
                      name="te_arsenic_as_maximum_in_air_dried_coal"
                      {...register11("te_arsenic_as_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_arsenic_as_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te antimony sb typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te antimony sb typical"
                      name="te_antimony_sb_typical_in_air_dried_coal"
                      {...register11("te_antimony_sb_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_antimony_sb_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te antimony sb minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te antimony sb minimum"
                      name="te_antimony_sb_minimum_in_air_dried_coal"
                      {...register11("te_antimony_sb_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_antimony_sb_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te antimony sb maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te antimony sb maximum"
                      name="te_antimony_sb_maximum_in_air_dried_coal"
                      {...register11("te_antimony_sb_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_antimony_sb_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te boron b typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te boron b typical"
                      name="te_boron_b_typical_in_air_dried_coal"
                      {...register11("te_boron_b_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_boron_b_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te boron b minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te boron b minimum"
                      name="te_boron_b_minimum_in_air_dried_coal"
                      {...register11("te_boron_b_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_boron_b_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te boron b maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te boron b maximum"
                      name="te_boron_b_maximum_in_air_dried_coal"
                      {...register11("te_boron_b_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_boron_b_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te cadmium cd typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te cadmium cd typical"
                      name="te_cadmium_cd_typical_in_air_dried_coal"
                      {...register11("te_cadmium_cd_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_cadmium_cd_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te cadmium cd minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te cadmium cd minimum"
                      name="te_cadmium_cd_minimum_in_air_dried_coal"
                      {...register11("te_cadmium_cd_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_cadmium_cd_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te cadamium cd maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te cadamium cd maximum"
                      name="te_cadamium_cd_maximum_in_air_dried_coal"
                      {...register11("te_cadamium_cd_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_cadamium_cd_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chromium cr typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chromium cr typical"
                      name="te_chromium_cr_typical_in_air_dried_coal"
                      {...register11("te_chromium_cr_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_chromium_cr_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chromium cr minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chromium cr minimum"
                      name="te_chromium_cr_minimum_in_air_dried_coal"
                      {...register11("te_chromium_cr_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_chromium_cr_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chromium cr maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chromium cr maximum"
                      name="te_chromium_cr_maximum_in_air_dried_coal"
                      {...register11("te_chromium_cr_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_chromium_cr_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te copper cu typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te copper cu typical"
                      name="te_copper_cu_typical_in_air_dried_coal"
                      {...register11("te_copper_cu_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_copper_cu_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te copper cu minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te copper cu minimum"
                      name="te_copper_cu_minimum_in_air_dried_coal"
                      {...register11("te_copper_cu_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_copper_cu_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te copper cu maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te copper cu maximum"
                      name="te_copper_cu_maximum_in_air_dried_coal"
                      {...register11("te_copper_cu_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_copper_cu_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="te chlorine cl typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="te chlorine cl typical"
                      name="te_chlorine_cl_typical_in_air_dried_coal"
                      {...register11("te_chlorine_cl_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_chlorine_cl_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chlorine cl minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chlorine cl minimum"
                      name="te_chlorine_cl_minimum_in_air_dried_coal"
                      {...register11("te_chlorine_cl_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_chlorine_cl_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chlorine cl maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chlorine cl maximum"
                      name="te_chlorine_cl_maximum_in_air_dried_coal"
                      {...register11("te_chlorine_cl_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_chlorine_cl_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te fluorine f typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te fluorine f typical"
                      name="te_fluorine_f_typical_in_air_dried_coal"
                      {...register11("te_fluorine_f_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_fluorine_f_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te fluorine f minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te fluorine f minimum"
                      name="te_fluorine_f_minimum_in_air_dried_coal"
                      {...register11("te_fluorine_f_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_fluorine_f_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te fluorine f maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te fluorine f maximum"
                      name="te_fluorine_f_maximum_in_air_dried_coal"
                      {...register11("te_fluorine_f_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_fluorine_f_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te mercury hg typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te mercury hg typical"
                      name="te_mercury_hg_typical_in_air_dried_coal"
                      {...register11("te_mercury_hg_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_mercury_hg_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te mercury hg minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te mercury hg minimum"
                      name="te_mercury_hg_minimum_in_air_dried_coal"
                      {...register11("te_mercury_hg_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_mercury_hg_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te mercury hg maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te mercury hg maximum"
                      name="te_mercury_hg_maximum_in_air_dried_coal"
                      {...register11("te_mercury_hg_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_mercury_hg_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te molybdenum mo typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te molybdenum mo typical"
                      name="te_molybdenum_mo_typical_in_air_dried_coal"
                      {...register11("te_molybdenum_mo_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_molybdenum_mo_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te molybdenum mo minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te molybdenum mo minimum"
                      name="te_molybdenum_mo_minimum_in_air_dried_coal"
                      {...register11("te_molybdenum_mo_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_molybdenum_mo_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te molybdenum mo maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te molybdenum mo maximum"
                      name="te_molybdenum_mo_maximum_in_air_dried_coal"
                      {...register11("te_molybdenum_mo_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_molybdenum_mo_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te lead pb typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te lead pb typical"
                      name="te_lead_pb_typical_in_air_dried_coal"
                      {...register11("te_lead_pb_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_lead_pb_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te lead pb minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te lead pb minimum"
                      name="te_lead_pb_minimum_in_air_dried_coal"
                      {...register11("te_lead_pb_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_lead_pb_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te lead pb maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te lead pb maximum"
                      name="te_lead_pb_maximum_in_air_dried_coal"
                      {...register11("te_lead_pb_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_lead_pb_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te phosphorus p typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te phosphorus p typical"
                      name="te_phosphorus_p_typical_in_air_dried_coal"
                      {...register11("te_phosphorus_p_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_phosphorus_p_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te phosphorus p minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te phosphorus p minimum"
                      name="te_phosphorus_p_minimum_in_air_dried_coal"
                      {...register11("te_phosphorus_p_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_phosphorus_p_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te phosphorus p maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te phosphorus p maximum"
                      name="te_phosphorus_p_maximum_in_air_dried_coal"
                      {...register11("te_phosphorus_p_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_phosphorus_p_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te selenium se typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te selenium se typical"
                      name="te_selenium_se_typical_in_air_dried_coal"
                      {...register11("te_selenium_se_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_selenium_se_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te selenium se minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te selenium se minimum"
                      name="te_selenium_se_minimum_in_air_dried_coal"
                      {...register11("te_selenium_se_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_selenium_se_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te selenium se maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te selenium se maximum"
                      name="te_selenium_se_maximum_in_air_dried_coal"
                      {...register11("te_selenium_se_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_selenium_se_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te vanadium v typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te vanadium v typical"
                      name="te_vanadium_v_typical_in_air_dried_coal"
                      {...register11("te_vanadium_v_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_vanadium_v_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te vanadium v min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te vanadium v min"
                      name="te_vanadium_v_min_in_air_dried_coal"
                      {...register11("te_vanadium_v_min_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_vanadium_v_min_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te vanadium v max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te vanadium v max"
                      name="te_vanadium_v_max_in_air_dried_coal"
                      {...register11("te_vanadium_v_max_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_vanadium_v_max_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te zinc zn typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te zinc zn typical"
                      name="te_zinc_zn_typical_in_air_dried_coal"
                      {...register11("te_zinc_zn_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_zinc_zn_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te zinc zn min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te zinc zn min"
                      name="te_zinc_zn_min_in_air_dried_coal"
                      {...register11("te_zinc_zn_min_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_zinc_zn_min_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te zinc zn max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te zinc zn max"
                      name="te_zinc_zn_max_in_air_dried_coal"
                      {...register11("te_zinc_zn_max_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors11.te_zinc_zn_max_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
               
                
              </Row> 
              <input type="submit"  className="addaccount-save" value='Next'/>
              <div className='successDiv3'></div>
            </Form>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={dataUid10}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Productsizing
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <Form onSubmit={handleSubmit12(productSizing)}>
              <Row className="mb-3">
                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size 0 to 50mm typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size 0 to 50mm typical"
                      name="size_0_to_50mm_typical"
                      {...register12("size_0_to_50mm_typical", { valueAsNumber:true, required: false })}
                    />
                    {errors12.size_0_to_50mm_typical && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size 0 to 50mm min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size 0 to 50mm min"
                      name="size_0_to_50mm_min"
                      {...register12("size_0_to_50mm_min", { valueAsNumber:true, required: false })}
                    />
                    {errors12.size_0_to_50mm_min && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size 0 to 50mm max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size 0 to 50mm max"
                      name="size_0_to_50mm_max"
                      {...register12("size_0_to_50mm_max", { valueAsNumber:true, required: false })}
                    />
                    {errors12.size_0_to_50mm_max && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Above 0 to 50mm typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Above 0 to 50mm typical"
                      name="above_0_to_50mm_typical"
                      {...register12("above_0_to_50mm_typical", { valueAsNumber:true, required: false })}
                    />
                    {errors12.above_0_to_50mm_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Above 0 to 50mm min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Above 0 to 50mm min"
                      name="above_0_to_50mm_min"
                      {...register12("above_0_to_50mm_min", { valueAsNumber:true, required: false })}
                    />
                    {errors12.above_0_to_50mm_min && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Above 0 to 50mm max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Above 0 to 50mm max"
                      name="above_0_to_50mm_max"
                      {...register12("above_0_to_50mm_max", { valueAsNumber:true, required: false })}
                    />
                    {errors12.above_0_to_50mm_max && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Under 2mm typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Under 2mm typical"
                      name="under_2mm_typical"
                      {...register12("under_2mm_typical", { valueAsNumber:true, required: false })}
                    />
                    {errors12.under_2mm_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Under 2mm min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Under 2mm min"
                      name="under_2mm_min"
                      {...register12("under_2mm_min", { valueAsNumber:true, required: false })}
                    />
                    {errors12.under_2mm_min && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Under 2mm max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Under 2mm max"
                      name="under_2mm_max"
                      {...register12("under_2mm_max", { valueAsNumber:true, required: false })}
                    />
                    {errors12.under_2mm_max && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size above 2mm typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size above 2mm typical"
                      name="size_above_2mm_typical"
                      {...register12("size_above_2mm_typical", { valueAsNumber:true, required: false })}
                    />
                    {errors12.size_above_2mm_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size above 2mm min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size above 2mm min"
                      name="size_above_2mm_min"
                      {...register12("size_above_2mm_min", { valueAsNumber:true, required: false })}
                    />
                    {errors12.size_above_2mm_min && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> 
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size above 2mm max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size above 2mm max"
                      name="size_above_2mm_max"
                      {...register12("size_above_2mm_max", { valueAsNumber:true, required: false })}
                    />
                    {errors12.size_above_2mm_max && <span>Required </span>}
                  </FloatingLabel>
                  </Form.Group> 
    
              </Row> 
              <input type="submit"  className="product-sizing" value='Next'/>
              <div className='successDiv'></div>
            </Form>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={dataUid11}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Product Testing Method
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    
        <Form onSubmit={handleSubmit13(testing)}>
              <Row className="mb-3">
                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="12">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Testing Method"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Testing Method"
                      name="testing_method"
                      {...register13("testing_method", {  required: false })}
                    />
                    {errors13.testing_method && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group> 
              </Row> 
              <input type="submit"  className="addaccount-save" value='Next'/>
              <div className='successDiv11'></div>
            </Form>
                     
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={dataUid12}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Product System Info
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <Form onSubmit={handleSubmit14(lastForm)}>
              <Row className="mb-3">
                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="12">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Last modified by"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Last modified by"
                      name="last_modified_by"
                      {...register14("last_modified_by", { required: false })}
                    />
                    {errors14.last_modified_by && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group> 
              </Row> 
              <input type="submit"  className="addaccount-save" value='Save'/>
              <div className='successDiv6'></div>
            </Form>
                     
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
        </div>
        <Footer/>
        </div>
    )
}