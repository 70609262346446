// import React, { createContext, useContext, useEffect, useState } from 'react';
// import axios from 'axios';
// import { AES, enc } from 'crypto-js';
// import { useNavigate } from 'react-router-dom';

// export const UsernameContext = createContext();
// export const TokenContext = createContext();
// const isTokenValid = (token) => {
//   try {
//     const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
//     const decryptedAccessToken = AES.decrypt(token, secret_key).toString(enc.Utf8);

//     if (!decryptedAccessToken) {
//       return false;
//     }

//     const tokenParts = decryptedAccessToken.split('.');
//     const payload = JSON.parse(atob(tokenParts[1]));
//     const expirationDate = new Date(payload.exp * 1000);

//     return !isNaN(expirationDate.getTime()) && expirationDate > new Date();
//   } catch (error) {
//     console.error('Token validation error:', error);
//     return false;
//   }
// };
// const AuthProvider = ({ children }) => {
//   const [selectedView, setSelectedViews] = useState('');
//   const [allTableData, setAllTableData] = useState([]);
//   const [otherTableData, setOtherTableData] = useState([]);
//   const [username, setUsername] = useState([]);
//   const navigate = useNavigate();
//   const [token, setToken] = useState(localStorage.getItem('access_token'));
//   const [isOnline, setIsOnline] = useState(navigator.onLine);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const handleOnlineStatus = () => {
//       setIsOnline(navigator.onLine);
//     };

//     window.addEventListener('online', handleOnlineStatus);
//     window.addEventListener('offline', handleOnlineStatus);

//     setIsOnline(navigator.onLine);

//     return () => {
//       window.removeEventListener('online', handleOnlineStatus);
//       window.removeEventListener('offline', handleOnlineStatus);
//     };
//   }, []);

//   useEffect(() => {
//     const checkAuthentication = async () => {
//       const storedToken = localStorage.getItem('access_token');

//       if (!localStorage.getItem('access_token')) {
//         navigate('/login');
//         setIsLoading(false);
//         return;
//       }

//       try {
//         const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
//         const decryptedAccessToken = AES.decrypt(storedToken, secret_key).toString(enc.Utf8);

//         if (!decryptedAccessToken) {
//           navigate('/login');
//           setIsLoading(false);
//           return;
//         }

//         const tokenParts = decryptedAccessToken.split('.');
//         const payload = JSON.parse(atob(tokenParts[1]));
//         const expirationDate = new Date(payload.exp * 1000);

//         console.log("expirationDate",expirationDate)

//         if (!payload || !payload.exp || isNaN(expirationDate.getTime()) || expirationDate <= new Date()) {
//           navigate('/login');
//           setIsLoading(false);
//           return;
//         }

//         const usernameResponse = await axios.get(API_URL+'get/username', {
//           headers: { 'Authorization': `Bearer ${decryptedAccessToken} `},
//         });

//         setUsername(usernameResponse.data.username);
//       } catch (error) {
//         console.error(error);
//         navigate('/login');
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     checkAuthentication();
//   }, [navigate]);

//   // useEffect(() => {
//   //   const checkAuthentication = async () => {
//   //     const storedToken = localStorage.getItem('access_token');

//   //     if (!storedToken) {
//   //       navigate('/login');
//   //       setIsLoading(false);
//   //       return;
//   //     }

//   //     try {
//   //       const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
//   //       const decryptedAccessToken = AES.decrypt(storedToken, secret_key).toString(enc.Utf8);

//   //      const usernameResponse = await axios.get(API_URL+'get/username', {
//   //         headers: { 'Authorization': Bearer ${decryptedAccessToken} },
//   //       });

//   //       setIsLoading(true);

//   //       setUsername(usernameResponse.data.username);

//   //     } catch (error) {
//   //       console.error(error);
//   //       navigate('/login');
//   //     } finally {
//   //       setIsLoading(false);
//   //     }
//   //   };

//   //   checkAuthentication();
//   // }, [navigate]);


//   const login = async (email, password) => {
//     try {
//       if (!isOnline) {
//         navigate('/login');
//         return;
//       }

//       const response = await axios.post(API_URL+'login', {
//         email,
//         password,
//       });
//       setTimeout(() => {
//       setToken(response.data.access_token);
//       const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
//       const encryptedAccessToken = AES.encrypt(response.data.access_token, secret_key).toString();
//       localStorage.setItem('access_token', encryptedAccessToken);
//       localStorage.setItem('accordion', 'a');
//       localStorage.setItem('email', response.data.email);
//       localStorage.setItem('role', response.data.role);
//       setTimeout(refreshToken, 60 * 60 * 1000);

//       navigate('/');
//     }, 0);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const refreshToken = async () => {
//     const storedToken = localStorage.getItem('access_token');
//     if (!localStorage.getItem('access_token')) {
//       navigate('/login');
//       return;
//     }

//     try {
//       const response = await axios.post(API_URL+'refresh', {
//         token: storedToken,
//       });
//       setToken(response.data.access_token);
//       localStorage.setItem('access_token', response.data.access_token);
//       // Schedule a refresh in 60 minutes
//       setTimeout(refreshToken, 60 * 60 * 1000);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <UsernameContext.Provider value={username}>
//       <TokenContext.Provider
//         value={{
//           token,
//           setToken,
//           login,
//           username,
//           selectedView,
//           setSelectedViews,
//           allTableData,
//           setAllTableData,
//           otherTableData,
//           setOtherTableData,
//         }}
//       >
//         {isLoading ? null : children}
//       </TokenContext.Provider>
//     </UsernameContext.Provider>
//   );
// };

// const useAuth = () => {
//   const context = useContext(TokenContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };

// export { AuthProvider, useAuth };
// import React, { createContext, useContext, useEffect, useState } from 'react';
// import axios from 'axios';
// import { AES, enc } from 'crypto-js';
// import { useNavigate , useLocation} from 'react-router-dom';

// export const UsernameContext = createContext();
// export const TokenContext = createContext();
// const isTokenValid = (token) => {
//   try {
//     const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
//     const decryptedAccessToken = AES.decrypt(token, secret_key).toString(enc.Utf8);

//     if (!decryptedAccessToken) {
//       return false;
//     }

//     const tokenParts = decryptedAccessToken.split('.');
//     const payload = JSON.parse(atob(tokenParts[1]));
//     const expirationDate = new Date(payload.exp * 1000);

//     return !isNaN(expirationDate.getTime()) && expirationDate > new Date();
//   } catch (error) {
//     console.error('Token validation error:', error);
//     return false;
//   }
// };
// const AuthProvider = ({ children }) => {
//   const [selectedView, setSelectedViews] = useState('');
//   const [allTableData, setAllTableData] = useState([]);
//   const [otherTableData, setOtherTableData] = useState([]);
//   const [username, setUsername] = useState([]);
//   const navigate = useNavigate();
//   const [token, setToken] = useState(localStorage.getItem('access_token'));
//   const [isOnline, setIsOnline] = useState(navigator.onLine);
//   const [isLoading, setIsLoading] = useState(true);



//   // const location = useLocation();

//   // useEffect(() => {
//   //   const handleRouteChange = () => {
//   //     const currentPage = location.pathname;
//   //     localStorage.setItem('lastlogoutpage', currentPage);
//   //   };

//   //   handleRouteChange();

//   //   const unlisten = () => {
//   //     return () => {
//   //     };
//   //   };

//   //   return unlisten();


//   // }, [location]);


//   useEffect(() => {
//     const handleOnlineStatus = () => {
//       setIsOnline(navigator.onLine);
//     };

//     window.addEventListener('online', handleOnlineStatus);
//     window.addEventListener('offline', handleOnlineStatus);

//     setIsOnline(navigator.onLine);

//     return () => {
//       window.removeEventListener('online', handleOnlineStatus);
//       window.removeEventListener('offline', handleOnlineStatus);
//     };

//   }, []);





//   useEffect(() => {
//     const checkAuthentication = async () => {
//       const storedToken = localStorage.getItem('access_token');

//       if (!localStorage.getItem('access_token')) {
//         navigate('/login');
//         setIsLoading(false);
//         return;
//       }

//       try {
//         const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
//         const decryptedAccessToken = AES.decrypt(storedToken, secret_key).toString(enc.Utf8);

//         if (!decryptedAccessToken) {
//           navigate('/login');
//           setIsLoading(false);
//           return;
//         }

//         const tokenParts = decryptedAccessToken.split('.');
//         const payload = JSON.parse(atob(tokenParts[1]));
//         const expirationDate = new Date(payload.exp * 1000);

//         console.log("expirationDate",expirationDate)

//         if (!payload || !payload.exp || isNaN(expirationDate.getTime()) || expirationDate <= new Date()) {
//           navigate('/login');
//           setIsLoading(false);
//           return;
//         }

//         const usernameResponse = await axios.get(API_URL+'get/username', {
//           headers: { 'Authorization': `Bearer ${decryptedAccessToken}` },
//         });

//         setUsername(usernameResponse.data.username);
//       } catch (error) {
//         console.error(error);
//         navigate('/login');
//       } finally {
//         setIsLoading(false);
//       }
//     };


//       checkAuthentication();


//   }, [ navigate]);

//   // useEffect(() => {
//   //   const checkAuthentication = async () => {
//   //     const storedToken = localStorage.getItem('access_token');

//   //     if (!storedToken) {
//   //       navigate('/login');
//   //       setIsLoading(false);
//   //       return;
//   //     }

//   //     try {
//   //       const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
//   //       const decryptedAccessToken = AES.decrypt(storedToken, secret_key).toString(enc.Utf8);

//   //      const usernameResponse = await axios.get(API_URL+'get/username', {
//   //         headers: { 'Authorization': Bearer ${decryptedAccessToken} },
//   //       });

//   //       setIsLoading(true);

//   //       setUsername(usernameResponse.data.username);

//   //     } catch (error) {
//   //       console.error(error);
//   //       navigate('/login');
//   //     } finally {
//   //       setIsLoading(false);
//   //     }
//   //   };

//   //   checkAuthentication();
//   // }, [navigate]);



//   const login = async (email, password) => {
//     try {
//       if (!isOnline) {
//         navigate('/login');
//         return;
//       }

//       const response = await axios.post(API_URL+'login', {
//         email,
//         password,
//       });
//       setTimeout(() => {
//         console.log(response.data.role)
//       setToken(response.data.access_token);
//       const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
//       const encryptedAccessToken = AES.encrypt(response.data.access_token, secret_key).toString();
//       localStorage.setItem('access_token', encryptedAccessToken);
//       localStorage.setItem('accordion', 'a');
//       localStorage.setItem('email', response.data.email);
//       localStorage.setItem('role', response.data.role);
//       setTimeout(refreshToken, 60 * 60 * 1000);

//       navigate('/');
//     }, 0);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const refreshToken = async () => {
//     const storedToken = localStorage.getItem('access_token');
//     if (!localStorage.getItem('access_token')) {
//       navigate('/login');
//       return;
//     }

//     try {
//       const response = await axios.post(API_URL+'refresh', {
//         token: storedToken,
//       });
//       setToken(response.data.access_token);
//       localStorage.setItem('access_token', response.data.access_token);
//       // Schedule a refresh in 60 minutes
//       setTimeout(refreshToken, 60 * 60 * 1000);
//     } catch (error) {
//       console.error(error);
//     }
//   };



//   const logout = () => {
//     try {
//       setToken(null);
//       localStorage.removeItem('access_token');
//       localStorage.removeItem('refresh_token');

//       const storeLastLogoutPage = () => {
//         return new Promise((resolve) => {
//           localStorage.setItem('lastLogoutPage', window.location.pathname);

//           resolve();
//         });
//       };

//       storeLastLogoutPage().then(() => {
//         localStorage.removeItem('email');
//         localStorage.removeItem('uuid');
//         localStorage.removeItem('uuids');
//         localStorage.removeItem('product_id');
//         localStorage.removeItem('role');

//         navigate('/login');
//       });
//     } catch (error) {
//       console.error('Logout failed:', error);
//     }
//   };
//   return (
//     <UsernameContext.Provider value={username}>
//       <TokenContext.Provider
//         value={{
//           token,
//           setToken,
//           login,
//           username,
//           selectedView,
//           setSelectedViews,
//           allTableData,
//           setAllTableData,
//           otherTableData,
//           setOtherTableData,
//           logout
//         }}
//       >
//         {isLoading ? null : children}
//       </TokenContext.Provider>
//     </UsernameContext.Provider>
//   );
// };

// const useAuth = () => {
//   const context = useContext(TokenContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };

// export { AuthProvider, useAuth };



import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from './config';
import { AES, enc } from 'crypto-js';
import { useNavigate , useLocation} from 'react-router-dom';

export const UsernameContext = createContext();
export const TokenContext = createContext();
const isTokenValid = (token) => {
  try {
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    const decryptedAccessToken = AES.decrypt(token, secret_key).toString(enc.Utf8);

    if (!decryptedAccessToken) {
      return false;
    }

    const tokenParts = decryptedAccessToken.split('.');
    const payload = JSON.parse(atob(tokenParts[1]));
    const expirationDate = new Date(payload.exp * 1000);

    return !isNaN(expirationDate.getTime()) && expirationDate > new Date();
  } catch (error) {
    console.error('Token validation error:', error);
    return false;
  }
};
const AuthProvider = ({ children }) => {
  const [selectedView, setSelectedViews] = useState('');
  const [allTableData, setAllTableData] = useState([]);
  const [otherTableData, setOtherTableData] = useState([]);
  const [username, setUsername] = useState([]);
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem('access_token'));
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isLoading, setIsLoading] = useState(true);



  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    setIsOnline(navigator.onLine);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };

  }, []);





  useEffect(() => {
    const checkAuthentication = async () => {
      const storedToken = localStorage.getItem('access_token');

      if (!localStorage.getItem('access_token')) {
        localStorage.clear()
        navigate('/login');
        setIsLoading(false);
        return;
      }

      try {
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        const decryptedAccessToken = AES.decrypt(storedToken, secret_key).toString(enc.Utf8);

        if (!decryptedAccessToken) {
          localStorage.clear()
          navigate('/login');
          setIsLoading(false);
          return;
        }

        const tokenParts = decryptedAccessToken.split('.');
        const payload = JSON.parse(atob(tokenParts[1]));
        const expirationDate = new Date(payload.exp * 1000);

        console.log("expirationDate",expirationDate)

        if (!payload || !payload.exp || isNaN(expirationDate.getTime()) || expirationDate <= new Date()) {
          localStorage.clear()
          navigate('/login');
          setIsLoading(false);
          return;
        }

        const usernameResponse = await axios.get(API_URL+'get/username', {
        headers: { 'Authorization': `Bearer ${decryptedAccessToken}` },
      });
      console.log(usernameResponse)
      if (!usernameResponse.data.username  && usernameResponse.data.username === null) {
        localStorage.clear();
        navigate('/login');
        setIsLoading(false);
        return;
      }


        setUsername(usernameResponse.data.username);
      } catch (error) {
        console.error(error);
        navigate('/login');
      } finally {
        setIsLoading(false);
      }
    };


      checkAuthentication();


  }, [ navigate]);




  const login = async (email, password) => {
    try {
      if (!isOnline) {
        navigate('/login');
        return;
      }

      const response = await axios.post(API_URL+'login', {
        email,
        password,
      });
      setTimeout(() => {
        console.log(response.data.role)
      setToken(response.data.access_token);
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
      const encryptedAccessToken = AES.encrypt(response.data.access_token, secret_key).toString();
      localStorage.setItem('access_token', encryptedAccessToken);
      localStorage.setItem('accordion', 'a');
      localStorage.setItem('email', response.data.email);
      localStorage.setItem('role', response.data.role);
      localStorage.setItem('username', response.data.username);
      setTimeout(refreshToken, 60 * 60 * 1000);

      navigate('/');
    }, 0);
    } catch (error) {
      console.error(error);
    }
  };

  const refreshToken = async () => {
    const storedToken = localStorage.getItem('access_token');
    if (!localStorage.getItem('access_token')) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.post(API_URL+'refresh', {
        token: storedToken,
      });
      setToken(response.data.access_token);
      localStorage.setItem('access_token', response.data.access_token);
      setTimeout(refreshToken, 60 * 60 * 1000);
    } catch (error) {
      console.error(error);
    }
  };



  const logout = () => {
    try {
      setToken(null);
      localStorage.clear()
      const storeLastLogoutPage = () => {
        return new Promise((resolve) => {
          localStorage.setItem('lastLogoutPage', window.location.pathname);

          resolve();
        });
      };

      storeLastLogoutPage().then(() => {
        localStorage.clear()

        navigate('/login');
      });
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  return (
    <UsernameContext.Provider value={username}>
      <TokenContext.Provider
        value={{
          token,
          setToken,
          login,
          username,
          selectedView,
          setSelectedViews,
          allTableData,
          setAllTableData,
          otherTableData,
          setOtherTableData,
          logout
        }}
      >
        {isLoading ? null : children}
      </TokenContext.Provider>
    </UsernameContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };