import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,

  OverlayTrigger,
  
  Tooltip,
  Table,
} from "react-bootstrap"; 
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import { API_URL } from '../config';
import Header from '../Header' 
import Footer from '../Footer' 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import $ from 'jquery'
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams  } from "react-router-dom"; 
const ViewBank = () => {
  
  const params = useParams()
  const id=params.id 
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState({});
  const [history, setHistory] = useState([]);

  
  useEffect(() => {
   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}get/rwoodbanksbyID/`+id, { 
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.rwood_bank_details; 
      const history = response.data.history; 
      console.log(response)
      setDatas(data);     
      setHistory(history)  
      setTimeout(() => {
        $("#history").dataTable(); 
    }, 100); 
    } catch (error) {
      console.error(error);
    }
  };
  if (!datas) {
    return null;
  }
  return (
 
    <div id='view-page'> 
    <Header/>
        
                         
             <div className="contactAccount">
    
     <div className="page-header btn-margin" id='add-account'>
     <div>
       <h2 className="main-content-title tx-24 mg-b-5">Rwood Bank Details</h2>
       <ol className="breadcrumb"> 
         <li className="breadcrumb-item active" aria-current="page">Dashboard / Rwood Bank details</li>
       </ol>
     </div>
     
       </div> 
       <div className='tasks'>
       <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/bank/bank-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
   <div className=''> 
   
{datas.length > 0 ?(
  <>
  
  {datas.map((x)=>(
    <>
    <div className="row">
                     <div className="col-md-12" id="head">
                       <h4 className="heading">Rwood Bank Master Detail
</h4>
                       <hr></hr>
                     </div>
                   </div>
    <table class="table table-bordered account-table tables">
    <tbody>
      <tr>
      <td id="td-right">
          <span>Bank Branch Name</span>
          </td>
          <td>
           {x.bank_branch_name}
        </td>
        <td id="td-right">
          <span>Owner</span>
        </td>
        <td>
          {x.owner}
        </td>
      </tr>
      <tr>
      <td id="td-right">
          <span>Remark</span>
          </td>
          <td>
           {x.remark}
        </td>
        <td id="td-right">
          </td>
          <td>
        </td>
      </tr>
      <tr>
        
      </tr>
      <tr>
      <td id="td-right">
          <span>Bank From Date</span>
          </td>
          <td>
           {x.bank_from_date}
        </td>
        <td id="td-right">
          </td>
          <td>
        </td>
      </tr>
      <tr>
      <td id="td-right">
          <span>Bank To Date</span>
          </td>
          <td>
           {x.bank_to_date}
        </td>
        <td id="td-right">
          </td>
          <td>
        </td>
      </tr>
      
      
      </tbody>
      </table>
    
      <div className="row">
                     <div className="col-md-12" id="head">
                       <h4 className="heading">Rwood Costing

</h4>
                       <hr></hr>
                     </div>
                   </div>
    <table class="table table-bordered account-table tables">
    <tbody>
      <tr>
      <td id="td-right">
          <span>Fixed other cost</span>
          </td>
          <td>
           {x.fixed_other_cost}
        </td>
        <td id="td-right">
          </td>
          <td>
        </td> 
      </tr>
      <tr>
      <td id="td-right">
          <span>Fixed appointed overheads</span>
          </td>
          <td>
           {x.fixed_appointed_overheads}
        </td>
       
        <td id="td-right">
          </td>
          <td>
        </td>
      </tr>
    
      
      
      </tbody>
      </table>

      
      <div className="row">
                     <div className="col-md-12" id="head">
                       <h4 className="heading">Bank Costs

</h4>
                       <hr></hr>
                     </div>
                   </div>
    <table class="table table-bordered account-table tables">
    <tbody>
      <tr>
      <td id="td-right">
          <span>LC transfer rate or advising cost</span>
          </td>
          <td>
           {x.lc_transfer_rate_or_advising_cost}
        </td>
        <td id="td-right">
          <span>adding confirmation charges</span>
          </td>
          <td>
           {x.adding_confirmation_charges}
        </td> 
      </tr>
      <tr>
      <td id="td-right">
          <span>LC negotiation or commission rate</span>
          </td>
          <td>
           {x.lc_negotiation_or_commission_rate}
        </td>
        <td id="td-right">
          <span>Interest</span>
          </td>
          <td>
           {x.interest}
        </td>
      </tr>
      <tr>
      <td id="td-right">
          </td>
          <td>
        </td>
        <td id="td-right">
          <span>Swift Charges</span>
          </td>
          <td>
           {x.swift_charges}
        </td>
      </tr>
      <tr>
      <td id="td-right">
          <span>Tax</span>
          </td>
          <td>
           {x.tax_percent}
        </td>
        <td id="td-right">
          </td>
          <td>
        </td>
      </tr>
      <tr>
      <td id="td-right">
          </td>
          <td>
        </td>
        <td id="td-right">
          </td>
          <td>
        </td>
      </tr>
      
      
      </tbody>
      </table>
      <div className="row">
                     <div className="col-md-12" id="head">
                       <h4 className="heading">Bank Address

</h4>
                       <hr></hr>
                     </div>
                   </div>
    <table class="table table-bordered account-table tables">
    <tbody>
      <tr>
      <td id="td-right">
          <span>Bank Full Name</span>
          </td>
          <td>
           {x.bank_full_name}
        </td>
        
        <td id="td-right">
          <span>Corresponding Bank</span>
          </td>
          <td>
           {x.corresponding_bank}
        </td>
      </tr>
      <tr>
      <td id="td-right">
          <span>
          Bank Account Number{" "}
            
          </span>
          </td><td>
          {x.bank_account_no}
        </td>
       
        <td id="td-right">
          <span>Corresponding Bank Swift</span>
          </td>
          <td>
           {x.corresponding_bank_swift}
        </td>
      </tr>
    <tr>
    <td id="td-right"><span>Rwood bank name </span></td>
        <td>{x.rwood_bank_name}</td>
        <td id="td-right"><span></span></td>
        <td> </td>
    </tr>
    <tr>
      <td id="td-right">
          <span>Bank Street</span>
          </td>
          <td>
           {x.bank_street}
        </td>
        <td id="td-right">
          <span>IBAN</span>
          </td>
          <td>
           {x.iban}
        </td>
       
      </tr>
      <tr>
      <td id="td-right">
          <span>Bank Country</span>
          </td>
          <td>
           {x.bank_country}
        </td>
        <td id="td-right">
          <span>Swift Code</span>
          </td>
          <td>
           {x.swift_code}
        </td>
      </tr>
      <tr>
      <td id="td-right">
          <span>Created By</span>
          </td>
          <td>
           {x.created_at}
        </td>
        <td id="td-right">
          <span>Update By</span>
          </td>
          <td>
           {x.updated_at}
        </td>
      </tr>
      </tbody>
      </table>
  

                <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Open Activities</span>
                                  <span>
                                    <i className=""></i>
                                    New Task
                                  </span>
                                  <span>
                                    <i className=""></i>
                                    New Event
                                  </span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>
                            <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Activity History</span>
                              <span>
                                <i className=""></i>
                                Log a Call
                              </span>
                              <span>
                                <i className=""></i>
                                Mail merge
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Trade Confirmation Forms</span>
                              <span>
                                <i className=""></i>
                                New Trade Confirmation Forms
                              </span>
                              
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Debit Notes</span>
                              <span>
                                <i className=""></i>
                                New Note
                              </span>
                              
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                        
                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Pre Shipment Documents</span>
                              <span>
                                <i className=""></i>
                                New Pre Shipment Documents
                              </span>
                              
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                        <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>History Information</span></h4>
                    <hr></hr>
                  </div>
                  <table id="history" className="">
                    <thead>
                      <tr>
                        <th>Field Name</th>
                        <th>New Value</th>
                        <th>Old Value</th>
                        <th>Changed By</th>
                        <th>Change Time</th>
                      </tr>
                    </thead>

                    {history.length > 0 ? (
                      <tbody>
                        {history.map((y, i) => (
                          <tr>
                            <td>{y.field_name}</td>
                            <td>{y.new_value}</td>
                            <td>{y.old_value}</td>
                            <td>{y.changed_by}</td>
                            <td>{y.change_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </Row>
                        <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/bank/bank-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
  </>
  ))}
  </>
):(
  <></>
)}
      </div>
      </div></div>
      <Footer/>
      </div>
  );
};

export default ViewBank;