import React, { useState, useMemo, useEffect, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from '../Header'
import Footer from '../Footer' 
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css'; 
import axios from "axios";
import DatePicker from "react-datepicker";
import { AES, enc } from "crypto-js";
import $ from 'jquery';
import { API_URL } from '../config';
import { Link, useNavigate } from "react-router-dom";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
function AddBank() {
  const navigate=useNavigate();
  const [fromDate, setFromDate] = useState(null);
  const fromDates = fromDate ? `${fromDate.getDate().toString().padStart(2, '0')}/${(fromDate.getMonth() + 1).toString().padStart(2, '0')}/${fromDate.getFullYear()}` : null; 
  const handlefromDateChange = (date) => {
    setFromDate(date);
  };
  const [toDate, setToDate] = useState(null);
  const toDates = toDate ? `${toDate.getDate().toString().padStart(2, '0')}/${(toDate.getMonth() + 1).toString().padStart(2, '0')}/${toDate.getFullYear()}` : null; 
  const handleToDateChange = (date) => {
    setToDate(date);
  };
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height() 
  const total_height=header+nav+footer;
  const window_height=body-total_height;  
  // $(".priceAccount div:nth-child(3)").css({"min-height":(window_height)+"px"})
  const [datas,setDatas]=useState([]);  
  const [getPrice, setgetPrice]=useState([]);
  // useEffect(() => {
   
  //   fetchData();
  // }, [decryptedAccessToken]);

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(`${API_URL}get/all/price_items', {
  //       headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
  //     );
  //     const data = response.data; 
  //     setgetPrice(data);
       
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  const adminRole=localStorage.getItem('role')
console.log(getPrice)
 const { register, handleSubmit, reset, formState: { errors } } = useForm();
 const onSubmit = data =>{ 
      const responseData={
        remark:data.remark,
         rwood_bank_name:data.rwood_bank_name,
lc_type:data.lc_type,
bank_from_date:fromDates?fromDates:null,
bank_to_date:toDates?toDates:null,
owner:data.owner,
lc_transfer_rate_or_advising_cost:data.lc_transfer_rate_or_advising_cost,
lc_negotiation_or_commission_rate:data.lc_negotiation_or_commission_rate,
tax_percent:data.tax_percent,
adding_confirmation_charges:data.adding_confirmation_charges,
interest:data.interest,
swift_charges:data.swift_charges,
fixed_other_cost:data.fixed_other_cost,
fixed_appointed_overheads:data.fixed_appointed_overheads,
bank_full_name:data.bank_full_name,
bank_account_no:data.bank_account_no,
bank_branch_name:data.bank_branch_name,
bank_street:data.bank_street,
bank_country:data.bank_country,
corresponding_bank:data.corresponding_bank,
corresponding_bank_swift:data.corresponding_bank_swift,
iban:data.iban,
swift_code:data.swift_code,
      }
console.log(JSON.stringify(responseData))
   const encryptedAccessToken = localStorage.getItem('access_token');
     const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    
     if (encryptedAccessToken) {
       const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
       
     fetch(`${API_URL}add/rwoodbank`, {
               method: "POST",
               body: JSON.stringify(responseData),
               headers: { Authorization: `Bearer ${decryptedAccessToken}`,
               'Content-Type': 'application/json' },
             })
               .then((response) => response.json())
              
               .then((response) => { 
                console.log(response)
                toast.success('Bank added successfully', {
                  position: "bottom-center",
                  autoClose: 500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
              //   console.log('Test'+JSON.stringify(response)) 
              //  $('.priceDiv').html("<p class='result'>"+response.MSG+"</p>"); 
              // //  $('.priceDiv').hide(2000)
              navigate(`/bank/bank-details/${response.rwood_bank_id}`)
               reset();
               })
               
             } 
              
     };

  return (

    <div className="priceAccount m-b">
    <Header/>
    <div>
    <div className="page-header" id=''>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Bank</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Bank / Add bank</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div> 
     <Form onSubmit={handleSubmit(onSubmit)} id='price'>
     {Object.keys(errors).length > 0 && (
  <div className="alert alert-danger" role="alert">
    Please fix the following errors:
    <ul>
      {Object.keys(errors).map((fieldName, index) => (
        <li key={index}>{errors[fieldName]?.message}</li>
      ))}
    </ul>
  </div>
)}
     <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/bank/bank-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <Row className="mb-3">
      <h4 className="heading">Information</h4>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Rwood bank name"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Rwood bank name"
              name="rwood_bank_name" 
              className={`error-validation form-control ${errors.rwood_bank_name ? 'is-invalid' : ''}`}
              {...register("rwood_bank_name", { required: 'Rwood Bank name is required' })} 
            />
            {/* {errors.rwood_bank_name && <span>Required</span>} */}
            {errors.rwood_bank_name && <div className="invalid-feedback"></div>}
          </FloatingLabel>
        </Form.Group>   
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Owner"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Owner"
              name="owner"
              className="record-type"
              defaultValue={adminRole}
              {...register("owner", { required: false })}
            />
            {errors.owner && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>  
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Remark"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Remark"
              name="remark"
              {...register("remark", { required: false })}
            />
            {errors.remark && <span>Required</span>}
          </FloatingLabel>
        </Form.Group> 
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="LC Type">
    <Form.Select aria-label="Floating label select example"
    name="lc_type" 
    className={`error-validation form-control ${errors.lc_type ? 'is-invalid' : ''}`}
              {...register("lc_type", { required: 'LC Type is required' })} 
    >
      <option value="">--None--</option><option value="Irrevocable transferrable Letter of Credit">Irrevocable transferrable Letter of Credit</option><option value="Irrevocable Non transferrable Letter of Credit">Irrevocable Non transferrable Letter of Credit</option><option value="Irrevocable Letter of Credit">Irrevocable Letter of Credit</option><option value="Irrevocable Back to Back LC">Irrevocable Back to Back LC</option><option value="Payment and Receipt">Payment and Receipt</option><option value="Standby_letter_of_credit_c">Standby Letter of Credit</option>
    </Form.Select> 
    {errors.lc_type && <div className="invalid-feedback"></div>}
  </FloatingLabel>
  
              </Form.Group>  
              <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
        <FloatingLabel
            controlId="floatingInput"
            label="From  Date"
            className="mb-3 datepick"
            
            
          >
        <DatePicker 
              selected={fromDate}
        onChange={handlefromDateChange}
        
        dateFormat='dd/MM/yyyy'
         placeholderText="Select" 
      />
       </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Bank To Date"
            className="mb-3 datepick"
            
            
          >
            <DatePicker 
              selected={toDate}
        onChange={handleToDateChange}
        
        dateFormat='dd/MM/yyyy'
         placeholderText="Select" 
      />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
           
        </Row> 
        <Row>
        <h4 className="heading">Bank Costs</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="LC transfer rate or advising cost"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="LC transfer rate or advising cost"
              name="lc_transfer_rate_or_advising_cost" 
              className={`error-validation form-control ${errors.lc_transfer_rate_or_advising_cost ? 'is-invalid' : ''}`}
              {...register("lc_transfer_rate_or_advising_cost", { required: 'LC transfer rate or advising cost is required', validate: {
                isNumber: value => !isNaN(value) || 'Input must be a number'
              } })} 
            />
            
            {errors.lc_transfer_rate_or_advising_cost && <div className="invalid-feedback"></div>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Adding confirmation charges"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Adding confirmation charges"
              name="adding_confirmation_charges"
              className={`error-validation form-control ${errors.adding_confirmation_charges ? 'is-invalid' : ''}`}
              {...register("adding_confirmation_charges", { required: 'Adding confirmation charges is required', validate: {
                isNumber: value => !isNaN(value) || 'Input must be a number'
              } })}
            />
               {errors.adding_confirmation_charges && <div className="invalid-feedback"></div>}
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="LC negotiation or commission rate"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="LC negotiation or commission rate"
              name="lc_negotiation_or_commission_rate"
              className={`error-validation form-control ${errors.lc_negotiation_or_commission_rate ? 'is-invalid' : ''}`}
              {...register("lc_negotiation_or_commission_rate", { required: 'LC Type is required', validate: {
                isNumber: value => !isNaN(value) || 'Input must be a number'
              }  })} 
            />
            {errors.lc_negotiation_or_commission_rate && <div className="invalid-feedback"></div>}
          </FloatingLabel>
        </Form.Group>   
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Interest"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Interest"
              name="interest"
              className={`error-validation form-control ${errors.interest ? 'is-invalid' : ''}`}
              {...register("interest", { required: 'Interest', validate: {
                isNumber: value => !isNaN(value) || 'Input must be a number'
              }  })} 
           
            />
            {errors.interest && <div className="invalid-feedback"></div>}
          </FloatingLabel>
        </Form.Group> 
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Tax"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Tax"
              name="tax_percent"
              className={`error-validation form-control ${errors.tax_percent ? 'is-invalid' : ''}`}
              {...register("tax_percent", { required: 'Tax is required', validate: {
                isNumber: value => !isNaN(value) || 'Input must be a number'
              } })} 
           
            />
            {errors.tax_percent && <div className="invalid-feedback"></div>}
          </FloatingLabel>
        </Form.Group>    
        
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Swift charges"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Swift charges"
              name="swift_charges"
              {...register("swift_charges",  {valueAsNumber:true, required: false })}
            />
            {/* {errors.swift_charges && <span>Required</span>} */}
          </FloatingLabel>
        </Form.Group> 
        </Row>
        <Row>
        <h4 className="heading">Rwood Costing</h4>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Fixed other cost"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Fixed other cost"
              name="fixed_other_cost"
              {...register("fixed_other_cost", {valueAsNumber:true, required: false })}
            />
            {errors.fixed_other_cost && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>  <Form.Group as={Col} md="6"></Form.Group> <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Fixed appointed overheads"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Fixed appointed overheads"
              name="fixed_appointed_overheads"
              {...register("fixed_appointed_overheads", {valueAsNumber:true, required: false })}
            />
            {errors.fixed_appointed_overheads && <span>Required</span>}
          </FloatingLabel>
        </Form.Group> 
        
        </Row>
        <Row>
        <h4 className="heading">Bank Address</h4>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Bank full name"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Bank full name"
              name="bank_full_name"
              {...register("bank_full_name", { required: false })}
            />
            {errors.bank_full_name && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Bank account no"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Bank account no"
              name="bank_account_no"
              {...register("bank_account_no", {valueAsNumber:true, required: false })}
            />
            {errors.bank_account_no && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Bank branch name"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Bank_branch name"
              name="bank_branch_name"
              {...register("bank_branch_name", { required: false })}
            />
            {errors.bank_branch_name && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Bank street"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Bank street"
              name="bank_street"
              {...register("bank_street", { required: false })}
            />
            {errors.bank_street && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Bank country"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Bank country"
              name="bank_country"
              {...register("bank_country", { required: false })}
            />
            {errors.bank_country && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Corresponding bank"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Corresponding bank"
              name="corresponding_bank"
              {...register("corresponding_bank", { required: false })}
            />
            {errors.corresponding_bank && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Corresponding bank swift"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Corresponding bank swift"
              name="corresponding_bank_swift"
              {...register("corresponding_bank_swift", { required: false })}
            />
            {errors.corresponding_bank_swift && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Iban"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Iban"
              name="iban"
              {...register("iban", { required: false })}
            />
            {errors.iban && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Swift code"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Swift code"
              name="swift_code"
              {...register("swift_code", { required: false })}
            />
            {errors.swift_code && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>      
        
      </Row>
      <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/bank/bank-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <div className='priceDiv'><p></p><p></p></div>
    </Form>
    </div>
    <Footer/>
    </div>
  );
}

export default AddBank;
