import React, { Component, useState, useEffect, useContext, forwardRef } from "react";
import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";

import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-dt/js/dataTables.dataTables.js";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import $ from "jquery";
import { parse, format , isValid } from 'date-fns';
import DatePicker from "react-datepicker";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Popup from "reactjs-popup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { API_URL } from '../config';
import Editor from 'react-simple-wysiwyg';
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";

import { IoSearch } from "react-icons/io5";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams,useLocation } from "react-router-dom";
const PostreceiptDetail = () => {
 
  const params = useParams();
  const id = params.id;
  const userRole = localStorage.getItem("role");
  
  const [selectedProductCode, setSelectedProductCode] = useState("");
  const [lineitemText, setLineitemText] = useState("");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
  const [line, setLine] = useState([]);
  const [OrderID,setOrderId]=useState([]);


  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [bank, setBank] = useState([]);
  useEffect(() => {
   
    fetchDatasBank();
  }, [decryptedAccessToken]);

  const fetchDatasBank = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/rwoodbank', { 
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      console.log(response)
      const data = response.data.rwood_bank; 
      setBank(data);     
    } catch (error) {
      console.error(error);
    }
  };
 

  const [startDate1, setStartDate1] = useState(null);

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/pay_recievebyID/" + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      
      console.log(response)
      
      setOrderId(response.data.pay_recieve_lineitem[0].order_id)
      const data = response.data.rw_pay_detail[0];
      setLine(response.data.pay_recieve_lineitem)
      setDatas(data)
      $('#tables-pay').DataTable()
      let birth_Date=data.document_date

      if (birth_Date !== null){
        const defaultDatepicker = parse(birth_Date, 'dd/MM/yyyy', new Date()); 
        setStartDate1(defaultDatepicker);
      }else{
        setStartDate1(new Date())
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [accountName, setAccountName] = useState([]);
  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(API_URL+"get/buyer", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });
      const data = response.data;
      setAccountName(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setDatas({ ...datas, [name]: value });
  };
  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setDatas({ ...datas, [name]: value });
  };
  
  useEffect(() => {
    if (datas.description) {
      setEditor1(datas.description);
    }
  }, [datas]);



  const [bdate, setbirthDate]=useState('')
  useEffect(()=>{
    const dateObject = new Date(startDate1); 
    if (dateObject) { 
      const formattedDate = format(dateObject, 'dd/MM/yyyy', { timeZone: 'UTC' });
      setbirthDate(formattedDate)
      console.log(formattedDate)
    }  
  })



  const [editor1, setEditor1] = useState(datas.description)
   function onChanges(e) {
    console.log('onChange event:', e);
    console.log('Current HTML:', e.target.value);
    setEditor1(e.target.value);
  }




  const [lineItems, setlineItems] = useState([]);
 
  const lineItemLists = async () => {
    if (OrderID) {
    try {
      const response = await axios.get(
        API_URL+"get/all/financeconfig",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.all_finance;
      setlineItems(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  }
  };
  useEffect(() => {
    lineItemLists();
  }, [decryptedAccessToken]);


  const location = useLocation();

  const { postId } = location.state || {};
  console.log(postId)


  const [lineitems, setLineitems]=useState([])
  

  const fetchDatas = async () => {
    if (OrderID) {
    try {
      const response = await axios.get(
        `${API_URL}order/post_shipemnt/${OrderID}`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.order_postshipments; 
      console.log(response);
       setLineitems(data)
 
    } catch (error) {
      console.error(error);
    }
  }
  };
  useEffect(() => {
    fetchDatas();
  }, [OrderID,decryptedAccessToken]);


  const [lineItem, setlineItem] = useState([]);
  useEffect(() => {
    lineItemList();
  }, [decryptedAccessToken]);

  const lineItemList = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/financeconfig",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.all_finance;
      setlineItem(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };


  // edit option
  const [data, setData] = useState({}); 
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


 const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);
    $('#tables-pay').DataTable();
  };
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); 
  useEffect(() => {
    setIsButtonEnabled(true);
  }, []);

  
  const [lockeds, setLockeds] = useState(null);  
  const [payments, setPayments] = useState(null);  
  

  const chek_bok = datas.locked;
  const chek_payment = datas.payment;
  useEffect(() => {
    setLockeds(chek_bok);
    setPayments(chek_payment) 
  }, [chek_bok, chek_payment]); // Run this effect whenever tt changes

  useEffect(() => {
  }, [lockeds, payments]); // Run

  const handleCheckboxChange1 = (e) => {
    setLockeds(e.target.checked);
  };
  const handleCheckboxChange2 = (e) => {
    setPayments(e.target.checked);
  };


  
  const mapData = () => {
    if (line.length > 0) {
      const mappedData = {};
  
      line.forEach((item) => {
        const id = item.id;
  
        if (!mappedData[id]) {
          mappedData[id] = [];
        }
  
        mappedData[id].push({
          currency: item.currency,
          id: item.id,
          quantity: item.quantity,
          exchange_rate: item.exchange_rate,
          description: item.description,
          amount: item.amount,
          unit_rate: item.unit_rate,
          post_ship_document: item.post_ship_document,
          line_item: item.line_item,
          line_item_type: item.line_item_type,
          order:item.order
        });
      });
  
      // Combine all the arrays into a single array
      const combinedData = Object.values(mappedData).reduce(
        (result, data) => [...result, ...data],
        []
      );
  
      setFormData1(combinedData);
    }
  };
  

  // const mapData = () => {
  //   if (line.length > 0) {
  //     const combinedData = line.reduce((result, item) => {
  //       return {
  //         ...result,
  //         ...item,
  //       };
  //     }, {});

  //     setFormData1(combinedData);
  //   }
  // };

  // Call the mapData function when needed, e.g., when formData2 changes
  useEffect(() => {
    mapData();
  }, [line]);

 



  const [formData1, setFormData1] = useState([{
    "currency":"",
    "id":"",
    "quantity":"",
    "exchange_rate":"",
    "description":editor1,
    "amount":"",
    "unit_rate":"",
    "post_ship_document":"",
    "line_item":"",
    "line_item_type":"",
    "order":"",
    "company":""   
  }]);
  



  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFormData = [...formData1];
    updatedFormData[index][name] = value;
    setFormData1(updatedFormData);
  };




 
  const handleClear = () => {
    setSelectedProductCode(""); 
    // setFormData1([{ line_item_type: "", line_item: "" }]); 
    setIsPopupOpen(true); // Open the popup
    // console.log("Cleared formData1:", { line_item_type: "", line_item: "" });
  };
 

  const handleRowClick = (productCode, lineItem, id) => {
    setSelectedProductCode(productCode); // Update selected product code
    setLineitemText(lineItem); // Update selected line item
    setIsPopupOpen(false); // Close the popup
  
    // Update formData1 with the selected product code, line item, and id
    setFormData1((prevFormData) => {
      const updatedFormData = [...prevFormData];
      
      // Update line item type, line item, and id
      updatedFormData[0] = {
        ...updatedFormData[0],
        line_item_type: productCode,
        line_item: lineItem
      };
  
      // Debugging logs
      console.log("Updated formData1:", updatedFormData);
  
      return updatedFormData; // Return the updated form data
    });
  };










  const adminRole=localStorage.getItem('role')
console.log(formData1)
  const handleSaveEdit = (e) => {
    
    e.preventDefault();  
$("#tables-pay").DataTable()
    const requestData={
      receive_update:formData1
    }

    const order_detail = {
      "currency":datas.currency,
      "exchange_rate":datas.exchange_rate,
      "company":datas.company,
      "record_type":datas.record_type, 
      "owner":datas.owner,
      "document_reference_no":datas.document_reference_no,
      "debit_note_no":datas.debit_note_no, 
      "recieve_status":datas.recieve_status,
      "document_date":bdate,
      "rwood_bank_master":datas.rwood_bank_master,
      "description":editor1,
      "locked":lockeds,
      "payment":payments,
      "account":datas.account 

  }; 
  
  console.log("request Data",JSON.stringify(requestData))
    fetch(`${API_URL}update/rw_pay_recieve/${id}`, {  
      method: 'PUT',
      body: JSON.stringify(order_detail),
      headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
         
    })
      .then((response) => response.json())
      .then((updatedData) => {
       console.log(updatedData);
        $('#table').DataTable();
        // if(updatedData.success==="updated successfuly"){
         
          fetch(`${API_URL}update/pay_recieve/${id}`, {  
            method: 'PUT',
            body: JSON.stringify(requestData),
            headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                    'Content-Type': 'application/json' },
               
          })
            .then((response) => response.json())
            .then((updatedData) => {
              fetchData();
              console.log(updatedData)
              $('#table').DataTable();
            })
      
       
        setData(updatedData);
        setIsEditMode(false);
      })
      .catch((error) => console.error(error));
  };

  
   
  const [company, setCompany] = useState([]);
  useEffect(() => {
    fetchDataAccount();
  }, [decryptedAccessToken]);

  const fetchDataAccount = async () => {
    $('tables').DataTable();
    try {
      const response = await axios.get(
        API_URL+"get/all/rwoodcompany",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwoodcompany;
      setCompany(data);
      console.log(data);
    } catch (error) {

      console.error(error);
    }
  };



  return (
    <div id="view-page" className="lineitem-receipt">
      <Header />
      <div className="contactAccount">
        <div className="page-header btn-margin" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">
              Postshipment Receipt Details
            </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Postshipment / Receipt details
              </li>
            </ol>
          </div>
        </div>
        {isEditMode ? (
          <>
          <div className="tasks">
          <p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
     
     
     </p>
           <Row className="mb-3">
            <h4 className="heading">RW Payable Receivable Information</h4>
             <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="RW Payable/Receivable"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="RW Payable/Receivable"
                  name="order_code_no"                  
                  defaultValue={datas.rw_code_no || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group> 
             
            <Form.Group as={Col} md="6">
                  <label>Locked</label>
                  <input
                    type='checkbox'
                    name='locked'
                    checked={lockeds} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange1} // Handle the change event
                    className='mx-3'
                    
                  />
                </Form.Group>
                <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Company"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="company"
                  placeholder="Company"
                  defaultValue={datas.company }
                  onChange={handleChange2} 
                >
                  <option value="">Select</option>
                  {company.map((x) => {
                    return (
                      <option value={x.company_name}>{x.company_name}</option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Record Type"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Record Type"
                  name="record_type"
                  defaultValue={datas.record_type}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Currency"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="currency"
                  placeholder="Currency"
                  defaultValue={datas.currency || ""}
                  onChange={handleChangeInput}
                >
                  <option value="">Select</option>
                  <option value="USD">USD</option>
                  <option value="IDR">IDR</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Account"
              >
                <Form.Select
                tabIndex={10}
                  aria-label="Floating label select example"
                  name="account"
                  placeholder="Account"
                  value={datas.account }
                  onChange={handleChange1}
                >
                   <option value="">Select</option>
                    {accountName.length > 0 ? (
                      <>
                        {accountName.map((x) => {
                          return (
                            <option value={x.account_name}>
                              {x.account_name}
                            </option>
                          );
                        })}
                      </>
                    ) : (
                      <option className="no-data">No data available</option>
                    )}
                  
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Exchange Rate"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Exchange Rate"
                  name="exchange_rate"
                  className="clear"
                  defaultValue={datas.exchange_rate || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
            
           
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Owner ID"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Owner ID"
                  name="owner"

                  defaultValue={datas.order_owner || adminRole}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
                   
 <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Document Date"
            className="mb-3 datepick"
            
            
          >
         <DatePicker
          selected={startDate1}
          onChange={(date) => setStartDate1(date)}
          dateFormat="dd/MM/yyyy"
          placeholderText="Select a date" 
      />

 
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        
        <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Document Reference No"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Document Reference No"
                  style={{ height: "100px" }}
                  className="clear"
                  name="document_reference_no"
                  defaultValue={datas.document_reference_no || ""}   
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Debit Note No"
              >
                <Form.Control
                  type="text"
                  placeholder="Debit Note No"
                  style={{ height: "100px" }}
                  name="debit_note_no"
                  className="clear"
                  defaultValue={datas.debit_note_no || ""}  
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
                
                  <label>Description</label>
                  <Editor value={editor1} onChange={onChanges} />
                 
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Total Amount"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Total Amount"
                  name="total_amount"
                  className="clear"
                  defaultValue={datas.total_amount || ""}  
                  onChange={handleChangeInput}

                />
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="recieve_status"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="recieve_status"
                  placeholder="Status"
                  defaultValue={datas.recieve_status || ""}  
                  onChange={handleChangeInput}
                  className="clear"
                >
                  <option value="Raised" selected="selected">
                    Raised
                  </option>
                  <option value="Paid">Paid</option>
                  <option value="Partial Payment">Partial Payment</option>
                  <option value="Adjusted">Adjusted</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Settled By Arutmin">Settled By Arutmin</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
             
             <FloatingLabel controlId="floatingSelect" 
             className='dropDown' label="Rwood bank master" >
   <Form.Select aria-label="Floating label select example"
   name="rwood_bank_master" 
   placeholder="bank master master" 
   defaultValue={datas.rwood_bank_master || ""}  
                  onChange={handleChangeInput}
    
   > 
     <option value='' >Select</option>
      {
         bank.map((x)=>{
             return(
                 <option value={x.rwood_bank_name}>{x.rwood_bank_name}</option>
                  
             )
         })
      }
   </Form.Select>
    
 </FloatingLabel>
             </Form.Group> 

             <Form.Group as={Col} md="6">
              
                  <label>Payment</label>
                  <input
                    type='checkbox'
                    name='payment'
                    checked={payments} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange2} // Handle the change event
                    className='mx-3'
                    
                  />
                </Form.Group>
          </Row>
          <table id="rw-table" className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Order</th>
                <th>Post Shipment Document</th>
                <th>Line item type</th>
                <th>Line item</th>
                <th>Unit Rate</th>
                <th>Exchange Rate</th>
                <th>Quantity</th>
                <th>Description</th>
                <th>Amount</th>
               
              </tr>
            </thead>
            <tbody>



          {formData1.map((x,index)=>(
              <tr key={index}>
           <td style={{ width: '5%' }} >
                <input
                  type="text"
                  name="id"
                  className="form-control clear"
                  placeholder="id"
                  defaultValue={x.id}
                  onChange={(e) => handleInputChange(index, e)}
                 
                   
                />
              </td>
              <td>
                <input
                  type="text"
                  name="order"
                  placeholder="Order"
                  className="form-control"

                  // /value={formData1[index].currency !== undefined ? formData1[index].currency : ""}

                  defaultValue={ x.order}
                  onChange={(e) => handleInputChange(index, e)}
                  />
              </td>
              <td>
                
                <select
          name="post_ship_document"
          value={x.post_ship_document}
          className="form-control clear"  
          onChange={(e) => handleInputChange(index, e)}>
            <option>---None---</option>
          {lineitems?.map((x, idx) => (
            <option key={idx} value={x.post_code_no}>{x.post_code_no}</option>
          ))}
        </select>
              </td>

              
              

            
              <td>
              <div className="lineGroup">
        <input
          type="text"
          placeholder="Line Item Type"
          name="line_item_type"
          id="line_item_clear"
          value={selectedProductCode || x.line_item_type}
          // Handle input change
          onChange={(e) => {
            const newValue = e.target.value;
            setFormData1((prev) => {
              const updated = [...prev];
              updated[0].line_item_type = newValue; // Update the line item type
              return updated; // Return the updated form data
            });
          }}
        />
        <button className="button select-search" onClick={handleClear}>
          <IoSearch />
        </button>
      </div>
      <Popup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        modal
      >
        <table className="table table-hovered">
          <thead>
            <tr>
              <th>Billing Config Name</th>
              <th>Line Item</th>
              <th>Lineitem Type</th>
            </tr>
          </thead>
          <tbody>
            {lineItems.map((y, i) => (
              <tr
                key={i}
                onClick={() => handleRowClick(y.billing_config_name, y.line_item)}
              >
                <td>{y.billing_config_name}</td>
                <td>{y.line_item}</td>
                <td>{y.lineitem_type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Popup>
              {/* <select className="form-control lineitem"
         name="line_item_type" 
         defaultValue={x.line_item_type}
         onChange={(e) => handleInputChange(index, e)}>
            <option>---None---</option>
          {lineItems.map((x, idx) => (
            <option key={idx} value={x.billing_config_name}>{x.billing_config_name}</option>
          ))}
        </select> */}
      </td>
      <td>
                <input
                  type="text"
                  name="line_item"
                  placeholder="Line item"
                  id='line_item_clear'
                  value={lineitemText||x.line_item}
                  className="form-control clear"
                  onChange={(e) => handleInputChange(index, e)}
                  />
              </td>

              <td style={{ width: '7%' }} >
                <input
                  type="text"
                  name="unit_rate"
                  placeholder="Unit Rate"
                  className="form-control clear"
                  defaultValue={x.unit_rate}
                     
                  onChange={(e) => handleInputChange(index, e)}
                  />
              </td>
              
              <td style={{ width: '7%' }} >
                <input
                  type="text"
                  name="exchange_rate"
                  placeholder="Exchange Rate"
                  defaultValue={x.exchange_rate}
                  className="form-control clear"
                  onChange={(e) => handleInputChange(index, e)}
                     />
              </td>

              <td style={{ width: '7%' }} >
                <input
                  type="text"
                  name="quantity"
                  placeholder="Quantity"
                  defaultValue={x.quantity}
                  className="form-control clear"
                     
                  onChange={(e) => handleInputChange(index, e)}
                  />
              </td>
              <td>
                <input
                  type="text"
                  name="description"
                  placeholder="Description"
                  className="form-control clear"
                  defaultValue={x.description}
                  onChange={(e) => handleInputChange(index, e)}
                  />
              </td>
              
              <td style={{ width: '7%' }} >
                <input
                  type="text"
                  name="amount"
                  placeholder="Amount"
                  defaultValue={x.amount}
                     
                  className="form-control clear"
                  onChange={(e) => handleInputChange(index, e)}
                  />
              </td>
               
            
               
             
            </tr>
          ))}
          </tbody>
          </table>
          <p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
     
     
     </p>
          </div>
          </>
        ):(
      <div className="tasks">
       
       <p className='edit-cancel'><input type="submit" className="account-save" value="Edit" onClick={handleEditClick}/> <Link to={`/postshipment/postshipment-details/${postId}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
         <div className="row">
           <div className="col-md-12" id="head">
             <h4 className="heading">RW Payable/Receivable Detail
</h4>
             <hr></hr>
           </div>
         </div>
         <table class="table table-bordered account-table tables">
           <tbody>
            <tr>
            <td id="td-right">
                 <span>RW Payable/Receivable</span>
                </td><td>
                 {datas.document_reference_no}
               </td>
               
               <td id="td-right">
                 <span>Locked</span>
                </td><td>
                <input
                    type='checkbox'
                    name='locked'
                    checked={lockeds} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange1} // Handle the change event
                    className='mx-3'
                    disabled={isButtonEnabled}
                  />
               </td>
            </tr>
            <tr>
            <td id="td-right">
                 <span>currency</span>
                </td><td>
                 {datas.currency}
               </td>
               <td id="td-right">
                 <span>Record Type	</span>
                </td><td>
                 {datas.record_type}
               </td>
            </tr>
            <tr>
            <td id="td-right">
                 <span>exchange rate</span>
                </td><td>
                 {datas.exchange_rate}
               </td>
               <td id="td-right">
                 <span>Owner</span>
                </td><td>
                 {datas.owner}
               </td>
            </tr>
            <tr>
            <td id="td-right">
                 <span>company</span>
                </td><td>
                <Link to={`/company/company-details/${datas.rwoodcompany_id}`}> {datas.company}</Link>
               </td>
               <td id="td-right">
                 <span>debit note no</span>
                </td><td>
                 {datas.debit_note_no}
               </td>
              
            </tr>
            <tr>
            <td id="td-right">
                 <span>Account</span>
                </td><td>
                 <Link to={`/accounts/${datas.account_id}`}>{datas.account}</Link>
               </td>
               <td id="td-right">
                 {/* <span>debit note no</span> */}
                </td><td>
                 {/* {datas.debit_note_no} */}
               </td>
              
            </tr>
            <tr>
              
            <td id="td-right">
                 <span>document date</span>
                </td><td>
                 {datas.document_date}
               </td>
               
               <td id="td-right">
                 {/* <span>document date</span> */}
                </td><td>
                 {/* {datas.document_date} */}
               </td>
            </tr>
            <tr>
            <td id="td-right">
                 <span>document reference no</span>
                </td><td>
                 {datas.document_reference_no}
               </td>
               <td id="td-right">
                 <span>rwood bank master</span>
                </td><td>
                <Link to={`/bank/bank-details/${datas.rwoodbank_id}`}>{datas.rwood_bank_master}</Link>
               </td>
            </tr>
            <tr>
            <td id="td-right">
                 <span>description</span>
                </td><td><div dangerouslySetInnerHTML={{ __html: datas.description }} /></td>
               <td id="td-right">
                 {/* <span>description</span> */}
                </td><td>
                 {/* {datas.description} */}
               </td>
            </tr>
            <tr>
              
            <td id="td-right">
                 <span>status</span>
                </td><td>
                 {datas.status}
               </td>
               
               <td id="td-right">
                 <span>amount in words</span>
                </td><td>
                 {datas.amount_in_words}
               </td>
            </tr>
            <tr>
            <td id="td-right">
                 <span>Total amount</span>
                </td><td>
                 {datas.total_amount}
               </td>
               <td id="td-right">
                 {/* <span>amount in words</span> */}
                </td><td>
                 {/* {datas.amount_in_words} */}
               </td>
            </tr>
            <tr>
            <td id="td-right">
                 <span>Total paid or received amount</span>
                </td><td>
                 {datas.total_paid_or_received_amount}
               </td>
               <td id="td-right">
                 {/* <span>Total paid or received amount</span> */}
                </td><td>
                 {/* {datas.total_paid_or_received_amount} */}
               </td>
            </tr>
            <tr>
                
            <td id="td-right">
                 <span>Total balance due</span>
                </td><td>
                 {datas.total_balance_due}
               </td>
                 
               <td id="td-right">
                 {/* <span>Total balance due</span> */}
                </td><td>
                 {/* {datas.total_balance_due} */}
               </td>
            </tr>
            <tr>
              
            </tr>
            </tbody>
            </table>
           
            {/* <div className="row">
           <div className="col-md-12" id="head">
             <h4 className="heading">Debit Notes Data
</h4>
             <hr></hr>
           </div>
         </div>
         <table class="table table-bordered account-table tables">
           <tbody>
            <tr>
            <td id="td-right">
                 <span></span>
                </td><td>
               </td>
               
               <td id="td-right">
                 <span>Payment</span>
                </td><td>
                <input
                    type='checkbox'
                    name='locked'
                    checked={payments} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange2} // Handle the change event
                    className='mx-3'
                    disabled={isButtonEnabled}
                  />
               </td>
            </tr>
            <tr>
            <td id="td-right">
                </td><td>
               </td>
               <td id="td-right">
                 <span>DEL Paid / Received Date		</span>
                </td><td>
                 {datas.del_paid_or_received_date}
               </td>
            </tr>
            <tr>
            <td id="td-right">
                </td><td>
               </td>
               <td id="td-right">
                 <span>DEL Paid / Received Amount	</span>
                </td><td>
                 {datas.del_paid_or_received_amount}
               </td>
            </tr>
            <tr>
            <td id="td-right">
                </td><td>
               </td>
               <td id="td-right">
                 <span>DEL Balance Due	</span>
                </td><td>
                 {datas.del_balance_due}
               </td>
            </tr>
            <tr>
            <td id="td-right">
                 <span>Created By	</span>
                </td><td>
                 {datas.created_by	}
               </td>
               <td id="td-right">
                 <span>Last Modified By	</span>
                </td><td>
                 {datas.last_modified_by}
               </td>
              
            </tr> 
            </tbody>
            </table> */}
            {/* <div className="row">
           <div className="col-md-12" id="head">
             <h4 className="heading">RW Payable/Receivable Detail
</h4>
             <hr></hr>
           </div>
         </div> */}
          
         <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Rw Payable/ Receivable LineItem </span>
              
               
               </h4>
                    <hr></hr>
                  </div>
                  <table id="tables-pay" className="">
                    <thead>
                      <tr>
                         
                        <th>Rw Payable/ Receivable LineItem	</th>
                        <th>Order</th>
                        <th>Line Item	</th>
                        <th>Unit Rate	</th>
                        <th>Quantity	</th>
                        <th>Amount</th>
                        <th>Description</th>
                        <th>Vessel Name	</th>
                        <th>BL Date	</th>
                        <th>Exchange Rate
                        </th>
                      </tr>
                    </thead>

                    {line.length> 0 ? (
                      <tbody>
                        {line.map((y, i) => (
                          <tr>
                            <td><Link to={`/postshipment/receivable-details/${y.id
}`}>{y.rw_payment_receivable_lineitem}</Link></td>
                            <td><Link to={`/order/view-order-details/${y.order_id
}`}>{y.order}</Link></td>
                            <td>{y.line_item}</td>
                            <td>{y.unit_rate}</td>

                            <td>{y.quantity}</td>
                            <td>{y.amount}</td>
                            <td>{y.description}</td>
                            <td>{y.vessel_name}</td>
                            <td>{y.bl_date}</td>
                            <td>{y.exchange_rate}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                        <td></td><td></td>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                          <td></td><td></td><td></td>
                          
                        </tr>
                      </tbody>
                    )}
                  </table>
               
                  <p className='edit-cancel'><input type="submit" className="account-save" value="Edit" onClick={handleEditClick}/> <Link to={`/postshipment/postshipment-details/${postId}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
       
                </Row>
       </div> 
        )}
       </div>
      <Footer />
    </div>
  );
};

export default PostreceiptDetail;
