import React,{useState, useEffect, useContext} from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form'; 
import {
  Link
} from "react-router-dom";
import { TokenContext } from './AuthProvider';
const Login = (props) => {
    const [email, setemail] = useState('');
    const [password, setPassword] = useState('');
    const {  login } = useContext(TokenContext);

    const handleSubmit = async (e) => {
      e.preventDefault();
      login(email, password); 
    
    };
  
    return (
      
       <div className='login'>
      <img src='/images/logo.jpg' className="login-image"/>
        <h4>Signin to your account</h4>
      <Form onSubmit={handleSubmit}>
      <FloatingLabel
        controlId="floatingInput"
        label="Email address"
        className="mb-3"

      >
        <Form.Control
         type="text"
          placeholder="name@example.com"           
          name="email"
          value={email} onChange={(e) => setemail(e.target.value)}
        />
      </FloatingLabel>
      <FloatingLabel controlId="floatingPassword" label="Password">
        <Form.Control
         type="password"
          placeholder="Password"
          name="password"
          value={password} onChange={(e) => setPassword(e.target.value)} 
 />
      </FloatingLabel>
      <input type='submit' value='SignIn' className='btn btn-primary'/>

      </Form>
      <p id='forgot'>Forgot your  password  <Link to='/reset-password'>Reset</Link></p>
      </div>
    )
}

export default Login