

import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header' 
import Footer from '../Footer' 
import $ from 'jquery';
import { API_URL } from '../config';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function AddRwoodCompany() {
  
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  console.log(total_height)
  console.log(body)
  $(".contactAccount").css({"min-height":(window_height)+"px"})
    
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = data =>{ 
        const responseData={
          company_name:data.company_name,
          address_1:data.address_1,
          address_2:data.address_2,
          country:data.country,
          pincode:data.pincode,
          shortname:data.shortname,
          starting_number:data.starting_number,
      }; 
      console.log(JSON.stringify(responseData))
      const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
          
        fetch(API_URL+'add/rwoodcompany', {
                  method: "POST",
                  body: JSON.stringify(responseData),
                  headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json' },
                })
                  .then((response) => response.json())
                  
                  .then((response) => {  
                    console.log(response)
                    toast.success("Company added successfully",
                                   {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    }); 
                    setTimeout(() => { 
                      reset();
                    }, 300); 
                 
                  })
                  
                } 
                 
        };
      
  return (

    <div className="addAccount">
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Company</h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Company /Add company </li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div>    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
        <h4 className='heading'>Account Information</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Company Name"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Company Name"
              name="company_name"
              {...register("company_name", { required: false })}
            />
            {errors.company_name && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Address 1"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Address 1"
              name="address_1"
              {...register("address_1", { required: false })}
            />
            {errors.address_1 && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Address 2"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Address 2"
              name="address_2"
              {...register("address_2", { required: false })}
            />
            {errors.address_2 && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
       <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Country"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Country"
              name="country"
              {...register("country", { required: false })}
            />
            {errors.country && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>    
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Pincode"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Pincode"
              name="pincode"
              {...register("pincode", { required: false })}
            />
            {errors.pincode && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>   
         </Row>
         <Row>
         <h4 className='heading'>System Information</h4>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Shortname"
             className="mb-3"
             
             
           >
             <Form.Control
               type="text"
               placeholder="Shortname"
               name="shortname"
               {...register("shortname", { required: false })}
             />
             {errors.shortname && <span>Required </span>}
           </FloatingLabel>
         </Form.Group>    
          
         
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Starting Number"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Starting Number"
              name="starting_number"
              {...register("starting_number", { valueAsNumber:true, required: false })}
            />
            {errors.starting_number && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>    
           
                </Row>
      <input type="submit"  className="addaccount-save" value='Save'/>
      <div className='successDiv'><p></p><p></p></div>
    </Form>
    <Footer/>
    </div>
  );
}

export default AddRwoodCompany;

