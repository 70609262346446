

import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { AES, enc } from 'crypto-js';
import Header from '../Header' 
import { Link, useNavigate, useParams, useLocation,  } from "react-router-dom";
import Footer from '../Footer' 
import $ from 'jquery';
import axios from "axios";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { ToastContainer, toast } from 'react-toastify';
import { BsArrowDownSquare, BsArrowUpSquare , BsArrowLeftSquare, BsArrowRightSquare} from "react-icons/bs";
import 'react-toastify/dist/ReactToastify.css';

import { API_URL } from '../config';
function OrderEditview() {
  
  const params=useParams();
  const ids=params.id;
  // console.log(ids)
const location =useLocation()
const{pageID,accountIds}=location.state;
// console.log(accountIds,'**',pageID)
  const [datas, setDatas]=useState({})
  const [inputDatas, setInputDatas]=useState([])
   
  const [isChecked, setIsChecked] = useState(Boolean(inputDatas.all_account));
  useEffect(() => {
    setIsChecked(Boolean(inputDatas.all_account));
  }, []);
const [accountName, setAccountName]=useState([])
const [operators, setOperator]=useState([])
const [checkboxes, setCheckboxes] = useState([]);
 

  const fetchData5 = async () => {
    try {
      const response = await axios.get(API_URL+'get/rwoodorders_createview_byID/'+pageID, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response;
      var inputData=response.data.order_view;
 console.log(data); 
setInputDatas(inputData)
setAccountName(inputData.fields_to_display)
setSelectedFields(inputData.fields_to_display)
setOperator(inputData.filter_fields)
 
// const test=inputresult.filter_fields     


      setDatas(inputData)
      // console.log(inputresult)
      // if (inputData.all_account === "true" || inputData.owner === "true"||inputData.all_account === "false" || inputData.my_account === "false") {
        setFormData({ 
          view_name: inputData.view_name || '', 
          unique_name: inputData.unique_name || inputData.view_name || '',
          my_orders: inputData.my_orders ?? false,
          all_orders: inputData.all_orders ?? false,
          id: parseInt(pageID, 10)
        });
    }
catch (error) {
      console.error(error);
    }
  };
  


  
  useEffect(() => {

    fetchData5();
  }, [decryptedAccessToken]);


  const [filterConditions, setFilterConditions] = useState([]);
  useEffect(() => {
    if (operators.length === 0) {
      setFilterConditions([
        { input: "", operator: "", values: [] },
      ]);
    } else {
      const initialFilterConditions = inputDatas.filter_fields.map((x) => ({
        input: x.input,
        operator: x.operator,
        values: x.values.map((y) => y),
      }));

      setFilterConditions(initialFilterConditions);
    }
  }, [operators, inputDatas]);  



  

  const navigate = useNavigate();
  
 

  const [account_keys, setaccount_keys] = useState([]);

  const [availableFields, setAvailableFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState(['account_name']);


 
  
  const [formData, setFormData] = useState({
    view_name: '',
    unique_name: '',
    my_orders: inputDatas.my_orders,
    all_orders: inputDatas.all_orders,

  });
 


  // console.log(datas)
  const [selectedOptions, setSelectedOptions] = useState([]); 

  const moveSelectedFieldsUp = () => {
    const updatedFields = [...selectedFields];
    const firstSelectedIndex = selectedFields.indexOf(selectedOptions[0]);

    if (firstSelectedIndex > 0) {
      const movedFields = updatedFields.splice(firstSelectedIndex, selectedOptions.length);
      updatedFields.splice(firstSelectedIndex - 1, 0, ...movedFields);
      setSelectedFields(updatedFields);
    }
  };

  // console.log(formData);
  const moveSelectedFieldsDown = () => {
    const updatedFields = [...selectedFields];
    const lastSelectedIndex = selectedFields.indexOf(selectedOptions[selectedOptions.length - 1]);

    if (lastSelectedIndex < selectedFields.length - 1) {
      const movedFields = updatedFields.splice(lastSelectedIndex, selectedOptions.length);
      updatedFields.splice(lastSelectedIndex + 1, 0, ...movedFields);
      setSelectedFields(updatedFields);
    }
  };

  
  const handleFieldSelection = (e) => {
    const options = Array.from(e.target.options)
      .filter((option) => option.selected)
      .map((option) => option.value);
    setSelectedOptions(options);
  };
  const handleAddFields = () => {
    const newFields = selectedOptions.filter(
      (option) => !selectedFields.includes(option)
    );
  
    setAvailableFields((prevFields) =>
      prevFields.filter((field) => !newFields.includes(field))
    );
  
    setSelectedFields((prevFields) => [...prevFields, ...newFields]);
    setSelectedOptions([]);
  };




  const handleRemoveFields = () => {
    setAvailableFields((prevFields) => {
      const updatedFields = [...prevFields, ...selectedOptions].filter(
        (field, index, self) => index === self.findIndex((f) => f === field)
      );
      

      return updatedFields;
    });
    setSelectedFields((prevFields) =>
      prevFields.filter((field) => !selectedOptions.includes(field))
    );
    setSelectedOptions([]);
  };

  const handleFieldToggle = (field) => {

    if (selectedOptions.includes(field)) {
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((option) => option !== field)
      );
    } else {
      setSelectedOptions((prevOptions) => [...prevOptions, field]);
    }
  };

  const handleInputChanges = (event) => {
    const { name, checked, value, type } = event.target;
  
    setFormData((prevState) => {
      const newState = {
        ...prevState,
        [name]: type === 'checkbox' ? checked : value
      };
  
      // Handle mutually exclusive checkboxes
      if (name === 'all_orders' && checked) {
        newState.my_orders = false;
      } else if (name === 'my_orders' && checked) {
        newState.all_orders = false;
      }
  
      return newState;
    });
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(API_URL+"get/order_keys", {
          method: "GET",
        });

        if (response.ok) {
          const responseData = await response.json();
          
          setAvailableFields(responseData.fields);
        } else {
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchKeys = async () => {
      try {
        const response = await fetch(API_URL+"get/order_keys", {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error ("Request failed");
        }

        const data = await response.json();
        setaccount_keys(data.fields);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchKeys();
  }, []);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFilterConditions = [...filterConditions];
    const updatedCondition = {
      ...updatedFilterConditions[index],
      [name]: name === "values" ? value.split(",") : value,
    };
    updatedFilterConditions[index] = updatedCondition;
    setFilterConditions(updatedFilterConditions);
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };
 

  const addFilterCondition = () => {
    setFilterConditions([   
      ...filterConditions,
  { input: "", operator: "", values: [] },

]); 
// if (filterConditions.length < 1) {
//       setFilterConditions([
//         ...filterConditions,
//         { input: "", operator: "", values: [] },
//       ]);
//     } else {
//       alert("You can't add more than 10 filters.");
//     }
    
  };

  const removeFilterCondition = (index) => {
    const updatedFilterConditions = [...filterConditions];
    updatedFilterConditions.splice(index, 1);
    setFilterConditions(updatedFilterConditions);
  };
  // console.log(JSON.stringify(pageID))
  const handleSubmit = async (e) => {
        e.preventDefault();
    
    const isFilterConditionsEmpty =
  filterConditions.length === 1 &&
  filterConditions[0].input === "" &&
  filterConditions[0].operator === "" &&
  filterConditions[0].values.length === 0;

  const dataToSend = {
    ...formData,
    filterConditions: isFilterConditionsEmpty ? [] : filterConditions,
    selectedFields: isFilterConditionsEmpty ?  []:selectedFields,
  };


    //  console.log(filterConditions);
    // console.log(dataToSend)
    // if (filterConditions.length === 0) {
    //   alert("You should add filters.");
    //   return;
    // }

    // if (!formData.view_name) {
    //   alert("You should enter a view name.");
    //   return;
    // }

    const cleanedJsonString = JSON.stringify(dataToSend)
    .replace(/\\/g, '')
    .replace(/"\[/g, '[')
    .replace(/\]"/g, ']');
  const datatosending = JSON.parse(cleanedJsonString);
  console.log(JSON.stringify(datatosending))

  try {
    const response = await fetch(
      `${API_URL}update/order_records/${pageID}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datatosending),
      }
    );

    // Check for HTTP errors
    if (!response.ok) {
      if (response.status === 401) {
        throw new Error("Unauthorized: Please check your access token.");
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse the JSON response
    const data = await response.json();
    console.log("Record updated successfully:", data);
    navigate(`/order/create-order-list`)
  } catch (error) {
    console.error("Failed to update record:", error.message);
  }
}

      
      
  return (

    <div className="addAccount">
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Edit View</h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Order / Edit View </li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div>  
    <div id='view-container'>
    <p className='edit-cancel'><input type="submit" className="account-save" value="Save" onClick={handleSubmit} /><Link to={'/order/create-order-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <Row className="mb-3">
        <h4 className='heading'>Step 1: Enter View Name</h4>
       
            <Form.Group as={Col} md="12">
            <FloatingLabel
              controlId="floatingInput"
              label="View Name"
              className="mb-3"
              
              
            >
              <Form.Control
                type="text"
            name="view_name"
            placeholder="View Name"
            value={formData.view_name || ""}
            onChange={handleInputChanges} 
              />
              
            </FloatingLabel>
          </Form.Group>
          {/* <Form.Group as={Col} md="12">
          <FloatingLabel
            controlId="floatingInput"
            label="Unique Name"
            className="mb-3"
            
            
          >
            <Form.Control
          type="text"
          name="unique_name"
          value={formData.unique_name}
          onChange={handleInputChanges}
        placeholder="Unique Name"
            />
            
          </FloatingLabel>
        </Form.Group> */}
       
     
       
        </Row>
      
      <Row className="mb-3">
        <h4 className='heading'>Step 2: Specify Filter Criteria</h4>
        <p>Filter By Owner:</p>
       
        <Form.Group as={Col} md="12">
        <input
          className="form-check-input"
          type="checkbox"
          checked={formData.all_orders}
          name="all_orders"
          id="flexCheckChecked"
          onChange={handleInputChanges}
        />
        <label className="form-check-label" htmlFor="flexCheckChecked">
          All Orders
        </label>
      </Form.Group>

      <Form.Group as={Col} md="12">
        <input
          className="form-check-input"
          type="checkbox"
          checked={formData.my_orders}
          name="my_orders"
          id="flexCheckDefault"
          onChange={handleInputChanges}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          My Order
        </label>
      </Form.Group>   <p id='filter-by'>Filter By Additional Fields (Optional):</p>
        <Form.Group as={Col} md="12" className="account-field" id='view-filters'>
        {filterConditions?.map((condition, index) => (
  <div key={index}>
   
      <div >
       <select
            name='input'
            value={condition.input}
            onChange={(e) => handleInputChange(e, index)}
          >
          <option>Select Key</option>
          {account_keys?.map((key, keyIndex) => (
            <option key={keyIndex} value={key}>
              {key.replace(/_/g, " ")}
            </option>
          ))}
        </select>

        <select
          name="operator"
          value={condition.operator}
          onChange={(e) => handleInputChange(e, index)}
        >
          <option value="Operator">Operator</option>
          <option value="equals">equals</option>
          <option value="not_equals">not_equals</option>
          <option value="greater">greater</option>
          {/* ... other options ... */}
        </select>

        <input
            type="text"
            name="values"
            placeholder="Values"
            value={condition.values.join(",")}
            onChange={(e) => handleInputChange(e, index)}
          />
        <FaTrashAlt id='trash' onClick={() => removeFilterCondition(index)} />
      </div>
   
    
  </div>
))}


      <button onClick={addFilterCondition}>Add Filter</button>
      

  </Form.Group>
        </Row>
        <Row className="mb-3 available">
          <h4 className='heading'>Step 3: Select Fields to Display</h4>
          <Form.Group as={Col} md="6">
            <p>Available Fields</p>
            <select
              multiple
              value={selectedOptions}
              className='form-control selectedField'
              onChange={handleFieldSelection}
            >
              {availableFields.map((field) => (
                <option key={field.id} value={field}>
                  {field.replace(/_/g, " ")}
                </option>
              ))}
            </select>
          </Form.Group>
          
          <Form.Group as={Col} md="6" >
          <Form.Group as={Col} id='createview-add-remove' >
            <div>
            <p>
            <span>Add</span> <br/>
            <BsArrowRightSquare  onClick={handleAddFields}
              disabled={!selectedOptions.length}/>
              </p>
             <p>
            
              <BsArrowLeftSquare onClick={handleRemoveFields}
              disabled={!selectedFields.length}/>
              <br/>
              <span>Remove</span>
              </p>
           

             
            
              </div>
          </Form.Group>
          

          <div id='right-select-section'>
          <p>Selected Fields</p>
          <div className="selectedFields" >
            {selectedFields.map((field, index) => (
              <div
                key={field}
                style={{ 
                  justifyContent: "left",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "0px",
                  background: selectedOptions.includes(field)
                    ? "#e3e3e3"
                    : "#fff",
                }}
                onClick={() => handleFieldToggle(field)}
              >
               {field.replace(/_/g, " ")}
              </div>
            ))}
          </div>
          </div>

         
          <Form.Group as={Col} id='move-up-down' >
           <p>
          <BsArrowUpSquare   onClick={moveSelectedFieldsUp}
            disabled={!selectedFields.length}/>
          
            <BsArrowDownSquare onClick={moveSelectedFieldsDown}
            disabled={!selectedFields.length}/>
          </p>
        </Form.Group>
        </Form.Group>
        
      {/* <Row className="mb-3">
        <Form.Group as={Col} id='add-remove' md="12">
          <button
            type="button"
            onClick={handleRemoveFields}
            disabled={!selectedFields.length}
          >
            Remove Selected Fields
          </button>
        </Form.Group>
      </Row>
        */}
          
        </Row> 
        <p className='edit-cancel'><input type="submit" className="account-save" value="Save" onClick={handleSubmit} /><Link to={'/order/create-order-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>

      
      <div className='successDiv'><p></p><p></p></div>
    </div>
    <Footer/>
    </div>
  );
}

export default OrderEditview;

