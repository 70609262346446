import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import { API_URL } from '../config';
  import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { AiFillDiff } from "react-icons/ai";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer"; 
import $ from "jquery";
import Select from 'react-select';
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
const ContractList = () => {
  const navigate = useNavigate();
  const [formData, setFromdata] = useState({

    account_record: "",

  });


  const handleChange = (e) => {

    setFromdata({ ...formData, [e.target.name]: e.target.value });

  };

  const handleSubmit = (event) => {

    event.preventDefault();


    const encryptedAccessToken = localStorage.getItem('access_token');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
      
    fetch(`${API_URL}add/record`, {
              method: "POST",
              body: JSON.stringify(formData),
              headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
            })
              .then((response) => response.json())
              
              .then((response) => { 
                
                  console.log(response)
                  console.log(response.status)
                if (response.message === "record addedd successfully") {
                                  const account_record_new = response.account_type;
                                  
                                  navigate("/contract/add-contract", {
                        
                                    state: { account_record: account_record_new },
                        
                                  });
                        
                                }
              }) 
            } 
             
    };


  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState([]);


  
  useEffect(() => {
   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}get/all/contract`, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.contract;
      console.log(data)
      setDatas(data);
      $(document).ready(function(){
        // $('#tables').DataTable().destroy();
        // setTimeout(()=>{
          $('#tables').DataTable();
        // },500)
       })
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <div> 
      <Header/>
    <div className="contactAccount">
    
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Contract List </h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Contract / Add contract</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
<Popup trigger={<button className="button add-accountrecord"> <FaPlus/> Add Contract </button>} modal>
    
       <form onSubmit={handleSubmit} className="add-accounts">
        <div className='form-group'>
        <label>Account Record Type</label><br/>
        <select name='account_record' className='form-control' onChange={handleChange} value={formData.account_record}>
          <option >--Select Contract Type--</option>
          <option value='Buyer - Long Term'>Buyer Long Term</option>
          <option value='Buyer - Spot'>Buyer Spot</option>
          <option value='Supplier - Long Term'>Supplier Long Term</option>
          <option value='Supplier'>Supplier</option>
          <option value='Supplier - Spot'>Supplier Spot</option>
          
          </select>
          </div>
          <input type='submit' className="btn btn-primary " value='Submit'/>

          </form>
  </Popup>        
      </div>
    </div> 
  </div> 
  <div class='tasks'>
  <table id='tables' className="">
    <thead>
    <tr>
      <th>Contract Number</th>
       <th>Contract owner</th>
      <th>Currency</th>
      <th>Contract Record Type</th>
      <th>Renewal Date</th>
      <th>Company signed date</th>
      <th>Commodity</th> 
      <th>Actions</th>
    </tr>
    </thead>
    <tbody> 
      <React.Fragment>
      {datas.length > 0 ? (
  datas.map((x) => (
    <tr>
    <td><Link to={"/contract/contract-detail/"+x.id }>{x.rwood_contract_number}</Link></td>
    <td><Link to={"/contract/contract-detail/"+x.id }>{x.contract_owner}</Link></td>
    <td>{x.currency}</td> 
    <td><span id='renewal'>{x.contract_record_type}</span></td>
    <td>{x.renewal_date}</td> 
    <td>{x.company_signed_date}</td>
    <td>{x.commodity}</td> 
    <td><Link to={'/contract/edit-contract/'+x.id}><FaPencilAlt/></Link> <FaTrashAlt/><Link to={"/contract/contract-detail/"+x.id }><AiFillDiff/></Link></td>
    </tr>

  ))
) : (
  <tr>
    <td colSpan="6">No data available</td>
  </tr>
)}

      </React.Fragment>


    </tbody>
    </table>  
    </div> </div> 
    <Footer/>
    </div>
  );
};

export default ContractList;