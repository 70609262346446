 

import React, {
  Component,
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo
} from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import { API_URL } from '../config';
import { AiFillDiff } from "react-icons/ai";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import DataTable from "react-data-table-component";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import $ from "jquery";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { AiOutlineCloseCircle } from "react-icons/ai";

import Header from "../Header";
import Footer from "../Footer";
import { set } from "date-fns";
import { DropDownMenu } from "material-ui";
// import FilterComponent from '../filterComponent';
const FilterComponent = ({ onFilter, onClear, filterText }) => {
  return (
    <div>
      <input
        type="text"
        placeholder="Search..."
        value={filterText}
        className="table-search"
        onChange={onFilter}
      />
      {/* <button onClick={onClear} ><AiOutlineCloseCircle /></button> */}
    </div>
  );
};

const CreateviewList = () => {
  



  const navigate = useNavigate();
  const { selectedView, setSelectedViews, allTableData, setAllTableData, otherTableData, setOtherTableData } = useContext(TokenContext);

  const [selectedOption, setSelectedOption] = useState('');
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [showAllTableView, setShowAllTableView] = useState(selectedView === "all");
  const [accountId, setAccountId]=useState([])
  const [dropId, setDropId]=useState('')
  const [randomDropdownValue, setRandomDropdownValue] = useState('recently_created');
  const [keysAlldata,setKeys]=useState([])
  const[allKeys, setAllkeys]=useState([])
  const[ids,setID]=useState([])

console.log(showAllTableView);




 
    const fetchDropdownOptions = async () => {

      try {
        const response = await axios.get(`${API_URL}get/all/opportunity/create_view`);
        const options = response.data.opportunity_create_view;
        console.log(options)
        setID(options[0].id)

        setDropdownOptions(options);
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
        setDropdownOptions([]);
      }
    };

    useEffect(() => {
      fetchDropdownOptions();
    }, [decryptedAccessToken]);


    const [filterText, setFilterText] = useState('');
  const [filterItems, setFilteredItems]=useState([])
  const [columnsData, setColumnsData]=useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedView === 'all') {
          const response = await axios.get(`${API_URL}all/opportunity?random_dropdown_option=recently_created`, {
              headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
            );  
            const data = response.data.opportunity;
          console.log(response)
                       
const columns = [
  
  {
      name: 'Opportunity Code',
      selector: (row, index) =>row.opportunity_no,
  },
  {
    name: 'Opportunity name',
    selector: (row, index) => row.opportunity_name

}, 
{
  name: 'Record Type',
  selector: row => row.opportunity_record_type,
},
  // ... other columns
];

const filteredItems = data.filter(item =>
  Object.values(item).some(val =>
      val && val.toString().toLowerCase().includes(filterText.toLowerCase())
  )
).map(item => ({
  opportunity_no: <Link to={`/opportunity/opportunity-detail/${item.id}`}>{item.opportunity_no}</Link>,
  opportunity_name:  <Link to={`/opportunity/opportunity-detail/${item.id}`}>{item.opportunity_name}</Link>,
  opportunity_record_type: item.opportunity_record_type
  // Add other properties as needed
}));

 setColumnsData(columns)
  setFilteredItems(filteredItems)

          setAllTableData(data);
        } else if (selectedView) { 
          const response = await axios.get(`${API_URL}get/opportunity/view/${selectedView}`);          
          console.log(response);
          const data = response.data.opps;
          setOtherTableData(data);
          const account_keys = Object.keys(data[0]);
          setAllkeys(account_keys);
          // setAccountId(data.accounts[0].id);
          setTimeout(() => {
            $('.table').dataTable();
          }, 50);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setAllTableData([]);
        setOtherTableData([]);
      }
    };

    fetchData();
}, [selectedView, filterText, setAllTableData, setOtherTableData, setColumnsData, setFilteredItems, setAllkeys, setAccountId]);


  useEffect(() => {
    const storedView = localStorage.getItem('selectedView');
    if (storedView && dropdownOptions.find(opt => opt.view_name === storedView)) {
      setSelectedViews(storedView);
      setShowAllTableView(storedView === 'all');
    } else {
      // If there's no stored view or it's not valid, set the default view to 'all'
      setSelectedViews('all');
      setShowAllTableView(true);
      localStorage.setItem('selectedView', 'all');
    }
  }, [dropdownOptions])


  const [formData, setFromdata] = useState({

    account_record: "",

  });


  const handleChange = (e) => {

    setFromdata({ ...formData, [e.target.name]: e.target.value });

  };

  const [error, setError] = useState({});
  const validate = () => {
    const newErrors = {};
    if (!formData1.account_record) {
      newErrors.account_record = 'Select Opportunity Record Type';
    }
    return newErrors;
  };
  const handleSubmit = (event) => {

    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
    }
    else{
    const encryptedAccessToken = localStorage.getItem('access_token');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

    fetch(`${API_URL}add/record`, {
              method: "POST",
              body: JSON.stringify(formData1),
              headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
            })
              .then((response) => response.json())

              .then((response) => {

                  console.log(response)
                  console.log(response.status)
                  const record_type_new = response.account_type;
                                  
                  navigate("/opportunity/add-opportunity", {
      
                      state: { record_type: record_type_new },
          
                    });
                // if (response.message === "record addedd successfully") {
                //                   const account_record_new = response.account_type;

                //                   navigate("/accounts/new-account", {

                //                     state: { account_record: account_record_new },

                //                   });

                //                 }
              })
            }

    };
  }
    const editView=()=>{ 
      navigate('/opportunity/edit-view',{
        state: { accountIds: accountId, pageID:dropId },
      })
    }

const handleRandomDropdownChange = (event) => {
  const selectedValue = event.target.value;
  const encryptedAccessToken = localStorage.getItem('access_token');
  const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
  if (encryptedAccessToken) {
    const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
    fetch(`${API_URL}all/opportunity?random_dropdown_option=${selectedValue}`,{
      headers: { Authorization: `Bearer ${decryptedAccessToken}`},

    })
      .then(response => response.json())
      .then(data => {
console.log(data)
        setAllTableData(data);
      })
      .catch(error => {
        console.error('Error fetching data from backend:', error);
        setAllTableData([]);
      });

    setRandomDropdownValue(selectedValue);}

};


const handleDropdownChange = (event) => {
  const index = event.target.selectedIndex;
  const el = event.target.childNodes[index];
  const dropdownId = el.getAttribute('id');
  console.log(dropdownId);
  setDropId(dropdownId);
  const viewName = event.target.value;
  console.log(viewName);
  setSelectedViews(viewName);
  setShowAllTableView(viewName === 'all');
  setSelectedOption(event.target.value);
  localStorage.setItem('selectedView', viewName);




};


const fetchRecent = async () => {
  try {
    const response = await axios.get(`${API_URL}get/recent_items`);
    console.log(response)
    
  } catch (error) {
    console.error(error);
  }
};
useEffect(() => {
 
  fetchRecent();
}, []);

 


 console.log(allTableData)
// all table
const column = Object.keys(allTableData[0] || {}).map((key) => {
  if (key === 'account_name') {
    return {
      name: key.replace(/_/g, " "),
      selector: row => (
        <Link to={`/accounts/${row.id}`}>
          {row.account_name}
        </Link>
      ),
    };
  }

  if (key === 'opportunity_no') {
    return {
      name: key.replace(/_/g, " "),
      selector: row => (
        <Link to={`/opportunity/opportunity-detail/${row.id}`}>
          {row.opportunity_no}
        </Link>
      ),
    };
  }

  return key === 'id' 
    ? null  // Exclude the 'id' column
    : {
        name: key.replace(/_/g, " "),
        selector: row => row[key],
        // Additional properties for each column, if needed
      };
}).filter(column => column !== null);
 
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  // const filteredItems = allTableData.filter(item => item.account_name && item.account_name.toLowerCase().includes(filterText.toLowerCase()));
  // console.log(allTableData);
  const filteredItems = allTableData.filter(item => {
    // Check if any property in the item contains the filter text
    for (const key in item) {
      if (item[key] && item[key].toString().toLowerCase().includes(filterText.toLowerCase())) {
        return true;
      }
    }
    return false;
  });

  
  
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);

 

 
// other table

const columns = Object.keys(otherTableData[0] || {}).map((key) => {
  if (key === 'account_name') {
    return {
      name: key.replace(/_/g, " "),
      selector: row => (
        <Link to={`/accounts/${row.id}`}>
          {row.account_name}
        </Link>
      ),
    };
  } if (key === 'opportunity_no') {
    return {
      name: key.replace(/_/g, " "),
      selector: row => (
        <Link to={`/opportunity/opportunity-detail/${row.id}`}>
          {row.opportunity_no}
        </Link>
      ),
    };
  }

  return key === 'id' 
    ? null  // Exclude the 'id' column
    : {
        name: key.replace(/_/g, " "),
        selector: row => row[key],
        // Additional properties for each column, if needed
      };
}).filter(columns => columns !== null);

const [filterOther, setFilterOther] = useState('');
  const [resetPaginationToggleOther, setResetPaginationToggleOther] = React.useState(false);
  // const filteredItemsOther = otherTableData.filter(item => item.account_name && item.account_name.toLowerCase().includes(filterOther.toLowerCase()));
 
  

  const filteredItemsOther = otherTableData.filter(item => {
    // Check if any property in the item contains the filter text
    for (const key in item) {
      if (item[key] && item[key].toString().toLowerCase().includes(filterOther.toLowerCase())) {
        return true;
      }
    }
    return false;
  });

  
  const subHeaderComponentMemoOther = React.useMemo(() => {
    const handleClear = () => {
      if (setResetPaginationToggleOther) {
        setResetPaginationToggleOther(!resetPaginationToggleOther);
        setFilterOther('');
      }
    };
    return <FilterComponent onFilter={e => setFilterOther(e.target.value)} onClear={handleClear} filterOther={filterOther} />;
  }, [filterOther, resetPaginationToggleOther]);

 



  // delete

const deleteCreateview = (event) => {

  event.preventDefault();

  const isConfirmed = window.confirm("Are you sure you want to delete?");

  if (!isConfirmed) {
    // User canceled the deletion
    return;
  }

  const encryptedAccessToken = localStorage.getItem('access_token');
  const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

  if (encryptedAccessToken) {
    const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
  
    fetch(`${API_URL}delete/opportunity_view/${dropId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${decryptedAccessToken}`,
        'Content-Type': 'application/json'
      },
    })
    .then((response) => {
      if (response.ok) { 
        return response.json();
      } else {
        // Handle non-successful response (status not in 200-299 range)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    })
    .then((data) => {
      // Handle successful response data
      console.log(data);
      fetchDropdownOptions();
    })
    .catch((error) => {
      console.error('Error deleting:', error.message);
      // Handle network errors or non-successful responses here
    });
  }
  
  };
// validation
const [formData1, setFormData1] = useState({
  account_record: '',
});

const handleChange1 = (e) => {
  setFormData1({
    ...formData1,
    [e.target.name]: e.target.value,
  });
};

 

  return (
    <>
      <Header />
      <div className="contactAccount">
        <div className="page-header" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">Opportunity </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Opportunity
              </li>
            </ol>
          </div>
          <div className="d-flex">
            <div className="justify-content-center">
            <div className="d-flex">
            <div className="justify-content-center">
            <Popup trigger={<button className="button add-accountrecord"> <FaPlus/> Add Opportunity </button>} modal>
            <form onSubmit={handleSubmit} className="add-accounts">
      <div className='form-group'>
        <label>Opportunity Record Type</label><br/>
        <select name='account_record' className='form-control' onChange={handleChange1} value={formData1.account_record}>
        <option value="">--Select Opportunity record type--</option>
          <option value='Buyer - Long Term'>Buyer - Long Term</option>
          <option value='Buyer - Spot'>Buyer - Spot</option>
          {/* <option value='Supplier'>Supplier</option> */}
          <option value='Supplier - Long Term'>Supplier - Long Term</option>
          <option value='Supplier - Spot'>Supplier - Spot</option>
        </select>
        {error.account_record && <div className="errors-msg">{error.account_record}</div>}
      </div>
      <input type='submit' className="btn btn-primary" value='Submit'/>
    </form>
</Popup>
      
</div>
          </div>

</div>
          </div>
        </div>
        <div className="accounts-page">
          <div className="row">
            <div className="col-md-6">
              <form  className="d-flex dropdown-form">

                <label for="pwd" className="mr-sm-2">
                  View
                </label>
                <select
  onChange={handleDropdownChange}
  className='form-control view-select'
  value={selectedView}
  defaultValue="all">
        <option value="">Select a View</option>
        <option value="all" >All Views</option>
        {dropdownOptions.map((option, index) => (
          <option key={index} id={option.id} value={option.view_name}>
            {option.view_name}
          </option>
        ))}
      </select>



                {/* <input
                  type="submit"
                  class="btn btn-primary mb-2"
                  value="GO"
                /> */}
              </form>
            </div>
            <div className="col-md-6 links">
            <p>
               <span onClick={editView}>Edit</span> |{selectedOption && (
       <span onClick={deleteCreateview}>Delete | </span>
      )} <Link to="/opportunity/create-new-view">Create New View</Link>
              </p>
               
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
            <h6 className="d-flex justify-content-between align-items-center">
            <span>Recent Accounts</span>

            {selectedView === 'all' && allTableData && (
          <div className="random- d-flex">
            <select
         dropdowns     className='form-control'
              onChange={handleRandomDropdownChange}
              value={randomDropdownValue}
              defaultValue="recently_created"

            >
              <option value="recently_created">Recently Created</option>
              <option value="recently_modified">Recently Modified</option>
              <option value="recently_viewed">Recently Viewed</option>
            </select>
          </div>
        )}
          </h6>
          <div className="create-view-table">
              {showAllTableView  && (
                
                <DataTable  columns={columnsData} data={filterItems} pagination  // optionally, a hook to reset pagination to page 1
                subHeader subHeaderComponent={subHeaderComponentMemo} fixedHeader selectableRows persistTableHead />
              )}
                 {!showAllTableView && selectedView && (
        <div>
          {otherTableData.length > 0 ?(

<DataTable  columns={columns.map((column) => ({
  name: column.name.replace(/_/g, " "),
  selector: column.selector,
  // Add other properties as needed
}))} data={filteredItemsOther} fixedHeader pagination  // optionally, a hook to reset pagination to page 1
subHeader subHeaderComponent={subHeaderComponentMemoOther} 
fixedHeaderScrollHeight="500px"   selectableRows persistTableHead />



              ):(
                <table class="table table-hover table-bordered create-table">

                  <tr>
                    <th><b>Account Code</b></th>
                    <th><b>Account Name</b></th>
                    <th><b>Account Alias</b></th>
                    <th><b>Account Record Type</b></th>
                  </tr>



                   <tr>
                    <td align='center' colSpan={4}> No Data Available</td>
                    </tr>


              </table>
                  )}
        </div>
      )}
      </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-6">
            <div className="bg-info">
              <h6>
                <span className="pull-left">Reports</span>
              </h6>
              <div className="account-reports">
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
              </div>
            </div>
            </div>
           <div className="col-md-6">
            <div className="bg-info">
              <h6>
                <span className="pull-left">Tools</span>
              </h6>
              <div className="account-reports">
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
              </div>
            </div>
            </div>

          </div> */}
        </div>

      </div>
      <Footer />
    </>
  );
};

export default CreateviewList;
