import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap"; 
import Header from '../Header' 
import Footer from '../Footer' 
import { AES, enc } from 'crypto-js';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import $ from 'jquery' 
import DatePicker from "react-datepicker";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios";
import { API_URL } from '../config';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useParams,Link , useNavigate } from "react-router-dom"; 
function  ContractShippmentDetails() { 
  
  const navigate=useNavigate()
  const params=useParams();
  const ids=params.id
  const [datas,setDatas]=useState([])
  const [datas1,setDatas1]=useState([])
  const [history,setHistory]=useState([])
  const [orders,setOrders]=useState([])
  const [tcf,setTcf]=useState([])
  const [data, setData] = useState({}); 
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [product,setProduct]=useState([])

  useEffect(() => {
   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/contract_shipbyID/'+ids, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      const t=response.data
      console.log(t)
      setDatas(data.contract_details);
      setDatas1(data.contract_details[0]);
      setOrders(data.orders)
      setProduct(data.products)
       
     
      $(document).ready(function(){
        // $('#tables').DataTable().destroy();
        // setTimeout(()=>{
          $('#tables').DataTable();
          $('#tcf').DataTable();
          $('#orders').DataTable();
          $('#history').DataTable();
          $('#product').DataTable();
        // },500)
       })
    } catch (error) {
      console.error(error);
    }
  };

  

   const noOfshipment=()=>{

    navigate("/shippment/contract-shippment-details-lists/"+ids,
     {                        
      state: { number_of_shipment: datas[0].no_of_shipments },


    });   


   }
   
   $('#tables').DataTable();
   $('#tcf').DataTable();
   $('#orders').DataTable();
   $('#history').DataTable();
   $('#product').DataTable();

  //  edit
  const handleEditClick = () => {
    $('#tables').DataTable();
          $('#tcf').DataTable();
          $('#orders').DataTable();
          $('#history').DataTable();
          $('#product').DataTable();
    setIsEditMode(true);
    setEditedData(data);
  };
  
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas1((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);
  };
  
  const handleSaveEdit = (e) => {
    $('#tables').DataTable();
          $('#tcf').DataTable();
          $('#orders').DataTable();
          $('#history').DataTable();
          $('#product').DataTable();
    e.preventDefault();  
    const responseData={
      "currency":datas1.currency,
"exchange_rate":datas1.exchange_rate,
"contract_shipment_status":datas1.contract_shipment_status,
"record_type":datas1.record_type,
"contract":datas1.contract,
"parent_shipment":datas1.parent_shipment,
"order_quantity":datas1.order_quantity,
"shipping_term":datas1.shipping_term,
"price":datas1.price,
"price_type":datas1.price_type,
"price_basis":datas1.price_basis,
"sales_price_margin":datas1.sales_price_margin,
"price_pmt_in_words":datas1.price_pmt_in_words,
"quantity_type":datas1.quantity_type,
"quantity":datas1.quantity,
"quantity_from":datas1.quantity_from,
"quantity_to":datas1.quantity_to,
"no_of_shipments":datas1.no_of_shipments,
"child_shipment_count":datas1.child_shipment_count,
"shipment_quantity_tolerance":datas1.shipment_quantity_tolerance,
"tcf_count":datas1.tcf_count,
"product_count":datas1.product_count,
"no_of_orders":datas1.no_of_orders,
"quantity_info ":datas1.quantity_info,
"laycan_year_from":datas1.laycan_year_from,
"laycan_year_to":datas1.laycan_year_to,
"laycan_month_from":datas1.laycan_month_from,
"laycan_month_to":datas1.laycan_month_to,
"laycan_from_date":'2023-04-22',
"laycan_to_date":'2023-04-22',
"shipment_month":datas1.shipment_month,
"laycan":datas1.laycan,
"shipment_quantity":datas1.shipment_quantity
         };
         console.log( JSON.stringify(responseData))
    fetch(`${API_URL}update/contract_shipments/${ids}`, {  
      method: 'PUT',
      body: JSON.stringify(responseData),
      headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
         
    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log(updatedData) 
         fetchData();
        setData(updatedData);
        setIsEditMode(false);
      })
      .catch((error) => console.error(error));
  };
  
  const createProduct=()=>{
     
    navigate("/contract/add/"+ids,{  

     state: { contractshipment: datas[0].contract_ship_code, buyercontract:datas[0].contract },


   }); 
  }

  return (
    
    <div className="contactAccount" id='contacts'>
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Contact Shipment Details</h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Contact Shipment Details</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
    
    </div> 
  </div>   
 
  </div>
  {isEditMode ? (
    <>
        
  <div className="tasks">
  <p className="edit-btn">
    <button className="btn btn-primary" onClick={handleSaveEdit}>
      Save
    </button>
    <button className="btn btn-primary" onClick={handleCancelEdit}>
      Cancel
    </button>
  </p>
  <div className="row">
                         <div className="col-md-12" id="head">
                           <h4 className="heading">Contract Shipment Details</h4>
   
                           <hr></hr>
                         </div>
                       </div>
    <Row>
  <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="Exchange rate"
         className="mb-3"
         
         
       >
            <Form.Control   
           type="text"
           placeholder="Exchange rate"
           name="exchange_rate"
           defaultValue={datas1.exchange_rate || ""}
 onChange={handleChangeInput}
           />
       </FloatingLabel>
     </Form.Group>  
     <Form.Group as={Col} md="6">
            
            <FloatingLabel controlId="floatingSelect" 
            className='dropDown' label="Currency">
  <Form.Select aria-label="Floating label select example"
  name="currency" 
  placeholder="Currency" 
  defaultValue={datas1.currency || ""}
  onChange={handleChangeInput}
  > 
    <option value='' >---None---</option>
    <option value='USD' >USD</option>
    <option value='IDR' >IDR</option>
     
  </Form.Select> 
</FloatingLabel>
            </Form.Group> 
              
     
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="Contract Shipment Status"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.contract_shipment_status || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="Contract Shipment Status"
           name="contract_shipment_status"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="Record Type"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.record_type || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="Record Type"
           name="record_type"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="Contract"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.contract || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="contract"
           name="contract"
           />
       </FloatingLabel>
     </Form.Group> 

     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="Parent Shipment"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.parent_shipment || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="Parent Shipment"
           name="parent_shipment"
           />
       </FloatingLabel>
     </Form.Group> 
     </Row>
     <div className="row">
                         <div className="col-md-12" id="head">
                           <h4 className="heading">Price</h4>
   
                           <hr></hr>
                         </div>
                       </div>
     <Row>
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="Order Quantity"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.order_quantity || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="Order Quantity"
           name="order_quantity"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="Shipping Term"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.shipping_term || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="Shipping Term"
           name="shipping_term"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="Price"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.price || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="Price"
           name="Price"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="Price Type"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.price_type || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="Price Type"
           name="price_type"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="Price Basis"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.price_basis || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="Price  Basis"
           name="price_basis"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="Sales Price Margin"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.sales_price_margin || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="Sales Price Margin"
           name="sales_price_margin"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="Price PMT in Words"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.price_pmt_in_words || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="Price PMT in Words"
           name="price_pmt_in_words"
           />
       </FloatingLabel>
     </Form.Group> 
     </Row>
     <div className="row">
                         <div className="col-md-12" id="head">
                           <h4 className="heading">Delivery</h4>
   
                           <hr></hr>
                         </div>
                       </div>
     <Row>
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="Quantity Type"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.quantity_type || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="Quantity Type"
           name="quantity_type"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="Quantity"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.quantity || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="Quantity"
           name="quantity"
           />
       </FloatingLabel>
     </Form.Group> 

      
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="quantity from"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.quantity_from || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="Quantity From"
           name="quantity_from"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="quantity  to"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.quantity_to || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="quantity to"
           name="quantity_to"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="no of shipments"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.no_of_shipments || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="no of shipments"
           name="no_of_shipments"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="child shipment count"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.child_shipment_count || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="child shipment count"
           name="child_shipment_count"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="shipment quantity tolerance"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.shipment_quantity_tolerance || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="shipment quantity tolerance"
           name="shipment_quantity_tolerance"
           />
       </FloatingLabel>
     </Form.Group> 
     </Row>
     <div className="row">
                         <div className="col-md-12" id="head">
                           <h4 className="heading">Quantity</h4>
   
                           <hr></hr>
                         </div>
                       </div>
                       <Row>
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="quantity info"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.quantity_info || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="quantity info"
           name="quantity_info"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="Quantity"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.quantity || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="laycan year from"
           name="laycan_year_from"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="laycan year to"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.laycan_year_to || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="laycan year to"
           name="laycan_year_to"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="laycan month from"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.laycan_month_from || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="laycan month from"
           name="laycan_month_from"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="laycan month to"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.laycan_month_to || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="laycan month to"
           name="laycan_month_to"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="laycan from date"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.quantity || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="laycan from date"
           name="laycan_from_date"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="laycan to date"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.quantity || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="laycan to date"
           name="laycan_to_date"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="shipment month"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.quantity || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="shipment month"
           name="shipment_month"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="Quantity"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.laycan || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="Laycan"
           name="laycan"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="shipment quantity"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.shipment_quantity || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="shipment quantity"
           name="shipment_quantity"
           />
       </FloatingLabel>
     </Form.Group> 
      
     </Row>
     <div className="row">
                         <div className="col-md-12" id="head">
                           <h4 className="heading">System Information</h4>
   
                           <hr></hr>
                         </div>
                       </div>
     <Row>
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="TCF count"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.tcf_count || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="TCF count"
           name="tcf_count"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="product count"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.product_count || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="product count"
           name="product_count"
           />
       </FloatingLabel>
     </Form.Group> 
     <Form.Group as={Col} md="6">
       <FloatingLabel
         controlId="floatingInput"
         label="no of orders"
         className="mb-3"
         
         
       >
            <Form.Control   defaultValue={datas1.no_of_orders || ""}
 onChange={handleChangeInput}
           type="text"
           placeholder="no of orders"
           name="no_of_orders"
           />
       </FloatingLabel>
     </Form.Group> 
     
     </Row>
     <p className="edit-btn">
    <button className="btn btn-primary" onClick={handleSaveEdit}>
      Save
    </button>
    <button className="btn btn-primary" onClick={handleCancelEdit}>
      Cancel
    </button>
  </p>
     </div>
  </>
  ):(
  <div className="tasks" id='view-page'>
     
                    {datas.length > 0 ?(
                    datas.map((x)=>(
                      
                    <> 
                    {/* <p id="top-header"><span class="button add-accountrecord" aria-describedby="popup-54"> RW Payment/Receipt LineItem </span></p> */}
  <p className='edit-btn'>
    
    <button id='edits' onClick={handleEditClick}>
                              Edit
                            </button>
                            <button id='edits' onClick={createProduct}>
                              Create Product
                            </button>
                            <Link><button id='edits'>
                              Create TCF
                            </button></Link>
                            <Link to={'/contract-shipment/new-order/'+ids}><button id='edits'>
                              Create Order
                            </button></Link>
                            <button className='btn btn-primary' id='edits' onClick={noOfshipment}>Contract Shipment Details</button> 
    </p> 
  
                     <div className="">
                       <div className="row">
                         <div className="col-md-12" id="head">
                           <h4 className="heading">Contract Shipment Details</h4>
   
                           <hr></hr>
                         </div>
                       </div>
                       <table class="table table-bordered account-table tables">
                         <tbody>
                           <tr>
                             <td id="td-right"><span>contract Shipment No</span> </td>
                             <td>{x.contract_ship_code}</td>
                             <td id="td-right"><span>Record Type</span></td>
                             <td>{x.record_type}
                             </td>
                             
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>Currency</span>
                             </td>
                             <td>
                               {x.currency}
                             </td>
                             <td id="td-right">
                               <span>
                                 Contract{" "}
                                 {/* <OverlayTrigger
                                   placement="right"
                                   delay={{ show: 250, hide: 400 }}
                                   overlay={renderTooltip}
                                 >
                                   <Button variant="success" className="tooltips">
                                     <IoIosHelpCircle id="help" />
                                   </Button>
                                 </OverlayTrigger>{" "} */}
                               </span>
                               </td><td>
                               {x.contract}
                             </td>
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>Exchange Rate</span>
                               </td>
                               <td>
                                {x.exchange_rate}
                             </td>
                             <td id="td-right">
                               <span>Locked</span>
                               </td>
                               <td>
                                {x.locked}
                             </td>
                           </tr>
                           <tr>
                       <td id="td-right">
                       <span>Contract Shipment Status</span>
                           </td><td>
                            {x.contract_shipment_status}
                          </td>
                          <td id="td-right">
                       <span>Parent Shipment</span>
                           </td><td>
                            {x.parent_shipment}
                          </td>
                        </tr>
                        
                         </tbody>
                       </table>
                     </div>
                     <div className="">
                       <div className="row">
                         <div className="col-md-12" id="head">
                           <h4 className="heading">Price</h4>
                           <hr></hr>
                         </div>
                       </div>
                       <table class="table table-bordered account-table tables">
                         <tbody>
                           <tr>
                             <td id="td-right"><span>Shipping Term</span> </td>
                             <td>{x.shipping_term}</td>
                             <td id="td-right"><span>Price</span></td>
                             <td>{x.price}
                             </td>
                             
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>price type</span>
                             </td>
                             <td>
                               {x.price_type}
                             </td>
                             <td id="td-right">
                               <span>
                               price basis{" "}
                                 {/* <OverlayTrigger
                                   placement="right"
                                   delay={{ show: 250, hide: 400 }}
                                   overlay={renderTooltip}
                                 >
                                   <Button variant="success" className="tooltips">
                                     <IoIosHelpCircle id="help" />
                                   </Button>
                                 </OverlayTrigger>{" "} */}
                               </span>
                               </td><td>
                               {x.price_basis}
                             </td>
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>sales price margin</span>
                               </td>
                               <td>
                                {x.sales_price_margin}
                             </td>
                             <td id="td-right">
                               <span>price PMT words</span>
                               </td>
                               <td>
                                {x.price_pmt_in_words}
                             </td>
                           </tr>
                        
                         </tbody>
                       </table>
                     </div>

<div className="">
                       <div className="row">
                         <div className="col-md-12" id="head">
                           <h4 className="heading">Delivery</h4>
                           <hr></hr>
                         </div>
                       </div>
                       <table class="table table-bordered account-table tables">
                         <tbody>
                           <tr>
                             <td id="td-right"><span>laycan year date</span> </td>
                             <td>{x.laycan_year_date}</td>
                             <td id="td-right"><span>laycan</span></td>
                             <td>{x.laycan}
                             </td>
                             
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>laycan year to</span>
                             </td>
                             <td>
                               {x.laycan_year_to}
                             </td>
                             <td id="td-right">
                               <span>
                               laycan from date
                                 {/* <OverlayTrigger
                                   placement="right"
                                   delay={{ show: 250, hide: 400 }}
                                   overlay={renderTooltip}
                                 >
                                   <Button variant="success" className="tooltips">
                                     <IoIosHelpCircle id="help" />
                                   </Button>
                                 </OverlayTrigger>{" "} */}
                               </span>
                               </td><td>
                               {x.laycan_from_date}
                             </td>
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>laycan month from</span>
                               </td>
                               <td>
                                {x.laycan_month_from}
                             </td>
                             <td id="td-right">
                               <span>laycan to date</span>
                               </td>
                               <td>
                                {x.laycan_to_date}
                             </td>
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>laycan month to</span>
                               </td>
                               <td>
                                {x.laycan_month_to}
                             </td>
                           <td id="td-right">
                               <span></span>
                               </td>
                               <td>
                                 
                             </td>
                             
                           </tr>
                         </tbody>
                       </table>
                     </div>     
                     
                     <div className="">
                       <div className="row">
                         <div className="col-md-12" id="head">
                           <h4 className="heading">Quantity</h4>
                           <hr></hr>
                         </div>
                       </div>
                       <table class="table table-bordered account-table tables">
                         <tbody>
                           <tr>
                             <td id="td-right"><span>quantity info</span> </td>
                             <td>{x.quantity_info}</td>
                             <td id="td-right"><span>no of shipments</span></td>
                             <td>{x.no_of_shipments}
                             </td>
                             
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>child shipment count</span>
                             </td>
                             <td>
                               {x.child_shipment_count}
                             </td>
                             <td id="td-right">
                               <span>
                               Quantity
                                 {/* <OverlayTrigger
                                   placement="right"
                                   delay={{ show: 250, hide: 400 }}
                                   overlay={renderTooltip}
                                 >
                                   <Button variant="success" className="tooltips">
                                     <IoIosHelpCircle id="help" />
                                   </Button>
                                 </OverlayTrigger>{" "} */}
                               </span>
                               </td><td>
                               {x.quantity}
                             </td>
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>shipment quantity tolerance</span>
                               </td>
                               <td>
                                {x.shipment_quantity_tolerance}
                             </td>
                             <td id="td-right">
                               <span>quantity from</span>
                               </td>
                               <td>
                                {x.quantity_from}
                             </td>
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>quantity to</span>
                               </td>
                               <td>
                                {x.quantity_to}
                             </td>
                           <td id="td-right">
                               <span></span>
                               </td>
                               <td>
                                 
                             </td>
                             
                           </tr>
                         </tbody>
                       </table>
                     </div> 

                     
                     <div className="">
                       <div className="row">
                         <div className="col-md-12" id="head">
                           <h4 className="heading">System Information</h4>
                           <hr></hr>
                         </div>
                       </div>
                       <table class="table table-bordered account-table tables">
                         <tbody>
                           <tr>
                             <td id="td-right"><span>TCF count</span> </td>
                             <td>{x.tcf_count}</td>
                             <td id="td-right"><span>no of orders</span></td>
                             <td>{x.no_of_orders}
                             </td>
                             
                           </tr>
                           <tr>
                           <td id="td-right">
                               <span>product count</span>
                             </td>
                             <td>
                               {x.product_count}
                             </td>
                             <td id="td-right">
                               <span>
                               order quantity
                                 {/* <OverlayTrigger
                                   placement="right"
                                   delay={{ show: 250, hide: 400 }}
                                   overlay={renderTooltip}
                                 >
                                   <Button variant="success" className="tooltips">
                                     <IoIosHelpCircle id="help" />
                                   </Button>
                                 </OverlayTrigger>{" "} */}
                               </span>
                               </td><td>
                               {x.order_quantity}
                             </td>
                           </tr>
                           
                         </tbody>
                       </table>
                     
                            
                       <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Product Information</span>
                               
                                </h4>
                                <div id="inner-heading"></div>
                                <hr></hr>
                              </div>
                              <table id="product" className="">
                                <thead>
                                  <tr>
                                    <th>Product Code</th>
                                    <th>Product Name</th>
                                    <th>Product Family</th>
                                    <th>Product Description</th>
                                    <th>Quantity MT</th>
                                    <th>Supplier</th> 
                                  </tr>
                                </thead>
                    
                                {product.length > 0 ? (
                                  <tbody>
                                    {product.map((x, i) => (
                                      <tr>
                                        <td>
                                          <Link to={"/contract-shipment/product-details/" + x.id}>{x.product_code}</Link>
                                        </td>
                                        <td>{x.product_name}</td>
                                        <td>{x.product_family}</td>
                                        <td>{x.product_description}</td>
                                        <td>{x.quantity_MT}</td>
                                        <td>{x.supplier}</td> 
                                      </tr>
                                    ))}
                                  </tbody>
                                ) : (
                                  <tbody>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>No data available</td>
                                      <td></td>
                                      <td></td> 
                                    </tr>
                                  </tbody>
                                )}
                              </table>
                            </Row>
                            
                            
                       <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Orders Information</span>
                               
                                </h4>
                                <div id="inner-heading"></div>
                                <hr></hr>
                              </div>
                              <table id="orders" className="">
                                <thead>
                                  <tr>
                                    <th>Order code no</th>
                                    <th>Contract Number</th>
                                    <th>Purchase Invoice</th>
                                    <th>Shipment Qty</th> 
                                  </tr>
                                </thead>
                    
                                {orders.length > 0 ? (
                                  <tbody>
                                    {orders.map((x, i) => (
                                      <tr>
                                        <td>
                                          <Link>{x.order_code_no}</Link>
                                        </td>
                                        <td>{x.contract_number}</td>
                                        <td>{x.purchase_invoice}</td>
                                        <td>{x.shipment_qty}</td> 
                                      </tr>
                                    ))}
                                  </tbody>
                                ) : (
                                  <tbody>
                                    <tr>
                                      
                                      <td></td>
                                      <td>No data available</td>
                                      <td></td>
                                      <td></td> 
                                    </tr>
                                  </tbody>
                                )}
                              </table>
                            </Row>
                     </div>     
                     
                                    </>
                                    
                    ))
                    ):(
                      <></>
                    )
                    }
                   </div>
  )}
    <Footer/>
    
    </div>
  );
}

export default ContractShippmentDetails;
