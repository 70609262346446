import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import { API_URL } from '../config';
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import $ from "jquery";
import axios from "axios";
import Footer from '../Footer'
import Header from '../Header' 
import { AiFillDiff } from "react-icons/ai";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
const FinanceList = () => {
  const userRole = localStorage.getItem("role"); 
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState([]);

  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height() 
  const total_height=header+nav+footer;
  const window_height=body-total_height;  
  $(".contactAccount").css({"min-height":(window_height)+"px"})

  
  useEffect(() => {
   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}get/all/financeconfig`, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const data = response.data.all_finance;
      console.log(data)
      setDatas(data);
      $(document).ready(function(){
        // $('#tables').DataTable().destroy();
        // setTimeout(()=>{
          $('#tables').DataTable();
        // },500)
       })
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <div> 
      <Header/>
    <div className="contactAccount">
    
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Finance List</h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard /Finance List</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <Link to='/finance/add-finance'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Finance</Link>
        </button>
        
      </div>
    </div> 
  </div> 
  <div class='tasks'>
  <table id='tables'>
    <thead>
    <tr>
    <th>Billing Config Name</th> 
      <th>Billing Doc Record Type</th>
      <th>Line Item</th>
      <th>Payable DR Chart Account No</th> 
      <th>payable DR Account Group</th> 
      {/* <th>Actions</th> */}
    </tr>
    </thead>
    <tbody> 
      <React.Fragment>
      {datas.length > 0 ? (
  datas.map((x) => (
    <tr>
    <td><Link to={`/finance/finance-details/${x.id}`}>{x.billing_config_name}</Link></td> 
      <td>{x.billing_doc_record_type}</td>
      <td>{x.line_item}</td>  
      <td>{x.payable_dr_chart_account_no}</td>
      <td>{x.payable_dr_acc_group}</td> 
      {/* <td><Link to={"/contact/edit-contact/"+x.id }><FaPencilAlt/></Link> <FaTrashAlt/><Link to={"/contact/contact-details"+"/"+x.id }><AiFillDiff/></Link></td> */}
    </tr>
  ))
) : (
  <tr>
    <td colSpan="5">No data available</td>
  </tr>
)}

      </React.Fragment>


    </tbody>
    </table>  
    </div> </div> 
    <Footer/>
    </div>
  );
};

export default FinanceList;