import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";

const ReportSelection = ({ onFileSelection }) => {
    
    const [apiPayload, setApiPayload] = useState({});

    const BASE_URL = "https://rwooduatpy.znapay.in";


     
     
    // Function to fetch keys based on API call
    const getKeys = async () => {
         
        try {
             
            const response = await axios.get(
                `${BASE_URL}/get/keys`, {
                    params: {
                        table_name: "accounts_contacts"
                    }
                }
            );

            if (response.data) {

                console.log(response.data)
                onFileSelection(response.data, apiPayload);
            }

        } catch (error) {
            console.error("Error fetching keys:", error);
        }
    };

    // Item selection handler
    const handleItemSelectionToggle = (event, itemId, isSelected) => {

        if (isSelected) {

            setApiPayload({ table_name: itemId });

        }

    };

    // Define your report tree structure
    const RWOOD_REPORT_DOCX = [
        {
            id: "accounts_and_contacts",
            label: "Accounts & Contacts",
            children: [
                { id: "accounts", label: "Accounts" },
                { id: "accounts_ledger_1", label: "Accounts and Ledger 1" },
                { id: "accounts_ledger_2", label: "Accounts and Ledger 2" },
                { id: "accounts_ledger_3", label: "Accounts and Ledger 3" },
                { id: "accounts_ledger_4", label: "Accounts and Ledger 4" },
                {
                    id: "contacts_accounts_ledger_1",
                    label: "Contacts & Accounts and Ledger 1",
                },
                {
                    id: "contacts_accounts_ledger_2",
                    label: "Contacts & Accounts and Ledger 2",
                },
                {
                    id: "contacts_accounts_ledger_3",
                    label: "Contacts & Accounts and Ledger 3",
                },
                {
                    id: "contacts_accounts_ledger_4",
                    label: "Contacts & Accounts and Ledger 4",
                },
                { id: "accounts_contacts", label: "Contact & Accounts" },
            ],
        },
        {
            id: "opportunities",
            label: "Opportunities",
            children: [
                { id: "opportunities_report", label: "Opportunities" },
                {
                    id: "opportunities_trader_name",
                    label: "Opportunities and Trader Name",
                },
                {
                    id: "opportunities_price_type",
                    label: "Opportunities and Price Type",
                },
                { id: "opportunities_company", label: "Opportunities and Company" },
            ],
        },
        {
            id: "forecasts",
            label: "ForeCasts",
            children: [{ id: "rw_price_books", label: "RW Price Books" }],
        },
        {
            id: "customer_support_reports",
            label: "Customer Support Reports",
            children: [
                { id: "cases", label: "Cases" },
                { id: "cases_lifecycle", label: "Cases Lifecycle" },
                { id: "cases_contact_roles", label: "Cases With Contact Roles" },
            ],
        },
        {
            id: "leads",
            label: "Leads",
            children: [
                { id: "leads_1", label: "Leads" },
                {
                    id: "leads_converted_lead_info",
                    label: "Leads with converted lead information",
                },
                {
                    id: "leads_converted_lead_info_trader_name",
                    label: "Leads with converted lead information and Trader Name",
                },
            ],
        },
        {
            id: "campaigns",
            label: "Campaigns",
            children: [
                { id: "campaigns_1", label: "Campaigns" },
                { id: "campaigns_contacts", label: "Campaigns With Contacts" },
                { id: "campaigns_lead", label: "Campaigns with Leads" },
            ],
        },
        {
            id: "activities",
            label: "Activities",
            children: [
                { id: "task_events", label: "Task and Events" },
                { id: "events_invitees", label: "Events with Invitees" },
            ],
        },
        {
            id: "contracts_orders",
            label: "Contracts and Orders",
            children: [
                { id: "contract", label: "Contracts" },
                { id: "contract_ledger_1", label: "Contracts and Ledger 1" },
                { id: "contract_ledger_2", label: "Contracts and Ledger 2" },
                { id: "contract_ledger_3", label: "Contracts and Ledger 3" },
                { id: "contract_ledger_4", label: "Contracts and Ledger 4" },
            ],
        },
    ];

    return (
        <div className="rwood_report_selection_container">
            <div className="rwood_report_selection_section_preview_container">
                <div className="rwood_report_selection">
                    <Stack spacing={2}>
                        <Box sx={{ minHeight: 352, minWidth: 300 }}>
                            <RichTreeView
                                items={RWOOD_REPORT_DOCX}
                                onItemSelectionToggle={handleItemSelectionToggle}
                            />
                        </Box>
                    </Stack>
                </div>
                
            </div>

            <div className="rwood_report_action_btn">
                <Button variant="contained" onClick={getKeys}>
                    Create
                </Button>
            </div>
        </div>
    );
};

export default ReportSelection;






 