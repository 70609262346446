

import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header' 
import Footer from '../Footer' 
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function AddLaytime() {
  const [orders, setOrders] = useState([]);
  // bank
  useEffect(() => {
    fetchBank();
  }, [decryptedAccessToken]);

  const fetchBank = async () => {
    try {
      const response = await axios.get(
        "https://rwooduatpy.znapay.in/get/allorders",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.orders;
            setOrders(data); 
    } catch (error) {
      console.error(error);
    }
  };
  
    const [startDate1, setStartDate1] = useState(new Date());
   const date1=startDate1.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
 const [startDate2, setStartDate2] = useState(new Date());
  const date2=startDate2.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate2.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate2.toLocaleDateString("en-US", { day: '2-digit' }))
  const  CustomInput2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input2"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

    const [startDate3, setStartDate3] = useState(new Date());
   const date3=startDate3.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate3.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate3.toLocaleDateString("en-US", { day: '2-digit' }))
  const CustomInput3 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input3"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  
  $(".contactAccount").css({"min-height":(window_height)+"px"})
    
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = data =>{ 
        const responseData={
          order:data.order,
          temp_time_start:date1,
          temp_time_end:date2,
          percent_to_count:data.percent_to_count,
          stoppgae_remarks:data.stoppgae_remarks,
          date:date3,
          total_days:data.total_days,
          days_left:data.days_left,
          time_start:data.time_start,
          time_end:data.time_end,
          time_used:data.time_used
      }; 
      console.log(JSON.stringify(responseData))
      const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
          
        fetch('https://rwooduatpy.znapay.in/add/laytime', {
                  method: "POST",
                  body: JSON.stringify(responseData),
                  headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json' },
                })
                  .then((response) => response.json())
                  
                  .then((response) => {   
                 toast.success("Laytime record added succesfully", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              reset();
            }, 300);
                  })
                  
                } 
                 
        };
      
  return (

    <div className="addAccount">
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Laytime</h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Add Laytime</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div>    
  <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
        <h4 className='heading'>Laytime Information</h4>
         <Form.Group as={Col} md="6">
         <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="Order"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="order"
                id="account_name"
                placeholder="Order"
                defaultValue=""
                {...register("order", { required: false })}
              >
                <option value="">Select</option>
                {orders.map((x) => {
                  return (
                    <option value={x.order_code_no}>{x.order_code_no}</option>
                  );
                })}
              </Form.Select>
              {errors.order && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>
         
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Temp Time Start"
            className="mb-3"
            
            
          >
            <DatePicker
      selected={startDate1}
      onChange={(date) => setStartDate1(date)}
      customInput={<CustomInput />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Choose Date'
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Temp Time End"
            className="mb-3"
            
            
          >
            <DatePicker
      selected={startDate2}
      onChange={(date) => setStartDate2(date)}
      customInput={<CustomInput2 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Choose Date'
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Percent To Count"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Percent To Count"
              name="percent_to_count"
              {...register("percent_to_count", {valueAsNumber:true, required: false })}
            />
            {errors.percent_to_count && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Stoppgae Remarks"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Stoppgae Remarks"
              name="stoppgae_remarks"
              {...register("stoppgae_remarks", { required: false })}
            />
            {errors.stoppgae_remarks && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
       
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Date"
            className="mb-3"
            
            
          >
            <DatePicker
      selected={startDate3}
      onChange={(date) => setStartDate3(date)}
      customInput={<CustomInput3 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Choose Date'
    />
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Total Days"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Total Days"
              name="total_days"
              {...register("total_days", {valueAsNumber:true, required: false })}
            />
            {errors.total_days && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>   
         
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Days Left"
             className="mb-3"
             
             
           >
             <Form.Control
               type="text"
               placeholder="Days Left"
               name="days_left"
               {...register("days_left", {valueAsNumber:true, required: false })}
             />
             {errors.days_left && <span>Required </span>}
           </FloatingLabel>
         </Form.Group>    
          
         
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Time Start"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Time Start"
              name="time_start"
              {...register("time_start", {required: false })}
            />
            {errors.time_start && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>   
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Time End"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Time End"
              name="time_end"
              {...register("time_end", {required: false })}
            />
            {errors.time_end && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>    
           
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Time Used"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Time Used"
              name="time_used"
              {...register("time_used", {required: false })}
            />
            {errors.time_used && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>    
           
                </Row>
      <input type="submit"  className="addaccount-save" value='Save'/>
      <div className='successDiv'><p></p><p></p></div>
    </Form>
    <Footer/>
    </div>
  );
}

export default AddLaytime;

