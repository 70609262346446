import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AiFillDiff } from "react-icons/ai";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import $ from "jquery";
import axios from "axios";
import Footer from '../Footer'
import { API_URL } from '../config';
import Header from '../Header' 
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
const PriceBooklist = () => {
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
const [loading, setLoading] = useState(false);
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height() 
  const total_height=header+nav+footer;
  const window_height=body-total_height;  
  $(".contactAccount").css({"min-height":(window_height)+"px"})

  
  useEffect(() => {
   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(API_URL+'get/all/pricebook', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const data = response.data.pricebook; 
      console.log(data)
      setLoading(false);
      setDatas(data);
      $(document).ready(function(){
        // $('#tables').DataTable().destroy();
        // setTimeout(()=>{
          $('#tables').DataTable();
        // },500)
       })
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <div> 
      <Header/>
    <div className="contactAccount">
    
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Pricebook List </h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / price / pricebook list</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <Link to='/pricebook/add-pricebook'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Pricebook</Link>
        </button>
        
      </div>
    </div> 
  </div> 
  <div class='tasks'>
  {loading ? <p id='loading'>Loading Data..</p> 
    :<table id='tables'>
    <thead>
    <tr> 
    <th>Pricebook Code</th>
      <th>Month</th>
      <th>Year</th>
      <th>Price book name</th>
      <th>Description</th>  
      <th>Nex</th>
      <th>RB</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody> 
      <React.Fragment>
      {datas.length > 0 ? (
  datas.map((x) => (
    <tr>
      <td><Link to={"/pricebook/pricebook-details/"+x.id }>{x.pricebook_code}</Link></td>
      <td>{x.month}</td>
      <td>{x.year}</td>  
      <td>{x.price_book_name}</td>
      <td>{x.description}</td>    
      <td>{x.Nex}</td>
      <td>{x.RB}</td>
      <td>
      <Link to={"/pricebook/update-price-book/"+x.id}><FaPencilAlt/></Link><Link to={"/pricebook/pricebook-details/"+x.id }><AiFillDiff/></Link>
       </td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="6">No data available</td>
  </tr>
)}

      </React.Fragment>


    </tbody>
    </table>
    }  
    </div> </div> 
    <Footer/>
    </div>
  );
};

export default PriceBooklist;