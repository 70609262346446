import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from "crypto-js";
import Header from "../Header";
import Footer from "../Footer";
import $ from "jquery";
import axios from "axios";
import { API_URL } from '../config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function UpdateAccount() {
  const [startDate, setStartDate] = useState(new Date());

  const renewalDate =
    startDate.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name="KYC_doc_date"
      ref={ref}
    ></input>
  ));

  const params = useParams();
  const id = params.id;
  const body = $(window).height();
  const header = $("#header").height();
  const nav = $(".navbar").height();
  const footer = $(".footer").height();
  const total_height = header + nav + footer;
  const window_height = body - total_height;
  console.log(total_height);
  console.log(body);

  $(".contactAccount").css({ "min-height": window_height + "px" });
  const [update, setUpdate] = useState([]);
  const [type, setType] = useState("");
  const [account, setAccount] = useState([]);

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}all/accountsbyID/` + id,
        {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data.accounts;
      console.log(data);
      setAccount(data);
      setValue("account_name", data.account_name);
      setValue("account_alias", data.account_alias);
      setValue("website", data.website);
      setValue("company", data.company);
      setValue("account_owner", data.account_owner);
      setValue("parent_account", data.parent_account);
      setValue("account_number", data.account_number);
      setValue("account_record_type", data.account_record_type);
      setValue("annual_revenue", data.annual_revenue);
      setValue("no_of_employees", data.no_of_employees);
      setValue("port", data.port);
      setValue("origin", data.origin);
      setValue("remarks", data.remarks);
      setValue("product", data.product);
      setValue("general_terms", data.general_terms);
      setValue("region", data.region);
      setValue("billing_street", data.billing_street);
      setValue("phone", data.phone);
      setValue("shipping_street", data.shipping_street);
      setValue("billing_city", data.billing_city);
      setValue("shipping_postal_code", data.shipping_postal_code);
      setValue("shipping_city", data.shipping_city);
      setValue("shipping_state_or_province", data.shipping_state_or_province);
      setValue("shipping_country", data.shipping_country);
      setValue("billing_state_or_province", data.billing_state_or_province);
      setValue("billing_country", data.billing_country);
      setValue("fax", data.fax);
      setValue("KYC_docs", data.KYC_docs);
      setValue("KYC_doc_date", data.KYC_doc_date);
      setValue("refrence_check_date", data.refrence_check_date);
      setValue("refrence_check", data.refrence_check);
      setValue("commitment", data.commitment);
      setValue("reliability", data.reliability);
      setValue("under_group", data.under_group);
      setValue("station_name", data.station_name);
      setValue(
        "expansion_setup_or_buyer_status",
        data.expansion_setup_or_buyer_status
      );
      setValue("industry", data.industry);
      setValue("sector", data.sector);
      setValue("market_impression_rating", data.market_impression_rating);
      setValue(
        "annual_coal_requirement_in_MT",
        data.annual_coal_requirement_in_MT
      );
      setValue("imported_volume_PA_in_MT", data.imported_volume_PA_in_MT);
      setValue("quantity_MT_monthly", data.quantity_MT_monthly);
      setValue("production_capacity", data.production_capacity);
      setValue("originiaze_import_breakup", data.originiaze_import_breakup);
      setValue("account_source", data.account_source);
      setValue("rating", data.rating);
      setValue("coal_spec_1_3800_GAR", data.coal_spec_1_3800_GAR);
      setValue("coal_spec_2_4200_GAR", data.coal_spec_2_4200_GAR);
      setValue("coal_spec_3_4700_GAR", data.coal_spec_3_4700_GAR);
      setValue("coal_spec_4_5000_GAR", data.coal_spec_4_5000_GAR);
      setValue("coal_spec_5_5500_GAR", data.coal_spec_5_5500_GAR);
      setValue("description", data.description);
      setValue("coal_spec_6", data.coal_spec_6);
      setValue("coal_spec_7", data.coal_spec_7);
      setValue("coal_spec_8", data.coal_spec_8);
    } catch (error) {
      console.error(error);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const {
    reset: reset2,
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    mode: "onBlur",
  });
  const {
    reset: reset3,
    register: register3,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
  } = useForm({
    mode: "onBlur",
  });
  const {
    reset: reset4,
    register: register4,
    formState: { errors: errors4 },
    handleSubmit: handleSubmit4,
  } = useForm({
    mode: "onBlur",
  });
  const {
    reset: reset5,
    register: register5,
    formState: { errors: errors5 },
    handleSubmit: handleSubmit5,
  } = useForm({
    mode: "onBlur",
  });
  if (account.account_record_type === "Agent") {
    const onSubmit = (data) => {
      const responseData = {
        account_name: data.account_name,
        account_alias: data.account_alias,
        website: data.website,
        account_owner: data.account_owner,
        parent_account: data.parent_account,
        account_number: data.account_number,
        account_record_type: data.account_record_type,
        annual_revenue: data.annual_revenue,
        no_of_employees: data.no_of_employees,
        port: data.port,
        origin: data.origin,
        remarks: data.remarks,
        product: data.product,
        general_terms: data.general_terms,
        region: data.region,
        billing_street: data.billing_street,
        phone: data.phone,
        shipping_street: data.shipping_street,
        billing_city: data.billing_city,
        shipping_postal_code: data.shipping_postal_code,
        shipping_city: data.shipping_city,
        shipping_state_or_province: data.shipping_state_or_province,
        shipping_country: data.shipping_country,
        billing_state_or_province: data.billing_state_or_province,
        billing_country: data.billing_country,
        fax: data.fax,
        KYC_docs: data.KYC_docs,
        KYC_doc_date: data.KYC_doc_date,
        refrence_check_date: data.refrence_check_date,
        refrence_check: data.refrence_check,
        commitment: data.commitment,
        reliability: data.reliability,
        under_group: data.under_group,
        station_name: data.station_name,
        expansion_setup_or_buyer_status: data.expansion_setup_or_buyer_status,
        industry: data.industry,
        sector: data.sector,
        market_impression_rating: data.market_impression_rating,
        annual_coal_requirement_in_MT: data.annual_coal_requirement_in_MT,
        imported_volume_PA_in_MT: data.imported_volume_PA_in_MT,
        quantity_MT_monthly: data.quantity_MT_monthly,
        production_capacity: data.production_capacity,
        originiaze_import_breakup: data.originiaze_import_breakup,
        account_source: data.account_source,
        rating: data.rating,
        coal_spec_1_3800_GAR: data.coal_spec_1_3800_GAR,
        coal_spec_2_4200_GAR: data.coal_spec_2_4200_GAR,
        coal_spec_3_4700_GAR: data.coal_spec_3_4700_GAR,
        coal_spec_4_5000_GAR: data.coal_spec_4_5000_GAR,
        coal_spec_5_5500_GAR: data.coal_spec_5_5500_GAR,
        description: data.description,
        coal_spec_6: data.coal_spec_6,
        coal_spec_7: data.coal_spec_7,
        coal_spec_8: data.coal_spec_8,
      };
      console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}update/account/` + id, {
          method: "PUT",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response);
            toast.success("Account Updated Successfully", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              reset();
            }, 300);
          });
      }
    };
    const handleChange = (e) => {
      const { name, value } = e.target;
      setUpdate((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };

    return (
      <>
        <Header />
        <div className="contactAccount">
          <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">
                Edit Agent Accounts{" "}
              </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Account/ Edit Agent{" "}
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <h4 className="heading">Account Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Name"
                    name="account_name"
                    defaultValue={account.account_name || ""}
                    onChange={handleChange}
                    {...register("account_name", { required: false })}
                  />
                  {errors.account_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Owner"
                    name="account_owner"
                    defaultValue={account.account_owner || ""}
                    onChange={handleChange}
                    {...register("account_owner", { required: false })}
                  />
                  {errors.account_owner && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Alias"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Alias"
                    name="account_alias"
                    defaultValue={account.account_alias || ""}
                    onChange={handleChange}
                    {...register("account_alias", { required: false })}
                  />
                  {errors.account_alias && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Account Number"
                    name="account_number"
                    defaultValue={account.account_number || ""}
                    onChange={handleChange}
                    {...register("account_number", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.website && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Website"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Website"
                    name="website"
                    defaultValue={account.account_number || ""}
                    onChange={handleChange}
                    {...register("website", { required: false })}
                  />
                  {errors.website && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account record type"
                    name="account_record_type"
                    defaultValue={account.account_record_type || ""}
                    onChange={handleChange}
                    {...register("account_record_type", { required: false })}
                  />
                  {errors.account_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port"
                    name="port"
                    defaultValue={account.port || ""}
                    onChange={handleChange}
                    {...register("port", { required: false })}
                  />
                  {errors.port && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Annual revenue"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Annual revenue"
                    name="annual_revenue"
                    defaultValue={account.annual_revenue || ""}
                    onChange={handleChange}
                    {...register("annual_revenue", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.annual_revenue && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    defaultValue={account.origin || ""}
                    onChange={handleChange}
                    {...register("origin", { required: false })}
                  />
                  {errors.origin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of employees"
                    name="no_of_employees"
                    defaultValue={account.no_of_employees || ""}
                    onChange={handleChange}
                    {...register("no_of_employees", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.no_of_employees && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Product"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Product"
                    name="product"
                    defaultValue={account.product || ""}
                    onChange={handleChange}
                    {...register("product", { required: false })}
                  />
                  {errors.product && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remarks"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Remarks"
                    name="remarks"
                    defaultValue={account.remarks || ""}
                    onChange={handleChange}
                    {...register("remarks", { required: false })}
                  />
                  {errors.remarks && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Address Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Region"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Region"
                    name="region"
                    defaultValue={account.region || ""}
                    onChange={handleChange}
                    {...register("region", { required: false })}
                  />
                  {errors.region && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Phone"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    name="phone"
                    defaultValue={account.phone || ""}
                    onChange={handleChange}
                    {...register("phone", { required: false })}
                  />
                  {errors.phone && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Billing Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Billing Address"
                    style={{ height: "100px" }}
                    name="billing_street"
                    defaultValue={account.billing_street || ""}
                    onChange={handleChange}
                    {...register("billing_street", { required: false })}
                  />
                  {errors.billing_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Fax"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="fax"
                    name="fax"
                    defaultValue={account.fax || ""}
                    onChange={handleChange}
                    {...register("fax", { required: false })}
                  />
                  {errors.fax && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing postal code"
                    name="billing_postal_code"
                    defaultValue={account.billing_postal_code || ""}
                    onChange={handleChange}
                    {...register("billing_postal_code", { required: false })}
                  />
                  {errors.billing_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Shipping Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Shipping Address"
                    style={{ height: "100px" }}
                    name="shipping_street"
                    defaultValue={account.shipping_street || ""}
                    onChange={handleChange}
                    {...register("shipping_street", { required: false })}
                  />
                  {errors.shipping_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    defaultValue={account.billing_city || ""}
                    onChange={handleChange}
                    {...register("billing_city", { required: false })}
                  />
                  {errors.billing_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping postal code"
                    name="shipping_postal_code"
                    defaultValue={account.shipping_postal_code || ""}
                    onChange={handleChange}
                    {...register("shipping_postal_code", { required: false })}
                  />
                  {errors.shipping_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state province"
                    name="billing_state_or_province"
                    defaultValue={account.billing_state_or_province || ""}
                    onChange={handleChange}
                    {...register("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state province"
                    name="shipping_state_or_province"
                    defaultValue={account.shipping_state_or_province || ""}
                    onChange={handleChange}
                    {...register("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.shipping_state_or_province && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    defaultValue={account.shipping_city || ""}
                    onChange={handleChange}
                    {...register("shipping_city", { required: false })}
                  />
                  {errors.shipping_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    defaultValue={account.billing_country || ""}
                    onChange={handleChange}
                    {...register("billing_country", { required: false })}
                  />
                  {errors.billing_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="shipping_country"
                    defaultValue={account.shipping_country || ""}
                    onChange={handleChange}
                    {...register("shipping_country", { required: false })}
                  />
                  {errors.shipping_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Rwood Reference</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Start Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    customInput={<ExampleCustomInput />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Commitment"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Commitment"
                    name="commitment"
                    defaultValue={account.commitment || ""}
                    onChange={handleChange}
                    {...register("commitment", { required: false })}
                  />
                  {errors.commitment && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="KYC doc date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="KYC doc date"
                    name="KYC_doc_date"
                    defaultValue={account.KYC_doc_date || ""}
                    onChange={handleChange}
                    {...register("KYC_doc_date", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.KYC_doc_date && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Reliability"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Reliability"
                    name="reliability"
                    defaultValue={account.reliability || ""}
                    onChange={handleChange}
                    {...register("reliability", { required: false })}
                  />
                  {errors.reliability && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Refrence check"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Refrence check"
                    name="refrence_check"
                    defaultValue={account.refrence_check || ""}
                    onChange={handleChange}
                    {...register("refrence_check", { required: false })}
                  />
                  {errors.refrence_check && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Refrence check date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Refrence check date"
                    name="refrence_check_date"
                    defaultValue={account.refrence_check_date || ""}
                    onChange={handleChange}
                    {...register("refrence_check_date", { required: false })}
                  />
                  {errors.refrence_check_date && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Industry Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Parent account"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Parent account"
                    name="parent_account"
                    defaultValue={account.parent_account || ""}
                    onChange={handleChange}
                    {...register("parent_account", { required: false })}
                  />
                  {errors.parent_account && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="annual coal requirement in  MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="annual coal requirement in  MT"
                    name="annual_coal_requirement_in_MT"
                    defaultValue={account.annual_coal_requirement_in_MT || ""}
                    onChange={handleChange}
                    {...register("annual_coal_requirement_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.annual_coal_requirement_in_MT && (
                    <span>Required</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Under group"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Under group"
                    name="under_group"
                    defaultValue={account.under_group || ""}
                    onChange={handleChange}
                    {...register("under_group", { required: false })}
                  />
                  {errors.under_group && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Imported volume PA in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Imported volume PA in MT"
                    name="imported_volume_PA_in_MT"
                    defaultValue={account.imported_volume_PA_in_MT || ""}
                    onChange={handleChange}
                    {...register("imported_volume_PA_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.imported_volume_PA_in_MT && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Station name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Station name"
                    name="station_name"
                    defaultValue={account.station_name || ""}
                    onChange={handleChange}
                    {...register("station_name", { required: false })}
                  />
                  {errors.station_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity MT monthly"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Quantity MT monthly"
                    name="quantity_MT_monthly"
                    defaultValue={account.quantity_MT_monthly || ""}
                    onChange={handleChange}
                    {...register("quantity_MT_monthly", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.quantity_MT_monthly && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Expansion setup or buyer status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="expansion_setup_or_buyer_status"
                    placeholder="Expansion setup or buyer status"
                    defaultValue={account.expansion_setup_or_buyer_status || ""}
                    onChange={handleChange}
                    {...register("expansion_setup_or_buyer_status", {
                      required: false,
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Under Construction">
                      Under Construction
                    </option>
                    <option value="Operated">Operated</option>
                    <option value="Permitted">Permitted</option>
                  </Form.Select>
                  {errors.expansion_setup_or_buyer_status && (
                    <span>Required Account Name</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Production capacity"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Production capacity"
                    name="production_capacity"
                    defaultValue={account.production_capacity || ""}
                    onChange={handleChange}
                    {...register("production_capacity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.production_capacity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Industry"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="industry"
                    placeholder="Industry"
                    defaultValue={account.industry || ""}
                    onChange={handleChange}
                    {...register("industry", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Chemicals">Chemicals</option>
                    <option value="Constructions">Constructions</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Energy">Energy</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Environmental">Environmental</option>
                    <option value="Government">Government</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Other">Other</option>
                    <option value="Shipping">Shipping</option>
                  </Form.Select>
                  {errors.industry && <span>Required Account Name</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Originiaze import breakup"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Originiaze import breakup"
                    name="originiaze_import_breakup"
                    defaultValue={account.originiaze_import_breakup || ""}
                    onChange={handleChange}
                    {...register("originiaze_import_breakup", {
                      required: false,
                    })}
                  />
                  {errors.originiaze_import_breakup && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Sector"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="sector"
                    placeholder="Sector"
                    defaultValue={account.sector || ""}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="Government">Government</option>
                    <option value="Power Sector">Power Sector</option>
                    <option value="Private Sector">Private Sector</option>
                    <option value="Industrial">Industrial</option>
                  </Form.Select>
                  {errors.sector && <span>Required Account Name</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Market impression rating"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Market impression rating"
                    name="market_impression_rating"
                    defaultValue={account.market_impression_rating || ""}
                    onChange={handleChange}
                    {...register("market_impression_rating", {
                      required: false,
                    })}
                  />
                  {errors.market_impression_rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            {/* product Information */}
            <Row className="mb-3">
              <h4 className="heading">Product Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 1 3800 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 1 3800 GAR"
                    name="coal_spec_1_3800_GAR"
                    defaultValue={account.coal_spec_1_3800_GAR || ""}
                    onChange={handleChange}
                    {...register("coal_spec_1_3800_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_1_3800_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 3 4700 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 3 4700 GAR"
                    name="coal_spec_3_4700_GAR"
                    defaultValue={account.coal_spec_3_4700_GAR || ""}
                    onChange={handleChange}
                    {...register("coal_spec_3_4700_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_3_4700_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 2 4200 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 2 4200 GAR"
                    name="coal_spec_2_4200_GAR"
                    defaultValue={account.coal_spec_2_4200_GAR || ""}
                    onChange={handleChange}
                    {...register("coal_spec_2_4200_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_2_4200_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px!important" }}
                    name="description"
                    {...register("description", { required: false })}
                  />
                  {errors.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 4 5000 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 4 5000 GAR"
                    name="coal_spec_4_5000_GAR"
                    defaultValue={account.coal_spec_4_5000_GAR || ""}
                    onChange={handleChange}
                    {...register("coal_spec_4_5000_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_4_5000_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 5 5500 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 5 5500 GAR"
                    name="coal_spec_5_5500_GAR"
                    defaultValue={account.coal_spec_5_5500_GAR || ""}
                    onChange={handleChange}
                    {...register("coal_spec_5_5500_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_5_5500_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 6"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 6"
                    name="coal_spec_6"
                    defaultValue={account.coal_spec_6 || ""}
                    onChange={handleChange}
                    {...register("coal_spec_6", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_6 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 7"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 7"
                    name="coal_spec_7"
                    defaultValue={account.coal_spec_7 || ""}
                    onChange={handleChange}
                    {...register("coal_spec_7", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_7 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 8"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 8"
                    name="coal_spec_8"
                    defaultValue={account.coal_spec_8 || ""}
                    onChange={handleChange}
                    {...register("coal_spec_8", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_8 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">System Information</h4>
              {/* <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Account Source"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Account Source"
              name="account_source"
              defaultValue={account.account_source || ""}  onChange={handleChange}
              {...register("account_source", { required: false })}
            />
            {errors.account_source && <span>Required </span>}
          </FloatingLabel>
        </Form.Group> */}

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rating"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Rating"
                    name="rating"
                    defaultValue={account.rating || ""}
                    onChange={handleChange}
                    {...register("rating", { required: false })}
                  />
                  {errors.rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Account Summary</h4>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="General terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="general_terms"
                    style={{ height: "200px!important" }}
                    name="general_terms"
                    defaultValue={account.general_terms || ""}
                    onChange={handleChange}
                    {...register("general_terms", { required: false })}
                  />
                  {errors.general_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <input type="submit" className="addaccount-save" value="Save" />
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form> 
        </div>
        <Footer />
      </>
    );
  } if (account.account_record_type === "Bank") {
    const bankPage = (data) => {
      const responseData = {
        account_name: data.account_name,
        account_alias: data.account_alias,
        port: data.port,
        origin: data.origin,
        product: data.product,
        remarks: data.remarks,
        company: data.company,
        account_record_type: data.account_record_type,
        account_owner: data.account_owner,
        parent_account: data.parent_account,
        fax: data.fax,
        phone: data.phone,
        employees: data.employees,
        shipping_street: data.shipping_street,
        billing_street: data.billing_street,
        shipping_postal_code: data.shipping_postal_code,
        billing_postal_code: data.billing_postal_code,
        billing_city: data.billing_city,
        shipping_city: data.shipping_city,
        shipping_country: data.shipping_country,
        billing_country: data.billing_country,
      };
      console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}update/account/` + id, {
          method: "PUT",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response);
            toast.success("Account Updated Successfully", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            // setTimeout(() => {
            //   reset2();
            // }, 300);
          });
      }
    };
    const handleChange = (e) => {
      const { name, value } = e.target;
      setUpdate((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
    return (
      <>
        <Header />
        <div className="contactAccount">
          <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Accounts </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Account/ Edit Bank
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center"></div>
            </div>
          </div>

          <Form onSubmit={handleSubmit2(bankPage)}>
            <Row className="mb-3">
              <h4 className="heading">Account Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Name"
                    name="account_name"
                    defaultValue={account.account_name || ""}
                    onChange={handleChange}
                    {...register2("account_name", { required: false })}
                  />
                  {errors2.account_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Owner"
                    name="account_owner"
                    defaultValue={account.account_owner || ""}
                    onChange={handleChange}
                    {...register2("account_owner", { required: false })}
                  />
                  {errors2.account_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Company"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Company"
                    name="company"
                    defaultValue={account.company || ""}
                    onChange={handleChange}
                    {...register2("company", { required: false })}
                  />
                  {errors2.company && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Alias"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Alias"
                    name="account_alias"
                    defaultValue={account.account_alias || ""}
                    onChange={handleChange}
                    {...register2("account_alias", { required: false })}
                  />
                  {errors2.account_alias && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account record type"
                    name="account_record_type"
                    defaultValue={account.account_record_type || ""}
                    onChange={handleChange}
                    {...register2("account_record_type", { required: false })}
                  />
                  {errors2.account_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Parent Account"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Parent Account"
                    name="parent_account"
                    defaultValue={account.parent_account || ""}
                    onChange={handleChange}
                    {...register2("parent_account", { required: false })}
                  />
                  {errors2.parent_account && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port"
                    name="port"
                    defaultValue={account.port || ""}
                    onChange={handleChange}
                    {...register2("port", { required: false })}
                  />
                  {errors2.port && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Fax"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Fax"
                    name="fax"
                    defaultValue={account.fax || ""}
                    onChange={handleChange}
                    {...register2("fax", { required: false })}
                  />
                  {errors2.fax && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    defaultValue={account.origin || ""}
                    onChange={handleChange}
                    {...register2("origin", { required: false })}
                  />
                  {errors2.origin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Phone"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Phone"
                    name="phone"
                    defaultValue={account.phone || ""}
                    onChange={handleChange}
                    {...register2("phone", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors2.phone && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Product"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Product"
                    name="product"
                    defaultValue={account.product || ""}
                    onChange={handleChange}
                    {...register2("product", { required: false })}
                  />
                  {errors2.product && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Employees"
                    name="employees"
                    defaultValue={account.employees || ""}
                    onChange={handleChange}
                    {...register2("employees", { required: false })}
                  />
                  {errors2.employees && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remarks"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Remarks"
                    name="remarks"
                    defaultValue={account.remarks || ""}
                    onChange={handleChange}
                    {...register2("remarks", { required: false })}
                  />
                  {errors2.remarks && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Address Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Billing Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Billing Address"
                    style={{ height: "100px" }}
                    name="billing_street"
                    defaultValue={account.billing_street || ""}
                    onChange={handleChange}
                    {...register2("billing_street", { required: false })}
                  />
                  {errors2.billing_street && (
                    <p id="text-area">Required Billing Address</p>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Shipping Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Shipping Address"
                    style={{ height: "100px" }}
                    name="shipping_street"
                    defaultValue={account.shipping_street || ""}
                    onChange={handleChange}
                    {...register2("shipping_street", { required: false })}
                  />
                  {errors2.shipping_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing postal code"
                    name="billing_postal_code"
                    defaultValue={account.billing_postal_code || ""}
                    onChange={handleChange}
                    {...register2("billing_postal_code", { required: false })}
                  />
                  {errors2.billing_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping postal code"
                    name="shipping_postal_code"
                    defaultValue={account.shipping_postal_code || ""}
                    onChange={handleChange}
                    {...register2("shipping_postal_code", { required: false })}
                  />
                  {errors2.shipping_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    defaultValue={account.billing_city || ""}
                    onChange={handleChange}
                    {...register2("billing_city", { required: false })}
                  />
                  {errors2.billing_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    defaultValue={account.shipping_city || ""}
                    onChange={handleChange}
                    {...register2("shipping_city", { required: false })}
                  />
                  {errors2.shipping_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state province"
                    name="billing_state_or_province"
                    defaultValue={account.billing_state_or_province || ""}
                    onChange={handleChange}
                    {...register2("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors2.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state province"
                    name="shipping_state_or_province"
                    defaultValue={account.shipping_state_or_province || ""}
                    onChange={handleChange}
                    {...register2("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors2.shipping_state_or_province && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    defaultValue={account.billing_country || ""}
                    onChange={handleChange}
                    {...register2("billing_country", { required: false })}
                  />
                  {errors2.billing_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="shipping_country"
                    defaultValue={account.shipping_country || ""}
                    onChange={handleChange}
                    {...register2("shipping_country", { required: false })}
                  />
                  {errors2.shipping_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <input type="submit" className="addaccount-save" value="Save" />
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form> 
        </div>
        <Footer />
      </>
    );
  } if (account.account_record_type === "Surveyor") {
    const serviyor = (data) => {
      const responseData = {
        account_name: data.account_name,
        account_alias: data.account_alias,
        website: data.website,
        account_owner: data.account_owner,
        parent_account: data.parent_account,
        account_number: data.account_number,
        account_record_type: data.account_record_type,
        annual_revenue: data.annual_revenue,
        description: data.description,
        no_of_employees: data.no_of_employees,
        port: data.port,
        origin: data.origin,
        remarks: data.remarks,
        product: data.product,
        general_terms: data.general_terms,
        region: data.region,
        billing_street: data.billing_street,
        phone: data.phone,
        shipping_street: data.shipping_street,
        billing_city: data.billing_city,
        shipping_postal_code: data.shipping_postal_code,
        shipping_city: data.shipping_city,
        shipping_state_or_province: data.shipping_state_or_province,
        shipping_country: data.shipping_country,
        billing_state_or_province: data.billing_state_or_province,
        billing_country: data.billing_country,
        fax: data.fax,
        KYC_docs: data.KYC_docs,
        KYC_doc_date: data.KYC_doc_date,
        refrence_check_date: data.refrence_check_date,
        refrence_check: data.refrence_check,
        commitment: data.commitment,
        reliability: data.reliability,
        under_group: data.under_group,
        station_name: data.station_name,
        expansion_setup_or_buyer_status: data.expansion_setup_or_buyer_status,
        industry: data.industry,
        sector: data.sector,
        market_impression_rating: data.market_impression_rating,
        annual_coal_requirement_in_MT: data.annual_coal_requirement_in_MT,
        imported_volume_PA_in_MT: data.imported_volume_PA_in_MT,
        quantity_MT_monthly: data.quantity_MT_monthly,
        production_capacity: data.production_capacity,
        originiaze_import_breakup: data.originiaze_import_breakup,
        account_source: data.account_source,
        rating: data.rating,
        coal_spec_1_3800_GAR: data.coal_spec_1_3800_GAR,
        coal_spec_2_4200_GAR: data.coal_spec_2_4200_GAR,
        coal_spec_3_4700_GAR: data.coal_spec_3_4700_GAR,
        coal_spec_4_5000_GAR: data.coal_spec_4_5000_GAR,
        coal_spec_5_5500_GAR: data.coal_spec_5_5500_GAR,
        coal_spec_6: data.coal_spec_6,
        coal_spec_7: data.coal_spec_7,
        coal_spec_8: data.coal_spec_8,
      };
      console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}update/account/` + id, {
          method: "PUT",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response);
            toast.success("Account Updated Successfully", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              reset();
            }, 300);
          });
      }
    };
    const handleChange = (e) => {
      const { name, value } = e.target;
      setUpdate((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
    return (
      <>
        <Header />
        <div className="contactAccount">
          <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Accounts </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Account/ Edit Surveyor
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit3(serviyor)}>
            <Row className="mb-3">
              <h4 className="heading">Account Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Name"
                    name="account_name"
                    defaultValue={account.account_name || ""}
                    onChange={handleChange}
                    {...register3("account_name", { required: false })}
                  />
                  {errors3.account_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Owner"
                    name="account_owner"
                    defaultValue={account.account_owner || ""}
                    onChange={handleChange}
                    {...register3("account_owner", { required: false })}
                  />
                  {errors3.account_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Alias"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Alias"
                    name="account_alias"
                    defaultValue={account.account_alias || ""}
                    onChange={handleChange}
                    {...register3("account_alias", { required: false })}
                  />
                  {errors3.account_alias && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Account Number"
                    name="account_number"
                    defaultValue={account.account_number || ""}
                    onChange={handleChange}
                    {...register3("account_number", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors3.account_number && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Website"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Website"
                    name="website"
                    defaultValue={account.website || ""}
                    onChange={handleChange}
                    {...register3("website", { required: false })}
                  />
                  {errors3.website && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account record type"
                    name="account_record_type"
                    defaultValue={account.account_record_type || ""}
                    onChange={handleChange}
                    {...register3("account_record_type", { required: false })}
                  />
                  {errors3.account_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port"
                    name="port"
                    defaultValue={account.port || ""}
                    onChange={handleChange}
                    {...register3("port", { required: false })}
                  />
                  {errors3.port && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Annual revenue"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Annual revenue"
                    name="annual_revenue"
                    defaultValue={account.annual_revenue || ""}
                    onChange={handleChange}
                    {...register3("annual_revenue", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors3.annual_revenue && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    defaultValue={account.origin || ""}
                    onChange={handleChange}
                    {...register3("origin", { required: false })}
                  />
                  {errors3.origin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of employees"
                    name="no_of_employees"
                    defaultValue={account.no_of_employees || ""}
                    onChange={handleChange}
                    {...register3("no_of_employees", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors3.no_of_employees && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Product"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Product"
                    name="product"
                    defaultValue={account.product || ""}
                    onChange={handleChange}
                    {...register3("product", { required: false })}
                  />
                  {errors3.product && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remarks"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Remarks"
                    name="remarks"
                    defaultValue={account.remarks || ""}
                    onChange={handleChange}
                    {...register3("remarks", { required: false })}
                  />
                  {errors3.remarks && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Address Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Region"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Region"
                    name="region"
                    defaultValue={account.region || ""}
                    onChange={handleChange}
                    {...register3("region", { required: false })}
                  />
                  {errors3.region && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Phone"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    name="phone"
                    defaultValue={account.phone || ""}
                    onChange={handleChange}
                    {...register3("phone", { required: false })}
                  />
                  {errors3.phone && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Billing Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Billing Address"
                    style={{ height: "100px" }}
                    name="billing_street"
                    defaultValue={account.billing_street || ""}
                    onChange={handleChange}
                    {...register3("billing_street", { required: false })}
                  />
                  {errors3.billing_street && <p id="text-area">Required </p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Fax"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="fax"
                    name="fax"
                    defaultValue={account.fax || ""}
                    onChange={handleChange}
                    {...register3("fax", { required: false })}
                  />
                  {errors3.fax && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing postal code"
                    name="billing_postal_code"
                    defaultValue={account.billing_postal_code || ""}
                    onChange={handleChange}
                    {...register3("billing_postal_code", { required: false })}
                  />
                  {errors3.billing_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Shipping Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Shipping Address"
                    style={{ height: "100px" }}
                    name="shipping_street"
                    defaultValue={account.billing_postal_code || ""}
                    onChange={handleChange}
                    {...register3("shipping_street", { required: false })}
                  />
                  {errors3.shipping_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    defaultValue={account.billing_city || ""}
                    onChange={handleChange}
                    {...register3("billing_city", { required: false })}
                  />
                  {errors3.billing_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping postal code"
                    name="shipping_postal_code"
                    defaultValue={account.shipping_postal_code || ""}
                    onChange={handleChange}
                    {...register3("shipping_postal_code", { required: false })}
                  />
                  {errors3.shipping_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state province"
                    name="billing_state_or_province"
                    defaultValue={account.billing_state_or_province || ""}
                    onChange={handleChange}
                    {...register3("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors3.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state province"
                    name="shipping_state_or_province"
                    defaultValue={account.shipping_state_or_province || ""}
                    onChange={handleChange}
                    {...register3("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors3.shipping_state_or_province && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    defaultValue={account.shipping_city || ""}
                    onChange={handleChange}
                    {...register3("shipping_city", { required: false })}
                  />
                  {errors3.shipping_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    defaultValue={account.billing_country || ""}
                    onChange={handleChange}
                    {...register3("billing_country", { required: false })}
                  />
                  {errors3.billing_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="shipping_country"
                    defaultValue={account.shipping_country || ""}
                    onChange={handleChange}
                    {...register3("shipping_country", { required: false })}
                  />
                  {errors3.shipping_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Rwood Reference</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="KYC docs"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="KYC docs"
                    name="KYC_docs"
                    defaultValue={account.KYC_docs || ""}
                    onChange={handleChange}
                    {...register3("KYC_docs", { required: false })}
                  />
                  {errors3.KYC_docs && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Commitment"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Commitment"
                    name="commitment"
                    defaultValue={account.commitment || ""}
                    onChange={handleChange}
                    {...register3("commitment", { required: false })}
                  />
                  {errors3.commitment && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="KYC doc date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="KYC doc date"
                    name="KYC_doc_date"
                    defaultValue={account.KYC_doc_date || ""}
                    onChange={handleChange}
                    {...register3("KYC_doc_date", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors3.KYC_doc_date && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Reliability"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Reliability"
                    name="reliability"
                    defaultValue={account.reliability || ""}
                    onChange={handleChange}
                    {...register3("reliability", { required: false })}
                  />
                  {errors3.reliability && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Refrence check"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Refrence check"
                    name="refrence_check"
                    defaultValue={account.refrence_check || ""}
                    onChange={handleChange}
                    {...register3("refrence_check", { required: false })}
                  />
                  {errors3.refrence_check && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Refrence check date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Refrence check date"
                    name="refrence_check_date"
                    defaultValue={account.refrence_check_date || ""}
                    onChange={handleChange}
                    {...register3("refrence_check_date", { required: false })}
                  />
                  {errors3.refrence_check_date && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Industry Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Parent account"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Parent account"
                    name="parent_account"
                    defaultValue={account.parent_account || ""}
                    onChange={handleChange}
                    {...register3("parent_account", { required: false })}
                  />
                  {errors3.parent_account && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="annual coal requirement in  MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="annual coal requirement in  MT"
                    name="annual_coal_requirement_in_MT"
                    defaultValue={account.annual_coal_requirement_in_MT || ""}
                    onChange={handleChange}
                    {...register3("annual_coal_requirement_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors3.annual_coal_requirement_in_MT && (
                    <span>Required</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Under group"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Under group"
                    name="under_group"
                    defaultValue={account.under_group || ""}
                    onChange={handleChange}
                    {...register3("under_group", { required: false })}
                  />
                  {errors3.under_group && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Imported volume PA in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Imported volume PA in MT"
                    name="imported_volume_PA_in_MT"
                    defaultValue={account.imported_volume_PA_in_MT || ""}
                    onChange={handleChange}
                    {...register3("imported_volume_PA_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors3.imported_volume_PA_in_MT && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Station name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Station name"
                    name="station_name"
                    defaultValue={account.station_name || ""}
                    onChange={handleChange}
                    {...register3("station_name", { required: false })}
                  />
                  {errors3.station_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity MT monthly"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Quantity MT monthly"
                    name="quantity_MT_monthly"
                    defaultValue={account.quantity_MT_monthly || ""}
                    onChange={handleChange}
                    {...register3("quantity_MT_monthly", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors3.quantity_MT_monthly && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Expansion setup or buyer status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="expansion_setup_or_buyer_status"
                    placeholder="Expansion setup or buyer status"
                    defaultValue={account.expansion_setup_or_buyer_status || ""}
                    onChange={handleChange}
                    {...register3("expansion_setup_or_buyer_status", {
                      required: false,
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Under Construction">
                      Under Construction
                    </option>
                    <option value="Operated">Operated</option>
                    <option value="Permitted">Permitted</option>
                  </Form.Select>
                  {errors3.expansion_setup_or_buyer_status && (
                    <span>Required Account Name</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Production capacity"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Production capacity"
                    defaultValue={account.production_capacity || ""}
                    onChange={handleChange}
                    name="production_capacity"
                    {...register3("production_capacity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors3.production_capacity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Industry"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="industry"
                    placeholder="Industry"
                    defaultValue={account.industry || ""}
                    onChange={handleChange}
                    {...register3("industry", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Chemicals">Chemicals</option>
                    <option value="Constructions">Constructions</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Energy">Energy</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Environmental">Environmental</option>
                    <option value="Government">Government</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Other">Other</option>
                    <option value="Shipping">Shipping</option>
                  </Form.Select>
                  {errors3.industry && <span>Required Account Name</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Originiaze import breakup"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Originiaze import breakup"
                    name="originiaze_import_breakup"
                    defaultValue={account.originiaze_import_breakup || ""}
                    onChange={handleChange}
                    {...register3("originiaze_import_breakup", {
                      required: false,
                    })}
                  />
                  {errors3.originiaze_import_breakup && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Sector"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="sector"
                    placeholder="Sector"
                    defaultValue={account.sector || ""}
                    onChange={handleChange}
                    {...register3("sector", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Government">Government</option>
                    <option value="Power Sector">Power Sector</option>
                    <option value="Private Sector">Private Sector</option>
                    <option value="Industrial">Industrial</option>
                  </Form.Select>
                  {errors3.sector && <span>Required Account Name</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Market impression rating"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Market impression rating"
                    name="market_impression_rating"
                    defaultValue={account.market_impression_rating || ""}
                    onChange={handleChange}
                    {...register3("market_impression_rating", {
                      required: false,
                    })}
                  />
                  {errors3.market_impression_rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Product Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 1 3800 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 1 3800 GAR"
                    name="coal_spec_1_3800_GAR"
                    defaultValue={account.coal_spec_1_3800_GAR || ""}
                    onChange={handleChange}
                    {...register3("coal_spec_1_3800_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors3.coal_spec_1_3800_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 3 4700 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 3 4700 GAR"
                    name="coal_spec_3_4700_GAR"
                    defaultValue={account.coal_spec_3_4700_GAR || ""}
                    onChange={handleChange}
                    {...register3("coal_spec_3_4700_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors3.coal_spec_3_4700_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 2 4200 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 2 4200 GAR"
                    name="coal_spec_2_4200_GAR"
                    defaultValue={account.coal_spec_2_4200_GAR || ""}
                    onChange={handleChange}
                    {...register3("coal_spec_2_4200_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors3.coal_spec_2_4200_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 4 5000 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 4 5000 GAR"
                    name="coal_spec_4_5000_GAR"
                    defaultValue={account.coal_spec_4_5000_GAR || ""}
                    onChange={handleChange}
                    {...register3("coal_spec_4_5000_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors3.coal_spec_4_5000_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 5 5500 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 5 5500 GAR"
                    name="coal_spec_5_5500_GAR"
                    defaultValue={account.coal_spec_5_5500_GAR || ""}
                    onChange={handleChange}
                    {...register3("coal_spec_5_5500_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors3.coal_spec_5_5500_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 6"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 6"
                    name="coal_spec_6"
                    defaultValue={account.coal_spec_6 || ""}
                    onChange={handleChange}
                    {...register3("coal_spec_6", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors3.coal_spec_6 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 7"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 7"
                    name="coal_spec_7"
                    defaultValue={account.coal_spec_7 || ""}
                    onChange={handleChange}
                    {...register3("coal_spec_7", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors3.coal_spec_7 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 8"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 8"
                    name="coal_spec_8"
                    defaultValue={account.coal_spec_8 || ""}
                    onChange={handleChange}
                    {...register3("coal_spec_8", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors3.coal_spec_8 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px" }}
                    name="description"
                    defaultValue={account.description || ""}
                    onChange={handleChange}
                    {...register3("description", { required: false })}
                  />
                  {errors3.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Account Summary</h4>
              {/* <Form.Group as={Col} md="6">
      <FloatingLabel
        controlId="floatingInput"
        label="Account Source"
        className="mb-3"
        
        
      >
        <Form.Control
          type="text"
          placeholder="Account Source"
          name="account_source"
          defaultValue={account.account_source || ""}
                    onChange={handleChange}
          {...register3("account_source", { required: false })}
        />
        {errors3.account_source && <span>Required </span>}
      </FloatingLabel>
    </Form.Group> */}

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rating"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Rating"
                    name="rating"
                    defaultValue={account.rating || ""}
                    onChange={handleChange}
                    {...register3("rating", { required: false })}
                  />
                  {errors3.rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">System Information</h4>

              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="General terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="General terms"
                    style={{ height: "100px" }}
                    name="general_terms"
                    defaultValue={account.general_terms || ""}
                    onChange={handleChange}
                    {...register3("general_terms", { required: false })}
                  />
                  {errors3.general_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <input type="submit" className="addaccount-save" value="Save" />
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
        </div>
        <Footer />
      </>
    );
  } if (account.account_record_type === "Competitor") {
    const onSubmitCompetitor = (data) => {
      const responseData = {
        account_name: data.account_name,
        account_alias: data.account_alias,
        website: data.website,
        account_owner: data.account_owner,
        parent_account: data.parent_account,
        account_number: data.account_number,
        account_record_type: data.account_record_type,
        annual_revenue: data.annual_revenue,
        description: data.description,
        no_of_employees: data.no_of_employees,
        general_terms: data.general_terms,
        region: data.region,
        billing_street: data.billing_street,
        phone: data.phone,
        shipping_street: data.shipping_street,
        billing_city: data.billing_city,
        shipping_postal_code: data.shipping_postal_code,
        shipping_city: data.shipping_city,
        shipping_state_or_province: data.shipping_state_or_province,
        shipping_country: data.shipping_country,
        billing_state_or_province: data.billing_state_or_province,
        billing_country: data.billing_country,
        fax: data.fax,
        KYC_docs: data.KYC_docs,
        KYC_doc_date: data.KYC_doc_date,
        refrence_check_date: data.refrence_check_date,
        refrence_check: data.refrence_check,
        commitment: data.commitment,
        reliability: data.reliability,
        under_group: data.under_group,
        port: data.port,
        origin: data.origin,
        imported_volume_from_indonesia_PA_in_MT:
          data.imported_volume_from_indonesia_PA_in_MT,
        payment_terms: data.payment_terms,
        station_name: data.station_name,
        expansion_setup_or_buyer_status: data.expansion_setup_or_buyer_status,
        industry: data.industry,
        sector: data.sector,
        market_impression_rating: data.market_impression_rating,
        annual_coal_requirement_in_MT: data.annual_coal_requirement_in_MT,
        imported_volume_PA_in_MT: data.imported_volume_PA_in_MT,
        quantity_MT_monthly: data.quantity_MT_monthly,
        production_capacity: data.production_capacity,
        originiaze_import_breakup: data.originiaze_import_breakup,
        account_source: data.account_source,
        rating: data.rating,
        coal_spec_1_3800_GAR: data.coal_spec_1_3800_GAR,
        coal_spec_2_4200_GAR: data.coal_spec_2_4200_GAR,
        coal_spec_3_4700_GAR: data.coal_spec_3_4700_GAR,
        coal_spec_4_5000_GAR: data.coal_spec_4_5000_GAR,
        coal_spec_5_5500_GAR: data.coal_spec_5_5500_GAR,
        coal_spec_6: data.coal_spec_6,
        coal_spec_7: data.coal_spec_7,
        coal_spec_8: data.coal_spec_8,
      };
      console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}update/account/` + id, {
          method: "PUT",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response);
            toast.success("Account Updated Successfully", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              reset();
            }, 300);
          });
      }
    };
    const handleChange = (e) => {
      const { name, value } = e.target;
      setUpdate((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
    return (
      <>
        <Header />
        <div className="contactAccount">
          <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Accounts </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Account/ Edit Competitor
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit4(onSubmitCompetitor)}>
            <Row className="mb-3">
              <h4 className="heading">Account Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Name"
                    name="account_name"
                    defaultValue={account.account_name || ""}
                    onChange={handleChange}
                    {...register4("account_name", { required: false })}
                  />
                  {errors4.account_name && <span>Required Account Name</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Owner"
                    name="account_owner"
                    defaultValue={account.account_owner || ""}
                    onChange={handleChange}
                    {...register4("account_owner", { required: false })}
                  />
                  {errors4.account_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Alias"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Alias"
                    name="account_alias"
                    defaultValue={account.account_alias || ""}
                    onChange={handleChange}
                    {...register4("account_alias", { required: false })}
                  />
                  {errors4.account_alias && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Parent Account"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Parent Account"
                    name="parent_account"
                    defaultValue={account.parent_account || ""}
                    onChange={handleChange}
                    {...register4("parent_account", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.parent_account && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Account Number"
                    name="account_number"
                    defaultValue={account.account_number || ""}
                    onChange={handleChange}
                    {...register4("account_number", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.account_number && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Website"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Website"
                    name="website"
                    defaultValue={account.website || ""}
                    onChange={handleChange}
                    {...register4("website", { required: false })}
                  />
                  {errors4.website && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account record type"
                    defaultValue={account.account_record_type || ""}
                    onChange={handleChange}
                    name="account_record_type"
                    {...register4("account_record_type", { required: false })}
                  />
                  {errors4.account_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Annual revenue"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Annual revenue"
                    name="annual_revenue"
                    defaultValue={account.annual_revenue || ""}
                    onChange={handleChange}
                    {...register4("annual_revenue", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.annual_revenue && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of employees"
                    name="no_of_employees"
                    defaultValue={account.no_of_employees || ""}
                    onChange={handleChange}
                    {...register4("no_of_employees", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.no_of_employees && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Address Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Region"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Region"
                    name="region"
                    defaultValue={account.region || ""}
                    onChange={handleChange}
                    {...register4("region", { required: false })}
                  />
                  {errors4.region && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Shipping Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Shipping Address"
                    style={{ height: "100px" }}
                    name="shipping_street"
                    defaultValue={account.shipping_street || ""}
                    onChange={handleChange}
                    {...register4("shipping_street", { required: false })}
                  />
                  {errors4.shipping_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Billing Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Billing Address"
                    style={{ height: "100px" }}
                    name="billing_street"
                    {...register4("billing_street", { required: false })}
                  />
                  {errors4.billing_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing postal code"
                    name="billing_postal_code"
                    defaultValue={account.billing_postal_code || ""}
                    onChange={handleChange}
                    {...register4("billing_postal_code", { required: false })}
                  />
                  {errors4.billing_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    defaultValue={account.billing_city || ""}
                    onChange={handleChange}
                    {...register4("billing_city", { required: false })}
                  />
                  {errors4.billing_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping postal code"
                    name="shipping_postal_code"
                    defaultValue={account.shipping_postal_code || ""}
                    onChange={handleChange}
                    {...register4("shipping_postal_code", { required: false })}
                  />
                  {errors4.shipping_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state province"
                    name="billing_state_or_province"
                    defaultValue={account.billing_state_or_province || ""}
                    onChange={handleChange}
                    {...register4("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors4.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state province"
                    name="shipping_state_or_province"
                    defaultValue={account.shipping_state_or_province || ""}
                    onChange={handleChange}
                    {...register4("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors4.shipping_state_or_province && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    defaultValue={account.shipping_city || ""}
                    onChange={handleChange}
                    {...register4("shipping_city", { required: false })}
                  />
                  {errors4.shipping_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    defaultValue={account.billing_country || ""}
                    onChange={handleChange}
                    {...register4("billing_country", { required: false })}
                  />
                  {errors4.billing_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="shipping_country"
                    defaultValue={account.shipping_country || ""}
                    onChange={handleChange}
                    {...register4("shipping_country", { required: false })}
                  />
                  {errors4.shipping_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Phone"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    name="phone"
                    defaultValue={account.phone || ""}
                    onChange={handleChange}
                    {...register4("phone", { required: false })}
                  />
                  {errors4.phone && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Fax"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="fax"
                    name="fax"
                    defaultValue={account.fax || ""}
                    onChange={handleChange}
                    {...register4("fax", { required: false })}
                  />
                  {errors4.fax && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Rwood Reference</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="KYC docs"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="KYC docs"
                    name="KYC_docs"
                    defaultValue={account.KYC_docs || ""}
                    onChange={handleChange}
                    {...register4("KYC_docs", { required: false })}
                  />
                  {errors4.KYC_docs && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Commitment"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Commitment"
                    name="commitment"
                    defaultValue={account.commitment || ""}
                    onChange={handleChange}
                    {...register4("commitment", { required: false })}
                  />
                  {errors4.commitment && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="KYC doc date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="KYC doc date"
                    name="KYC_doc_date"
                    defaultValue={account.KYC_doc_date || ""}
                    onChange={handleChange}
                    {...register4("KYC_doc_date", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.KYC_doc_date && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Reliability"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Reliability"
                    name="reliability"
                    defaultValue={account.reliability || ""}
                    onChange={handleChange}
                    {...register4("reliability", { required: false })}
                  />
                  {errors4.reliability && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Refrence check"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Refrence check"
                    name="refrence_check"
                    defaultValue={account.refrence_check || ""}
                    onChange={handleChange}
                    {...register4("refrence_check", { required: false })}
                  />
                  {errors4.refrence_check && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Refrence check date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Refrence check date"
                    name="refrence_check_date"
                    defaultValue={account.refrence_check_date || ""}
                    onChange={handleChange}
                    {...register4("refrence_check_date", { required: false })}
                  />
                  {errors4.refrence_check_date && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Industry Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Under group"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Under group"
                    name="under_group"
                    defaultValue={account.under_group || ""}
                    onChange={handleChange}
                    {...register4("under_group", { required: false })}
                  />
                  {errors4.under_group && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="annual coal requirement in  MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="annual coal requirement in  MT"
                    name="annual_coal_requirement_in_MT"
                    defaultValue={account.annual_coal_requirement_in_MT || ""}
                    onChange={handleChange}
                    {...register4("annual_coal_requirement_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.annual_coal_requirement_in_MT && (
                    <span>Required</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Station name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Station Name"
                    name="station_name"
                    defaultValue={account.station_name || ""}
                    onChange={handleChange}
                    {...register4("station_name", { required: false })}
                  />
                  {errors4.station_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Imported volume PA in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Imported volume PA in MT"
                    name="imported_volume_PA_in_MT"
                    defaultValue={account.imported_volume_PA_in_MT || ""}
                    onChange={handleChange}
                    {...register4("imported_volume_PA_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.imported_volume_PA_in_MT && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Expansion setup or buyer status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="expansion_setup_or_buyer_status"
                    placeholder="Expansion setup or buyer status"
                    defaultValue={account.expansion_setup_or_buyer_status || ""}
                    onChange={handleChange}
                    {...register4("expansion_setup_or_buyer_status", {
                      required: false,
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Under Construction">
                      Under Construction
                    </option>
                    <option value="Operated">Operated</option>
                    <option value="Permitted">Permitted</option>
                  </Form.Select>
                  {errors4.expansion_setup_or_buyer_status && (
                    <span>Required Account Name</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Imported volume from indonesia PA in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Imported volume from indonesia PA in MT"
                    name="imported_volume_from_indonesia_PA_in_MT"
                    defaultValue={
                      account.imported_volume_from_indonesia_PA_in_MT || ""
                    }
                    onChange={handleChange}
                    {...register4("imported_volume_from_indonesia_PA_in_MT", {
                      required: false,
                    })}
                  />
                  {errors4.imported_volume_from_indonesia_PA_in_MT && (
                    <span>Required</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Quantity MT monthly"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Quantity MT monthly"
                    name="quantity_MT_monthly"
                    defaultValue={account.quantity_MT_monthly || ""}
                    onChange={handleChange}
                    {...register4("quantity_MT_monthly", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.quantity_MT_monthly && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Production capacity"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Production capacity"
                    name="production_capacity"
                    defaultValue={account.production_capacity || ""}
                    onChange={handleChange}
                    {...register4("production_capacity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.production_capacity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Industry"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="industry"
                    placeholder="Industry"
                    defaultValue={account.industry || ""}
                    onChange={handleChange}
                    {...register4("industry", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Chemicals">Chemicals</option>
                    <option value="Constructions">Constructions</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Energy">Energy</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Environmental">Environmental</option>
                    <option value="Government">Government</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Other">Other</option>
                    <option value="Shipping">Shipping</option>
                  </Form.Select>
                  {errors4.industry && <span>Required Account Name</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Originiaze import breakup"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Originiaze import breakup"
                    name="originiaze_import_breakup"
                    defaultValue={account.originiaze_import_breakup || ""}
                    onChange={handleChange}
                    {...register4("originiaze_import_breakup", {
                      required: false,
                    })}
                  />
                  {errors4.originiaze_import_breakup && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Sector"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="sector"
                    placeholder="Sector"
                    defaultValue={account.sector || ""}
                    onChange={handleChange}
                    {...register4("sector", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Government">Government</option>
                    <option value="Power Sector">Power Sector</option>
                    <option value="Private Sector">Private Sector</option>
                    <option value="Industrial">Industrial</option>
                  </Form.Select>
                  {errors4.sector && <span>Required Account Name</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Market impression rating"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Market impression rating"
                    name="market_impression_rating"
                    defaultValue={account.market_impression_rating || ""}
                    onChange={handleChange}
                    {...register4("market_impression_rating", {
                      required: false,
                    })}
                  />
                  {errors4.market_impression_rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port"
                    name="port"
                    defaultValue={account.port || ""}
                    onChange={handleChange}
                    {...register4("port", { required: false })}
                  />
                  {errors4.port && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    defaultValue={account.origin || ""}
                    onChange={handleChange}
                    {...register4("origin", { required: false })}
                  />
                  {errors4.origin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Product Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 1 3800 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 1 3800 GAR"
                    name="coal_spec_1_3800_GAR"
                    defaultValue={account.coal_spec_1_3800_GAR || ""}
                    onChange={handleChange}
                    {...register4("coal_spec_1_3800_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.coal_spec_1_3800_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 3 4700 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 3 4700 GAR"
                    name="coal_spec_3_4700_GAR"
                    defaultValue={account.coal_spec_3_4700_GAR || ""}
                    onChange={handleChange}
                    {...register4("coal_spec_3_4700_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.coal_spec_3_4700_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 2 4200 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 2 4200 GAR"
                    name="coal_spec_2_4200_GAR"
                    defaultValue={account.coal_spec_2_4200_GAR || ""}
                    onChange={handleChange}
                    {...register4("coal_spec_2_4200_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.coal_spec_2_4200_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 4 5000 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 4 5000 GAR"
                    name="coal_spec_4_5000_GAR"
                    defaultValue={account.coal_spec_4_5000_GAR || ""}
                    onChange={handleChange}
                    {...register4("coal_spec_4_5000_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.coal_spec_4_5000_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 5 5500 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 5 5500 GAR"
                    name="coal_spec_5_5500_GAR"
                    defaultValue={account.coal_spec_5_5500_GAR || ""}
                    onChange={handleChange}
                    {...register4("coal_spec_5_5500_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.coal_spec_5_5500_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 6"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 6"
                    name="coal_spec_6"
                    defaultValue={account.coal_spec_6 || ""}
                    onChange={handleChange}
                    {...register4("coal_spec_6", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.coal_spec_6 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 7"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 7"
                    name="coal_spec_7"
                    defaultValue={account.coal_spec_7 || ""}
                    onChange={handleChange}
                    {...register4("coal_spec_7", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.coal_spec_7 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 8"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 8"
                    name="coal_spec_8"
                    defaultValue={account.coal_spec_8 || ""}
                    onChange={handleChange}
                    {...register4("coal_spec_8", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors4.coal_spec_8 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Product"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Product"
                    name="product"
                    defaultValue={account.product || ""}
                    onChange={handleChange}
                    {...register4("product", { required: false })}
                  />
                  {errors4.product && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remarks"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Remarks"
                    name="remarks"
                    defaultValue={account.remarks || ""}
                    onChange={handleChange}
                    {...register4("remarks", { required: false })}
                  />
                  {errors4.remarks && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    defaultValue={account.description || ""}
                    onChange={handleChange}
                    style={{ height: "100px" }}
                    name="description"
                    {...register4("description", { required: false })}
                  />
                  {errors4.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Terms and Conditions</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="General terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="General terms"
                    defaultValue={account.general_terms || ""}
                    onChange={handleChange}
                    style={{ height: "100px" }}
                    name="general_terms"
                    {...register4("general_terms", { required: false })}
                  />
                  {errors4.general_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Payment terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Payment terms"
                    style={{ height: "100px" }}
                    name="payment_terms"
                    defaultValue={account.payment_terms || ""}
                    onChange={handleChange}
                    {...register4("payment_terms", { required: false })}
                  />
                  {errors4.payment_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <input type="submit" className="addaccount-save" value="Save" />
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
        </div>
        <Footer />
      </>
    );
  } if (account.account_record_type === "Buyer") {
    const buyer = (data) => {
      const responseData = {
        account_name: data.account_name,
        account_alias: data.account_alias,
        website: data.website,
        account_owner: data.account_owner,
        parent_account: data.parent_account,
        account_number: data.account_number,
        account_record_type: data.account_record_type,
        annual_revenue: data.annual_revenue,
        no_of_employees: data.no_of_employees,
        port: data.port,
        origin: data.origin,
        remarks: data.remarks,
        product: data.product,
        general_terms: data.general_terms,
        region: data.region,
        billing_street: data.billing_street,
        phone: data.phone,
        shipping_street: data.shipping_street,
        billing_city: data.billing_city,
        shipping_postal_code: data.shipping_postal_code,
        shipping_city: data.shipping_city,
        shipping_state_or_province: data.shipping_state_or_province,
        shipping_country: data.shipping_country,
        billing_state_or_province: data.billing_state_or_province,
        billing_country: data.billing_country,
        fax: data.fax,
        description: data.description,
        KYC_docs: data.KYC_docs,
        KYC_doc_date: data.KYC_doc_date,
        refrence_check_date: data.refrence_check_date,
        refrence_check: data.refrence_check,
        commitment: data.commitment,
        reliability: data.reliability,
        under_group: data.under_group,
        station_name: data.station_name,
        expansion_setup_or_buyer_status: data.expansion_setup_or_buyer_status,
        industry: data.industry,
        sector: data.sector,
        market_impression_rating: data.market_impression_rating,
        annual_coal_requirement_in_MT: data.annual_coal_requirement_in_MT,
        imported_volume_PA_in_MT: data.imported_volume_PA_in_MT,
        quantity_MT_monthly: data.quantity_MT_monthly,
        production_capacity: data.production_capacity,
        originiaze_import_breakup: data.originiaze_import_breakup,
        account_source: data.account_source,
        rating: data.rating,
        coal_spec_1_3800_GAR: data.coal_spec_1_3800_GAR,
        coal_spec_2_4200_GAR: data.coal_spec_2_4200_GAR,
        coal_spec_3_4700_GAR: data.coal_spec_3_4700_GAR,
        coal_spec_4_5000_GAR: data.coal_spec_4_5000_GAR,
        coal_spec_5_5500_GAR: data.coal_spec_5_5500_GAR,
        coal_spec_6: data.coal_spec_6,
        coal_spec_7: data.coal_spec_7,
        coal_spec_8: data.coal_spec_8,
      };
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}update/account/` + id, {
          method: "POST",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response);
            toast.success("Account added successfully", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              reset2();
            }, 300);
          });
      }
    };
    const handleChange = (e) => {
      const { name, value } = e.target;
      setUpdate((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
    return (
      <>
        <Header />
        <div className="contactAccount">
          <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Accounts </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Account/ Edit Buyer
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit5(buyer)}>
            <Row className="mb-3">
              <h4 className="heading">Account Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Name"
                    defaultValue={account.account_name || ""}
                    onChange={handleChange}
                    name="account_name"
                    {...register5("account_name", { required: false })}
                  />
                  {errors5.account_name && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Owner"
                    name="account_owner"
                    defaultValue={account.account_owner || ""}
                    onChange={handleChange}
                    {...register5("account_owner", { required: false })}
                  />
                  {errors5.account_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Alias"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Alias"
                    name="account_alias"
                    defaultValue={account.account_alias || ""}
                    onChange={handleChange}
                    {...register5("account_alias", { required: false })}
                  />
                  {errors5.account_alias && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Parent Account"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Parent Account"
                    name="parent_account"
                    defaultValue={account.parent_account || ""}
                    onChange={handleChange}
                    {...register5("parent_account", { required: false })}
                  />
                  {errors5.parent_account && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Website"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Website"
                    name="website"
                    defaultValue={account.website || ""}
                    onChange={handleChange}
                    {...register5("website", { required: false })}
                  />
                  {errors5.website && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account Number"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account Number"
                    name="account_number"
                    defaultValue={account.account_number || ""}
                    onChange={handleChange}
                    {...register5("account_number", { required: false })}
                  />
                  {errors5.account_number && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Account record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Account record type"
                    name="account_record_type"
                    defaultValue={account.account_record_type || ""}
                    onChange={handleChange}
                    {...register5("account_record_type", { required: false })}
                  />
                  {errors5.account_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Annual Revenue"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Annual Revenue"
                    name="annual_revenue"
                    defaultValue={account.annual_revenue || ""}
                    onChange={handleChange}
                    {...register5("annual_revenue", { required: false })}
                  />
                  {errors5.annual_revenue && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of employees"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of employees"
                    name="no_of_employees"
                    defaultValue={account.no_of_employees || ""}
                    onChange={handleChange}
                    {...register5("no_of_employees", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors5.no_of_employees && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row>
              <h4 className="heading">Address Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Region"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Region"
                    name="region"
                    defaultValue={account.region || ""}
                    onChange={handleChange}
                    {...register5("region", { required: false })}
                  />
                  {errors5.region && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Shipping Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Shipping Address"
                    style={{ height: "100px" }}
                    name="shipping_street"
                    defaultValue={account.shipping_street || ""}
                    onChange={handleChange}
                    {...register5("shipping_street", { required: false })}
                  />
                  {errors5.shipping_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Billing Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Billing Address"
                    style={{ height: "100px" }}
                    name="billing_street"
                    defaultValue={account.billing_street || ""}
                    onChange={handleChange}
                    {...register5("billing_street", { required: false })}
                  />
                  {errors5.billing_street && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing postal code"
                    name="billing_postal_code"
                    defaultValue={account.billing_postal_code || ""}
                    onChange={handleChange}
                    {...register5("billing_postal_code", { required: false })}
                  />
                  {errors5.billing_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping postal code"
                    name="shipping_postal_code"
                    defaultValue={account.shipping_postal_code || ""}
                    onChange={handleChange}
                    {...register5("shipping_postal_code", { required: false })}
                  />
                  {errors5.shipping_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    defaultValue={account.billing_city || ""}
                    onChange={handleChange}
                    {...register5("billing_city", { required: false })}
                  />
                  {errors5.billing_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    defaultValue={account.shipping_city || ""}
                    onChange={handleChange}
                    {...register5("shipping_city", { required: false })}
                  />
                  {errors5.shipping_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state province"
                    name="billing_state_or_province"
                    defaultValue={account.billing_state_or_province || ""}
                    onChange={handleChange}
                    {...register5("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors5.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state province"
                    name="shipping_state_or_province"
                    defaultValue={account.shipping_state_or_province || ""}
                    onChange={handleChange}
                    {...register5("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors5.shipping_state_or_province && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    defaultValue={account.billing_country || ""}
                    onChange={handleChange}
                    {...register5("billing_country", { required: false })}
                  />
                  {errors5.billing_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="shipping_country"
                    defaultValue={account.shipping_country || ""}
                    onChange={handleChange}
                    {...register5("shipping_country", { required: false })}
                  />
                  {errors5.shipping_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Phone"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Phone"
                    name="phone"
                    defaultValue={account.phone || ""}
                    onChange={handleChange}
                    {...register5("phone", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors5.phone && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Fax"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="fax"
                    name="fax"
                    defaultValue={account.fax || ""}
                    onChange={handleChange}
                    {...register5("fax", { required: false })}
                  />
                  {errors5.fax && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Rwood Reference</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="KYC docs"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="KYC docs"
                    name="KYC_docs"
                    defaultValue={account.KYC_docs || ""}
                    onChange={handleChange}
                    {...register5("KYC_docs", { required: false })}
                  />
                  {errors5.KYC_docs && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Commitment"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="commitment"
                    placeholder="Commitment"
                    defaultValue={account.commitment || ""}
                    onChange={handleChange}
                    {...register5("commitment", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Minimum Term">Minimum Term</option>
                    <option value="Short Term">Short Term</option>
                    <option value="Long Term">Long Term</option>
                  </Form.Select>
                  {errors5.commitment && <span>Required Account Name</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="KYC doc date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="KYC doc date"
                    name="KYC_doc_date"
                    defaultValue={account.KYC_doc_date || ""}
                    onChange={handleChange}
                    {...register5("KYC_doc_date", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors5.KYC_doc_date && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Reliability"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Reliability"
                    name="reliability"
                    defaultValue={account.reliability || ""}
                    onChange={handleChange}
                    {...register5("reliability", { required: false })}
                  />
                  {errors5.reliability && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Refrence check"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Refrence check"
                    name="refrence_check"
                    defaultValue={account.refrence_check || ""}
                    onChange={handleChange}
                    {...register5("refrence_check", { required: false })}
                  />
                  {errors5.refrence_check && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Refrence check date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Refrence check date"
                    name="refrence_check_date"
                    defaultValue={account.refrence_check_date || ""}
                    onChange={handleChange}
                    {...register5("refrence_check_date", { required: false })}
                  />
                  {errors5.refrence_check_date && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">Industry Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Under group"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Under group"
                    name="under_group"
                    defaultValue={account.under_group || ""}
                    onChange={handleChange}
                    {...register5("under_group", { required: false })}
                  />
                  {errors5.under_group && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="annual coal requirement in  MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="annual coal requirement in  MT"
                    name="annual_coal_requirement_in_MT"
                    defaultValue={account.annual_coal_requirement_in_MT || ""}
                    onChange={handleChange}
                    {...register5("annual_coal_requirement_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors5.annual_coal_requirement_in_MT && (
                    <span>Required</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Station name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Station name"
                    name="station_name"
                    defaultValue={account.station_name || ""}
                    onChange={handleChange}
                    {...register5("station_name", { required: false })}
                  />
                  {errors5.station_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Imported volume PA in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Imported volume PA in MT"
                    name="imported_volume_PA_in_MT"
                    defaultValue={account.imported_volume_PA_in_MT || ""}
                    onChange={handleChange}
                    {...register5("imported_volume_PA_in_MT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors5.imported_volume_PA_in_MT && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Expansion setup or buyer status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="expansion_setup_or_buyer_status"
                    defaultValue={account.expansion_setup_or_buyer_status || ""}
                    onChange={handleChange}
                    placeholder="Expansion setup or buyer status"
                    {...register("expansion_setup_or_buyer_status", {
                      required: false,
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Under Construction">
                      Under Construction
                    </option>
                    <option value="Operated">Operated</option>
                    <option value="Permitted">Permitted</option>
                  </Form.Select>
                  {errors.expansion_setup_or_buyer_status && (
                    <span>Required Account Name</span>
                  )}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Imported volume from indonesia PA in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Imported volume from indonesia PA in MT"
                    name="imported_volume_from_indonesia_PA_in_MT"
                    defaultValue={
                      account.imported_volume_from_indonesia_PA_in_MT || ""
                    }
                    onChange={handleChange}
                    {...register5("imported_volume_from_indonesia_PA_in_MT", {
                      required: false,
                    })}
                  />
                  {errors5.imported_volume_from_indonesia_PA_in_MT && (
                    <span>Required</span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Industry"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="industry"
                    placeholder="Industry"
                    defaultValue={account.industry || ""}
                    onChange={handleChange}
                    {...register5("industry", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Chemicals">Chemicals</option>
                    <option value="Constructions">Constructions</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Energy">Energy</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Environmental">Environmental</option>
                    <option value="Government">Government</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Other">Other</option>
                    <option value="Shipping">Shipping</option>
                  </Form.Select>
                  {errors5.industry && <span>Required Account Name</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Production capacity"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Production capacity"
                    name="production_capacity"
                    defaultValue={account.production_capacity || ""}
                    onChange={handleChange}
                    {...register5("production_capacity", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors5.production_capacity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Sector"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="sector"
                    placeholder="Sector"
                    defaultValue={account.sector || ""}
                    onChange={handleChange}
                    {...register5("sector", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="Government">Government</option>
                    <option value="Power Sector">Power Sector</option>
                    <option value="Private Sector">Private Sector</option>
                    <option value="Industrial">Industrial</option>
                  </Form.Select>
                  {errors5.sector && <span>Required Account Name</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Originiaze import breakup"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Originiaze import breakup"
                    name="originiaze_import_breakup"
                    defaultValue={account.originiaze_import_breakup || ""}
                    onChange={handleChange}
                    {...register5("originiaze_import_breakup", {
                      required: false,
                    })}
                  />
                  {errors5.originiaze_import_breakup && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port"
                    name="port"
                    defaultValue={account.port || ""}
                    onChange={handleChange}
                    {...register5("port", { required: false })}
                  />
                  {errors5.port && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    defaultValue={account.origin || ""}
                    onChange={handleChange}
                    {...register5("origin", { required: false })}
                  />
                  {errors5.origin && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Market impression rating"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Market impression rating"
                    name="market_impression_rating"
                    defaultValue={account.market_impression_rating || ""}
                    onChange={handleChange}
                    {...register5("market_impression_rating", {
                      required: false,
                    })}
                  />
                  {errors5.market_impression_rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Product Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 1 3800 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 1 3800 GAR"
                    name="coal_spec_1_3800_GAR"
                    defaultValue={account.coal_spec_1_3800_GAR || ""}
                    onChange={handleChange}
                    {...register5("coal_spec_1_3800_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors5.coal_spec_1_3800_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 2 4200 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 2 4200 GAR"
                    name="coal_spec_2_4200_GAR"
                    defaultValue={account.coal_spec_2_4200_GAR || ""}
                    onChange={handleChange}
                    {...register("coal_spec_2_4200_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.coal_spec_2_4200_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Product"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Product"
                    name="product"
                    defaultValue={account.product || ""}
                    onChange={handleChange}
                    {...register5("product", { required: false })}
                  />
                  {errors5.product && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 2 4200 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 2 4200 GAR"
                    name="coal_spec_2_4200_GAR"
                    defaultValue={account.coal_spec_2_4200_GAR || ""}
                    onChange={handleChange}
                    {...register5("coal_spec_2_4200_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors5.coal_spec_2_4200_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px!important" }}
                    name="description"
                    defaultValue={account.description || ""}
                    onChange={handleChange}
                    {...register5("description", { required: false })}
                  />
                  {errors5.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 3 4700 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 3 4700 GAR"
                    name="coal_spec_3_4700_GAR"
                    defaultValue={account.coal_spec_3_4700_GAR || ""}
                    onChange={handleChange}
                    {...register5("coal_spec_3_4700_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors5.coal_spec_3_4700_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remarks"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Remarks"
                    name="remarks"
                    defaultValue={account.remarks || ""}
                    onChange={handleChange}
                    {...register5("remarks", { required: false })}
                  />
                  {errors5.remarks && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 4 5000 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 4 5000 GAR"
                    name="coal_spec_4_5000_GAR"
                    defaultValue={account.coal_spec_4_5000_GAR || ""}
                    onChange={handleChange}
                    {...register5("coal_spec_4_5000_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors5.coal_spec_4_5000_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 5 5500 GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 5 5500 GAR"
                    name="coal_spec_5_5500_GAR"
                    defaultValue={account.coal_spec_5_5500_GAR || ""}
                    onChange={handleChange}
                    {...register5("coal_spec_5_5500_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors5.coal_spec_5_5500_GAR && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 6"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 6"
                    name="coal_spec_6"
                    defaultValue={account.coal_spec_6 || ""}
                    onChange={handleChange}
                    {...register5("coal_spec_6", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors5.coal_spec_6 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 7"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 7"
                    name="coal_spec_7"
                    defaultValue={account.coal_spec_7 || ""}
                    onChange={handleChange}
                    {...register5("coal_spec_7", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors5.coal_spec_7 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Coal specification 8"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Coal specification 8"
                    name="coal_spec_8"
                    defaultValue={account.coal_spec_8 || ""}
                    onChange={handleChange}
                    {...register5("coal_spec_8", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors5.coal_spec_8 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row>
              <h4 className="heading">Terms & Conditions</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="General terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="General terms"
                    style={{ height: "100px" }}
                    name="general_terms"
                    defaultValue={account.general_terms || ""}
                    onChange={handleChange}
                    {...register5("general_terms", { required: false })}
                  />
                  {errors5.general_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Payment terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Payment terms"
                    style={{ height: "100px" }}
                    name="payment_terms"
                    defaultValue={account.payment_terms || ""}
                    onChange={handleChange}
                    {...register5("payment_terms", { required: false })}
                  />
                  {errors5.payment_terms && <p id="text-area">Required </p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rating"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Rating"
                    name="rating"
                    defaultValue={account.rating || ""}
                    onChange={handleChange}
                    {...register5("rating", { required: false })}
                  />
                  {errors5.rating && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <input type="submit" className="addaccount-save" value="Save" />
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
        </div> 
        <Footer />
      </>
    );
  }
}

export default UpdateAccount;
