import React, { Component, useState, useEffect, useContext, forwardRef } from "react";
import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import $ from "jquery";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Popup from "reactjs-popup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { parse, format , isValid } from 'date-fns';
import Col from "react-bootstrap/Col";
import { API_URL } from '../config';
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
const PriceType = () => {
  
  const [data, setData] = useState({}); 
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


 const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);
     
  };

  

  const navigate = useNavigate();
  const [formData, setFromdata] = useState({
    account_record: "",
  });

  const handleChange = (e) => {
    setFromdata({ ...formData, [e.target.name]: e.target.value });
  };

 
  const params = useParams();
  const id = params.id;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
  const [lenthData, setlenthData] = useState([]);
  const [historys, setHistory] = useState([]);
  const [opp, setOpp] = useState([]);
  const [bdate, setbirthDate]=useState('')
  const [accountNamedrop, setAccountnameDropdown]=useState('')

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken], lenthData);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/price_itembyID/" + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      
      const data = response.data.price_item_details[0];
      console.log(response.data.price_item_details.length)
      setlenthData(response.data.price_item_details.length)
      setDatas(data);
      $("#tcf").dataTable();
          $("#tcf-1").dataTable();
          $("#tcf-2").dataTable();
          $("#tcf-3").dataTable();
          $("#tcf-4").dataTable(); 
          $("#tcf-5").dataTable();
          $("#tcf-6").dataTable();
          $("#tcf-7").dataTable();
          $("#tcf-8").dataTable();
      
    } catch (error) {
      console.error(error);
    }
  }; 

  
 
  const handleSaveEdit = async (date) => {
    
     
    const responseData={
      "saluation":datas.saluation,
      "first_name":datas.first_name,
      "last_name":datas.last_name, 
      "birthdate":bdate?bdate:null,
      "title":datas.title,
      "account_name":accountNamedrop.value,
      "reports_to":datas.reports_to,
      "contact_email":datas.contact_email,
      "contact_mobile":datas.contact_mobile,
      "contact_owner":datas.contact_owner,
      "department":datas.department,
      "lead_source":datas.lead_source,
      "mail_street":datas.mail_street,
      "mail_postal_code":datas.mail_postal_code,
      "mail_city":datas.mail_city,
      "mail_state":datas.mail_state,
      "mail_country":datas.mail_country
      
    };
    console.log(JSON.stringify(responseData))
    
    fetch(`${API_URL}contact/update/${id}`, {  
      method: 'PUT',
      body: JSON.stringify(responseData),
      headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
         
    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log(updatedData) 
        fetchData();
        if(updatedData.msg==='contact updated successfully'){
         
          // fetchData();
      }
       
        setData(updatedData);
        setIsEditMode(false);
      })
      .catch((error) => console.error(error));
    }

  return (
    <div id="view-page">
      <Header />
      <>
            {/* {lenthData.length > 0 ? ( */}
      <div className="contactAccount">
        <div className="page-header btn-margin" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">
              Price Type Details
            </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Price Type / Price Type details
              </li>
            </ol>
          </div>
        </div>
        {isEditMode ? (
          
         <div className="tasks">
          
            <>
          
            <p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
     
     
     </p>
     <Row className="mb-3">

      <div className='col-md-12'>
      <h4 className='heading'>Contact Information</h4>
        </div>
         
      
    <Form.Group
              as={Col}
              md="6"
             
            >
    <FloatingLabel
            controlId="floatingInput"
            label="price type name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="price type name"
              name="price_type_name"
              defaultValue={datas.price_type_name || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        
            </Form.Group>
     
        
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="price description"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="price description" 
            name="price_description"
            defaultValue={datas.price_description || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>
        


            <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="price sr no"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="price sr no" 
            name="price_sr_no"
             defaultValue={datas.price_sr_no || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>
         

      
     </Row>
     <p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
     
     
     </p> </>
           
         </div>
        ):(
          <>


         
       
         <div className="tasks">
         <div className="edit-delete">
                  <span id="edit" onClick={handleEditClick}>
                    Edit
                  </span>
                  <span>
                  <Link to={'/price/price-list'}> Cancel</Link>
                  </span>
                  
                  </div>
           
             <>
             <div className="">
               <div id="contracts">
                 <div className="">
                   <div className="row">
                     <div className="col-md-12" id="head">
                       <h4 className="heading">Information</h4>
                       <hr></hr>
                     </div>
                   </div>
                   <table class="table table-bordered account-table tables">
                     <tbody>
                        <tr>
                         <td id="td-right">
                           <span>price type name</span>
                         </td>
                         <td>{datas.price_type_name}</td>
                         <td id="td-right">
                          Owner
                         </td>
                         <td>{userRole}</td>
                       </tr>

                       <tr>
                         <td id="td-right">
                           <span>price description</span>
                         </td>
                         <td>{datas.price_description}</td>
                         <td id="td-right">
                         </td>
                         <td></td>
                       </tr>
                       

                        <tr>
                         <td id="td-right">
                           <span>Price sr no</span>
                         </td>
                         <td>{datas.price_sr_no}</td>
                         <td id="td-right">
                         </td>
                         <td></td>
                       </tr>

                       <tr>
                         <td id="td-right">
                           <span>Created By	
                           </span>
                         </td>
                         <td>{datas.created_by}</td>
                         <td id="td-right">Last Modified By	

                         </td>
                         <td>{datas.modified_by}</td>
                       </tr>
                       
                      </tbody>
                   </table>

                 </div>
               <div className="edit-delete">
                  <span id="edit" onClick={handleEditClick}>
                    Edit
                  </span>
                  <span>
                  <Link to={'/price/price-list'}> Cancel</Link>
                  </span>
                  
                  </div>
               </div>
             </div>
             <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Trade Confirmation Forms (Projected Purchase Price Type)

                    </span></h4>
                    <hr></hr>
                  </div>
                  <table id="tcf" className="">
                    <thead>
                      <tr>
                      <th>TCF No	</th> 
                      <th>Buyer	</th> 
                      <th>Supplier</th> 
                      <th>Quantity (MT)		</th> 
                      <th>Contractual GAR (P)		</th> 
                      <th>Actual GAR (P)		</th> 
                      <th>Actual GAR (S)
                      </th>
                      <th>TCF Status
                      </th> 
                       
                      </tr>
                    </thead>
 
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                  </table>
                </Row>
           
                <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Trade Confirmation Forms (Purchase Price Type)

                    </span></h4>
                    <hr></hr>
                  </div>
                  <table id="tcf-1" className="">
                    <thead>
                      <tr>
                      <th>TCF No	</th> 
                      <th>Buyer	</th> 
                      <th>Supplier</th> 
                      <th>Quantity (MT)		</th> 
                      <th>Contractual GAR (P)		</th> 
                      <th>Actual GAR (P)		</th> 
                      <th>Actual GAR (S)
                      </th>
                      <th>TCF Status
                      </th> 
                       
                      </tr>
                    </thead>
 
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                  </table>
                </Row>
                <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Trade Confirmation Forms (Purchase Price Type)

                    </span></h4>
                    <hr></hr>
                  </div>
                  <table id="tcf-2" className="">
                    <thead>
                      <tr>
                      <th>TCF No	</th> 
                      <th>Buyer	</th> 
                      <th>Supplier</th> 
                      <th>Quantity (MT)		</th> 
                      <th>Contractual GAR (P)		</th> 
                      <th>Actual GAR (P)		</th> 
                      <th>Actual GAR (S)
                      </th>
                      
                       
                      </tr>
                    </thead>
 
                      <tbody>
                        <tr>
                           
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                  </table>
                </Row>
                
                <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Trade Confirmation Forms (Sale Price Type)

                    </span></h4>
                    <hr></hr>
                  </div>
                  <table id="tcf-3" className="">
                    <thead>
                      <tr>
                      <th>TCF No	</th> 
                      <th>Buyer	</th> 
                      <th>Supplier</th> 
                      <th>Quantity (MT)		</th> 
                      <th>Contractual GAR (P)		</th> 
                      <th>Actual GAR (P)		</th> 
                      <th>Actual GAR (S)
                      </th>
                      <th>TCF Status
                      </th> 
                       
                      </tr>
                    </thead>
 
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                  </table>
                </Row>
                <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Contract Shipments

                    </span></h4>
                    <hr></hr>
                  </div>
                  <table id="tcf-4" className="">
                    <thead>
                      <tr>
                      <th>Contract Shipments No	</th> 
                      <th>Contract	</th> 
                      <th>Laycan</th> 
                      <th>No of Shipments	</th> 
                      <th>No of Orders	</th> 
                      <th>Pricing</th> 
                      <th>Quantity
                      </th>
                        
                       
                      </tr>
                    </thead>
 
                      <tbody>
                        <tr> 
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                  </table>
                </Row>
                
                <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Opp Shipments


                    </span></h4>
                    <hr></hr>
                  </div>
                  <table id="tcf-5" className="">
                    <thead>
                      <tr>
                      <th>Opp Shipment Name
                      </th> 
                       
                        
                       
                      </tr>
                    </thead>
 
                      <tbody>
                        <tr> 
                          
                          <td>No data available</td>
                           
                        </tr>
                      </tbody>
                  </table>
                </Row>
                <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Quote Shipments



                    </span></h4>
                    <hr></hr>
                  </div>
                  <table id="tcf-6" className="">
                    <thead>
                      <tr>
                      <th>Quote Shipment Name
                      </th> 
                       
                        
                       
                      </tr>
                    </thead>
 
                      <tbody>
                        <tr> 
                          
                          <td>No data available</td>
                           
                        </tr>
                      </tbody>
                  </table>
                </Row>
            <div className="">
               <div id="contracts">
                
 
                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Product Masters</span>
                              <span>
                                <i className=""></i>
                                New Product Master
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>

                        <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Quote Shipments



                    </span></h4>
                    <hr></hr>
                  </div>
                  <table id="tcf-7" className="">
                    <thead>
                      <tr>
                      <th>Product Name	 </th> 
                      <th>Product Code	 </th> 
                      <th>Product No	 </th> 
                      <th>Quantity (MT)	 </th> 
                      <th>Status </th> 
                      <th>Buyer Name
                      </th> 
                      </tr>
                    </thead>
 
                      <tbody>
                        <tr> 
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                  </table>
                </Row>
                        <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Legacy Data
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Legacy Data

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Legacy Data (Sales Price Type)

                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Legacy Data

                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                         
               </div>
             </div>
           </>
          
         </div>
       
       </>
        )
}
      </div>
      {/* ):(
        <div className="tasks">
        <h1 className="no-data-detail"><img src='../../images/loading.gif' className="loading"/></h1>
        </div>
      )} */}
</>

      <Footer />
    </div>
  );
};

export default PriceType;
