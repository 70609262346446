import React, { Component, useState, useEffect, useContext, forwardRef } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap"; 
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { parse, format } from 'date-fns';
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import RecentItem from "../recentitem/rececentItem";
import Header from '../Header'  
import { API_URL } from '../config';
import Footer from '../Footer' 
import { FaEnvelope,FaPhoneSquareAlt,FaMobileAlt,FaFax } from "react-icons/fa";
import axios from "axios";
import $ from 'jquery';
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams  } from "react-router-dom"; 
import DatePicker from "react-datepicker";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
const OpportunityShipmentDetail = () => {
  
  const params = useParams()
  const id=params.id  
  const userRole = localStorage.getItem("role");
  
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState({});
  const[shipments,setShipments]=useState([]);
  const [users, setUsers]=useState({})
  const [getPrice, setgetPrice] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [endDateError, setEndDateError] = useState('');
  
  const [data, setData] = useState({}); 
  const [startDate1, setStartDate1] = useState(new Date());

  //  const birthDate=startDate1.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
   useEffect(() => {
    fetchDataPrice();
  }, [decryptedAccessToken]);

  const fetchDataPrice = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/price_items",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.priceitem;
      // console.log(data)
      setgetPrice(data);
    } catch (error) {
      console.error(error);
    }
  };
 
  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      name="birthdate"
      onChange={onChange}
      ref={ref}
    ></input>
  )); 
  const [error, setError] = useState(null);
  const [startDate4, setStartDate4] = useState(new Date());
  const datepick4 =
    startDate4.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput4 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  
  const datepick1 =
    startDate1.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
 
  const [history, setHistory]=useState([])
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  useEffect(() => {
   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/opp_shipmentsbyID/'+id, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      if(response.status===200){
      const data = response.data;
      console.log(data) 
      setDatas(data.opp_shipments_details);   
      if (data.opp_shipments_details.laycan_from_date !== null || data.opp_shipments_details.laycan_from_date===""){
      const startDate=parse(data.opp_shipments_details.laycan_from_date, 'dd/MM/yyyy', new Date()); 
      setStartDate(startDate);   
      }   
      else{
        setStartDate(new Date()); 
      }
      if(data.opp_shipments_details.laycan_to_date!==null || data.opp_shipments_details.laycan_to_date===""){
      const endDate=parse(data.opp_shipments_details.laycan_to_date, 'dd/MM/yyyy', new Date());      
      setEndDate(endDate); 
      }
      else{
        setEndDate(new Date());
      }


      setShipments(data.quote_shipments)    
      setUsers(data.user_data)
      setHistory(data.history)
      
      setTimeout(()=>{
          $('#shipment').DataTable(); 
      $('#user').DataTable(); 
      $("#historys").dataTable();
        },1000)}
        else{
          toast.error("No Data Available", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",})
        }
    } catch (error) {
      // console.error(error);
    }
  };
  if (!datas) {
    return null;
  } 




  
  const options_1 = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", options_1).format(number);
  };
  
  const renderShipmentQty = (tcfValues) => {
    if (tcfValues > 0) {
      return <span className='sr'>{formatNumber(tcfValues)}</span>;
    } else if (tcfValues < 0) {
      return <span className='sr'>({formatNumber(Math.abs(tcfValues))})</span>;
    } else {
      return <span className='sr'>{formatNumber(tcfValues)}</span>;
    }
  };
  



  
  // edit


  
  const handleStartDateChange = (date) => {
    setStartDate(date);
    
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);

    // if (!startDate) {
    //   setEndDateError('Please select a Laycan From Date	.');
    // } else {
    //   const diffInTime = date.getTime() - startDate.getTime();
    //   const diffInDays = diffInTime / (1000 * 3600 * 24);
    //   if (diffInDays !== 10) {
    //     setEndDateError('Laycan to Date must be exactly 10 days after the Laycan From Date ');
    //   } else {
    //     setEndDateError('');
    //   }
    // }
  };
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  
  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);
  };
 

  const handleSaveEdit = (e) => {
    e.preventDefault(); 
    if(datas.record_type==='Buyer - Long Term') {
      
    setIsSubmitted(true);
    const responseData={  
      "exchange_rate":datas.exchange_rate,
      "status":datas.status,
      "opportunity":datas.opportunity,
      "opportunity_shipment":datas.opportunity_shipment, 
      "record_type":datas.record_type,
      "price_type":datas.price_type,
      "price_basis":datas.price_basis,
      "price":datas.price,
      "sales_price_margin":datas.sales_price_margin,
      "shipping_term":datas.shipping_term,
      "laycan_year":datas.laycan_year,
      "laycan_year_to":datas.laycan_year_to,       
      "laycan_month_to":datas.laycan_month_to, 
      // "laycan_month_to_date":datas.CustomInput4datepick4,
      "quantity":datas.quantity,
      "shipment_quality_tolerance":datas.shipment_quality_tolerance,
      "vessel_type":datas.vessel_type,
      "remark":datas.remark, 
      "currency":datas.currency, 
      "laycan_year_from":datas.laycan_year_from,
      "laycan_month":datas.laycan_month, 
      "currency":datas.currency,
      "no_of_shipments":datas.no_of_shipments,
      "quantity_type":datas.quantity_type,
      "quantity_to":datas.quantity_to,
      "quantity_from":datas.quantity_from,
      "remark":datas.remark
             };
             console.log(JSON.stringify(responseData))
             axios.put(`${API_URL}update/opp_shipment/${id}`, responseData, {
              headers: {
                Authorization: `Bearer ${decryptedAccessToken}`,
                'Content-Type': 'application/json',
              },
            })
              .then((response) => {
               console.log(response)
      
               if (response.data.success === true) {
                
                setIsSubmitted(false);
                document.getElementById('messageContainer').innerHTML = `
                    <div class="success-message">
                        ${response.data.message}
                    </div>
                `;
                
                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
                
                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
                fetchData();
                setTimeout(() => {
                    $("#historys").dataTable();
                    $('#shipment').DataTable();
                    $('#user').DataTable();
                }, 500);
                setData(response);
                setIsEditMode(false);
            } else if (response.data.success === false) { setIsSubmitted(false);
                document.getElementById('messageContainer').innerHTML = `
                    <div class="error-message">
                    ${response.data.msg}
                    </div>
                `;
                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
            }
            
      })
      .catch((error) => console.error(error));
    }
    if(datas.record_type==='Supplier - Long Term') {
      
    setIsSubmitted(true);
      const responseData={  
        "exchange_rate":datas.exchange_rate,
        "status":datas.status,
        "opportunity":datas.opportunity,
        "opportunity_shipment":datas.opportunity_shipment, 
        "record_type":datas.record_type,
        "price_type":datas.price_type,
        "price_basis":datas.price_basis,
        "price":datas.price,
        "sales_price_margin":datas.sales_price_margin,
        "shipping_term":datas.shipping_term,
        "laycan_year":datas.laycan_year,
        "laycan_year_to":datas.laycan_year_to,      
        "laycan_from_date":startDate?format(startDate, 'dd/MM/yyyy'):null, 
        "laycan_to_date":endDate?format(endDate, 'dd/MM/yyyy'):null, 
        "laycan_month_to":datas.laycan_month_to,
        "laycan_year_from":datas.laycan_year_from,
        // "laycan_month_to_date":datas.CustomInput4datepick4,
        "quantity":datas.quantity,
        "shipment_quality_tolerance":datas.shipment_quality_tolerance,
        "vessel_type":datas.vessel_type,
        "remark":datas.remark, 
        "currency":datas.currency,
        "laycan_month":datas.laycan_month,  
        "no_of_shipments":datas.no_of_shipments,
        "quantity_type":datas.quantity_type,
        "quantity_to":datas.quantity_to,
        "quantity_from":datas.quantity_from,
        "remark":datas.remark
               };
               console.log(JSON.stringify(responseData))
               axios.put(`${API_URL}update/opp_shipment/${id}`, responseData, {
                headers: {
                  Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json',
                },
              })
                .then((response) => {
                 
        console.log(response)
        if (response.data.success === true) {
          
    setIsSubmitted(false);
          document.getElementById('messageContainer').innerHTML = `
              <div class="success-message">
                  ${response.data.message}
              </div>
          `;
          
          window.scrollTo({
            top: 0,
            behavior: "smooth" // Optional: animated scrolling
        });
          fetchData();
          setTimeout(() => {
              $("#historys").dataTable();
              $('#shipment').DataTable();
              $('#user').DataTable();
          }, 500);
          setData(response);
          setIsEditMode(false);
      } else if (response.data.success === false) { setIsSubmitted(false);

          document.getElementById('messageContainer').innerHTML = `
              <div class="error-message">
              ${response.data.msg}
              </div>
          `;
          window.scrollTo({
            top: 0,
            behavior: "smooth" // Optional: animated scrolling
        });
      }
      
        })
        .catch((error) => console.error(error));
      }


    if(datas.record_type==='Buyer - Spot' ) {
      
       
      
    setIsSubmitted(true);
      const responseData={  
        "exchange_rate":datas.exchange_rate,
        "status":datas.status,
        "opportunity":datas.opportunity,
        "opportunity_shipment":datas.opportunity_shipment, 
        "record_type":datas.record_type,
        "price_type":datas.price_type,
        "price_basis":datas.price_basis,
        "price":datas.price,
        "currency":datas.currency,
        "exchange_rate":datas.exchange_rate,
        "sales_price_margin":datas.sales_price_margin,
        "shipping_term":datas.shipping_term,
        "laycan_year":datas.laycan_year,
        // "laycan_year_to":datas.laycan_year_to,      
        "laycan_month":datas.laycan_month,
        // "laycan_month_to":datas.laycan_month_to,
        // "laycan_year_from":datas.laycan_year_from,
        // "laycan_month_to_date":datas.CustomInput4datepick4,
        "quantity":datas.quantity,
        "shipment_quality_tolerance":datas.shipment_quality_tolerance,
        "vessel_type":datas.vessel_type,
        "remark":datas.remark,  
        "laycan_from_date":startDate?format(startDate, 'dd/MM/yyyy'):null, 
        "laycan_to_date":endDate?format(endDate, 'dd/MM/yyyy'):null, 
        // "laycan_month":datas.laycan_month,  
        "no_of_shipments":datas.no_of_shipments,
        "quantity_type":datas.quantity_type,
        "remark":datas.remark
               };
               console.log(JSON.stringify(responseData))
               axios.put(`${API_URL}update/opp_shipment/${id}`, responseData, {
                headers: {
                  Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json',
                },
              })
                .then((response) => {
                 console.log(response)
                 if (response.data.success === true) {
                  
    setIsSubmitted(false);
                  document.getElementById('messageContainer').innerHTML = `
                      <div class="success-message">
                          ${response.data.msg}
                      </div>
                  `;
                  
                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
                  fetchData();
                  setTimeout(() => {
                      $("#historys").dataTable();
                      $('#shipment').DataTable();
                      $('#user').DataTable();
                  }, 500);
                  setData(response);
                  setIsEditMode(false);
              } else if (response.data.success === false) { setIsSubmitted(false);
                
                  document.getElementById('messageContainer').innerHTML = `
                      <div class="error-message">
                      ${response.data.msg}
                      </div>
                  `;
                  
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth" // Optional: animated scrolling
                });
              }
              
        })
        .catch((error) => console.error(error));
      
    }

    if(datas.record_type==='Supplier - Spot') {
      
       
    setIsSubmitted(true);
      
      const responseData={  
        "exchange_rate":datas.exchange_rate,
        "status":datas.status,
        "opportunity":datas.opportunity,
        "opportunity_shipment":datas.opportunity_shipment, 
        "record_type":datas.record_type,
        "price_type":datas.price_type,
        "price_basis":datas.price_basis,
        "price":datas.price,
        "sales_price_margin":datas.sales_price_margin,
        "shipping_term":datas.shipping_term,       
        // "laycan_month_to_date":datas.CustomInput4datepick4,
        "quantity":datas.quantity,
        "shipment_quality_tolerance":datas.shipment_quality_tolerance,
        "vessel_type":datas.vessel_type,
        "remark":datas.remark, 
        "currency":datas.currency,
        "laycan_from_date":startDate?format(startDate, 'dd/MM/yyyy'):null, 
        "laycan_to_date":endDate?format(endDate, 'dd/MM/yyyy'):null, 
        "laycan_month":datas.laycan_month,
        "laycan_year":datas.laycan_year,
        "currency":datas.currency,
        "no_of_shipments":datas.no_of_shipments,
        "quantity_type":datas.quantity_type,
        "remark":datas.remark
               };
               console.log(JSON.stringify(responseData))
               axios.put(`${API_URL}update/opp_shipment/${id}`, responseData, {
                headers: {
                  Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json',
                },
              })
                .then((response) => {
                 console.log(response)
                 if (response.data.success === true) {
                  
    setIsSubmitted(false);
                  document.getElementById('messageContainer').innerHTML = `
                      <div class="success-message">
                          ${response.data.message}
                      </div>
                  `;
                  
                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
                  fetchData();
                  setTimeout(() => {
                      $("#historys").dataTable();
                      $('#shipment').DataTable();
                      $('#user').DataTable();
                  }, 500);
                  setData(response);
                  setIsEditMode(false);
              } else if (response.data.success === false) { setIsSubmitted(false);
                  document.getElementById('messageContainer').innerHTML = `
                      <div class="error-message">
                          ${response.data.msg}
                      </div>
                  `;
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth" // Optional: animated scrolling
                });
              }
              
        })
        .catch((error) => console.error(error));
      
    }
  };

  return (
    <div id="view-page">
      <Header />
      <div className="contactAccount full-container">
      <div className="row">
        <div className="col-md-2 recent-item">
          <RecentItem/>
        </div>
        <div className="col-md-10 right-section">
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Opportunity Shipment Details</h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Opportunity Shipment Details</li>
      </ol>
    </div>
     
  </div> 
 {datas.record_type==='Buyer - Long Term' ? (
  <>
   {isEditMode ? (
    <div className="tasks"><div id="messageContainer"></div>
    
   <p className='edit-btn'>
   <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value="Save"
      //disabled={isSubmitted}
    />
   <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
   
   
   </p>
   
   <Row className="mb-3">
      <h4 className="heading">Information</h4>
      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                     value={datas.currency || ""}
     onChange={handleChangeInput}
                  >
                    <option value="">Select</option>
                    <option value="USD">USD</option>
                    <option value="IDR">IDR</option>
                  </Form.Select>
                   
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    className="record-type"
                      defaultValue={datas.record_type || ""}
     onChange={handleChangeInput}
                  />
                   
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange Rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Exchange Rate"
                    name="exchange_rate"
                     defaultValue={datas.exchange_rate || ""}
     onChange={handleChangeInput}
     />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                      defaultValue={datas.status || ""}
     onChange={handleChangeInput}
                  >
                    <option value="">Select</option>
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </Form.Select>
                   
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Opportunity"
                    className="record-type"
                    name="opportunity_shipment"
                     defaultValue={datas.opportunity_shipment || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
      </Row>
      <Row className="mb-3">
 <h4 className="heading">Price</h4>

 <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping term"
                   defaultValue={datas.shipping_term || ""}
     onChange={handleChangeInput}>
                    <option value="">Select</option>
                    <option value="FOB-B">FOB-B</option>
                    <option value="FOB-MV">FOB-MV</option>
                    <option value="CFR-MV">CFR-MV</option>
                    <option value="CIF-MV">CIF-MV</option>
                    <option value="FAS">FAS</option>
                  </Form.Select> 
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price"
                    name="price" id="price"
                     defaultValue={datas.price || ""}
                     disabled={datas.price_type !== "Fix Price"}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price basis"
                     defaultValue={datas.price_basis || ""}
     onChange={handleChangeInput}
                  >
                    <option value="">Select</option>
                    <option value="GCV(ARB)">GCV(ARB)</option>
                    <option value="GCV(ADB)">GCV(ADB)</option>
                    <option value="NCV(ARB)">NCV(ARB)</option>
                  </Form.Select> 
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="Sales price margin"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="Sales price margin"
      name="sales_price_margin"
      id="sales_price_margin" // Add an id to easily access the input
      defaultValue={datas.sales_price_margin || ""}
      onChange={handleChangeInput}
      disabled={datas.price_type === "Fix Price"} // Disable if price_type is "Fix Price"
    />
  </FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingSelect"
                     className="dropDown"
                     label="Price type"
                   >
                    <Form.Select
  aria-label="Floating label select example"
  name="price_type"
  placeholder="Price type"
  defaultValue={datas.price_type || ""}
  onChange={(e) => {
    handleChangeInput(e);
    if (e.target.value === "Fix Price") {
      // If price_type is "Fix Price", disable sales_price_margin input
      document.getElementById("sales_price_margin").disabled = true;
      document.getElementById("sales_price_margin").value = "";
    } else if (e.target.value !== "Fix Price"){
      document.getElementById("price").disabled = true;
       document.getElementById("price").value = "";
    } else {
      // Otherwise, enable sales_price_margin input
      document.getElementById("sales_price_margin").disabled = false;
    }
  }}
>
  <option value="">Select</option>
  {getPrice.map((x) => (
    <option value={x.price_type_name}>{x.price_type_name}</option>
  ))}
</Form.Select>
                   </FloatingLabel>
                 </Form.Group></Row>
 <Row className="mb-3">
              <h4 className="heading">Delivery</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="laycan Year From"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year_from"
                    placeholder="Laycan Year From"
                      defaultValue={datas.laycan_year_from|| ""}
     onChange={handleChangeInput}
                  >
                     <option value="">---None---</option>
                            
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                  </Form.Select>
                   
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="laycan Year To"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year_to"
                    placeholder="Laycan Year To"
                      defaultValue={datas.laycan_year_to || ""}
     onChange={handleChangeInput}
                  >
                     <option value="">---None---</option>
                            
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                  </Form.Select>
                   
                </FloatingLabel>
              </Form.Group>
              
              <Form.Group as={Col} md="6"></Form.Group>
              
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month From"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month_from"
                    placeholder="Laycan Month From"
                    defaultValue={datas.laycan_month_from || ""}
     onChange={handleChangeInput}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  
                </FloatingLabel>
              </Form.Group>
            
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month To"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month_to" 
                    placeholder="Laycan Month To"
                    defaultValue={datas.laycan_month_to || ""}
     onChange={handleChangeInput}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  
                </FloatingLabel>
              </Form.Group>
            

        <Form.Group as={Col} md="6"></Form.Group>
           
        
              </Row>
              <Row className="mb-3">
              <h4 className="heading">Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Quantity"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quantity_type"
                    placeholder="Quantity"
                      defaultValue={datas.quantity_type || ""}
     onChange={handleChangeInput}
                  >
                    <option value="">Select</option>
                    <option value="Base Quantity">Base Quantity</option>
                    <option value="Buyers Option">Buyers Option</option>
                  </Form.Select>
                   
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of shipments"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of shipments"
                    name="no_of_shipments"
                      defaultValue={datas.no_of_shipments || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="quantity from"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="quantity from"
                    name="quantity_from"
                      defaultValue={datas.quantity_from || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="quantity"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="quantity"
                    name="quantity"
                      defaultValue={datas.quantity || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="quantity_to"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="quantity to"
                    name="quantity_to"
                      defaultValue={datas.quantity_to || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment Quantity Tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Shipment Quantity Tolerance"
                    name="shipment_quality_tolerance"
                      defaultValue={datas.shipment_quality_tolerance || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              </Row>
              <p className='edit-btn'>
   <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value="Save"
      //disabled={isSubmitted}
    />
   <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
   
   
   </p>

      </div>
 ):(<>
   <div className="tasks"><div id="messageContainer"></div>
   <div className="edit-delete">
     <span id="edit" onClick={handleEditClick}> Edit </span>
     <span>
       <Link to={`/opportunity-shipment/create-view-list`}> Cancel</Link>
     </span>
     <span> Clone </span>
   </div>
   <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Information</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
      <tr>
      <td id="td-right">
           <span>Opp Shipment Name</span>
         </td>
         <td>{datas.opp_ship_code}</td>
         <td id="td-right">
           <span>Record Type</span>
         </td>
         <td>{datas.record_type} </td>
      </tr>
      <tr>
      <td id="td-right">
           <span>Currency</span>
         </td>
         <td>{datas.currency}</td>
         <td id="td-right">
           <span>Status</span>
         </td>
         <td>
           {datas.status} 
           </td>
      </tr>
      <tr>
      <td id="td-right">
           <span>Exchange Rate</span>
         </td>
         <td> 
          {datas.exchange_rate}
          </td>
          <td id="td-right">
           {/* <span>Exchange Rate</span> */}
         </td>
         <td> 
          {/* {datas.exchange_rate} */}
          </td>
      </tr>
      <tr>
      <td id="td-right">
           <span>Opportunity</span>
         </td>
         <td><Link to={`/opportunity/opportunity-detail/${datas.opportunity_id}`}>{datas.opportunity_shipment  } </Link> </td>
         <td id="td-right">
           {/* <span>Exchange Rate</span> */}
         </td>
         <td> 
          {/* {datas.exchange_rate} */}
          </td>
      </tr>
      <tr>
         <td id="td-right">
           <span>Quantity Info</span>
         </td>
         <td>{datas.quantity_info}</td>
         <td></td>
         <td></td>
       </tr>
     </tbody>
   </table>
   <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Price</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
      <tr>
      <td id="td-right">
           <span>Shipping Term</span>
         </td>
         <td> {datas.shipping_term}</td>
         <td id="td-right">
           <span>Pricing</span>
         </td>
         <td> {datas.pricing}</td>
      </tr>
      <tr>
      <td id="td-right">
           <span>Price Basis</span>
         </td>
         <td> {datas.price_basis} </td>
         <td id="td-right">
           <span>Price</span>
         </td>
          <td> {renderShipmentQty(datas.price)}</td>
      </tr>
      <tr>
         <td id="td-right">
           <span>Price Type</span>
         </td>
         <td> {datas.price_type}</td>
         <td id="td-right">
           <span>Sales price margin</span>
         </td>
          <td> {renderShipmentQty(datas.sales_price_margin)}</td>
       </tr>
       
     </tbody>
   </table>
   <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Delivery</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
       <tr>
       <td id="td-right">
           <span>laycan year from</span>
         </td>
         <td> {datas.laycan_year_from} </td>
         <td id="td-right">
           <span>Laycan</span>
         </td>
         <td> {datas.laycan}</td>
         
       </tr>
       <tr>
         <td id="td-right">
           <span>laycan Year To</span>
         </td>
         <td> {datas.laycan_year_to}</td>
         <td id="td-right">
           <span> </span>
         </td>
         <td>  </td>
       </tr>
       <tr>
       <td id="td-right">
           <span>Laycan Month From </span>
         </td>
         <td> {datas.laycan_month_from} </td>
         <td id="td-right">
           <span></span>
         </td>
         <td></td>
         
       </tr> 
       <tr>
       <td id="td-right">
           <span>Laycan Month To</span>
         </td>
         <td> {datas.laycan_month_to}</td>

         <td id="td-right">
           <span></span>
         </td>
         <td> </td>
       
       </tr>
     </tbody>
   </table>
   <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Quantity</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
       <tr>
         <td id="td-right">
           <span>Quantity</span>
         </td>
         <td> {datas.quantity_type}</td>
         <td id="td-right">
           <span>no of shipments</span>
         </td>
         <td> {datas.no_of_shipments} </td>
       </tr>
       <tr>
         <td id="td-right">
           <span>quantity from</span>
         </td>
          <td> {renderShipmentQty(datas.quantity_from)}</td>
         <td id="td-right">
           <span>Quantity</span>
         </td>
          <td> {renderShipmentQty(datas.quantity)}</td>
       </tr>
       <tr>
         <td id="td-right">
           <span>Quantity To</span>
         </td>
          <td> {renderShipmentQty(datas.quantity_to)}</td>
         <td id="td-right">
           <span>shipment quality tolerance</span>
         </td>
         <td> {datas.shipment_quality_tolerance} </td>
       </tr>
       <tr>
         <td id="td-right">
           <span>Created By</span>
         </td>
         <td> {datas.created_by}</td>
         <td id="td-right">
           <span>Last Modified By</span>
         </td>
         <td> {datas.modified_by} </td>
       </tr>
     </tbody>
   </table>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Open Activities</span>
         <span>
           <i className=""></i> New Task </span>
         <span>
           <i className=""></i> New Event </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>

   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Notes & Attachments</span>
         <span>
           <i className=""></i> New Note </span>
         <span>
           <i className=""></i> Attach File </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Opportunity Product</span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id='table-styles'>
       <div className="col-md-12" id='ax.account_details'>
         <h4 className="heading">
           <span>Quote Shipment Information</span>
         </h4>
         <hr>
         </hr>
       </div>
       <table id='shipment' className="table table-bordered">
         <thead>
           <tr>
             <th>Quote Shipment Name	</th>
             <th>Laycan</th>
             <th>Pricing</th>
             <th>Quantity Type	</th>
             <th>Quantity	</th>
             <th>Quantity from	</th>
             <th>Quantity To	</th>
             <th>No of Shipments
             </th>
           </tr>
         </thead> {shipments.length>0 ? <tbody> { shipments.map((y,i)=>( <tr>
             <td>
               <span id='codes'><Link to={`/quote-shipment/detail/${y.id}`}>{y.quote_shipment_code}</Link></span>
             </td>
             <td>{y.quote_shipment_laycan}</td>
             <td>{y.quote_shipment_price}</td>
             <td>{y.quote_shipment_quantity_type}</td>
             <td>{y.quote_shipment_quantity}</td>
             <td>{y.quote_shipment_quantity_from}</td>
             <td>{y.quote_shipment_quantity_to}</td>
             <td>{y.quote_shipment_no_of_shipments}</td>
           </tr> ))} </tbody> : <tbody>
           <tr>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td>No data available</td>
             <td></td>
             <td></td>
             <td></td>
           </tr>
         </tbody> }
       </table>
     </Row>
   <Row id='table-styles'>
     <div className="col-md-12" id='ax.account_details'>
       <h4 className="heading">
         <span>Users Information</span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id='user' className="table table-bordered">
       <thead>
         <tr>
           <th>User Name</th>
           <th>Email</th>
         </tr>
       </thead> {users!=='' ? <tbody>
         <tr>
           <td>
             <span id='codes'>{users.username}</span>
           </td>
           <td>{users.email}</td>
         </tr>
       </tbody> : <tbody>
         <tr>
           <td></td>
           <td>No data available</td>
         </tr>
       </tbody> }
     </table>
   </Row>
   <Row id="table-styles">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            <span>Account History</span>
                                          </h4>
                                          <hr></hr>
                                        </div>
                                        <table id="historys" className="">
                                          <thead>
                                            <tr>
                                              <th>Field Name</th>
                                              <th>New Value</th>
                                              <th>Old Value</th>
                                              <th>Changed By</th>
                                              <th>Change Time</th>
                                            </tr>
                                          </thead>

                                          {history.length > 0 ? (
                                            <tbody>
                                              {history.map((y, i) => (
                                                <tr>
                                                  <td>{y.field_name}</td>
                                                  <td>{y.new_value}</td>
                                                  <td>{y.old_value}</td>
                                                  <td>{y.changed_by}</td>
                                                  <td>{y.change_time}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          ) : (
                                            <tbody>
                                              <tr>
                                                <td></td>
                                                <td></td>
                                                <td>No data available</td>
                                                <td></td>
                                                <td></td>
                                              </tr>
                                            </tbody>
                                          )}
                                        </table>
                                      </Row>
 </div> 
 </>)}
 </>
 
 ): (
  <>
  
    {datas.record_type==='Buyer - Spot' ? (
     <>
     {isEditMode ? (
      <div className="tasks">
      <div id="messageContainer"></div>
     <p className='edit-btn'>
     <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value="Save"
      //disabled={isSubmitted}
    />
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
     
     
     </p>
     {error && <p className='date-error'><span>{error}</span></p>}
     
     <Row className="mb-3">
        <h4 className="heading">Information</h4>
            <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="opportunity shipment"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="opportunity shipment"
                      name="opportunity_shipment"
                       defaultValue={datas.opportunity_shipment || ""}
       onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Opportunity"
                    className="record-type"
                    name="opportunity_shipment"
                     defaultValue={datas.opportunity_shipment || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
 

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                     defaultValue={datas.currency || ""}
     onChange={handleChangeInput}
                  >
                    <option value="">Select</option>
                    <option value="USD">USD</option>
                    <option value="IDR">IDR</option>
                  </Form.Select>
                   
                </FloatingLabel>
                
                </Form.Group>


                <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    className="record-type"
                      defaultValue={datas.record_type || ""}
     onChange={handleChangeInput}
                  />
                   
                </FloatingLabel>
              </Form.Group>
 
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange Rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Exchange Rate"
                    name="exchange_rate"
                     defaultValue={datas.exchange_rate || ""}
     onChange={handleChangeInput}
     />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                      defaultValue={datas.status || ""}
     onChange={handleChangeInput}
                  >
                    <option value="">Select</option>
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </Form.Select>
                   
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
    
    </Row>

    <Row className="mb-3">
              <h4 className="heading">Price</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
                  <Form.Select
  aria-label="Floating label select example"
  name="price_type"
  placeholder="Price type"
  defaultValue={datas.price_type || ""}
  onChange={(e) => {
    handleChangeInput(e);
    if (e.target.value === "Fix Price") {
      // If price_type is "Fix Price", disable sales_price_margin input
      document.getElementById("sales_price_margin").disabled = true;
      document.getElementById("sales_price_margin").value = "";
    } else if (e.target.value !== "Fix Price"){
      document.getElementById("price").disabled = true;
       document.getElementById("price").value = "";
    } else {
      // Otherwise, enable sales_price_margin input
      document.getElementById("sales_price_margin").disabled = false;
    }
  }}
>
  <option value="">Select</option>
  {getPrice.map((x) => (
    <option value={x.price_type_name}>{x.price_type_name}</option>
  ))}
</Form.Select>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price"
                    name="price" id="price"
                     defaultValue={datas.price || ""}
                     disabled={datas.price_type !== "Fix Price"}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price basis"
                     defaultValue={datas.price_basis || ""}
     onChange={handleChangeInput}
                  >
                    <option value="">Select</option>
                    <option value="GCV(ARB)">GCV(ARB)</option>
                    <option value="GCV(ADB)">GCV(ADB)</option>
                    <option value="NCV(ARB)">NCV(ARB)</option>
                  </Form.Select> 
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="Sales price margin"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="Sales price margin"
      name="sales_price_margin"
      id="sales_price_margin" // Add an id to easily access the input
      defaultValue={datas.sales_price_margin || ""}
      onChange={handleChangeInput}
      disabled={datas.price_type === "Fix Price"} // Disable if price_type is "Fix Price"
    />
  </FloatingLabel>
</Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping term"
                   defaultValue={datas.shipping_term || ""}
     onChange={handleChangeInput}>
                    <option value="">Select</option>
                    <option value="FOB-B">FOB-B</option>
                    <option value="FOB-MV">FOB-MV</option>
                    <option value="CFR-MV">CFR-MV</option>
                    <option value="CIF-MV">CIF-MV</option>
                    <option value="FAS">FAS</option>
                  </Form.Select> 
                </FloatingLabel>
              </Form.Group>
              </Row>


              <Row className="mb-3">
              <h4 className="heading">Delivery</h4>
              <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Laycan From Date"
          className="mb-3 datepick"
          
          
        >
            <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        dateFormat='dd/MM/yyyy'
          placeholderText="Select" 
      />
        </FloatingLabel>
      </Form.Group>       


               
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="laycan Year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year"
                    placeholder="Laycan Year"
                      defaultValue={datas.laycan_year || ""}
     onChange={handleChangeInput}
                  >
                     <option value="">---None---</option>
                            
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                  </Form.Select>
                   
                </FloatingLabel>
              </Form.Group>
              
              
              <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Laycan To Date"
          className="mb-3 datepick error-validation"
          
          
        >
           <DatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        dateFormat='dd/MM/yyyy'
        selectsEnd 
        
         placeholderText="Select Date"
      />
 </FloatingLabel>
 {endDateError && <div className='error-validations' style={{ color: 'red' }}>{endDateError}</div>}
      </Form.Group>
      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month"
                    placeholder="Laycan Month"
                    defaultValue={datas.laycan_month || ""}
     onChange={handleChangeInput}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  
                </FloatingLabel>
              </Form.Group>
           
        
              </Row>
              <Row className="mb-3">
              <h4 className="heading">Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="quantity"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="quantity"
                    name="quantity"
                      defaultValue={datas.quantity || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="vessel type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="vessel_type"
                    placeholder="vessel type"
                     value={datas.vessel_type || ""}
     onChange={handleChangeInput}
                  > 
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>
                   
                </FloatingLabel>
              </Form.Group>
              
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment Quantity Tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipment Quantity Tolerance"
                    name="shipment_quality_tolerance"
                      defaultValue={datas.shipment_quality_tolerance || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
             
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remark"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Remark"
                    name="remark"
                      defaultValue={datas.remark || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
               </Row>
    
                <p className='edit-btn'>
     <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value="Save"
      //disabled={isSubmitted}
    />
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
     
     
     </p>
  
        </div>
   ):(<>
     <div className="tasks"><div id="messageContainer"></div>
     <div className="edit-delete">
       <span id="edit" onClick={handleEditClick}> Edit </span>
       <span>
         <Link to={`/opportunity-shipment/create-view-list`}> Cancel</Link>
       </span>
       <span> Clone </span>
     </div>
     <div className="col-md-12" id='ax.account_details'>
       <h4 className="heading">Information</h4>
       <hr>
       </hr>
     </div>
     <table class="table table-bordered account-table tables">
       <tbody>
       <tr>
         <td id="td-right">
           <span>Opp Shipment Name</span>
         </td>
         <td>{datas.opp_ship_code}</td>
         <td id="td-right">
           <span>Opportunity</span>
         </td>
         <td><Link to={`/opportunity/opportunity-detail/${datas.opportunity_id}`}>{datas.opportunity_shipment  } </Link> </td>
       </tr>
          <tr>
           <td id="td-right">
             <span>Currency</span>
           </td>
           <td>{datas.currency}</td>
           <td id="td-right">
             <span>Record Type</span>
           </td>
           <td>{datas.record_type} </td>
         </tr>
          <tr>
           <td id="td-right">
             <span>Exchange Rate</span>
           </td>
           <td>{datas.exchange_rate}</td>
           <td id="td-right">
             {/* <span>Opportunity</span> */}
           </td>
           {/* <td>{datas.opportunity} </td> */}
         </tr><tr>
           <td id="td-right">
             <span>Status</span>
           </td>
           <td>{datas.status}</td>
           <td id="td-right">
              <span></span>
           </td>
           <td></td>
         </tr>
         <tr>
          
           <td id="td-right">
              <span>quantity info</span>
           </td>
           <td>{datas.quantity_info}</td>
           <td id="td-right">
             {/* <span>Status</span> */}
           </td>
           <td></td>
         </tr>
         
       </tbody>
     </table>
     <div className="col-md-12" id='ax.account_details'>
       <h4 className="heading">Price</h4>
       <hr>
       </hr>
     </div>
     <table class="table table-bordered account-table tables">
       <tbody>
       <tr>
           <td id="td-right">
             <span>Pricing</span>
           </td>
           <td> {datas.pricing}</td>
           <td id="td-right">
             <span>Price</span>
           </td>
            <td> {renderShipmentQty(datas.price)}</td>
         </tr>
         <tr>
           <td id="td-right">
             <span>Price Type</span>
           </td>
           <td> {datas.price_type}</td>
           <td id="td-right">
             <span>Sales price margin</span>
           </td>
            <td> {renderShipmentQty(datas.sales_price_margin)}</td>
         </tr> 
         <tr>
         <td id="td-right">
             <span>price basis</span>
           </td>
           <td> {datas.price_basis}</td>
           <td id="td-right">
             <span>shipping term</span>
           </td>
           <td> {datas.shipping_term}</td>
         </tr>
       </tbody>
     </table>
     <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Delivery</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
      <tr>
      <td id="td-right">
           <span>laycan</span>
         </td>
         <td> {datas.laycan} </td>
         
         <td id="td-right">
           <span>Laycan Year</span>
         </td>
         <td> {datas.laycan_year}</td>
        
      </tr>
      <tr>
      <td id="td-right">
           <span>laycan from date</span>
         </td>
         <td> {datas.laycan_from_date}</td>
         <td id="td-right">
           <span>Laycan Month</span>
         </td>
         <td> {datas.laycan_month}</td>

        
      </tr>
      <tr>
         <td id="td-right">
           <span>laycan to date</span>
         </td>
         <td> {datas.laycan_to_date}</td>
         <td id="td-right">
           {/* <span>Laycan Month</span> */}
         </td>
         <td>  </td>
       </tr>
      </tbody>
      </table>
     <div className="col-md-12" id='ax.account_details'>
       <h4 className="heading">Quantity</h4>
       <hr>
       </hr>
     </div>
     <table class="table table-bordered account-table tables">
       <tbody>
         <tr>
           <td id="td-right">
             <span>Quantity</span>
           </td>
            <td> {renderShipmentQty(datas.quantity)}</td>
           <td id="td-right">
             <span>vessel type</span>
           </td>
           <td> {datas.vessel_type} </td>
         </tr>
         <tr>
           <td id="td-right">
             <span>shipment quantity tolerance</span>
           </td>
           <td> {datas.shipment_quality_tolerance}</td>
           <td id="td-right">
             {/* <span>remark</span> */}
           </td>
           <td>
             {/* {datas.remark}  */}
             </td>
         </tr>
         <tr>
         <td id="td-right">
             <span>remark</span>
           </td>
           <td> {datas.remark} </td>
           <td id="td-right">
             {/* <span>remark</span> */}
           </td>
           <td>
             {/* {datas.remasivark}  */}
             </td>
         </tr>
         <tr>
         <td id="td-right">
           <span>Created By</span>
         </td>
         <td> {datas.created_by}</td>
         <td id="td-right">
           <span>Last Modified By</span>
         </td>
         <td> {datas.modified_by} </td>
       </tr>
          
       </tbody>
     </table>
     <Row id="table-styles">
       <div className="col-md-12" id="head">
         <h4 className="heading">
           <span>Open Activities</span>
           <span>
             <i className=""></i> New Task </span>
           <span>
             <i className=""></i> New Event </span>
         </h4>
         <hr>
         </hr>
       </div>
       <table id="history" className="">
         <tr>
           <td>
             <p class="no-records"> No records to display </p>
           </td>
         </tr>
       </table>
     </Row>
     <Row id="table-styles">
       <div className="col-md-12" id="head">
         <h4 className="heading">
           <span>Activity History</span>
           <span>
             <i className=""></i> Log a Call </span>
           <span>
             <i className=""></i> Mail merge </span>
         </h4>
         <hr>
         </hr>
       </div>
       <table id="history" className="">
         <tr>
           <td>
             <p class="no-records"> No records to display </p>
           </td>
         </tr>
       </table>
     </Row>
     <Row id="table-styles">
       <div className="col-md-12" id="head">
         <h4 className="heading">
           <span>Notes & Attachments</span>
           <span>
             <i className=""></i> New Note </span>
           <span>
             <i className=""></i> Attach File </span>
         </h4>
         <hr>
         </hr>
       </div>
       <table id="history" className="">
         <tr>
           <td>
             <p class="no-records"> No records to display </p>
           </td>
         </tr>
       </table>
     </Row>
     <Row id="table-styles">
       <div className="col-md-12" id="head">
         <h4 className="heading">
           <span>Opportunity Product</span>
         </h4>
         <hr>
         </hr>
       </div>
       <table id="history" className="">
         <tr>
           <td>
             <p class="no-records"> No records to display </p>
           </td>
         </tr>
       </table>
     </Row>
     <Row id='table-styles'>
       <div className="col-md-12" id='ax.account_details'>
         <h4 className="heading">
           <span>Quote Shipment Information</span>
         </h4>
         <hr>
         </hr>
       </div>
       <table id='shipment' className="table table-bordered">
         <thead>
           <tr>
             <th>Quote Shipment Name	</th>
             <th>Laycan</th>
             <th>Pricing</th>
             <th>Quantity Type	</th>
             <th>Quantity	</th>
             <th>Quantity from	</th>
             <th>Quantity To	</th>
             <th>No of Shipments
             </th>
           </tr>
         </thead> {shipments.length>0 ? <tbody> { shipments.map((y,i)=>( <tr>
             <td>
               <span id='codes'><Link to={`/quote-shipment/detail/${y.id}`}>{y.quote_shipment_code}</Link></span>
             </td>
             <td>{y.quote_shipment_laycan}</td>
             <td>{y.quote_shipment_price}</td>
             <td>{y.quote_shipment_quantity_type}</td>
             <td>{y.quote_shipment_quantity}</td>
             <td>{y.quote_shipment_quantity_from}</td>
             <td>{y.quote_shipment_quantity_to}</td>
             <td>{y.quote_shipment_no_of_shipments}</td>
           </tr> ))} </tbody> : <tbody>
           <tr>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td>No data available</td>
             <td></td>
             <td></td>
             <td></td>
           </tr>
         </tbody> }
       </table>
     </Row>
     <Row id='table-styles'>
       <div className="col-md-12" id='ax.account_details'>
         <h4 className="heading">
           <span>Users Information</span>
         </h4>
         <hr>
         </hr>
       </div>
       <table id='user' className="table table-bordered">
         <thead>
           <tr>
             <th>User Name</th>
             <th>Email</th>
           </tr>
         </thead> {users!=='' ? <tbody>
           <tr>
             <td>
               <span id='codes'>{users.username}</span>
             </td>
             <td>{users.email}</td>
           </tr>
         </tbody> : <tbody>
           <tr>
             <td></td>
             <td>No data available</td>
           </tr>
         </tbody> }
       </table>
     </Row>
   </div> 
   </>)}
   </>
  ):(<>
  {datas.record_type==='Supplier - Long Term' ?(
<>
 {isEditMode ? (
    <div className="tasks"><div id="messageContainer"></div>
  
   <p className='edit-btn'>
   <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value="Save"
      //disabled={isSubmitted}
    />
   <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
   
   
   </p>
   
   <Row className="mb-3">
      <h4 className="heading">Information</h4>
      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                     value={datas.currency || ""}
     onChange={handleChangeInput}
                  >
                    <option value="">Select</option>
                    <option value="USD">USD</option>
                    <option value="IDR">IDR</option>
                  </Form.Select>
                   
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Record type"
                    name="record_type"
                    className="record-type"
                      defaultValue={datas.record_type || ""}
     onChange={handleChangeInput}
                  />
                   
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange Rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Exchange Rate"
                    name="exchange_rate"
                     defaultValue={datas.exchange_rate || ""}
     onChange={handleChangeInput}
     />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                      defaultValue={datas.status || ""}
     onChange={handleChangeInput}
                  >
                    <option value="">Select</option>
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </Form.Select>
                   
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Opportunity"
                    className="record-type"
                    name="opportunity_shipment"
                     defaultValue={datas.opportunity_shipment || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
      </Row>
      <Row className="mb-3">
 <h4 className="heading">Price</h4>

 <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping term"
                   defaultValue={datas.shipping_term || ""}
     onChange={handleChangeInput}>
                    <option value="">Select</option>
                    <option value="FOB-B">FOB-B</option>
                    <option value="FOB-MV">FOB-MV</option>
                    <option value="CFR-MV">CFR-MV</option>
                    <option value="CIF-MV">CIF-MV</option>
                    <option value="FAS">FAS</option>
                  </Form.Select> 
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price"
                    name="price" id="price"
                     defaultValue={datas.price || ""}
                     disabled={datas.price_type !== "Fix Price"}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price basis"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_basis"
                    placeholder="Price basis"
                     defaultValue={datas.price_basis || ""}
     onChange={handleChangeInput}
                  >
                    <option value="">Select</option>
                    <option value="GCV(ARB)">GCV(ARB)</option>
                    <option value="GCV(ADB)">GCV(ADB)</option>
                    <option value="NCV(ARB)">NCV(ARB)</option>
                  </Form.Select> 
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="Sales price margin"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="Sales price margin"
      name="sales_price_margin"
      id="sales_price_margin" // Add an id to easily access the input
      defaultValue={datas.sales_price_margin || ""}
      onChange={handleChangeInput}
      disabled={datas.price_type === "Fix Price"} // Disable if price_type is "Fix Price"
    />
  </FloatingLabel>
</Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
                  <Form.Select
  aria-label="Floating label select example"
  name="price_type"
  placeholder="Price type"
  defaultValue={datas.price_type || ""}
  onChange={(e) => {
    handleChangeInput(e);
    if (e.target.value === "Fix Price") {
      // If price_type is "Fix Price", disable sales_price_margin input
      document.getElementById("sales_price_margin").disabled = true;
      document.getElementById("sales_price_margin").value = "";
    } else if (e.target.value !== "Fix Price"){
      document.getElementById("price").disabled = true;
       document.getElementById("price").value = "";
    } else {
      // Otherwise, enable sales_price_margin input
      document.getElementById("sales_price_margin").disabled = false;
    }
  }}
>
  <option value="">Select</option>
  {getPrice.map((x) => (
    <option value={x.price_type_name}>{x.price_type_name}</option>
  ))}
</Form.Select>
                </FloatingLabel>
              </Form.Group>
            
 </Row>
 <Row className="mb-3">
              <h4 className="heading">Delivery</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="laycan Year From"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year_from"
                    placeholder="Laycan Year From"
                      defaultValue={datas.laycan_year_from|| ""}
     onChange={handleChangeInput}
                  >
                     <option value="">---None---</option>
                            
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                  </Form.Select>
                   
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="laycan Year To"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year_to"
                    placeholder="Laycan Year To"
                      defaultValue={datas.laycan_year_to || ""}
     onChange={handleChangeInput}
                  >
                     <option value="">---None---</option>
                            
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                  </Form.Select>
                   
                </FloatingLabel>
              </Form.Group>
              
              <Form.Group as={Col} md="6"></Form.Group>
              
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month From "
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month_from"
                    placeholder="Laycan Month From"
                    defaultValue={datas.laycan_month_from || ""}
     onChange={handleChangeInput}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  
                </FloatingLabel>
              </Form.Group>
            
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month To"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month_to"
                    placeholder="Laycan Month To"
                    defaultValue={datas.laycan_month_to || ""}
     onChange={handleChangeInput}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  
                </FloatingLabel>
              </Form.Group>
            

        <Form.Group as={Col} md="6"></Form.Group>
           
        
              </Row>
              <Row className="mb-3">
              <h4 className="heading">Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Quantity"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="quantity_type"
                    placeholder="Quantity"
                      defaultValue={datas.quantity_type || ""}
     onChange={handleChangeInput}
                  >
                    <option value="">Select</option>
                    <option value="Base Quantity">Base Quantity</option>
                    <option value="Buyers Option">Buyers Option</option>
                  </Form.Select>
                   
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="No of shipments"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="No of shipments"
                    name="no_of_shipments"
                      defaultValue={datas.no_of_shipments || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="quantity from"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="quantity from"
                    name="quantity_from"
                      defaultValue={datas.quantity_from || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="quantity"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="quantity"
                    name="quantity"
                      defaultValue={datas.quantity || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="quantity_to"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="quantity to"
                    name="quantity_to"
                      defaultValue={datas.quantity_to || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipment Quantity Tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Shipment Quantity Tolerance"
                    name="shipment_quality_tolerance"
                      defaultValue={datas.shipment_quality_tolerance || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
              </Row>
              <p className='edit-btn'>
   <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value="Save"
      //disabled={isSubmitted}
    />
   <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
   
   
   </p>

      </div>
 ):(<>
   <div className="tasks"><div id="messageContainer"></div>
   <div className="edit-delete">
     <span id="edit" onClick={handleEditClick}> Edit </span>
     <span>
       <Link to={`/opportunity-shipment/create-view-list`}> Cancel</Link>
     </span>
     <span> Clone </span>
   </div>
   <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Information</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
       <tr>
         <td id="td-right">
           <span>Opp Shipment Name</span>
         </td>
         <td>{datas.opp_ship_code}</td>
         <td id="td-right">
           <span>Record Type</span>
         </td>
         <td>{datas.record_type} </td>
        
       </tr>
       <tr>
         <td id="td-right">
           <span>Currency</span>
         </td>
         <td>{datas.currency}</td>
         <td id="td-right">
           <span>Status</span>
         </td>
         <td>{datas.status} </td>
       </tr>
       <tr>
         <td id="td-right">
           <span>Exchange Rate</span>
         </td>
         <td> {datas.exchange_rate}</td>
         <td id="td-right">
         </td>
         <td>  </td>
       </tr>
       <tr>
       <td id="td-right">
           <span>Opportunity</span>
         </td>
         <td><Link to={`/opportunity/opportunity-detail/${datas.opportunity_id}`}>{datas.opportunity_shipment  } </Link> </td>
         <td id="td-right">
         </td>
         <td></td>
       </tr>
       <tr>
         <td id="td-right">
           <span>Quantity Info</span>
         </td>
         <td>{datas.quantity_info}</td>
         <td></td>
         <td></td>
       </tr>
     </tbody>
   </table>
   <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Price</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
       <tr>
       <td id="td-right">
           <span>Shipping Term</span>
         </td>
         <td> {datas.shipping_term}</td>
         <td id="td-right">
           <span>Pricing</span>
         </td>
         <td> {datas.pricing}</td>
        
       </tr>
       <tr>
       <td id="td-right">
           <span>Price Basis</span>
         </td>
         <td> {datas.price_basis} </td>
         <td id="td-right">
           <span>Price</span>
         </td>
          <td> {renderShipmentQty(datas.price)}</td>
        
       </tr>
       <tr>
         <td id="td-right">
           <span>Price Type</span>
         </td>
         <td> {datas.price_type}</td>
         <td id="td-right">
           <span>Sales price margin</span>
         </td>
          <td> {renderShipmentQty(datas.sales_price_margin)}</td>
       </tr>     </tbody>
   </table>
   <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Delivery</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
       <tr>
       <td id="td-right">
           <span>laycan year from</span>
         </td>
         <td> {datas.laycan_year_from} </td>
         <td id="td-right">
           <span>Laycan</span>
         </td>
         <td> {datas.laycan}</td>
         
       </tr>
       <tr>
         <td id="td-right">
           <span>laycan Year To</span>
         </td>
         <td> {datas.laycan_year_to}</td>
         <td id="td-right">
           <span> </span>
         </td>
         <td>  </td>
       </tr>
       <tr>
       <td id="td-right">
           <span>Laycan Month From</span>
         </td>
         <td> {datas.laycan_month_from} </td>
         <td id="td-right">
           <span></span>
         </td>
         <td></td>
         
       </tr> 
       <tr>
       <td id="td-right">
           <span>Laycan Month To</span>
         </td>
         <td> {datas.laycan_month_to}</td>

         <td id="td-right">
           <span></span>
         </td>
         <td> </td>
       
       </tr>
     </tbody>
   </table>
   <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Quantity</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
       <tr>
         <td id="td-right">
           <span>Quantity</span>
         </td>
         <td> {datas.quantity_type}</td>
         <td id="td-right">
           <span>no of shipments</span>
         </td>
         <td> {datas.no_of_shipments} </td>
       </tr>
       <tr>
         <td id="td-right">
           <span>quantity from</span>
         </td>
          <td> {renderShipmentQty(datas.quantity_from)}</td>
         <td id="td-right">
           <span>Quantity</span>
         </td>
          <td> {renderShipmentQty(datas.quantity)}</td>
       </tr>
       <tr>
         <td id="td-right">
           <span>Quantity To</span>
         </td>
          <td> {renderShipmentQty(datas.quantity_to)}</td>
         <td id="td-right">
           <span>shipment quality tolerance</span>
         </td>
         <td> {datas.shipment_quality_tolerance} </td>
       </tr>
     </tbody>
   </table>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Open Activities</span>
         <span>
           <i className=""></i> New Task </span>
         <span>
           <i className=""></i> New Event </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Notes & Attachments</span>
         <span>
           <i className=""></i> New Note </span>
         <span>
           <i className=""></i> Attach File </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Opportunity Product</span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id='table-styles'>
       <div className="col-md-12" id='ax.account_details'>
         <h4 className="heading">
           <span>Quote Shipment Information</span>
         </h4>
         <hr>
         </hr>
       </div>
       <table id='shipment' className="table table-bordered">
         <thead>
           <tr>
             <th>Quote Shipment Name	</th>
             <th>Laycan</th>
             <th>Pricing</th>
             <th>Quantity Type	</th>
             <th>Quantity	</th>
             <th>Quantity from	</th>
             <th>Quantity To	</th>
             <th>No of Shipments
             </th>
           </tr>
         </thead> {shipments.length>0 ? <tbody> { shipments.map((y,i)=>( <tr>
             <td>
               <span id='codes'><Link to={`/quote-shipment/detail/${y.id}`}>{y.quote_shipment_code}</Link></span>
             </td>
             <td>{y.quote_shipment_laycan}</td>
             <td>{y.quote_shipment_price}</td>
             <td>{y.quote_shipment_quantity_type}</td>
             <td>{y.quote_shipment_quantity}</td>
             <td>{y.quote_shipment_quantity_from}</td>
             <td>{y.quote_shipment_quantity_to}</td>
             <td>{y.quote_shipment_no_of_shipments}</td>
           </tr> ))} </tbody> : <tbody>
           <tr>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td>No data available</td>
             <td></td>
             <td></td>
             <td></td>
           </tr>
         </tbody> }
       </table>
     </Row>
   <Row id='table-styles'>
     <div className="col-md-12" id='ax.account_details'>
       <h4 className="heading">
         <span>Users Information</span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id='user' className="table table-bordered">
       <thead>
         <tr>
           <th>User Name</th>
           <th>Email</th>
         </tr>
       </thead> {users!=='' ? <tbody>
         <tr>
           <td>
             <span id='codes'>{users.username}</span>
           </td>
           <td>{users.email}</td>
         </tr>
       </tbody> : <tbody>
         <tr>
           <td></td>
           <td>No data available</td>
         </tr>
       </tbody> }
     </table>
   </Row>
   <Row id="table-styles">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            <span>Account History</span>
                                          </h4>
                                          <hr></hr>
                                        </div>
                                        <table id="historys" className="">
                                          <thead>
                                            <tr>
                                              <th>Field Name</th>
                                              <th>New Value</th>
                                              <th>Old Value</th>
                                              <th>Changed By</th>
                                              <th>Change Time</th>
                                            </tr>
                                          </thead>

                                          {history.length > 0 ? (
                                            <tbody>
                                              {history.map((y, i) => (
                                                <tr>
                                                  <td>{y.field_name}</td>
                                                  <td>{y.new_value}</td>
                                                  <td>{y.old_value}</td>
                                                  <td>{y.changed_by}</td>
                                                  <td>{y.change_time}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          ) : (
                                            <tbody>
                                              <tr>
                                                <td></td>
                                                <td></td>
                                                <td>No data available</td>
                                                <td></td>
                                                <td></td>
                                              </tr>
                                            </tbody>
                                          )}
                                        </table>
                                      </Row>
 </div> 
 </>)}</>
  ):(<>
   {datas.record_type==='Supplier - Spot' ?(
    <>
    {isEditMode ? (
       <div className="tasks"><div id="messageContainer"></div>
      
      <p className='edit-btn'>
      <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value="Save"
      //disabled={isSubmitted}
    />
      <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
      
      
      </p>
      
      <Row className="mb-3">
         <h4 className="heading">Information</h4>
         <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Opp Shipment Name"
                     className="mb-3"
                   >
                     <Form.Control
                       type="text"
                       placeholder="Opp Shipment Name"
                       name="opp_ship_code"
                       className="record-type"
                         defaultValue={datas.opp_ship_code || ""}
        onChange={handleChangeInput}
                     />
                      
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Opportunity"
                     className="mb-3"
                   >
                     <Form.Control
                       type="text"
                       placeholder="Opportunity"
                       className="record-type"
                       name="opportunity_shipment"
                        defaultValue={datas.opportunity_shipment || ""}
        onChange={handleChangeInput}/>
                   </FloatingLabel>
                 </Form.Group>
         <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingSelect"
                     className="dropDown"
                     label="Currency"
                   >
                     <Form.Select
                       aria-label="Floating label select example"
                       name="currency"
                       placeholder="Currency"
                        value={datas.currency || ""}
        onChange={handleChangeInput}
                     >
                       <option value="">Select</option>
                       <option value="USD">USD</option>
                       <option value="IDR">IDR</option>
                     </Form.Select>
                      
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Record type"
                     className="mb-3"
                   >
                     <Form.Control
                       type="text"
                       placeholder="Record type"
                       name="record_type"
                       className="record-type"
                         defaultValue={datas.record_type || ""}
        onChange={handleChangeInput}
                     />
                      
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Exchange Rate"
                     className="mb-3"
                   >
                     <Form.Control
                       type="number"
                       placeholder="Exchange Rate"
                       name="exchange_rate"
                        defaultValue={datas.exchange_rate || ""}
        onChange={handleChangeInput}
        />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6"></Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingSelect"
                     className="dropDown"
                     label="Status"
                   >
                     <Form.Select
                       aria-label="Floating label select example"
                       name="status"
                       placeholder="Status"
                         defaultValue={datas.status || ""}
        onChange={handleChangeInput}
                     >
                       <option value="">Select</option>
                       <option value="Open">Open</option>
                       <option value="Closed">Closed</option>
                     </Form.Select>
                      
                   </FloatingLabel>
                 </Form.Group>
                 
         </Row>
         <Row className="mb-3">
    <h4 className="heading">Price</h4>
   
    <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingSelect"
                     className="dropDown"
                     label="Shipping term"
                   >
                     <Form.Select
                       aria-label="Floating label select example"
                       name="shipping_term"
                       placeholder="Shipping term"
                      defaultValue={datas.shipping_term || ""}
        onChange={handleChangeInput}>
                       <option value="">Select</option>
                       <option value="FOB-B">FOB-B</option>
                       <option value="FOB-MV">FOB-MV</option>
                       <option value="CFR-MV">CFR-MV</option>
                       <option value="CIF-MV">CIF-MV</option>
                       <option value="FAS">FAS</option>
                     </Form.Select> 
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Price"
                     className="mb-3"
                   >
                     <Form.Control
                       type="number"
                       placeholder="Price"
                       name="price" id="price"
                        defaultValue={datas.price || ""}
                        disabled={datas.price_type !== "Fix Price"}
        onChange={handleChangeInput}/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingSelect"
                     className="dropDown"
                     label="Price basis"
                   >
                     <Form.Select
                       aria-label="Floating label select example"
                       name="price_basis"
                       placeholder="Price basis"
                        defaultValue={datas.price_basis || ""}
        onChange={handleChangeInput}
                     >
                       <option value="">Select</option>
                       <option value="GCV(ARB)">GCV(ARB)</option>
                       <option value="GCV(ADB)">GCV(ADB)</option>
                       <option value="NCV(ARB)">NCV(ARB)</option>
                     </Form.Select> 
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
  <FloatingLabel
    controlId="floatingInput"
    label="Sales price margin"
    className="mb-3"
  >
    <Form.Control
      type="number"
      placeholder="Sales price margin"
      name="sales_price_margin"
      id="sales_price_margin" // Add an id to easily access the input
      defaultValue={datas.sales_price_margin || ""}
      onChange={handleChangeInput}
      disabled={datas.price_type === "Fix Price"} 
    />
  </FloatingLabel>
</Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingSelect"
                     className="dropDown"
                     label="Price type"
                   >
                    <Form.Select
  aria-label="Floating label select example"
  name="price_type"
  placeholder="Price type"
  defaultValue={datas.price_type || ""}
  onChange={(e) => {
    handleChangeInput(e);
    if (e.target.value === "Fix Price") {
      // If price_type is "Fix Price", disable sales_price_margin input
      document.getElementById("sales_price_margin").disabled = true;
      document.getElementById("sales_price_margin").value = "";
    } 
    
    else if (e.target.value !== "Fix Price"){
      document.getElementById("price").disabled = true;
       document.getElementById("price").value = "";
    }
    else {
      // Otherwise, enable sales_price_margin input
      document.getElementById("sales_price_margin").disabled = false;
    }
  }}
>
  <option value="">Select</option>
  {getPrice.map((x) => (
    <option value={x.price_type_name}>{x.price_type_name}</option>
  ))}
</Form.Select>
                   </FloatingLabel>
                 </Form.Group>
               
    </Row>
    <Row className="mb-3">
              <h4 className="heading">Delivery</h4>
              <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Laycan From Date"
          className="mb-3 datepick"
          
          
        >
            <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        dateFormat='dd/MM/yyyy'
          placeholderText="Select" 
      />
        </FloatingLabel>
      </Form.Group>       


               
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="laycan Year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year"
                    placeholder="Laycan Year"
                      defaultValue={datas.laycan_year || ""}
     onChange={handleChangeInput}
                  >
                     <option value="">---None---</option>
                            
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                  </Form.Select>
                   
                </FloatingLabel>
              </Form.Group>
              
              
              <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Laycan To Date"
          className="mb-3 datepick error-validation"
          
          
        >
           <DatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        dateFormat='dd/MM/yyyy'
        selectsEnd 
        
         placeholderText="Select Date"
      />
      {endDateError && <div style={{ color: 'red' }}>{endDateError}</div>}
 </FloatingLabel>
      </Form.Group>
      <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month  "
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month"
                    placeholder="Laycan Month "
                    defaultValue={datas.laycan_month || ""}
     onChange={handleChangeInput}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  
                </FloatingLabel>
              </Form.Group>
           
        
              </Row>
                 <Row className="mb-3">
                 <h4 className="heading">Quantity</h4>
              
                 
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="quantity"
                     className="mb-3"
                   >
                     <Form.Control
                       type="number"
                       placeholder="quantity"
                       name="quantity"
                         defaultValue={datas.quantity || ""}
        onChange={handleChangeInput}/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="vessel type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="vessel_type"
                    placeholder="vessel type"
                     value={datas.vessel_type || ""}
     onChange={handleChangeInput}
                  > 
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>
                   
                </FloatingLabel>
              </Form.Group> 
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Shipment Quantity Tolerance"
                     className="mb-3"
                   >
                     <Form.Control
                       type="number"
                       placeholder="Shipment Quantity Tolerance"
                       name="shipment_quality_tolerance"
                         defaultValue={datas.shipment_quality_tolerance || ""}
        onChange={handleChangeInput}/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6"></Form.Group>
                 <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remark"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Remark"
                    name="remark"
                      defaultValue={datas.remark || ""}
     onChange={handleChangeInput}/>
                </FloatingLabel>
              </Form.Group>
                 </Row>
                 <p className='edit-btn'>
      <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value="Save"
      //disabled={isSubmitted}
    />
      <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
      
      
      </p>
   
         </div>
    ):(<>
      <div className="tasks"><div id="messageContainer"></div>
      <div className="edit-delete">
        <span id="edit" onClick={handleEditClick}> Edit </span>
        <span>
          <Link to={`/opportunity-shipment/create-view-list`}> Cancel</Link>
        </span>
        <span> Clone </span>
      </div>
      <div className="col-md-12" id='ax.account_details'>
        <h4 className="heading">Information</h4>
        <hr>
        </hr>
      </div>
      <table class="table table-bordered account-table tables">
        <tbody>
          <tr>
            <td id="td-right">
              <span>Opp Shipment Name</span>
            </td>
            <td>{datas.opp_ship_code}</td>
            <td id="td-right">
              <span>Opportunity</span>
            </td>
            <td><Link to={`/opportunity/opportunity-detail/${datas.opportunity_id}`}>{datas.opportunity_shipment  } </Link> </td>
          </tr>
          <tr>
            <td id="td-right">
              <span>Currency</span>
            </td>
            <td>{datas.currency}</td>
            <td id="td-right">
              <span>Record Type</span>
            </td>
            <td>{datas.record_type} </td>
          </tr>
          <tr>
            <td id="td-right">
              <span>Exchange Rate</span>
            </td>
            <td> {datas.exchange_rate}</td>
            <td id="td-right">
            </td>
            <td>  </td>
          </tr>
          
          <tr>
          <td id="td-right">
              <span>Status</span>
            </td>
            <td> {datas.status} </td>
            <td id="td-right">
              <span></span>
            </td>
            <td></td>
            
          </tr>
          <tr>
          
          <td id="td-right">
             <span>quantity info</span>
          </td>
          <td>{datas.quantity_info}</td>
          <td id="td-right">
            {/* <span>Status</span> */}
          </td>
          <td></td>
        </tr>
        </tbody>
      </table>
      <div className="col-md-12" id='ax.account_details'>
        <h4 className="heading">Price</h4>
        <hr>
        </hr>
      </div>
      <table class="table table-bordered account-table tables">
        <tbody>
          <tr>
            <td id="td-right">
              <span>Pricing</span>
            </td>
            <td> {datas.pricing}</td>
            <td id="td-right">
              <span>Price</span>
            </td>
             <td> {renderShipmentQty(datas.price)}</td>
          </tr>
          <tr>
            <td id="td-right">
              <span>Price Type</span>
            </td>
            <td> {datas.price_type}</td>
            <td id="td-right">
              <span>Sales price margin</span>
            </td>
             <td> {renderShipmentQty(datas.sales_price_margin)}</td>
          </tr>
          <tr>
            <td id="td-right">
              <span>Price Basis</span>
            </td>
            <td> {datas.price_basis} </td>
            <td id="td-right">
              <span>Shipping Term</span>
            </td>
            <td> {datas.shipping_term}</td>
          </tr>
        </tbody>
      </table>
      <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">Delivery</h4>
     <hr>
     </hr>
   </div>
   <table class="table table-bordered account-table tables">
     <tbody>
       <tr>
       <td id="td-right">
           <span>Laycan</span>
         </td>
         <td> {datas.laycan}</td>
       <td id="td-right">
           <span>laycan year</span>
         </td>
         <td> {datas.laycan_year} </td>
         
         
       </tr>
       <tr>
         <td id="td-right">
           <span>laycan From Date</span>
         </td>
         <td> {datas.laycan_from_date}</td>
         <td id="td-right">
           <span>Laycan Month</span>
         </td>
         <td> {datas.laycan_month} </td>
       </tr>
         
       <tr>
       <td id="td-right">
           <span>Laycan To Date</span>
         </td>
         <td> {datas.laycan_to_date }</td>

         <td id="td-right">
           <span></span>
         </td>
         <td> </td>
       
       </tr>
     </tbody>
   </table>
     <div className="col-md-12" id='ax.account_details'>
       <h4 className="heading">Quantity</h4>
       <hr>
       </hr>
     </div>
     <table class="table table-bordered account-table tables">
       <tbody>
         <tr>
           <td id="td-right">
             <span>Quantity</span>
           </td>
            <td> {renderShipmentQty(datas.quantity)}</td>
           <td id="td-right">
             <span>vessel type</span>
           </td>
           <td> {datas.vessel_type} </td>
         </tr>
         <tr>
           <td id="td-right">
             <span>shipment quantity tolerance</span>
           </td>
           <td> {datas.shipment_quality_tolerance}</td>
           <td id="td-right">
           </td>
           <td></td>
         </tr>

         <tr>
         <td id="td-right">
             <span>remark</span>
           </td>
           <td> {datas.remark} </td>
           <td id="td-right">
           </td>
           <td></td>
          
         </tr>          
       </tbody>
     </table>
      <Row id="table-styles">
        <div className="col-md-12" id="head">
          <h4 className="heading">
            <span>Open Activities</span>
            <span>
              <i className=""></i> New Task </span>
            <span>
              <i className=""></i> New Event </span>
          </h4>
          <hr>
          </hr>
        </div>
        <table id="history" className="">
          <tr>
            <td>
              <p class="no-records"> No records to display </p>
            </td>
          </tr>
        </table>
      </Row>
      
      <Row id="table-styles">
        <div className="col-md-12" id="head">
          <h4 className="heading">
            <span>Notes & Attachments</span>
            <span>
              <i className=""></i> New Note </span>
            <span>
              <i className=""></i> Attach File </span>
          </h4>
          <hr>
          </hr>
        </div>
        <table id="history" className="">
          <tr>
            <td>
              <p class="no-records"> No records to display </p>
            </td>
          </tr>
        </table>
      </Row>
      <Row id="table-styles">
        <div className="col-md-12" id="head">
          <h4 className="heading">
            <span>Opportunity Product</span>
          </h4>
          <hr>
          </hr>
        </div>
        <table id="history" className="">
          <tr>
            <td>
              <p class="no-records"> No records to display </p>
            </td>
          </tr>
        </table>
      </Row>
      <Row id='table-styles'>
       <div className="col-md-12" id='ax.account_details'>
         <h4 className="heading">
           <span>Quote Shipment Information</span>
         </h4>
         <hr>
         </hr>
       </div>
       <table id='shipment' className="table table-bordered">
         <thead>
           <tr>
             <th>Quote Shipment Name	</th>
             <th>Laycan</th>
             <th>Pricing</th>
             <th>Quantity Type	</th>
             <th>Quantity	</th>
             <th>Quantity from	</th>
             <th>Quantity To	</th>
             <th>No of Shipments
             </th>
           </tr>
         </thead> {shipments.length>0 ? <tbody> { shipments.map((y,i)=>( <tr>
             <td>
               <span id='codes'><Link to={`/quote-shipment/detail/${y.id}`}>{y.quote_shipment_code}</Link></span>
             </td>
             <td>{y.quote_shipment_laycan}</td>
             <td>{y.quote_shipment_price}</td>
             <td>{y.quote_shipment_quantity_type}</td>
             <td>{y.quote_shipment_quantity}</td>
             <td>{y.quote_shipment_quantity_from}</td>
             <td>{y.quote_shipment_quantity_to}</td>
             <td>{y.quote_shipment_no_of_shipments}</td>
           </tr> ))} </tbody> : <tbody>
           <tr>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td>No data available</td>
             <td></td>
             <td></td>
             <td></td>
           </tr>
         </tbody> }
       </table>
     </Row>
      <Row id='table-styles'>
        <div className="col-md-12" id='ax.account_details'>
          <h4 className="heading">
            <span>Users Information</span>
          </h4>
          <hr>
          </hr>
        </div>
        <table id='user' className="table table-bordered">
          <thead>
            <tr>
              <th>User Name</th>
              <th>Email</th>
            </tr>
          </thead> {users!=='' ? <tbody>
            <tr>
              <td>
                <span id='codes'>{users.username}</span>
              </td>
              <td>{users.email}</td>
            </tr>
          </tbody> : <tbody>
            <tr>
              <td></td>
              <td>No data available</td>
            </tr>
          </tbody> }
        </table>
      </Row>
      <Row id="table-styles">
                                           <div className="col-md-12" id="head">
                                             <h4 className="heading">
                                               <span>Account History</span>
                                             </h4>
                                             <hr></hr>
                                           </div>
                                           <table id="historys" className="">
                                             <thead>
                                               <tr>
                                                 <th>Field Name</th>
                                                 <th>New Value</th>
                                                 <th>Old Value</th>
                                                 <th>Changed By</th>
                                                 <th>Change Time</th>
                                               </tr>
                                             </thead>
   
                                             {history.length > 0 ? (
                                               <tbody>
                                                 {history.map((y, i) => (
                                                   <tr>
                                                     <td>{y.field_name}</td>
                                                     <td>{y.new_value}</td>
                                                     <td>{y.old_value}</td>
                                                     <td>{y.changed_by}</td>
                                                     <td>{y.change_time}</td>
                                                   </tr>
                                                 ))}
                                               </tbody>
                                             ) : (
                                               <tbody>
                                                 <tr>
                                                   <td></td>
                                                   <td></td>
                                                   <td>No data available</td>
                                                   <td></td>
                                                   <td></td>
                                                 </tr>
                                               </tbody>
                                             )}
                                           </table>
                                         </Row>
    </div> 
    </>)}</>
   ):( <></>)}
  </>)}
  </>)}
  </>
 )}
      </div></div></div>
<p></p><p></p>
      <Footer />
    </div>
  );
};

export default OpportunityShipmentDetail;