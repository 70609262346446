
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header' 
import Footer from '../Footer' 
import $ from 'jquery';
import { API_URL } from '../config';
import axios from "axios";  
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
const EditQuoteProduct = () => {
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
  const params = useParams();
  const id = params.id;
const [shippment, setShippment]=useState([]);  
const [productAccount, setProductaccount]=useState([]);
const [suppliers, setsupplierData]=useState([]);
const [getPrice, setgetPrice]=useState([]);
// price
 useEffect(() => {
   
    fetchDataShipment();
  }, [decryptedAccessToken]);

  const fetchDataShipment = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/quoteshipment', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      
      setShippment(data)
      console.log(data)
      
    } catch (error) {
      console.error(error);
    }
  };
  const [accounts,setAccount]=useState([]);
  useEffect(() => {
  fetchDataEditQuote();
  }, [decryptedAccessToken]);

  const fetchDataEditQuote = async () => {
    try {
      const response = await axios.get(API_URL+'get/quotesbyID/'+id, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const data = response.data.quote_details;
      
      setAccount(data);
setValue('locked', data.locked);
setValue('supplier', data.supplier);
setValue('quotes_status', data.quotes_status);
setValue('quotes_name', data.quotes_name); 
setValue('no_of_product', data.no_of_product);
setValue('no_of_quote_shipment', data.no_of_quote_shipment); 
setValue('quote_shipment', data.quote_shipment);
setValue('product_code', data.product_code);
setValue('product_name', data.product_name);
setValue('product_account', data.product_account);
setValue('product_supplier', data.product_supplier);
setValue('product_description', data.product_description);
setValue('product_family', data.product_family);
setValue('product_shipping_term', data.product_shipping_term);
setValue('product_owner', data.product_owner);
setValue('product_active', data.product_active);
setValue('short_term_available', data.short_term_available);
setValue('product_price_basis', data.product_price_basis);
setValue('product_price_type', data.product_price_type);
setValue('product_port_of_loading', data.product_port_of_loading);
setValue('total_moisture_typical', data.total_moisture_typical);
setValue('total_moisture_minimum', data.total_moisture_minimum);
setValue('total_moisture_maximum', data.total_moisture_maximum);
setValue('ash_typical_arb', data.ash_typical_arb);
setValue('ash_minimum_arb', data.ash_minimum_arb);
setValue('ash_maximum_arb', data.ash_maximum_arb);
setValue('sulphur_typical_arb', data.sulphur_typical_arb);
setValue('sulphur_minimum_arb', data.sulphur_minimum_arb);
setValue('sulphur_maximum_arb', data.sulphur_maximum_arb);
setValue('volatile_matter_typical_arb', data.volatile_matter_typical_arb);
setValue('volatile_matter_minimum_arb', data.volatile_matter_minimum_arb);
setValue('volatile_matter_maximum_arb', data.volatile_matter_maximum_arb);

setValue('moisture_typical', data.moisture_typical);
setValue('moisture_minimum', data.moisture_minimum);
setValue('ash_typical', data.ash_typical);
setValue('ash_minimum', data.ash_minimum);
setValue('ash_maximum', data.ash_maximum);

setValue('volatile_matter_typical', data.volatile_matter_typical);
setValue('volatile_matter_minimum', data.volatile_matter_minimum);
setValue('volatile_matter_maximum', data.volatile_matter_maximum);
setValue('fixed_carbon_by_difference', data.fixed_carbon_by_difference);
setValue('fixed_carbon_typical', data.fixed_carbon_typical);
setValue('fixed_carbon_minimum', data.fixed_carbon_minimum);
setValue('fixed_carbon_maximum', data.fixed_carbon_maximum);

setValue('gross_air_dried_typical', data.gross_air_dried_typical);
setValue('gross_air_dried_min', data.gross_air_dried_min);
setValue('gross_air_dried_max', data.gross_air_dried_max);
setValue('gross_as_recieved_min', data.gross_as_recieved_min);
setValue('gross_as_recieved_max', data.gross_as_recieved_max);
setValue('net_as_recieved_min', data.net_as_recieved_min);
setValue('net_as_recieved_max', data.net_as_recieved_max);

setValue('hgi_typical', data.hgi_typical);
setValue('hgi_min', data.hgi_min);
setValue('hgi_max', data.hgi_max);

setValue('carbon_typical', data.carbon_typical);
setValue('carbon_min', data.carbon_min);
setValue('carbon_max', data.carbon_max);

setValue('hydrogen_typical', data.carbon_max);
setValue('hydrogen_min', data.hydrogen_min);
setValue('hydrogen_max', data.hydrogen_max);

setValue('nitrogen_typical', data.nitrogen_typical);
setValue('nitrogen_min', data.nitrogen_min);
setValue('nitrogen_max', data.nitrogen_max);

setValue('sulphur_typical', data.sulphur_typical);
setValue('sulphur_min', data.sulphur_min);
setValue('sulphur_max_ultimate_analysis', data.sulphur_max_ultimate_analysis);
setValue('oxygen_typical', data.oxygen_typical);
setValue('oxygen_min', data.oxygen_min);
setValue('oxygen_max', data.oxygen_max);

setValue('sulphur_typical_adb', data.sulphur_typical_adb);
setValue('sulphur_min_adb', data.sulphur_min_adb);
setValue('product_sulphur_max', data.product_sulphur_max);

setValue('initial_typical', data.initial_typical);
setValue('initial_minimum', data.initial_minimum);
setValue('initial_maximum', data.initial_maximum);
setValue('spherical_typical', data.spherical_typical);
setValue('spherical_minimum', data.spherical_minimum);
setValue('spherical_maximum', data.spherical_maximum);
setValue('hemispherical_typical', data.hemispherical_typical);
setValue('hemispherical_minimum', data.hemispherical_minimum);
setValue('hemispherical_maximum', data.hemispherical_maximum);
setValue('flow_typical', data.flow_typical);
setValue('flow_minimum', data.flow_minimum);
setValue('flow_maximum', data.flow_maximum);

setValue('oxidising_initial_typical', data.oxidising_initial_typical);
setValue('oxidising_initial_minimum', data.oxidising_initial_minimum);
setValue('oxidising_initial_maximum', data.oxidising_initial_maximum);
setValue('oxidising_spherical_typical', data.oxidising_spherical_typical);
setValue('oxidising_spherical_minimum', data.oxidising_spherical_minimum);
setValue('oxidising_spherical_maximum', data.oxidising_spherical_maximum);
setValue('oxidising_hemispherical_typical', data.oxidising_hemispherical_typical);
setValue('oxidising_hemispherical_minimum', data.oxidising_hemispherical_minimum);
setValue('oxidising_hemispherical_maximum', data.oxidising_hemispherical_maximum);
setValue('oxidising_flow_typical', data.oxidising_flow_typical);
setValue('oxidising_flow_minimum', data.oxidising_flow_typical);
setValue('oxidising_flow_maximum', data.oxidising_flow_maximum);

setValue('SiO2_Typical', data.SiO2_Typical);
setValue('SiO2_Minimum', data.SiO2_Minimum);
setValue('SiO2_Maximum', data.SiO2_Maximum);
setValue('Al2O3_Typical', data.Al2O3_Typical);
setValue('Al2O3_Minimum', data.Al2O3_Minimum);
setValue('Al2O3_Maximum', data.Al2O3_Maximum);
setValue('Fe2O3_Typical', data.Fe2O3_Typical);
setValue('Fe2O3_Minimum', data.Fe2O3_Minimum);
setValue('Fe2O3_Maximum', data.Fe2O3_Maximum);
setValue('CaO_Typical', data.CaO_Typical);
setValue('CaO_Minimum', data.CaO_Minimum);
setValue('CaO_Maximum', data.CaO_Maximum);
setValue('MgO_Typical', data.MgO_Typical);
setValue('MgO_Minimum', data.MgO_Minimum);
setValue('MgO_Maximum', data.MgO_Maximum);
setValue('MnO_Typical', data.MnO_Typical);
setValue('MnO_Minimum', data.MnO_Minimum);
setValue('MnO_Maximum', data.MnO_Maximum);
setValue('TiO2_Typical', data.TiO2_Typical);
setValue('TiO2_Minimum', data.TiO2_Minimum);
setValue('TiO2_Maximum', data.TiO2_Maximum);
setValue('Na2O_Typical', data.Na2O_Typical);
setValue('Na2O_Minimum', data.Na2O_Minimum);
setValue('Na2O_Maximum', data.Na2O_Maximum);
setValue('K2O_Typical', data.K2O_Typical);
setValue('K2O_Minimum', data.K2O_Minimum);
setValue('K2O_Maximum', data.K2O_Maximum);
setValue('P2O5_Typical', data.P2O5_Typical);
setValue('P2O5_Minimum', data.P2O5_Minimum);
setValue('P2O5_Maximum', data.P2O5_Maximum);
setValue('SO3_Typical', data.SO3_Typical);
setValue('SO3_Minimum', data.SO3_Minimum);
setValue('SO3_Maximum', data.SO3_Maximum);
setValue('te_arsenic_as_typical_in_air_dried_coal', data.te_arsenic_as_typical_in_air_dried_coal);
setValue('te_arsenic_as_minimum_in_air_dried_coal', data.te_arsenic_as_minimum_in_air_dried_coal);
setValue('te_arsenic_as_maximum_in_air_dried_coal', data.te_arsenic_as_maximum_in_air_dried_coal);
setValue('te_antimony_sb_typical_in_air_dried_coal', data.te_antimony_sb_typical_in_air_dried_coal);
setValue('te_antimony_sb_minimum_in_air_dried_coal', data.te_antimony_sb_minimum_in_air_dried_coal);
setValue('te_antimony_sb_maximum_in_air_dried_coal', data.te_antimony_sb_maximum_in_air_dried_coal);
setValue('te_beryllium_be_typical_in_air_dried_coal', data.te_beryllium_be_typical_in_air_dried_coal);
setValue('te_beryllium_be_minimum_in_air_dried_coal', data.te_beryllium_be_minimum_in_air_dried_coal);
setValue('te_beryllium_be_maximum_in_air_dried_coal', data.te_beryllium_be_maximum_in_air_dried_coal);
setValue('te_boron_b_typical_in_air_dried_coal', data.te_boron_b_typical_in_air_dried_coal);
setValue('te_boron_b_minimum_in_air_dried_coal', data.te_boron_b_minimum_in_air_dried_coal);
setValue('te_boron_b_maximum_in_air_dried_coal', data.te_boron_b_maximum_in_air_dried_coal);
setValue('te_cadmium_cd_typical_in_air_dried_coal', data.te_cadmium_cd_typical_in_air_dried_coal);
setValue('te_cadmium_cd_minimum_in_air_dried_coal', data.te_cadmium_cd_minimum_in_air_dried_coal);
setValue('te_cadmium_cd_maximum_in_air_dried_coal', data.te_cadmium_cd_maximum_in_air_dried_coal);
setValue('te_chromium_cr_typical_in_air_dried_coal', data.te_chromium_cr_typical_in_air_dried_coal);
setValue('te_chromium_cr_minimum_in_air_dried_coal', data.te_chromium_cr_minimum_in_air_dried_coal);
setValue('te_chromium_cr_maximum_in_air_dried_coal', data.te_chromium_cr_maximum_in_air_dried_coal);
setValue('te_copper_cu_typical_in_air_dried_coal', data.te_copper_cu_typical_in_air_dried_coal);
setValue('te_copper_cu_minimum_in_air_dried_coal', data.te_copper_cu_minimum_in_air_dried_coal);
setValue('te_copper_cu_maximum_in_air_dried_coal', data.te_copper_cu_maximum_in_air_dried_coal);
setValue('te_chlorine_cl_typical_in_air_dried_coal', data.te_chlorine_cl_typical_in_air_dried_coal);
setValue('te_chlorine_cl_minimum_in_air_dried_coal', data.te_chlorine_cl_minimum_in_air_dried_coal);
setValue('te_chlorine_cl_maximum_in_air_dried_coal', data.te_chlorine_cl_maximum_in_air_dried_coal);
setValue('te_fluorine_f_typical_in_air_dried_coal', data.te_fluorine_f_typical_in_air_dried_coal);
setValue('te_fluorine_f_minimum_in_air_dried_coal', data.te_fluorine_f_minimum_in_air_dried_coal);
setValue('te_fluorine_f_maximum_in_air_dried_coal', data.te_fluorine_f_maximum_in_air_dried_coal);
setValue('te_mercury_hg_typical_in_air_dried_coal', data.te_mercury_hg_typical_in_air_dried_coal);
setValue('te_mercury_hg_minimum_in_air_dried_coal', data.te_mercury_hg_minimum_in_air_dried_coal);
setValue('te_mercury_hg_maximum_in_air_dried_coal', data.te_mercury_hg_maximum_in_air_dried_coal);
setValue('te_molybdenum_mo_typical_in_air_dried_coal', data.te_molybdenum_mo_typical_in_air_dried_coal);
setValue('te_molybdenum_mo_minimum_in_air_dried_coal', data.te_molybdenum_mo_minimum_in_air_dried_coal);
setValue('te_molybdenum_mo_maximum_in_air_dried_coal', data.te_molybdenum_mo_maximum_in_air_dried_coal);
setValue('te_lead_pb_typical_in_air_dried_coal', data.te_lead_pb_typical_in_air_dried_coal);
setValue('te_lead_pb_minimum_in_air_dried_coal', data.te_lead_pb_minimum_in_air_dried_coal);
setValue('te_lead_pb_maximum_in_air_dried_coal', data.te_lead_pb_maximum_in_air_dried_coal);
setValue('te_phosphorus_p_typical_in_air_dried_coal', data.te_phosphorus_p_typical_in_air_dried_coal);
setValue('te_phosphorus_p_minimum_in_air_dried_coal', data.te_phosphorus_p_minimum_in_air_dried_coal);
setValue('te_phosphorus_p_maximum_in_air_dried_coal', data.te_phosphorus_p_maximum_in_air_dried_coal);
setValue('te_selenium_se_typical_in_air_dried_coal', data.te_selenium_se_typical_in_air_dried_coal);
setValue('te_selenium_se_minimum_in_air_dried_coal', data.te_selenium_se_minimum_in_air_dried_coal);
setValue('te_selenium_se_maximum_in_air_dried_coal', data.te_selenium_se_maximum_in_air_dried_coal);
setValue('te_vanadium_v_typical_in_air_dried_coal', data.te_vanadium_v_typical_in_air_dried_coal);
setValue('te_vanadium_v_minimum_in_air_dried_coal', data.te_vanadium_v_minimum_in_air_dried_coal);
setValue('te_vanadium_v_maximum_in_air_dried_coal', data.te_vanadium_v_maximum_in_air_dried_coal);
setValue('te_zinc_zn_typical_in_air_dried_coal', data.te_zinc_zn_typical_in_air_dried_coal);
setValue('te_zinc_zn_minimum_in_air_dried_coal', data.te_zinc_zn_minimum_in_air_dried_coal);
setValue('te_zinc_zn_maximum_in_air_dried_coal', data.te_zinc_zn_maximum_in_air_dried_coal);
 
setValue('te_arsenic_as_typical', data.te_arsenic_as_typical);
setValue('te_arsenic_as_minimum', data.te_arsenic_as_minimum);
setValue('te_arsenic_as_maximum', data.te_arsenic_as_maximum);
setValue('te_antimony_sb_typical', data.te_antimony_sb_typical);
setValue('te_antimony_sb_minimum', data.te_antimony_sb_minimum);
setValue('te_antimony_sb_maximum', data.te_antimony_sb_maximum);
setValue('te_boron_b_typical', data.te_boron_b_typical);
setValue('te_boron_b_minimum', data.te_boron_b_minimum);
setValue('te_boron_b_maximum', data.te_boron_b_maximum);
setValue('te_cadmium_cd_typical', data.te_cadmium_cd_typical);
setValue('te_cadmium_cd_minimum', data.te_cadmium_cd_minimum);
setValue('te_cadmium_cd_maximum', data.te_cadmium_cd_maximum);
setValue('te_chromium_cr_typical', data.te_chromium_cr_typical);
setValue('te_chromium_cr_minimum', data.te_chromium_cr_minimum);
setValue('te_chromium_cr_maximum', data.te_chromium_cr_maximum);
setValue('te_copper_cu_typical', data.te_copper_cu_typical);
setValue('te_copper_cu_minimum', data.te_copper_cu_minimum);
setValue('te_copper_cu_maximum', data.te_copper_cu_maximum);
setValue('te_chlorine_cl_typical', data.te_chlorine_cl_typical);
setValue('te_chlorine_cl_minimum', data.te_chlorine_cl_minimum);
setValue('te_chlorine_cl_maximum', data.te_chlorine_cl_maximum);
setValue('te_fluorine_f_typical', data.te_fluorine_f_typical);
setValue('te_fluorine_f_minimum', data.te_fluorine_f_minimum);
setValue('te_fluorine_f_maximum', data.te_fluorine_f_maximum);
setValue('te_mercury_hg_typical', data.te_mercury_hg_typical);
setValue('te_mercury_hg_minimum', data.te_mercury_hg_minimum);
setValue('te_mercury_hg_maximum', data.te_mercury_hg_maximum);
setValue('te_molybdenum_mo_typical', data.te_molybdenum_mo_typical);
setValue('te_molybdenum_mo_minimum', data.te_molybdenum_mo_minimum);
setValue('te_molybdenum_mo_maximum', data.te_molybdenum_mo_maximum);
setValue('te_lead_pb_typical', data.te_lead_pb_typical);
setValue('te_lead_pb_minimum', data.te_lead_pb_minimum);
setValue('te_lead_pb_maximum', data.te_lead_pb_maximum);
setValue('te_phosphorus_p_typical', data.te_phosphorus_p_typical);
setValue('te_phosphorus_p_minimum', data.te_phosphorus_p_minimum);
setValue('te_phosphorus_p_maximum', data.te_phosphorus_p_maximum);
setValue('te_selenium_se_typical', data.te_selenium_se_typical);
setValue('te_selenium_se_minimum', data.te_selenium_se_minimum);
setValue('te_selenium_se_maximum', data.te_selenium_se_maximum);
setValue('te_vanadium_v_typical', data.te_vanadium_v_typical);
setValue('te_vanadium_v_min', data.te_vanadium_v_min);
setValue('te_vanadium_v_max', data.te_vanadium_v_max);
setValue('te_zinc_zn_typical', data.te_zinc_zn_typical);
setValue('te_zinc_zn_min', data.te_zinc_zn_min);
setValue('te_zinc_zn_max', data.te_zinc_zn_max);
 
setValue('size_0_to_50mm_typical', data.size_0_to_50mm_typical);
setValue('size_0_to_50mm_min', data.size_0_to_50mm_min);
setValue('size_0_to_50mm_max', data.size_0_to_50mm_max);
setValue('above_0_to_50mm_typical', data.above_0_to_50mm_typical);
setValue('above_0_to_50mm_min', data.above_0_to_50mm_min);
setValue('above_0_to_50mm_max', data.above_0_to_50mm_max);
setValue('under_2mm_typical', data.under_2mm_typical);
setValue('under_2mm_min', data.under_2mm_typical);
setValue('under_2mm_max', data.under_2mm_max);
setValue('size_above_2mm_typical', data.size_above_2mm_typical);
setValue('size_above_2mm_min', data.size_above_2mm_min);
setValue('size_above_2mm_max', data.size_above_2mm_max);
 
setValue('testing_method', data.testing_method);
    } catch (error) {
      console.error(error);
    }
  };
  

useEffect(() => {
  fetchDataPrice();
}, [decryptedAccessToken]);

const fetchDataPrice = async () => {
  try {
    const response = await axios.get(API_URL+'get/all/price_items', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data.priceitem;
    console.log(data)
    setgetPrice(data);
     
  } catch (error) {
    console.error(error);
  }
};
// product supplier
useEffect(() => {   
  fetchDataSupplier();
}, [decryptedAccessToken]);

const fetchDataSupplier = async () => {
  try {
    const response = await axios.get(API_URL+'get/supplier', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data;
    // console.log(data)
    setsupplierData(data);
     
  } catch (error) {
    console.error(error);
  }
};

  // product account
  useEffect(() => {   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'all/accounts', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.accounts; 
      // console.log(data)
      setProductaccount(data);
       
    } catch (error) {
      console.error(error); 
    }
  };

  
  const onSubmit = data =>{ 
    const responseData={ 
        locked:data.locked,
supplier:data.supplier,
quotes_status:data.quotes_status,
quotes_name:data.quotes_name, 
no_of_product:data.no_of_product,
no_of_quote_shipment:data.no_of_quote_shipment, 
quote_shipment:data.quote_shipment,
product_code:data.product_code,
product_name:data.product_name,
product_account:data.product_account,
product_supplier:data.product_supplier,
product_description:data.product_description,
product_family:data.product_family,
product_shipping_term:data.product_shipping_term,
product_owner:data.product_owner,
product_active:data.product_active,
short_term_available:data.short_term_available,
product_price_basis:data.product_price_basis,
product_price_type:data.product_price_type,
product_port_of_loading:data.product_port_of_loading,
total_moisture_typical:data.total_moisture_typical,
total_moisture_minimum:data.total_moisture_minimum,
total_moisture_maximum:data.total_moisture_maximum,
ash_typical_arb:data.ash_typical_arb,
ash_minimum_arb:data.ash_minimum_arb,
ash_maximum_arb:data.ash_maximum_arb,
sulphur_typical_arb:data.sulphur_typical_arb,
sulphur_minimum_arb:data.sulphur_minimum_arb,
sulphur_maximum_arb:data.sulphur_maximum_arb,
volatile_matter_typical_arb:data.volatile_matter_typical_arb,
volatile_matter_minimum_arb:data.volatile_matter_minimum_arb,
volatile_matter_maximum_arb:data.volatile_matter_maximum_arb,
 
moisture_typical:data.moisture_typical,
moisture_minimum:data.moisture_minimum,
ash_typical:data.ash_typical,
ash_minimum:data.ash_minimum,
ash_maximum:data.ash_maximum,
 
volatile_matter_typical:data.volatile_matter_typical,
volatile_matter_minimum:data.volatile_matter_minimum,
volatile_matter_maximum:data.volatile_matter_maximum,
fixed_carbon_by_difference:data.fixed_carbon_by_difference,
fixed_carbon_typical:data.fixed_carbon_typical,
fixed_carbon_minimum:data.fixed_carbon_minimum,
fixed_carbon_maximum:data.fixed_carbon_maximum,
 
gross_air_dried_typical:data.gross_air_dried_typical,
gross_air_dried_min:data.gross_air_dried_min,
gross_air_dried_max:data.gross_air_dried_max,
gross_as_recieved_min:data.gross_as_recieved_min,
gross_as_recieved_max:data.gross_as_recieved_max,
net_as_recieved_min:data.net_as_recieved_min,
net_as_recieved_max:data.net_as_recieved_max,
 
hgi_typical:data.hgi_typical,
hgi_min:data.hgi_min,
hgi_max:data.hgi_max,
 
carbon_typical:data.carbon_typical,
carbon_min:data.carbon_min,
carbon_max:data.carbon_max,
 
hydrogen_typical:data.carbon_max,
hydrogen_min:data.hydrogen_min,
hydrogen_max:data.hydrogen_max,
 
nitrogen_typical:data.nitrogen_typical,
nitrogen_min:data.nitrogen_min,
nitrogen_max:data.nitrogen_max,
 
sulphur_typical:data.sulphur_typical,
sulphur_min:data.sulphur_min,
sulphur_max_ultimate_analysis:data.sulphur_max_ultimate_analysis,
oxygen_typical:data.oxygen_typical,
oxygen_min:data.oxygen_min,
oxygen_max:data.oxygen_max,
 
sulphur_typical_adb:data.sulphur_typical_adb,
sulphur_min_adb:data.sulphur_min_adb,
product_sulphur_max:data.product_sulphur_max,
 
initial_typical:data.initial_typical,
initial_minimum:data.initial_minimum,
initial_maximum:data.initial_maximum,
spherical_typical:data.spherical_typical,
spherical_minimum:data.spherical_minimum,
spherical_maximum:data.spherical_maximum,
hemispherical_typical:data.hemispherical_typical,
hemispherical_minimum:data.hemispherical_minimum,
hemispherical_maximum:data.hemispherical_maximum,
flow_typical:data.flow_typical,
flow_minimum:data.flow_minimum,
flow_maximum:data.flow_maximum,
 
oxidising_initial_typical:data.oxidising_initial_typical,
oxidising_initial_minimum:data.oxidising_initial_minimum,
oxidising_initial_maximum:data.oxidising_initial_maximum,
oxidising_spherical_typical:data.oxidising_spherical_typical,
oxidising_spherical_minimum:data.oxidising_spherical_minimum,
oxidising_spherical_maximum:data.oxidising_spherical_maximum,
oxidising_hemispherical_typical:data.oxidising_hemispherical_typical,
oxidising_hemispherical_minimum:data.oxidising_hemispherical_minimum,
oxidising_hemispherical_maximum:data.oxidising_hemispherical_maximum,
oxidising_flow_typical:data.oxidising_flow_typical,
oxidising_flow_minimum:data.oxidising_flow_typical,
oxidising_flow_maximum:data.oxidising_flow_maximum,
 
SiO2_Typical:data.SiO2_Typical,
SiO2_Minimum:data.SiO2_Minimum,
SiO2_Maximum:data.SiO2_Maximum,
Al2O3_Typical:data.Al2O3_Typical,
Al2O3_Minimum:data.Al2O3_Minimum,
Al2O3_Maximum:data.Al2O3_Maximum,
Fe2O3_Typical:data.Fe2O3_Typical,
Fe2O3_Minimum:data.Fe2O3_Minimum,
Fe2O3_Maximum:data.Fe2O3_Maximum,
CaO_Typical:data.CaO_Typical,
CaO_Minimum:data.CaO_Minimum,
CaO_Maximum:data.CaO_Maximum,
MgO_Typical:data.MgO_Typical,
MgO_Minimum:data.MgO_Minimum,
MgO_Maximum:data.MgO_Maximum,
MnO_Typical:data.MnO_Typical,
MnO_Minimum:data.MnO_Minimum,
MnO_Maximum:data.MnO_Maximum,
TiO2_Typical:data.TiO2_Typical,
TiO2_Minimum:data.TiO2_Minimum,
TiO2_Maximum:data.TiO2_Maximum,
Na2O_Typical:data.Na2O_Typical,
Na2O_Minimum:data.Na2O_Minimum,
Na2O_Maximum:data.Na2O_Maximum,
K2O_Typical:data.K2O_Typical,
K2O_Minimum:data.K2O_Minimum,
K2O_Maximum:data.K2O_Maximum,
P2O5_Typical:data.P2O5_Typical,
P2O5_Minimum:data.P2O5_Minimum,
P2O5_Maximum:data.P2O5_Maximum,
SO3_Typical:data.SO3_Typical,
SO3_Minimum:data.SO3_Minimum,
SO3_Maximum:data.SO3_Maximum,
te_arsenic_as_typical_in_air_dried_coal:data.te_arsenic_as_typical_in_air_dried_coal,
te_arsenic_as_minimum_in_air_dried_coal:data.te_arsenic_as_minimum_in_air_dried_coal,
te_arsenic_as_maximum_in_air_dried_coal:data.te_arsenic_as_maximum_in_air_dried_coal,
te_antimony_sb_typical_in_air_dried_coal:data.te_antimony_sb_typical_in_air_dried_coal,
te_antimony_sb_minimum_in_air_dried_coal:data.te_antimony_sb_minimum_in_air_dried_coal,
te_antimony_sb_maximum_in_air_dried_coal:data.te_antimony_sb_maximum_in_air_dried_coal,
te_beryllium_be_typical_in_air_dried_coal:data.te_beryllium_be_typical_in_air_dried_coal,
te_beryllium_be_minimum_in_air_dried_coal:data.te_beryllium_be_minimum_in_air_dried_coal,
te_beryllium_be_maximum_in_air_dried_coal:data.te_beryllium_be_maximum_in_air_dried_coal,
te_boron_b_typical_in_air_dried_coal:data.te_boron_b_typical_in_air_dried_coal,
te_boron_b_minimum_in_air_dried_coal:data.te_boron_b_minimum_in_air_dried_coal,
te_boron_b_maximum_in_air_dried_coal:data.te_boron_b_maximum_in_air_dried_coal,
te_cadmium_cd_typical_in_air_dried_coal:data.te_cadmium_cd_typical_in_air_dried_coal,
te_cadmium_cd_minimum_in_air_dried_coal:data.te_cadmium_cd_minimum_in_air_dried_coal,
te_cadmium_cd_maximum_in_air_dried_coal:data.te_cadmium_cd_maximum_in_air_dried_coal,
te_chromium_cr_typical_in_air_dried_coal:data.te_chromium_cr_typical_in_air_dried_coal,
te_chromium_cr_minimum_in_air_dried_coal:data.te_chromium_cr_minimum_in_air_dried_coal,
te_chromium_cr_maximum_in_air_dried_coal:data.te_chromium_cr_maximum_in_air_dried_coal,
te_copper_cu_typical_in_air_dried_coal:data.te_copper_cu_typical_in_air_dried_coal,
te_copper_cu_minimum_in_air_dried_coal:data.te_copper_cu_minimum_in_air_dried_coal,
te_copper_cu_maximum_in_air_dried_coal:data.te_copper_cu_maximum_in_air_dried_coal,
te_chlorine_cl_typical_in_air_dried_coal:data.te_chlorine_cl_typical_in_air_dried_coal,
te_chlorine_cl_minimum_in_air_dried_coal:data.te_chlorine_cl_minimum_in_air_dried_coal,
te_chlorine_cl_maximum_in_air_dried_coal:data.te_chlorine_cl_maximum_in_air_dried_coal,
te_fluorine_f_typical_in_air_dried_coal:data.te_fluorine_f_typical_in_air_dried_coal,
te_fluorine_f_minimum_in_air_dried_coal:data.te_fluorine_f_minimum_in_air_dried_coal,
te_fluorine_f_maximum_in_air_dried_coal:data.te_fluorine_f_maximum_in_air_dried_coal,
te_mercury_hg_typical_in_air_dried_coal:data.te_mercury_hg_typical_in_air_dried_coal,
te_mercury_hg_minimum_in_air_dried_coal:data.te_mercury_hg_minimum_in_air_dried_coal,
te_mercury_hg_maximum_in_air_dried_coal:data.te_mercury_hg_maximum_in_air_dried_coal,
te_molybdenum_mo_typical_in_air_dried_coal:data.te_molybdenum_mo_typical_in_air_dried_coal,
te_molybdenum_mo_minimum_in_air_dried_coal:data.te_molybdenum_mo_minimum_in_air_dried_coal,
te_molybdenum_mo_maximum_in_air_dried_coal:data.te_molybdenum_mo_maximum_in_air_dried_coal,
te_lead_pb_typical_in_air_dried_coal:data.te_lead_pb_typical_in_air_dried_coal,
te_lead_pb_minimum_in_air_dried_coal:data.te_lead_pb_minimum_in_air_dried_coal,
te_lead_pb_maximum_in_air_dried_coal:data.te_lead_pb_maximum_in_air_dried_coal,
te_phosphorus_p_typical_in_air_dried_coal:data.te_phosphorus_p_typical_in_air_dried_coal,
te_phosphorus_p_minimum_in_air_dried_coal:data.te_phosphorus_p_minimum_in_air_dried_coal,
te_phosphorus_p_maximum_in_air_dried_coal:data.te_phosphorus_p_maximum_in_air_dried_coal,
te_selenium_se_typical_in_air_dried_coal:data.te_selenium_se_typical_in_air_dried_coal,
te_selenium_se_minimum_in_air_dried_coal:data.te_selenium_se_minimum_in_air_dried_coal,
te_selenium_se_maximum_in_air_dried_coal:data.te_selenium_se_maximum_in_air_dried_coal,
te_vanadium_v_typical_in_air_dried_coal:data.te_vanadium_v_typical_in_air_dried_coal,
te_vanadium_v_minimum_in_air_dried_coal:data.te_vanadium_v_minimum_in_air_dried_coal,
te_vanadium_v_maximum_in_air_dried_coal:data.te_vanadium_v_maximum_in_air_dried_coal,
te_zinc_zn_typical_in_air_dried_coal:data.te_zinc_zn_typical_in_air_dried_coal,
te_zinc_zn_minimum_in_air_dried_coal:data.te_zinc_zn_minimum_in_air_dried_coal,
te_zinc_zn_maximum_in_air_dried_coal:data.te_zinc_zn_maximum_in_air_dried_coal,
 
te_arsenic_as_typical:data.te_arsenic_as_typical,
te_arsenic_as_minimum:data.te_arsenic_as_minimum,
te_arsenic_as_maximum:data.te_arsenic_as_maximum,
te_antimony_sb_typical:data.te_antimony_sb_typical,
te_antimony_sb_minimum:data.te_antimony_sb_minimum,
te_antimony_sb_maximum:data.te_antimony_sb_maximum,
te_boron_b_typical:data.te_boron_b_typical,
te_boron_b_minimum:data.te_boron_b_minimum,
te_boron_b_maximum:data.te_boron_b_maximum,
te_cadmium_cd_typical:data.te_cadmium_cd_typical,
te_cadmium_cd_minimum:data.te_cadmium_cd_minimum,
te_cadmium_cd_maximum:data.te_cadmium_cd_maximum,
te_chromium_cr_typical:data.te_chromium_cr_typical,
te_chromium_cr_minimum:data.te_chromium_cr_minimum,
te_chromium_cr_maximum:data.te_chromium_cr_maximum,
te_copper_cu_typical:data.te_copper_cu_typical,
te_copper_cu_minimum:data.te_copper_cu_minimum,
te_copper_cu_maximum:data.te_copper_cu_maximum,
te_chlorine_cl_typical:data.te_chlorine_cl_typical,
te_chlorine_cl_minimum:data.te_chlorine_cl_minimum,
te_chlorine_cl_maximum:data.te_chlorine_cl_maximum,
te_fluorine_f_typical:data.te_fluorine_f_typical,
te_fluorine_f_minimum:data.te_fluorine_f_minimum,
te_fluorine_f_maximum:data.te_fluorine_f_maximum,
te_mercury_hg_typical:data.te_mercury_hg_typical,
te_mercury_hg_minimum:data.te_mercury_hg_minimum,
te_mercury_hg_maximum:data.te_mercury_hg_maximum,
te_molybdenum_mo_typical:data.te_molybdenum_mo_typical,
te_molybdenum_mo_minimum:data.te_molybdenum_mo_minimum,
te_molybdenum_mo_maximum:data.te_molybdenum_mo_maximum,
te_lead_pb_typical:data.te_lead_pb_typical,
te_lead_pb_minimum:data.te_lead_pb_minimum,
te_lead_pb_maximum:data.te_lead_pb_maximum,
te_phosphorus_p_typical:data.te_phosphorus_p_typical,
te_phosphorus_p_minimum:data.te_phosphorus_p_minimum,
te_phosphorus_p_maximum:data.te_phosphorus_p_maximum,
te_selenium_se_typical:data.te_selenium_se_typical,
te_selenium_se_minimum:data.te_selenium_se_minimum,
te_selenium_se_maximum:data.te_selenium_se_maximum,
te_vanadium_v_typical:data.te_vanadium_v_typical,
te_vanadium_v_min:data.te_vanadium_v_min,
te_vanadium_v_max:data.te_vanadium_v_max,
te_zinc_zn_typical:data.te_zinc_zn_typical,
te_zinc_zn_min:data.te_zinc_zn_min,
te_zinc_zn_max:data.te_zinc_zn_max,
 
size_0_to_50mm_typical:data.size_0_to_50mm_typical,
size_0_to_50mm_min:data.size_0_to_50mm_min,
size_0_to_50mm_max:data.size_0_to_50mm_max,
above_0_to_50mm_typical:data.above_0_to_50mm_typical,
above_0_to_50mm_min:data.above_0_to_50mm_min,
above_0_to_50mm_max:data.above_0_to_50mm_max,
under_2mm_typical:data.under_2mm_typical,
under_2mm_min:data.under_2mm_typical,
under_2mm_max:data.under_2mm_max,
size_above_2mm_typical:data.size_above_2mm_typical,
size_above_2mm_min:data.size_above_2mm_min,
size_above_2mm_max:data.size_above_2mm_max,
 
testing_method:data.testing_method

    
    
  }; 
    console.log(JSON.stringify(responseData))
    const encryptedAccessToken = localStorage.getItem('access_token');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
        
      fetch(API_URL+'update_quotes/'+id, {
                method: "PUT",
                body: JSON.stringify(responseData),
                headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                'Content-Type': 'application/json' },
              })
                .then((response) => response.json())
                
                .then((response) => {  
                  console.log(response)
                  toast.success('Quote Update Successfully', {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                  setTimeout(() => { 
                    reset();
                  }, 300); 
               
                })
              
              } 
               
      };
      const [check, setCheck] = useState(false);
      const [active, setActive] = useState(false);
      const [short, setShort] = useState(false); 
      const handleChange = (e) => {
          const { name, value } = e.target;
          setAccount((prevData) => ({
            ...prevData,
            [name]: value
          }));
        };
    return (
<>
<Header/>
<div className="contactAccount"  id='product-master'>
      <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Quote Product </h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Quote Product / Add Quote Product</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
      </div>
    </div> 
  </div> 
      <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
        <h4 className='heading'>Information</h4> 
        {/* <h4 className='heading'>Account Information</h4> */}
          <Form.Group as={Col} md="6"> 
            <input type='checkbox' name='locked' onChange={(e) => setCheck(e.target.checked) } />Locked
          </Form.Group>    
        
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Quotes Status"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Quotes Status"
              name="quotes_status"
              defaultValue={accounts.quotes_status || ""}
onChange={handleChange} {...register("quotes_status", { required: false })}
            />
            {errors.quotes_status && <span>Required Product Code</span>}
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Quotes Name "
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Quotes Name "
              name="quotes_name"
              defaultValue={accounts.quotes_name || ""}
onChange={handleChange} {...register("quotes_name", { required: false })}
            />
            {errors.quotes_name && <span>Required Product Code</span>}
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="No of Product"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="No of Product"
              name="no_of_product"
              defaultValue={accounts.no_of_product || ""}
onChange={handleChange} {...register("no_of_product", {valueAsNumber:true, required: false })}
            />
            {errors.no_of_product && <span>Required Product Code</span>}
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="No of Quote Shipment "
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="No of Quote Shipment "
              name="no_of_quote_shipment"
              defaultValue={accounts.no_of_quote_shipment || ""}
onChange={handleChange} {...register("no_of_quote_shipment", {valueAsNumber:true, required: false })}
            />
            {errors.no_of_quote_shipment && <span>Required Product Code</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Quote Shipment">
    <Form.Select aria-label="Floating label select example"
    defaultValue={accounts.quote_shipment || ""}
onChange={handleChange} 
    name="quote_shipment" 
    >
      <option value='' >Select</option>
       {
          shippment.map((x)=>{
              return(
                <option key={x.id} value={x.quote_shipment_code}>
                {x.quote_shipment_code}
              </option>
              )
          })
       }
    </Form.Select> 
  </FloatingLabel>
              </Form.Group> 
       
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product code"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Product Code"
              name="product_code"
              defaultValue={accounts.product_code || ""}
onChange={handleChange} {...register("product_code", { required: false })}
            />
            {errors.product_code && <span>Required Product Code</span>}
          </FloatingLabel>
        </Form.Group>
        

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Product Name"
              name="product_name"
              defaultValue={accounts.product_name || ""}
            onChange={handleChange}
 {...register("product_name", { required: false })}
            />
             {errors.product_name && <span>Required Product Name</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Product account">
    <Form.Select aria-label="Floating label select example"
    name="product_account" 
    placeholder="Product account" 
    defaultValue={accounts.product_account || ""}
onChange={handleChange} {...register("product_account", { required: false })}
    >

<option value='' >Select </option>
         {
            productAccount.map((x)=>{
                return(
                    <option value={x.account_name}>{x.account_name}</option>
                )
            })
         }
      
        
    </Form.Select>
    {errors.product_account && <span>Required</span>}
  </FloatingLabel>
              </Form.Group> 
       
         <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Supplier">
    <Form.Select aria-label="Floating label select example"
    name="product_supplier" 
    placeholder="Product Supplier" 
    defaultValue=""
    defaultValue={accounts.product_supplier || ""}
onChange={handleChange} {...register("product_supplier", { required: false })}
    >

<option value='' >Select </option>
         {
            productAccount.map((x)=>{
                return(
                    <option value={x.account_name}>{x.account_name}</option>
                )
            })
         }
      
        
    </Form.Select>
    {errors.product_supplier && <span>Required</span>}
  </FloatingLabel>

        </Form.Group>
        
        

          
       
        <Form.Group as={Col} md="12">
        <FloatingLabel controlId="floatingTextarea2" label="Product Description">
        <Form.Control
          as="textarea"
          placeholder="Product Description"
          style={{ height: '200px!important' }}
          name="product_description" 
          defaultValue={accounts.product_description || ""}
onChange={handleChange} {...register("product_description", { required: false })}
          />
           {errors.product_description && <p id='text-area'>Required</p>}
      </FloatingLabel>
    </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Family"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Product Family" 
            name="product_family"
            defaultValue={accounts.product_family || ""}
onChange={handleChange} {...register("product_family", { required: false })}
            />
             {errors.product_family && <span>Required Product Family</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Shipping Term"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Product Shipping Term" 
            name="product_shipping_term"
            defaultValue={accounts.product_shipping_term || ""}
onChange={handleChange} {...register("product_shipping_term", { required: false })}
            />
             {errors.product_shipping_term && <span>Required Product Shipping Term</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Owner"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Owner" 
            name="product_owner"
            defaultValue={accounts.product_owner || ""}
onChange={handleChange} {...register("product_owner", { required: false })}
            />
             {errors.product_owner && <span>Required Product Owner</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6" className='p-1'>
        <label>
        <input
          type="checkbox" 
          name="product_active"
          defaultValue={accounts.product_active || ""}
          onChange={(e) => setActive(e.target.checked)}
        />
         &nbsp;&nbsp; Active
      </label> 
        </Form.Group>
        <Form.Group as={Col} md="6" className='p-1'>
        <label>
        <input 
        type="checkbox" 
          name="short_term_available"
          defaultValue={accounts.short_term_available || ""}
          onChange={(e) => setShort(e.target.checked)}
        />
         &nbsp;&nbsp;Short Term Available
      </label> 
     
        </Form.Group>

        
      </Row>
      <Row className="mb-3">
        <h4 className='heading'>Product Info</h4>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Price Basis"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Product Price Basis" 
            name="product_price_basis"
            defaultValue={accounts.product_price_basis || ""}
onChange={handleChange} {...register("product_price_basis", { required: false })}
            />
             {errors.product_price_basis && <span>Required Product Active</span>}
          </FloatingLabel>
        </Form.Group>
       
        
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Product price type">
    <Form.Select aria-label="Floating label select example"
    name="product_price_type" 
    placeholder="Product Price type"  
    defaultValue={accounts.product_price_type || ""}
onChange={handleChange} {...register("product_price_type", { required:true })}
    > 
      <option value='' >Select</option>
       {
          getPrice.map((x)=>{
              return(
                  <option value={x.price_type_name}>{x.price_type_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.product_price_type && <span>Required</span>}
  </FloatingLabel>
              </Form.Group> 
         
               <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Port Of Loading"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Port Of Loading" 
            name="product_port_of_loading"
            defaultValue={accounts.product_port_of_loading || ""}
onChange={handleChange} {...register("product_port_of_loading", { required: false })}
            />
             {errors.product_port_of_loading && <span>Required Product Active</span>}
          </FloatingLabel>
        </Form.Group>
        </Row>
      <Row className="mb-3">
        <h4 className='heading'>%As Received Basis</h4>
        
                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Moisture Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Tota Moisture Typical"
                      name="total_moisture_typical"
                      defaultValue={accounts.total_moisture_typical || ""}
onChange={handleChange} {...register("total_moisture_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.total_moisture_typical && <span>Required Moisture Typical</span>}
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Moisture Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Total Moisture Minimum" 
                    name="total_moisture_minimum"
                    defaultValue={accounts.total_moisture_minimum || ""}
onChange={handleChange} {...register("total_moisture_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors.total_moisture_minimum && <span>Required Moisture Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Moisture Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Total Moisture Maximum" 
                    name="total_moisture_maximum"
                    defaultValue={accounts.total_moisture_maximum || ""}
onChange={handleChange} {...register("total_moisture_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors.total_moisture_maximum && <span>Required Moisture Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                
        
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash typical arb"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Ash typical arb" 
                    name="ash_typical_arb"
                    defaultValue={accounts.ash_typical_arb || ""}
onChange={handleChange} {...register("ash_typical_arb", {valueAsNumber:true, required: false })}
                    />
                     {errors.ash_typical_arb && <span>Required Ash Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Minimum ARB"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Ash Minimum ARB" 
                    name="ash_minimum"
                    defaultValue={accounts.ash_minimum || ""}
onChange={handleChange} {...register("ash_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors.ash_minimum && <span>Required Ash Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Maximum ARB"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Ash Maximum ARB" 
                    name="ash_maximum_arb"
                    defaultValue={accounts.ash_maximum_arb || ""}
onChange={handleChange} {...register("ash_maximum_arb", {valueAsNumber:true, required: false })}
                    />
                     {errors.ash_maximum_arb && <span>Required Ash Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical ARB"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Sulphur Typical ARB" 
                    name="sulphur_typical_arb"
                    defaultValue={accounts.sulphur_typical_arb || ""}
onChange={handleChange} {...register("sulphur_typical_arb", {valueAsNumber:true, required: false })}
                    />
                     {errors.sulphur_typical_arb && <span>Required Sulphur Typical Arb</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Minimum ARB"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Volatile Matter Minimum ARB" 
                    name="volatile_matter_minimum_arb"
                    defaultValue={accounts.volatile_matter_minimum_arb || ""}
onChange={handleChange} {...register("volatile_matter_minimum_arb", {valueAsNumber:true, required: false })}
                    />
                     {errors.volatile_matter_minimum && <span>Required Volatile Matter Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Maximum ARB"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Volatile Matter Maximum ARB" 
                    name="volatile_matter_maximum_arb"
                    defaultValue={accounts.volatile_matter_maximum_arb || ""}
                    onChange={handleChange}
 {...register("volatile_matter_maximum_arb", {valueAsNumber:true, required: false })}
                    />
                     {errors.volatile_matter_maximum && <span>Required Volatile Matter Maximum</span>}
                  </FloatingLabel>
                </Form.Group> 
        </Row>
         
        <Row className="mb-3">
                <h4 className='heading'>Proximate Analysis %Air Dried Basis</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Moisture Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Moisture Typical"
                      name="moisture_typical"
                      defaultValue={accounts.moisture_typical || ""}
onChange={handleChange} {...register("moisture_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.moisture_typical && <span>Required Moisture Typical</span>}
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Moisture Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Moisture Minimum" 
                    name="moisture_minimum"
                    defaultValue={accounts.moisture_minimum || ""}
onChange={handleChange} {...register("moisture_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors.moisture_minimum && <span>Required Moisture Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                
        
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Ash Typical" 
                    name="ash_typical"
                    defaultValue={accounts.ash_typical || ""}
onChange={handleChange} {...register("ash_typical", {valueAsNumber:true, required: false })}
                    />
                     {errors.ash_typical && <span>Required Ash Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Ash Maximum" 
                    name="ash_maximum"
                    defaultValue={accounts.ash_maximum || ""}
onChange={handleChange} {...register("ash_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors.ash_maximum && <span>Required Ash Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Volatile Matter Typical" 
                    name="volatile_matter_typical"
                    defaultValue={accounts.volatile_matter_typical || ""}
onChange={handleChange} {...register("volatile_matter_typical", {valueAsNumber:true, required: false })}
                    />
                     {errors.volatile_matter_typical && <span>Required Ash Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Volatile Matter Minimum" 
                    name="volatile_matter_minimum"
                    defaultValue={accounts.volatile_matter_minimum || ""}
onChange={handleChange} {...register("volatile_matter_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors.volatile_matter_minimum && <span>Required Ash Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Volatile Matter Maximum" 
                    name="volatile_matter_maximum"
                    defaultValue={accounts.volatile_matter_maximum || ""}
onChange={handleChange} {...register("volatile_matter_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors.volatile_matter_maximum && <span>Required Ash Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon By Difference"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Fixed Carbon By Difference" 
                    name="fixed_carbon_by_difference"
                    defaultValue={accounts.fixed_carbon_by_difference || ""}
onChange={handleChange} {...register("fixed_carbon_by_difference", {valueAsNumber:true, required: false })}
                    />
                     {errors.fixed_carbon_by_difference && <span>Required Ash Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Fixed Carbon Typical" 
                    name="fixed_carbon_typical"
                    defaultValue={accounts.fixed_carbon_typical || ""}
onChange={handleChange} {...register("fixed_carbon_typical", {valueAsNumber:true, required: false })}
                    />
                     {errors.fixed_carbon_typical && <span>Required Ash Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Fixed Carbon Minimum" 
                    name="fixed_carbon_minimum"
                    defaultValue={accounts.fixed_carbon_minimum || ""}
onChange={handleChange} {...register("fixed_carbon_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors.fixed_carbon_minimum && <span>Required Ash Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Fixed Carbon Maximum" 
                    name="fixed_carbon_maximum"
                    defaultValue={accounts.fixed_carbon_maximum || ""}
onChange={handleChange} {...register("fixed_carbon_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors.fixed_carbon_maximum && <span>Required Ash Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
              </Row>   
              <Row className="mb-3">
                <h4 className='heading'>CALORIFIC VALUE, Keal/kg</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross Air Dried Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Gross Air Dried Typical"
                      name="gross_air_dried_typical"
                      defaultValue={accounts.gross_air_dried_typical || ""}
onChange={handleChange} {...register("gross_air_dried_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.gross_air_dried_typical && <span>Required Gross Air Dried Typical</span>}
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross Air Dried Min"
                    className="mb-3"
                  > 
                    <Form.Control type="text" 
                    placeholder="Gross Air Dried Min" 
                    name="gross_air_dried_min"
                    defaultValue={accounts.gross_air_dried_min || ""}
onChange={handleChange} {...register("gross_air_dried_min", { valueAsNumber:true, required: false })}
                    />
                     {errors.gross_air_dried_min && <span>Required Gross Air Dried Min</span>}
                  </FloatingLabel>
                </Form.Group>
                
        
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross Air Dried Max"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Gross Air Dried Max" 
                    name="gross_air_dried_max"
                    defaultValue={accounts.gross_air_dried_max || ""}
onChange={handleChange} {...register("gross_air_dried_max", {valueAsNumber:true, required: false })}
                    />
                     {errors.gross_air_dried_max && <span>Required Gross Air Dried Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross As Recieved Min"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Gross As Recieved Min" 
                    name="gross_as_recieved_min"
                    defaultValue={accounts.gross_as_recieved_min || ""}
onChange={handleChange} {...register("gross_as_recieved_min", {valueAsNumber:true, required: false })}
                    />
                     {errors.gross_as_recieved_min && <span>Required Gross As Recieved Min</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross As Recieved Max"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Gross As Recieved Max" 
                    name="gross_as_recieved_max"
                    defaultValue={accounts.gross_as_recieved_max || ""}
onChange={handleChange} {...register("gross_as_recieved_max", {valueAsNumber:true, required: false })}
                    />
                     {errors.gross_as_recieved_max && <span>Required Gross As Recieved Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Net As Recieved Min"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Net As Recieved Min" 
                    name="net_as_recieved_min"
                    defaultValue={accounts.net_as_recieved_min || ""}
onChange={handleChange} {...register("net_as_recieved_min", {valueAsNumber:true, required: false })}
                    />
                     {errors.net_as_recieved_min && <span>Required Net As Recieved Min</span>}
                  </FloatingLabel>
                </Form.Group>  

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Net As Recieved Max"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Net As Recieved Max" 
                    name="net_as_recieved_max"
                    defaultValue={accounts.net_as_recieved_max || ""}
onChange={handleChange} {...register("net_as_recieved_max", {valueAsNumber:true, required: false })}
                    />
                     {errors.net_as_recieved_max && <span>Required Net As Recieved Min</span>}
                  </FloatingLabel>
                </Form.Group>  
              </Row> 
              <Row className="mb-3">
                <h4 className='heading'>HGI</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="HGI typical" 
                    name="hgi_typical"
                    defaultValue={accounts.hgi_typical || ""}
onChange={handleChange} {...register("hgi_typical", { required: false })}
                    />
                     {errors.hgi_typical && <span>Required Volatile Matter Maximum</span>}
                  </FloatingLabel>
                </Form.Group> 
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="HGI minimum" 
                    name="hgi_min"
                    defaultValue={accounts.hgi_min || ""}
onChange={handleChange} {...register("hgi_min", { required: false })}
                    />
                     {errors.hgi_min && <span>Required Volatile Matter Maximum</span>}
                  </FloatingLabel>
                </Form.Group> 
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="HGI Maximum" 
                    name="hgi_max"
                    defaultValue={accounts.hgi_max || ""}
onChange={handleChange} {...register("hgi_max", { required: false })}
                    />
                     {errors.hgi_max && <span>Required Volatile Matter Maximum</span>}
                  </FloatingLabel>
                </Form.Group> 
                </Row>  
                <Row className="mb-3">
                <h4 className='heading'>ULTIMATE ANALYSIS %dry ash free basis</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Carbon Typical"
                      name="carbon_typical"
                      defaultValue={accounts.carbon_typical || ""}
onChange={handleChange} {...register("carbon_typical", { valueAsNumber:true, required: false })}
                    />
                    {errors.carbon_typical && <span>Required Carbon Typical</span>}
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Carbon Minimum" 
                    name="carbon_min"
                    defaultValue={accounts.carbon_min || ""}
onChange={handleChange} {...register("carbon_min", {valueAsNumber:true,  required: false })}
                    />
                     {errors.carbon_min && <span>Required Carbon Min</span>}
                  </FloatingLabel>
                </Form.Group>
                
        
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Carbon Maximum" 
                    name="carbon_max"
                    defaultValue={accounts.carbon_max || ""}
onChange={handleChange} {...register("carbon_max", {valueAsNumber:true,  required: false })}
                    />
                     {errors.carbon_max && <span>Required Carbon Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Hydrogen Typical" 
                    name="hydrogen_typical"
                    defaultValue={accounts.hydrogen_typical || ""}
onChange={handleChange} {...register("hydrogen_typical", { valueAsNumber:true, required: false })}
                    />
                     {errors.hydrogen_typical && <span>Required Hydrogen Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Hydrogen Minimum" 
                    name="hydrogen_min"
                    defaultValue={accounts.hydrogen_min || ""}
onChange={handleChange} {...register("hydrogen_min", {valueAsNumber:true,  required: false })}
                    />
                     {errors.hydrogen_min && <span>Required Hydrogen Min</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Hydrogen Maximum" 
                    name="hydrogen_max"
                    defaultValue={accounts.hydrogen_max || ""}
onChange={handleChange} {...register("hydrogen_max", { valueAsNumber:true, required: false })}
                    />
                     {errors.hydrogen_max && <span>Required Hydrogen Max</span>}
                  </FloatingLabel>
                </Form.Group>
                
                 
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Nitrogen Typical" 
                    name="nitrogen_typical"
                    defaultValue={accounts.nitrogen_typical || ""}
onChange={handleChange} {...register("nitrogen_typical", {valueAsNumber:true,  required: false })}
                    />
                     {errors.nitrogen_typical && <span>Required Nitrogen Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Nitrogen Minimum" 
                    name="nitrogen_min"
                    defaultValue={accounts.nitrogen_min || ""}
onChange={handleChange} {...register("nitrogen_min", { valueAsNumber:true, required: false })}
                    />
                     {errors.nitrogen_min && <span>Required Nitrogen Min</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Nitrogen Maximum" 
                    name="nitrogen_max"
                    defaultValue={accounts.nitrogen_max || ""}
onChange={handleChange} {...register("nitrogen_max", {valueAsNumber:true,  required: false })}
                    />
                     {errors.nitrogen_max && <span>Nitrogen Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Sulphur Typical" 
                    name="sulphur_typical"
                    defaultValue={accounts.sulphur_typical || ""}
onChange={handleChange} {...register("sulphur_typical", {valueAsNumber:true,  required: false })}
                    />
                     {errors.sulphur_typical && <span>Required Sulphur Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Sulphur Minimum" 
                    name="sulphur_minimum"
                    defaultValue={accounts.sulphur_minimum || ""}
onChange={handleChange} {...register("sulphur_minimum", { valueAsNumber:true, required: false })}
                    />
                     {errors.sulphur_minimum && <span>Required Sulphur Min</span>}
                  </FloatingLabel>
                </Form.Group>
                
               
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Oxygen Typical" 
                    name="oxygen_typical"
                    defaultValue={accounts.oxygen_typical || ""}
onChange={handleChange} {...register("oxygen_typical", { valueAsNumber:true, required: false })}
                    />
                     {errors.oxygen_typical && <span>Required Oxygen Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Oxygen Minimum" 
                    name="oxygen_min"
                    defaultValue={accounts.oxygen_min || ""}
onChange={handleChange} {...register("oxygen_min", {valueAsNumber:true,  required: false })}
                    />
                     {errors.oxygen_min && <span>Required Oxygen Min</span>}
                  </FloatingLabel>
                </Form.Group>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Oxygen Maximum" 
                    name="oxygen_max"
                    defaultValue={accounts.oxygen_max || ""}
onChange={handleChange} {...register("oxygen_max", {valueAsNumber:true,  required: false })}
                    />
                     {errors.oxygen_max && <span>Required Oxygen Max</span>}
                  </FloatingLabel>
                </Form.Group>
              </Row>  
              <Row className="mb-3">
                <h4 className='heading'>SULPHUR %air dried basis</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical Adb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Sulphur Typical Adb"
                      name="sulphur_typical_adb"
                      defaultValue={accounts.sulphur_typical_adb || ""}
onChange={handleChange} {...register("sulphur_typical_adb", {valueAsNumber:true, required: false })}
                    />
                    {errors.sulphur_typical_adb && <span>Required Sulphur Typical Adb</span>}
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Minimum Adb"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Sulphur Minimum Adb" 
                    name="sulphur_min_adb"
                    defaultValue={accounts.sulphur_min_adb || ""}
onChange={handleChange} {...register("sulphur_min_adb", { valueAsNumber:true,required: false })}
                    />
                     {errors.sulphur_min_adb && <span>Required Sulphur Min Adb</span>}
                  </FloatingLabel>
                </Form.Group>
                
        
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Sulphur Maximum" 
                    name="product_sulphur_max"
                    defaultValue={accounts.product_sulphur_max || ""}
onChange={handleChange} {...register("product_sulphur_max", {valueAsNumber:true, required: false })}
                    />
                     {errors.product_sulphur_max && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>
                
              </Row>  
              <Row className="mb-3">
                <h4 className='heading'>ASH FUSION TEMPERATURE, DEG (C) Reducing</h4>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Initial Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Initial Typical" 
                    name="initial_typical"
                    defaultValue={accounts.initial_typical || ""}
onChange={handleChange} {...register("initial_typical", {valueAsNumber:true, required: false })}
                    />
                     {errors.initial_typical && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Initial Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Initial Minimum" 
                    name="initial_minimum"
                    defaultValue={accounts.initial_minimum || ""}
onChange={handleChange} {...register("initial_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors.initial_minimum && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Initial Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Initial Maximum" 
                    name="initial_maximum"
                    defaultValue={accounts.initial_maximum || ""}
onChange={handleChange} {...register("initial_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors.initial_maximum && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Spherical Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Spherical Typical" 
                    name="spherical_typical"
                    defaultValue={accounts.spherical_typical || ""}
onChange={handleChange} {...register("spherical_typical", {valueAsNumber:true, required: false })}
                    />
                     {errors.spherical_typical && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Spherical Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Spherical Minimum" 
                    name="spherical_minimum"
                    defaultValue={accounts.spherical_minimum || ""}
onChange={handleChange} {...register("spherical_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors.spherical_minimum && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Spherical Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Spherical Maximum" 
                    name="spherical_maximum"
                    defaultValue={accounts.spherical_maximum || ""}
onChange={handleChange} {...register("spherical_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors.spherical_maximum && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hemispherical Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Hemispherical Typical" 
                    name="hemispherical_typical"
                    defaultValue={accounts.hemispherical_typical || ""}
onChange={handleChange} {...register("hemispherical_typical", {valueAsNumber:true, required: false })}
                    />
                     {errors.hemispherical_typical && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hemispherical Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Hemispherical Minimum" 
                    name="hemispherical_minimum"
                    defaultValue={accounts.hemispherical_minimum || ""}
onChange={handleChange} {...register("hemispherical_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors.hemispherical_minimum && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hemispherical Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Hemispherical Maximum" 
                    name="hemispherical_maximum"
                    defaultValue={accounts.hemispherical_maximum || ""}
onChange={handleChange} {...register("hemispherical_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors.hemispherical_maximum && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Flow Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Flow Typical" 
                    name="flow_typical"
                    defaultValue={accounts.flow_typical || ""}
onChange={handleChange} {...register("flow_typical", {valueAsNumber:true, required: false })}
                    />
                     {errors.spherical_minimum && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Flow Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Flow Minimum" 
                    name="flow_minimum"
                    defaultValue={accounts.flow_minimum || ""}
onChange={handleChange} {...register("flow_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors.flow_minimum && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Spherical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Spherical" 
                    name="spherical_minimum"
                    defaultValue={accounts.spherical_minimum || ""}
onChange={handleChange} {...register("spherical_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors.spherical_minimum && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Flow Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Flow Maximum" 
                    name="flow_maximum"
                    defaultValue={accounts.flow_maximum || ""}
onChange={handleChange} {...register("flow_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors.flow_maximum && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <h4 className='heading'>ASH FUSION TEMPERATURE, Deg C Oxidizing</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Deformtion Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidizing Initial Deformtion Typical"
                      name="oxidizing_initial_typical"
                      defaultValue={accounts.oxidizing_initial_typical || ""}
onChange={handleChange} {...register("oxidizing_initial_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.oxidizing_initial_typical && <span>Required Initial Typical</span>}
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Initial Minimum" 
                    name="oxidizing_initial_minimum"
                    defaultValue={accounts.oxidizing_initial_minimum || ""}
onChange={handleChange} {...register("oxidizing_initial_minimum", { valueAsNumber:true,required: false })}
                    />
                     {errors.oxidizing_initial_minimum && <span>Required Initial Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                
         
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Initial Maximum" 
                    name="oxidizing_initial_maximum"
                    defaultValue={accounts.oxidizing_initial_maximum || ""}
onChange={handleChange} {...register("oxidizing_initial_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidizing_initial_maximum && <span>Required Initial Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Spherical Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Spherical Typical" 
                    name="oxidizing_spherical_typical"
                    defaultValue={accounts.oxidizing_spherical_typical || ""}
onChange={handleChange} {...register("oxidizing_spherical_typical", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidizing_spherical_typical && <span>Required Spherical Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Spherical Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Spherical Minimum" 
                    name="oxidizing_spherical_minimum"
                    defaultValue={accounts.oxidizing_spherical_minimum || ""}
onChange={handleChange} {...register("oxidizing_spherical_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidizing_spherical_minimum && <span>Required Spherical Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Spherical Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Spherical Maximum" 
                    name="oxidizing_spherical_maximum"
                    defaultValue={accounts.oxidizing_spherical_maximum || ""}
onChange={handleChange} {...register("oxidizing_spherical_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidizing_spherical_maximum && <span>Required Spherical Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Hemispherical Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Hemispherical Typical" 
                    name="oxidizing_hemispherical_typical"
                    defaultValue={accounts.oxidizing_hemispherical_typical || ""}
onChange={handleChange} {...register("oxidizing_hemispherical_typical", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidizing_hemispherical_typical && <span>Required Hemispherical Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Hemispherical Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Hemispherical Minimum" 
                    name="oxidising_hemispherical_minimum"
                    defaultValue={accounts.oxidising_hemispherical_minimum || ""}
onChange={handleChange} {...register("oxidising_hemispherical_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidising_hemispherical_minimum && <span>Required Hemispherical Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Hemispherical Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Hemispherical Maximum" 
                    name="oxidizing_hemispherical_maximum"
                    defaultValue={accounts.oxidizing_hemispherical_maximum || ""}
onChange={handleChange} {...register("oxidizing_hemispherical_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidizing_hemispherical_maximum && <span>Required Hemispherical Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Flow Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Flow Typical" 
                    name="oxidising_flow_typical"
                    defaultValue={accounts.oxidising_flow_typical || ""}
onChange={handleChange} {...register("oxidising_flow_typical", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidising_flow_typical && <span>Required Flow Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Flow Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Flow Minimum" 
                    name="oxidising_flow_minimum"
                    defaultValue={accounts.oxidising_flow_minimum || ""}
onChange={handleChange} {...register("oxidising_flow_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidising_flow_minimum && <span>Required Flow Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Flow Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Flow Maximum" 
                    name="oxidizing_flow_maximum"
                    defaultValue={accounts.oxidizing_flow_maximum || ""}
onChange={handleChange} {...register("oxidizing_flow_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidizing_flow_maximum && <span>Required Flow Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
              </Row>  
              <Row className="mb-3">
                <h4 className='heading'>ASH ANALYSIS %Dry Basis in Ash</h4>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SiO2 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SiO2 Typical"
                      name="SiO2_Typical"
                      defaultValue={accounts.SiO2_Typical || ""}
onChange={handleChange} {...register("SiO2_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.SiO2_Typical && <span>Required SiO2 Typical </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SiO2 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SiO2 Minimum"
                      name="SiO2_Minimum"
                      defaultValue={accounts.SiO2_Minimum || ""}
onChange={handleChange} {...register("SiO2_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.SiO2_Minimum && <span>Required SiO2 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SiO2 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SiO2 Maximum"
                      name="SiO2_Maximum"
                      defaultValue={accounts.SiO2_Maximum || ""}
onChange={handleChange} {...register("SiO2_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.SiO2_Maximum && <span>Required SiO2 Maximum </span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Al2O3 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Al2O3 Typical"
                      name="Al2O3_Typical"
                      defaultValue={accounts.Al2O3_Typical || ""}
onChange={handleChange} {...register("Al2O3_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.Al2O3_Typical && <span>Required Al2O3 Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Al2O3 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Al2O3 Minimum"
                      name="Al2O3_Minimum"
                      defaultValue={accounts.Al2O3_Minimum || ""}
onChange={handleChange} {...register("Al2O3_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.Al2O3_Minimum && <span>Required Al2O3 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Al2O3 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Al2O3 Maximum"
                      name="Al2O3_Maximum"
                      defaultValue={accounts.Al2O3_Maximum || ""}
onChange={handleChange} {...register("Al2O3_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.Al2O3_Maximum && <span>Required Al2O3 Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fe2O3 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Fe2O3 Typical"
                      name="Fe2O3_Typical"
                      defaultValue={accounts.Fe2O3_Typical || ""}
onChange={handleChange} {...register("Fe2O3_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.Fe2O3_Typical && <span>Required Fe2O3 Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fe2O3 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Fe2O3 Minimum"
                      name="Fe2O3_Minimum"
                      defaultValue={accounts.Fe2O3_Minimum || ""}
onChange={handleChange} {...register("Fe2O3_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.Fe2O3_Minimum && <span>Required Fe2O3 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fe2O3 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Fe2O3 Maximum"
                      name="Fe2O3_Maximum"
                      defaultValue={accounts.Fe2O3_Maximum || ""}
onChange={handleChange} {...register("Fe2O3_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.Fe2O3_Maximum && <span>Required Fe2O3 Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="CaO Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="CaO Typical"
                      name="CaO_Typical"
                      defaultValue={accounts.CaO_Typical || ""}
onChange={handleChange} {...register("CaO_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.CaO_Typical && <span>Required CaO Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="CaO Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="CaO Minimum"
                      name="CaO_Minimum"
                      defaultValue={accounts.CaO_Minimum || ""}
onChange={handleChange} {...register("CaO_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.CaO_Minimum && <span>Required CaO Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="CaO Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="CaO Maximum"
                      name="CaO_Maximum"
                      defaultValue={accounts.CaO_Maximum || ""}
onChange={handleChange} {...register("CaO_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.CaO_Maximum && <span>Required CaO Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MgO Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MgO Typical"
                      name="MgO_Typical"
                      defaultValue={accounts.MgO_Typical || ""}
onChange={handleChange} {...register("MgO_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.MgO_Typical && <span>Required MgO Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MgO Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MgO Minimum"
                      name="MgO_Minimum"
                      defaultValue={accounts.MgO_Minimum || ""}
onChange={handleChange} {...register("MgO_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.MgO_Minimum && <span>Required MgO Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MgO Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MgO Maximum"
                      name="MgO_Maximum"
                      defaultValue={accounts.MgO_Maximum || ""}
onChange={handleChange} {...register("MgO_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.MgO_Maximum && <span>Required MgO Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MnO Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MnO Typical"
                      name="MnO_Typical"
                      defaultValue={accounts.MnO_Typical || ""}
onChange={handleChange} {...register("MnO_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.MnO_Typical && <span>Required MnO Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MnO Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MnO Minimum"
                      name="MnO_Minimum"
                      defaultValue={accounts.MnO_Minimum || ""}
onChange={handleChange} {...register("MnO_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.MnO_Minimum && <span>Required MnO Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MnO Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MnO Maximum"
                      name="MnO_Maximum"
                      defaultValue={accounts.MnO_Maximum || ""}
onChange={handleChange} {...register("MnO_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.MnO_Maximum && <span>Required MnO Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TiO2 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TiO2 Typical"
                      name="TiO2_Typical"
                      defaultValue={accounts.TiO2_Typical || ""}
onChange={handleChange} {...register("TiO2_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.TiO2_Typical && <span>Required TiO2 Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TiO2 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TiO2 Minimum"
                      name="TiO2_Minimum"
                      defaultValue={accounts.TiO2_Minimum || ""}
onChange={handleChange} {...register("TiO2_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.TiO2_Minimum && <span>Required TiO2 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TiO2 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TiO2 Maximum"
                      name="TiO2_Maximum"
                      defaultValue={accounts.TiO2_Maximum || ""}
onChange={handleChange} {...register("TiO2_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.TiO2_Maximum && <span>Required TiO2 Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Na2O Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Na2O Typical"
                      name="Na2O_Typical"
                      defaultValue={accounts.Na2O_Typical || ""}
onChange={handleChange} {...register("Na2O_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.Na2O_Typical && <span>Required Na2O Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Na2O Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Na2O Minimum"
                      name="Na2O_Minimum"
                      defaultValue={accounts.Na2O_Minimum || ""}
onChange={handleChange} {...register("Na2O_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.Na2O_Minimum && <span>Required Na2O Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Na2O Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Na2O Maximum"
                      name="Na2O_Maximum"
                      defaultValue={accounts.Na2O_Maximum || ""}
onChange={handleChange} {...register("Na2O_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.Na2O_Maximum && <span>Required Na2O Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="K2O Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="K2O Typical"
                      name="K2O_Typical"
                      defaultValue={accounts.K2O_Typical || ""}
onChange={handleChange} {...register("K2O_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.K2O_Typical && <span>Required K2O Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="K2O Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="K2O Minimum"
                      name="K2O_Minimum"
                      defaultValue={accounts.K2O_Minimum || ""}
onChange={handleChange} {...register("K2O_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.K2O_Minimum && <span>Required K2O Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="K2O Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="K2O Maximum"
                      name="K2O_Maximum"
                      defaultValue={accounts.K2O_Maximum || ""}
onChange={handleChange} {...register("K2O_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.K2O_Maximum && <span>Required K2O Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="P2O5 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="P2O5 Typical"
                      name="P2O5_Typical"
                      defaultValue={accounts.P2O5_Typical || ""}
onChange={handleChange} {...register("P2O5_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.P2O5_Typical && <span>Required P2O5 Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="P2O5 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="P2O5 Minimum"
                      name="P2O5_Minimum"
                      defaultValue={accounts.P2O5_Minimum || ""}
onChange={handleChange} {...register("P2O5_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.P2O5_Minimum && <span>Required P2O5 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="P2O5 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="P2O5 Maximum"
                      name="P2O5_Maximum"
                      defaultValue={accounts.P2O5_Maximum || ""}
onChange={handleChange} {...register("P2O5_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.P2O5_Maximum && <span>Required P2O5 Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SO3 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SO3 Typical"
                      name="SO3_Typical"
                      defaultValue={accounts.SO3_Typical || ""}
onChange={handleChange} {...register("SO3_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.SO3_Typical && <span>Required SO3 Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SO3 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SO3 Minimum"
                      name="SO3_Minimum"
                      defaultValue={accounts.SO3_Minimum || ""}
onChange={handleChange} {...register("SO3_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.SO3_Minimum && <span>Required SO3 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SO3 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SO3 Maximum"
                      name="SO3_Maximum"
                      defaultValue={accounts.SO3_Maximum || ""}
onChange={handleChange} {...register("SO3_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.SO3_Maximum && <span>Required SO3 Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
         
               
                 
                
              </Row> 
              <Row className="mb-3">
                <h4 className='heading'>Trace Elements, ppm Air Dried in Coal</h4>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE arsenic as typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE arsenic as typical"
                      name="te_arsenic_as_typical_in_air_dried_coal"
                      defaultValue={accounts.te_arsenic_as_typical_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_arsenic_as_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_arsenic_as_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE rsenic as minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE rsenic as minimum"
                      name="te_arsenic_as_minimum_in_air_dried_coal"
                      defaultValue={accounts.te_arsenic_as_minimum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_arsenic_as_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_arsenic_as_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE arsenic as maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE arsenic as maximum"
                      name="te_arsenic_as_maximum_in_air_dried_coal"
                      defaultValue={accounts.te_arsenic_as_maximum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_arsenic_as_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_arsenic_as_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE antimony sb typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE antimony sb typical"
                      name="te_antimony_sb_typical_in_air_dried_coal"
                      defaultValue={accounts.te_antimony_sb_typical_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_antimony_sb_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_antimony_sb_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE antimony sb minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE antimony sb minimum"
                      name="te_antimony_sb_minimum_in_air_dried_coal"
                      defaultValue={accounts.te_antimony_sb_minimum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_antimony_sb_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_antimony_sb_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE antimony sb maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE antimony sb maximum"
                      name="te_antimony_sb_maximum_in_air_dried_coal"
                      defaultValue={accounts.te_antimony_sb_maximum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_antimony_sb_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_antimony_sb_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE boron b typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE boron b typical"
                      name="te_boron_b_typical_in_air_dried_coal"
                      defaultValue={accounts.te_boron_b_typical_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_boron_b_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_boron_b_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE boron b minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE boron b minimum"
                      name="te_boron_b_minimum_in_air_dried_coal"
                      defaultValue={accounts.te_boron_b_minimum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_boron_b_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_boron_b_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE boron b maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE boron b maximum"
                      name="te_boron_b_maximum_in_air_dried_coal"
                      defaultValue={accounts.te_boron_b_maximum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_boron_b_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_boron_b_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE cadmium cd typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE cadmium cd typical"
                      name="te_cadmium_cd_typical_in_air_dried_coal"
                      defaultValue={accounts.te_cadmium_cd_typical_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_cadmium_cd_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_cadmium_cd_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE cadmium cd minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE cadmium cd minimum"
                      name="te_cadmium_cd_minimum_in_air_dried_coal"
                      defaultValue={accounts.te_cadmium_cd_minimum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_cadmium_cd_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_cadmium_cd_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE cadamium cd maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE cadamium cd maximum"
                      name="te_cadamium_cd_maximum_in_air_dried_coal"
                      defaultValue={accounts.te_cadamium_cd_maximum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_cadamium_cd_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_cadamium_cd_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chromium cr typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chromium cr typical"
                      name="te_chromium_cr_typical_in_air_dried_coal"
                      defaultValue={accounts.te_chromium_cr_typical_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_chromium_cr_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chromium_cr_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chromium cr minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chromium cr minimum"
                      name="te_chromium_cr_minimum_in_air_dried_coal"
                      defaultValue={accounts.te_chromium_cr_minimum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_chromium_cr_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chromium_cr_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chromium cr maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chromium cr maximum"
                      name="te_chromium_cr_maximum_in_air_dried_coal"
                      defaultValue={accounts.te_chromium_cr_maximum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_chromium_cr_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chromium_cr_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE copper cu typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE copper cu typical"
                      name="te_copper_cu_typical_in_air_dried_coal"
                      defaultValue={accounts.te_copper_cu_typical_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_copper_cu_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_copper_cu_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE copper cu minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE copper cu minimum"
                      name="te_copper_cu_minimum_in_air_dried_coal"
                      defaultValue={accounts.te_copper_cu_minimum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_copper_cu_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_copper_cu_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE copper cu maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE copper cu maximum"
                      name="te_copper_cu_maximum_in_air_dried_coal"
                      defaultValue={accounts.te_copper_cu_maximum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_copper_cu_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_copper_cu_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chlorine cl typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chlorine cl typical"
                      name="te_chlorine_cl_typical_in_air_dried_coal"
                      defaultValue={accounts.te_chlorine_cl_typical_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_chlorine_cl_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chlorine_cl_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chlorine cl minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chlorine cl minimum"
                      name="te_chlorine_cl_minimum_in_air_dried_coal"
                      defaultValue={accounts.te_chlorine_cl_minimum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_chlorine_cl_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chlorine_cl_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chlorine cl maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chlorine cl maximum"
                      name="te_chlorine_cl_maximum_in_air_dried_coal"
                      defaultValue={accounts.te_chlorine_cl_maximum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_chlorine_cl_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chlorine_cl_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE fluorine f typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE fluorine f typical"
                      name="te_fluorine_f_typical_in_air_dried_coal"
                      defaultValue={accounts.te_fluorine_f_typical_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_fluorine_f_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_fluorine_f_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE fluorine f minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE fluorine f minimum"
                      name="te_fluorine_f_minimum_in_air_dried_coal"
                      defaultValue={accounts.te_fluorine_f_minimum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_fluorine_f_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_fluorine_f_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE fluorine f maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE fluorine f maximum"
                      name="te_fluorine_f_maximum_in_air_dried_coal"
                      defaultValue={accounts.te_fluorine_f_maximum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_fluorine_f_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_fluorine_f_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE mercury hg typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE mercury hg typical"
                      name="te_mercury_hg_typical_in_air_dried_coal"
                      defaultValue={accounts.te_mercury_hg_typical_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_mercury_hg_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_mercury_hg_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE mercury hg minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE mercury hg minimum"
                      name="te_mercury_hg_minimum_in_air_dried_coal"
                      defaultValue={accounts.te_mercury_hg_minimum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_mercury_hg_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_mercury_hg_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE mercury hg maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE mercury hg maximum"
                      name="te_mercury_hg_maximum_in_air_dried_coal"
                      defaultValue={accounts.te_mercury_hg_maximum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_mercury_hg_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_mercury_hg_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE molybdenum mo typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE molybdenum mo typical"
                      name="te_molybdenum_mo_typical_in_air_dried_coal"
                      defaultValue={accounts.te_molybdenum_mo_typical_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_molybdenum_mo_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_molybdenum_mo_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE molybdenum mo minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE molybdenum mo minimum"
                      name="te_molybdenum_mo_minimum_in_air_dried_coal"
                      defaultValue={accounts.te_molybdenum_mo_minimum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_molybdenum_mo_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_molybdenum_mo_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE molybdenum mo maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE molybdenum mo maximum"
                      name="te_molybdenum_mo_maximum_in_air_dried_coal"
                      defaultValue={accounts.te_molybdenum_mo_maximum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_molybdenum_mo_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_molybdenum_mo_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE lead pb typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE lead pb typical"
                      name="te_lead_pb_typical_in_air_dried_coal"
                      defaultValue={accounts.te_lead_pb_typical_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_lead_pb_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_lead_pb_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE lead pb minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE lead pb minimum"
                      name="te_lead_pb_minimum_in_air_dried_coal"
                      defaultValue={accounts.te_lead_pb_minimum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_lead_pb_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_lead_pb_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE lead pb maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE lead pb maximum"
                      name="te_lead_pb_maximum_in_air_dried_coal"
                      defaultValue={accounts.te_lead_pb_maximum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_lead_pb_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_lead_pb_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE phosphorus p typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE phosphorus p typical"
                      name="te_phosphorus_p_typical_in_air_dried_coal"
                      defaultValue={accounts.te_phosphorus_p_typical_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_phosphorus_p_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_phosphorus_p_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE phosphorus p minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE phosphorus p minimum"
                      name="te_phosphorus_p_minimum_in_air_dried_coal"
                      defaultValue={accounts.te_phosphorus_p_minimum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_phosphorus_p_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_phosphorus_p_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE phosphorus p maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE phosphorus p maximum"
                      name="te_phosphorus_p_maximum_in_air_dried_coal"
                      defaultValue={accounts.te_phosphorus_p_maximum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_phosphorus_p_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_phosphorus_p_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE selenium se typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE selenium se typical"
                      name="te_selenium_se_typical_in_air_dried_coal"
                      defaultValue={accounts.te_selenium_se_typical_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_selenium_se_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_selenium_se_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE selenium se minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE selenium se minimum"
                      name="te_selenium_se_minimum_in_air_dried_coal"
                      defaultValue={accounts.te_selenium_se_minimum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_selenium_se_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_selenium_se_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE selenium se maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE selenium se maximum"
                      name="te_selenium_se_maximum_in_air_dried_coal"
                      defaultValue={accounts.te_selenium_se_maximum_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_selenium_se_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_selenium_se_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE vanadium v typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE vanadium v typical"
                      name="te_vanadium_v_typical_in_air_dried_coal"
                      defaultValue={accounts.te_vanadium_v_typical_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_vanadium_v_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_vanadium_v_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE vanadium v min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE vanadium v min"
                      name="te_vanadium_v_min_in_air_dried_coal"
                      defaultValue={accounts.te_vanadium_v_min_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_vanadium_v_min_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_vanadium_v_min_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE vanadium v max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE vanadium v max"
                      name="te_vanadium_v_max_in_air_dried_coal"
                      defaultValue={accounts.te_vanadium_v_max_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_vanadium_v_max_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_vanadium_v_max_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE zinc zn typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE zinc zn typical"
                      name="te_zinc_zn_typical_in_air_dried_coal"
                      defaultValue={accounts.te_zinc_zn_typical_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_zinc_zn_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_zinc_zn_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE zinc zn min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE zinc zn min"
                      name="te_zinc_zn_min_in_air_dried_coal"
                      defaultValue={accounts.te_zinc_zn_min_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_zinc_zn_min_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_zinc_zn_min_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE zinc zn max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE zinc zn max"
                      name="te_zinc_zn_max_in_air_dried_coal"
                      defaultValue={accounts.te_zinc_zn_max_in_air_dried_coal || ""}
onChange={handleChange} {...register("te_zinc_zn_max_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_zinc_zn_max_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
               
                
              </Row>
               
              <Row className="mb-3">
                <h4 className='heading'>Trace Elements, ppm Dried Basis</h4>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="te arsenic as typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="te arsenic as typical"
                      name="te_arsenic_as_typical"
                      defaultValue={accounts.te_arsenic_as_typical || ""}
onChange={handleChange} {...register("te_arsenic_as_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_arsenic_as_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te arsenic as minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te arsenic as minimum"
                      name="te_arsenic_as_minimum"
                      defaultValue={accounts.te_arsenic_as_minimum || ""}
onChange={handleChange} {...register("te_arsenic_as_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_arsenic_as_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te arsenic as maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te arsenic as maximum"
                      name="te_arsenic_as_maximum"
                      defaultValue={accounts.te_arsenic_as_maximum || ""}
onChange={handleChange} {...register("te_arsenic_as_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_arsenic_as_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te antimony sb typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te antimony sb typical"
                      name="te_antimony_sb_typical"
                      defaultValue={accounts.te_antimony_sb_typical || ""}
onChange={handleChange} {...register("te_antimony_sb_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_antimony_sb_typical && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te antimony sb minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te antimony sb minimum"
                      name="te_antimony_sb_minimum"
                      defaultValue={accounts.te_antimony_sb_minimum || ""}
onChange={handleChange} {...register("te_antimony_sb_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_antimony_sb_minimum && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te antimony sb maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te antimony sb maximum"
                      name="te_antimony_sb_maximum"
                      defaultValue={accounts.te_antimony_sb_maximum || ""}
onChange={handleChange} {...register("te_antimony_sb_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_antimony_sb_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te beryllium be typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te beryllium be typical"
                      name="te_beryllium_be_typical"
                      defaultValue={accounts.te_beryllium_be_typical || ""}
onChange={handleChange} {...register("te_beryllium_be_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_beryllium_be_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te beryllium be minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te beryllium be minimum"
                      name="te_beryllium_be_minimum"
                      defaultValue={accounts.te_beryllium_be_minimum || ""}
onChange={handleChange} {...register("te_beryllium_be_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_beryllium_be_minimum && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te beryllium be maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te beryllium be maximum"
                      name="te_beryllium_be_maximum"
                      defaultValue={accounts.te_beryllium_be_maximum || ""}
onChange={handleChange} {...register("te_beryllium_be_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_beryllium_be_maximum && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te boron b typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te boron b typical"
                      name="te_boron_b_typical"
                      defaultValue={accounts.te_boron_b_typical || ""}
onChange={handleChange} {...register("te_boron_b_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_boron_b_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te boron b minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te boron b minimum"
                      name="te_boron_b_minimum"
                      defaultValue={accounts.te_boron_b_minimum || ""}
onChange={handleChange} {...register("te_boron_b_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_boron_b_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te boron b maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te boron b maximum"
                      name="te_boron_b_maximum"
                      defaultValue={accounts.te_boron_b_maximum || ""}
onChange={handleChange} {...register("te_boron_b_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_boron_b_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te cadmium cd typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te cadmium cd typical"
                      name="te_cadmium_cd_typical"
                      defaultValue={accounts.te_cadmium_cd_typical || ""}
onChange={handleChange} {...register("te_cadmium_cd_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_cadmium_cd_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te cadmium cd minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te cadmium cd minimum"
                      name="te_cadmium_cd_minimum"
                      defaultValue={accounts.te_cadmium_cd_minimum || ""}
onChange={handleChange} {...register("te_cadmium_cd_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_cadmium_cd_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te cadmium cd maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te cadmium cd maximum"
                      name="te_cadmium_cd_maximum"
                      defaultValue={accounts.te_cadmium_cd_maximum || ""}
onChange={handleChange} {...register("te_cadmium_cd_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_cadmium_cd_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chromium cr typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chromium cr typical"
                      name="te_chromium_cr_typical"
                      defaultValue={accounts.te_chromium_cr_typical || ""}
onChange={handleChange} {...register("te_chromium_cr_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chromium_cr_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chromium cr minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chromium cr minimum"
                      name="te_chromium_cr_minimum"
                      defaultValue={accounts.te_chromium_cr_minimum || ""}
onChange={handleChange} {...register("te_chromium_cr_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chromium_cr_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chromium cr maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chromium cr maximum"
                      name="te_chromium_cr_maximum"
                      defaultValue={accounts.te_chromium_cr_maximum || ""}
onChange={handleChange} {...register("te_chromium_cr_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chromium_cr_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te copper cu typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te copper cu typical"
                      name="te_copper_cu_typical"
                      defaultValue={accounts.te_copper_cu_typical || ""}
onChange={handleChange} {...register("te_copper_cu_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_copper_cu_typical&& <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te copper cu minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te copper cu minimum"
                      name="te_copper_cu_minimum"
                      defaultValue={accounts.te_copper_cu_minimum || ""}
onChange={handleChange} {...register("te_copper_cu_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_copper_cu_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te copper cu maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te copper cu maximum"
                      name="te_copper_cu_maximum"
                      defaultValue={accounts.te_copper_cu_maximum || ""}
onChange={handleChange} {...register("te_copper_cu_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_copper_cu_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chlorine cl typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chlorine cl typical"
                      name="te_chlorine_cl_typical"
                      defaultValue={accounts.te_chlorine_cl_typical || ""}
onChange={handleChange} {...register("te_chlorine_cl_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chlorine_cl_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chlorine cl minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chlorine cl minimum"
                      name="te_chlorine_cl_minimum"
                      defaultValue={accounts.te_chlorine_cl_minimum || ""}
onChange={handleChange} {...register("te_chlorine_cl_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chlorine_cl_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chlorine cl maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chlorine cl maximum"
                      name="te_chlorine_cl_maximum"
                      defaultValue={accounts.te_chlorine_cl_maximum || ""}
onChange={handleChange} {...register("te_chlorine_cl_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chlorine_cl_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te fluorine f typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te fluorine f typical"
                      name="te_fluorine_f_typical"
                      defaultValue={accounts.te_fluorine_f_typical || ""}
onChange={handleChange} {...register("te_fluorine_f_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_fluorine_f_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te fluorine f minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te fluorine f minimum"
                      name="te_fluorine_f_minimum"
                      defaultValue={accounts.te_fluorine_f_minimum || ""}
onChange={handleChange} {...register("te_fluorine_f_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_fluorine_f_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te fluorine f maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te fluorine f maximum"
                      name="te_fluorine_f_maximum"
                      defaultValue={accounts.te_fluorine_f_maximum || ""}
onChange={handleChange} {...register("te_fluorine_f_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_fluorine_f_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te mercury hg typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te mercury hg typical"
                      name="te_mercury_hg_typical"
                      defaultValue={accounts.te_mercury_hg_typical || ""}
onChange={handleChange} {...register("te_mercury_hg_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_mercury_hg_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te mercury hg minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te mercury hg minimum"
                      name="te_mercury_hg_minimum"
                      defaultValue={accounts.te_mercury_hg_minimum || ""}
onChange={handleChange} {...register("te_mercury_hg_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_mercury_hg_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te mercury hg maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te mercury hg maximum"
                      name="te_mercury_hg_maximum"
                      defaultValue={accounts.te_mercury_hg_maximum || ""}
onChange={handleChange} {...register("te_mercury_hg_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_mercury_hg_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te molybdenum mo typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te molybdenum mo typical"
                      name="te_molybdenum_mo_typical"
                      defaultValue={accounts.te_molybdenum_mo_typical || ""}
onChange={handleChange} {...register("te_molybdenum_mo_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_molybdenum_mo_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te molybdenum mo minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te molybdenum mo minimum"
                      name="te_molybdenum_mo_minimum"
                      defaultValue={accounts.te_molybdenum_mo_minimum || ""}
onChange={handleChange} {...register("te_molybdenum_mo_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_molybdenum_mo_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te molybdenum mo maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te molybdenum mo maximum"
                      name="te_molybdenum_mo_maximum"
                      defaultValue={accounts.te_molybdenum_mo_maximum || ""}
onChange={handleChange} {...register("te_molybdenum_mo_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_molybdenum_mo_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te lead pb typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te lead pb typical"
                      name="te_lead_pb_typical"
                      defaultValue={accounts.te_lead_pb_typical || ""}
onChange={handleChange} {...register("te_lead_pb_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_lead_pb_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te lead pb minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te lead pb minimum"
                      name="te_lead_pb_minimum"
                      defaultValue={accounts.te_lead_pb_minimum || ""}
onChange={handleChange} {...register("te_lead_pb_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_lead_pb_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te lead pb maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te lead pb maximum"
                      name="te_lead_pb_maximum"
                      defaultValue={accounts.te_lead_pb_maximum || ""}
onChange={handleChange} {...register("te_lead_pb_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_lead_pb_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te phosphorus p typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te phosphorus p typical"
                      name="te_phosphorus_p_typical"
                      defaultValue={accounts.te_phosphorus_p_typical || ""}
onChange={handleChange} {...register("te_phosphorus_p_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_phosphorus_p_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te phosphorus p minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te phosphorus p minimum"
                      name="te_phosphorus_p_minimum"
                      defaultValue={accounts.te_phosphorus_p_minimum || ""}
onChange={handleChange} {...register("te_phosphorus_p_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_phosphorus_p_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te phosphorus p maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te phosphorus p maximum"
                      name="te_phosphorus_p_maximum"
                      defaultValue={accounts.te_phosphorus_p_maximum || ""}
onChange={handleChange} {...register("te_phosphorus_p_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_phosphorus_p_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te selenium se typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te selenium se typical"
                      name="te_selenium_se_typical"
                      defaultValue={accounts.te_selenium_se_typical || ""}
onChange={handleChange} {...register("te_selenium_se_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_selenium_se_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te selenium se minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te selenium se minimum"
                      name="te_selenium_se_minimum"
                      defaultValue={accounts.te_selenium_se_minimum || ""}
onChange={handleChange} {...register("te_selenium_se_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_selenium_se_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te selenium se maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te selenium se maximum"
                      name="te_selenium_se_maximum"
                      defaultValue={accounts.te_selenium_se_maximum || ""}
onChange={handleChange} {...register("te_selenium_se_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_selenium_se_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te vanadium v typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te vanadium v typical"
                      name="te_vanadium_v_typical"
                      defaultValue={accounts.te_vanadium_v_typical || ""}
onChange={handleChange} {...register("te_vanadium_v_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_vanadium_v_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te vanadium v minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te vanadium v minimum"
                      name="te_vanadium_v_minimum"
                      defaultValue={accounts.te_vanadium_v_minimum || ""}
onChange={handleChange} {...register("te_vanadium_v_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_vanadium_v_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te vanadium v maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te vanadium v maximum"
                      name="te_vanadium_v_maximum"
                      defaultValue={accounts.te_vanadium_v_maximum || ""}
onChange={handleChange} {...register("te_vanadium_v_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_vanadium_v_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te zinc zn typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te zinc zn typical"
                      name="te_zinc_zn_typical"
                      defaultValue={accounts.te_zinc_zn_typical || ""}
onChange={handleChange} {...register("te_zinc_zn_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_zinc_zn_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te zinc zn minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te zinc zn minimum"
                      name="te_zinc_zn_minimum"
                      defaultValue={accounts.te_zinc_zn_minimum || ""}
onChange={handleChange} {...register("te_zinc_zn_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_zinc_zn_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te zinc zn maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te zinc zn maximum"
                      name="te_zinc_zn_maximum"
                      defaultValue={accounts.te_zinc_zn_maximum || ""}
onChange={handleChange} {...register("te_zinc_zn_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_zinc_zn_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                
                
              </Row> 
              <Row className="mb-3">
                <h4 className='heading'>SIZING, %</h4>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size 0 to 50mm typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size 0 to 50mm typical"
                      name="size_0_to_50mm_typical"
                      defaultValue={accounts.size_0_to_50mm_typical || ""}
onChange={handleChange} {...register("size_0_to_50mm_typical", { valueAsNumber:true, required: false })}
                    />
                    {errors.size_0_to_50mm_typical && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size 0 to 50mm min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size 0 to 50mm min"
                      name="size_0_to_50mm_min"
                      defaultValue={accounts.size_0_to_50mm_min || ""}
onChange={handleChange} {...register("size_0_to_50mm_min", { valueAsNumber:true, required: false })}
                    />
                    {errors.size_0_to_50mm_min && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size 0 to 50mm max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size 0 to 50mm max"
                      name="size_0_to_50mm_max"
                      defaultValue={accounts.size_0_to_50mm_max || ""}
onChange={handleChange} {...register("size_0_to_50mm_max", { valueAsNumber:true, required: false })}
                    />
                    {errors.size_0_to_50mm_max && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Above 0 to 50mm typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Above 0 to 50mm typical"
                      name="above_0_to_50mm_typical"
                      defaultValue={accounts.above_0_to_50mm_typical || ""}
onChange={handleChange} {...register("above_0_to_50mm_typical", { valueAsNumber:true, required: false })}
                    />
                    {errors.above_0_to_50mm_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Above 0 to 50mm min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Above 0 to 50mm min"
                      name="above_0_to_50mm_min"
                      defaultValue={accounts.above_0_to_50mm_min || ""}
onChange={handleChange} {...register("above_0_to_50mm_min", { valueAsNumber:true, required: false })}
                    />
                    {errors.above_0_to_50mm_min && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Above 0 to 50mm max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Above 0 to 50mm max"
                      name="above_0_to_50mm_max"
                      defaultValue={accounts.above_0_to_50mm_max || ""}
onChange={handleChange} {...register("above_0_to_50mm_max", { valueAsNumber:true, required: false })}
                    />
                    {errors.above_0_to_50mm_max && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Under 2mm typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Under 2mm typical"
                      name="under_2mm_typical"
                      defaultValue={accounts.under_2mm_typical || ""}
onChange={handleChange} {...register("under_2mm_typical", { valueAsNumber:true, required: false })}
                    />
                    {errors.under_2mm_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Under 2mm min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Under 2mm min"
                      name="under_2mm_min"
                      defaultValue={accounts.under_2mm_min || ""}
onChange={handleChange} {...register("under_2mm_min", { valueAsNumber:true, required: false })}
                    />
                    {errors.under_2mm_min && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Under 2mm max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Under 2mm max"
                      name="under_2mm_max"
                      defaultValue={accounts.under_2mm_max || ""}
onChange={handleChange} {...register("under_2mm_max", { valueAsNumber:true, required: false })}
                    />
                    {errors.under_2mm_max && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size above 2mm typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size above 2mm typical"
                      name="size_above_2mm_typical"
                      defaultValue={accounts.size_above_2mm_typical || ""}
onChange={handleChange} {...register("size_above_2mm_typical", { valueAsNumber:true, required: false })}
                    />
                    {errors.size_above_2mm_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size above 2mm min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size above 2mm min"
                      name="size_above_2mm_min"
                      defaultValue={accounts.size_above_2mm_min || ""}
onChange={handleChange} {...register("size_above_2mm_min", { valueAsNumber:true, required: false })}
                    />
                    {errors.size_above_2mm_min && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> 
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size above 2mm max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size above 2mm max"
                      name="size_above_2mm_max"
                      defaultValue={accounts.size_above_2mm_max || ""}
onChange={handleChange} {...register("size_above_2mm_max", { valueAsNumber:true, required: false })}
                    />
                    {errors.size_above_2mm_max && <span>Required </span>}
                  </FloatingLabel>
                  </Form.Group> 
    
              </Row> 
              <Row className="mb-3">
                <h4 className='heading'>Testing Method</h4>
                <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Testing Method">
    <Form.Select aria-label="Floating label select example"
    name="testing_method" 
    placeholder="Testing method" 
    defaultValue={accounts.testing_method || ""}
onChange={handleChange} {...register("testing_method", { required: false })}
    >

<option value='' >Select </option> 
<option value='According to ASTM Standard Method Unless Otherwise Stated' >According to ASTM Standard Method Unless Otherwise Stated</option> 
<option value='According to ISO Methods'>According to ISO Methods</option>
<option value='According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM'>According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM</option>
<option value='According to ISO Methods Except HGI to ASTM'>According to ISO Methods Except HGI to ASTM</option>
<option value='According to ISO Methods, Except HGI and Ash Analysis to ASTM'>According to ISO Methods, Except HGI and Ash Analysis to ASTM</option>
<option value='According to ISO Methods, Except HGI as per ASTM Test Method'>According to ISO Methods, Except HGI as per ASTM Test Method</option>
    </Form.Select>
    {errors.testing_method && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>  
              </Row>  
             
      <input type="submit"  className="addaccount-save" value='Save'/>
      <div className='successDiv'><p></p><p></p></div>
    </Form>
    <p></p><p></p>  
    </div>
    <Footer/>
    
</>
    );

  
 

};


export default EditQuoteProduct;