  
import React, {
  Component,
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo
} from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import { AiFillDiff } from "react-icons/ai";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import DataTable from "react-data-table-component";
import Popup from "reactjs-popup";
import { API_URL } from '../config';
import "reactjs-popup/dist/index.css";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import $ from "jquery";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { AiOutlineCloseCircle } from "react-icons/ai";

import Header from "../Header";
import Footer from "../Footer";
import { set } from "date-fns";
import { DropDownMenu } from "material-ui";
// import FilterComponent from '../filterComponent';
const FilterComponent = ({ onFilter, onClear, filterText }) => {
  return (
    <div>
      <input
        type="text"
        placeholder="Search"
        value={filterText}
        onChange={onFilter}
        className="table-search"
      />
      {/* <button onClick={onClear} ><AiOutlineCloseCircle /></button> */}
    </div>
  );
};


const Createproductmasterlist = () => {
  

  const [selectedOption, setSelectedOption] = useState('');

  const navigate = useNavigate();
  const { selectedView, setSelectedViews, allTableData, setAllTableData, otherTableData, setOtherTableData } = useContext(TokenContext);
  const [contactId, setContactId]=useState([]);

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [showAllTableView, setShowAllTableView] = useState(selectedView === "all");
  const [accountId, setAccountId]=useState([])
  const [dropId, setDropId]=useState('')
  const [randomDropdownValue, setRandomDropdownValue] = useState('recently_created');
  const [keysAlldata,setKeys]=useState([])
  const[allKeys, setAllkeys]=useState([])
  const[ids,setID]=useState([])






 
    const fetchDropdownOptions = async () => {

      try {
        const response = await axios.get(`${API_URL}get/all/contractcreateview`);
        const options = response.data.contract_create_view;
        console.log(options)
        setID(options[0].id)

        setDropdownOptions(options);
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
        setDropdownOptions([]);
      }
    };

    useEffect(() => {
      fetchDropdownOptions();
    }, [decryptedAccessToken]);



  const [filterItems, setFilteredItems]=useState([])
  const [columnsData, setColumnsData]=useState([])
  const [filterText, setFilterText] = useState('');
  useEffect(() => {
    const fetchData = async () => {
        try {
            let response, data;
            if (selectedView === 'all') {
                response = await axios.get(`${API_URL}get/all/contract?random_dropdown_option=recently_created`, {
                    headers: { 
                        Authorization: `Bearer ${decryptedAccessToken}`,
                        'Content-Type': 'application/json' 
                    },
                });
                data = response.data.contract;
                console.log(response);

                setAllTableData(data);

                const columns = [
                    {
                        name: 'Rwood Contract Number',
                        selector: (row, index) => row.rwood_contract_number,
                    },
                    {
                        name: 'account name',
                        selector: (row, index) => row.account_name
                    },
                    {
                        name: 'origin',
                        selector: row => row.origin,
                    },
                    {
                        name: 'contract record type',
                        selector: row => row.contract_record_type,
                    },
                    // ... other columns
                ];

                const filteredItems = data.filter(item =>
                    Object.values(item).some(val =>
                        val && val.toString().toLowerCase().includes(filterText.toLowerCase())
                    )
                ).map(item => ({
                    rwood_contract_number: <Link to={`/contract/contract-details/${item.id}`}>{item.rwood_contract_number}</Link>,
                    account_name: <Link to={`/accounts/${item.account_id}`}>{item.account_name}</Link>,
                    origin: item.origin,
                    contract_record_type: item.contract_record_type
                    // Add other properties as needed
                }));

                setColumnsData(columns);
                setFilteredItems(filteredItems);
            } else if (selectedView) {
                response = await axios.get(`${API_URL}get/contract/view/${selectedView}`, {
                    headers: {
                        Authorization: `Bearer ${decryptedAccessToken}`,
                        'Content-Type': 'application/json'
                    },
                });

                data = response.data.contracts; // Assuming the response contains the data for the selected view
                console.log(data);

                setOtherTableData(data); // Assuming "contracts" is the array of data for the specific view
                const account_keys = Object.keys(data[0]);
                setAccountId(data[0].id);
                setAllkeys(account_keys);

                setTimeout(() => {
                    $('.table').dataTable();
                }, 50);
            }
        } catch (error) {
            console.error('Error fetching data:', error);

            if (error.response && error.response.status === 422) {
                console.error('Validation error:', error.response.data);
                // Handle specific validation error response if needed
            }

            setAllTableData([]);
            setOtherTableData([]);
        }
    };

    fetchData();
}, [selectedView, filterText, decryptedAccessToken, setAllTableData, setOtherTableData, setColumnsData, setFilteredItems, setAllkeys, setAccountId]);


  useEffect(() => {
    const storedView = localStorage.getItem('selectedView');
    if (storedView && dropdownOptions.find(opt => opt.view_name === storedView)) {
      setSelectedViews(storedView);
      setShowAllTableView(storedView === 'all');
    } else {
      // If there's no stored view or it's not valid, set the default view to 'all'
      setSelectedViews('all');
      setShowAllTableView(true);
      localStorage.setItem('selectedView', 'all');
    }
  }, [dropdownOptions])


  const [formData, setFromdata] = useState({

    account_record: "",

  });


  const [error, setError] = useState({});
  const validate = () => {
    const newErrors = {};
    if (!formData.account_record) {
      newErrors.account_record = 'Contract Record Type is required';
    }
    return newErrors;
  };
  const handleChange = (e) => {

    setFromdata({ ...formData, [e.target.name]: e.target.value });

  };

  

const handleRandomDropdownChange = (event) => {
  const selectedValue = event.target.value;
  const encryptedAccessToken = localStorage.getItem('access_token');
  const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
  if (encryptedAccessToken) {
    const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
    fetch(`${API_URL}get/all/contract?random_dropdown_option=${selectedValue}`,{
      headers: { Authorization: `Bearer ${decryptedAccessToken}`},

    })
      .then(response => response.json())
      .then(data => {

        setAllTableData(data);
      })
      .catch(error => {
        console.error('Error fetching data from backend:', error);
        setAllTableData([]);
      });

    setRandomDropdownValue(selectedValue);}

};


const handleDropdownChange = (event) => {
  const index = event.target.selectedIndex;
  const el = event.target.childNodes[index];
  const dropdownId = el.getAttribute('id');
  console.log(dropdownId);
  setDropId(dropdownId);
  const viewName = event.target.value;
  console.log(viewName);
  setSelectedViews(viewName);
  setShowAllTableView(viewName === 'all');
  setSelectedOption(event.target.value);
  localStorage.setItem('selectedView', viewName);




};


const handleSubmit = (event) => {

  event.preventDefault();
  const validationErrors = validate();
  if (Object.keys(validationErrors).length > 0) {
    setError(validationErrors);
  } else {
  const encryptedAccessToken = localStorage.getItem('access_token');
  const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
  if (encryptedAccessToken) {
    const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
    
  fetch(`${API_URL}add/record`, {
            method: "POST",
            body: JSON.stringify(formData),
            headers: { Authorization: `Bearer ${decryptedAccessToken}`,
            'Content-Type': 'application/json' },
          })
            .then((response) => response.json())
            
            .then((response) => { 
              
                console.log(response)
                console.log(response.status)
                const record_type_new = response.account_type;
                                
                navigate("/contract/add-contract", {
    
                  state: { account_record: record_type_new },
      
                });
              // if (response.message === "record addedd successfully") {
                               
                      
              //                 }
            }) 
          } 
        }
  };


  
const editView=()=>{ 
  navigate('/contract/edit-view',{
    state: { accountIds: accountId, pageID:dropId },
  })
}

 
// all table
 
 
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  
  
  
   
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);


 

 
// other table
console.log(otherTableData)
const columns = Object.keys(otherTableData[0] || {}).map((key) => {
   

  if (key === 'rwood_contract_number') {
    return {
      name: key.replace(/_/g, " "),
      selector: row => (
        <Link to={`/contract/contract-details/${row.id}`}>
          {row.rwood_contract_number}
        </Link>
      ),
    };
  }
  if (key === 'account_name') {
    return {
      name: key.replace(/_/g, " "),
      selector: row => (
        <Link to={`/accounts/${row.id}`}>
          {row.account_name}
        </Link>
      ),
    };
  }
 

  return key === 'id' 
    ? null  // Exclude the 'id' column
    : {
        name: key.replace(/_/g, " "),
        selector: row => row[key],
        // Additional properties for each column, if needed
      };
}).filter(columns => columns !== null);

const [filterOther, setFilterOther] = useState('');
  const [resetPaginationToggleOther, setResetPaginationToggleOther] = React.useState(false);
  // const filteredItemsOther = otherTableData.filter(item => item.rwood_contract_no && item.rwood_contract_no.toLowerCase().includes(filterOther.toLowerCase()));
 
  
  
  
  const filteredItemsOther = otherTableData.filter(item => {
    // Check if any property in the item contains the filter text
    for (const key in item) {
      if (item[key] && item[key].toString().toLowerCase().includes(filterOther.toLowerCase())) {
        return true;
      }
    }
    return false;

    
  });
  
  const subHeaderComponentMemoOther = React.useMemo(() => {
    const handleClear = () => {
      if (setResetPaginationToggleOther) {
        setResetPaginationToggleOther(!resetPaginationToggleOther);
        setFilterOther('');
      }
    };
    return <FilterComponent onFilter={e => setFilterOther(e.target.value)} onClear={handleClear} filterOther={filterOther} />;
  }, [filterOther, resetPaginationToggleOther]);
 



  const deleteCreateview = (event) => {

    event.preventDefault();
  
    const isConfirmed = window.confirm("Are you sure you want to delete?");
  
    if (!isConfirmed) {
      // User canceled the deletion
      return;
    }
  
    const encryptedAccessToken = localStorage.getItem('access_token');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
  
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
    
      fetch(`${API_URL}delete/contract_create_view/${dropId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          'Content-Type': 'application/json'
        },
      })
      .then((response) => {
        if (response.ok) { 
          return response.json();
        } else {
          // Handle non-successful response (status not in 200-299 range)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      })
      .then((data) => {
        // Handle successful response data
        console.log(data);
        fetchDropdownOptions();
      })
      .catch((error) => {
        console.error('Error deleting:', error.message);
        // Handle network errors or non-successful responses here
      });
    }
    
    };

  return (
    <>
      <Header />
      <div className="contactAccount">
        <div className="page-header" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5"> Contract </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / contarct
              </li>
            </ol>
          </div>
          <div className="d-flex">
          <div className="justify-content-center">
          <Popup trigger={<button className="button add-accountrecord"> <FaPlus/> Add Contract </button>} modal>
  
  <form onSubmit={handleSubmit} className="add-accounts">
   <div className='form-group'>
   <label>Account Record Type</label><br/>
   <select name='account_record' className='form-control' onChange={handleChange} value={formData.account_record}>
      <option >--Select Contract Type--</option>
      <option value='Buyer - Long Term'>Buyer Long Term</option>
      <option value='Buyer - Spot'>Buyer Spot</option>
      <option value='Supplier - Long Term'>Supplier Long Term</option>
      {/* <option value='Supplier'>Supplier</option> */}
      <option value='Supplier - Spot'>Supplier Spot</option>
      
     </select>
     {error.account_record && <div className="errors-msg">{error.account_record}</div>}
     </div>
     <input type='submit' className="btn btn-primary " value='Submit'/>

     </form>
</Popup>
       {/* <Popup trigger={<button className="button add-accountrecord"> <FaPlus/> Add Account </button>} modal>
  
  <form onSubmit={handleSubmit} className="add-accounts">
   <div className='form-group'>
   <label>Account Record Type</label><br/>
   <select name='account_record' className='form-control' onChange={handleChange} value={formData.account_record}>
     <option >--Select account record type--</option> 
             <option value="Agent">Agent</option>
             <option value="Bank">Bank</option>
             <option value="Buyer">Buyer</option>

             <option value="Supplier">Supplier</option>
             <option value="Competitor">Competitor</option>
             <option value="Employee">Employee</option>
             <option value="Surveyor">Surveyor</option>
     
     </select>
     </div>
     <input type='submit' className="btn btn-primary " value='Submit'/>

     </form>
</Popup> */}
</div>
        </div>
        </div>
        <div className="accounts-page">
          <div className="row">
            <div className="col-md-6">
              <form  className="d-flex dropdown-form">

                <label for="pwd" className="mr-sm-2">
                  View
                </label>
                <select
  onChange={handleDropdownChange}
  className='form-control view-select'
  value={selectedView}
  defaultValue="all">
        <option value="">Select a View</option>
        <option value="all" >All Views</option>
        {dropdownOptions.length>0 ?(
          <>
        {dropdownOptions.map((option, index) => (
          <option key={index} id={option.id} value={option.view_name}>
            {option.view_name}
          </option>
        ))}
        </>
        ):(<option className="no-data">No data available</option>)}
      </select>


 
              </form>
            </div>
            <div className="col-md-6 links">
             
          <p>
               <span onClick={editView}>Edit</span> |{selectedOption && (
       <span onClick={deleteCreateview}>Delete | </span>
      )} <Link to="/contract/create-view">Create New View</Link>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
            <h6 className="d-flex justify-content-between align-items-center">
            <span>Recent Accounts</span>

            {selectedView === 'all' && allTableData && (
          <div className="random- d-flex">
            <select
         dropdowns     className='form-control'
              onChange={handleRandomDropdownChange}
              value={randomDropdownValue}
              defaultValue="recently_created"

            >
              <option value="recently_created">Recently Created</option>
              <option value="recently_modified">Recently Modified</option>
              <option value="recently_viewed">Recently Viewed</option>
            </select>
          </div>
        )}
          </h6>
          <div className="create-view-table">
              {showAllTableView  && (
                
                <DataTable  columns={columnsData} data={filterItems} pagination  // optionally, a hook to reset pagination to page 1
                subHeader subHeaderComponent={subHeaderComponentMemo} fixedHeader selectableRows persistTableHead />
              )}
                 {!showAllTableView && selectedView && (
        <div>
          {otherTableData.length > 0 ?(

<DataTable  columns={columns.map((column) => ({
  name: column.name.replace(/_/g, " "),
  selector: column.selector,
  // Add other properties as needed
}))} data={filteredItemsOther} fixedHeader pagination  // optionally, a hook to reset pagination to page 1
subHeader subHeaderComponent={subHeaderComponentMemoOther} 
fixedHeaderScrollHeight="500px"   selectableRows persistTableHead />



              ):(
                <table class="table table-hover table-bordered create-table">

                  <tr>
                    <th><b>Account Code</b></th>
                    <th><b>Account Name</b></th>
                    <th><b>Account Alias</b></th>
                    <th><b>Account Record Type</b></th>
                  </tr>



                   <tr>
                    <td align='center' colSpan={4}> No Data Available</td>
                    </tr>


              </table>
                  )}
        </div>
      )}
      </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-6">
            <div className="bg-info">
              <h6>
                <span className="pull-left">Reports</span>
              </h6>
              <div className="account-reports">
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
              </div>
            </div>
            </div>
           <div className="col-md-6">
            <div className="bg-info">
              <h6>
                <span className="pull-left">Tools</span>
              </h6>
              <div className="account-reports">
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
              </div>
            </div>
            </div>

          </div> */}
        </div>

      </div>
      <Footer />
    </>
  );
};

export default Createproductmasterlist;
