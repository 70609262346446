import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header' 
import Footer from '../Footer' 
import $ from 'jquery';
import { API_URL } from '../config';
import axios from "axios";
import { useParams  } from "react-router-dom"; 
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function UpdatePricebook() {
  
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  console.log(total_height)
  console.log(body)

  const params = useParams()
  const idUpdate=params.id 
  $(".contactAccount").css({"min-height":(window_height)+"px"})
  const [update,setUpdate]=useState([]);
      useEffect(() => {
   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/pricebook_by_ID/'+idUpdate, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const data = response.data;
      console.log(data)
      setUpdate(data);
      setValue('month', data.month);
      setValue('year', data.year);
      setValue('description', data.description);
      setValue('created_by', data.created_by);
      setValue('last_modified_by', data.last_modified_by);
      setValue('projected_HBA', data.projected_HBA);
      setValue('projected_GCNEWC', data.projected_GCNEWC);
      setValue('actual_GCNEWC_for_the_month', data.actual_GCNEWC_for_the_month);
      setValue('applicable_GCNEWC_for_the_month', data.applicable_GCNEWC_for_the_month);
      setValue('avg_GCNEWC_prev_QTR', data.avg_GCNEWC_prev_QTR);
      setValue('avg_GCNEWC_current_QTR', data.avg_GCNEWC_current_QTR);
      setValue('actual_HBA', data.actual_HBA);
      setValue('actual_HBA_1', data.actual_HBA_1);
      setValue('actual_HBA_2', data.actual_HBA_2);
      setValue('ici_4_4200_GAR', data.ici_4_4200_GAR);
      setValue('ici_5_3400_GAR', data.ici_5_3400_GAR);
      setValue('platts_K5900', data.platts_K5900);
      setValue('Nex', data.Nex);
      setValue('des_ARA', data.des_ARA);
      setValue('RB', data.RB); 
    } catch (error) {
      console.error(error);
    }
  };
  

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const onSubmit = data =>{ 
        const responseData={
          month:data.month,
          year:data.year, 
          description:data.description,
          created_by:data.created_by,
          last_modified_by:data.last_modified_by,
          projected_HBA:data.projected_HBA,
          projected_GCNEWC:data.projected_GCNEWC,
          actual_GCNEWC_for_the_month:data.actual_GCNEWC_for_the_month,
          applicable_GCNEWC_for_the_month:data.applicable_GCNEWC_for_the_month,
          avg_GCNEWC_prev_QTR:data.avg_GCNEWC_prev_QTR,
          avg_GCNEWC_current_QTR:data.avg_GCNEWC_current_QTR,
          actual_HBA:data.actual_HBA,
          actual_HBA_1:data.actual_HBA_1,
          actual_HBA_2:data.actual_HBA_2,
          ici_4_4200_GAR:data.ici_4_4200_GAR,
          ici_5_3400_GAR:data.ici_5_3400_GAR,
          platts_K5900:data.platts_K5900,
          Nex:data.Nex,
          des_ARA:data.des_ARA,
          RB:data.RB
        
      }; 
      console.log(JSON.stringify(responseData))
      const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
          
        fetch(API_URL+'update/pricebook/'+idUpdate, {
                  method: "PUT",
                  body: JSON.stringify(responseData),
                  headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json' },
                })
                  .then((response) => response.json())
                  
                  .then((response) => {  
                    
      if(response.message==" pricebook 1 updated successfully"){
      toast.success('Pricebook Updated Successfully', {
                      position: "bottom-center",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      });
    }
                  })
                 
                } 
                 
        };
        const handleChange = (e) => {
          const { name, value } = e.target;
          setUpdate((prevData) => ({
            ...prevData,
            [name]: value
          }));
        };
      
  return (

    <div className="addAccount">
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Edit pricebook</h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Edit Pricebook </li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div>    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
        <h4 className='heading'>Account Information</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Month"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Month"
              name="month" 
              defaultValue={update.month || ""}  onChange={handleChange} 
              {...register("month", { required: false })}
            /> 
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Year"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Year"
              name="year"
              defaultValue={update.year || ""}  onChange={handleChange} 
              {...register("year", {valueAsNumber:true, required: false })}
            /> 
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Description"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Description"
              name="description"
              defaultValue={update.description || ""}  onChange={handleChange} 
              {...register("description", { required: false })}
            /> 
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Created By"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Created By"
              name="created_by"
              defaultValue={update.created_by || ""}  onChange={handleChange} 
              {...register("created_by", { required: false })}
            />
             
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Last Modified By "
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Last Modified By"
              name="last_modified_by"
              defaultValue={update.last_modified_by || ""}  onChange={handleChange} 
              {...register("last_modified_by", { required: false })}
            /> 
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected HBA"
            className="mb-3"
            
            
          >
            <Form.Control
              type="number"
              placeholder="Projected HBA"
              name="projected_HBA"
              defaultValue={update.projected_HBA || ""}  onChange={handleChange} 
              {...register("projected_HBA", {valueAsNumber:true, required: false })}
            /> 
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected GCNEWC"
            className="mb-3"
            
            
          >
            <Form.Control
              type="number"
              placeholder="Projected GCNEWC"
              name="projected_GCNEWC"
              defaultValue={update.projected_GCNEWC || ""}  onChange={handleChange} 
              {...register("projected_GCNEWC", {valueAsNumber:true, required: false })}
            />
             
          </FloatingLabel>
        </Form.Group> 
      
        
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual GCNEWC for the month"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Actual GCNEWC for the month"
              name="actual_GCNEWC_for_the_month"
              defaultValue={update.actual_GCNEWC_for_the_month || ""}  onChange={handleChange} 
              {...register("actual_GCNEWC_for_the_month", {valueAsNumber:true, required: false })}
            />
           
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Applicable GCNEWC for the month"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Applicable GCNEWC for the month"
              name="applicable_GCNEWC_for_the_month"
              defaultValue={update.applicable_GCNEWC_for_the_month || ""}  onChange={handleChange} 
              {...register("applicable_GCNEWC_for_the_month", {valueAsNumber:true, required: false })}
            />
           
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Avg GCNEWC prev QTR"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Avg GCNEWC prev QTR"
              name="avg_GCNEWC_prev_QTR"
              defaultValue={update.avg_GCNEWC_prev_QTR || ""} 
               onChange={handleChange} 
              {...register("avg_GCNEWC_prev_QTR", { valueAsNumber:true,required: false })}
            />
             
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Avg GCNEWC current QTR"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Avg GCNEWC current QTR"
              name="avg_GCNEWC_current_QTR"
              defaultValue={update.avg_GCNEWC_current_QTR || ""}  onChange={handleChange} 
              {...register("avg_GCNEWC_current_QTR", { valueAsNumber:true,required: false })}
            /> 
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual HBA"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Actual HBA"
              name="actual_HBA"
              defaultValue={update.actual_HBA || ""}  onChange={handleChange} 
              {...register("actual_HBA", { valueAsNumber:true,required: false })}
            /> 
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual HBA 1"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Actual HBA 1"
              name="actual_HBA_1"
              defaultValue={update.actual_HBA_1 || ""}  onChange={handleChange} 
              {...register("actual_HBA_1", {valueAsNumber:true, required: false })}
            /> 
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual HBA 2"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Actual HBA 2"
              name="actual_HBA_2"
              defaultValue={update.actual_HBA_2 || ""}  onChange={handleChange} 
              {...register("actual_HBA_2", {valueAsNumber:true, required: false })}
            /> 
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Ici 4 4200 GAR "
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Ici 4 4200 GAR "
              name="ici_4_4200_GAR"
              defaultValue={update.ici_4_4200_GAR || ""}  onChange={handleChange} 
              {...register("ici_4_4200_GAR", {valueAsNumber:true, required: false })}
            /> 
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ici 5 3400 GAR"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="ici 5 3400 GAR"
              name="ici_5_3400_GAR"
              defaultValue={update.ici_5_3400_GAR || ""}  onChange={handleChange} 
              {...register("ici_5_3400_GAR", {valueAsNumber:true, required: false })}
            /> 
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="platts K5900"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="platts K5900"
              name="platts_K5900"
              defaultValue={update.platts_K5900 || ""}  onChange={handleChange} 
              {...register("platts_K5900", { required: false })}
            /> 
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Nex"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Nex"
              name="Nex"
              defaultValue={update.Nex || ""}  onChange={handleChange} 
              {...register("Nex", {valueAsNumber:true, required: false })}
            /> 
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Des ARA "
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Des ARA "
              name="des_ARA"
              defaultValue={update.des_ARA || ""}  onChange={handleChange} 
              {...register("des_ARA", { required: false })}
            /> 
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="RB"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="RB"
              name="RB"
              defaultValue={update.RB || ""}  onChange={handleChange} 
              {...register("RB", {valueAsNumber:true, required: false })}
            /> 
          </FloatingLabel>
        </Form.Group>

             </Row>
      <input type="submit"  className="addaccount-save" value='Update'/>
      <div className='successDiv'><p></p><p></p></div>
    </Form>
    <Footer/>
    </div>
  );
}

export default UpdatePricebook;
