
import React, { useEffect, useState, forwardRef } from "react";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header' 
import Footer from '../Footer' 
import { API_URL } from '../config';
import $ from 'jquery';
import { parse, format , isValid } from 'date-fns';
import axios from "axios";  
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams, useNavigate, Link } from "react-router-dom";
import ContractShippment from "./contractShippment";
const AddProductShipment = () => {
  
  const location=useLocation();
  const { contractshipment, buyercontract }=location.state;
  // console.log(contractshipmentID)


  const navigate=useNavigate();
  const params = useParams();
  const id = params.id;
  

const [productAccount, setProductaccount]=useState([]);
const [suppliers, setsupplierData]=useState([]);
const [getPrice, setgetPrice]=useState([]);
// price
useEffect(() => {
   
  quotes_names();
}, [decryptedAccessToken]);

 
const quotes_names = async () => {
  try {
    const encryptedAccessToken = localStorage.getItem("access_token");
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);
      const response = await axios.get(
        `${API_URL}get/quoteshipment_by_offer/${id}`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
// alert(JSON.stringify(response))
setShippment(response.data.offer_quote_shipment);
console.log(response)
    }
  } catch (error) {
    console.log(error);
  }
};

  const [startDate1, setStartDate1] = useState(new Date());
  
   const start_date=startDate1.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
   
  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      defaultValue={value}
      className="custom-input"
      onClick={onClick}
      name="birthdate"
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  

  const [startDate2, setStartDate2] = useState(new Date());
  
   const start_date2=startDate1.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
    
  const CustomInput2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      defaultValue={value}
      className="custom-input"
      onClick={onClick}
      name="birthdate"
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  

  const[contractsData, setContracts]=useState([])
useEffect(() => {
  fetchDataPrice();
}, [decryptedAccessToken]);

const fetchDataPrice = async () => {
  try {
    const response = await axios.get(API_URL+'get/all/price_items', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data.priceitem;
    console.log(data)
    setgetPrice(data);
     
  } catch (error) {
    console.error(error);
  }
};

  const [productCode, setProductCode] = useState("");
  const [productValues, setProductValues] = useState([]);
  const [products, setproducts] = useState([]);
  const [shippment, setShippment]=useState([]);
// product supplier
useEffect(() => {   
  fetchDataSupplier();
}, [decryptedAccessToken]);

const fetchDataSupplier = async () => {
  try {
    const response = await axios.get(API_URL+'get/supplier', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data;
    // console.log(data)
    setsupplierData(data);
     
  } catch (error) {
    console.error(error);
  }
};

  // product account
  useEffect(() => {   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'all/accounts', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.accounts; 
      console.log(data)
      setProductaccount(data);
       
    } catch (error) {
      console.error(error);
    }
  };



  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const fromDate = startDate ? `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}` : null;
  const toDate = endDate ? `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() + 1).toString().padStart(2, '0')}/${endDate.getFullYear()}` : null;
  
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };



const [formData, setFormData] = useState({
    locked:"",
supplier:"",
quotes_status:"",
quotes_name:"", 
no_of_product:"",
no_of_quote_shipment:"", 
quote_shipment:"",
product_code:"",
product_name:"",
product_account:"",
product_supplier:"",
product_description:"",
product_family:"",
product_shipping_term:contractsData.product_shipping_term||"",
product_owner:"",
buyer_contract:contractsData.contract|| "",
product_active:"",
short_term_available:"",
product_price_basis:"",
product_price_type:contractsData.product_price_type||"",
product_port_of_loading:"",
total_moisture_typical:"",
total_moisture_minimum:"",
total_moisture_maximum:"",
ash_typical_arb:"",
ash_minimum_arb:"",
ash_maximum_arb:"",
sulphur_typical_arb:"",
sulphur_minimum_arb:"",
sulphur_maximum_arb:"",
volatile_matter_typical_arb:"",
volatile_matter_minimum_arb:"",
volatile_matter_maximum_arb:"",
 
moisture_typical:"",
moisture_minimum:"",
ash_typical:"",
ash_minimum:"",
ash_maximum:"",
 
volatile_matter_typical:"",
volatile_matter_minimum:"",
volatile_matter_maximum:"",
fixed_carbon_by_difference:"",
fixed_carbon_typical:"",
fixed_carbon_minimum:"",
fixed_carbon_maximum:"",
 
gross_air_dried_typical:"",
gross_air_dried_min:"",
gross_air_dried_max:"",
gross_as_recieved_min:"",
gross_as_recieved_max:"",
net_as_recieved_min:"",
net_as_recieved_max:"",
 
hgi_typical:"",
hgi_min:"",
hgi_max:"",
 
carbon_typical:"",
carbon_min:"",
carbon_max:"",
 
hydrogen_typical:"",
hydrogen_min:"",
hydrogen_max:"",
 
nitrogen_typical:"",
nitrogen_min:"",
nitrogen_max:"",
 
sulphur_typical:"",
sulphur_min:"",
sulphur_max_ultimate_analysis:"",
oxygen_typical:"",
oxygen_min:"",
oxygen_max:"",
 
sulphur_typical_adb:"",
sulphur_min_adb:"",
product_sulphur_max:"",
 
initial_typical:"",
initial_minimum:"",
initial_maximum:"",
spherical_typical:"",
spherical_minimum:"",
spherical_maximum:"",
hemispherical_typical:"",
hemispherical_minimum:"",
hemispherical_maximum:"",
flow_typical:"",
flow_minimum:"",
flow_maximum:"",
 
oxidising_initial_typical:"",
oxidising_initial_minimum:"",
oxidising_initial_maximum:"",
oxidising_spherical_typical:"",
oxidising_spherical_minimum:"",
oxidising_spherical_maximum:"",
oxidising_hemispherical_typical:"",
oxidising_hemispherical_minimum:"",
oxidising_hemispherical_maximum:"",
oxidising_flow_typical:"",
oxidising_flow_minimum:"",
oxidising_flow_maximum:"",
 
SiO2_Typical:"",
SiO2_Minimum:"",
SiO2_Maximum:"",
Al2O3_Typical:"",
Al2O3_Minimum:"",
Al2O3_Maximum:"",
Fe2O3_Typical:"",
Fe2O3_Minimum:"",
Fe2O3_Maximum:"",
CaO_Typical:"",
CaO_Minimum:"",
CaO_Maximum:"",
MgO_Typical:"",
MgO_Minimum:"",
MgO_Maximum:"",
MnO_Typical:"",
MnO_Minimum:"",
MnO_Maximum:"",
TiO2_Typical:"",
TiO2_Minimum:"",
TiO2_Maximum:"",
Na2O_Typical:"",
Na2O_Minimum:"",
Na2O_Maximum:"",
K2O_Typical:"",
K2O_Minimum:"",
K2O_Maximum:"",
P2O5_Typical:"",
P2O5_Minimum:"",
P2O5_Maximum:"",
SO3_Typical:"",
SO3_Minimum:"",
SO3_Maximum:"",
te_arsenic_as_typical_in_air_dried_coal:"",
te_arsenic_as_minimum_in_air_dried_coal:"",
te_arsenic_as_maximum_in_air_dried_coal:"",
te_antimony_sb_typical_in_air_dried_coal:"",
te_antimony_sb_minimum_in_air_dried_coal:"",
te_antimony_sb_maximum_in_air_dried_coal:"",
te_beryllium_be_typical_in_air_dried_coal:"",
te_beryllium_be_minimum_in_air_dried_coal:"",
te_beryllium_be_maximum_in_air_dried_coal:"",
te_boron_b_typical_in_air_dried_coal:"",
te_boron_b_minimum_in_air_dried_coal:"",
te_boron_b_maximum_in_air_dried_coal:"",
te_cadmium_cd_typical_in_air_dried_coal:"",
te_cadmium_cd_minimum_in_air_dried_coal:"",
te_cadmium_cd_maximum_in_air_dried_coal:"",
te_chromium_cr_typical_in_air_dried_coal:"",
te_chromium_cr_minimum_in_air_dried_coal:"",
te_chromium_cr_maximum_in_air_dried_coal:"",
te_copper_cu_typical_in_air_dried_coal:"",
te_copper_cu_minimum_in_air_dried_coal:"",
te_copper_cu_maximum_in_air_dried_coal:"",
te_chlorine_cl_typical_in_air_dried_coal:"",
te_chlorine_cl_minimum_in_air_dried_coal:"",
te_chlorine_cl_maximum_in_air_dried_coal:"",
te_fluorine_f_typical_in_air_dried_coal:"",
te_fluorine_f_minimum_in_air_dried_coal:"",
te_fluorine_f_maximum_in_air_dried_coal:"",
te_mercury_hg_typical_in_air_dried_coal:"",
te_mercury_hg_minimum_in_air_dried_coal:"",
te_mercury_hg_maximum_in_air_dried_coal:"",
te_molybdenum_mo_typical_in_air_dried_coal:"",
te_molybdenum_mo_minimum_in_air_dried_coal:"",
te_molybdenum_mo_maximum_in_air_dried_coal:"",
te_lead_pb_typical_in_air_dried_coal:"",
te_lead_pb_minimum_in_air_dried_coal:"",
te_lead_pb_maximum_in_air_dried_coal:"",
te_phosphorus_p_typical_in_air_dried_coal:"",
te_phosphorus_p_minimum_in_air_dried_coal:"",
te_phosphorus_p_maximum_in_air_dried_coal:"",
te_selenium_se_typical_in_air_dried_coal:"",
te_selenium_se_minimum_in_air_dried_coal:"",
te_selenium_se_maximum_in_air_dried_coal:"",
te_vanadium_v_typical_in_air_dried_coal:"",
te_vanadium_v_minimum_in_air_dried_coal:"",
te_vanadium_v_maximum_in_air_dried_coal:"",
te_zinc_zn_typical_in_air_dried_coal:"",
te_zinc_zn_minimum_in_air_dried_coal:"",
te_zinc_zn_maximum_in_air_dried_coal:"",
 
te_arsenic_as_typical:"",
te_arsenic_as_minimum:"",
te_arsenic_as_maximum:"",
te_antimony_sb_typical:"",
te_antimony_sb_minimum:"",
te_antimony_sb_maximum:"",
te_boron_b_typical:"",
te_boron_b_minimum:"",
te_boron_b_maximum:"",
te_cadmium_cd_typical:"",
te_cadmium_cd_minimum:"",
te_cadmium_cd_maximum:"",
te_chromium_cr_typical:"",
te_chromium_cr_minimum:"",
te_chromium_cr_maximum:"",
te_copper_cu_typical:"",
te_copper_cu_minimum:"",
te_copper_cu_maximum:"",
te_chlorine_cl_typical:"",
te_chlorine_cl_minimum:"",
te_chlorine_cl_maximum:"",
te_fluorine_f_typical:"",
te_fluorine_f_minimum:"",
te_fluorine_f_maximum:"",
te_mercury_hg_typical:"",
te_mercury_hg_minimum:"",
te_mercury_hg_maximum:"",
te_molybdenum_mo_typical:"",
te_molybdenum_mo_minimum:"",
te_molybdenum_mo_maximum:"",
te_lead_pb_typical:"",
te_lead_pb_minimum:"",
te_lead_pb_maximum:"",
te_phosphorus_p_typical:"",
te_phosphorus_p_minimum:"",
te_phosphorus_p_maximum:"",
te_selenium_se_typical:"",
te_selenium_se_minimum:"",
te_selenium_se_maximum:"",
te_vanadium_v_typical:"",
laycan_from_date:fromDate,
laycan_to_date:toDate,
te_vanadium_v_min:"",
te_vanadium_v_max:"",
te_zinc_zn_typical:"",
te_zinc_zn_min:"",
te_zinc_zn_max:"",
product_master:"",
size_0_to_50mm_typical:"",
size_0_to_50mm_min:"",
size_0_to_50mm_max:"",
above_0_to_50mm_typical:"",
above_0_to_50mm_min:"",
above_0_to_50mm_max:"",
under_2mm_typical:"",
under_2mm_min:"",
under_2mm_max:"",
size_above_2mm_typical:"",
size_above_2mm_min:"",
size_above_2mm_max:"",
quantity_MT:"",
product_status:"",
product_year:"", 
vessel_type:"",
testing_method:""
  });
  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    console.log(event.target);
    const newValue = type === "checkbox" ? checked : value;
    console.log(newValue);
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "locked" ? Boolean(newValue) : newValue,
    }));
    setContracts((prevState1, e) => ({
      ...prevState1,
      [name]: newValue,
       
    }));
  };
 
  useEffect(() => {
    const fetchProductCodes = async () => {
      try {
        const encryptedAccessToken = localStorage.getItem("access_token");
        const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(
            encryptedAccessToken,
            secret_key
          ).toString(enc.Utf8);
          const response = await axios.get(
            API_URL+"get/products_api", 
            {
              headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
          );


         
          setproducts(response.data);
          
        }
      } catch (error) {
        console.log(error);
      }
    }; 
    fetchProductCodes();
    quotes_names();
  }, []);

  const getProductValues = async (productCode) => {
    try {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);
        const response = await axios.get(
          `${API_URL}all/product/datas/product_code/${productCode}`,
          {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },
          }
        );
console.log(response.data)
        setProductValues(response.data);

        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [quoteShipment_name, setquoteShipment_name] = useState([]);
  useEffect(() => {
   
    getShipment();
  }, [decryptedAccessToken]);

  const getShipment = async () => {
    try {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);
        const response = await axios.get(
          `${API_URL}get/all/quoteshipment`,
          {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },
          }
        );

        setquoteShipment_name(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleProductCodeChange = async (event) => {
    setProductCode(event.target.value);
    try {
      const productValues = await getProductValues(event.target.value);
      console.log(productValues);

      setFormData({
        ...formData,
        product_code: event.target.value,
        locked:productValues.locked,
        buyer_contract:productValues.buyer_contract,
supplier:productValues.supplier,  
product_master:productValues.product_code,
product_code:productValues.product_code,
product_name:productValues.product_name,
product_account:productValues.product_account,
product_supplier:productValues.product_supplier,
product_description:productValues.product_description,
product_family:productValues.product_family,
product_shipping_term:productValues.product_shipping_term,
product_owner:productValues.product_owner,
product_active:productValues.product_active,
short_term_available:productValues.short_term_available,
product_price_basis:productValues.product_price_basis,
product_price_type:productValues.product_price_type,
product_port_of_loading:productValues.product_port_of_loading,
total_moisture_typical:productValues.total_moisture_typical,
total_moisture_minimum:productValues.total_moisture_minimum,
total_moisture_maximum:productValues.total_moisture_maximum,
ash_typical_arb:productValues.ash_typical_arb,
ash_minimum_arb:productValues.ash_minimum_arb,
ash_maximum_arb:productValues.ash_maximum_arb,
sulphur_typical_arb:productValues.sulphur_typical_arb,
sulphur_minimum_arb:productValues.sulphur_minimum_arb,
sulphur_maximum_arb:productValues.sulphur_maximum_arb,
volatile_matter_typical_arb:productValues.volatile_matter_typical_arb,
volatile_matter_minimum_arb:productValues.volatile_matter_minimum_arb,
volatile_matter_maximum_arb:productValues.volatile_matter_maximum_arb,
 
moisture_typical:productValues.moisture_typical,
moisture_minimum:productValues.moisture_minimum,
ash_typical:productValues.ash_typical,
ash_minimum:productValues.ash_minimum,
ash_maximum:productValues.ash_maximum,
 
volatile_matter_typical:productValues.volatile_matter_typical,
volatile_matter_minimum:productValues.volatile_matter_minimum,
volatile_matter_maximum:productValues.volatile_matter_maximum,
fixed_carbon_by_difference:productValues.fixed_carbon_by_difference,
fixed_carbon_typical:productValues.fixed_carbon_typical,
fixed_carbon_minimum:productValues.fixed_carbon_minimum,
fixed_carbon_maximum:productValues.fixed_carbon_maximum,
quantity_MT: productValues.quantity_MT?productValues.quantity_MT:contractShippment.quantity,
gross_air_dried_typical:productValues.gross_air_dried_typical,
gross_air_dried_min:productValues.gross_air_dried_min,
gross_air_dried_max:productValues.gross_air_dried_max,
gross_as_recieved_min:productValues.gross_as_recieved_min,
gross_as_recieved_max:productValues.gross_as_recieved_max,
net_as_recieved_min:productValues.net_as_recieved_min,
net_as_recieved_max:productValues.net_as_recieved_max,
 
hgi_typical:productValues.hgi_typical,
hgi_min:productValues.hgi_min,
hgi_max:productValues.hgi_max,
 
carbon_typical:productValues.carbon_typical,
carbon_min:productValues.carbon_min,
carbon_max:productValues.carbon_max,
 
hydrogen_typical:productValues.hydrogen_typical,
hydrogen_min:productValues.hydrogen_min,
hydrogen_max:productValues.hydrogen_max,
 
nitrogen_typical:productValues.nitrogen_typical,
nitrogen_min:productValues.nitrogen_min,
nitrogen_max:productValues.nitrogen_max,
 
sulphur_typical:productValues.sulphur_typical,
sulphur_min:productValues.sulphur_min,
sulphur_max_ultimate_analysis:productValues.sulphur_max_ultimate_analysis,
oxygen_typical:productValues.oxygen_typical,
oxygen_min:productValues.oxygen_min,
oxygen_max:productValues.oxygen_max,
 
sulphur_typical_adb:productValues.sulphur_typical_adb,
sulphur_min_adb:productValues.sulphur_min_adb,
product_sulphur_max:productValues.product_sulphur_max,
 
initial_typical:productValues.initial_typical,
initial_minimum:productValues.initial_minimum,
initial_maximum:productValues.initial_maximum,
spherical_typical:productValues.spherical_typical,
spherical_minimum:productValues.spherical_minimum,
spherical_maximum:productValues.spherical_maximum,
hemispherical_typical:productValues.hemispherical_typical,
hemispherical_minimum:productValues.hemispherical_minimum,
hemispherical_maximum:productValues.hemispherical_maximum,
flow_typical:productValues.flow_typical,
flow_minimum:productValues.flow_minimum,
flow_maximum:productValues.flow_maximum,
 
oxidising_initial_typical:productValues.oxidising_initial_typical,
oxidising_initial_minimum:productValues.oxidising_initial_minimum,
oxidising_initial_maximum:productValues.oxidising_initial_maximum,
oxidising_spherical_typical:productValues.oxidising_spherical_typical,
oxidising_spherical_minimum:productValues.oxidising_spherical_minimum,
oxidising_spherical_maximum:productValues.oxidising_spherical_maximum,
oxidising_hemispherical_typical:productValues.oxidising_hemispherical_typical,
oxidising_hemispherical_minimum:productValues.oxidising_hemispherical_minimum,
oxidising_hemispherical_maximum:productValues.oxidising_hemispherical_maximum,
oxidising_flow_typical:productValues.oxidising_flow_typical,
oxidising_flow_minimum:productValues.oxidising_flow_typical,
oxidising_flow_maximum:productValues.oxidising_flow_maximum,
 
SiO2_Typical:productValues.SiO2_Typical,
SiO2_Minimum:productValues.SiO2_Minimum,
SiO2_Maximum:productValues.SiO2_Maximum,
Al2O3_Typical:productValues.Al2O3_Typical,
Al2O3_Minimum:productValues.Al2O3_Minimum,
Al2O3_Maximum:productValues.Al2O3_Maximum,
Fe2O3_Typical:productValues.Fe2O3_Typical,
Fe2O3_Minimum:productValues.Fe2O3_Minimum,
Fe2O3_Maximum:productValues.Fe2O3_Maximum,
CaO_Typical:productValues.CaO_Typical,
CaO_Minimum:productValues.CaO_Minimum,
CaO_Maximum:productValues.CaO_Maximum,
MgO_Typical:productValues.MgO_Typical,
MgO_Minimum:productValues.MgO_Minimum,
MgO_Maximum:productValues.MgO_Maximum,
MnO_Typical:productValues.MnO_Typical,
MnO_Minimum:productValues.MnO_Minimum,
MnO_Maximum:productValues.MnO_Maximum,
TiO2_Typical:productValues.TiO2_Typical,
TiO2_Minimum:productValues.TiO2_Minimum,
TiO2_Maximum:productValues.TiO2_Maximum,
Na2O_Typical:productValues.Na2O_Typical,
Na2O_Minimum:productValues.Na2O_Minimum,
Na2O_Maximum:productValues.Na2O_Maximum,
K2O_Typical:productValues.K2O_Typical,
K2O_Minimum:productValues.K2O_Minimum,
K2O_Maximum:productValues.K2O_Maximum,
P2O5_Typical:productValues.P2O5_Typical,
P2O5_Minimum:productValues.P2O5_Minimum,
P2O5_Maximum:productValues.P2O5_Maximum,
SO3_Typical:productValues.SO3_Typical,
SO3_Minimum:productValues.SO3_Minimum,
SO3_Maximum:productValues.SO3_Maximum,
te_arsenic_as_typical_in_air_dried_coal:productValues.te_arsenic_as_typical_in_air_dried_coal,
te_arsenic_as_minimum_in_air_dried_coal:productValues.te_arsenic_as_minimum_in_air_dried_coal,
te_arsenic_as_maximum_in_air_dried_coal:productValues.te_arsenic_as_maximum_in_air_dried_coal,
te_antimony_sb_typical_in_air_dried_coal:productValues.te_antimony_sb_typical_in_air_dried_coal,
te_antimony_sb_minimum_in_air_dried_coal:productValues.te_antimony_sb_minimum_in_air_dried_coal,
te_antimony_sb_maximum_in_air_dried_coal:productValues.te_antimony_sb_maximum_in_air_dried_coal,
te_beryllium_be_typical_in_air_dried_coal:productValues.te_beryllium_be_typical_in_air_dried_coal,
te_beryllium_be_minimum_in_air_dried_coal:productValues.te_beryllium_be_minimum_in_air_dried_coal,
te_beryllium_be_maximum_in_air_dried_coal:productValues.te_beryllium_be_maximum_in_air_dried_coal,
te_boron_b_typical_in_air_dried_coal:productValues.te_boron_b_typical_in_air_dried_coal,
te_boron_b_minimum_in_air_dried_coal:productValues.te_boron_b_minimum_in_air_dried_coal,
te_boron_b_maximum_in_air_dried_coal:productValues.te_boron_b_maximum_in_air_dried_coal,
te_cadmium_cd_typical_in_air_dried_coal:productValues.te_cadmium_cd_typical_in_air_dried_coal,
te_cadmium_cd_minimum_in_air_dried_coal:productValues.te_cadmium_cd_minimum_in_air_dried_coal,
te_cadmium_cd_maximum_in_air_dried_coal:productValues.te_cadmium_cd_maximum_in_air_dried_coal,
te_chromium_cr_typical_in_air_dried_coal:productValues.te_chromium_cr_typical_in_air_dried_coal,
te_chromium_cr_minimum_in_air_dried_coal:productValues.te_chromium_cr_minimum_in_air_dried_coal,
te_chromium_cr_maximum_in_air_dried_coal:productValues.te_chromium_cr_maximum_in_air_dried_coal,
te_copper_cu_typical_in_air_dried_coal:productValues.te_copper_cu_typical_in_air_dried_coal,
te_copper_cu_minimum_in_air_dried_coal:productValues.te_copper_cu_minimum_in_air_dried_coal,
te_copper_cu_maximum_in_air_dried_coal:productValues.te_copper_cu_maximum_in_air_dried_coal,
te_chlorine_cl_typical_in_air_dried_coal:productValues.te_chlorine_cl_typical_in_air_dried_coal,
te_chlorine_cl_minimum_in_air_dried_coal:productValues.te_chlorine_cl_minimum_in_air_dried_coal,
te_chlorine_cl_maximum_in_air_dried_coal:productValues.te_chlorine_cl_maximum_in_air_dried_coal,
te_fluorine_f_typical_in_air_dried_coal:productValues.te_fluorine_f_typical_in_air_dried_coal,
te_fluorine_f_minimum_in_air_dried_coal:productValues.te_fluorine_f_minimum_in_air_dried_coal,
te_fluorine_f_maximum_in_air_dried_coal:productValues.te_fluorine_f_maximum_in_air_dried_coal,
te_mercury_hg_typical_in_air_dried_coal:productValues.te_mercury_hg_typical_in_air_dried_coal,
te_mercury_hg_minimum_in_air_dried_coal:productValues.te_mercury_hg_minimum_in_air_dried_coal,
te_mercury_hg_maximum_in_air_dried_coal:productValues.te_mercury_hg_maximum_in_air_dried_coal,
te_molybdenum_mo_typical_in_air_dried_coal:productValues.te_molybdenum_mo_typical_in_air_dried_coal,
te_molybdenum_mo_minimum_in_air_dried_coal:productValues.te_molybdenum_mo_minimum_in_air_dried_coal,
te_molybdenum_mo_maximum_in_air_dried_coal:productValues.te_molybdenum_mo_maximum_in_air_dried_coal,
te_lead_pb_typical_in_air_dried_coal:productValues.te_lead_pb_typical_in_air_dried_coal,
te_lead_pb_minimum_in_air_dried_coal:productValues.te_lead_pb_minimum_in_air_dried_coal,
te_lead_pb_maximum_in_air_dried_coal:productValues.te_lead_pb_maximum_in_air_dried_coal,
te_phosphorus_p_typical_in_air_dried_coal:productValues.te_phosphorus_p_typical_in_air_dried_coal,
te_phosphorus_p_minimum_in_air_dried_coal:productValues.te_phosphorus_p_minimum_in_air_dried_coal,
te_phosphorus_p_maximum_in_air_dried_coal:productValues.te_phosphorus_p_maximum_in_air_dried_coal,
te_selenium_se_typical_in_air_dried_coal:productValues.te_selenium_se_typical_in_air_dried_coal,
te_selenium_se_minimum_in_air_dried_coal:productValues.te_selenium_se_minimum_in_air_dried_coal,
te_selenium_se_maximum_in_air_dried_coal:productValues.te_selenium_se_maximum_in_air_dried_coal,
te_vanadium_v_typical_in_air_dried_coal:productValues.te_vanadium_v_typical_in_air_dried_coal,
te_vanadium_v_minimum_in_air_dried_coal:productValues.te_vanadium_v_minimum_in_air_dried_coal,
te_vanadium_v_maximum_in_air_dried_coal:productValues.te_vanadium_v_maximum_in_air_dried_coal,
te_zinc_zn_typical_in_air_dried_coal:productValues.te_zinc_zn_typical_in_air_dried_coal,
te_zinc_zn_minimum_in_air_dried_coal:productValues.te_zinc_zn_minimum_in_air_dried_coal,
te_zinc_zn_maximum_in_air_dried_coal:productValues.te_zinc_zn_maximum_in_air_dried_coal,
product_month: productValues.product_month?productValues.product_month:contractShippment.laycan_month,
product_year: productValues.product_year?productValues.product_year:contractShippment.laycan_year,
te_arsenic_as_typical:productValues.te_arsenic_as_typical,
te_arsenic_as_minimum:productValues.te_arsenic_as_minimum,
te_arsenic_as_maximum:productValues.te_arsenic_as_maximum,
te_antimony_sb_typical:productValues.te_antimony_sb_typical,
te_antimony_sb_minimum:productValues.te_antimony_sb_minimum,
te_antimony_sb_maximum:productValues.te_antimony_sb_maximum,
te_boron_b_typical:productValues.te_boron_b_typical,
te_boron_b_minimum:productValues.te_boron_b_minimum,
te_boron_b_maximum:productValues.te_boron_b_maximum,
te_cadmium_cd_typical:productValues.te_cadmium_cd_typical,
te_cadmium_cd_minimum:productValues.te_cadmium_cd_minimum,
te_cadmium_cd_maximum:productValues.te_cadmium_cd_maximum,
te_chromium_cr_typical:productValues.te_chromium_cr_typical,
te_chromium_cr_minimum:productValues.te_chromium_cr_minimum,
te_chromium_cr_maximum:productValues.te_chromium_cr_maximum,
te_copper_cu_typical:productValues.te_copper_cu_typical,
te_copper_cu_minimum:productValues.te_copper_cu_minimum,
te_copper_cu_maximum:productValues.te_copper_cu_maximum,
te_chlorine_cl_typical:productValues.te_chlorine_cl_typical,
te_chlorine_cl_minimum:productValues.te_chlorine_cl_minimum,
te_chlorine_cl_maximum:productValues.te_chlorine_cl_maximum,
te_fluorine_f_typical:productValues.te_fluorine_f_typical,
te_fluorine_f_minimum:productValues.te_fluorine_f_minimum,
te_fluorine_f_maximum:productValues.te_fluorine_f_maximum,
te_mercury_hg_typical:productValues.te_mercury_hg_typical,
te_mercury_hg_minimum:productValues.te_mercury_hg_minimum,
te_mercury_hg_maximum:productValues.te_mercury_hg_maximum,
te_molybdenum_mo_typical:productValues.te_molybdenum_mo_typical,
te_molybdenum_mo_minimum:productValues.te_molybdenum_mo_minimum,
te_molybdenum_mo_maximum:productValues.te_molybdenum_mo_maximum,
te_lead_pb_typical:productValues.te_lead_pb_typical,
te_lead_pb_minimum:productValues.te_lead_pb_minimum,
te_lead_pb_maximum:productValues.te_lead_pb_maximum,
te_phosphorus_p_typical:productValues.te_phosphorus_p_typical,
te_phosphorus_p_minimum:productValues.te_phosphorus_p_minimum,
te_phosphorus_p_maximum:productValues.te_phosphorus_p_maximum,
te_selenium_se_typical:productValues.te_selenium_se_typical,
te_selenium_se_minimum:productValues.te_selenium_se_minimum,
te_selenium_se_maximum:productValues.te_selenium_se_maximum,
te_vanadium_v_typical:productValues.te_vanadium_v_typical,
te_vanadium_v_min:productValues.te_vanadium_v_min,
te_vanadium_v_max:productValues.te_vanadium_v_max,
te_zinc_zn_typical:productValues.te_zinc_zn_typical,
te_zinc_zn_min:productValues.te_zinc_zn_min,
te_zinc_zn_max:productValues.te_zinc_zn_max,
 
size_0_to_50mm_typical:productValues.size_0_to_50mm_typical,
size_0_to_50mm_min:productValues.size_0_to_50mm_min,
size_0_to_50mm_max:productValues.size_0_to_50mm_max,
above_0_to_50mm_typical:productValues.above_0_to_50mm_typical,
above_0_to_50mm_min:productValues.above_0_to_50mm_min,
above_0_to_50mm_max:productValues.above_0_to_50mm_max,
under_2mm_typical:productValues.under_2mm_typical,
under_2mm_min:productValues.under_2mm_typical,
under_2mm_max:productValues.under_2mm_max,
size_above_2mm_typical:productValues.size_above_2mm_typical,
size_above_2mm_min:productValues.size_above_2mm_min,
size_above_2mm_max:productValues.size_above_2mm_max,
product_status:productValues.product_status, 
// shipping_term:productValues.shipping_term,
vessel_type:productValues.vessel_type,
laycan_from_date:bdate,
laycan_to_date:bdate1,
testing_method:productValues.testing_method,
        ...productValues,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const encryptedAccessToken = localStorage.getItem("access_token");
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
// console.log(formData)
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);
      const headers = { Authorization: `Bearer ${decryptedAccessToken}` };
console.log(JSON.stringify(formData))
      axios
      // /add/contract_products/<quotes_id>/<contract_id>/<record_type></record_type>
        .post(API_URL+"add/contract_ship_products/"+id, formData, { headers})
        .then((response) => {
          console.log(response)
          
          if(response.status===200){
             toast.success('Product Create Successfully', {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            }); 
       
            navigate(`/product/product-detail/${response.data.product_id}`)
          }
         
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

      const [check, setCheck] = useState(false);
      const [active, setActive] = useState(false);
      const [short, setShort] = useState(false);
      const [datasContract, setdatasContract]=useState([]);
      useEffect(() => {
   
        fetchContractList();
      }, [decryptedAccessToken]);
    
      const fetchContractList = async () => {
        try {
          const response = await axios.get(API_URL+'get/all/purchase_contract', {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
          );
          const data = response.data.purchase_contract;
          console.log(data)
          setdatasContract(data);
          
        } catch (error) {
          console.error(error);
        }
      };


      const [errorMessage, setErrorMessage] = useState('');
      

  useEffect(() => {
    let timer;

    if (errorMessage) {
      timer = setTimeout(() => {
        setErrorMessage('');
      }, 1000); // Hide error after 10 seconds
    }

    return () => {
      clearTimeout(timer);
    };
  }, [errorMessage]);

  // Your other code...

  function isValidYear(year) {
    const currentYear = new Date().getFullYear();
    const parsedYear = parseInt(year);

    return !isNaN(parsedYear) && parsedYear >= currentYear && parsedYear <= currentYear + 5;
  }

  function handleChange1(event) {
    const { name, value } = event.target;

    if (name === 'product_year') {
      if (isValidYear(value)) {
        setFormData({ ...formData, [name]: value });
        setErrorMessage('');
      } else {
        setErrorMessage('Please select a valid year.');
      }
    }

    // Handle other form changes...
  }


  // Month

  const [errorMessageMonth, setErrorMessageMonth] = useState('');

  useEffect(() => {
    let timer;

    if (errorMessageMonth) {
      timer = setTimeout(() => {
        setErrorMessageMonth('');
      }, 1000); // Hide error after 10 seconds
    }

    return () => {
      clearTimeout(timer);
    };
  }, [errorMessageMonth]);

  function handleChangeMonth(event) {
    const { name, value } = event.target;

    if (name === 'product_month') {
      if (isValidMonth(value)) {
        setFormData({ ...formData, [name]: value });
        setErrorMessageMonth('');
      } else {
        setErrorMessageMonth('Please select a valid month.');
      }
    }

    // Handle other form changes...
  }

  function isValidMonth(month) {
    const validMonths = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return validMonths.includes(month);
  }
 


      const[contractShippment, setContractShippment]=useState([])
      const[supplierData, setContractsupplier]=useState([])
      const [startDateFrom, setStartFromDate] = useState(null);
      const [startDateFrom1, setStartFromDate1] = useState(null);
      const [bdate, setbirthDate]=useState('')
      const [bdate1, setbirthDate1]=useState('')
      useEffect(() => {   
        fetchContract();
      }, [decryptedAccessToken]);
    
      const fetchContract = async () => {
        try {
          const response = await axios.get(`${API_URL}get/contract_ship_id_shipments/${id}`, {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
          );
          
         
          const data = response.data.contract_ships[0];
          console.log(response)
          
          
        } catch (error) {
          console.error(error);
        }
      };
 
      
      const fetchDataContract = async () => {
        try {
          const response = await axios.get(
            `${API_URL}get/contract_shipbyID/${id}`,
            {
              headers: { Authorization: `Bearer ${decryptedAccessToken}` }
            }
          );
          const data = response.data;
      console.log(data)
      let laycanFromDate=data.contract_details[0].laycan_from_date;
      let laycanToDate=data.contract_details[0].laycan_to_date;

      if (laycanFromDate !== null){
        const defaultDatepicker = parse(laycanFromDate, 'dd/MM/yyyy', new Date()); 
        setStartFromDate(defaultDatepicker);
        console.log(defaultDatepicker)
      }else{
        setStartFromDate(new Date())
      }
      
      if (laycanToDate !== null){
        const defaultDatepicker = parse(laycanToDate, 'dd/MM/yyyy', new Date()); 
        setStartFromDate1(defaultDatepicker);
        console.log(defaultDatepicker)
      }else{
        setStartFromDate1(new Date())
      }
          if (data.contract_details && data.contract_details.length > 0) {
            setContracts(data.contract_details[0]);
            setContractShippment(data.contract_details[0]);
          }
      
          if (data.contract_products && data.contract_products.length > 0) {
            setContractsupplier(data.contract_products[0].supplier);
          }
      
          console.log("Supplier:", data.contract_products[0].supplier);
          console.log("Fetched Data:", data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      
      useEffect(() => {
        fetchDataContract();
      }, [decryptedAccessToken]);
      
      const [productApend, setProductcode] = useState('');
      function changeStatus(e) {
        setProductcode(e.target.value);
      }

     const dropdownChange = e => {
        changeStatus(e)
        handleProductCodeChange(e)
        handleChange(e)
      } 
      


      useEffect(()=>{
        const dateObject = new Date(startDateFrom); 
        if (dateObject) { 
          const formattedDate = format(dateObject, 'dd/MM/yyyy', { timeZone: 'UTC' });
          setbirthDate(formattedDate)
          console.log(formattedDate)
        }  
      })

      useEffect(()=>{
        const dateObject = new Date(startDateFrom1); 
        if (dateObject) { 
          const formattedDate = format(dateObject, 'dd/MM/yyyy', { timeZone: 'UTC' });
          setbirthDate1(formattedDate)
          console.log(formattedDate)
        }  
      })


    return (

<>
<Header/>
<div className="contactAccount"  id='product-master'>
      <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Product </h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Product / Add Product</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
      </div>
    </div> 
  </div> 
      <Form onSubmit={handleSubmit}>
      <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/shipment/contract-shipment-details/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <Row className="mb-3">
        <h4 className='heading'>Information</h4> 
        {/* <h4 className='heading'>Account Information</h4> */}
        
        <div className="form-grop col-md-6">
       <label htmlFor="product-code">Product Master</label>
       <select
         className="form-control"
         id="product_master"
         name="product_master" 
         defaultValue={productCode || contractsData.contract || productApend || formData.product_master}
         onChange={dropdownChange}
       >
         <option defaultValue="">Select a Product Master</option>
        
          {products.length>0?(
                 <>
                          
                          {products.map((product) => (
           <option key={product.id} defaultValue={product.product_code}>
             {product.product_code}
           </option>
         ))}
                   
                    

                </>
                ):(
         
                 <option className='no-data'>No data  available</option>
                 )}
       </select>
       </div> 
       <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Family"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Product Family" 
            name="product_family"
            defaultValue={formData.product_family }
    onChange={handleChange}  
            />
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Code"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Product Code" 
            name="product_code"
            disabled
            defaultValue={formData.product_code || productApend }
    onChange={handleChange}  
            />
          </FloatingLabel>
        </Form.Group>
       {/* <Form.Group as={Col} md="6" id='locked'> 
            <input type='checkbox' name='locked' onChange={(e) => setCheck(e.target.checked) } /><label>Locked</label>
          </Form.Group>     */}
        
         <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Suppliers">
    <Form.Select aria-label="Floating label select example"
    name="product_supplier" 
    placeholder="Supplier" 
    value={formData.product_supplier || supplierData}
    // defaultValue={}
    onChange={handleChange}  
    >

<option defaultValue='' >Select </option>
         
       {productAccount.length>0?(
                 <>
                      {
            productAccount.map((x)=>{
                return(
                    <option value={x.account_name}>{x.account_name}</option>
                )
            })
         }
                    

                </>
                ):(
         
                 <option className='no-data'>No data  available</option>
                 )}
        
    </Form.Select>
    
  </FloatingLabel>

        </Form.Group> 
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Product Description"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Product Description"
                  style={{ height: "200px!important" }}
                  name="product_description"
                  defaultValue={formData.product_description}
                  onChange={handleChange} 
                /> 
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Product Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Product Name"
                  name="product_name"
                  className="error-validation"
                  defaultValue={formData.product_name}
                  onChange={handleChange}
                /> 
              </FloatingLabel>
            </Form.Group>
            
         <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Purchase Contract">
    <Form.Select aria-label="Floating label select example"
    name="purchase_contract" 
    placeholder="Purchase Contract" 
    defaultValue={formData.purchase_contract}
    onChange={handleChange}  
    >

<option defaultValue='' >Select </option>
         
      {datasContract.length>0?(
                 <>
                     {
            datasContract.map((x)=>{
                return(
                    <option defaultValue={x.rwood_contract_number}>{x.rwood_contract_number}</option>
                )
            })
         }    

                </>
                ):(
         
                 <option className='no-data'>No data  available</option>
                 )}
        
    </Form.Select>
    
  </FloatingLabel>
</Form.Group>
            <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer Name"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Buyer Name"
              name="buyer_name"
              defaultValue={formData.buyer_name}
    onChange={handleChange}  />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer Contract"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Buyer Contract"
              name="buyer_contract"
              defaultValue={formData.buyer_contract || contractsData.contract}
    onChange={handleChange}  />
          </FloatingLabel>
        </Form.Group>

        {/* <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Buyer Contract">
    <Form.Select aria-label="Floating label select example"
    name="buyer_contract" 
    placeholder="Buyer Contract" 
    value={formData.contract || contractsData.contract}

    onChange={handleChange}  
    >

<option defaultValue='' >Select </option>
         
       {datasContract.length>0?(
                 <>
                   {
            datasContract.map((x)=>{
                return(
                  <option value={x.rwood_contract_number}>
                  {x.rwood_contract_number}</option>
                )
            })
         }

                </>
                ):(
         
                 <option className='no-data'>No data  available</option>
                 )}
        
    </Form.Select>
    
  </FloatingLabel>
</Form.Group> */}

<Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="contract shipment"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="contract shipment"
              name="contract_shipment"
              defaultValue={formData.contract_shipment|| contractsData.contract_ship_code}
    onChange={handleChange}  />
          </FloatingLabel>
        </Form.Group>

       
 
        
         
      </Row>
      <Row className="mb-3">
        <h4 className='heading'>Product Availability</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="quantity MT"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="quantity MT" 
            name="quantity_MT"
            defaultValue={contractShippment.quantity||formData.quantity_MT}
    onChange={handleChange}  />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="product_status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="product_status"
                    placeholder="product status"
                    defaultValue={formData.product_status}
                    onChange={handleChange}
                    
                  >
                   <option defaultValue="">--None--</option><option defaultValue="UnSold">UnSold</option><option defaultValue="Offered">Offered</option><option defaultValue="Approved">Approved</option><option defaultValue="Sold" selected="selected">Sold</option><option defaultValue="Rejected">Rejected</option><option defaultValue="Allocated">Allocated</option><option defaultValue="Cancelled">Cancelled</option><option defaultValue="Shipments - Arutmin">Shipments - Arutmin</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
              
       
              <Form.Group as={Col} md="6">
      <FloatingLabel
        controlId="floatingSelect"
        className="dropDown"
        label="product year"
      >
        <Form.Select
          aria-label="Floating label select example"
          name="product_year"
          placeholder="product year"
          value={isValidYear(contractShippment.laycan_year) ? contractShippment.laycan_year : formData.product_year}
          onChange={handleChange1}
          disabled
        >
          <option value="">--None--</option>
          <option value="2017">2017</option>
          <option value="2018">2018</option> 
          <option value="2019">2019</option>
          <option value="2020">2020</option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
        </Form.Select>
      </FloatingLabel>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </Form.Group>
    <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan From Date"
            className="mb-3 datepick"
            
            
          >
         <DatePicker
          selected={startDateFrom}
          onChange={(date) => setStartFromDate(date)}
          dateFormat="dd/MM/yyyy"
          placeholderText="Select a date" 
          disabled={true}
      />

 
          </FloatingLabel>
        </Form.Group>
       <Form.Group as={Col} md="6">
      <FloatingLabel
        controlId="floatingSelect"
        className="dropDown"
        label="product month"
      >
        <Form.Select
          aria-label="Floating label select example"
          name="product_month"
          placeholder="product month"
          value={isValidMonth(contractShippment.laycan_month) ? contractShippment.laycan_month :formData.product_month}
          onChange={handleChange1}
          disabled
        >
          <option value="">--None--</option>
          <option value="January">January</option>
          <option value="February">February</option>
          <option value="March">March</option>
          <option value="April">April</option>
          <option value="May">May</option>
          <option value="June">June</option>
          <option value="July">July</option>
          <option value="August">August</option>
          <option value="September">September</option>
          <option value="October">October</option>
          <option value="November">November</option>
          <option value="December">December</option>
        </Form.Select>
      </FloatingLabel>
      {errorMessageMonth && <p style={{ color: 'red' }}>{errorMessageMonth}</p>}
    </Form.Group>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan To Date"
            className="mb-3 datepick"
            
            
          >
         <DatePicker
          selected={startDateFrom1}
          onChange={(date) => setStartFromDate1(date)}
          dateFormat="dd/MM/yyyy"
          placeholderText="Select a date" 
          disabled={true}
      />

 
          </FloatingLabel>
        </Form.Group>
    

         
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Product price type">
    <Form.Select aria-label="Floating label select example"
    name="product_price_type" 
    placeholder="Product Price type" 
    value={formData.product_price_type ||contractShippment.price_type }
    onChange={handleChange}
    > 
      <option defaultValue='' >Select</option>
      

{getPrice.length>0?(
                 <>
               {
          getPrice.map((x)=>{
              return(
                  <option defaultValue={x.price_type_name}>{x.price_type_name}</option>
              )
          })
       }
                </>
                ):(
         
                 <option className='no-data'>No data  available</option>
                 )}
    </Form.Select>
    
  </FloatingLabel>
              </Form.Group> 
         
               <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Port Of Loading"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Port Of Loading" 
            name="product_port_of_loading"
            defaultValue={formData.product_port_of_loading}
    onChange={handleChange}  
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Price"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Price" 
            name="price"
            defaultValue={formData.price}
    onChange={handleChange}  />
          </FloatingLabel>
        </Form.Group>
       

        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Shipping Term">
    <Form.Select aria-label="Floating label select example"
    name="product_shipping_term" 
    placeholder="Shipping Term" 
    defaultValue={formData.product_shipping_term || contractsData.product_shipping_term}
    onChange={handleChange}  
    > 
      <option defaultValue="">--None--</option><option defaultValue="FOB-B">FOB-B</option><option defaultValue="FOB-MV" selected="selected">FOB-MV</option><option defaultValue="CFR-MV">CFR-MV</option><option defaultValue="CIF-MV">CIF-MV</option><option defaultValue="FAS">FAS</option>
    </Form.Select>
    
  </FloatingLabel>
              </Form.Group> 

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Price Basis"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Product Price Basis" 
            name="product_price_basis"
            defaultValue={formData.product_price_basis}
    onChange={handleChange}  />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Vessel Type	">
    <Form.Select aria-label="Floating label select example"
    name="vessel_type" 
    placeholder="Vessel Type	" 
    defaultValue={formData.vessel_type}
    onChange={handleChange}  
    > 
      <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless" selected="selected">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
    </Form.Select>
    
  </FloatingLabel>
              </Form.Group> 
        
        
        </Row>
      <Row className="mb-3">
        <h4 className='heading'>%As Received Basis</h4>
        
                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Moisture Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Total Moisture Typical"
                      name="total_moisture_typical"
                      defaultValue={formData.moisture_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur typical arb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur typical arb"
                      name="sulphur_typical_arb"
                      defaultValue={formData.sulphur_typical_arb}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="total moisture minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="total moisture minimum"
                      name="total_moisture_minimum"
                      defaultValue={formData.total_moisture_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur minimum arb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur minimum arb"
                      name="sulphur_minimum_arb"
                      defaultValue={formData.sulphur_minimum_arb}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="total moisture maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="total moisture maximum"
                      name="total_moisture_maximum"
                      defaultValue={formData.total_moisture_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur maximum arb "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur maximum arb "
                      name="sulphur_maximum_arb"
                      defaultValue={formData.sulphur_maximum_arb}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash typical arb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="ash typical arb"
                      name="ash_typical_arb"
                      defaultValue={formData.ash_typical_arb}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                  
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter typical arb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter typical arb"
                      name="volatile_matter_typical_arb"
                      defaultValue={formData.volatile_matter_typical_arb}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash minimum arb "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="ash minimum arb "
                      name="ash_minimum_arb "
                      defaultValue={formData.ash_minimum_arb }
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter minimum arb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter minimum arb"
                      name="volatile_matter_minimum_arb"
                      defaultValue={formData.volatile_matter_minimum_arb}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash maximum arb "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="ash maximum arb "
                      name="ash_maximum_arb "
                      defaultValue={formData.ash_maximum_arb }
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                  <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter maximum arb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter maximum arb"
                      name="volatile_matter_maximum_arb"
                      defaultValue={formData.volatile_matter_maximum_arb}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
        </Row>
         
        <Row className="mb-3">
                <h4 className='heading'>Proximate Analysis %Air Dried Basis</h4>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="moisture typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="moisture typical"
                      name="moisture_typical"
                      defaultValue={formData.moisture_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter typical"
                      name="volatile_matter_typical"
                      defaultValue={formData.volatile_matter_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="moisture minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="moisture minimum"
                      name="moisture_minimum"
                      defaultValue={formData.moisture_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter minimum"
                      name="volatile_matter_minimum"
                      defaultValue={formData.volatile_matter_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="moisture maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="moisture maximum"
                      name="moisture_maximum"
                      defaultValue={formData.moisture_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="volatile matter maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="volatile matter maximum"
                      name="volatile_matter_maximum"
                      defaultValue={formData.volatile_matter_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="ash typical"
                      name="ash_typical"
                      defaultValue={formData.ash_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon By Difference "
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="checkbox"
                      placeholder="Fixed Carbon By Difference "
                      name="fixed_carbon_by_difference"
                      defaultValue={formData.fixed_carbon_by_difference}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="ash minimum"
                      name="ash_minimum"
                      defaultValue={formData.ash_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="fixed carbon typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="fixed carbon typical"
                      name="fixed_carbon_typical"
                      defaultValue={formData.fixed_carbon_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ash maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="ash maximum"
                      name="ash_maximum"
                      defaultValue={formData.ash_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="fixed carbon minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="fixed carbon minimum"
                      name="fixed_carbon_minimum"
                      defaultValue={formData.fixed_carbon_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="fixed carbon maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="fixed carbon maximum"
                      name="fixed_carbon_maximum"
                      defaultValue={formData.fixed_carbon_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
              </Row>   
              <Row className="mb-3">
                <h4 className='heading'>CALORIFIC VALUE, Keal/kg</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross Air Dried Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Gross Air Dried Typical"
                      name="gross_air_dried_typical"
                      defaultValue={formData.gross_air_dried_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="net as recieved typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="net as recieved typical"
                      name="net_as_recieved_typical"
                      defaultValue={formData.net_as_recieved_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="gross air dried min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="gross air dried min"
                      name="gross_air_dried_min"
                      defaultValue={formData.gross_air_dried_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="net as recieved min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="net as recieved min"
                      name="net_as_recieved_min"
                      defaultValue={formData.net_as_recieved_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="gross air dried max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="gross air dried max"
                      name="gross_air_dried_max"
                      defaultValue={formData.gross_air_dried_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="net as recieved max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="net as recieved max"
                      name="net_as_recieved_max"
                      defaultValue={formData.net_as_recieved_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="gross as recieved typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="gross as recieved typical"
                      name="gross_as_recieved_typical"
                      defaultValue={formData.gross_as_recieved_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="gross as recieved min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="gross as recieved min"
                      name="gross_as_recieved_min"
                      defaultValue={formData.gross_as_recieved_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ggross as recieved max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="gross as recieved max"
                      name="gross_as_recieved_max"
                      defaultValue={formData.gross_as_recieved_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group></Row> 
              <Row className="mb-3">
                <h4 className='heading'>HGI</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="HGI typical" 
                    name="hgi_typical"
                    defaultValue={formData.hgi_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group> 
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="HGI minimum" 
                    name="hgi_min"
                    defaultValue={formData.hgi_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group> 
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="HGI Maximum" 
                    name="hgi_max"
                    defaultValue={formData.hgi_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group> 
                </Row>  
                <Row className="mb-3">
                <h4 className='heading'>ULTIMATE ANALYSIS %dry ash free basis</h4>
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Carbon typical"
                      name="carbon_typical"
                      defaultValue={formData.carbon_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur typical"
                      name="sulphur_typical"
                      defaultValue={formData.sulphur_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="carbon min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="carbon min"
                      name="carbon_min"
                      defaultValue={formData.carbon_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur min"
                      name="sulphur_min"
                      defaultValue={formData.sulphur_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="carbon max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="carbon max"
                      name="carbon_max"
                      defaultValue={formData.carbon_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="sulphur min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="sulphur min"
                      name="sulphur_min"
                      defaultValue={formData.sulphur_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hydrogen typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="hydrogen typical"
                      name="hydrogen_typical"
                      defaultValue={formData.hydrogen_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxygen typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxygen typical"
                      name="oxygen_typical"
                      defaultValue={formData.oxygen_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hydrogen min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="hydrogen min"
                      name="hydrogen_min"
                      defaultValue={formData.hydrogen_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxygen min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxygen min"
                      name="oxygen_min"
                      defaultValue={formData.oxygen_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hydrogen max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="hydrogen max"
                      name="hydrogen_max"
                      defaultValue={formData.hydrogen_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxygen max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxygen max"
                      name="oxygen_max"
                      defaultValue={formData.oxygen_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="nitrogen typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="nitrogen typical"
                      name="nitrogen_typical"
                      defaultValue={formData.nitrogen_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="nitrogen min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="nitrogen min"
                      name="nitrogen_min"
                      defaultValue={formData.nitrogen_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="nitrogen max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="nitrogen max"
                      name="nitrogen_max"
                      defaultValue={formData.nitrogen_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
              </Row>  
              <Row className="mb-3">
                <h4 className='heading'>SULPHUR %air dried basis</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical Adb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Sulphur Typical Adb"
                      name="sulphur_typical_adb"
                      defaultValue={formData.sulphur_typical_adb}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Minimum Adb"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Sulphur Minimum Adb" 
                    name="sulphur_min_adb"
                    defaultValue={formData.sulphur_min_adb}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                
        
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Sulphur Maximum" 
                    name="product_sulphur_max"
                    defaultValue={formData.product_sulphur_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                
              </Row>  
              <Row className="mb-3">
                <h4 className='heading'>ASH FUSION TEMPERATURE, DEG (C) Reducing</h4>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="initial typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="initial typical"
                      name="initial_typical"
                      defaultValue={formData.initial_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hemispherical typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="hemispherical typical"
                      name="hemispherical_typical"
                      defaultValue={formData.hemispherical_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="initial minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="initial minimum"
                      name="initial_minimum"
                      defaultValue={formData.initial_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hemispherical minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="hemispherical minimum"
                      name="hemispherical_minimum"
                      defaultValue={formData.hemispherical_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="initial maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="initial maximum"
                      name="initial_maximum"
                      defaultValue={formData.initial_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="hemispherical maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="hemispherical maximum"
                      name="hemispherical_maximum"
                      defaultValue={formData.hemispherical_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="spherical typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="spherical typical"
                      name="spherical_typical"
                      defaultValue={formData.spherical_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="flow typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="flow typical"
                      name="flow_typical"
                      defaultValue={formData.flow_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="spherical minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="spherical minimum"
                      name="spherical_minimum"
                      defaultValue={formData.spherical_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="flow minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="flow minimum"
                      name="flow_minimum"
                      defaultValue={formData.flow_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="spherical maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="spherical maximum"
                      name="spherical_maximum"
                      defaultValue={formData.spherical_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="flow maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="flow maximum"
                      name="flow_maximum"
                      defaultValue={formData.flow_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                



                 </Row>
                 <Row className="mb-3">
                <h4 className='heading'>ASH FUSION TEMPERATURE, Deg C Oxidizing</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Deformtion Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidizing Initial Deformtion Typical"
                      name="oxidizing_initial_typical"
                      defaultValue={formData.oxidizing_initial_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising hemispherical typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising hemispherical typical"
                      name="oxidising_hemispherical_typical"
                      defaultValue={formData.oxidising_hemispherical_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising initial minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising initial minimum"
                      name="oxidising_initial_minimum"
                      defaultValue={formData.oxidising_initial_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising hemispherical minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising hemispherical minimum"
                      name="oxidising_hemispherical_minimum"
                      defaultValue={formData.oxidising_hemispherical_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising initial maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising initial maximum"
                      name="oxidising_initial_maximum"
                      defaultValue={formData.oxidising_initial_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising hemispherical maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising hemispherical maximum"
                      name="oxidising_hemispherical_maximum"
                      defaultValue={formData.oxidising_hemispherical_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising spherical typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising spherical typical"
                      name="oxidising_spherical_typical"
                      defaultValue={formData.oxidising_spherical_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising flow typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising flow typical"
                      name="oxidising_flow_typical"
                      defaultValue={formData.oxidising_flow_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising spherical minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising spherical minimum"
                      name="oxidising_spherical_minimum"
                      defaultValue={formData.oxidising_spherical_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising flow minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising flow minimum"
                      name="oxidising_flow_minimum"
                      defaultValue={formData.oxidising_flow_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising spherical maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising spherical maximum"
                      name="oxidising_spherical_maximum"
                      defaultValue={formData.oxidising_spherical_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="oxidising flow maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="oxidising flow maximum"
                      name="oxidising_flow_maximum"
                      defaultValue={formData.oxidising_flow_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                </Row>
              
          <Row className="mb-3">
            <h4 className="heading">ASH ANALYSIS %Dry Basis in Ash</h4>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SiO2 Typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.SiO2_Typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="SiO2 Typical"
                  name="SiO2_Typical"
                 />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TiO2 Typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.TiO2_Typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="TiO2 Typical"
                  name="TiO2_Typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SiO2 Minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.SiO2_Minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="SiO2 Minimum"
                  name="SiO2_Minimum"
                 />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TiO2 Minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.TiO2_Minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="TiO2 Minimum"
                  name="TiO2_Minimum"
                 
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SiO2 Maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.SiO2_Maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="SiO2 Maximum"
                  name="SiO2_Maximum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TiO2 Maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.TiO2_Maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="TiO2 Maximum"
                  name="TiO2_Maximum"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="AI2O3 Typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.AI2O3_Typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="AI2O3 Typical"
                  name="AI2O3_Typical"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Na2O Typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.Na2O_Typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Na2O Typical"
                  name="Na2O_Typical" />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Al2O3 Minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.Al2O3_Minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Al2O3 Minimum"
                  name="Al2O3_Minimum" />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Na2O Minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.Na2O_Minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Na2O Minimum"
                  name="Na2O_Minimum" />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="AI2O3 Maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.AI2O3_Maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="AI2O3 Maximum"
                  name="AI2O3_Maximum" />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Na2O Maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.Na2O_Maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Na2O Maximum"
                  name="Na2O_Maximum" />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Fe2O3 Typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.Fe2O3_Typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Fe2O3 Typical"
                  name="Fe2O3_Typical" />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="K2O Typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.K2O_Typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="K2O Typical"
                  name="K2O_Typical" />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Fe2O3 Minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.Fe2O3_Minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Fe2O3 Minimum"
                  name="Fe2O3_Minimum" />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="K2O Minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.K2O_Minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="K2O Minimum"
                  name="K2O_Minimum"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Fe2O3 Maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.Fe2O3_Maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Fe2O3 Maximum"
                  name="Fe2O3_Maximum"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="K2O Maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.K2O_Maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="K2O Maximum"
                  name="K2O_Maximum"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="P2O5 Typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.P2O5_Typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="P2O5 Typical"
                  name="P2O5_Typical"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="CaO Minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.CaO_Minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="CaO Minimum"
                  name="CaO_Minimum"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="P2O5 Minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.P2O5_Minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="P2O5 Minimum"
                  name="P2O5_Minimum"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="CaO Maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.CaO_Maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="CaO Maximum"
                  name="CaO_Maximum"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="P2O5 Maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.P2O5_Maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="P2O5 Maximum"
                  name="P2O5_Maximum"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="MgO Typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.MgO_Typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="MgO Typical"
                  name="MgO_Typical"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SO3 Typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.SO3_Typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="SO3 Typical"
                  name="SO3_Typical"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="MgO Minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.MgO_Minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="MgO Minimum"
                  name="MgO_Minimum"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SO3 Minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.SO3_Minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="SO3 Minimum"
                  name="SO3_Minimum"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="MgO Maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.MgO_Maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="MgO Maximum"
                  name="MgO_Maximum"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="SO3 Maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.SO3_Maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="SO3 Maximum"
                  name="SO3_Maximum"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Mn3O4 Typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.Mn3O4_Typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Mn3O4 Typical"
                  name="Mn3O4_Typical"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Mn3O4 Minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.Mn3O4_Minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Mn3O4 Minimum"
                  name="Mn3O4_Minimum"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Mn3O4 Maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.Mn3O4_Maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Mn3O4 Maximum"
                  name="Mn3O4_Maximum"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6"></Form.Group>
          </Row>
          <Row className="mb-3">
            <h4 className="heading">Trace Elements, ppm Air Dried in Coal</h4>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE arsenic as typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_arsenic_as_typical_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE arsenic as typical"
                  name="te_arsenic_as_typical_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE rsenic as minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_arsenic_as_minimum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE rsenic as minimum"
                  name="te_arsenic_as_minimum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE arsenic as maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_arsenic_as_maximum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE arsenic as maximum"
                  name="te_arsenic_as_maximum_in_air_dried_coal"
                   />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE antimony sb typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_antimony_sb_typical_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE antimony sb typical"
                  name="te_antimony_sb_typical_in_air_dried_coal"
                   />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE antimony sb minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_antimony_sb_minimum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE antimony sb minimum"
                  name="te_antimony_sb_minimum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE antimony sb maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_antimony_sb_maximum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE antimony sb maximum"
                  name="te_antimony_sb_maximum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE boron b typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_boron_b_typical_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE boron b typical"
                  name="te_boron_b_typical_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE boron b minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_boron_b_minimum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE boron b minimum"
                  name="te_boron_b_minimum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE boron b maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_boron_b_maximum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE boron b maximum"
                  name="te_boron_b_maximum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE cadmium cd typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_cadmium_cd_typical_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE cadmium cd typical"
                  name="te_cadmium_cd_typical_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE cadmium cd minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_cadmium_cd_minimum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE cadmium cd minimum"
                  name="te_cadmium_cd_minimum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE cadamium cd maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_cadamium_cd_maximum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE cadamium cd maximum"
                  name="te_cadamium_cd_maximum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chromium cr typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_chromium_cr_typical_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE chromium cr typical"
                  name="te_chromium_cr_typical_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chromium cr minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_chromium_cr_minimum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE chromium cr minimum"
                  name="te_chromium_cr_minimum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chromium cr maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_chromium_cr_maximum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE chromium cr maximum"
                  name="te_chromium_cr_maximum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE copper cu typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_copper_cu_typical_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE copper cu typical"
                  name="te_copper_cu_typical_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE copper cu minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_copper_cu_minimum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE copper cu minimum"
                  name="te_copper_cu_minimum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE copper cu maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_copper_cu_maximum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE copper cu maximum"
                  name="te_copper_cu_maximum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chlorine cl typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_chlorine_cl_typical_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE chlorine cl typical"
                  name="te_chlorine_cl_typical_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chlorine cl minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_chlorine_cl_minimum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE chlorine cl minimum"
                  name="te_chlorine_cl_minimum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE chlorine cl maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_chlorine_cl_maximum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE chlorine cl maximum"
                  name="te_chlorine_cl_maximum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE fluorine f typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_fluorine_f_typical_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE fluorine f typical"
                  name="te_fluorine_f_typical_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE fluorine f minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_fluorine_f_minimum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE fluorine f minimum"
                  name="te_fluorine_f_minimum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE fluorine f maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_fluorine_f_maximum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE fluorine f maximum"
                  name="te_fluorine_f_maximum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE mercury hg typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_mercury_hg_typical_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE mercury hg typical"
                  name="te_mercury_hg_typical_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE mercury hg minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_mercury_hg_minimum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE mercury hg minimum"
                  name="te_mercury_hg_minimum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE mercury hg maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_mercury_hg_maximum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE mercury hg maximum"
                  name="te_mercury_hg_maximum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE molybdenum mo typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_molybdenum_mo_typical_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE molybdenum mo typical"
                  name="te_molybdenum_mo_typical_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE molybdenum mo minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_molybdenum_mo_minimum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE molybdenum mo minimum"
                  name="te_molybdenum_mo_minimum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE molybdenum mo maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_molybdenum_mo_maximum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE molybdenum mo maximum"
                  name="te_molybdenum_mo_maximum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE lead pb typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_lead_pb_typical_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE lead pb typical"
                  name="te_lead_pb_typical_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE lead pb minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_lead_pb_minimum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE lead pb minimum"
                  name="te_lead_pb_minimum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE lead pb maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_lead_pb_maximum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE lead pb maximum"
                  name="te_lead_pb_maximum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE phosphorus p typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_phosphorus_p_typical_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE phosphorus p typical"
                  name="te_phosphorus_p_typical_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE phosphorus p minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_phosphorus_p_minimum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE phosphorus p minimum"
                  name="te_phosphorus_p_minimum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE phosphorus p maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_phosphorus_p_maximum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE phosphorus p maximum"
                  name="te_phosphorus_p_maximum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE selenium se typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_selenium_se_typical_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE selenium se typical"
                  name="te_selenium_se_typical_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE selenium se minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_selenium_se_minimum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE selenium se minimum"
                  name="te_selenium_se_minimum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE selenium se maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_selenium_se_maximum_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE selenium se maximum"
                  name="te_selenium_se_maximum_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE vanadium v typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_vanadium_v_typical_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE vanadium v typical"
                  name="te_vanadium_v_typical_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE vanadium v min"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_vanadium_v_min_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE vanadium v min"
                  name="te_vanadium_v_min_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE vanadium v max"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_vanadium_v_max_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE vanadium v max"
                  name="te_vanadium_v_max_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE zinc zn typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_zinc_zn_typical_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE zinc zn typical"
                  name="te_zinc_zn_typical_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE zinc zn min"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_zinc_zn_min_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE zinc zn min"
                  name="te_zinc_zn_min_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="TE zinc zn max"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_zinc_zn_max_in_air_dried_coal}
    onChange={handleChange} 
                  type="number"
                  placeholder="TE zinc zn max"
                  name="te_zinc_zn_max_in_air_dried_coal"
                  />
              </FloatingLabel>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <h4 className="heading">Trace Elements, ppm Dried Basis</h4>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="te arsenic as typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_arsenic_as_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="te arsenic as typical"
                  name="te_arsenic_as_typical"
                 />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te arsenic as minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_arsenic_as_minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te arsenic as minimum"
                  name="te_arsenic_as_minimum"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te arsenic as maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_arsenic_as_maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te arsenic as maximum"
                  name="te_arsenic_as_maximum"/>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te antimony sb typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_antimony_sb_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te antimony sb typical"
                  name="te_antimony_sb_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te antimony sb minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_antimony_sb_minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te antimony sb minimum"
                  name="te_antimony_sb_minimum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te antimony sb maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_antimony_sb_maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te antimony sb maximum"
                  name="te_antimony_sb_maximum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te beryllium be typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_beryllium_be_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te beryllium be typical"
                  name="te_beryllium_be_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te beryllium be minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_beryllium_be_minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te beryllium be minimum"
                  name="te_beryllium_be_minimum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te beryllium be maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_beryllium_be_maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te beryllium be maximum"
                  name="te_beryllium_be_maximum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te boron b typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_boron_b_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te boron b typical"
                  name="te_boron_b_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te boron b minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_boron_b_minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te boron b minimum"
                  name="te_boron_b_minimum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te boron b maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_boron_b_maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te boron b maximum"
                  name="te_boron_b_maximum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te cadmium cd typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_cadmium_cd_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te cadmium cd typical"
                  name="te_cadmium_cd_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te cadmium cd minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_cadmium_cd_minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te cadmium cd minimum"
                  name="te_cadmium_cd_minimum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te cadmium cd maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_cadmium_cd_maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te cadmium cd maximum"
                  name="te_cadmium_cd_maximum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te chromium cr typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_chromium_cr_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te chromium cr typical"
                  name="te_chromium_cr_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te chromium cr minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_chromium_cr_minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te chromium cr minimum"
                  name="te_chromium_cr_minimum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te chromium cr maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_chromium_cr_maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te chromium cr maximum"
                  name="te_chromium_cr_maximum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te copper cu typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_copper_cu_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te copper cu typical"
                  name="te_copper_cu_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te copper cu minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_copper_cu_minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te copper cu minimum"
                  name="te_copper_cu_minimum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te copper cu maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_copper_cu_maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te copper cu maximum"
                  name="te_copper_cu_maximum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te chlorine cl typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_chlorine_cl_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te chlorine cl typical"
                  name="te_chlorine_cl_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te chlorine cl minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_chlorine_cl_minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te chlorine cl minimum"
                  name="te_chlorine_cl_minimum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te chlorine cl maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_chlorine_cl_maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te chlorine cl maximum"
                  name="te_chlorine_cl_maximum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te fluorine f typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_fluorine_f_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te fluorine f typical"
                  name="te_fluorine_f_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te fluorine f minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_fluorine_f_minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te fluorine f minimum"
                  name="te_fluorine_f_minimum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te fluorine f maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_fluorine_f_maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te fluorine f maximum"
                  name="te_fluorine_f_maximum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te mercury hg typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_mercury_hg_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te mercury hg typical"
                  name="te_mercury_hg_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te mercury hg minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_mercury_hg_minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te mercury hg minimum"
                  name="te_mercury_hg_minimum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te mercury hg maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_mercury_hg_maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te mercury hg maximum"
                  name="te_mercury_hg_maximum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te molybdenum mo typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_molybdenum_mo_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te molybdenum mo typical"
                  name="te_molybdenum_mo_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te molybdenum mo minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_molybdenum_mo_minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te molybdenum mo minimum"
                  name="te_molybdenum_mo_minimum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te molybdenum mo maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_molybdenum_mo_maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te molybdenum mo maximum"
                  name="te_molybdenum_mo_maximum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te lead pb typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_lead_pb_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te lead pb typical"
                  name="te_lead_pb_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te lead pb minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_lead_pb_minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te lead pb minimum"
                  name="te_lead_pb_minimum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te lead pb maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_lead_pb_maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te lead pb maximum"
                  name="te_lead_pb_maximum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te phosphorus p typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_phosphorus_p_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te phosphorus p typical"
                  name="te_phosphorus_p_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te phosphorus p minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_phosphorus_p_minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te phosphorus p minimum"
                  name="te_phosphorus_p_minimum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te phosphorus p maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_phosphorus_p_maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te phosphorus p maximum"
                  name="te_phosphorus_p_maximum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te selenium se typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_selenium_se_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te selenium se typical"
                  name="te_selenium_se_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te selenium se minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_selenium_se_minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te selenium se minimum"
                  name="te_selenium_se_minimum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te selenium se maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_selenium_se_maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te selenium se maximum"
                  name="te_selenium_se_maximum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te vanadium v typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_vanadium_v_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te vanadium v typical"
                  name="te_vanadium_v_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te vanadium v minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_vanadium_v_minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te vanadium v minimum"
                  name="te_vanadium_v_minimum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te vanadium v maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_vanadium_v_maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te vanadium v maximum"
                  name="te_vanadium_v_maximum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te zinc zn typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_zinc_zn_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te zinc zn typical"
                  name="te_zinc_zn_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te zinc zn minimum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_zinc_zn_minimum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te zinc zn minimum"
                  name="te_zinc_zn_minimum"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Te zinc zn maximum"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.te_zinc_zn_maximum}
    onChange={handleChange} 
                  type="number"
                  placeholder="Te zinc zn maximum"
                  name="te_zinc_zn_maximum"
                  />
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <h4 className="heading">SIZING, %</h4>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size 0 to 50mm typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.size_0_to_50mm_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Size 0 to 50mm typical"
                  name="size_0_to_50mm_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size 0 to 50mm min"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.size_0_to_50mm_min}
    onChange={handleChange} 
                  type="number"
                  placeholder="Size 0 to 50mm min"
                  name="size_0_to_50mm_min"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size 0 to 50mm max"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.size_0_to_50mm_max}
    onChange={handleChange} 
                  type="number"
                  placeholder="Size 0 to 50mm max"
                  name="size_0_to_50mm_max"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Above 0 to 50mm typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.above_0_to_50mm_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Above 0 to 50mm typical"
                  name="above_0_to_50mm_typical"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Above 0 to 50mm min"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.above_0_to_50mm_min}
    onChange={handleChange} 
                  type="number"
                  placeholder="Above 0 to 50mm min"
                  name="above_0_to_50mm_min"
                  />
              </FloatingLabel>
            </Form.Group>{" "}
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Above 0 to 50mm max"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.above_0_to_50mm_max}
    onChange={handleChange} 
                  type="number"
                  placeholder="Above 0 to 50mm max"
                  name="above_0_to_50mm_max"
                  />
              </FloatingLabel>
            </Form.Group>{" "}
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Under 2mm typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.under_2mm_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Under 2mm typical"
                  name="under_2mm_typical"
                  />
              </FloatingLabel>
            </Form.Group>{" "}
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Under 2mm min"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.under_2mm_min}
    onChange={handleChange} 
                  type="number"
                  placeholder="Under 2mm min"
                  name="under_2mm_min"
                  />
              </FloatingLabel>
            </Form.Group>{" "}
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Under 2mm max"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.under_2mm_max}
    onChange={handleChange} 
                  type="number"
                  placeholder="Under 2mm max"
                  name="under_2mm_max"
                  />
              </FloatingLabel>
            </Form.Group>{" "}
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size above 2mm typical"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.size_above_2mm_typical}
    onChange={handleChange} 
                  type="number"
                  placeholder="Size above 2mm typical"
                  name="size_above_2mm_typical"
                  />
              </FloatingLabel>
            </Form.Group>{" "}
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size above 2mm min"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.size_above_2mm_min}
    onChange={handleChange} 
                  type="number"
                  placeholder="Size above 2mm min"
                  name="size_above_2mm_min"
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Size above 2mm max"
                className="mb-3"
              >
                <Form.Control defaultValue={formData.size_above_2mm_max}
    onChange={handleChange} 
                  type="number"
                  placeholder="Size above 2mm max"
                  name="size_above_2mm_max" />
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <h4 className="heading">Testing Method</h4>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Testing Method"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="testing_method"
                  placeholder="Testing method" 
                  defaultValue={formData.size_above_2mm_max}
    onChange={handleChange} 
                >
                  <option defaultValue="">Select </option>
                  <option defaultValue="According to ASTM Standard Method Unless Otherwise Stated">
                    According to ASTM Standard Method Unless Otherwise Stated
                  </option>
                  <option defaultValue="According to ISO Methods">
                    According to ISO Methods
                  </option>
                  <option defaultValue="According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM">
                    According to ISO Methods Except HGI, Trace Elements and Ash
                    Analysis to ASTM
                  </option>
                  <option defaultValue="According to ISO Methods Except HGI to ASTM">
                    According to ISO Methods Except HGI to ASTM
                  </option>
                  <option defaultValue="According to ISO Methods, Except HGI and Ash Analysis to ASTM">
                    According to ISO Methods, Except HGI and Ash Analysis to
                    ASTM
                  </option>
                  <option defaultValue="According to ISO Methods, Except HGI as per ASTM Test Method">
                    According to ISO Methods, Except HGI as per ASTM Test Method
                  </option>
                </Form.Select> 
              </FloatingLabel>
            </Form.Group>
          </Row>
             
          <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/shipment/contract-shipment-details/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <div className='successDiv'><p></p><p></p></div>
    </Form>
    <p></p><p></p>
    </div>
    <Footer/>
    
</>
    );

  
 

};


export default AddProductShipment;