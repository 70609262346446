import React, { useState, useEffect, useContext } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Header from "../Header";
import Footer from "../Footer";
import { Col, Table } from "react-bootstrap";
import Popup from "reactjs-popup";
import { useNavigate, useParams, Link } from "react-router-dom";
import { AES, enc } from "crypto-js";
import axios from "axios";
import { API_URL } from '../config';
import { IoSearch } from "react-icons/io5";

const SelectQuoteproduct = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const [productMaster, setProductMaster] = useState([]);
  const [selectedProductCode, setSelectedProductCode] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const fetchProductCodes = async () => {
      try {
        const encryptedAccessToken = localStorage.getItem("access_token");
        const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(
            encryptedAccessToken,
            secret_key
          ).toString(enc.Utf8);
          const response = await axios.get(
            API_URL+"get/products_api",
            {
              headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
          );
          console.log(response)
          setProductMaster(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProductCodes();
  }, []);

  const handleButtonClick = () => {
    navigate("/quotes/quote-product", {
      state: { ids: id, productCodes: selectedProductCode },
    });
  };

  return (
    <>
      <div id="view-page">
        <Header />
        <div className="contactAccount">
          {/* Your existing JSX for page header */}
        </div>
        <div className="tasks edit-view">
        <p class="edit-cancel"><input type="submit" class="account-save" value="Save" onClick={handleButtonClick}/><Link to={`/opportunity/quote-view/${id}`}><input type="submit" class="account-save" value="Cancel"/></Link></p> <Form.Group as={Col} md="6" style={{ margin: '0 auto' }}>
  <div className="d-flex align-items-center">
    <FloatingLabel
      controlId="floatingInput"
      label="Select Product Master"
      className="mb-3"
      style={{ width: '100%' }}
    >
      <Form.Control
        type="text"
        placeholder="Product Master"
        name=""
        defaultValue={selectedProductCode}
        style={{ width: '100%' }}
      />
    </FloatingLabel>
    <button
      className="button select-search"
      onClick={() => setIsPopupOpen(true)}
       
    >
      <IoSearch />
    </button>
  </div>
</Form.Group>

          
         
          <Popup
            open={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
            modal
            className="select-quote-product"
          >
            <table className="table " >
              <thead>
                <tr>
                  <th>product code</th>
                  <th>product name</th>
                  <th>product description</th>
                </tr>
              </thead>
              <tbody>
                {productMaster.map((y, i) => (
                  <tr
                    key={i}
                    onClick={() => {
                      setSelectedProductCode(y.product_code);
                      setIsPopupOpen(false);
                    }}
                  >
                    
                    <td>{y.product_code}</td>
                    <td>{y.product_name}</td>
                    <td>{y.product_description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Popup>
          <p class="edit-cancel"><input type="submit" class="account-save" value="Save" onClick={handleButtonClick}/><Link to={`/opportunity/quote-view/${id}`}><input type="submit" class="account-save" value="Cancel"/></Link></p>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default SelectQuoteproduct;
