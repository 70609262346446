
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header' 
import Footer from '../Footer' 
import { API_URL } from '../config';
import $ from 'jquery';
import axios from "axios";  
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
const AddQuoteProduct = () => {
  const params = useParams();
  const id = params.id;

const [productAccount, setProductaccount]=useState([]);
const [suppliers, setsupplierData]=useState([]);
const [getPrice, setgetPrice]=useState([]);
// price
 useEffect(() => {
   
    fetchDataShipment();
  }, [decryptedAccessToken]);

  const fetchDataShipment = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/quoteshipment', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      
      setShippment(data)
      console.log(data)
      
    } catch (error) {
      console.error(error);
    }
  };
useEffect(() => {
  fetchDataPrice();
}, [decryptedAccessToken]);

const fetchDataPrice = async () => {
  try {
    const response = await axios.get(API_URL+'get/all/price_items', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data.priceitem;
    console.log(data)
    setgetPrice(data);
     
  } catch (error) {
    console.error(error);
  }
};

  const [productCode, setProductCode] = useState("");
  const [productValues, setProductValues] = useState([]);
  const [products, setproducts] = useState([]);
  const [shippment, setShippment]=useState([]);
// product supplier
useEffect(() => {   
  fetchDataSupplier();
}, [decryptedAccessToken]);

const fetchDataSupplier = async () => {
  try {
    const response = await axios.get(API_URL+'get/supplier', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data;
    // console.log(data)
    setsupplierData(data);
     
  } catch (error) {
    console.error(error);
  }
};

  // product account
  useEffect(() => {   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'all/accounts', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.accounts; 
      // console.log(data)
      setProductaccount(data);
       
    } catch (error) {
      console.error(error);
    }
  };
const [formData, setFormData] = useState({
    locked:"",
supplier:"",
quotes_status:"",
quotes_name:"", 
no_of_product:"",
no_of_quote_shipment:"", 
quote_shipment:"",
product_code:"",
product_name:"",
product_account:"",
product_supplier:"",
product_description:"",
product_family:"",
product_shipping_term:"",
product_owner:"",
product_active:"",
short_term_available:"",
product_price_basis:"",
product_price_type:"",
product_port_of_loading:"",
total_moisture_typical:"",
total_moisture_minimum:"",
total_moisture_maximum:"",
ash_typical_arb:"",
ash_minimum_arb:"",
ash_maximum_arb:"",
sulphur_typical_arb:"",
sulphur_minimum_arb:"",
sulphur_maximum_arb:"",
volatile_matter_typical_arb:"",
volatile_matter_minimum_arb:"",
volatile_matter_maximum_arb:"",
 
moisture_typical:"",
moisture_minimum:"",
ash_typical:"",
ash_minimum:"",
ash_maximum:"",
 
volatile_matter_typical:"",
volatile_matter_minimum:"",
volatile_matter_maximum:"",
fixed_carbon_by_difference:"",
fixed_carbon_typical:"",
fixed_carbon_minimum:"",
fixed_carbon_maximum:"",
 
gross_air_dried_typical:"",
gross_air_dried_min:"",
gross_air_dried_max:"",
gross_as_recieved_min:"",
gross_as_recieved_max:"",
net_as_recieved_min:"",
net_as_recieved_max:"",
 
hgi_typical:"",
hgi_min:"",
hgi_max:"",
 
carbon_typical:"",
carbon_min:"",
carbon_max:"",
 
hydrogen_typical:"",
hydrogen_min:"",
hydrogen_max:"",
 
nitrogen_typical:"",
nitrogen_min:"",
nitrogen_max:"",
 
sulphur_typical:"",
sulphur_min:"",
sulphur_max_ultimate_analysis:"",
oxygen_typical:"",
oxygen_min:"",
oxygen_max:"",
 
sulphur_typical_adb:"",
sulphur_min_adb:"",
product_sulphur_max:"",
 
initial_typical:"",
initial_minimum:"",
initial_maximum:"",
spherical_typical:"",
spherical_minimum:"",
spherical_maximum:"",
hemispherical_typical:"",
hemispherical_minimum:"",
hemispherical_maximum:"",
flow_typical:"",
flow_minimum:"",
flow_maximum:"",
 
oxidising_initial_typical:"",
oxidising_initial_minimum:"",
oxidising_initial_maximum:"",
oxidising_spherical_typical:"",
oxidising_spherical_minimum:"",
oxidising_spherical_maximum:"",
oxidising_hemispherical_typical:"",
oxidising_hemispherical_minimum:"",
oxidising_hemispherical_maximum:"",
oxidising_flow_typical:"",
oxidising_flow_minimum:"",
oxidising_flow_maximum:"",
 
SiO2_Typical:"",
SiO2_Minimum:"",
SiO2_Maximum:"",
Al2O3_Typical:"",
Al2O3_Minimum:"",
Al2O3_Maximum:"",
Fe2O3_Typical:"",
Fe2O3_Minimum:"",
Fe2O3_Maximum:"",
CaO_Typical:"",
CaO_Minimum:"",
CaO_Maximum:"",
MgO_Typical:"",
MgO_Minimum:"",
MgO_Maximum:"",
MnO_Typical:"",
MnO_Minimum:"",
MnO_Maximum:"",
TiO2_Typical:"",
TiO2_Minimum:"",
TiO2_Maximum:"",
Na2O_Typical:"",
Na2O_Minimum:"",
Na2O_Maximum:"",
K2O_Typical:"",
K2O_Minimum:"",
K2O_Maximum:"",
P2O5_Typical:"",
P2O5_Minimum:"",
P2O5_Maximum:"",
SO3_Typical:"",
SO3_Minimum:"",
SO3_Maximum:"",
te_arsenic_as_typical_in_air_dried_coal:"",
te_arsenic_as_minimum_in_air_dried_coal:"",
te_arsenic_as_maximum_in_air_dried_coal:"",
te_antimony_sb_typical_in_air_dried_coal:"",
te_antimony_sb_minimum_in_air_dried_coal:"",
te_antimony_sb_maximum_in_air_dried_coal:"",
te_beryllium_be_typical_in_air_dried_coal:"",
te_beryllium_be_minimum_in_air_dried_coal:"",
te_beryllium_be_maximum_in_air_dried_coal:"",
te_boron_b_typical_in_air_dried_coal:"",
te_boron_b_minimum_in_air_dried_coal:"",
te_boron_b_maximum_in_air_dried_coal:"",
te_cadmium_cd_typical_in_air_dried_coal:"",
te_cadmium_cd_minimum_in_air_dried_coal:"",
te_cadmium_cd_maximum_in_air_dried_coal:"",
te_chromium_cr_typical_in_air_dried_coal:"",
te_chromium_cr_minimum_in_air_dried_coal:"",
te_chromium_cr_maximum_in_air_dried_coal:"",
te_copper_cu_typical_in_air_dried_coal:"",
te_copper_cu_minimum_in_air_dried_coal:"",
te_copper_cu_maximum_in_air_dried_coal:"",
te_chlorine_cl_typical_in_air_dried_coal:"",
te_chlorine_cl_minimum_in_air_dried_coal:"",
te_chlorine_cl_maximum_in_air_dried_coal:"",
te_fluorine_f_typical_in_air_dried_coal:"",
te_fluorine_f_minimum_in_air_dried_coal:"",
te_fluorine_f_maximum_in_air_dried_coal:"",
te_mercury_hg_typical_in_air_dried_coal:"",
te_mercury_hg_minimum_in_air_dried_coal:"",
te_mercury_hg_maximum_in_air_dried_coal:"",
te_molybdenum_mo_typical_in_air_dried_coal:"",
te_molybdenum_mo_minimum_in_air_dried_coal:"",
te_molybdenum_mo_maximum_in_air_dried_coal:"",
te_lead_pb_typical_in_air_dried_coal:"",
te_lead_pb_minimum_in_air_dried_coal:"",
te_lead_pb_maximum_in_air_dried_coal:"",
te_phosphorus_p_typical_in_air_dried_coal:"",
te_phosphorus_p_minimum_in_air_dried_coal:"",
te_phosphorus_p_maximum_in_air_dried_coal:"",
te_selenium_se_typical_in_air_dried_coal:"",
te_selenium_se_minimum_in_air_dried_coal:"",
te_selenium_se_maximum_in_air_dried_coal:"",
te_vanadium_v_typical_in_air_dried_coal:"",
te_vanadium_v_minimum_in_air_dried_coal:"",
te_vanadium_v_maximum_in_air_dried_coal:"",
te_zinc_zn_typical_in_air_dried_coal:"",
te_zinc_zn_minimum_in_air_dried_coal:"",
te_zinc_zn_maximum_in_air_dried_coal:"",
 
te_arsenic_as_typical:"",
te_arsenic_as_minimum:"",
te_arsenic_as_maximum:"",
te_antimony_sb_typical:"",
te_antimony_sb_minimum:"",
te_antimony_sb_maximum:"",
te_boron_b_typical:"",
te_boron_b_minimum:"",
te_boron_b_maximum:"",
te_cadmium_cd_typical:"",
te_cadmium_cd_minimum:"",
te_cadmium_cd_maximum:"",
te_chromium_cr_typical:"",
te_chromium_cr_minimum:"",
te_chromium_cr_maximum:"",
te_copper_cu_typical:"",
te_copper_cu_minimum:"",
te_copper_cu_maximum:"",
te_chlorine_cl_typical:"",
te_chlorine_cl_minimum:"",
te_chlorine_cl_maximum:"",
te_fluorine_f_typical:"",
te_fluorine_f_minimum:"",
te_fluorine_f_maximum:"",
te_mercury_hg_typical:"",
te_mercury_hg_minimum:"",
te_mercury_hg_maximum:"",
te_molybdenum_mo_typical:"",
te_molybdenum_mo_minimum:"",
te_molybdenum_mo_maximum:"",
te_lead_pb_typical:"",
te_lead_pb_minimum:"",
te_lead_pb_maximum:"",
te_phosphorus_p_typical:"",
te_phosphorus_p_minimum:"",
te_phosphorus_p_maximum:"",
te_selenium_se_typical:"",
te_selenium_se_minimum:"",
te_selenium_se_maximum:"",
te_vanadium_v_typical:"",
te_vanadium_v_min:"",
te_vanadium_v_max:"",
te_zinc_zn_typical:"",
te_zinc_zn_min:"",
te_zinc_zn_max:"",
 
size_0_to_50mm_typical:"",
size_0_to_50mm_min:"",
size_0_to_50mm_max:"",
above_0_to_50mm_typical:"",
above_0_to_50mm_min:"",
above_0_to_50mm_max:"",
under_2mm_typical:"",
under_2mm_min:"",
under_2mm_max:"",
size_above_2mm_typical:"",
size_above_2mm_min:"",
size_above_2mm_max:"",
 
testing_method:""
  });
  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    console.log(event.target);
    const newValue = type === "checkbox" ? checked : value;
    console.log(newValue);
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "locked" ? Boolean(newValue) : newValue,
    }));
  };

  useEffect(() => {
    const fetchProductCodes = async () => {
      try {
        const encryptedAccessToken = localStorage.getItem("access_token");
        const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(
            encryptedAccessToken,
            secret_key
          ).toString(enc.Utf8);
          const response = await axios.get(
            API_URL+"get/products_api", 
            {
              headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
          );
          setproducts(response.data);
          
        }
      } catch (error) {
        console.log(error);
      }
    }; 
    fetchProductCodes();
    quotes_names();
  }, []);

  const getProductValues = async (productCode) => {
    try {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);
        const response = await axios.get(
          `${API_URL}all/product/datas/product_code/${productCode}`,
          {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },
          }
        );
          console.log(response)
        setProductValues(response.data);

        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [quoteShipment_name, setquoteShipment_name] = useState([]);
  


 
    const quotes_names = async () => {
    try {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);
        const response = await axios.get(
          `${API_URL}get/quoteshipment_by_offer/${id}`,
          {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },
          }
        );
console.log(response)
        setquoteShipment_name(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleProductCodeChange = async (event) => {
    setProductCode(event.target.value);
    try {
      const productValues = await getProductValues(event.target.value);
      console.log(productValues);

      setFormData({
        ...formData,
        product_code: event.target.value,
        locked:productValues.locked,
supplier:productValues.supplier,
quotes_status:productValues.quotes_status,
quotes_name:productValues.quotes_name, 
no_of_product:productValues.no_of_product,
no_of_quote_shipment:productValues.no_of_quote_shipment, 
quote_shipment:productValues.quote_shipment,
product_code:productValues.product_code,
product_name:productValues.product_name,
product_account:productValues.product_account,
product_supplier:productValues.product_supplier,
product_description:productValues.product_description,
product_family:productValues.product_family,
product_shipping_term:productValues.product_shipping_term,
product_owner:productValues.product_owner,
product_active:productValues.product_active,
short_term_available:productValues.short_term_available,
product_price_basis:productValues.product_price_basis,
product_price_type:productValues.product_price_type,
product_port_of_loading:productValues.product_port_of_loading,
total_moisture_typical:productValues.total_moisture_typical,
total_moisture_minimum:productValues.total_moisture_minimum,
total_moisture_maximum:productValues.total_moisture_maximum,
ash_typical_arb:productValues.ash_typical_arb,
ash_minimum_arb:productValues.ash_minimum_arb,
ash_maximum_arb:productValues.ash_maximum_arb,
sulphur_typical_arb:productValues.sulphur_typical_arb,
sulphur_minimum_arb:productValues.sulphur_minimum_arb,
sulphur_maximum_arb:productValues.sulphur_maximum_arb,
volatile_matter_typical_arb:productValues.volatile_matter_typical_arb,
volatile_matter_minimum_arb:productValues.volatile_matter_minimum_arb,
volatile_matter_maximum_arb:productValues.volatile_matter_maximum_arb,
 
moisture_typical:productValues.moisture_typical,
moisture_minimum:productValues.moisture_minimum,
ash_typical:productValues.ash_typical,
ash_minimum:productValues.ash_minimum,
ash_maximum:productValues.ash_maximum,
 
volatile_matter_typical:productValues.volatile_matter_typical,
volatile_matter_minimum:productValues.volatile_matter_minimum,
volatile_matter_maximum:productValues.volatile_matter_maximum,
fixed_carbon_by_difference:productValues.fixed_carbon_by_difference,
fixed_carbon_typical:productValues.fixed_carbon_typical,
fixed_carbon_minimum:productValues.fixed_carbon_minimum,
fixed_carbon_maximum:productValues.fixed_carbon_maximum,
 
gross_air_dried_typical:productValues.gross_air_dried_typical,
gross_air_dried_min:productValues.gross_air_dried_min,
gross_air_dried_max:productValues.gross_air_dried_max,
gross_as_recieved_min:productValues.gross_as_recieved_min,
gross_as_recieved_max:productValues.gross_as_recieved_max,
net_as_recieved_min:productValues.net_as_recieved_min,
net_as_recieved_max:productValues.net_as_recieved_max,
 
hgi_typical:productValues.hgi_typical,
hgi_min:productValues.hgi_min,
hgi_max:productValues.hgi_max,
 
carbon_typical:productValues.carbon_typical,
carbon_min:productValues.carbon_min,
carbon_max:productValues.carbon_max,
 
hydrogen_typical:productValues.hydrogen_typical,
hydrogen_min:productValues.hydrogen_min,
hydrogen_max:productValues.hydrogen_max,
 
nitrogen_typical:productValues.nitrogen_typical,
nitrogen_min:productValues.nitrogen_min,
nitrogen_max:productValues.nitrogen_max,
 
sulphur_typical:productValues.sulphur_typical,
sulphur_min:productValues.sulphur_min,
sulphur_max_ultimate_analysis:productValues.sulphur_max_ultimate_analysis,
oxygen_typical:productValues.oxygen_typical,
oxygen_min:productValues.oxygen_min,
oxygen_max:productValues.oxygen_max,
 
sulphur_typical_adb:productValues.sulphur_typical_adb,
sulphur_min_adb:productValues.sulphur_min_adb,
product_sulphur_max:productValues.product_sulphur_max,
 
initial_typical:productValues.initial_typical,
initial_minimum:productValues.initial_minimum,
initial_maximum:productValues.initial_maximum,
spherical_typical:productValues.spherical_typical,
spherical_minimum:productValues.spherical_minimum,
spherical_maximum:productValues.spherical_maximum,
hemispherical_typical:productValues.hemispherical_typical,
hemispherical_minimum:productValues.hemispherical_minimum,
hemispherical_maximum:productValues.hemispherical_maximum,
flow_typical:productValues.flow_typical,
flow_minimum:productValues.flow_minimum,
flow_maximum:productValues.flow_maximum,
 
oxidising_initial_typical:productValues.oxidising_initial_typical,
oxidising_initial_minimum:productValues.oxidising_initial_minimum,
oxidising_initial_maximum:productValues.oxidising_initial_maximum,
oxidising_spherical_typical:productValues.oxidising_spherical_typical,
oxidising_spherical_minimum:productValues.oxidising_spherical_minimum,
oxidising_spherical_maximum:productValues.oxidising_spherical_maximum,
oxidising_hemispherical_typical:productValues.oxidising_hemispherical_typical,
oxidising_hemispherical_minimum:productValues.oxidising_hemispherical_minimum,
oxidising_hemispherical_maximum:productValues.oxidising_hemispherical_maximum,
oxidising_flow_typical:productValues.oxidising_flow_typical,
oxidising_flow_minimum:productValues.oxidising_flow_typical,
oxidising_flow_maximum:productValues.oxidising_flow_maximum,
 
SiO2_Typical:productValues.SiO2_Typical,
SiO2_Minimum:productValues.SiO2_Minimum,
SiO2_Maximum:productValues.SiO2_Maximum,
Al2O3_Typical:productValues.Al2O3_Typical,
Al2O3_Minimum:productValues.Al2O3_Minimum,
Al2O3_Maximum:productValues.Al2O3_Maximum,
Fe2O3_Typical:productValues.Fe2O3_Typical,
Fe2O3_Minimum:productValues.Fe2O3_Minimum,
Fe2O3_Maximum:productValues.Fe2O3_Maximum,
CaO_Typical:productValues.CaO_Typical,
CaO_Minimum:productValues.CaO_Minimum,
CaO_Maximum:productValues.CaO_Maximum,
MgO_Typical:productValues.MgO_Typical,
MgO_Minimum:productValues.MgO_Minimum,
MgO_Maximum:productValues.MgO_Maximum,
MnO_Typical:productValues.MnO_Typical,
MnO_Minimum:productValues.MnO_Minimum,
MnO_Maximum:productValues.MnO_Maximum,
TiO2_Typical:productValues.TiO2_Typical,
TiO2_Minimum:productValues.TiO2_Minimum,
TiO2_Maximum:productValues.TiO2_Maximum,
Na2O_Typical:productValues.Na2O_Typical,
Na2O_Minimum:productValues.Na2O_Minimum,
Na2O_Maximum:productValues.Na2O_Maximum,
K2O_Typical:productValues.K2O_Typical,
K2O_Minimum:productValues.K2O_Minimum,
K2O_Maximum:productValues.K2O_Maximum,
P2O5_Typical:productValues.P2O5_Typical,
P2O5_Minimum:productValues.P2O5_Minimum,
P2O5_Maximum:productValues.P2O5_Maximum,
SO3_Typical:productValues.SO3_Typical,
SO3_Minimum:productValues.SO3_Minimum,
SO3_Maximum:productValues.SO3_Maximum,
te_arsenic_as_typical_in_air_dried_coal:productValues.te_arsenic_as_typical_in_air_dried_coal,
te_arsenic_as_minimum_in_air_dried_coal:productValues.te_arsenic_as_minimum_in_air_dried_coal,
te_arsenic_as_maximum_in_air_dried_coal:productValues.te_arsenic_as_maximum_in_air_dried_coal,
te_antimony_sb_typical_in_air_dried_coal:productValues.te_antimony_sb_typical_in_air_dried_coal,
te_antimony_sb_minimum_in_air_dried_coal:productValues.te_antimony_sb_minimum_in_air_dried_coal,
te_antimony_sb_maximum_in_air_dried_coal:productValues.te_antimony_sb_maximum_in_air_dried_coal,
te_beryllium_be_typical_in_air_dried_coal:productValues.te_beryllium_be_typical_in_air_dried_coal,
te_beryllium_be_minimum_in_air_dried_coal:productValues.te_beryllium_be_minimum_in_air_dried_coal,
te_beryllium_be_maximum_in_air_dried_coal:productValues.te_beryllium_be_maximum_in_air_dried_coal,
te_boron_b_typical_in_air_dried_coal:productValues.te_boron_b_typical_in_air_dried_coal,
te_boron_b_minimum_in_air_dried_coal:productValues.te_boron_b_minimum_in_air_dried_coal,
te_boron_b_maximum_in_air_dried_coal:productValues.te_boron_b_maximum_in_air_dried_coal,
te_cadmium_cd_typical_in_air_dried_coal:productValues.te_cadmium_cd_typical_in_air_dried_coal,
te_cadmium_cd_minimum_in_air_dried_coal:productValues.te_cadmium_cd_minimum_in_air_dried_coal,
te_cadmium_cd_maximum_in_air_dried_coal:productValues.te_cadmium_cd_maximum_in_air_dried_coal,
te_chromium_cr_typical_in_air_dried_coal:productValues.te_chromium_cr_typical_in_air_dried_coal,
te_chromium_cr_minimum_in_air_dried_coal:productValues.te_chromium_cr_minimum_in_air_dried_coal,
te_chromium_cr_maximum_in_air_dried_coal:productValues.te_chromium_cr_maximum_in_air_dried_coal,
te_copper_cu_typical_in_air_dried_coal:productValues.te_copper_cu_typical_in_air_dried_coal,
te_copper_cu_minimum_in_air_dried_coal:productValues.te_copper_cu_minimum_in_air_dried_coal,
te_copper_cu_maximum_in_air_dried_coal:productValues.te_copper_cu_maximum_in_air_dried_coal,
te_chlorine_cl_typical_in_air_dried_coal:productValues.te_chlorine_cl_typical_in_air_dried_coal,
te_chlorine_cl_minimum_in_air_dried_coal:productValues.te_chlorine_cl_minimum_in_air_dried_coal,
te_chlorine_cl_maximum_in_air_dried_coal:productValues.te_chlorine_cl_maximum_in_air_dried_coal,
te_fluorine_f_typical_in_air_dried_coal:productValues.te_fluorine_f_typical_in_air_dried_coal,
te_fluorine_f_minimum_in_air_dried_coal:productValues.te_fluorine_f_minimum_in_air_dried_coal,
te_fluorine_f_maximum_in_air_dried_coal:productValues.te_fluorine_f_maximum_in_air_dried_coal,
te_mercury_hg_typical_in_air_dried_coal:productValues.te_mercury_hg_typical_in_air_dried_coal,
te_mercury_hg_minimum_in_air_dried_coal:productValues.te_mercury_hg_minimum_in_air_dried_coal,
te_mercury_hg_maximum_in_air_dried_coal:productValues.te_mercury_hg_maximum_in_air_dried_coal,
te_molybdenum_mo_typical_in_air_dried_coal:productValues.te_molybdenum_mo_typical_in_air_dried_coal,
te_molybdenum_mo_minimum_in_air_dried_coal:productValues.te_molybdenum_mo_minimum_in_air_dried_coal,
te_molybdenum_mo_maximum_in_air_dried_coal:productValues.te_molybdenum_mo_maximum_in_air_dried_coal,
te_lead_pb_typical_in_air_dried_coal:productValues.te_lead_pb_typical_in_air_dried_coal,
te_lead_pb_minimum_in_air_dried_coal:productValues.te_lead_pb_minimum_in_air_dried_coal,
te_lead_pb_maximum_in_air_dried_coal:productValues.te_lead_pb_maximum_in_air_dried_coal,
te_phosphorus_p_typical_in_air_dried_coal:productValues.te_phosphorus_p_typical_in_air_dried_coal,
te_phosphorus_p_minimum_in_air_dried_coal:productValues.te_phosphorus_p_minimum_in_air_dried_coal,
te_phosphorus_p_maximum_in_air_dried_coal:productValues.te_phosphorus_p_maximum_in_air_dried_coal,
te_selenium_se_typical_in_air_dried_coal:productValues.te_selenium_se_typical_in_air_dried_coal,
te_selenium_se_minimum_in_air_dried_coal:productValues.te_selenium_se_minimum_in_air_dried_coal,
te_selenium_se_maximum_in_air_dried_coal:productValues.te_selenium_se_maximum_in_air_dried_coal,
te_vanadium_v_typical_in_air_dried_coal:productValues.te_vanadium_v_typical_in_air_dried_coal,
te_vanadium_v_minimum_in_air_dried_coal:productValues.te_vanadium_v_minimum_in_air_dried_coal,
te_vanadium_v_maximum_in_air_dried_coal:productValues.te_vanadium_v_maximum_in_air_dried_coal,
te_zinc_zn_typical_in_air_dried_coal:productValues.te_zinc_zn_typical_in_air_dried_coal,
te_zinc_zn_minimum_in_air_dried_coal:productValues.te_zinc_zn_minimum_in_air_dried_coal,
te_zinc_zn_maximum_in_air_dried_coal:productValues.te_zinc_zn_maximum_in_air_dried_coal,
 
te_arsenic_as_typical:productValues.te_arsenic_as_typical,
te_arsenic_as_minimum:productValues.te_arsenic_as_minimum,
te_arsenic_as_maximum:productValues.te_arsenic_as_maximum,
te_antimony_sb_typical:productValues.te_antimony_sb_typical,
te_antimony_sb_minimum:productValues.te_antimony_sb_minimum,
te_antimony_sb_maximum:productValues.te_antimony_sb_maximum,
te_boron_b_typical:productValues.te_boron_b_typical,
te_boron_b_minimum:productValues.te_boron_b_minimum,
te_boron_b_maximum:productValues.te_boron_b_maximum,
te_cadmium_cd_typical:productValues.te_cadmium_cd_typical,
te_cadmium_cd_minimum:productValues.te_cadmium_cd_minimum,
te_cadmium_cd_maximum:productValues.te_cadmium_cd_maximum,
te_chromium_cr_typical:productValues.te_chromium_cr_typical,
te_chromium_cr_minimum:productValues.te_chromium_cr_minimum,
te_chromium_cr_maximum:productValues.te_chromium_cr_maximum,
te_copper_cu_typical:productValues.te_copper_cu_typical,
te_copper_cu_minimum:productValues.te_copper_cu_minimum,
te_copper_cu_maximum:productValues.te_copper_cu_maximum,
te_chlorine_cl_typical:productValues.te_chlorine_cl_typical,
te_chlorine_cl_minimum:productValues.te_chlorine_cl_minimum,
te_chlorine_cl_maximum:productValues.te_chlorine_cl_maximum,
te_fluorine_f_typical:productValues.te_fluorine_f_typical,
te_fluorine_f_minimum:productValues.te_fluorine_f_minimum,
te_fluorine_f_maximum:productValues.te_fluorine_f_maximum,
te_mercury_hg_typical:productValues.te_mercury_hg_typical,
te_mercury_hg_minimum:productValues.te_mercury_hg_minimum,
te_mercury_hg_maximum:productValues.te_mercury_hg_maximum,
te_molybdenum_mo_typical:productValues.te_molybdenum_mo_typical,
te_molybdenum_mo_minimum:productValues.te_molybdenum_mo_minimum,
te_molybdenum_mo_maximum:productValues.te_molybdenum_mo_maximum,
te_lead_pb_typical:productValues.te_lead_pb_typical,
te_lead_pb_minimum:productValues.te_lead_pb_minimum,
te_lead_pb_maximum:productValues.te_lead_pb_maximum,
te_phosphorus_p_typical:productValues.te_phosphorus_p_typical,
te_phosphorus_p_minimum:productValues.te_phosphorus_p_minimum,
te_phosphorus_p_maximum:productValues.te_phosphorus_p_maximum,
te_selenium_se_typical:productValues.te_selenium_se_typical,
te_selenium_se_minimum:productValues.te_selenium_se_minimum,
te_selenium_se_maximum:productValues.te_selenium_se_maximum,
te_vanadium_v_typical:productValues.te_vanadium_v_typical,
te_vanadium_v_min:productValues.te_vanadium_v_min,
te_vanadium_v_max:productValues.te_vanadium_v_max,
te_zinc_zn_typical:productValues.te_zinc_zn_typical,
te_zinc_zn_min:productValues.te_zinc_zn_min,
te_zinc_zn_max:productValues.te_zinc_zn_max,
 
size_0_to_50mm_typical:productValues.size_0_to_50mm_typical,
size_0_to_50mm_min:productValues.size_0_to_50mm_min,
size_0_to_50mm_max:productValues.size_0_to_50mm_max,
above_0_to_50mm_typical:productValues.above_0_to_50mm_typical,
above_0_to_50mm_min:productValues.above_0_to_50mm_min,
above_0_to_50mm_max:productValues.above_0_to_50mm_max,
under_2mm_typical:productValues.under_2mm_typical,
under_2mm_min:productValues.under_2mm_typical,
under_2mm_max:productValues.under_2mm_max,
size_above_2mm_typical:productValues.size_above_2mm_typical,
size_above_2mm_min:productValues.size_above_2mm_min,
size_above_2mm_max:productValues.size_above_2mm_max,
 
testing_method:productValues.testing_method,
        ...productValues,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const encryptedAccessToken = localStorage.getItem("access_token");
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);
      const headers = { Authorization: `Bearer ${decryptedAccessToken}` };
  
      axios
        .post(API_URL+"add/new/quote/" + id, formData, { headers })
        .then((response) => {
          console.log(response.status);
  
          if (response.status === 200) {
            // Handle success (HTTP status code 200)
            console.log("Success: Quotes Product Added Successfully");
            toast.success('Quotes Product Added Successfully', {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.success('Quotes Already Present', {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            console.log(`Unexpected success status code: ${response.status}`);
          }
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // Handle different HTTP status codes
            console.log("Error status:", error.response.status);
            console.log("Error data:", error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            console.log("No response received:", error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error setting up request:", error.message);
          }
        });
    }
  };
  

      const [check, setCheck] = useState(false);
      const [active, setActive] = useState(false);
      const [short, setShort] = useState(false);

    return (
<>
<Header/>
<div className="contactAccount"  id='product-master'>
      <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Quote Product </h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Quote Product / Add Quote Product</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
      </div>
    </div> 
  </div> 
      <Form onSubmit={handleSubmit}>
      <Row className="mb-3">
        <h4 className='heading'>Information</h4> 
        {/* <h4 className='heading'>Account Information</h4> */}
          <div className="form-grop col-md-6">
       <label htmlFor="product-code">Product Code:</label>
       <select
         className="form-control"
         id="product-code"
         name="product-code"
         value={productCode}
         onChange={handleProductCodeChange}
       >
         <option value="">Select a product code</option>
         {products.map((product) => (
           <option key={product.id} value={product.product_code}>
             {product.product_code}
           </option>
         ))}
       </select>
       </div> 
       <Form.Group as={Col} md="6" id='locked'> 
            <input type='checkbox' name='locked' onChange={(e) => setCheck(e.target.checked) } /><label>Locked</label>
          </Form.Group>    
        
         <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Supplier">
    <Form.Select aria-label="Floating label select example"
    name="supplier" 
    placeholder="Supplier" 
    value={formData.supplier}
    onChange={handleChange}  
    >

<option value='' >Select </option>
        
         {productAccount.length>0?(
                 <>
                 {
            productAccount.map((x)=>{
                return(
                    <option value={x.account_name}>{x.account_name}</option>
                )
            })
         }
                </>
                ):(
         
                 <option className='no-data'>No data  available</option>
                 )}
      
        
    </Form.Select>
    
  </FloatingLabel>

        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Quotes Status"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Quotes Status"
              name="quotes_status"
                value={formData.quotes_status}
    onChange={handleChange}  
                />

          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Quotes Name "
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Quotes Name"
              name="quotes_name"
              value={formData.quotes_name}
    onChange={handleChange}  />
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="No of Product"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="No of Product"
              name="no_of_product"
              value={formData.no_of_product}
    onChange={handleChange}  />
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="No of Quote Shipment "
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="No of Quote Shipment "
              name="no_of_quote_shipment"
              value={formData.no_of_quote_shipment}
    onChange={handleChange}  />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Quote Shipment">
    <Form.Select aria-label="Floating label select example"
    
    name="quote_shipment" 
    value={formData.quote_shipment}
    onChange={handleChange}  
    >
      <option value='' >Select</option>
       
        {shippment.length>0?(
                 <>
                {
          shippment.map((x)=>{
              return(
                <option key={x.id} value={x.quote_shipment_code}>
                {x.quote_shipment_code}
              </option>
              )
          })
       }
                </>
                ):(
         
                 <option className='no-data'>No data  available</option>
                 )}
      
        
    </Form.Select> 
  </FloatingLabel>
              </Form.Group> 
        

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Product Name"
              name="product_name"
              value={formData.product_name}
    onChange={handleChange}  
        />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Product account">
    <Form.Select aria-label="Floating label select example"
    name="product_account" 
    placeholder="Product account" 
    value={formData.product_account}
    onChange={handleChange}  >

<option value='' >Select </option>
         

{productAccount.length>0?(
                 <>
               {
            productAccount.map((x)=>{
                return(
                    <option value={x.account_name}>{x.account_name}</option>
                )
            })
         }
                </>
                ):(
         
                 <option className='no-data'>No data  available</option>
                 )}
      
        
    </Form.Select>
    
  </FloatingLabel>
              </Form.Group> 
       
         <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Product Supplier">
    <Form.Select aria-label="Floating label select example"
    name="product_supplier" 
    placeholder="Product Supplier" 
    value={formData.product_supplier}
    onChange={handleChange}  
    >

<option value='' >Select </option>
        
{productAccount.length>0?(
                 <>
               {
            productAccount.map((x)=>{
                return(
                    <option value={x.account_name}>{x.account_name}</option>
                )
            })
         }
                </>
                ):(
         
                 <option className='no-data'>No data  available</option>
                 )}
      
      
        
    </Form.Select>
    
  </FloatingLabel>

        </Form.Group>
        
        

          
       
        <Form.Group as={Col} md="12">
        <FloatingLabel controlId="floatingTextarea2" label="Product Description">
        <Form.Control
          as="textarea"
          placeholder="Product Description"
          style={{ height: '200px!important' }}
          name="product_description" 
   value={formData.product_description}
    onChange={handleChange}   />  </FloatingLabel>
    </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Family"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Product Family" 
            name="product_family"
            value={formData.product_family}
    onChange={handleChange}  
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Shipping Term"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Product Shipping Term" 
            name="product_shipping_term"
            value={formData.product_shipping_term}
    onChange={handleChange}  />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Owner"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Owner" 
            name="product_owner"
            value={formData.product_owner}
    onChange={handleChange}  
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6" className='p-1'>
        <label>
        <input
          type="checkbox" 
          name="product_active"
          onChange={(e) => setActive(e.target.checked)}
        />
         &nbsp;&nbsp; Active
      </label> 
        </Form.Group>
        <Form.Group as={Col} md="6" className='p-1'>
        <label>
        <input 
        type="checkbox" 
          name="short_term_available"
          onChange={(e) => setShort(e.target.checked)}
        />
         &nbsp;&nbsp;Short Term Available
      </label> 
     
        </Form.Group>

        
      </Row>
      <Row className="mb-3">
        <h4 className='heading'>Product Info</h4>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Price Basis"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Product Price Basis" 
            name="product_price_basis"
            value={formData.product_price_basis}
    onChange={handleChange}  />
          </FloatingLabel>
        </Form.Group>
       
        
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Product price type">
    <Form.Select aria-label="Floating label select example"
    name="product_price_type" 
    placeholder="Product Price type" 
    value={formData.product_price_type}
    onChange={handleChange}  
    > 
      <option value='' >Select</option>

       
{getPrice.length>0?(
                 <>
                      {
          getPrice.map((x)=>{
              return(
                  <option value={x.price_type_name}>{x.price_type_name}</option>
              )
          })
       }

                </>
                ):(
         
                 <option className='no-data'>No data  available</option>
                 )}
      
    </Form.Select>
    
  </FloatingLabel>
              </Form.Group> 
         
               <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Port Of Loading"
            className="mb-3"
          >
            
            <Form.Control type="text" 
            placeholder="Port Of Loading" 
            name="product_port_of_loading"
            value={formData.product_port_of_loading}
    onChange={handleChange}  
    />
          </FloatingLabel>
        </Form.Group>
        </Row>
      <Row className="mb-3">
        <h4 className='heading'>%As Received Basis</h4>
        
                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Moisture Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Tota Moisture Typical"
                      name="total_moisture_typical"
                      value={formData.total_moisture_typical}
    onChange={handleChange}  />
                   
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Moisture Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Total Moisture Minimum" 
                    name="total_moisture_minimum"
                    value={formData.total_moisture_minimum}
    onChange={handleChange}  
                    />
                     
                  </FloatingLabel>
                </Form.Group>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Moisture Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Total Moisture Maximum" 
                    name="total_moisture_maximum"
                    value={formData.total_moisture_maximum}
    onChange={handleChange}  
                    />
                    
                  </FloatingLabel>
                </Form.Group>
                
        
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash typical arb"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Ash typical arb" 
                    name="ash_typical_arb"
                    value={formData.ash_typical_arb}
    onChange={handleChange}  
                    />
                     
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Minimum ARB"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Ash Minimum ARB" 
                    name="ash_minimum"
                    value={formData.ash_minimum}
    onChange={handleChange}  
                  /></FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Maximum ARB"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Ash Maximum ARB" 
                    name="ash_maximum"
                    value={formData.ash_maximum}
    onChange={handleChange}  
    />
                  </FloatingLabel>
                </Form.Group>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical ARB"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Sulphur Typical ARB" 
                    name="sulphur_typical_arb"
                    value={formData.sulphur_typical_arb}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Minimum ARB"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Volatile Matter Minimum ARB" 
                    name="volatile_matter_minimum_arb"
                    value={formData.volatile_matter_minimum_arb}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Maximum ARB"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Volatile Matter Maximum ARB" 
                    name="volatile_matter_maximum_arb"
                    value={formData.volatile_matter_maximum_arb}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group> 
        </Row>
         
        <Row className="mb-3">
                <h4 className='heading'>Proximate Analysis %Air Dried Basis</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Moisture Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Moisture Typical"
                      name="moisture_typical"
                      value={formData.moisture_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Moisture Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Moisture Minimum" 
                    name="moisture_minimum"
                    value={formData.moisture_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                
        
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Ash Typical" 
                    name="ash_typical"
                    value={formData.ash_typical}
    onChange={handleChange}  
    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Ash Minimum" 
                    name="ash_minimum"
                    value={formData.ash_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Ash Maximum" 
                    name="ash_maximum"
                    value={formData.ash_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Volatile Matter Typical" 
                    name="volatile_matter_typical"
                    value={formData.volatile_matter_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Volatile Matter Minimum" 
                    name="volatile_matter_minimum"
                    value={formData.volatile_matter_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Volatile Matter Maximum" 
                    name="volatile_matter_maximum"
                    value={formData.volatile_matter_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon By Difference"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Fixed Carbon By Difference" 
                    name="fixed_carbon_by_difference"
                    value={formData.fixed_carbon_by_difference}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Fixed Carbon Typical" 
                    name="fixed_carbon_typical"
                    value={formData.fixed_carbon_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Fixed Carbon Minimum" 
                    name="fixed_carbon_minimum"
                    value={formData.fixed_carbon_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fixed Carbon Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Fixed Carbon Maximum" 
                    name="fixed_carbon_maximum"
                    value={formData.fixed_carbon_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
              </Row>   
              <Row className="mb-3">
                <h4 className='heading'>CALORIFIC VALUE, Keal/kg</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross Air Dried Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Gross Air Dried Typical"
                      name="gross_air_dried_typical"
                      value={formData.gross_air_dried_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross Air Dried Min"
                    className="mb-3"
                  > 
                    <Form.Control type="text" 
                    placeholder="Gross Air Dried Min" 
                    name="gross_air_dried_min"
                    value={formData.gross_air_dried_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                
        
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross Air Dried Max"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Gross Air Dried Max" 
                    name="gross_air_dried_max"
                    value={formData.gross_air_dried_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross As Recieved Min"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Gross As Recieved Min" 
                    name="gross_as_recieved_min"
                    value={formData.gross_as_recieved_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross As Recieved Max"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Gross As Recieved Max" 
                    name="gross_as_recieved_max"
                    value={formData.gross_as_recieved_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Net As Recieved Min"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Net As Recieved Min" 
                    name="net_as_recieved_min"
                    value={formData.net_as_recieved_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>  

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Net As Recieved Max"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Net As Recieved Max" 
                    name="net_as_recieved_max"
                    value={formData.net_as_recieved_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>  
              </Row> 
              <Row className="mb-3">
                <h4 className='heading'>HGI</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="HGI typical" 
                    name="hgi_typical"
                    value={formData.hgi_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group> 
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="HGI minimum" 
                    name="hgi_min"
                    value={formData.hgi_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group> 
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="HGI Maximum" 
                    name="hgi_max"
                    value={formData.hgi_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group> 
                </Row>  
                <Row className="mb-3">
                <h4 className='heading'>ULTIMATE ANALYSIS %dry ash free basis</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="text"
                      placeholder="Carbon Typical"
                      name="carbon_typical"
                      value={formData.carbon_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Carbon Minimum" 
                    name="carbon_min"
                    value={formData.carbon_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                
        
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Carbon Maximum" 
                    name="carbon_max"
                    value={formData.carbon_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Hydrogen Typical" 
                    name="hydrogen_typical"
                    value={formData.hydrogen_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Hydrogen Minimum" 
                    name="hydrogen_min"
                    value={formData.hydrogen_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Hydrogen Maximum" 
                    name="hydrogen_max"
                    value={formData.hydrogen_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                
                 
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Nitrogen Typical" 
                    name="nitrogen_typical"
                    value={formData.nitrogen_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Nitrogen Minimum" 
                    name="nitrogen_min"
                    value={formData.nitrogen_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Nitrogen Maximum" 
                    name="nitrogen_max"
                    value={formData.nitrogen_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Sulphur Typical" 
                    name="sulphur_typical"
                    value={formData.sulphur_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Sulphur Minimum" 
                    name="sulphur_minimum"
                    value={formData.sulphur_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                
               
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Oxygen Typical" 
                    name="oxygen_typical"
                    value={formData.oxygen_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Oxygen Minimum" 
                    name="oxygen_min"
                    value={formData.oxygen_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="text" 
                    placeholder="Oxygen Maximum" 
                    name="oxygen_max"
                    value={formData.oxygen_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
              </Row>  
              <Row className="mb-3">
                <h4 className='heading'>SULPHUR %air dried basis</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical Adb"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Sulphur Typical Adb"
                      name="sulphur_typical_adb"
                      value={formData.sulphur_typical_adb}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Minimum Adb"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Sulphur Minimum Adb" 
                    name="sulphur_min_adb"
                    value={formData.sulphur_min_adb}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                
        
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Sulphur Maximum" 
                    name="product_sulphur_max"
                    value={formData.product_sulphur_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                
              </Row>  
              <Row className="mb-3">
                <h4 className='heading'>ASH FUSION TEMPERATURE, DEG (C) Reducing</h4>
                
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Initial Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Initial Typical" 
                    name="initial_typical"
                    value={formData.initial_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Initial Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Initial Minimum" 
                    name="initial_minimum"
                    value={formData.initial_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Initial Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Initial Maximum" 
                    name="initial_maximum"
                    value={formData.initial_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Spherical Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Spherical Typical" 
                    name="spherical_typical"
                    value={formData.spherical_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Spherical Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Spherical Minimum" 
                    name="spherical_minimum"
                    value={formData.spherical_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Spherical Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Spherical Maximum" 
                    name="spherical_maximum"
                    value={formData.spherical_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hemispherical Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Hemispherical Typical" 
                    name="hemispherical_typical"
                    value={formData.hemispherical_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hemispherical Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Hemispherical Minimum" 
                    name="hemispherical_minimum"
                    value={formData.hemispherical_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hemispherical Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Hemispherical Maximum" 
                    name="hemispherical_maximum"
                    value={formData.hemispherical_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Flow Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Flow Typical" 
                    name="flow_typical"
                    value={formData.flow_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Flow Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Flow Minimum" 
                    name="flow_minimum"
                    value={formData.flow_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Spherical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Spherical" 
                    name="spherical_minimum"
                    value={formData.spherical_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Flow Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Flow Maximum" 
                    name="flow_maximum"
                    value={formData.flow_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <h4 className='heading'>ASH FUSION TEMPERATURE, Deg C Oxidizing</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Deformtion Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidizing Initial Deformtion Typical"
                      name="oxidizing_initial_typical"
                      value={formData.oxidizing_initial_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
               
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Initial Minimum" 
                    name="oxidizing_initial_minimum"
                    value={formData.oxidising_initial_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                
         
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Initial Maximum" 
                    name="oxidizing_initial_maximum"
                    value={formData.oxidizing_initial_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Spherical Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Spherical Typical" 
                    name="oxidizing_spherical_typical"
                    value={formData.oxidizing_spherical_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Spherical Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Spherical Minimum" 
                    name="oxidizing_spherical_minimum"
                    value={formData.oxidizing_spherical_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Spherical Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Spherical Maximum" 
                    name="oxidizing_spherical_maximum"
                   value={formData.oxidizing_spherical_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Hemispherical Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Hemispherical Typical" 
                    name="oxidizing_hemispherical_typical"
                     value={formData.oxidizing_hemispherical_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Hemispherical Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Hemispherical Minimum" 
                    name="oxidising_hemispherical_minimum"
                    value={formData.oxidising_hemispherical_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Hemispherical Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Hemispherical Maximum" 
                    name="oxidizing_hemispherical_maximum"
                    
                    value={formData.oxidizing_hemispherical_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Flow Typical"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Flow Typical" 
                    name="oxidising_flow_typical"
                    
                    value={formData.oxidising_flow_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Flow Minimum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Flow Minimum" 
                    name="oxidising_flow_minimum"
                    value={formData.oxidising_flow_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Flow Maximum"
                    className="mb-3"
                  >
                    
                    <Form.Control type="number" 
                    placeholder="Oxidizing Flow Maximum" 
                    name="oxidizing_flow_maximum"
                    value={formData.oxidizing_flow_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
              </Row>  
              <Row className="mb-3">
                <h4 className='heading'>ASH ANALYSIS %Dry Basis in Ash</h4>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SiO2 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SiO2 Typical"
                      name="SiO2_Typical"
                    value={formData.SiO2_Typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SiO2 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SiO2 Minimum"
                      name="SiO2_Minimum"
                      
                    value={formData.SiO2_Minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SiO2 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SiO2 Maximum"
                      name="SiO2_Maximum"
                    value={formData.SiO2_Maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Al2O3 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Al2O3 Typical"
                      name="Al2O3_Typical"
                      
                    value={formData.Al2O3_Typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Al2O3 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Al2O3 Minimum"
                      name="Al2O3_Minimum"
                      
                    value={formData.Al2O3_Minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Al2O3 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Al2O3 Maximum"
                      name="Al2O3_Maximum"
                      
                    value={formData.Al2O3_Maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fe2O3 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Fe2O3 Typical"
                      name="Fe2O3_Typical"

                    value={formData.Fe2O3_Typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fe2O3 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Fe2O3 Minimum"
                      name="Fe2O3_Minimum"
                    value={formData.Fe2O3_Minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fe2O3 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Fe2O3 Maximum"
                      name="Fe2O3_Maximum"
                      
                    value={formData.Fe2O3_Maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="CaO Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="CaO Typical"
                      name="CaO_Typical"
                    value={formData.CaO_Typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="CaO Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="CaO Minimum"
                      name="CaO_Minimum"
                      
                    value={formData.CaO_Minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="CaO Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="CaO Maximum"
                      name="CaO_Maximum"
                      
                    value={formData.CaO_Maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MgO Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MgO Typical"
                      name="MgO_Typical"
                      
                    value={formData.MgO_Typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MgO Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MgO Minimum"
                      name="MgO_Minimum"
                      
                    value={formData.MgO_Minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MgO Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MgO Maximum"
                      name="MgO_Maximum"
                      
                    value={formData.MgO_Maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MnO Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MnO Typical"
                      name="MnO_Typical"
                      
                    value={formData.MnO_Typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MnO Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MnO Minimum"
                      name="MnO_Minimum"
                      
                    value={formData.MnO_Minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MnO Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="MnO Maximum"
                      name="MnO_Maximum"

                    value={formData.MnO_Maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TiO2 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TiO2 Typical"
                      name="TiO2_Typical"
                      
                    value={formData.TiO2_Typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TiO2 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TiO2 Minimum"
                      name="TiO2_Minimum"
                      
                    value={formData.TiO2_Minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TiO2 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TiO2 Maximum"
                      name="TiO2_Maximum"
                      
                    value={formData.TiO2_Maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Na2O Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Na2O Typical"
                      name="Na2O_Typical"

                    value={formData.Na2O_Typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Na2O Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Na2O Minimum"
                      name="Na2O_Minimum"

                    value={formData.Na2O_Minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Na2O Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Na2O Maximum"
                      name="Na2O_Maximum"
                      
                    value={formData.Na2O_Maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="K2O Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="K2O Typical"
                      name="K2O_Typical"
                      
                    value={formData.K2O_Typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="K2O Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="K2O Minimum"
                      name="K2O_Minimum"
                    value={formData.K2O_Minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="K2O Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="K2O Maximum"
                      name="K2O_Maximum"
                      
                    value={formData.K2O_Maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="P2O5 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="P2O5 Typical"
                      name="P2O5_Typical"
                      
                    value={formData.P2O5_Typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="P2O5 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="P2O5 Minimum"
                      name="P2O5_Minimum"
                      
                    value={formData.P2O5_Minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="P2O5 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="P2O5 Maximum"
                      name="P2O5_Maximum"
                      
                    value={formData.P2O5_Maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SO3 Typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SO3 Typical"
                      name="SO3_Typical"
                      
                    value={formData.SO3_Typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SO3 Minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SO3 Minimum"
                      name="SO3_Minimum"

                    value={formData.SO3_Minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SO3 Maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="SO3 Maximum"
                      name="SO3_Maximum"
                      
                    value={formData.SO3_Maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
               
                 
                
              </Row> 
              <Row className="mb-3">
                <h4 className='heading'>Trace Elements, ppm Air Dried in Coal</h4>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE arsenic as typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE arsenic as typical"
                      name="te_arsenic_as_typical_in_air_dried_coal"
                      
                    value={formData.te_arsenic_as_typical_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE rsenic as minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE rsenic as minimum"
                      name="te_arsenic_as_minimum_in_air_dried_coal"
                    value={formData.te_arsenic_as_minimum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE arsenic as maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE arsenic as maximum"
                      name="te_arsenic_as_maximum_in_air_dried_coal"
                      value={formData.te_arsenic_as_maximum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE antimony sb typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE antimony sb typical"
                      name="te_antimony_sb_typical_in_air_dried_coal"
                      value={formData.te_antimony_sb_typical_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE antimony sb minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE antimony sb minimum"
                      name="te_antimony_sb_minimum_in_air_dried_coal"
                      value={formData.te_antimony_sb_minimum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE antimony sb maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE antimony sb maximum"
                      name="te_antimony_sb_maximum_in_air_dried_coal"
                      value={formData.te_antimony_sb_maximum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE boron b typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE boron b typical"
                      name="te_boron_b_typical_in_air_dried_coal"
                      value={formData.te_boron_b_typical_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE boron b minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE boron b minimum"
                      name="te_boron_b_minimum_in_air_dried_coal"
                      value={formData.te_boron_b_minimum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE boron b maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE boron b maximum"
                      name="te_boron_b_maximum_in_air_dried_coal"
                      value={formData.te_boron_b_maximum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE cadmium cd typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE cadmium cd typical"
                      name="te_cadmium_cd_typical_in_air_dried_coal"
                      value={formData.te_cadmium_cd_typical_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE cadmium cd minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE cadmium cd minimum"
                      name="te_cadmium_cd_minimum_in_air_dried_coal"
                      value={formData.te_cadmium_cd_minimum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE cadamium cd maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE cadamium cd maximum"
                      name="te_cadamium_cd_maximum_in_air_dried_coal"
                      value={formData.te_cadamium_cd_maximum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chromium cr typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chromium cr typical"
                      name="te_chromium_cr_typical_in_air_dried_coal"
                      value={formData.te_chromium_cr_typical_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chromium cr minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chromium cr minimum"
                      name="te_chromium_cr_minimum_in_air_dried_coal"
                      value={formData.te_chromium_cr_minimum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chromium cr maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chromium cr maximum"
                      name="te_chromium_cr_maximum_in_air_dried_coal"
                      value={formData.te_chromium_cr_maximum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE copper cu typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE copper cu typical"
                      name="te_copper_cu_typical_in_air_dried_coal"
                      value={formData.te_copper_cu_typical_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE copper cu minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE copper cu minimum"
                      name="te_copper_cu_minimum_in_air_dried_coal"
                      value={formData.te_copper_cu_minimum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE copper cu maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE copper cu maximum"
                      name="te_copper_cu_maximum_in_air_dried_coal"
                      value={formData.te_copper_cu_maximum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chlorine cl typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chlorine cl typical"
                      name="te_chlorine_cl_typical_in_air_dried_coal"
                      value={formData.te_chlorine_cl_typical_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chlorine cl minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chlorine cl minimum"
                      name="te_chlorine_cl_minimum_in_air_dried_coal"
                      value={formData.te_chlorine_cl_minimum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chlorine cl maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chlorine cl maximum"
                      name="te_chlorine_cl_maximum_in_air_dried_coal"
                      value={formData.te_chlorine_cl_maximum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE fluorine f typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE fluorine f typical"
                      name="te_fluorine_f_typical_in_air_dried_coal"
                      value={formData.te_fluorine_f_typical_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE fluorine f minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE fluorine f minimum"
                      name="te_fluorine_f_minimum_in_air_dried_coal"
                      value={formData.te_fluorine_f_minimum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE fluorine f maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE fluorine f maximum"
                      name="te_fluorine_f_maximum_in_air_dried_coal"
                      value={formData.te_fluorine_f_maximum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE mercury hg typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE mercury hg typical"
                      name="te_mercury_hg_typical_in_air_dried_coal"
                      value={formData.te_mercury_hg_typical_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE mercury hg minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE mercury hg minimum"
                      name="te_mercury_hg_minimum_in_air_dried_coal"
                      value={formData.te_mercury_hg_minimum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE mercury hg maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE mercury hg maximum"
                      name="te_mercury_hg_maximum_in_air_dried_coal"
                      value={formData.te_mercury_hg_maximum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE molybdenum mo typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE molybdenum mo typical"
                      name="te_molybdenum_mo_typical_in_air_dried_coal"
                      value={formData.te_molybdenum_mo_typical_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE molybdenum mo minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE molybdenum mo minimum"
                      name="te_molybdenum_mo_minimum_in_air_dried_coal"
                      value={formData.te_molybdenum_mo_minimum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE molybdenum mo maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE molybdenum mo maximum"
                      name="te_molybdenum_mo_maximum_in_air_dried_coal"
                      value={formData.te_molybdenum_mo_maximum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE lead pb typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE lead pb typical"
                      name="te_lead_pb_typical_in_air_dried_coal"
                      value={formData.te_lead_pb_typical_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE lead pb minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE lead pb minimum"
                      name="te_lead_pb_minimum_in_air_dried_coal"
                      value={formData.te_lead_pb_minimum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE lead pb maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE lead pb maximum"
                      name="te_lead_pb_maximum_in_air_dried_coal"
                      value={formData.te_lead_pb_maximum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE phosphorus p typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE phosphorus p typical"
                      name="te_phosphorus_p_typical_in_air_dried_coal"
                      value={formData.te_phosphorus_p_typical_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE phosphorus p minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE phosphorus p minimum"
                      name="te_phosphorus_p_minimum_in_air_dried_coal"
                      value={formData.te_phosphorus_p_minimum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE phosphorus p maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE phosphorus p maximum"
                      name="te_phosphorus_p_maximum_in_air_dried_coal"
                      value={formData.te_phosphorus_p_maximum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE selenium se typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE selenium se typical"
                      name="te_selenium_se_typical_in_air_dried_coal"
                      value={formData.te_selenium_se_typical_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE selenium se minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE selenium se minimum"
                      name="te_selenium_se_minimum_in_air_dried_coal"
                      value={formData.te_selenium_se_minimum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE selenium se maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE selenium se maximum"
                      name="te_selenium_se_maximum_in_air_dried_coal"
                      value={formData.te_selenium_se_maximum_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE vanadium v typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE vanadium v typical"
                      name="te_vanadium_v_typical_in_air_dried_coal"
                      value={formData.te_vanadium_v_typical_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE vanadium v min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE vanadium v min"
                      name="te_vanadium_v_min_in_air_dried_coal"
                      value={formData.te_vanadium_v_min_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE vanadium v max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE vanadium v max"
                      name="te_vanadium_v_max_in_air_dried_coal"
                      value={formData.te_vanadium_v_max_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE zinc zn typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE zinc zn typical"
                      name="te_zinc_zn_typical_in_air_dried_coal"
                      value={formData.te_zinc_zn_typical_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE zinc zn min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE zinc zn min"
                      name="te_zinc_zn_min_in_air_dried_coal"
                      value={formData.te_zinc_zn_min_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE zinc zn max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="TE zinc zn max"
                      name="te_zinc_zn_max_in_air_dried_coal"
                      value={formData.te_zinc_zn_max_in_air_dried_coal}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
               
                
              </Row>
               
              <Row className="mb-3">
                <h4 className='heading'>Trace Elements, ppm Dried Basis</h4>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="te arsenic as typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="te arsenic as typical"
                      name="te_arsenic_as_typical"
                      value={formData.te_arsenic_as_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
         
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te arsenic as minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te arsenic as minimum"
                      name="te_arsenic_as_minimum"
                      value={formData.te_arsenic_as_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te arsenic as maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te arsenic as maximum"
                      name="te_arsenic_as_maximum"
                      value={formData.te_arsenic_as_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te antimony sb typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te antimony sb typical"
                      name="te_antimony_sb_typical"
                      value={formData.te_antimony_sb_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te antimony sb minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te antimony sb minimum"
                      name="te_antimony_sb_minimum"
                      value={formData.te_antimony_sb_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te antimony sb maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te antimony sb maximum"
                      name="te_antimony_sb_maximum"
                      value={formData.te_antimony_sb_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te beryllium be typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te beryllium be typical"
                      name="te_beryllium_be_typical"
                      value={formData.te_beryllium_be_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te beryllium be minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te beryllium be minimum"
                      name="te_beryllium_be_minimum"
                      value={formData.te_beryllium_be_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te beryllium be maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te beryllium be maximum"
                      name="te_beryllium_be_maximum"
                      value={formData.te_beryllium_be_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te boron b typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te boron b typical"
                      name="te_boron_b_typical"
                      value={formData.te_boron_b_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te boron b minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te boron b minimum"
                      name="te_boron_b_minimum"
                      value={formData.te_boron_b_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te boron b maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te boron b maximum"
                      name="te_boron_b_maximum"
                      value={formData.te_boron_b_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te cadmium cd typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te cadmium cd typical"
                      name="te_cadmium_cd_typical"
                      value={formData.te_cadmium_cd_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te cadmium cd minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te cadmium cd minimum"
                      name="te_cadmium_cd_minimum"
                      value={formData.te_cadmium_cd_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te cadmium cd maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te cadmium cd maximum"
                      name="te_cadmium_cd_maximum"
                      value={formData.te_cadmium_cd_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chromium cr typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chromium cr typical"
                      name="te_chromium_cr_typical"
                      value={formData.te_chromium_cr_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chromium cr minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chromium cr minimum"
                      name="te_chromium_cr_minimum"
                      value={formData.te_chromium_cr_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chromium cr maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chromium cr maximum"
                      name="te_chromium_cr_maximum"
                      value={formData.te_chromium_cr_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te copper cu typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te copper cu typical"
                      name="te_copper_cu_typical"
                      value={formData.te_copper_cu_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te copper cu minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te copper cu minimum"
                      name="te_copper_cu_minimum"
                      value={formData.te_copper_cu_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te copper cu maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te copper cu maximum"
                      name="te_copper_cu_maximum"
                      value={formData.te_copper_cu_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chlorine cl typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chlorine cl typical"
                      name="te_chlorine_cl_typical"
                      value={formData.te_chlorine_cl_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chlorine cl minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chlorine cl minimum"
                      name="te_chlorine_cl_minimum"
                      value={formData.te_chlorine_cl_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chlorine cl maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chlorine cl maximum"
                      name="te_chlorine_cl_maximum"
                      value={formData.te_chlorine_cl_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te fluorine f typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te fluorine f typical"
                      name="te_fluorine_f_typical"
                      value={formData.te_fluorine_f_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te fluorine f minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te fluorine f minimum"
                      name="te_fluorine_f_minimum"
                      value={formData.te_fluorine_f_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te fluorine f maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te fluorine f maximum"
                      name="te_fluorine_f_maximum"
                      value={formData.te_fluorine_f_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te mercury hg typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te mercury hg typical"
                      name="te_mercury_hg_typical"
                      value={formData.te_mercury_hg_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te mercury hg minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te mercury hg minimum"
                      name="te_mercury_hg_minimum"
                      value={formData.te_mercury_hg_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te mercury hg maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te mercury hg maximum"
                      name="te_mercury_hg_maximum"
                      value={formData.te_mercury_hg_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te molybdenum mo typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te molybdenum mo typical"
                      name="te_molybdenum_mo_typical"
                      value={formData.te_molybdenum_mo_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te molybdenum mo minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te molybdenum mo minimum"
                      name="te_molybdenum_mo_minimum"
                      value={formData.te_molybdenum_mo_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te molybdenum mo maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te molybdenum mo maximum"
                      name="te_molybdenum_mo_maximum"
                      value={formData.te_molybdenum_mo_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te lead pb typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te lead pb typical"
                      name="te_lead_pb_typical"
                      value={formData.te_lead_pb_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te lead pb minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te lead pb minimum"
                      name="te_lead_pb_minimum"
                      value={formData.te_lead_pb_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te lead pb maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te lead pb maximum"
                      name="te_lead_pb_maximum"
                      value={formData.te_lead_pb_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te phosphorus p typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te phosphorus p typical"
                      name="te_phosphorus_p_typical"
                      value={formData.te_phosphorus_p_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te phosphorus p minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te phosphorus p minimum"
                      name="te_phosphorus_p_minimum"
                      value={formData.te_phosphorus_p_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te phosphorus p maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te phosphorus p maximum"
                      name="te_phosphorus_p_maximum"
                      value={formData.te_phosphorus_p_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te selenium se typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te selenium se typical"
                      name="te_selenium_se_typical"
                      value={formData.te_selenium_se_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te selenium se minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te selenium se minimum"
                      name="te_selenium_se_minimum"
                      value={formData.te_selenium_se_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te selenium se maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te selenium se maximum"
                      name="te_selenium_se_maximum"
                      value={formData.te_selenium_se_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te vanadium v typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te vanadium v typical"
                      name="te_vanadium_v_typical"
                      value={formData.te_vanadium_v_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te vanadium v minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te vanadium v minimum"
                      name="te_vanadium_v_minimum"
                      value={formData.te_vanadium_v_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te vanadium v maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te vanadium v maximum"
                      name="te_vanadium_v_maximum"
                      value={formData.te_vanadium_v_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te zinc zn typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te zinc zn typical"
                      name="te_zinc_zn_typical"
                      value={formData.te_zinc_zn_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te zinc zn minimum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te zinc zn minimum"
                      name="te_zinc_zn_minimum"
                      value={formData.te_zinc_zn_minimum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te zinc zn maximum"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Te zinc zn maximum"
                      name="te_zinc_zn_maximum"
                      value={formData.te_zinc_zn_maximum}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                
                
              </Row> 
              <Row className="mb-3">
                <h4 className='heading'>SIZING, %</h4>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size 0 to 50mm typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size 0 to 50mm typical"
                      name="size_0_to_50mm_typical"
                      value={formData.size_0_to_50mm_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size 0 to 50mm min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size 0 to 50mm min"
                      name="size_0_to_50mm_min"
                      value={formData.size_0_to_50mm_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size 0 to 50mm max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size 0 to 50mm max"
                      name="size_0_to_50mm_max"
                      value={formData.size_0_to_50mm_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Above 0 to 50mm typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Above 0 to 50mm typical"
                      name="above_0_to_50mm_typical"
                      value={formData.above_0_to_50mm_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Above 0 to 50mm min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Above 0 to 50mm min"
                      name="above_0_to_50mm_min"
                      value={formData.above_0_to_50mm_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Above 0 to 50mm max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Above 0 to 50mm max"
                      name="above_0_to_50mm_max"
                      value={formData.above_0_to_50mm_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Under 2mm typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Under 2mm typical"
                      name="under_2mm_typical"
                      value={formData.under_2mm_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Under 2mm min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Under 2mm min"
                      name="under_2mm_min"
                      value={formData.under_2mm_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Under 2mm max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Under 2mm max"
                      name="under_2mm_max"
                      value={formData.under_2mm_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size above 2mm typical"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size above 2mm typical"
                      name="size_above_2mm_typical"
                      value={formData.size_above_2mm_typical}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size above 2mm min"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size above 2mm min"
                      name="size_above_2mm_min"
                      value={formData.size_above_2mm_min}
    onChange={handleChange}  />
                  </FloatingLabel>
                </Form.Group> 
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size above 2mm max"
                    className="mb-3"
                    
                    
                  >
                    <Form.Control
                      type="number"
                      placeholder="Size above 2mm max"
                      name="size_above_2mm_max"
                      value={formData.size_above_2mm_max}
    onChange={handleChange}  />
                  </FloatingLabel>
                  </Form.Group> 
    
              </Row> 
              <Row className="mb-3">
                <h4 className='heading'>Testing Method</h4>
                <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Testing Method">
    <Form.Select aria-label="Floating label select example"
    name="testing_method" 
    placeholder="Testing method"
    value={formData.testing_method}
    onChange={handleChange}  
    >

<option value='' >Select </option> 
<option value='According to ASTM Standard Method Unless Otherwise Stated' >According to ASTM Standard Method Unless Otherwise Stated</option> 
<option value='According to ISO Methods'>According to ISO Methods</option>
<option value='According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM'>According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM</option>
<option value='According to ISO Methods Except HGI to ASTM'>According to ISO Methods Except HGI to ASTM</option>
<option value='According to ISO Methods, Except HGI and Ash Analysis to ASTM'>According to ISO Methods, Except HGI and Ash Analysis to ASTM</option>
<option value='According to ISO Methods, Except HGI as per ASTM Test Method'>According to ISO Methods, Except HGI as per ASTM Test Method</option>
    </Form.Select>
    
  </FloatingLabel>
              </Form.Group>  
              </Row>  
             
      <input type="submit"  className="addaccount-save" value='Save'/>
      <div className='successDiv'><p></p><p></p></div>
    </Form>
    <p></p><p></p>
    </div>
    <Footer/>
    
</>
    );

  
 

};


export default AddQuoteProduct;