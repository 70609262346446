import React, { useState, useMemo, useEffect, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, watch } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from '../Header'
import Footer from '../Footer';import { API_URL } from '../config'; 
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import axios from "axios";
import { AES, enc } from "crypto-js";
import $ from 'jquery';
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
// import SingleValue from "react-select/dist/declarations/src/components/SingleValue";
function Addcontractshipment() {
  const params = useParams();
  const id = params.id; 
  const navigate = useNavigate();
  const location = useLocation();
  const { account_record} = location.state;
   
  const [startDate, setStartDate] = useState(null);
  const birthDate = startDate ? `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}` : null; 
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const [endDateError, setEndDateError] = useState('');
  const [startDate1, setStartDate1] = useState(null);
  const birthDate1 = startDate1 ? `${startDate1.getDate().toString().padStart(2, '0')}/${(startDate1.getMonth() + 1).toString().padStart(2, '0')}/${startDate1.getFullYear()}` : null; 
  const handleStartDateChange1 = (date) => {
    setStartDate1(date);
    if (!startDate) {
      setEndDateError('Please select a Laycan From Date	.');
    } else {
      const diffInTime = date.getTime() - startDate.getTime();
      const diffInDays = diffInTime / (1000 * 3600 * 24);
      if (diffInDays !== 10) {
        setEndDateError('Laycan to Date must be exactly 10 days after the Laycan From Date ');
      } else {
        setEndDateError('');
      }
    }
  };
  // const body=$(window).height()
  // const header=$("#header").height()
  // const nav=$(".navbar").height()
  // const footer=$(".footer").height() 
  // const total_height=header+nav+footer;
  // const window_height=body-total_height;  
  // $(".priceAccount div:nth-child(3)").css({"min-height":(window_height)+"px"})
  const [datas,setDatas]=useState([]);  
  const [getPrice, setPrice]=useState([]);
  const [companies, setCompanies]=useState([]); 
  const [one, setOne] = useState(new Date());
 const ones =
    one.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    one.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    one.toLocaleDateString("en-US", { day: "2-digit" });
  const Date1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      name='laycan_to_date'
      onChange={onChange} 
      ref={ref}
    ></input>
  ));
  

  const [two, setTwo] = useState(new Date());
  const twos =
     two.toLocaleDateString("en-US", { year: "numeric" }) +
     "-" +
     two.toLocaleDateString("en-US", { month: "2-digit" }) +
     "-" +
     two.toLocaleDateString("en-US", { day: "2-digit" });
   const Date2 = forwardRef(({ value, onClick, onChange }, ref) => (
     <input
       value={value}
       className="example-custom-input"
       onClick={onClick}
       name='laycan_from_date'
       onChange={onChange} 
       ref={ref}
     ></input>
   ));
   
   const [rwood,setRwood]=useState([]);
   const[currencys,setCurrencys]=useState("");
 
  const fetchDataRwood = async () => {
    try {
      const response = await axios.get(API_URL+'get/contractbyID/'+id, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data[0].contract_details.rwood_contract_number; 
      setRwood(data); 
      setCurrencys(response.data[0].contract_details.currency)
      console.log(response.data[0].contract_details.currency)
       
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
   
    fetchDataRwood();
  }, [decryptedAccessToken]);

  
   
  const handleChange = (event) => {
    const selectedCompany = event.target.value;
    console.log(selectedCompany);
    setCurrencys(selectedCompany);
  };
 useEffect(() => {
   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/contract', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.contract; 
      setCompanies(data); 
      console.log(data)
       
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
   
  fetchDataPrice();
}, [decryptedAccessToken]);
const [isFormsubmit, setFormsubmited] = useState(false);
const [check,setCheck1]=useState(false)
const fetchDataPrice = async () => {
  try {
    const response = await axios.get(API_URL+'get/all/price_items', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data.priceitem;
    setPrice(data); 
    // console.log(data)
     
  } catch (error) {
    console.error(error);
  }
};

 
useEffect(() => {
}, [currencys]);

 const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
 const priceType = watch("price_type");
 if (account_record === "Buyer - Spot") {
 const onSubmit = data =>{  
    // console.log('Test'+JSON.stringify(data)) 
   const encryptedAccessToken = localStorage.getItem('access_token');
     const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
     const responseData = {
      currency: data.currency || null,
      exchange_rate: data.exchange_rate || null,
      contract_shipment_status: data.contract_shipment_status || null,
      record_type: data.record_type || null,
      locked: data.locked || null,
      parent_shipment: data.parent_shipment || null,
      order_quantity: data.order_quantity || null,
      shipping_term: data.shipping_term || null,
      price: data.price || null,
      price_type: data.price_type || null,
      price_basis: data.price_basis || null,
      sales_price_margin: data.sales_price_margin || null,
      price_pmt_in_words: data.price_pmt_in_words || null,
      laycan_year_from: data.laycan_year_from || null,
      // laycan_year_to: data.laycan_year_to || null,
      laycan_month_from: data.laycan_month_from || null,
      // laycan_month_to: data.laycan_month_to || null,
      laycan_from_date: birthDate || null,
      laycan_to_date: birthDate1 || null,
      laycan_year: data.laycan_year || null,
      laycan_month: data.laycan_month || null,
      quantity_type: data.quantity_type || null,
      quantity: data.quantity || null,
      quantity_from: data.quantity_from || null,
      quantity_to: data.quantity_to || null,
      no_of_shipments: data.no_of_shipments || null,
      child_shipment_count: data.child_shipment_count || null,
      shipment_quantity_tolerance: data.shipment_quantity_tolerance || null,
      tcf_count: data.tcf_count || null,
      product_count: data.product_count || null,
      no_of_orders: data.no_of_orders || null
  };

  console.log(responseData)
  
     if (encryptedAccessToken) {
       const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
       
     fetch(API_URL+'add/contract/contract_shipments/'+id, {
               method: "POST",
               body: JSON.stringify(responseData),
               headers: { Authorization: `Bearer ${decryptedAccessToken}`,
               'Content-Type': 'application/json' },
             })
               .then((response) => response.json())
               
               .then((response) => { 
                console.log(response)
              
            if (response.success === true) {
              document.getElementById('messageContainer').innerHTML = `
                  <div class="success-message">
                  ${response.msg}
                  </div>
              `;
           
                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
              
              
              navigate("/shipment/contract-shipment-details/"+response.contract_ship_id);
          } else if (response.success === false) {
            
              document.getElementById('messageContainer').innerHTML = `
                  <div class="error-message">
                      ${response.msg}
                  </div>
              `;
           
              // Scroll to the top of the page
              window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
          }

               })
               
             } 
              
     };

  return (

    <div className="priceAccount" id='view-contract-ship'>
    <Header/>
    <div>
    <div className="page-header" id=''>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Contract Shippment</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Shipment / Add Shippment</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div> 
     <Form onSubmit={handleSubmit(onSubmit)} id='price'>
     <div id="messageContainer"></div>
     {Object.keys(errors).length > 0 && (
  <div className="alert alert-danger" role="alert">
    Please fix the following errors:
    <ul>
      {Object.keys(errors).map((fieldName, index) => (
        <li key={index}>{errors[fieldName]?.message}</li>
      ))}
    </ul>
  </div>
)}
     <p className='edit-cancel'><input type="submit" className="account-save" value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}/><Link to={`/contract/contract-details/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>

     <Row className="mb-3">
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Currency"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="currency"
                            value={currencys||""} onChange={handleChange}
                           {...register("currency", { required: false })}
                          >
                            <option value="">Select </option>
                            <option value="IDR">IDR </option>
                            <option value="USD">USD </option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Record type"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Record type"
                            name="record_type"
                            className="record-type"
                            defaultValue={account_record || ""}
                           {...register("record_type", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Exchange rate"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Exchange rate"
                            name="exchange_rate"
                          {...register("exchange_rate", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Contract"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Contract"
                            name="contract"
                          defaultValue={rwood||''}
                            {...register("contract", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Contract shipment status"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="contract_shipment_status"
                            placeholder="Contract shipment status"
                             {...register("contract_shipment_status", { required: false })}
                          >
                            <option value="">--None--</option>
                            <option value="Activated">Activated</option>
                            <option value="Completed">Completed</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Draft" selected>Draft</option>
                            <option value="Shipments - Arutmin">
                              Shipments - Arutmin
                            </option>
                            <option value="Cancelled with Penalty">
                              Cancelled with Penalty
                            </option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Order Quantity"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Order Quantity"
                            name="order_quantity"
                            
                             {...register("order_quantity", { required: false })}
                          />
                          {errors.order_quantity && <span>Required</span>}
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Parent Shipment"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Parent Shipment"
                            name="parent_shipment"
                            
                             {...register("parent_shipment", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6" id="lockeds">
                        <input
                          type="checkbox"
                          name="locked"
                          onChange={(e) => setCheck1(e.target.checked)}
                        />
                        <label>Locked</label>
                      </Form.Group>
                    </Row>
                    <Row>
                      <h4 className="heading">Price</h4>

                      <Form.Group as={Col} md="6">
      <FloatingLabel
        controlId="floatingSelect"
        className="dropDown"
        label="Price type"
      >
        <Form.Select
          aria-label="Floating label select example"
          name="price_type"
          placeholder="Price type"
          {...register("price_type", { required: false })}
          onChange={(e) => {
            if (e.target.value === "GcNewc" || e.target.value === "Fix Price") {
              // If price_type is "Fix Price", disable sales_price_margin input
              document.getElementById("sales_price_margin").disabled = true;
              document.getElementById("sales_price_margin").value = "";
            }  else {
              // Otherwise, enable sales_price_margin input
              document.getElementById("sales_price_margin").disabled = false;
            }
          }}
        >
          <option value="">Select</option>
          {getPrice.map((x) => {
            return (
              <option key={x.price_type_name} value={x.price_type_name}>
                {x.price_type_name}
              </option>
            );
          })}
        </Form.Select>
      </FloatingLabel>
    </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Price Basis"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="price_basis"
                            placeholder="Price Basis"
                           
                             {...register("price_basis", { required: false })}
                          >
                            <option value="">--None--</option>
                            <option value="GCV (ARB)">GCV (ARB)</option>
                            <option value="GCV (ADB)">GCV (ADB)</option>
                            <option value="NCV (ARB)">NCV (ARB)</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Price"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Price"
                            name="price"
                            
                             {...register("price", { required: false })}
                          />
                          {errors.price && <span>Required</span>}
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Shipping Term"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="shipping_term"
                            placeholder="Shipping Term"
                            
                             {...register("shipping_term", { required: false })}
                          >
                         <option value="">--None--</option><option value="FOB-B">FOB-B</option><option value="FOB-MV">FOB-MV</option><option value="CFR-MV">CFR-MV</option><option value="CIF-MV">CIF-MV</option><option value="FAS">FAS</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Price pmt in words"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Price pmt in words"
                            name="price_pmt_in_words"
                            
                             {...register("price_pmt_in_words", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
      <FloatingLabel
        controlId="floatingInput"
        label="Sales price margin"
        className="mb-3"
      >
        <Form.Control
          type="text"
          placeholder="Sales price margin"
          name="sales_price_margin" id="sales_price_margin"
          {...register("sales_price_margin", { required: false })}
          disabled={priceType === "GcNewc" || priceType === "Fix Price"}// Disable based on watched value
        />
      </FloatingLabel>
    </Form.Group>
                    </Row>
                    <Row>
                      <h4 className="heading">Delivery</h4>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Laycan From Date"
                          className="mb-3 datepick"
                        >
                          <DatePicker 
              selected={startDate}
        onChange={handleStartDateChange}
        
        dateFormat='dd/MM/yyyy'
         placeholderText="Select" 
         
      />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6"></Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Laycan To Date"
                          className="mb-3 datepick"
                        >
                         <DatePicker 
              selected={startDate1}
        onChange={handleStartDateChange1}
        
        dateFormat='dd/MM/yyyy'
         placeholderText="Select" 
         
      />
      {endDateError && (
            <Form.Text className="text-danger">{endDateError}</Form.Text>
          )}
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Year"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_year"
                            placeholder="Laycan Year"
                            className={`error-validation form-control ${errors.laycan_year ? 'is-invalid' : ''}`}
                            {...register("laycan_year", { required: 'Laycan Year is required' })}
                          >
                            <option value="">---None---</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                          </Form.Select>
                          {errors.laycan_year && <div className="invalid-feedback"></div>}
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Month"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_month"
                            placeholder="Laycan Month"
                            className={`error-validation form-control ${errors.laycan_month ? 'is-invalid' : ''}`}
                            {...register("laycan_month", { required: 'Laycan Month is required' })}
                          
                          >
                            <option value="">----None----</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </Form.Select>
                          {/* {errors.laycan_month && <span>Select Correct Month</span>} */}
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                    </Row>
                    <Row>
                      <h4 className="heading">Quanitity</h4>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Quantity"
                            name="quantity" 
                            {...register("quantity", { valueAsNumber:true, required: true })}
                          />
                          {errors.quantity && <span>Error: You must enter a value</span>}
                          
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Shipment Quantity Tolerance"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Shipment Quantity Tolerance"
                            name="shipment_quantity_tolerance"
                            {...register("shipment_quantity_tolerance", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Row>
                    <Row>
                      <h4 className="heading">System Information</h4>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="TCF Count"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="TCF Count"
                            name="tcf_count"
                            {...register("tcf_count", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="No of orders"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="No of orders"
                            name="no_of_orders"
                            {...register("no_of_orders", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Product Count"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Product Count"
                            name="product_count"
                          {...register("product_count", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Row>
      <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract/contract-details/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
        <div className='priceDiv'><p></p><p></p></div>
    </Form>
    </div>
    <Footer/>
    </div>
  );
} 
if (account_record === "Buyer - Long Term") {
  const onSubmit = data =>{  
    //  console.log('Test'+JSON.stringify(data)) 
    const encryptedAccessToken = localStorage.getItem('access_token');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
      const responseData={
        currency:data.currency|| null,
exchange_rate:data.exchange_rate|| null,
contract_shipment_status:data.contract_shipment_status|| null,
record_type:data.record_type|| null, 
locked:data.locked|| null,
parent_shipment:data.parent_shipment|| null,
order_quantity:data.order_quantity|| null,
shipping_term:data.shipping_term|| null,
price:data.price|| null,
price_type:data.price_type|| null,
price_basis:data.price_basis|| null,
sales_price_margin:data.sales_price_margin|| null,
price_pmt_in_words:data.price_pmt_in_words|| null,
laycan_year_from:data.laycan_year_from|| null,
laycan_year_to:data.laycan_year_to|| null,
 laycan_month_from:data.laycan_month_from|| null,
 laycan_month_to:data.laycan_month_to|| null, 
laycan_year:data.laycan_year|| null,
laycan_month:data.laycan_month|| null,
quantity_type:data.quantity_type|| null,
quantity:data.quantity|| null,
quantity_from:data.quantity_from|| null,
quantity_to:data.quantity_to|| null,
no_of_shipments:data.no_of_shipments|| null,
child_shipment_count:data.child_shipment_count|| null,
shipment_quantity_tolerance:data.shipment_quantity_tolerance|| null,
tcf_count:data.tcf_count|| null,
product_count:data.product_count|| null,
no_of_orders:data.no_of_orders|| null

    } 

    console.log(JSON.stringify(responseData))
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
        
      fetch(API_URL+'add/contract/contract_shipments/'+id, {
                method: "POST",
                body: JSON.stringify(responseData),
                headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                'Content-Type': 'application/json' },
              })
                .then((response) => response.json())
                
                .then((response) => { 
                 console.log(response)
                
            if (response.success === true) {
              document.getElementById('messageContainer').innerHTML = `
                  <div class="success-message">
                  ${response.msg}
                  </div>
              `;
           
                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
              
              
              navigate("/shipment/contract-shipment-details/"+response.contract_ship_id);
          } else if (response.success === false) {
            
              document.getElementById('messageContainer').innerHTML = `
                  <div class="error-message">
                      ${response.msg}
                  </div>
              `;
           
              // Scroll to the top of the page
              window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
          }

                })
             
                
              } 
               
      };
 
   return (
 
     <div className="priceAccount" id='view-contract-ship'>
     <Header/>
     <div>
     <div className="page-header" id=''>
     <div>
       <h2 className="main-content-title tx-24 mg-b-5">Add Contract Shippment</h2>
       <ol className="breadcrumb">
         <li className="breadcrumb-item active" aria-current="page">Dashboard / Shipment / Add Shippment</li>
       </ol>
     </div>
     <div className="d-flex">
       <div className="justify-content-center">
       
         {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
         </button> */}
         
       </div>
     </div> 
   </div> 
      
     <Form onSubmit={handleSubmit(onSubmit)} id='price'>
     <div id="messageContainer"></div>
     {Object.keys(errors).length > 0 && (
  <div className="alert alert-danger" role="alert">
    Please fix the following errors:
    <ul>
      {Object.keys(errors).map((fieldName, index) => (
        <li key={index}>{errors[fieldName]?.message}</li>
      ))}
    </ul>
  </div>
)}
     <p className='edit-cancel'><input type="submit" className="account-save" value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}/><Link to={`/contract/contract-details/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
<Row className="mb-3">
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Currency"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="currency" 
                            value={currencys||""} onChange={handleChange}
                            placeholder="Currency"
                             {...register("currency", { required: false })}
                          >
                            <option value="">Select </option>
                            <option value="IDR">IDR </option>
                            <option value="USD">USD </option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Record type"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Record type"
                            name="record_type"
                            className="record-type"
                            defaultValue={account_record}
                             {...register("record_type", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Exchange rate"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Exchange rate"
                            name="exchange_rate"
                             {...register("exchange_rate", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Contract"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Contract"
                            name="contract"
                          defaultValue={rwood||''}
                            {...register("contract", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Contract shipment status"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="contract_shipment_status"
                            placeholder="Contract shipment status"
                            
                             {...register("contract_shipment_status", { required: false })}
                          >
                            <option value="">--None--</option>
                            <option value="Activated">Activated</option>
                            <option value="Completed">Completed</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Draft" selected>Draft</option>
                            <option value="Shipments - Arutmin">
                              Shipments - Arutmin
                            </option>
                            <option value="Cancelled with Penalty">
                              Cancelled with Penalty
                            </option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6" id="lockeds">
                        <input
                          type="checkbox"
                          name="locked"
                          onChange={(e) => setCheck1(e.target.checked)}
                        />
                        <label>Locked</label>
                      </Form.Group>
                    </Row>
                    <Row>
                      <h4 className="heading">Price</h4>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Shipping Term"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="shipping_term"
                            placeholder="Shipping Term"
                            
                             {...register("shipping_term", { required: false })}
                          >
                            <option value="">--None--</option><option value="FOB-B">FOB-B</option><option value="FOB-MV">FOB-MV</option><option value="CFR-MV">CFR-MV</option><option value="CIF-MV">CIF-MV</option><option value="FAS">FAS</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Price"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Price"
                            name="price"
                             {...register("price", { required: false })}
                          />
                          {errors.price && <span>Required</span>}
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
      <FloatingLabel
        controlId="floatingSelect"
        className="dropDown"
        label="Price type"
      >
        <Form.Select
          aria-label="Floating label select example"
          name="price_type"
          placeholder="Price type"
          {...register("price_type", { required: false })}
          onChange={(e) => {
            if (e.target.value === "GcNewc" || e.target.value === "Fix Price") {
              // If price_type is "Fix Price", disable sales_price_margin input
              document.getElementById("sales_price_margin").disabled = true;
              document.getElementById("sales_price_margin").value = "";
            }  else {
              // Otherwise, enable sales_price_margin input
              document.getElementById("sales_price_margin").disabled = false;
            }
          }}
        >
          <option value="">Select</option>
          {getPrice.map((x) => {
            return (
              <option key={x.price_type_name} value={x.price_type_name}>
                {x.price_type_name}
              </option>
            );
          })}
        </Form.Select>
      </FloatingLabel>
    </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Price Basis"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="price_basis"
                            placeholder="Price Basis"
                           
                             {...register("price_basis", { required: false })}
                          >
                            <option value="">--None--</option>
                            <option value="GCV (ARB)">GCV (ARB)</option>
                            <option value="GCV (ADB)">GCV (ADB)</option>
                            <option value="NCV (ARB)">NCV (ARB)</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
      <FloatingLabel
        controlId="floatingInput"
        label="Sales price margin"
        className="mb-3"
      >
        <Form.Control
          type="text"
          placeholder="Sales price margin"
          name="sales_price_margin" id="sales_price_margin"
          {...register("sales_price_margin", { required: false })}
          disabled={priceType === "GcNewc" || priceType === "Fix Price"}// Disable based on watched value
        />
      </FloatingLabel>
    </Form.Group> <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Price pmt in words"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Price pmt in words"
                            name="price_pmt_in_words"
                            
                             {...register("price_pmt_in_words", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Row>
                    <Row>
                      <h4 className="heading">Delivery</h4>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Year From"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_year_from"
                            placeholder="Laycan Year From"
                            
                             {...register("laycan_year_from", { required: false })}
                          >
                            <option value="">---None---</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"> </Form.Group>
                      {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Laycan From Date"
                          className="mb-3 datepick"
                        >
                          <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            selectsStart
                            dateFormat="dd/MM/yyyy"
                            minDate={startDate}
                            startDate={startDate}
                            placeholderText="Select"
                          />
                        </FloatingLabel>
                      </Form.Group> */}

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Year To"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_year_to"
                            placeholder="Laycan Year To"
                            
                             {...register("laycan_year_to", { required: false })}
                          >
                            <option value="">---None---</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"> </Form.Group>
                      {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Laycan To Date"
                          className="mb-3 datepick"
                        >
                          <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            dateFormat="dd/MM/yyyy"
                            startDate={endDate}
                            placeholderText="Select"
                          />
                        </FloatingLabel>
                      </Form.Group> */}

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Month From"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_month_from"
                            placeholder="Laycan Month From"
                                {...register("laycan_month_from", { required: false })}
                          >
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Month To"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_month_to"
                            placeholder="Laycan Month To"
                            
                                {...register("laycan_month_to", { required: false })}
                          >
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                    </Row>
                    <Row>
                      <h4 className="heading">Quanitity</h4>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Quantity Type"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="quantity_type"
                            placeholder="Quantity Type"
                            
                                {...register("quantity_type", { required: false })}
                          >
                            <option value="">--None--</option>
                            <option value="Base Quantity">Base Quantity</option>
                            <option value="Buyers Option">Buyers Option</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="No of Shipments"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="No of Shipments"
                            name="no_of_shipments"
                            
                                {...register("no_of_shipments", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Quantity"
                            name="quantity"
                          {...register("quantity", { valueAsNumber:true, required: true })}
                          />
                          {errors.quantity && <span>Error: You must enter a value</span>}
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Child Shipment Count"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Child Shipment Count"
                            name="child_shipment_count"
                           {...register("child_shipment_count", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity From"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Quantity From"
                            name="quantity_from"
                            
                           {...register("quantity_from", { required: false })}
                          />
                          {errors.quantity_from && <span>Required</span>}
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Shipment Quantity Tolerance"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Shipment Quantity Tolerance"
                            name="shipment_quantity_tolerance"
                            
                           {...register("shipment_quantity_tolerance", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity To"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Quantity To"
                            name="quantity_to"
                            
                            {...register("quantity_to", {required: false })}
                          />
                          {errors.quantity_to && <span>Required</span>}
                        </FloatingLabel>
                      </Form.Group>
                    </Row>
                    <Row>
                      <h4 className="heading">System Information</h4>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="TCF Count"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="TCF Count"
                            name="tcf_count"
                            
                           {...register("tcf_count", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="No of orders"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="No of orders"
                            name="no_of_orders"
                            
                           {...register("no_of_orders", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Product Count"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Product Count"
                            name="product_count"
                            
                           {...register("product_count", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Order Quantity"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Order Quantity"
                            name="order_quantity"
                            
                           {...register("order_quantity", { required: false })}
                          />
                          {errors.order_quantity && <span>Required</span>}
                        </FloatingLabel>
                      </Form.Group>
                    </Row>
       <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract/contract-details/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
         <div className='priceDiv'><p></p><p></p></div>
     </Form>
     </div>
     <Footer/>
     </div>
   );
 } 
 if (account_record === "Supplier - Long Term") {
  const onSubmit = data =>{  
    //  console.log('Test'+JSON.stringify(data)) 
    const encryptedAccessToken = localStorage.getItem('access_token');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
      const responseData={
        currency: data.currency|| null,
        exchange_rate: data.exchange_rate|| null,
        contract_shipment_status: data.contract_shipment_status|| null,
        record_type: data.record_type|| null,
        contract: data.contract|| rwood || null,
        laycan_year: data.laycan_year|| null,
        parent_shipment: data.parent_shipment|| null,
        order_quantity: data.order_quantity|| null,
        shipping_term: data.shipping_term|| null,
        price: data.price|| null,
        price_type: data.price_type|| null,
        price_basis: data.price_basis|| null,
        sales_price_margin: data.sales_price_margin|| null,
        price_pmt_in_words: data.price_pmt_in_words|| null,
        quantity_type: data.quantity_type|| null,
        quantity: data.quantity|| null,
        quantity_from: data.quantity_from|| null,
        quantity_to: data.quantity_to|| null,
        no_of_shipments: data.no_of_shipments|| null,
        child_shipment_count: data.child_shipment_count|| null,
        shipment_quantity_tolerance: data.shipment_quantity_tolerance|| null,
        tcf_count: data.tcf_count|| null,
        product_count: data.product_count|| null,
        no_of_orders: data.no_of_orders|| null,
        quantity_info: data.quantity_info|| null,
        laycan_year_from: data.laycan_year_from|| null,
        laycan_year_to: data.laycan_year_to|| null,
        laycan_month_from: data.laycan_month_from|| null,
        laycan_month_to: data.laycan_month_to|| null,
        shipment_month: data.shipment_month|| null,
        laycan: data.laycan|| null,
        shipment_quantity: data.shipment_quantity|| null,
       }
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
        
      fetch(API_URL+'add/contract/contract_shipments/'+id, {
                method: "POST",
                body: JSON.stringify(responseData),
                headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                'Content-Type': 'application/json' },
              })
                .then((response) => response.json())
                
                .then((response) => { 
                  console.log(response)
                 
            if (response.success === true) {
              document.getElementById('messageContainer').innerHTML = `
                  <div class="success-message">
                  ${response.msg}
                  </div>
              `;
           
                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
              
              
              navigate("/shipment/contract-shipment-details/"+response.contract_ship_id);
          } else if (response.success === false) {
            
              document.getElementById('messageContainer').innerHTML = `
                  <div class="error-message">
                      ${response.msg}
                  </div>
              `;
           
              // Scroll to the top of the page
              window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
          }

                 })
                
              } 
               
      };
 
   return (
 
     <div className="priceAccount" id='view-contract-ship'>
     <Header/>
     <div>
     <div className="page-header" id=''>
     <div>
       <h2 className="main-content-title tx-24 mg-b-5">Add Contract Shippment</h2>
       <ol className="breadcrumb">
         <li className="breadcrumb-item active" aria-current="page">Dashboard / shipment / Add Supplier - Long Term</li>
       </ol>
     </div>
     <div className="d-flex">
       <div className="justify-content-center">
       
         {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
         </button> */}
         
       </div>
     </div> 
   </div> 
      
     <Form onSubmit={handleSubmit(onSubmit)} id='price'>
     <div id="messageContainer"></div>
     {Object.keys(errors).length > 0 && (
  <div className="alert alert-danger" role="alert">
    Please fix the following errors:
    <ul>
      {Object.keys(errors).map((fieldName, index) => (
        <li key={index}>{errors[fieldName]?.message}</li>
      ))}
    </ul>
  </div>
)}
     <p className='edit-cancel'><input type="submit" className="account-save" value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}/><Link to={`/contract/contract-details/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>

      <Row className="mb-3">
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Currency"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="currency" value={currencys||""} onChange={handleChange}
                                                       {...register("currency", { required: false })}

                          >
                            <option value="">Select </option>
                            <option value="IDR">IDR </option>
                            <option value="USD">USD </option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Record type"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Record type"
                            name="record_type"
                            className="record-type"
                            defaultValue={account_record}
                             {...register("record_type", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Exchange rate"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Exchange rate"
                            name="exchange_rate"
                            {...register("exchange_rate", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Laycan From Date"
                          className="mb-3 datepick"
                        >
                          <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            selectsStart
                            dateFormat="dd/MM/yyyy"
                            minDate={startDate}
                            startDate={startDate}
                            placeholderText="Select"
                          />
                        </FloatingLabel>
                      </Form.Group> */}

                      <Form.Group as={Col} md="6"></Form.Group>

                      {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Laycan To Date"
                          className="mb-3 datepick"
                        >
                          <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            dateFormat="dd/MM/yyyy"
                            startDate={endDate}
                            placeholderText="Select"
                          />
                        </FloatingLabel>
                      </Form.Group> */}
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
      <FloatingLabel
        controlId="floatingSelect"
        className="dropDown"
        label="Price type"
      >
        <Form.Select
          aria-label="Floating label select example"
          name="price_type"
          placeholder="Price type"
          {...register("price_type", { required: false })}
          onChange={(e) => {
            if (e.target.value === "GcNewc" || e.target.value === "Fix Price") {
              // If price_type is "Fix Price", disable sales_price_margin input
              document.getElementById("sales_price_margin").disabled = true;
              document.getElementById("sales_price_margin").value = "";
            }  else {
              // Otherwise, enable sales_price_margin input
              document.getElementById("sales_price_margin").disabled = false;
            }
          }}
        >
          <option value="">Select</option>
          {getPrice.map((x) => {
            return (
              <option key={x.price_type_name} value={x.price_type_name}>
                {x.price_type_name}
              </option>
            );
          })}
        </Form.Select>
      </FloatingLabel>
    </Form.Group>

                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Quantity"
                            name="quantity"
                            
                           {...register("quantity", { valueAsNumber:true, required: true })}
                          />
                          {errors.quantity && <span>Error: You must enter a value</span>}
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity From"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Quantity From"
                            name="quantity_from"
                            
                           {...register("quantity_from", { required: false })}
                          />
                          {errors.quantity_from && <span>Required</span>}
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity To"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Quantity To"
                            name="quantity_to"
                            {...register("quantity_to", {required: false })}
                          />
                          {errors.quantity_to && <span>Required</span>}
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Quantity Type"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="quantity_type"
                            placeholder="Quantity Type"
                            {...register("quantity_type", { required: false })}
                          >
                            <option value="">--None--</option>
                            <option value="Base Quantity">Base Quantity</option>
                            <option value="Buyers Option">Buyers Option</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Price"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Price"
                            name="price"
                            {...register("price", { required: false })}
                          />
                          {errors.price && <span>Required</span>}
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Price pmt in words"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Price pmt in words"
                            name="price_pmt_in_words"
                           {...register("price_pmt_in_words", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Shipment Quantity Tolerance"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Shipment Quantity Tolerance"
                            name="shipment_quantity_tolerance"
                            {...register("shipment_quantity_tolerance", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Year From"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_year_from"
                            placeholder="Laycan Year From"
                             {...register("laycan_year_from", { required: false })}
                          >
                            <option value="">---None---</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Year To"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_year_to"
                            placeholder="Laycan Year To"
                            
                            {...register("laycan_year_to", { required: false })}
                          >
                            <option value="">---None---</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Month From"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_month_from"
                            placeholder="Laycan Month From"
                            {...register("laycan_month_from", { required: true })}
                          >
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Month To"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_month_to"
                            placeholder="Laycan Month To"
                           
                            {...register("laycan_month_to", { required: false })}
                          >
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="No of Shipments"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="No of Shipments"
                            name="no_of_shipments"
                          
                            {...register("no_of_shipments", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Contract"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Contract"
                            name="contract"
                          defaultValue={rwood||''}
                            {...register("contract", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>

                       <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
      <FloatingLabel
        controlId="floatingInput"
        label="Sales price margin"
        className="mb-3"
      >
        <Form.Control
          type="text"
          placeholder="Sales price margin"
          name="sales_price_margin" id="sales_price_margin"
          {...register("sales_price_margin", { required: false })}
          disabled={priceType === "GcNewc" || priceType === "Fix Price"}// Disable based on watched value
        />
      </FloatingLabel>
    </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Contract shipment status"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="contract_shipment_status"
                            placeholder="Contract shipment status"
                           {...register("contract_shipment_status", { required: false })}
                          >
                            <option value="">--None--</option>
                            <option value="Activated">Activated</option>
                            <option value="Completed">Completed</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Draft" selected>Draft</option>
                            <option value="Shipments - Arutmin">
                              Shipments - Arutmin
                            </option>
                            <option value="Cancelled with Penalty">
                              Cancelled with Penalty
                            </option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Order quantity"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Order quantity"
                            name="order_quantity"
                            {...register("order_quantity", { required: false })}
                          />
                          {errors.order_quantity && <span>Required</span>}
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Price Basis"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="price_basis"
                            placeholder="Price Basis"
                             {...register("price_basis", { required: false })}
                          >
                            <option value="">--None--</option>
                            <option value="GCV (ARB)">GCV (ARB)</option>
                            <option value="GCV (ADB)">GCV (ADB)</option>
                            <option value="NCV (ARB)">NCV (ARB)</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Shipping Term"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="shipping_term"
                            placeholder="Shipping Term"
                            {...register("shipping_term", { required: false })}
                          >
                           <option value="">--None--</option><option value="FOB-B">FOB-B</option><option value="FOB-MV">FOB-MV</option><option value="CFR-MV">CFR-MV</option><option value="CIF-MV">CIF-MV</option><option value="FAS">FAS</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Year"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_year"
                            placeholder="Laycan Year"
                            
                            {...register("laycan_year", { required: false })}
                          >
                            <option value="">---None---</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group> */} 
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="TCF Count"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="TCF Count"
                            name="tcf_count"
                            
                            {...register("tcf_count", { required: false })}
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Row>
       <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract/contract-details/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
         <div className='priceDiv'><p></p><p></p></div>
     </Form>
     </div>
     <Footer/>
     </div>
   );
 } 
 if (account_record === "Supplier - Spot") {
  const onSubmit = data =>{  
    //  console.log('Test'+JSON.stringify(data)) 
    const encryptedAccessToken = localStorage.getItem('access_token');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
      const responseData={
        currency:data.currency|| null,
exchange_rate:data.exchange_rate|| null,
contract_shipment_status:data.contract_shipment_status|| null,
record_type:data.record_type|| null, 
locked:data.locked|| null,
parent_shipment:data.parent_shipment|| null,
order_quantity:data.order_quantity|| null,
shipping_term:data.shipping_term|| null,
price:data.price|| null,
// price_type:data.price_type|| null,
price_basis:data.price_basis|| null,
sales_price_margin:data.sales_price_margin|| null,
price_pmt_in_words:data.price_pmt_in_words|| null,
laycan_year_from:data.laycan_year_from|| null,
laycan_year_to:data.laycan_year_to|| null,
//  laycan_month_from:data.laycan_month_from|| null,
//  laycan_month_to:data.laycan_month_to|| null,
laycan_from_date:birthDate|| null,
laycan_to_date:birthDate1|| null,
laycan_year:data.laycan_year|| null,
laycan_month:data.laycan_month|| null,
quantity_type:data.quantity_type|| null,
quantity:data.quantity|| null,
quantity_from:data.quantity_from|| null,
quantity_to:data.quantity_to|| null,
no_of_shipments:data.no_of_shipments|| null,
child_shipment_count:data.child_shipment_count|| null,
shipment_quantity_tolerance:data.shipment_quantity_tolerance|| null,
tcf_count:data.tcf_count|| null,
product_count:data.product_count|| null,
no_of_orders:data.no_of_orders|| null 
    }
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
        
      fetch(API_URL+'add/contract/contract_shipments/'+id, {
                method: "POST",
                body: JSON.stringify(responseData),
                headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                'Content-Type': 'application/json' },
              })
                .then((response) => response.json())
                
                .then((response) => { 
                  console.log(response)
               
            if (response.success === true) {
              document.getElementById('messageContainer').innerHTML = `
                  <div class="success-message">
                  ${response.msg}
                  </div>
              `;
           
                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
              
              
              navigate("/shipment/contract-shipment-details/"+response.contract_ship_id);
          } else if (response.success === false) {
            
              document.getElementById('messageContainer').innerHTML = `
                  <div class="error-message">
                      ${response.msg}
                  </div>
              `;
           
              // Scroll to the top of the page
              window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Optional: animated scrolling
              });
          }

                 })
                
              } 
               
      };
 
   return (
 
     <div className="priceAccount" id='view-contract-ship'>
     <Header/>
     <div>
     <div className="page-header" id=''>
     <div>
       <h2 className="main-content-title tx-24 mg-b-5">Add Contract Shippment</h2>
       <ol className="breadcrumb">
         <li className="breadcrumb-item active" aria-current="page">Dashboard / shipment / Add Supplier - Long Term</li>
       </ol>
     </div>
     <div className="d-flex">
       <div className="justify-content-center">
       
         {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
         </button> */}
         
       </div>
     </div> 
   </div> 
    
   <Form onSubmit={handleSubmit(onSubmit)} id='price'>
     <div id="messageContainer"></div>
     {Object.keys(errors).length > 0 && (
  <div className="alert alert-danger" role="alert">
    Please fix the following errors:
    <ul>
      {Object.keys(errors).map((fieldName, index) => (
        <li key={index}>{errors[fieldName]?.message}</li>
      ))}
    </ul>
  </div>
)}
     <p className='edit-cancel'><input type="submit" className="account-save" value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}/><Link to={`/contract/contract-details/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
   <Row className="mb-3">
       <h4 className="heading">Contract Shipment Detail</h4>
       <Form.Group as={Col} md="6">
                 <FloatingLabel
                   controlId="floatingSelect"
                   className="dropDown"
                   label="Currency"
                 >
                   <Form.Select
                     aria-label="Floating label select example"
                     name="currency" value={currencys||""} onChange={handleChange}
                     placeholder="Currency"
                     
                     {...register("currency", { required: false })}
                   >
                     <option value="">Select </option> 
                     <option value="IDR">IDR </option>
                     <option value="USD">USD </option>
                   </Form.Select>
                   {errors.currency && <span>Required</span>}
                 </FloatingLabel>
               </Form.Group>
               <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Record type"
             className="mb-3"
             
             
           >
             <Form.Control
               type="text"
               placeholder="Record type"
               name="record_type" className='record-type'
               defaultValue={account_record}
               {...register("record_type", { required: false })}
             />
             {errors.record_type && <span>Required</span>}
           </FloatingLabel>
         </Form.Group> 
       
       <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Exchange rate"
             className="mb-3"
             
             
           >
             <Form.Control
               type="text"
               placeholder="Exchange rate"
               name="exchange_rate"
               {...register("exchange_rate", {valueAsNumber:true, required: false })}
             />
             {errors.exchange_rate && <span>Required</span>}
           </FloatingLabel>
         </Form.Group> 
         <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Laycan From Date"
                          className="mb-3 datepick"
                        >
                          <DatePicker 
              selected={startDate}
        onChange={handleStartDateChange}
        
        dateFormat='dd/MM/yyyy'
         placeholderText="Select" 
      />
                        </FloatingLabel>
                      </Form.Group>
      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Laycan To Date"
                          className="mb-3 datepick"
                        >
                         <DatePicker 
              selected={startDate1}
        onChange={handleStartDateChange1}
        
        dateFormat='dd/MM/yyyy'
         placeholderText="Select" 
      />
                        </FloatingLabel>
                      </Form.Group>
              <Form.Group as={Col} md="6">
      <FloatingLabel
        controlId="floatingSelect"
        className="dropDown"
        label="Price type"
      >
        <Form.Select
          aria-label="Floating label select example"
          name="price_type"
          placeholder="Price type"
          {...register("price_type", { required: false })}
          onChange={(e) => {
            if (e.target.value === "GcNewc" || e.target.value === "Fix Price") {
              // If price_type is "Fix Price", disable sales_price_margin input
              document.getElementById("sales_price_margin").disabled = true;
              document.getElementById("sales_price_margin").value = "";
            }  else {
              // Otherwise, enable sales_price_margin input
              document.getElementById("sales_price_margin").disabled = false;
            }
          }}
        >
          <option value="">Select</option>
          {getPrice.map((x) => {
            return (
              <option key={x.price_type_name} value={x.price_type_name}>
                {x.price_type_name}
              </option>
            );
          })}
        </Form.Select>
      </FloatingLabel>
    </Form.Group>

                  <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Quantity"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Quantity"
              name="quantity"
              {...register("quantity", { valueAsNumber:true, required: true })}
            />
            {errors.quantity && <span>Error: You must enter a value</span>}
          </FloatingLabel>
        </Form.Group> 
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Quantity From"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Quantity From"
              name="quantity_from"
              {...register("quantity_from", { required: false })}
            />
            {errors.quantity_from && <span>Required</span>}
          </FloatingLabel>
        </Form.Group> 

         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Quantity To"
             className="mb-3"
             
             
           >
             <Form.Control
               type="number"
               placeholder="Quantity To"
               name="quantity_to"
               {...register("quantity_to", {required: false })}
             />
             {errors.quantity_to && <span>Required</span>}
           </FloatingLabel>
         </Form.Group> 
          <Form.Group as={Col} md="6">
             
             <FloatingLabel controlId="floatingSelect" 
             className='dropDown' label="Quantity Type">
   <Form.Select aria-label="Floating label select example"
   name="quantity_type" 
   placeholder="Quantity" 
   defaultValue=""
   {...register("quantity_type", { required: false })}
   > 
     <option value="">--None--</option><option value="Base Quantity">Base Quantity</option><option value="Buyers Option">Buyers Option</option>
   </Form.Select>
   {errors.quantity_type && <span>Required </span>}
 </FloatingLabel>
             </Form.Group>
             <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Price"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Price"
              name="price"
              {...register("price", {valueAsNumber:true, required: false })}
            />
            {errors.price && <span>Required</span>}
          </FloatingLabel>
        </Form.Group> 
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Price pmt in words"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Price pmt in words"
              name="price_pmt_in_words"
              {...register("price_pmt_in_words", {valueAsNumber:true, required: false })}
            />
            {errors.price_pmt_in_words && <span>Required</span>}
          </FloatingLabel>
        </Form.Group> 
       <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Shipment Quantity Tolerance"
            className="mb-3"
            
            
          >
            <Form.Control
              type="number"
              placeholder="Shipment Quantity Tolerance"
              name="shipment_quantity_tolerance"
              {...register("shipment_quantity_tolerance", {valueAsNumber:true, required: false })}
            />
            {errors.shipment_quantity_tolerance && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
             
             <FloatingLabel controlId="floatingSelect" 
             className='dropDown' label="Laycan Year From">
   <Form.Select aria-label="Floating label select example"
   name="laycan_year_from" 
   placeholder="Laycan Year From" 
   defaultValue=""
   {...register("laycan_year_from", { required: false })}
   > 
     <option value='' >---None---</option>
     <option value="2021">2021</option>
     <option value="2022">2022</option>
     <option value="2023">2023</option>
     <option value="2024">2024</option>
     <option value="2025">2025</option>
     <option value="2026">2026</option>
     <option value="2015">2015</option>
     <option value="2019">2019</option>
     <option value="2020">2020</option>
   </Form.Select>
   {errors.laycan_year_from && <span>Required </span>}
 </FloatingLabel>
             </Form.Group>
               <Form.Group as={Col} md="6">
             
             <FloatingLabel controlId="floatingSelect" 
             className='dropDown' label="Laycan Year To">
   <Form.Select aria-label="Floating label select example"
   name="laycan_year_to" 
   placeholder="Laycan Year To" 
   defaultValue=""
   {...register("laycan_year_to", { required: false })}
   > 
     <option value='' >---None---</option>
     <option value="2021">2021</option>
     <option value="2022">2022</option>
     <option value="2023">2023</option>
     <option value="2024">2024</option>
     <option value="2025">2025</option>
     <option value="2026">2026</option>
     <option value="2015">2015</option>
     <option value="2019">2019</option>
     <option value="2020">2020</option>
   </Form.Select>
   {errors.laycan_year_to && <span>Required </span>}
 </FloatingLabel>
             </Form.Group>
               <Form.Group as={Col} md="6">
             
             <FloatingLabel controlId="floatingSelect" 
             className='dropDown' label="Laycan Month From">
   <Form.Select aria-label="Floating label select example"
   name="laycan_month_from" 
   placeholder="Laycan Month From" 
   defaultValue=""
   {...register("laycan_month_from", { required: true })}
   > 
     <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
   </Form.Select>
   {errors.laycan_month_from && <span>Required </span>}
 </FloatingLabel>
             </Form.Group>
             <Form.Group as={Col} md="6">
             
             <FloatingLabel controlId="floatingSelect" 
             className='dropDown' label="Laycan Month To">
   <Form.Select aria-label="Floating label select example"
   name="laycan_month_to" 
   placeholder="Laycan Month To" 
   defaultValue=""
   {...register("laycan_month_to", { required: false })}
   > 
     <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
   </Form.Select>
   {errors.laycan_month_to && <span>Required </span>}
 </FloatingLabel>
             </Form.Group>  
              <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="No of Shipments"
             className="mb-3"
             
             
           >
             <Form.Control
               type="number"
               placeholder="No of Shipments"
               name="no_of_shipments"
               {...register("no_of_shipments", {valueAsNumber:true, required: false })}
             />
             {errors.no_of_shipments && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
           <FloatingLabel
             controlId="floatingInput"
             label="Contract"
             className="mb-3"
             
             
           >
             <Form.Control
               type="text"
               placeholder="Contract"
               name="contract"
               defaultValue={rwood} className="record-type"
               {...register("contract", {required: false })}
             />
             {errors.contract && <span>Required</span>}
           </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
      <FloatingLabel
        controlId="floatingInput"
        label="Sales price margin"
        className="mb-3"
      >
        <Form.Control
          type="text"
          placeholder="Sales price margin"
          name="sales_price_margin" id="sales_price_margin"
          {...register("sales_price_margin", { required: false })}
          disabled={priceType === "GcNewc" || priceType === "Fix Price"}// Disable based on watched value
        />
      </FloatingLabel>
    </Form.Group>
          <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Contract shipment status">
    <Form.Select aria-label="Floating label select example"
    name="contract_shipment_status" 
    placeholder="Contract shipment status"
    defaultValue=""
    {...register("contract_shipment_status", { required:false })}
    > 
      <option value="">--None--</option><option value="Activated">Activated</option><option value="Completed">Completed</option><option value="Cancelled">Cancelled</option><option value="Draft" selected>Draft</option><option value="Shipments - Arutmin">Shipments - Arutmin</option><option value="Cancelled with Penalty">Cancelled with Penalty</option>
    </Form.Select>
    {errors.contract_shipment_status && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
                 <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Order quantity"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Order quantity"
              name="order_quantity"
              {...register("order_quantity", {valueAsNumber:true, required: false })}
            />
            {errors.order_quantity && <span>Required</span>}
          </FloatingLabel>
        </Form.Group> 
            <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Price Basis" >
    <Form.Select aria-label="Floating label select example"
    name="price_basis" 
    placeholder="Price Basis" 
    defaultValue=""
    {...register("price_basis", { required:false })}
    > 
      <option value="">--None--</option><option value="GCV (ARB)">GCV (ARB)</option><option value="GCV (ADB)">GCV (ADB)</option><option value="NCV (ARB)">NCV (ARB)</option>
    </Form.Select>
    {errors.price_basis && <span>Required</span>}
  </FloatingLabel>
      </Form.Group>
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Shipping Term"  >
    <Form.Select aria-label="Floating label select example"
    name="shipping_term" 
    placeholder="Shipping Term" 
    defaultValue=""
    {...register("shipping_term", { required:false })}
    > 
      <option value="">--None--</option><option value="GCV (ARB)">GCV (ARB)</option><option value="GCV (ADB)">GCV (ADB)</option><option value="NCV (ARB)">NCV (ARB)</option>
    </Form.Select>
    {errors.shipping_term && <span>Required</span>}
  </FloatingLabel>
      </Form.Group>
             <Form.Group as={Col} md="6">
            
            <FloatingLabel controlId="floatingSelect" 
            className='dropDown' label="Laycan Year">
  <Form.Select aria-label="Floating label select example"
  name="laycan_year" 
  placeholder="Laycan Year" 
  defaultValue=""
  {...register("laycan_year", { required: false })}
  > 
    <option value='' >---None---</option>
    <option value="2021">2021</option>
    <option value="2022">2022</option>
    <option value="2023">2023</option>
    <option value="2024">2024</option>
    <option value="2025">2025</option>
    <option value="2026">2026</option>
    <option value="2015">2015</option>
    <option value="2019">2019</option>
    <option value="2020">2020</option>
  </Form.Select>
  {errors.laycan_year && <span>Required </span>}
</FloatingLabel>
            </Form.Group>     
    <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TCF Count"
            className="mb-3"
            
            
          >
            <Form.Control
              type="number"
              placeholder="TCF Count"
              name="tcf_count"
              {...register("tcf_count", { valueAsNumber:true, required: false })}
            />
            {errors.tcf_count && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
       </Row>
       <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract/contract-details/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
         <div className='priceDiv'><p></p><p></p></div>
     </Form>
     </div>
     <Footer/>
     </div>
   );
 }
}

export default Addcontractshipment;
