import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header' 
import { API_URL } from '../config';
import Footer from '../Footer' 
import $ from 'jquery';
import DatePicker from "react-datepicker";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios";
import { useParams,Link , useNavigate, useLocation } from "react-router-dom"; 
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

function ShipmentOrder() {
  const navigate=useNavigate()
    const params=useParams();
    const location=useLocation();
    const { laycanDate, laycanToDate }=location.state;
    
  console.log(laycanDate,'*****',laycanToDate)
    const ids=params.id
    const [startDate, setStartDate] = useState(null);
    const [laycanFrom, setLaycanFrom] = useState(null);
    const [laycanTo, setLaycanTo] = useState(null);
    const [etadate, setEtadate] = useState(null);
    const [etbdate, setEtbdate] = useState(null);
    const [supplierDate, setSupplierDate] = useState(null);
    const [confirmationDate, setConfirmationDate] = useState(null);
    const [supplietoDate, setSupplietoDate] = useState(null);
  
    useEffect(() => {
      // Parse laycanDate and laycanToDate into Date objects
      const fromDate = parseDate(laycanDate);
      const toDate = parseDate(laycanToDate);
  
      // Set state with parsed Date objects
      setLaycanFrom(fromDate);
      setLaycanTo(toDate);
    }, [laycanDate, laycanToDate]);
  
    // Parsing function to convert string to Date object
    const parseDate = (dateString) => {
      const [day, month, year] = dateString.split('/');
      return new Date(`${year}-${month}-${day}`);
    };
  
    


    const supplier_to_date = supplietoDate ? `${supplietoDate.getDate().toString().padStart(2, '0')}/${(supplietoDate.getMonth() + 1).toString().padStart(2, '0')}/${supplietoDate.getFullYear()}` : null; 
  
  
    const order_start_date = startDate ? `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}` : null; 
     
    const confirmation_date = confirmationDate ? `${confirmationDate.getDate().toString().padStart(2, '0')}/${(confirmationDate.getMonth() + 1).toString().padStart(2, '0')}/${confirmationDate.getFullYear()}` : null; 
     
   
    const laycan_from = laycanFrom ? `${laycanFrom.getDate().toString().padStart(2, '0')}/${(laycanFrom.getMonth() + 1).toString().padStart(2, '0')}/${laycanFrom.getFullYear()}` : null;
    
    
   
  
  
     const laycan_to = laycanTo ? `${laycanTo.getDate().toString().padStart(2, '0')}/${(laycanTo.getMonth() + 1).toString().padStart(2, '0')}/${laycanTo.getFullYear()}` : null;
    
    
  
  
     const etaDate = etadate ? `${etadate.getDate().toString().padStart(2, '0')}/${(etadate.getMonth() + 1).toString().padStart(2, '0')}/${etadate.getFullYear()}` : null;
   
  
     const etbDate = etbdate ? `${etbdate.getDate().toString().padStart(2, '0')}/${(etbdate.getMonth() + 1).toString().padStart(2, '0')}/${etbdate.getFullYear()}` : null;
    
     const supplier_date= supplierDate ? `${supplierDate.getDate().toString().padStart(2, '0')}/${(supplierDate.getMonth() + 1).toString().padStart(2, '0')}/${supplierDate.getFullYear()}` : null;
    
  
    const handleStartDateChange = (date) => {
      setStartDate(date);
      console.log(date)
    };
  
    const handleEndDateChange = (date) => {
      setLaycanFrom(date);
    };
    const [endDateError, setEndDateError] = useState('');
     const handleLaycantoChange = (date) => {
      setLaycanTo(date);
      if (!laycanFrom) {
        setEndDateError('Please select a Laycan From Date	.');
      } else {
        const diffInTime = date.getTime() - laycanFrom.getTime();
        const diffInDays = diffInTime / (1000 * 3600 * 24);
        if (diffInDays !== 10) {
          setEndDateError('Laycan to Date must be exactly 10 days after the Laycan From Date ');
        } else {
          setEndDateError('');
        }
      }

    };
  
     const handleEtaChange = (date) => {
      setEtadate(date);
    };
  
     const handleEtbChange = (date) => {
      setEtbdate(date);
    }; 
    const handleSupplierDateChange = (date) => {
      setSupplierDate(date);
    };
  
    const handleConfirmeDateChange = (date) => {
      setConfirmationDate(date);
    }; 
  
  
    const handleSupplierToDateChange = (date) => {
      setSupplietoDate(date);
    };
  
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  console.log(total_height)
  console.log(body)
  const [supplier, setSupplier]=useState([]);
  $(".contactAccount").css({"min-height":(window_height)+"px"})

  useEffect(() => {
   
    fetchData5();
  }, [decryptedAccessToken]);

  const fetchData5 = async () => {
    try {
      const response = await axios.get(API_URL+'get/supplier', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data; 
      console.log(data)
      setSupplier(data);
       
    } catch (error) {
      console.error(error);
    }
  };
  const[datas,setDatas]=useState([])
  const [contractId, setContractID]=useState([])
  const [contractData, setContractData]=useState([])
  
  useEffect(() => {

    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/contract_shipbyID/'+ids, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
       console.log(data.contract_details[0].laycan_month)
      setDatas(data.contract_details[0]);
      setContractID(data.contract_details[0].contract_id)
      fetchDataContract(data.contract_details[0].contract_id)
      console.log(data.contract_details[0])
    } catch (error) {
      console.error(error);
    }
  };
   


  // useEffect(() => {

  //   fetchDataContract();
  // }, [decryptedAccessToken]);

  const fetchDataContract = async (contractId) => {
    try {
      const response = await axios.get(`${API_URL}get/contractbyID/${contractId}`, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data[0].contract_details;
      setContractData(data)
        console.log(response)
    } catch (error) {
      console.error(error);
    }
  };
   




  const [selectedValue, setSelectedValue] = useState(''); 

  const handleSelectChange = (event) => { 
    setSelectedValue(event.target.value);
  };
   
  const [selectedStatus, setSelectedStatus] = useState('');

  const handleSelectStatus = (event) => { 
    setSelectedStatus(event.target.value);
  };

  
  const [companyDropdown, setCompanyDropdown] = useState('');

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    console.log(selectedCompany);
    setCompanyDropdown(selectedCompany);
  };


    const { register, handleSubmit,control, reset, formState: { errors } } = useForm({ defaultValues: { locked: true } });
    const onSubmit = data =>{ 
      const boolValue=data.locked;
        const responseData={
          account_name:data.account_name || contractData.account_name,
          company:companyDropdown,
          status:data.status, 
          end_user:data.end_user,
          order_start_date:order_start_date,
          laycan_from_date:laycan_from,
          laycan_to_date:laycan_to,
          eta_date:etaDate,
          etb_date:etbDate,
    laycan_month:data.laycan_month,
    laycan_year:data.laycan_year,
     
          
        
      }; 
      const dataWithRoute = {
        [API_URL]: `/add/rwood_orders/${ids}`,
      };
      function determineFormURL() {
        return API_URL; 
      }

      const formURL = determineFormURL(); 
      const route = dataWithRoute[formURL];
 
      if (route) {
        // Construct the complete URL by combining the base URL with the route
        const url = `${API_URL}${route}`;

        console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
          
        fetch(url, {
                  method: "POST",
                  body: JSON.stringify(responseData),
                  headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json' },
                })
                  .then((response) => response.json())
                  
                  .then((responseData) => { 
                    console.log('res1',responseData)
                    const order_id=responseData.id;
                     if(responseData.success===true){
                      fetch(`${API_URL}add/order/preshipment/${responseData.id}`, {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${decryptedAccessToken}`,
                            "Content-Type": "application/json",
                        },
                    })
                        .then((response) => response.json())
                        .then((response) => {
                          console.log('response 2',response)
                          if(response.success===true){
                            fetch(`${API_URL}add/order/postshipment/${order_id}/${response.preship_id}`, {
                              method: "POST",
                              headers: {
                                  Authorization: `Bearer ${decryptedAccessToken}`,
                                  "Content-Type": "application/json",
                              },
                          })
                              .then((response) => response.json())
                              .then((response) => {
                                console.log('TCF Created',response);
                                window.location.href = `/order/view-order-details/${responseData.id}`;
                              });
                           }
                        });
                        // navigate("/order/view-order-details/"+response.id)
                       

                        setTimeout(() => { 
                          reset();
                        }, 300); 
                     }
                     
                     if(responseData.success===false){
                      document.getElementById('messageContainer').innerHTML = `
                      <div class="error-message">
                          ${responseData.msg}
                      </div>
                  `;
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth" // Optional: animated scrolling
                });
                     }
                  })
                  console.log(responseData)
                } 
              }    
        };

        
  
  


        const [company,setCompany]=useState([]);
        useEffect(() => {
   
            fetchDataAccount();
          }, [decryptedAccessToken]);
          
          const fetchDataAccount = async () => {
            try {
              const response = await axios.get(API_URL+'get/all/rwoodcompany', {
                headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
              );
              const data = response.data.rwoodcompany;
              setCompany(data)
               console.log(data)
            } catch (error) {
              console.error(error);
            }
          }; 
          

          const handleInputChange = (e) => {
            setContractData(e.target.value);
          };   
 
          

          const handleChangeInput = (e) => {
            const { name, value } = e.target;
            setDatas(prevState => ({
              ...prevState,
              [name]: value
            }));
          };
        

  return (

    <div className="addAccount">
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Create Order</h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Create Order </li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div>   
   <Form onSubmit={handleSubmit(onSubmit)}>
   <div id="messageContainer"></div>
   <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/shipment/contract-shipment-details/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <Row className="mb-3">
        <h4 className='heading'>Order Information</h4>
        
        
 
        
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Account Name"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Account Name"
              name="account_name"
              value={contractData.account_name || datas.account_name}
              

              {...register("account_name", { required: false })}
            />
            {errors.account_name && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Company">
    <Form.Select aria-label="Floating label select example"
    name="company" 
    placeholder="Company" className="error-validation"
    
    id="companyDropdown" 
    value={companyDropdown || contractData.company} onChange={handleCompanyChange}
     
    // {...register("supplier", { required: true })}
    > 
      <option>Select</option>
       
       
{company.length>0?(
                 <>
             {
           company.map((x)=>{
              return(
                  <option value={x.company_name}>{x.company_name}</option>
              )
          })
       }
                </>
                ):(
         
                 <option className='no-data'>No data  available</option>
                 )}
    </Form.Select>
    {errors.company && <span>Required</span>}
  </FloatingLabel>
              </Form.Group> 
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contract Number"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Contract Number"
              name="contract_number"
              value={contractData.rwood_contract_number ||''}
              

              {...register("contract_number", { required: false })}
            />
            {errors.contract_number && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>

              
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Status">
    <Form.Select aria-label="Floating label select example"
    name="status" 
    placeholder="Status" 
    defaultValue={contractData.status} 
    id="companyDropdown" value={selectedStatus || contractData.status} onChange={handleSelectStatus}
    
    >
      <option value="Additional Surveyor appointed by Rwood">Additional Surveyor appointed by Rwood</option>
<option value="Additional Surveyors certificates">Additional Surveyors certificates</option>
<option value="Agent Appointment">Agent Appointment</option>
<option value="AI-COO Courier">AI-COO Courier</option>
<option value="AI-COO issued">AI-COO issued</option>
<option value="Bl and AI COO Confirm">Bl and AI COO Confirm</option>
<option value="BL Issued">BL Issued</option>
<option value="Business Confirmation to Supplier">Business Confirmation to Supplier</option>
<option value="Cancelled">Cancelled</option>
<option value="Checking of Import Invoice">Checking of Import Invoice</option>
<option value="Transfer LC">Child LC</option>
<option value="Commercial Invoice">Commercial Invoice</option>
<option value="Commercial Invoice Bill of Exchange">Commercial Invoice Bill of Exchange</option>
<option value="Documents Arrive at RWood Bank">Documents Arrive at RWood Bank</option>
<option value="Documents negotiation by Rwood">Documents negotiation by Rwood</option>
<option value="Documents Submitted for LC Negotiation">Documents Submitted for LC Negotiation</option>
<option value="Draft">Draft</option>
<option value="Laycan Confirmation" selected>Laycan Confirmation</option>
<option value="LC Discounting Application">LC Discounting Application</option>
<option value="Master LC Issued">Master LC Issued</option>
<option value="Negotiated Document Status">Negotiated Document Status</option>
<option value="NN Documets Sent">NN Documets Sent</option>
<option value="Nomination of Independent Surveyor">Nomination of Independent Surveyor</option>
<option value="Notice of Readiness">Notice of Readiness</option>
<option value="Shipments - Arutmin">Shipments - Arutmin</option>
<option value="Shipping Instruction">Shipping Instruction</option>
<option value="Surveyor Documents Issued">Surveyor Documents Issued</option>
<option value="Surveyor Send Invoice to Rwood">Surveyor Send Invoice to Rwood</option>
<option value="Vessel Acceptance">Vessel Acceptance</option>
<option value="Vessel Arrival / Loading commence">Vessel Arrival / Loading commence</option>
<option value="Vessel Loading Complete/Departure">Vessel Loading Complete/Departure</option>
<option value="Vessel Nomination">Vessel Nomination</option>
<option value="Cancelled with Penalty">Cancelled with Penalty</option>
    </Form.Select> 
    {errors.status && <span>Required </span>}
  </FloatingLabel>
              </Form.Group>    
   
              <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Supplier">
    <Form.Select aria-label="Floating label select example"
    name="supplier" 
    placeholder="Supplier" className="error-validation"
 
    id="companyDropdown" value={selectedValue || contractData.supplier} onChange={handleSelectChange}
     
    // {...register("supplier", { required: true })}
    > 
      <option value='' >Select</option>

          
{supplier.length>0?(
                 <>
                    {
          supplier.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }

                </>
                ):(
         
                 <option className='no-data'>No data  available</option>
                 )}
    </Form.Select>
    {errors.supplier && <span>Required</span>}
  </FloatingLabel>
              </Form.Group> 
               
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contract Shipment"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Contract Shipment"
              name="contract_shipment"
              defaultValue={contractData.contract_ship_code || datas.contract_ship_code}
              {...register("contract_shipment", { required: false })}
            />
            {errors.contract_shipment && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Order Start Date"
          className="mb-3 datepick"
          
          
        >
           <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        
        dateFormat='dd/MM/yyyy'
         placeholderText="Select" 
      />
        </FloatingLabel>
      </Form.Group>
 
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="End User"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="End User"
              name="end_user"
              {...register("end_user", { required: false })}
            />
            {errors.end_user && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Loading At"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Loading At"
              name="loading_at"
              {...register("loading_at", { required: false })}
            />
            {errors.loading_at && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <h4 className='heading'>Laycan Information</h4>
        <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label='Laycan from Date'
          className="mb-3 datepick"
          
          
        >
           <DatePicker
        selected={laycanFrom}
        onChange={handleEndDateChange}
        
        dateFormat='dd/MM/yyyy'
          placeholderText="Select" 
      />
        </FloatingLabel>
      </Form.Group>
      <Form.Group as={Col} md="6">
      <FloatingLabel
        controlId="floatingSelect"
        label="Laycan Month"
      >
        <Form.Select
          aria-label="Floating label select example"
          name="laycan_month"
          value={datas.laycan_month ||''}
          onChange={handleChangeInput}
        >
          <option value="">Select Month</option>
          <option value="January">January</option>
          <option value="February">February</option>
          <option value="March">March</option>
          <option value="April">April</option>
          <option value="May">May</option>
          <option value="June">June</option>
          <option value="July">July</option>
          <option value="August">August</option>
          <option value="September">September</option>
          <option value="October">October</option>
          <option value="November">November</option>
          <option value="December">December</option>
        </Form.Select>
        {/* Display validation error if present */}
        {/* Assuming errors is defined somewhere */} 
      </FloatingLabel>
    </Form.Group>
    
            <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label='Laycan to Date'
          className="mb-3 datepick"
          
          
        >
           <DatePicker
        selected={laycanTo}
        onChange={handleLaycantoChange}
        
        dateFormat='dd/MM/yyyy'
         placeholderText="Select" 
      />
        </FloatingLabel>
        {endDateError && <div className='error-validations order-error' style={{ color: 'red' }}>{endDateError}</div>}
      </Form.Group>
      
 
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year"
                    placeholder="Laycan Year"
                    value={datas.laycan_year||''}
                    onChange={handleChangeInput} 
                  >
                    <option value="">--None--</option><option value="2021">2021</option><option value="2022">2022</option><option value="2023">2023</option><option value="2024">2024</option><option value="2025">2025</option><option value="2026">2026</option><option value="2015">2015</option><option value="2018">2018</option><option value="2019">2019</option><option value="2020">2020</option>
                  </Form.Select> 
                </FloatingLabel>
              </Form.Group>
             
 <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label='ETA Date'
          className="mb-3 datepick"
          
          
        >
           <DatePicker
        selected={etadate}
        onChange={handleEtaChange}
        dateFormat='dd/MM/yyyy'
          placeholderText="Select" 
      />
        </FloatingLabel>
      </Form.Group>
      
        
 <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label='ETB Date'
          className="mb-3 datepick"
          
          
        >
           <DatePicker
        selected={etbdate}
        onChange={handleEtbChange}
        dateFormat='dd/MM/yyyy'
          placeholderText="Select" 
      />
        </FloatingLabel>
      </Form.Group>
      
  
 <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label='Supplier laycan from date'
          className="mb-3 datepick"
          
          
        >
           <DatePicker
        selected={supplierDate}
        onChange={handleSupplierDateChange}
        dateFormat='dd/MM/yyyy'
         placeholderText="Select" 
      />
        </FloatingLabel>
      </Form.Group>
      
 <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label='laycan confirmation date'
          className="mb-3 datepick"
          
          
        >
           <DatePicker
        selected={confirmationDate}
        onChange={handleConfirmeDateChange}
        dateFormat='dd/MM/yyyy'
         placeholderText="Select" 
      />
        </FloatingLabel>
      </Form.Group>
      
  
      <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label='Supplier Laycan To Date'
          className="mb-3 datepick"
          
          
        >
           <DatePicker
        selected={supplietoDate}
        onChange={handleSupplierToDateChange}
        
        dateFormat='dd/MM/yyyy'
         placeholderText="Select" 
      />
        </FloatingLabel>
      </Form.Group>
      
             </Row>
           
             <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/shipment/contract-shipment-details/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <div className='successDiv'><p></p><p></p></div>
    </Form>
    <Footer/>
    </div>
  );
}

export default ShipmentOrder;

