import React, {
  Component,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import { AiFillDiff } from "react-icons/ai";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import { API_URL } from '../config';
import Popup from "reactjs-popup";
import DataTable from "react-data-table-component";
import "reactjs-popup/dist/index.css";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import $ from "jquery";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import Header from "../Header";
import Footer from "../Footer";
const ContactLists = () => {
  const inputRef = useRef(null);
  const [value, setValue] = useState("");
  const [allaccount, setAllaccount] = useState([]);
  const [account, setAccount] = useState([]);
  

  const [showResults, setShowResults] = React.useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    setValue(inputRef.current.value);
    setShowResults(true);
  };

  console.log(value);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFromdata] = useState({
    account_record: "",
  });

  const handleChange = (e) => {
    setFromdata({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const encryptedAccessToken = localStorage.getItem("access_token");
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);

      fetch(`${API_URL}add/record`, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())

        .then((response) => {
          console.log(response);
          console.log(response.status);
          if (response.message === "record addedd successfully") {
            const account_record_new = response.account_type;

            navigate("/accounts/account-view", {
              state: { account_record: account_record_new },
            });
          }
        });
    }
  };

  const body = $(window).height();
  const header = $("#header").height();
  const nav = $(".navbar").height();
  const footer = $(".footer").height();
  const total_height = header + nav + footer;
  const window_height = body - total_height;
  console.log(total_height);
  console.log(body);
  $(".addAccount").css({ "min-height": window_height + "px" });
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_URL}all/accounts`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.accounts; 
      console.log(data);
      setDatas(data);
      setLoading(false);
      $(document).ready(function () {
        setTimeout(() => {
          $("#tables").DataTable();
        }, 5);
      });
    } catch (error) {
      console.error(error);
    }
  };
// create view dropdown
const { selectedView, setSelectedViews } = useContext(TokenContext);

const [dropdownOptions, setDropdownOptions] = useState([]);
// const[newvalue, setnewvalue]=useState('')

useEffect(() => {
const fetchDropdownOptions = async () => {
try {
const response = await axios.get(`${API_URL}get/all/contactcreateview`);
const options = response.data.contact_create_view;
console.log(options);

setDropdownOptions(options);
} catch (error) {
console.error('Error fetching dropdown options:', error);
setDropdownOptions([]);
}
};

fetchDropdownOptions();
}, []);



const handleDropdownChange = async (event) => {
const viewName = event.target.value;
console.log(viewName);
setSelectedViews(viewName);
console.log(viewName)

if (viewName === 'all') {
  
try {    //bro authorization code bro  now run bro

const response = await axios.get(`${API_URL}allcontacts`, 
{
  headers: { Authorization: `Bearer ${decryptedAccessToken}` },
}
);
const data = response.data.contact; 
console.log(data);
setAllaccount(data)
setTimeout(() => {   
  var table  = $('#create-table').DataTable({
      
scrollY:300,

scrollCollapse: true,
fixedHeader: {
header: true,
},
    });
     
   
},1000);
} catch (error) {
console.error('Error fetching data:', error);
}
}
 else {
try {
const response = await axios.get(`https://rwooduatpy.znapay.in/get/contact/view/${viewName}`);
const data = response.data.contacts;
console.log(data)
setTimeout(() => {   
  var table  = $('#create-table').DataTable({
      
scrollY:300,

scrollCollapse: true,
fixedHeader: {
header: true,
},
    });
     
   
},1000);
setAccount(data);
} catch (error) {
console.error('Error fetching data:', error);
}
}
};


 


// const editView=()=>{ 
//   navigate('/contact/edit-view/',{
//     state: { accountIds: accountId, pageID:dropId },
//   })
// }

   return (
    <>
      <Header />
      <div className="contactAccount">
        <div className="page-header" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">Contact </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Contact
              </li>
            </ol>
          </div>
          <div className="d-flex">
            <div className="justify-content-center">
            <button type="button" className="btn btn-primary my-2 btn-icon-text">
              <Link to='/contact/add-contact'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Contact
              </Link></button>
            </div>
          </div>
        </div>
        <div className="accounts-page">
          <div className="row">
            <div className="col-md-6">
              <form onSubmit={handleSubmit} className="d-flex dropdown-form">

                <label for="pwd" className="mr-sm-2">
                  View
                </label>
                <select onChange={handleDropdownChange} className="form-control" value={selectedView}>
<option value="">----Select Account Type----</option>
<option value="all" selected="selected">All Contacts</option>
{/* Populate the dropdown with the available view names */}
{dropdownOptions.map((option, index) => (
<option key={index} value={option.view_name}>
{option.view_name}
</option>
))}
</select>
{/* <select
                  name="account_record"
                  className="form-control"
                  value={formData.account_record}
                  onChange={handleChange}
                >
                  <option>--Select Account Record Type--</option>
                  <option value="Agent">Agent</option>
                  <option value="Bank">Bank</option>
                  <option value="Buyer">Buyer</option>

                  <option value="Supplier">Supplier</option>
                  <option value="Competitor">Competitor</option>
                  <option value="Employee">Employee</option>
                  <option value="Surveyor">Surveyor</option> */}
                {/* </select> */}
                <input
                  type="submit"
                  class="btn btn-primary mb-2"
                  value="GO" 
                />
              </form>
            </div>
            <div className="col-md-6 links">
              <p>
                <Link to="#">Edit</Link> | <Link to="/contact/create-new-view">Create New View</Link>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h6>
                <span className="pull-left">Recent Accounts</span>
              </h6>
              {selectedView==='all' ? (
              <table class="table table-hover table-bordered" id="create-table">
                <thead>
                  <tr>
                  <th><b>Contact Code</b></th>
                    <th><b>Saluation</b></th>
                    <th><b>First Name</b></th>
                    <th><b>Birthdate</b></th>
                    <th><b>Contact Email</b></th>
                  </tr>
                </thead>

                <tbody>
                  {account.map((x,i)=>(
                  <tr>
                     <td><Link to={"/contact/contact-details"+"/"+x.id }>{x.contact_code}</Link></td> 
     
                    <td>{x.saluation}</td>
                    <td><Link to={"/contact/contact-details"+"/"+x.id }>{x.first_name}</Link></td>
                    <td>{x.birthdate}</td>
                    <td>{x.contact_email}</td>
                  </tr>
                  ))}
                </tbody>
              </table>
              ):(
                <table class="table table-hover table-bordered" id="create-table">
                <thead>
                  <tr>
                  <th><b>Contact Code</b></th>
                    <th><b>Saluation</b></th>
                    <th><b>First Name</b></th>
                    <th><b>Birthdate</b></th>
                    <th><b>Contact Email</b></th>
                  </tr>
                </thead>

                <tbody>
                  {account.length >0 ?(

                    <>
                  {account.map((x,i)=>(
                  <tr>
                    <td><Link to={"/contact/contact-details"+"/"+x.id }>{x.contact_code}</Link></td> 
     
     <td>{x.saluation}</td>
     <td><Link to={"/contact/contact-details"+"/"+x.id }>{x.first_name}</Link></td>
                    <td>{x.birthdate}</td>
                    <td>{x.contact_email}</td>
                  </tr>
                  ))}
                  </>
              ):
                <tr>
                  <td colspan="5" align="center"> No Data Available</td>
                     
                  </tr>}
                </tbody>
              </table>
             
              )}
            </div>
          </div> 
          <div className="row">
            <div className="col-md-6">
            <div className="bg-info">
              <h6>
                <span className="pull-left">Reports</span>
              </h6>
              <div className="account-reports">
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
              </div>
            </div>
            </div>
           <div className="col-md-6">
            <div className="bg-info">
              <h6>
                <span className="pull-left">Tools</span>
              </h6>
              <div className="account-reports">
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
              </div>
            </div>
            </div>
          
          </div>
        </div>
        
{/*        {showResults ? (
          <div class="tasks">
            {loading ? (
              <p id="loading">Loading Data..</p>
            ) : (
              <table id="tables">
                <thead>
                  <tr>
                    <th>Account Code</th>
                    <th>Account Name</th>
                    <th>Account Alias</th>
                    <th>Account Record Type</th>
                    <th>Origin</th>
                    <th>Phone</th>
                    <th>Port</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {datas.length > 0 ? (
                      datas.map((x) => (
                        <>
                          {x.account_record_type === value && (
                            <tr>
                              <td>
                                <Link to={"/accounts/" + x.id + "/"}>
                                  {x.account_code}
                                </Link>
                              </td>
                              <td>{x.account_name}</td>
                              <td>{x.account_alias}</td>
                              <td>
                                <span className="account-record">
                                  {x.account_record_type}
                                </span>
                              </td>
                              <td>{x.origin}</td>
                              <td>{x.phone}</td>
                              <td>{x.port}</td>
                              <td>
                                {" "}
                                <Link to={"/accounts/edit-account/" + x.id}>
                                  <FaPencilAlt />
                                </Link>
                                <Link to={"/accounts/" + x.id + "/"}>
                                  <AiFillDiff />
                                </Link>
                              </td>
                            </tr>
                          )}
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">No data available</td>
                      </tr>
                    )}
                  </React.Fragment>
                </tbody>
              </table>
            )}
          </div>
        ) : null}*/}
      </div>
      <Footer />
    </>
  );
};

export default ContactLists;
