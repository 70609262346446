  
import React, {
  Component,
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo
} from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Table,
} from "react-bootstrap";
import { AiFillDiff } from "react-icons/ai";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import DataTable from "react-data-table-component";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { API_URL } from '../config';
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import $ from "jquery";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { AiOutlineCloseCircle } from "react-icons/ai";

import Header from "../Header";
import Footer from "../Footer";
import { set } from "date-fns";
import { DropDownMenu } from "material-ui";
// import FilterComponent from '../filterComponent';
const FilterComponent = ({ onFilter, onClear, filterText }) => {
  return (
    <div>
      <input
        type="text"
        placeholder="Search"
        value={filterText}
        onChange={onFilter}
        className="table-search"
      />
      {/* <button onClick={onClear} ><AiOutlineCloseCircle /></button> */}
    </div>
  );
};

const CreateContractshipmentlist = () => {
   

  const navigate = useNavigate();
  // const { selectedView, setSelectedViews, allTableData, setAllTableData, otherTableData, setOtherTableData } = useContext(TokenContext);
  const [selectedView, setSelectedViews] = useState('');
  const [allTableData, setAllTableData] = useState([]);
  const [otherTableData, setOtherTableData] = useState([]); 

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [showAllTableView, setShowAllTableView] = useState(selectedView === "all");
  const [accountId, setAccountId]=useState([])
  const [dropId, setDropId]=useState('')
  const [randomDropdownValue, setRandomDropdownValue] = useState('recently_created');
  const [keysAlldata,setKeys]=useState([])
  const[allKeys, setAllkeys]=useState([])
  const[ids,setID]=useState([])



 
  useEffect(() => {
    fetchDropdownOptions();
  }, [decryptedAccessToken]);
    const fetchDropdownOptions = async () => {

      try {
        const response = await axios.get(API_URL+'get/all/contract_ship/createview');
        const options =  response.data.contract_shipment_create_view;
        console.log(options) 

        setDropdownOptions(options);
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
        setDropdownOptions([]);
      }
    };

   


  const deleteCreateview = (event) => {

    event.preventDefault();
  
    const isConfirmed = window.confirm("Are you sure you want to delete?");
  
    if (!isConfirmed) {
      // User canceled the deletion
      return;
    }
  
    const encryptedAccessToken = localStorage.getItem('access_token');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
  
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
  
      fetch(`${API_URL}delete/contract_ships_create_view/${dropId}`, {

        method: "DELETE",
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          'Content-Type': 'application/json'
        },
      })
      .then((response) => {
        if (response.ok) {
          console.log('Deleted')
          return response.json();
        } 
      })
      .then((response) => {
        console.log(response);
        fetchDropdownOptions()
      })
      .catch((error) => {
        console.error('Error deleting:', error);
        // Handle errors here
      });
    }
    
    };
  
  

    
const [filterItems, setFilteredItems]=useState([])
const [columnsData, setColumnsData]=useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedView === 'all') {
          const response = await axios.get(API_URL+'get/allcontract_shipments?random_dropdown_option=recently_created',{
            headers: { Authorization: `Bearer ${decryptedAccessToken}`,
            'Content-Type': 'application/json' },
          });
          const data =  response.data.contractshipment; 
          console.log(data)
          setAllTableData(data);
          
const columns = [
  
  {
      name: 'Conract Shipment Code',
      selector: (row, index) =>row.contract_ship_code,
  },
  {
    name: 'Record Type',
    selector: (row, index) => row.record_type

},

{
  name: 'Price Basis',
  selector: row => row.price_basis,
},
  // ... other columns
];


const filteredItems = data.filter(item => {
    for (const key in item) {
        if (item[key] && item[key].toString().toLowerCase().includes(filterText.toLowerCase())) {
            return true;
        }
    }
    return false;
}).map(item => ({
    "contract_ship_code": <Link to={`/shipment/contract-shipment-details/${item.id}`}>{item.contract_ship_code}</Link>,
    "record_type": item.record_type,
    "price_basis": item.price_basis 
}));

  setColumnsData(columns)
  setFilteredItems(filteredItems)
} else if (selectedView) {
   
          const response = await axios.get(`${API_URL}get/contractshipments/view/${selectedView}`);
          const data = response.data.ships; // Assuming the response con:tains the data for the selected view
          setOtherTableData(data); // Assuming "accounts" is the array of data for the specific view
          console.log(data)
          const account_keys = Object.keys(data[0])
          setAllkeys(account_keys); 
          setTimeout(() => {
            $('.table').dataTable();
          }, 50);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setAllTableData([]);
        setOtherTableData([]);
      }
    };

    fetchData();
  }, [selectedView, setAllTableData, setOtherTableData]);


// console.log(columnsData);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  
  useEffect(() => {
    const storedView = localStorage.getItem('selectedView');
    
    if (storedView && dropdownOptions.find(opt => opt.view_name === storedView)) {
      setSelectedViews(storedView);
      setShowAllTableView(storedView === 'all');
    } else {
      // If there's no stored view or it's not valid, set the default view to 'all'
      setSelectedViews('all');
      setShowAllTableView(true);
      localStorage.setItem('selectedView', 'all');
    }
  }, [dropdownOptions])


  const [formData, setFromdata] = useState({

    account_record: "",

  });


  const handleChange = (e) => {

    setFromdata({ ...formData, [e.target.name]: e.target.value });

  };

  const handleSubmit = (event) => {

    event.preventDefault();


    const encryptedAccessToken = localStorage.getItem('access_token');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

    fetch(API_URL+'add/record', {
              method: "POST",
              body: JSON.stringify(formData),
              headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
            })
              .then((response) => response.json())

              .then((response) => {

                  console.log(response)
                  console.log(response.status)
                  const account_record_new = response.account_type;

                   navigate("/shippment/add-contract-shippment", {
        
                    state: { account_record: account_record_new },
        
                  });
                if (response.message === "record addedd successfully") {
                                 

                                }
              })
            }

    };

  const editView=()=>{ 
        navigate('/contract-shipment/edit-view',{
          state: { accountIds: accountId, pageID:dropId },
        })
      }


const handleRandomDropdownChange = (event) => {
  const selectedValue = event.target.value;
  const encryptedAccessToken = localStorage.getItem('access_token');
  const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
  if (encryptedAccessToken) {
    const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
    fetch(`${API_URL}get/allcontract_shipments?random_dropdown_option=${selectedValue}`,{
      headers: { Authorization: `Bearer ${decryptedAccessToken}`},

    })
      .then(response => response.json())
      .then(data => {
// console.log(data)
        setAllTableData(data);
      })
      .catch(error => {
        console.error('Error fetching data from backend:', error);
        setAllTableData([]);
      });

    setRandomDropdownValue(selectedValue);}



  };
const [selectedOption, setSelectedOption] = useState('');

const handleDropdownChange = (event) => {
  const index = event.target.selectedIndex;
  const el = event.target.childNodes[index];
  const dropdownId = el.getAttribute('id');
  console.log(dropdownId);
  setDropId(dropdownId);
  const viewName = event.target.value;
  console.log(viewName);
  setSelectedViews(viewName);
  setShowAllTableView(viewName === 'all');
  localStorage.setItem('selectedView', viewName);
  setSelectedOption(event.target.value);


};

  
  
  
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);

 
// other table

const columnsNew = Object.keys(otherTableData[0] || {}).map((key) => {
  if (key === 'contract_ship_code') {
    return {
      name: key.replace(/_/g, " "),
      selector: row => (
        <Link to={`/shipment/contract-shipment-details/${row.id}`}>
          {row.contract_ship_code}
        </Link>
      ),
    };
  }
  if (key === 'price_basis') {
    return {
      name: key.replace(/_/g, " "),
      selector: row => (
          row.price_basis
      ),
    };
  }

  return key === 'id' 
    ? null  // Exclude the 'id' column
    : {
        name: key.replace(/_/g, " "),
        selector: row => row[key], 
      };
}).filter(columns => columns !== null);

const [filterOther, setFilterOther] = useState('');
  const [resetPaginationToggleOther, setResetPaginationToggleOther] = React.useState(false);
  // const filteredItemsOther = otherTableData.filter(item => item.account_name && item.account_name.toLowerCase().includes(filterOther.toLowerCase()));
 
  
  const filteredItemsOther = otherTableData.filter(item => {
    // Check if any property in the item contains the filter text
    for (const key in item) {
      if (item[key] && item[key].toString().toLowerCase().includes(filterOther.toLowerCase())) {
        return true;
      }
    }
    return false;

    
  });
  
  const subHeaderComponentMemoOther = React.useMemo(() => {
    const handleClear = () => {
      if (setResetPaginationToggleOther) {
        setResetPaginationToggleOther(!resetPaginationToggleOther);
        setFilterOther('');
      }
    };
    return <FilterComponent onFilter={e => setFilterOther(e.target.value)} onClear={handleClear} filterOther={filterOther} />;
  }, [filterOther, resetPaginationToggleOther]);

//  Recent Items
 
const [recentItems, setRecentItems]=useState([])
const [loading, setLoading] = useState(true);

const fetchRecent = async () => {
  try {
    const response = await axios.get(API_URL+'get/recent_items');
    console.log(response)
    if(response.status===200){
      setRecentItems(response.data.recent_items)
    }
    
  } catch (error) {
    console.error(error);
  }
  finally{
    setLoading(false); 
  }
};
useEffect(() => {
 
  fetchRecent();
}, []);





  return (
    <>
      <Header />
      <div className="contactAccount full-container" >
        <div className="row">
        <div className="col-md-2 recent-item">
          <h4>Recent Items</h4>
          {loading ? (
        <p className="loading-text">Loading...</p>
      ) : (
          <div>
            {recentItems.map((x,i)=>(
              <p><Link to={x.url}>{x.code}</Link></p>
            ))}
          </div>
      )}
</div>
        <div className="col-md-10">
        <div className="page-header" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">Contract Shipment </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Contract Shipment
              </li>
            </ol>
          </div>
          <div className="d-flex">
            <div className="justify-content-center">
            {/* <Popup trigger={<button className="button add-accountrecord"> <FaPlus/> Add Contract Shipment </button>} modal>
      
      <form onSubmit={handleSubmit} className="add-accounts">
       <div className='form-group'>
       <label>Account Record Type</label><br/>
       <select name='account_record' className='form-control' onChange={handleChange} value={formData.account_record}>
         
       <option >--Select Record Type--</option>
       <option value='Buyer - Long Term'>Buyer Long Term</option>
       <option value='Buyer - Spot'>Buyer Spot</option>
       <option value='Supplier - Long Term'>Supplier - Long Term</option> 
       <option value='Supplier - Spot'>Supplier - Spot</option>
         </select>
         </div>
         <input type='submit' className="btn btn-primary " value='Submit'/>
  
         </form>
  </Popup> */}

</div>
          </div>
        </div>
        <div className="accounts-page">
          <div className="row">
            <div className="col-md-6">
              <form  className="d-flex dropdown-form">

                <label for="pwd" className="mr-sm-2">
                  View
                </label>
                <select
  onChange={handleDropdownChange}
  className='form-control view-select'
  value={selectedView}
  defaultValue="all">
        <option value="">Select a View</option>
        <option value="all" >All Views</option>
        {dropdownOptions.length>0 ?(
          <>
        {dropdownOptions.map((option, index) => (
          <option key={index} id={option.id} value={option.view_name}>
            {option.view_name}
          </option>
        ))}
        </>
        ):(<option className="no-data">No data available</option>)}
      </select>



                {/* <input
                  type="submit"
                  class="btn btn-primary mb-2"
                  value="GO"
                /> */}
              </form>
            </div>
            <div className="col-md-6 links">
              <p>
               <span onClick={editView}>Edit</span> |{selectedOption && (
       <span onClick={deleteCreateview}>Delete | </span>
      )} <Link to="/contract-shipment/create-new-view">Create New View</Link>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
            <h6 className="d-flex justify-content-between align-items-center">
            <span>Recent Accounts</span>

            {selectedView === 'all' && allTableData && (
          <div className="random- d-flex">
            <select
         dropdowns     className='form-control'
              onChange={handleRandomDropdownChange}
              value={randomDropdownValue}
              defaultValue="recently_created"

            >
              <option value="recently_created">Recently Created</option>
              <option value="recently_modified">Recently Modified</option>
              <option value="recently_viewed">Recently Viewed</option>
            </select>
          </div>
        )}
          </h6>
          <div className="create-view-table">
            
              {showAllTableView  && (
                
                
                <DataTable  columns={columnsData} data={filterItems} pagination  // optionally, a hook to reset pagination to page 1
  subHeader subHeaderComponent={subHeaderComponentMemo} fixedHeader selectableRows persistTableHead />
              )}
                 {!showAllTableView && selectedView && (
        <div>
          {otherTableData.length > 0 ?(

<DataTable  columns={columnsNew.map((column) => ({
  name: column.name.replace(/_/g, " "),
  selector: column.selector,
  // Add other properties as needed
}))} data={filteredItemsOther} fixedHeader pagination  // optionally, a hook to reset pagination to page 1
subHeader subHeaderComponent={subHeaderComponentMemoOther} 
fixedHeaderScrollHeight="500px"   selectableRows persistTableHead /> 



              ):(
                <table class="table table-hover table-bordered create-table">

                  <tr>
                    <th><b>Account Code</b></th>
                    <th><b>Account Name</b></th>
                    <th><b>Account Alias</b></th>
                    <th><b>Account Record Type</b></th>
                  </tr>



                   <tr>
                    <td align='center' colSpan={4}> No Data Available</td>
                    </tr>


              </table>
                  )}
        </div>
      )}</div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-6">
            <div className="bg-info">
              <h6>
                <span className="pull-left">Reports</span>
              </h6>
              <div className="account-reports">
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
              </div>
            </div>
            </div>
           <div className="col-md-6">
            <div className="bg-info">
              <h6>
                <span className="pull-left">Tools</span>
              </h6>
              <div className="account-reports">
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
                <p>A & A Commodities</p>
              </div>
            </div>
            </div>

          </div> */}
        </div>
        </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateContractshipmentlist;
