
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header' 
import Footer from '../Footer';import { API_URL } from '../config'; 
import $ from 'jquery';
import axios from "axios";  
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
const AddbuyerInner = () => {
  const params = useParams();
  const id = params.id;
const [shippment, setShippment]=useState([]);  
const [serveyour, setServeyor]=useState([]);  
const [datas, setDatas]=useState([]);
useEffect(() => {
   
  fetchData();
}, [decryptedAccessToken]);

const fetchData = async () => {
  try {
    const response = await axios.get(API_URL+'get/allorders', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
    );
    const data = response.data.orders;
    console.log(data)
    setDatas(data);
    $(document).ready(function(){
      // $('#tables').DataTable().destroy();
      // setTimeout(()=>{
        $('#tables').DataTable();
      // },500)
     })
  } catch (error) {
    console.error(error);
  }
};


 useEffect(() => {
   
    fetchDataShipment();
  }, [decryptedAccessToken]);

  const fetchDataShipment = async () => {
    try {
      const response = await axios.get(API_URL+'get/products_api', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      
      setShippment(data)
      console.log(JSON.stringify(data))
      
    } catch (error) {
      console.error(error);
    }
  };
 useEffect(() => {
   
    fetchDataServeyour();
  }, [decryptedAccessToken]);

  const fetchDataServeyour = async () => {
    try {
      const response = await axios.get(API_URL+'get/surveyor', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      
      setServeyor(data)
      console.log(data)
      
    } catch (error) {
      console.error(error);
    }
  };
  const navigate = useNavigate();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const onSubmit = data =>{ 
    const responseData={ 
      product:data.product,
product_master:data.product_master,
buyer_product_type:data.buyer_product_type,
surveyor_name:data.surveyor_name,
order:data.order,
gross_as_recieved:data.gross_as_recieved,
gross_air_dried:data.gross_air_dried,
net_as_recieved:data.net_as_recieved,
total_moisture:data.total_moisture,
ash_ARB:data.ash_ARB,
sulphur_ARB:data.sulphur_ARB,
volatile_matter_ARB:data.volatile_matter_ARB,
moisture_in_air_dried_basis:data.moisture_in_air_dried_basis,
ash_in_air_dried_basis:data.ash_in_air_dried_basis,
volatile_mater_in_air_dried_basis:data.volatile_mater_in_air_dried_basis,
fixed_carbon_by_difference_in_air_dried_basis:data.fixed_carbon_by_difference_in_air_dried_basis,
fixed_carbon_in_air_dried_basis:data.fixed_carbon_in_air_dried_basis,
hgi:data.hgi,
carbon_dry_ash_free_basis:data.carbon_dry_ash_free_basis,
hydrogen_dry_ash_free_basis:data.hydrogen_dry_ash_free_basis,
nitrogen_dry_ash_free_basis:data.nitrogen_dry_ash_free_basis,
sulphur_dry_ash_free_basis:data.sulphur_dry_ash_free_basis,
oxygen_dry_ash_free_basis:data.oxygen_dry_ash_free_basis,
sulphur_ADB:data.sulphur_ADB,
initial_deformation_deg_c_reducing:data.initial_deformation_deg_c_reducing,
spherical_deg_c_reducing:data.spherical_deg_c_reducing,
hemispherical_deg_c_reducing:data.hemispherical_deg_c_reducing,
flow_deg_c_reducing:data.flow_deg_c_reducing,
oxidizing_hemispherical:data.oxidizing_hemispherical,
oxidizing_spherical:data.oxidizing_spherical,
oxidizing_flow:data.oxidizing_flow,
SiO2_dry_basis_in_ash:data.SiO2_dry_basis_in_ash,
Mn3O4_typical_dry_basis_in_ash:data.Mn3O4_typical_dry_basis_in_ash,
AI2O3_dry_basis_in_ash :data.AI2O3_dry_basis_in_ash,
Fe2O3_dry_basis_in_ash:data.Fe2O3_dry_basis_in_ash,
CaO_dry_basis_in_ash:data.CaO_dry_basis_in_ash,
MgO_dry_basis_in_ash:data.MgO_dry_basis_in_ash,
TiO2_dry_basis_in_ash:data.TiO2_dry_basis_in_ash,
NA2O_dry_basis_in_ash:data.NA2O_dry_basis_in_ash,
K2O_dry_basis_in_ash:data.K2O_dry_basis_in_ash,
P2O5_dry_basis_in_ash:data.P2O5_dry_basis_in_ash,
SO3_dry_basis_in_ash:data.SO3_dry_basis_in_ash,
 
TE_arsenic_As_air_dried_coal:data.TE_arsenic_As_air_dried_coal,
TE_antimony_Sb_air_dried_coal:data.TE_antimony_Sb_air_dried_coal,
TE_beryllium_Be_air_dried_coal:data.TE_beryllium_Be_air_dried_coal,
TE_boron_B_air_dried_coal:data.TE_boron_B_air_dried_coal,
TE_cadmium_Cd_air_dried_coal:data.TE_cadmium_Cd_air_dried_coal,
TE_chromium_Cr_air_dried_coal:data.TE_chromium_Cr_air_dried_coal,
TE_copper_Fe_air_dried_coal:data.TE_copper_Fe_air_dried_coal,
TE_fluorine_F_air_dried_coal:data.TE_fluorine_F_air_dried_coal,
TE_mercury_Hg_air_dried_coal:data.TE_mercury_Hg_air_dried_coal,
TE_molybdenum_Mo_air_dried_coal:data.TE_molybdenum_Mo_air_dried_coal,
TE_lead_Pb_air_dried_coal:data.TE_lead_Pb_air_dried_coal,
TE_phosporous_P_air_dried_coal:data.TE_phosporous_P_air_dried_coal,
TE_selenium_Se_air_dried_coal:data.TE_selenium_Se_air_dried_coal,
TE_vanadium_V_air_dried_coal:data.TE_vanadium_V_air_dried_coal,
TE_Zinc_Zn_air_dried_coal:data.TE_Zinc_Zn_air_dried_coal,
TE_arsenic_As_dried_basis:data.TE_arsenic_As_dried_basis,
TE_antimony_Sb_dried_basis:data.TE_antimony_Sb_dried_basis,
TE_beryllium_Be_dried_basis:data.TE_beryllium_Be_dried_basis,
TE_boron_B_dried_basis:data.TE_boron_B_dried_basis,
TE_cadmium_Cd_dried_basis:data.TE_cadmium_Cd_dried_basis,
TE_chromium_Cr_dried_basis:data.TE_chromium_Cr_dried_basis,
TE_copper_Fe_dried_basis:data.TE_copper_Fe_dried_basis,
TE_fluorine_F_dried_basis:data.TE_fluorine_F_dried_basis,
TE_mercury_Hg_dried_basis:data.TE_mercury_Hg_dried_basis,
TE_molybdenum_Mo_dried_basis:data.TE_molybdenum_Mo_dried_basis,
TE_lead_Pb_dried_basis:data.TE_lead_Pb_dried_basis,
TE_phosporous_P_dried_basis:data.TE_phosporous_P_dried_basis,
TE_selenium_Se_dried_basis:data.TE_selenium_Se_dried_basis,
TE_vanadium_V_dried_basis:data.TE_vanadium_V_dried_basis,
TE_Zinc_Zn_dried_basis:data.TE_Zinc_Zn_dried_basis,
size_0_to_50_mm:data.size_0_to_50_mm,
above_50_mm:data.above_50_mm,
under_2_mm:data.under_2_mm,
size_above_2mm_typical:data.size_above_2mm_typical,
size_above_2mm_rejection:data.size_above_2mm_rejection,
testing_method:data.testing_method

    }; 
    console.log(JSON.stringify(responseData))
    const encryptedAccessToken = localStorage.getItem('access_token');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
        
      fetch(API_URL+'add/product_master_buyer/'+id, {
                method: "POST",
                body: JSON.stringify(responseData),
                headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                'Content-Type': 'application/json' },
              })
                .then((response) => response.json())
                
                .then((response) => {  
                  console.log(response)
                  toast.success("Buyer Product Added Successfully", {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                    setTimeout(() => { 
                        reset();
                        navigate("/productmaster/product-detail/"+id);
                      }, 2000); 
               
                })
              
              } 
               
      };
      const [check, setCheck] = useState(false);
      const [active, setActive] = useState(false);
      const [short, setShort] = useState(false);

    return (
<>
<Header/>
<div className="contactAccount"  id='product-master'>
      <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Buyer Product </h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Add Buyer Product / Add Quote Product</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
      </div>
    </div> 
  </div> 
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
        <h4 className='heading'>Information</h4> 
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Product"
              name="product"
              {...register("product", { required: false })}
            />
            {errors.product && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Product Master">
    <Form.Select aria-label="Floating label select example"
    
    name="product_master" 
    {...register("product_master", { required: false })}
    >
      <option value='' >Select</option>
       {
          shippment.map((x)=>{
              return(
                <option key={x.id} value={x.product_name}>
                {x.product_name}
              </option>
              )
          })
       }
    </Form.Select> 
  </FloatingLabel>
              </Form.Group> 
       <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer Product Type"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Buyer Product Type"
              name="buyer_product_type"
              {...register("buyer_product_type", { required: false })}
            />
            {errors.buyer_product_type && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Surveyor Name">
    <Form.Select aria-label="Floating label select example"
    
    name="surveyor_name" 
    {...register("surveyor_name", { required: false })}
    >
      <option value='' >Select</option>
       {
          serveyour.map((x)=>{
              return(
                <option key={x.id} value={x.account_name}>
                {x.account_name}
              </option>
              )
          })
       }
    </Form.Select> 
  </FloatingLabel>
              </Form.Group> 
              <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Order">
    <Form.Select aria-label="Floating label select example"
    
    name="order" 
    {...register("order", { required: false })}
    >
      <option value='' >Select</option>
       {
          datas.map((x, i)=>{
              return(
                <option key={i} value={x.order_code_no}>
                {x.order_code_no}
              </option>
              )
          })
       }
    </Form.Select> 
  </FloatingLabel>
              </Form.Group> 
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Type">
    <Form.Select aria-label="Floating label select example"
    
    name="type" 
    {...register("type", { required: false })}
    >
      <option value="">--None--</option><option value="Load Port - Buyer">Load Port - Buyer</option><option value="Load Port - Supplier">Load Port - Supplier</option><option value="Discharge Port - Buyer">Discharge Port - Buyer</option><option value="Umpire Surveyor Report">Umpire Surveyor Report</option>
    </Form.Select> 
  </FloatingLabel>
              </Form.Group> 
      </Row>
      <Row className="mb-3">
        <h4 className='heading'>CALORIFIC VALUE, kcal / kg</h4> 
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Gross As Recieved"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Gross As Recieved"
              name="gross_as_recieved"
              {...register("gross_as_recieved", {valueAsNumber:true, required: false })}
            />
            {errors.gross_as_recieved && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Gross Air Dried"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Gross Air Dried"
              name="gross_air_dried"
              {...register("gross_air_dried", {valueAsNumber:true, required: false })}
            />
            {errors.gross_air_dried && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Net As Recieved"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Net As Recieved"
              name="net_as_recieved"
              {...register("net_as_recieved", {valueAsNumber:true, required: false })}
            />
            {errors.net_as_recieved && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
         
      </Row>
    
    <Row className="mb-3">
        <h4 className='heading'>%As Received Basis</h4> 
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Total Moisture"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Total Moisture"
              name="total_moisture"
              {...register("total_moisture", {valueAsNumber:true, required: false })}
            />
            {errors.total_moisture && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Ash ARB"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Ash ARB"
              name="ash_ARB"
              {...register("ash_ARB", {valueAsNumber:true, required: false })}
            />
            {errors.ash_ARB && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sulphur ARB"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Sulphur ARB"
              name="sulphur_ARB"
              {...register("sulphur_ARB", {valueAsNumber:true, required: false })}
            />
            {errors.sulphur_ARB && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Volatile Matter ARB"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Volatile Matter ARB"
              name="volatile_matter_ARB"
              {...register("volatile_matter_ARB", {valueAsNumber:true, required: false })}
            />
            {errors.volatile_matter_ARB && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
      </Row>
    <Row className="mb-3">
        <h4 className='heading'>HGI</h4> 
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="HGI"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="HGI"
              name="hgi"
              {...register("hgi", {valueAsNumber:true, required: false })}
            />
            {errors.hgi && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
      </Row>
    <Row className="mb-3">
        <h4 className='heading'>ULTIMATE ANALYSIS, %dry ash free basis</h4> 
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Carbon"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Carbon"
              name="carbon_dry_ash_free_basis"
              {...register("carbon_dry_ash_free_basis", {valueAsNumber:true, required: false })}
            />
            {errors.carbon_dry_ash_free_basis && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Hydrogen"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Hydrogen"
              name="hydrogen_dry_ash_free_basis"
              {...register("hydrogen_dry_ash_free_basis", {valueAsNumber:true, required: false })}
            />
            {errors.hydrogen_dry_ash_free_basis && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Nitrogen"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Nitrogen"
              name="nitrogen_dry_ash_free_basis"
              {...register("nitrogen_dry_ash_free_basis", {valueAsNumber:true, required: false })}
            />
            {errors.nitrogen_dry_ash_free_basis && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sulphur"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Sulphur"
              name="sulphur_dry_ash_free_basis"
              {...register("sulphur_dry_ash_free_basis", {valueAsNumber:true, required: false })}
            />
            {errors.sulphur_dry_ash_free_basis && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Oxygen"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Oxygen"
              name="oxygen_dry_ash_free_basis"
              {...register("oxygen_dry_ash_free_basis", {valueAsNumber:true, required: false })}
            />
            {errors.oxygen_dry_ash_free_basis && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
      </Row>
    <Row className="mb-3">
        <h4 className='heading'>SULPHUR, %air dried basis</h4> 
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sulphur ADB"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Sulphur ADB"
              name="sulphur_ADB"
              {...register("sulphur_ADB", {valueAsNumber:true, required: false })}
            />
            {errors.sulphur_ADB && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
      </Row>
      <Row className="mb-3">
        <h4 className='heading'>ASH FUSION TEMPERATURE, Deg (C) Reducing</h4> 
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Initial Deformation"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Initial Deformation"
              name="initial_deformation_deg_c_reducing"
              {...register("initial_deformation_deg_c_reducing", {valueAsNumber:true, required: false })}
            />
            {errors.initial_deformation_deg_c_reducing && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Spherical"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Spherical"
              name="spherical_deg_c_reducing"
              {...register("spherical_deg_c_reducing", {valueAsNumber:true, required: false })}
            />
            {errors.spherical_deg_c_reducing && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Hemispherical"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Hemispherical"
              name="hemispherical_deg_c_reducing"
              {...register("hemispherical_deg_c_reducing", {valueAsNumber:true, required: false })}
            />
            {errors.hemispherical_deg_c_reducing && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Flow"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Flow"
              name="flow_deg_c_reducing"
              {...register("flow_deg_c_reducing", {valueAsNumber:true, required: false })}
            />
            {errors.flow_deg_c_reducing && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
      </Row>

      <Row className="mb-3">
        <h4 className='heading'>ASH FUSION TEMPERATURE, Deg C Oxidizing</h4> 
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Oxidizing Hemispherical"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Oxidizing Hemispherical"
              name="oxidizing_hemispherical"
              {...register("oxidizing_hemispherical", {valueAsNumber:true, required: false })}
            />
            {errors.oxidizing_hemispherical && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Oxidizing Spherical"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Oxidizing Spherical"
              name="oxidizing_spherical"
              {...register("oxidizing_spherical", {valueAsNumber:true, required: false })}
            />
            {errors.oxidizing_spherical && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Oxidizing Flow"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Oxidizing Flow"
              name="oxidizing_flow"
              {...register("oxidizing_flow", {valueAsNumber:true, required: false })}
            />
            {errors.oxidizing_flow && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
      </Row>
      <Row className="mb-3">
        <h4 className='heading'>ASH ANALYSIS, %dry basis in ash</h4> 
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="SiO2"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="SiO2"
              name="SiO2_dry_basis_in_ash"
              {...register("SiO2_dry_basis_in_ash", {valueAsNumber:true, required: false })}
            />
            {errors.SiO2_dry_basis_in_ash && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Mn3O4"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Mn3O4"
              name="Mn3O4_typical_dry_basis_in_ash"
              {...register("Mn3O4_typical_dry_basis_in_ash", {valueAsNumber:true, required: false })}
            />
            {errors.Mn3O4_typical_dry_basis_in_ash && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="AI2O3"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="AI2O3"
              name="AI2O3_dry_basis_in_ash"
              {...register("AI2O3_dry_basis_in_ash", {valueAsNumber:true, required: false })}
            />
            {errors.AI2O3_dry_basis_in_ash && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Fe2O3"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Fe2O3"
              name="Fe2O3_dry_basis_in_ash"
              {...register("Fe2O3_dry_basis_in_ash", {valueAsNumber:true, required: false })}
            />
            {errors.Fe2O3_dry_basis_in_ash && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="CaO"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="CaO"
              name="CaO_dry_basis_in_ash"
              {...register("CaO_dry_basis_in_ash", {valueAsNumber:true, required: false })}
            />
            {errors.CaO_dry_basis_in_ash && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="MgO"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="MgO"
              name="MgO_dry_basis_in_ash"
              {...register("MgO_dry_basis_in_ash", {valueAsNumber:true, required: false })}
            />
            {errors.MgO_dry_basis_in_ash && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TiO2"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="TiO2"
              name="TiO2_dry_basis_in_ash"
              {...register("TiO2_dry_basis_in_ash", {valueAsNumber:true, required: false })}
            />
            {errors.TiO2_dry_basis_in_ash && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="NA2O"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="NA2O"
              name="NA2O_dry_basis_in_ash"
              {...register("NA2O_dry_basis_in_ash", {valueAsNumber:true, required: false })}
            />
            {errors.NA2O_dry_basis_in_ash && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="K2O"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="K2O"
              name="K2O_dry_basis_in_ash"
              {...register("K2O_dry_basis_in_ash", {valueAsNumber:true, required: false })}
            />
            {errors.K2O_dry_basis_in_ash && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="P2O5"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="P2O5"
              name="P2O5_dry_basis_in_ash"
              {...register("P2O5_dry_basis_in_ash", {valueAsNumber:true, required: false })}
            />
            {errors.P2O5_dry_basis_in_ash && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="SO3"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="SO3"
              name="SO3_dry_basis_in_ash"
              {...register("SO3_dry_basis_in_ash", {valueAsNumber:true, required: false })}
            />
            {errors.SO3_dry_basis_in_ash && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
      </Row>
      <Row className="mb-3">
        <h4 className='heading'>TRACE ELEMENTS, ppm Air Dried in Coal</h4> 
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Arsenic As"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="TE Arsenic As"
              name="TE_arsenic_As_air_dried_coal"
              {...register("TE_arsenic_As_air_dried_coal", {valueAsNumber:true, required: false })}
            />
            {errors.TE_arsenic_As_air_dried_coal && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Antimony Sb"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="TE Antimony Sb"
              name="TE_antimony_Sb_air_dried_coal"
              {...register("TE_antimony_Sb_air_dried_coal", {valueAsNumber:true, required: false })}
            />
            {errors.TE_antimony_Sb_air_dried_coal && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Beryllium Be"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="TE Beryllium Be"
              name="TE_beryllium_Be_air_dried_coal"
              {...register("TE_beryllium_Be_air_dried_coal", {valueAsNumber:true, required: false })}
            />
            {errors.TE_beryllium_Be_air_dried_coal && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Boron B"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="TE Boron B"
              name="TE_boron_B_air_dried_coal"
              {...register("TE_boron_B_air_dried_coal", {valueAsNumber:true, required: false })}
            />
            {errors.TE_boron_B_air_dried_coal && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Cadmium Cd"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="TE Cadmium Cd"
              name="TE_cadmium_Cd_air_dried_coal"
              {...register("TE_cadmium_Cd_air_dried_coal", {valueAsNumber:true, required: false })}
            />
            {errors.TE_cadmium_Cd_air_dried_coal && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE chromium Cr"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="TE chromium Cr"
              name="TE_chromium_Cr_air_dried_coal"
              {...register("TE_chromium_Cr_air_dried_coal", {valueAsNumber:true, required: false })}
            />
            {errors.TE_chromium_Cr_air_dried_coal && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Copper Fe"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="TE Copper Fe"
              name="TE_copper_Fe_air_dried_coal"
              {...register("TE_copper_Fe_air_dried_coal", {valueAsNumber:true, required: false })}
            />
            {errors.TE_copper_Fe_air_dried_coal && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Fluorine F"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="TE Fluorine F"
              name="TE_fluorine_F_air_dried_coal"
              {...register("TE_fluorine_F_air_dried_coal", {valueAsNumber:true, required: false })}
            />
            {errors.TE_fluorine_F_air_dried_coal && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Mercury Hg"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="TE Mercury Hg"
              name="TE_mercury_Hg_air_dried_coal"
              {...register("TE_mercury_Hg_air_dried_coal", {valueAsNumber:true, required: false })}
            />
            {errors.TE_mercury_Hg_air_dried_coal && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Molybdenum Mo"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="TE Molybdenum Mo"
              name="TE_molybdenum_Mo_air_dried_coal"
              {...register("TE_molybdenum_Mo_air_dried_coal", {valueAsNumber:true, required: false })}
            />
            {errors.TE_molybdenum_Mo_air_dried_coal && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Lead Pb"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="TE Lead Pb"
              name="TE_lead_Pb_air_dried_coal"
              {...register("TE_lead_Pb_air_dried_coal", {valueAsNumber:true, required: false })}
            />
            {errors.TE_lead_Pb_air_dried_coal && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Phosporous P"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="TE Phosporous P"
              name="TE_phosporous_P_air_dried_coal"
              {...register("TE_phosporous_P_air_dried_coal", {valueAsNumber:true, required: false })}
            />
            {errors.TE_phosporous_P_air_dried_coal && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Selenium Se"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="TE Selenium Se"
              name="TE_selenium_Se_air_dried_coal"
              {...register("TE_selenium_Se_air_dried_coal", {valueAsNumber:true, required: false })}
            />
            {errors.TE_selenium_Se_air_dried_coal && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Vanadium V"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="TE Vanadium V"
              name="TE_vanadium_V_air_dried_coal"
              {...register("TE_vanadium_V_air_dried_coal", {valueAsNumber:true, required: false })}
            />
            {errors.TE_vanadium_V_air_dried_coal && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Zinc Zn"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="TE Zinc Zn"
              name="TE_Zinc_Zn_air_dried_coal"
              {...register("TE_Zinc_Zn_air_dried_coal", {valueAsNumber:true, required: false })}
            />
            {errors.TE_Zinc_Zn_air_dried_coal && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
      </Row>
      <Row className="mb-3">
        <h4 className='heading'>Sizing, %</h4> 
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="size 0 To 50 mm"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="size 0 To 50 mm"
              name="size_0_to_50_mm"
              {...register("size_0_to_50_mm", {valueAsNumber:true, required: false })}
            />
            {errors.size_0_to_50_mm && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Above 50 mm"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Above 50 mm"
              name="above_50_mm"
              {...register("above_50_mm", {valueAsNumber:true, required: false })}
            />
            {errors.above_50_mm && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Under 2 mm"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Under 2 mm"
              name="under_2_mm"
              {...register("under_2_mm", {valueAsNumber:true, required: false })}
            />
            {errors.under_2_mm && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Size Above 2mm Typical"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Size Above 2mm Typical"
              name="size_above_2mm_typical"
              {...register("size_above_2mm_typical", {valueAsNumber:true, required: false })}
            />
            {errors.size_above_2mm_typical && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Size Above 2mm Rejection"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Size Above 2mm Rejection"
              name="size_above_2mm_rejection"
              {...register("size_above_2mm_rejection", {valueAsNumber:true, required: false })}
            />
            {errors.size_above_2mm_rejection && <span>Required Product Code</span>}
          </FloatingLabel>
          </Form.Group>
          </Row>
          <Row className="mb-3">
                <h4 className='heading'>Testing Method</h4>
                <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Testing Method">
    <Form.Select aria-label="Floating label select example"
    name="testing_method" 
    placeholder="Testing method" 
    defaultValue=""
    {...register("testing_method", { required: false })}
    >

<option value='' >Select </option> 
<option value='According to ASTM Standard Method Unless Otherwise Stated' >According to ASTM Standard Method Unless Otherwise Stated</option> 
<option value='According to ISO Methods'>According to ISO Methods</option>
<option value='According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM'>According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM</option>
<option value='According to ISO Methods Except HGI to ASTM'>According to ISO Methods Except HGI to ASTM</option>
<option value='According to ISO Methods, Except HGI and Ash Analysis to ASTM'>According to ISO Methods, Except HGI and Ash Analysis to ASTM</option>
<option value='According to ISO Methods, Except HGI as per ASTM Test Method'>According to ISO Methods, Except HGI as per ASTM Test Method</option>
    </Form.Select>
    {errors.testing_method && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>  
              </Row>  
             
      <input type="submit"  className="addaccount-save" value='Save'/>
      <div className='successDiv'><p></p><p></p></div>
    </Form>
    </div>
</>
    );

  
 

};


export default AddbuyerInner;